import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Input, Button } from 'antd';
import { useWithdrawal } from '@libs/hooks/withdrawal';
import { useAuth } from '@libs/contexts/auth';
import censorEmail from '@libs/utils/censorEmail';

const Verify = ({ withdrawalID, showModal, onCancel, setModalState }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { verify: verifyWithdrawal, resend, loading } = useWithdrawal();
  const { user } = useAuth();
  const [timer, setTimer] = useState(0);

  const onSubmit = async (values) => {
    await verifyWithdrawal(withdrawalID, values.code);
    setModalState({ withdrawalID: '', step: 'success' });
    form.resetFields();
  };

  const onResend = async () => {
    if (timer < 1) {
      await resend(withdrawalID);
      setTimer(60);
    }
  };

  useEffect(() => {
    if (showModal === true) {
      setTimer(60);
    }
  }, [showModal]);

  useEffect(() => {
    let interval;
    if (showModal === true) {
      interval = setInterval(() => {
        if (timer > 0) setTimer(timer - 1);
      }, 1000);

      if (timer < 1) {
        clearInterval(interval);
      }
    }

    return () => clearInterval(interval);
  }, [timer, showModal]);

  return (
    <Modal
      className="withdrawal-modal"
      title={t('balance.verify.label')}
      visible={showModal}
      footer={null}
      width={635}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
    >
      <p>{user && t('balance.verify.description', { email: censorEmail(user?.email) })}</p>
      <p>
        {t('balance.verify.resend.text')} <br />
        <u style={{ cursor: timer < 1 && 'pointer' }} onClick={onResend}>
          {t('balance.verify.resend.label')}
        </u>
        {timer > 0 && (
          <span>
            {t('balance.verify.resend.wait')} {timer}s
          </span>
        )}
      </p>
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        onFieldsChange={(value) => {
          const nameArr = value[0].name;
          if (nameArr[0] === 'method') {
            form.resetFields(['destination']);
          }
        }}
      >
        <Form.Item
          name="code"
          label={t('balance.verify.enter')}
          rules={[{ required: true, message: t('balance.verify.enter') }]}
        >
          <Input
            type="number"
            className="withdrawal-modal__enter-code"
            style={{ width: '100%' }}
            placeholder={t('balance.verify.placeholder')}
          />
        </Form.Item>
        <p>
          {t('balance.verify.cantAccess')}
          <br />
          {t('balance.verify.support')}
        </p>
        <Button type="primary" loading={loading} disabled={loading} htmlType="submit">
          {t('balance.continue')}
        </Button>
      </Form>
    </Modal>
  );
};
export default Verify;
