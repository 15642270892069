import { Button, Typography, Row, Col, Modal, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useCreatorUser } from '@libs/hooks/user';

function ModalRegister({ onShow, onClose }) {
  const { Title, Paragraph } = Typography;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [registerConfirm, setRegisterConfirm] = useState(false);
  const { signUp, loading, success: successRegister } = useCreatorUser();
  const handleRegister = async (values) => {
    await signUp(values);
  };
  useEffect(() => {
    if (successRegister === true) {
      setRegisterConfirm(true);
      onClose();
      form.resetFields();
    }
  }, [successRegister]); // eslint-disable-line
  return (
    <>
      <Modal
        className="landing__modal"
        onCancel={onClose}
        visible={onShow}
        footer={null}
        closeIcon={<></>}
        width={790}
      >
        <div>
          <Title level={2} className="landing-h2">
            {t('components.modalRegister.title')}
          </Title>
          <Paragraph className="landing-paragraph landing-paragraph-register">
            {t('components.modalRegister.description')}
          </Paragraph>
          <Form onFinish={handleRegister} form={form}>
            <Row gutter={16}>
              <Col span={24} md={12}>
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: t('components.modalRegister.fields.firstName.required')
                    },
                    {
                      pattern: /^[a-zA-Z\s]*$/,
                      message: t('components.modalRegister.fields.firstName.invalid')
                    }
                  ]}
                >
                  <Input
                    autoComplete="off"
                    placeholder={t('components.modalRegister.fields.firstName.placeholder')}
                  />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: t('components.modalRegister.fields.lastName.required')
                    },
                    {
                      pattern: /^[a-zA-Z\s]*$/,
                      message: t('components.modalRegister.fields.lastName.invalid')
                    }
                  ]}
                >
                  <Input placeholder={t('components.modalRegister.fields.lastName.placeholder')} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name="email"
              rules={[
                { type: 'email', message: t('components.modalRegister.fields.email.type') },
                { required: true, message: t('components.modalRegister.fields.email.required') }
              ]}
            >
              <Input
                autoComplete="off"
                placeholder={t('components.modalRegister.fields.email.placeholder')}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: t('components.modalRegister.fields.password.required') },
                {
                  pattern: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                  message: t('components.modalRegister.fields.password.strength')
                },
                { min: 8, message: t('components.modalRegister.fields.password.minLength') },
                { max: 64, message: t('components.modalRegister.fields.password.maxLength') }
              ]}
            >
              <Input
                type="password"
                placeholder={t('components.modalRegister.fields.password.placeholder')}
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: t('components.modalRegister.fields.confirmPassword.required')
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      t('components.modalRegister.fields.confirmPassword.notMatch')
                    );
                  }
                })
              ]}
            >
              <Input
                type="password"
                placeholder={t('components.modalRegister.fields.confirmPassword.placeholder')}
              />
            </Form.Item>
            <Button htmlType="submit" className="landing__button" loading={loading}>
              {t('components.modalRegister.button')}
            </Button>
          </Form>
        </div>
      </Modal>
      <Modal
        className="landing__modal"
        visible={registerConfirm}
        footer={null}
        closeIcon={<></>}
        width={790}
        onCancel={() => {
          setRegisterConfirm(false);
        }}
      >
        <div>
          <CheckCircleOutlined className="landing__modal__icon" />
          <Title level={2} className="landing-h2">
            {t('components.modalRegister.confirmation.title')}
          </Title>
          <Paragraph className="landing-paragraph">
            {t('components.modalRegister.confirmation.description')}
          </Paragraph>
          <Button
            className="landing__button"
            onClick={() => {
              setRegisterConfirm(false);
            }}
          >
            {t('components.modalRegister.confirmation.button')}
          </Button>
        </div>
      </Modal>
    </>
  );
}

ModalRegister.defaultProps = {
  onShow: false,
  onCancel: false
};

export default ModalRegister;
