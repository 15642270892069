import FansincCreators from './fansinc';
import PatronsCreators from './patrons';

const Creators = () => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsCreators />;
  } else {
    return <FansincCreators />;
  }
};

export default Creators;
