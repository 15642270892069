import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { Redirect, useParams } from 'react-router-dom';

import { useAuth } from 'libs/contexts/auth';
import { useInvitation } from '@libs/hooks/invitation';
import { useUserByEmail } from '@libs/hooks/user';

function AuthAcceptInvitation() {
  const { id } = useParams();
  const { t } = useTranslation();

  const { isAuthenticated, user } = useAuth();
  const { data: invitation, onAcceptInvitation, invitationLoading } = useInvitation(id);
  const { data: usersByEmail } = useUserByEmail(invitation?.email);

  const acceptInvitation = () => {
    onAcceptInvitation(id);
  };

  if (!isAuthenticated) {
    if (Array.isArray(usersByEmail)) {
      const userHasAccount = usersByEmail?.length > 0;
      const nextPath = userHasAccount ? '/login' : `/join-team/${id}`;

      return <Redirect to={nextPath} />;
    }
  }

  return (
    <>
      <div className="auth-invitation">
        <div className="auth-invitation__wrapper">
          <div className="auth-invitation__description">
            {invitation?.email !== user?.email
              ? t('auth.acceptInvite.differentEmail')
              : t('auth.acceptInvite.instruction', { organization: invitation?.account?.name })}
          </div>
          <div className="auth-invitation__button">
            <Button
              type="primary"
              onClick={acceptInvitation}
              loading={invitationLoading}
              disabled={invitation?.email !== user?.email}
            >
              {t('auth.acceptInvite.button')}
            </Button>
          </div>
        </div>
      </div>
      <Helmet>
        <title>{t('auth.acceptInvite.title')}</title>
      </Helmet>
    </>
  );
}

export default AuthAcceptInvitation;
