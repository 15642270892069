const censorWord = (str) => {
  return str[0] + '*'.repeat(str.length - 1);
};

const censorEmail = (email) => {
  const arr = email.split('@');
  return censorWord(arr[0]) + '@' + censorWord(arr[1].replace('.com', '')) + '.com';
};

export default censorEmail;
