import Creatable from 'react-select/creatable';
import cx from 'classnames';

const CreatableSelect = ({
  label,
  onCreate,
  isLoading,
  onInputChange,
  onChange,
  options,
  value,
  placeholder,
  disabled = false,
  error
}) => {
  return (
    <>
      {label && (
        <label className="block mb-1 text-gray-700" htmlFor="forms-validationInputCode_error">
          {label}
        </label>
      )}
      <Creatable
        placeholder={placeholder}
        isMulti
        cacheOptions
        onCreateOption={onCreate}
        isLoading={isLoading}
        onInputChange={onInputChange}
        onChange={onChange}
        options={options}
        value={value}
        className={cx(error && 'select-creatable-error')}
        isDisabled={disabled}
      />
      {error && (
        <div
          class="ant-form-item-explain ant-form-item-explain-connected"
          style={{ height: 'auto', minHeight: '24px', opacity: 1 }}
        >
          <div role="alert" class="ant-form-item-explain-error">
            {error}
          </div>
        </div>
      )}
    </>
  );
};

CreatableSelect.defaultProps = {
  onCreateOption: () => {}
};

export default CreatableSelect;
