import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Checkbox, Col, Form, Input, Row } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Link, ModalPatronsRegister } from '@components';
import { useAuth } from '@libs/contexts/auth';
import useQuery from '@libs/utils/query';

function AuthLogin() {
  const { t } = useTranslation();
  const query = useQuery();
  const { loading: formLoading, error, success, authenticate, isAuthenticated, user } = useAuth();
  const [registerModal, setRegisterModal] = useState(false);

  if (isAuthenticated) {
    if (user?.accountID) {
      return <Redirect to="/collectibles" />;
    }
  }

  const onFinish = (values) => {
    authenticate({
      email: values.email,
      password: values.password
    });
  };
  return (
    <>
      <div className="auth-login">
        <div className="auth-login__wrapper">
          <div className="auth-login__title">{t('auth.login.patrons.title')}</div>
          <div className="auth-login__description">{t('auth.login.patrons.description')}</div>
          <Row>
            <Col className="auth-box__left" span={24}>
              <Form
                initialValues={{
                  email: query.get('email')
                }}
                name="login"
                layout="vertical"
                className="admin-form"
                onFinish={onFinish}
              >
                {(error || success) && (
                  <Form.Item>
                    {error && <Alert message={error} type="error" showIcon />}
                    {success && <Alert message={success} type="success" showIcon />}
                  </Form.Item>
                )}
                <Form.Item
                  name="email"
                  label="Email Address"
                  rules={[
                    { type: 'email', message: t('auth.fields.email.type') },
                    { required: true, message: t('auth.fields.email.required') }
                  ]}
                >
                  <Input placeholder={t('auth.fields.email.placeholder')} />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[{ required: true, message: t('auth.fields.password.required') }]}
                >
                  <Input.Password
                    type="password"
                    placeholder={t('auth.fields.password.placeholder')}
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </Form.Item>
                <Row>
                  <Col className="auth-checkbox" span={12}>
                    <Form.Item name="remember" valuePropName="checked" style={{ marginBottom: 0 }}>
                      <Checkbox>{t('auth.fields.remember.label')}</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={12} className="auth-remember__right">
                    <Link to="/forgot-password">{t('auth.forgotPassword')}</Link>
                  </Col>
                </Row>
                <div className="auth-login__button">
                  <Button block type="primary" loading={formLoading} htmlType="submit">
                    {t('auth.buttons.signIn')}
                  </Button>
                </div>
              </Form>
              <div className="auth-login__bottom">
                {t('auth.login.patrons.signUp')}{' '}
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => setRegisterModal(true)}
                >
                  here
                </span>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <ModalPatronsRegister onShow={registerModal} onClose={() => setRegisterModal(false)} />
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME === 'patrons'
            ? t('auth.login.patrons.title')
            : t('auth.login.title')}
        </title>
      </Helmet>
    </>
  );
}

export default AuthLogin;
