import cx from 'classnames';
import { Typography } from 'antd';
import { LeftOutlined, MenuOutlined } from '@ant-design/icons';

import { Link } from '@components';

import { useSider } from '@libs/utils/sider';

const DashboardContent = ({ title, backLink, children }) => {
  const { Title } = Typography;
  const { isCollapse, toggleSider } = useSider();

  const siderToggleClass = {
    siderOpened: !isCollapse,
    siderClosed: isCollapse
  };

  return (
    <>
      <div className="dash-content__header">
        <div
          className={cx('toggle-sider', siderToggleClass)}
          onClick={(e) => {
            e.preventDefault();
            toggleSider(!isCollapse);
          }}
        >
          <MenuOutlined />
        </div>
        {backLink && (
          <Link to={backLink} className="dash-content__header__back">
            <LeftOutlined />
          </Link>
        )}
        {title && <Title>{title}</Title>}
      </div>
      <div className="dash-content__inner">{children}</div>
    </>
  );
};

export default DashboardContent;
