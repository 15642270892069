import Logo from '@assets/img/footer-logo.png';
import { ReactComponent as ArrowRight } from '@assets/svg/arrow-right.svg';
import { useTranslation } from 'react-i18next';
import { Link } from '@components';

function AuthFooter() {
  const { t } = useTranslation();
  return (
    <div className="auth-footer">
      <div className="auth-footer__wrapper">
        <div className="auth-footer__left">
          <img alt="logo" src={Logo} />
          <div className="auth-footer__left__text">{t('components.authFooter.copyright')}</div>
        </div>
        <div className="auth-footer__right">
          <div>
            <Link to={{ pathname: 'https://www.patrons.art/' }} target="_blank">
              <div className="auth-footer__right__visit">
                {t('components.authFooter.visit')} <ArrowRight />
              </div>
            </Link>
            <div className="auth-footer__right__link">
              <Link to={{ pathname: 'https://www.patrons.art/faq' }} target="_blank">
                <div className="auth-footer__right__link__item">
                  {t('components.authFooter.faq')}
                </div>
              </Link>
              <Link
                to={{ pathname: 'https://www.patrons.art/terms-and-conditions' }}
                target="_blank"
              >
                <div className="auth-footer__right__link__item">
                  {t('components.authFooter.terms')}
                </div>
              </Link>
              <Link to={{ pathname: 'https://www.patrons.art/privacy-policy' }} target="_blank">
                <div className="auth-footer__right__link__item">
                  {t('components.authFooter.privacy')}
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthFooter;
