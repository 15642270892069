import FansincDetailOrder from './fansinc';
import PatronsDetailOrder from './patrons';

const DetailOrder = () => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsDetailOrder />;
  } else {
    return <FansincDetailOrder />;
  }
};

export default DetailOrder;
