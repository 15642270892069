import { useState, useCallback, useRef, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Storage } from 'aws-amplify';
import { useParams } from 'react-router';
import moment from 'moment';
import Sticky from 'react-stickynode';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import slugify from 'slugify';

import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Tooltip,
  Typography,
  Upload,
  Modal,
  Tabs,
  Timeline,
  Collapse
} from 'antd';

import {
  ExclamationCircleOutlined,
  UploadOutlined,
  EditFilled,
  CheckOutlined,
  CloseOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  MenuOutlined,
  UpOutlined,
  DownOutlined
} from '@ant-design/icons';

import {
  CollectibleCardV2,
  DashboardContent,
  FormGroup,
  Link,
  Cropper,
  RichTextEditor,
  Creatable,
  Price
} from '@components';
import { categories, genres, draggerProps, defaultFileList } from './utils';
import { useProfileOrganisation } from '@libs/hooks/organisation';
import { useProfile } from '@libs/hooks/verified-profile';
import { useChannelByOwner } from '@libs/hooks/discourse';
import {
  useCollectible,
  useCollectibles,
  useFeaturedArtist,
  useFeaturedArtistByCollectible
} from '@libs/hooks/collectible';
import { useImageResizer } from '@libs/utils/uploader';
import { useCollection, useCollectionByAccountId } from '@libs/hooks/collection';
import { historyColorMapper } from '@libs/utils/mapper';
import { useAuth } from '@libs/contexts/auth';
import PrintFrame from './printFrame';

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24
    },
    sm: {
      span: 24
    }
  },
  wrapperCol: {
    xs: {
      span: 24
    },
    sm: {
      span: 24
    }
  }
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: {
      span: 24
    },
    sm: {
      span: 24
    }
  }
};
const { TabPane } = Tabs;

function EditCollectibles() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { user } = useAuth();
  const { Panel } = Collapse;
  const { data: collectionAccount } = useCollectionByAccountId();
  const { data: collectible, loading, updateLoading, reviewLoading, onUpdate } = useCollectible(id);

  const { isSlugAvailable } = useCollectibles();
  const { data: artistByCollectible, loading: artistLoading } = useFeaturedArtistByCollectible(id);
  const { data: creators } = useProfileOrganisation(true);
  const {
    options,
    value: artistsValue,
    onChange: onChangeArtists,
    onCreate: createArtist
  } = useFeaturedArtist();

  const { data: collections, loading: collectionsLoading } = useCollection();

  const [promoEnabled, setPromoEnabled] = useState(collectible?.promoEnabled === 'TRUE');
  const numberedIndex = collectible?.releases?.length - 1;
  const [digitalOriginalEnabled, setDigitalOriginalEnabled] = useState(numberedIndex > 0);

  const [previewData, setPreviewData] = useState({
    title: collectible?.title,
    slug: collectible?.slug,
    creator: collectible?.creator,
    lowestPrice: collectible?.searchableLowestPrice,
    highestPrice: collectible?.searchableHighestPrice,
    listingType: collectible?.releases ? collectible?.releases?.[0].listingType : 'FIXED_PRICE',
    releaseCount: collectible?.releases ? collectible?.releases?.[numberedIndex]?.releaseCount : 1,
    auctionEndDate: collectible?.releases ? collectible?.releases[0].saleEndDate : null
  });

  const [form] = Form.useForm();
  const disabled = collectible?.status === 'IN_REVIEW' || collectible?.status === 'PUBLISHED';

  const { Option } = Select;
  const { Title } = Typography;
  const { TextArea } = Input;
  const { Dragger } = Upload;

  const [doReleaseDate, setDoReleaseDate] = useState(moment());
  const [agree, setAgreement] = useState(false);

  const [editSlug, setEditSlug] = useState(false);

  // Pricing fields
  const doListingType = form.getFieldValue(['releases', 0, 'listingType']);
  const doSetStartingPrice = form.getFieldValue(['releases', 0, 'setStartingPrice']);
  const doSetReservePrice = form.getFieldValue(['releases', 0, 'setFinalPrice']);

  const [featuredArtist, setFeaturedArtist] = useState(false);
  const [featuredArtistError, setFeaturedArtistError] = useState(false);

  const [titleLength, setTitleLength] = useState('0');
  const [descriptionLength, setDescriptionLength] = useState('0');
  const [metaDescriptionLength, setMetaDescriptionLength] = useState('0');

  const [enableInsideStory, setEnableInsideStory] = useState(
    collectible?.insideStory !== null && collectible?.insideStory !== ''
  );
  const [insideStory, setInsideStory] = useState(collectible?.insideStory);

  const [whatsIncludedOriginal, setWhatsIncludedOriginal] = useState(
    collectible?.whatsIncludedOriginal
  );
  const [whatsIncludedNumbered, setWhatsIncludedNumbered] = useState(
    collectible?.whatsIncludedNumbered
  );
  const [insideStoryLength, setInsideStoryLength] = useState('0');

  const [profileID, setProfileID] = useState();
  //let { options: collections } = useCollectionsByUser(id, true);
  let { data: profile } = useProfile(profileID);
  let { data: channels } = useChannelByOwner(profileID);

  const [externalStreaming, setExternalStreaming] = useState({
    spotify: '',
    appleMusic: '',
    youtube: ''
  });

  const [assetUrl, setAssetUrl] = useState();
  const [mediaThumbnail, setMediaThumbnail] = useState();
  const [thumbnailFile, setThumbnailFile] = useState();
  const [listChannel, setListChannel] = useState([]);
  const [originalChannelValue, setOriginalChannelValue] = useState([]);
  const [limitedChannelValue, setLimitedChannelValue] = useState([]);
  const [thumbnailModal, setThumbnailModal] = useState(false);
  const [, triggerChanges] = useState();
  const [isDirty, setIsDirty] = useState(false);
  const [width, setWidth] = useState(0);
  const slugRef = useRef();
  const [validateFaqValue, setValidateFaqValue] = useState(true);
  const [originalWidth, setOriginalWidth] = useState(20);
  const [originalHeight, setOriginalHeight] = useState(24);
  const [originalFrame, setOriginalFrame] = useState('');
  const [smallWidth, setSmallWidth] = useState(0);
  const [smallHeight, setSmallHeight] = useState(0);
  const [smallFrame, setSmallFrame] = useState('');
  const [doPrintable, setDoPrintable] = useState(false);
  const [doSmallPrint, setDoSmallPrint] = useState(false);
  const [largeWidth, setLargeWidth] = useState(0);
  const [largeHeight, setLargeHeight] = useState(0);

  const [items, setItems] = useState([]);
  const [openFaq, setOpenFaq] = useState([]);
  const [countItem, setCountItems] = useState(1);

  useEffect(() => {
    if (user?.type === 'Artist' && creators?.length > 0) {
      setProfileID(creators[0].value);
    }
  }, [user, creators]);

  const validateFaq = useCallback(() => {
    setValidateFaqValue(true);
    let validateValue = true;
    items.forEach((item) => {
      if (item.title === '' || item.content === '') {
        setValidateFaqValue(false);
        validateValue = false;
      }
    });
    return validateValue;
  }, [items]);

  useEffect(() => {
    onChangeArtists(
      artistByCollectible?.map((x) => ({
        label: x?.featuredArtist?.artistName,
        value: x?.featuredArtist?.id
      })) || []
    );
  }, [artistByCollectible]); // eslint-disable-line

  useEffect(() => {
    const beforeUnload = isDirty ? () => '' : null;
    window.onbeforeunload = beforeUnload;
  }, [isDirty]);
  // const defaultPrintable = collectible?.printable === 'TRUE';

  const handleSubmit = useCallback(
    (submitForReview = false) => {
      if (artistsValue.length < 1) {
        setFeaturedArtistError(true);
        form.validateFields();
        return false;
      } else {
        setFeaturedArtistError(false);
      }
      const validateValue = validateFaq();
      if (!validateValue) {
        return false;
      }

      form
        .validateFields()
        .then(async (data) => {
          const numbIndex = data?.releases?.length - 1;

          const payload = {
            ...data,
            userID: user?.type === 'Artist' ? profileID : data?.userID,
            collectionID: collectionAccount?.id ? collectionAccount?.id : data.collectionID,
            width: data.width.toString(),
            height: data.height.toString(),
            largeWidth: largeWidth.toString(),
            largeHeight: largeHeight.toString(),
            smallWidth: smallWidth.toString(),
            smallHeight: smallHeight.toString(),
            assetUrl: assetUrl?.replace('temps/', ''),
            mediaThumbnail: mediaThumbnail?.replace('temps/', ''),
            featuredArtist: artistsValue,
            insideStory,
            originalFrame,
            smallFrame,
            whatsIncludedOriginal,
            whatsIncludedNumbered,
            marketingText: '',
            maxNumberedEditions: data?.releases
              ? data?.releases[numbIndex]?.releaseCount
              : collectible.maxNumberedEditions,
            promoEnabled: data.promoEnabled ? 'TRUE' : 'FALSE',
            printable: doPrintable ? 'TRUE' : 'FALSE',
            smallPrint: doSmallPrint ? 'TRUE' : 'FALSE',
            perksOriginal: {
              limitedPerks: data?.limitedPerksOriginal,
              lifetimePerks: data?.lifetimePerksOriginal,
              fanClubAccess: originalChannelValue
            },
            perksNumbered: {
              limitedPerks: data?.limitedPerksNumbered,
              lifetimePerks: data?.lifetimePerksNumbered,
              fanClubAccess: limitedChannelValue
            },
            faq: items?.map((item, index) => {
              return { ...item, key: index + 1 };
            }),
            streamUrl: {
              spotify: data?.streamSpotify,
              appleMusic: data?.streamAppleMusic,
              youtube: data?.streamYoutube
            },
            releases: data.releases
              .filter((r) => r)
              .map(({ soldCount, ...release }) => {
                const enableStartingPrice =
                  release.setStartingPrice || release.listingType === 'FIXED_PRICE';
                const enableFinalPrice =
                  (release.setFinalPrice && release.listingType === 'AUCTION') ||
                  release.listingType === 'FIXED_PRICE';

                return {
                  ...release,
                  preReleaseAvailability: 0,
                  availableForPreRelease: release.availableForPreRelease ? 'TRUE' : 'FALSE',
                  setStartingPrice: release.setStartingPrice ? 'TRUE' : 'FALSE',
                  setFinalPrice: release.setFinalPrice ? 'TRUE' : 'FALSE',
                  startingPrice: enableStartingPrice ? release.startingPrice : '0.00',
                  finalPrice: enableFinalPrice
                    ? release.listingType === 'FIXED_PRICE'
                      ? release.startingPrice
                      : release.finalPrice
                    : '0.00',
                  saleEndDate: release?.saleEndDate ? release.saleEndDate.toISOString() : undefined,
                  releaseDate: release.releaseDate.toISOString()
                };
              })
          };
          return await onUpdate(id, payload, submitForReview);
        })
        .catch((error) => {
          console.error(error.message);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      mediaThumbnail,
      assetUrl,
      insideStory,
      artistsValue,
      whatsIncludedOriginal,
      whatsIncludedNumbered,
      originalChannelValue,
      limitedChannelValue,
      items,
      smallHeight,
      largeHeight,
      smallWidth,
      largeWidth,
      originalHeight,
      originalWidth,
      originalFrame,
      smallFrame,
      doPrintable,
      doSmallPrint,
      collectionAccount,
      profileID,
      user
    ]
  );

  const handleUploadAsset = async (asset, remove) => {
    if (remove) {
      setAssetUrl('');
      return;
    }

    setDoPrintable(false);

    setAssetUrl(asset);
  };

  const handleUploadThumbnail = async (image, remove) => {
    if (remove) {
      setMediaThumbnail('');
      return;
    }

    const imageUrl = await Storage.get(image, { contentType: 'image/png' });

    setMediaThumbnail('');
    setThumbnailFile({ imageUrl, key: image });
    setThumbnailModal(true);
  };

  const { loading: imageResizeLoading, onResize } = useImageResizer();
  const featuredArtistValue = artistByCollectible?.map((x) => x?.featuredArtist?.artistName);
  const initialData = {
    title: collectible?.title,
    category: collectible?.category,
    genre: collectible?.genre,
    featuredArtist: featuredArtistValue,
    collectionID: collectible?.collectionID,
    userID: collectible?.userID,
    slug: collectible?.slug,
    description: collectible?.description,
    insideStory: collectible?.insideStory,
    enableInsideStory: collectible?.insideStory !== '',
    maxNumberedEditions: collectible?.maxNumberedEditions,
    assetUrl: collectible?.assetUrl,
    mediaThumbnail: collectible?.mediaThumbnail,
    yearOfCreation: collectible?.yearOfCreation,
    featuredInProfile: collectible?.featuredInProfile === 'TRUE',
    whatsIncludedOriginal: collectible?.whatsIncludedOriginal,
    whatsIncludedNumbered: collectible?.whatsIncludedNumbered,
    marketingText: collectible?.marketingText,
    metaTitle: collectible?.metaTitle,
    metaDescription: collectible?.metaDescription,
    streamExternal: collectible?.streamExternal === 'TRUE',
    streamSpotify: collectible?.streamUrl?.spotify,
    streamAppleMusic: collectible?.streamUrl?.appleMusic,
    streamYoutube: collectible?.streamUrl?.youtube,
    promoEnabled: collectible?.promoEnabled === 'TRUE',
    printable: collectible?.printable === 'TRUE',
    promoTitle: collectible?.promoTitle,
    promoDescription: collectible?.promoDescription,
    width: collectible?.width,
    height: collectible?.height,
    limitedPerksOriginal:
      collectible?.perksOriginal?.limitedPerks?.length > 0
        ? collectible?.perksOriginal?.limitedPerks
        : [''],
    limitedPerksNumbered:
      collectible?.perksNumbered?.limitedPerks?.length > 0
        ? collectible?.perksNumbered?.limitedPerks
        : [''],
    lifetimePerksOriginal:
      collectible?.perksOriginal?.lifetimePerks?.length > 0
        ? collectible?.perksOriginal?.lifetimePerks
        : [''],
    lifetimePerksNumbered:
      collectible?.perksNumbered?.lifetimePerks?.length > 0
        ? collectible?.perksNumbered?.lifetimePerks
        : [''],
    //collectionIDs: collectible?.collections?.items.map((c) => c.collectionID),
    releases: collectible?.releases?.map((t) => ({
      ...t,
      setStartingPrice: t.setStartingPrice === 'TRUE',
      setFinalPrice: t.setFinalPrice === 'TRUE',
      releaseDate: moment(t?.releaseDate),
      saleEndDate: t?.saleEndDate ? moment(t?.saleEndDate) : undefined
    }))
  };

  const numbEdition = (form.getFieldValue('releases') || []).filter(
    (r) => r.tierType === 'NUMBERED_EDITION'
  );
  const countNumbEditionReleases = numbEdition.reduce(
    (a, b) => (a += b?.releaseCount ? parseInt(b.releaseCount) : 0),
    0
  );
  const numbEditionTotalPrice = numbEdition.reduce((a, b) => {
    const releaseCount = parseInt(b.releaseCount);
    const finalPrice = b.finalPrice || b.startingPrice || 0;
    a += releaseCount * finalPrice;
    return a;
  }, 0);

  // Upload media validation
  const normalizeForm = (e) => {
    if (e?.file?.status === 'removed') {
      return undefined;
    } else if (e?.file?.status === 'done') {
      return e?.file?.response;
    } else {
      return 'error';
    }
  };

  const auctionEndDate = (current) => {
    const now = new Date();
    const released =
      collectible?.releases?.[0]?.soldCount < 1 &&
      collectible?.releases?.[0]?.saleEndDate < now.toISOString();

    const startDate = released ? now.toISOString() : doReleaseDate;
    if (current < moment(startDate).add(1, 'days') || current > moment(startDate).add(8, 'days')) {
      return true;
    }
    return false;
  };

  const handleInsideStoryChange = (editor) => {
    setInsideStory(editor.getHTML());
  };

  const handleWhatsIncludedOriginalChange = (editor) => {
    setWhatsIncludedOriginal(editor.getHTML());
  };
  const handleWhatsIncludedNumberedChange = (editor) => {
    setWhatsIncludedNumbered(editor.getHTML());
  };

  const normalizeInsideStory = (editor) => {
    setInsideStoryLength(editor.getLength().toString());
    const contentText = editor.getText();
    if (contentText && contentText !== '\n') {
      return contentText;
    }
    return undefined;
  };

  const handleDoReleaseDate = (date) => {
    setDoReleaseDate(date);
    const { releases } = form.getFieldsValue();
    releases[0].saleEndDate = moment(date).add(8, 'days');
    form.setFieldsValue({ releases });
  };

  const handleCreateArtist = async (inputValue) => {
    await createArtist({ name: inputValue });
  };

  useEffect(() => {
    const updateSlug = async (title, collectible) => {
      if (title) {
        const slug = slugify(title, {
          lower: true,
          remove: /[*+~%<>/;.(){}?,'"!:@#^|]/g
        });
        let resp = {
          slug: collectible?.slug
        };
        if ((!collectible || slug !== collectible?.slug) && !disabled) {
          resp = await isSlugAvailable(slug);
        }
        const numberedIndex = collectible?.releases?.length - 1;
        setPreviewData({
          ...previewData,
          slug: resp.slug,
          listingType: collectible?.releases
            ? collectible?.releases?.[0].listingType
            : 'FIXED_PRICE',
          releaseCount: collectible?.releases
            ? collectible?.releases?.[numberedIndex]?.releaseCount
            : 1
        });
        setExternalStreaming({
          spotify: collectible?.streamUrl?.spotify,
          appleMusic: collectible?.streamUrl?.appleMusic,
          youtube: collectible?.streamUrl?.youtube
        });
        form.setFieldsValue({ slug: resp.slug });
        setWidth(slugRef?.current?.offsetWidth);
      }
    };
    const timeOutId = setTimeout(() => updateSlug(previewData?.title, collectible), 500);
    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewData.title, collectible, promoEnabled]);

  useEffect(() => {
    setPreviewData({
      ...previewData,
      creator: profile?.username
        ? profile
        : { username: process.env.REACT_APP_NAME === 'patrons' ? 'artist' : 'creator' }
    });

    if (profile) {
      const artistName = profile?.displayName || `${profile?.firstName} ${profile?.lastName}`;

      let changed = false;
      for (const artist of options) {
        if (artist.label === artistName) {
          const value = [artist];
          onChangeArtists(value);
          setFeaturedArtist(true);
          if (value.length > 0) {
            setFeaturedArtistError(false);
          }
          changed = true;
          break;
        }
      }

      if (!changed) {
        createArtist({ name: artistName, reset: true });
      }
    }
  }, [profile]); // eslint-disable-line

  useEffect(() => {
    if (channels) {
      const listChannel = channels.map((x) => ({
        label: x.name,
        value: x.id
      }));
      setListChannel(listChannel);
      if (profileID === collectible.userID) {
        setOriginalChannelValue(collectible?.perksOriginal?.fanClubAccess);
        setLimitedChannelValue(collectible?.perksNumbered?.fanClubAccess);
      } else {
        setOriginalChannelValue([]);
        setLimitedChannelValue([]);
      }
    }
  }, [channels]); // eslint-disable-line

  useEffect(() => {
    if (collectible) {
      setAssetUrl(collectible?.assetUrl);
      setMediaThumbnail(collectible?.mediaThumbnail);
      const numberedIndex = collectible?.promoEnabled === 'TRUE' ? 0 : 1;
      setPreviewData({
        title: collectible?.title,
        slug: collectible?.slug,
        creator: collectible?.creator,
        lowestPrice: collectible?.searchableLowestPrice,
        highestPrice: collectible?.searchableHighestPrice,
        listingType: collectible?.releases ? collectible?.releases?.[0].listingType : 'FIXED_PRICE',
        releaseCount: collectible?.releases
          ? collectible?.releases?.[numberedIndex]?.releaseCount
          : 1,
        auctionEndDate: collectible?.releases ? collectible?.releases?.[0].saleEndDate : null,
        category: collectible?.category
      });
      setItems(collectible?.faq || []);
      setCountItems(collectible?.faq?.length || 0 + 1);
      setProfileID(collectible?.userID);
      setDoReleaseDate(collectible?.releases?.[0]?.releaseDate);
      setInsideStory(collectible?.insideStory);
      setWhatsIncludedOriginal(collectible?.whatsIncludedOriginal);
      setWhatsIncludedNumbered(collectible?.whatsIncludedNumbered);
      setEnableInsideStory(collectible?.insideStory !== '' && collectible?.insideStory !== null);
      setWidth(slugRef?.current?.offsetWidth);
      setPromoEnabled(collectible?.promoEnabled === 'TRUE');
      setDigitalOriginalEnabled(numberedIndex > 0);
      setOriginalHeight(collectible?.height);
      setOriginalWidth(collectible?.width);
      setSmallHeight(collectible?.smallHeight);
      setSmallWidth(collectible?.smallWidth);
      setLargeHeight(collectible?.largeHeight);
      setLargeWidth(collectible?.largeWidth);
      setOriginalFrame(collectible?.originalFrame);
      setSmallFrame(collectible?.smallFrame);
      setDoPrintable(collectible?.printable === 'TRUE');
      setDoSmallPrint(collectible?.smallPrint === 'TRUE');
      setTimeout(() => {
        setOriginalHeight(collectible?.height);
        setOriginalWidth(collectible?.width);
        setSmallHeight(collectible?.smallHeight);
        setSmallWidth(collectible?.smallWidth);
        setLargeHeight(collectible?.largeHeight);
        setLargeWidth(collectible?.largeWidth);
      }, 1000);
    }
  }, [collectible]);

  const handlePromoEnabled = (input) => {
    const releases = form.getFieldValue('releases');
    if (!input) {
      releases.unshift({
        tierType: 'DIGITAL_ORIGINAL',
        listingType: 'AUCTION',
        releaseCount: 1
      });
      form.setFieldsValue({ releases });
    }

    if (input) {
      releases.shift();
      form.setFieldsValue({ releases });
    }
    setPromoEnabled(input);
  };

  const now = new Date();
  const DragHandle = sortableHandle(() => (
    <span>
      <MenuOutlined />
    </span>
  ));

  const updateItems = (fieldValue, field, key) => {
    const newData = items.map((item) => {
      if (item.key === key) {
        if (field === 'question') {
          const title = fieldValue;
          return { ...item, title };
        } else {
          const content = fieldValue;
          return { ...item, content };
        }
      } else {
        return item;
      }
    });
    setItems(newData);
  };

  const deleteFaq = useCallback(
    (key) => {
      const newItems = items.filter((item) => item.key !== key);
      setItems(newItems);
    },
    [items]
  );

  const addOpenFaq = useCallback(
    (key) => {
      const newValue = openFaq.concat([key]);
      setOpenFaq(newValue);
    },
    [openFaq]
  );

  const deleteOpenFaq = useCallback(
    (key) => {
      const newValue = openFaq.filter((x) => x !== key);
      setOpenFaq(newValue);
    },
    [openFaq]
  );

  const SortableItem = sortableElement(({ value }) => {
    return (
      <div className="faq-collapse">
        <Collapse
          expandIconPosition="right"
          bordered={false}
          style={{ marginTop: '2em' }}
          activeKey={openFaq.includes(value.key) ? ['1'] : []}
        >
          <Panel
            header={
              <>
                <DragHandle /> <div style={{ marginLeft: '1em' }}>Question Box</div>
                {openFaq.includes(value.key) ? (
                  <div onClick={() => deleteOpenFaq(value.key)} style={{ marginLeft: 'auto' }}>
                    <DownOutlined />
                  </div>
                ) : (
                  <>
                    <div onClick={() => addOpenFaq(value.key)} style={{ marginLeft: 'auto' }}>
                      <UpOutlined />
                    </div>
                  </>
                )}
              </>
            }
            key={1}
            showArrow={false}
          >
            <div className="faq-item">
              <Form layout="vertical" className="admin-form admin-form-collectible">
                <Form.Item label="Question" required={true}>
                  <Input
                    defaultValue={value?.title}
                    onBlur={(e) => {
                      updateItems(e.target.value, 'question', value.key);
                    }}
                    placeholder={t('collectibles.faq.placeholder.question')}
                  />
                </Form.Item>
                <Form.Item label="Answer" required={true}>
                  <TextArea
                    defaultValue={value?.content}
                    onBlur={(e) => {
                      updateItems(e.target.value, 'answer', value.key);
                    }}
                    rows={4}
                    placeholder={t('collectibles.faq.placeholder.answer')}
                  />
                </Form.Item>
                <Form.Item>
                  <Row style={{ alignItems: 'center' }}>
                    <Col span={24}>
                      <Button
                        danger
                        onClick={() => deleteFaq(value.key)}
                        style={{
                          width: '100%',
                          padding: '10px 0'
                        }}
                      >
                        {t('collectibles.faq.delete')}
                      </Button>
                    </Col>
                  </Row>
                  <Form.ErrorList />
                </Form.Item>
              </Form>
            </div>
          </Panel>
        </Collapse>
      </div>
    );
  });

  const SortableContainer = sortableContainer(({ children }) => {
    return <ul>{children}</ul>;
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems(arrayMoveImmutable(items, oldIndex, newIndex));
  };

  const addFaq = useCallback(() => {
    const newFaq = {
      key: countItem,
      title: '',
      content: ''
    };
    setItems(items.concat(newFaq));
    setCountItems(countItem + 1);
  }, [items, countItem]);

  const creatorLabel =
    process.env.REACT_APP_NAME === 'patrons'
      ? t('collectibles.fields.creator.patrons.label')
      : t('collectibles.fields.creator.label');

  const creatorRequired =
    process.env.REACT_APP_NAME === 'patrons'
      ? t('collectibles.fields.creator.patrons.required')
      : t('collectibles.fields.creator.required');

  const creatorPlaceholder =
    process.env.REACT_APP_NAME === 'patrons'
      ? t('collectibles.fields.creator.patrons.placeholder')
      : t('collectibles.fields.creator.placeholder');

  const creatorRoyalties =
    process.env.REACT_APP_NAME === 'patrons'
      ? t('collectibles.previewSales.patrons.creatorRoyalties')
      : t('collectibles.previewSales.creatorRoyalties');

  const creatorRoyaltiesTitle =
    process.env.REACT_APP_NAME === 'patrons'
      ? t('collectibles.previewSales.patrons.creatorRoyaltiesInfo')
      : t('collectibles.previewSales.creatorRoyaltiesInfo');

  return (
    <>
      <DashboardContent title={t('collectibles.edit.title')} backLink="/collectibles">
        {!collectible ||
        loading ||
        !artistByCollectible ||
        artistLoading ||
        !collections ||
        !user ||
        smallWidth === undefined ||
        largeWidth === undefined ||
        smallHeight === undefined ||
        largeHeight === undefined ||
        collectionsLoading ? null : (
          <Form
            onFinish={() => handleSubmit(true)}
            form={form}
            layout="vertical"
            className="admin-form admin-form-collectible"
            initialValues={initialData}
            onFieldsChange={(_, fields) => {
              triggerChanges(fields);
              setIsDirty(true);
            }}
            disabled={disabled}
          >
            <Row gutter={30}>
              <Col span={24} xl={18} lg={16} className="collectible-form-left edit-collectible">
                <Row gutter={30} style={{ height: '100%' }}>
                  <Col span={24}>
                    <Form.Item
                      className="collectible-form-title"
                      name="title"
                      rules={[
                        { required: true, message: t('collectibles.fields.title.required') },
                        {
                          min: 3,
                          message: (
                            <Trans i18nKey="collectibles.fields.title.minLength">
                              {titleLength}
                            </Trans>
                          )
                        },
                        {
                          max: 100,
                          message: (
                            <Trans i18nKey="collectibles.fields.title.maxLength">
                              {titleLength}
                            </Trans>
                          )
                        }
                      ]}
                    >
                      <Input
                        autoFocus
                        bordered={false}
                        placeholder={t('collectibles.fields.title.placeholder')}
                        onChange={(e) => {
                          setPreviewData({
                            ...previewData,
                            title: e.target.value,
                            creator: profile?.username
                              ? profile
                              : {
                                  username:
                                    process.env.REACT_APP_NAME === 'patrons' ? 'artist' : 'creator'
                                }
                          });
                          setTitleLength(e.target.value.length.toString());
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      className="collectible-form-slug"
                      name="slug"
                      rules={[
                        { required: true, message: t('collectibles.fields.slug.required') },
                        {
                          pattern: /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
                          message: t('collectibles.fields.slug.invalid')
                        }
                      ]}
                    >
                      <span ref={slugRef} style={{ visibility: 'hidden', position: 'absolute' }}>
                        {previewData?.slug}
                      </span>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span className="form-slug-label">
                          {process.env.REACT_APP_NAME === 'patrons'
                            ? `patrons.art/${previewData.creator.username}/`
                            : `fans.inc/${previewData.creator.username}/`}
                        </span>
                        <Input
                          style={{ width, marginRight: 5 }}
                          bordered={false}
                          placeholder={t('collectibles.fields.slug.placeholder')}
                          disabled={!editSlug || disabled}
                          onChange={(e) => {
                            setPreviewData({
                              ...previewData,
                              slug: e.target.value
                            });
                          }}
                          value={previewData?.slug}
                        />
                        {editSlug ? (
                          <div style={{ display: 'flex' }}>
                            <CheckOutlined
                              width={12.75}
                              height={12.75}
                              style={{
                                cursor: 'pointer',
                                marginRight: '0.625rem'
                              }}
                              onClick={async () => {
                                setEditSlug(false);
                                const { slug } = await isSlugAvailable(previewData.slug);
                                setPreviewData({
                                  ...previewData,
                                  slug
                                });
                                form.setFieldsValue({ slug });
                                setWidth(slugRef.current.offsetWidth);
                              }}
                            />
                            <CloseOutlined
                              width={9.89}
                              height={10.19}
                              style={{
                                cursor: 'pointer'
                              }}
                              onClick={() => {
                                setEditSlug(false);
                              }}
                            />
                          </div>
                        ) : (
                          <EditFilled
                            width={16}
                            height={16}
                            onClick={() => {
                              if (!disabled) {
                                setEditSlug(true);
                              }
                            }}
                            style={{
                              cursor: disabled ? 'not-allowed' : 'pointer'
                            }}
                          />
                        )}
                      </div>
                    </Form.Item>
                  </Col>

                  <Col span={24} style={{ height: '-webkit-fill-available' }}>
                    <Tabs
                      defaultActiveKey="1"
                      className="collectible-form-tabs"
                      style={{ height: '100%' }}
                    >
                      <TabPane
                        tab={t('collectibles.fields.group.content.heading')}
                        key="1"
                        forceRender
                      >
                        <Row gutter={16}>
                          {!user?.type === 'Artist' && (
                            <Col span={24}>
                              <Form.Item
                                label={creatorLabel}
                                name="userID"
                                rules={[
                                  {
                                    required: true,
                                    message: creatorRequired
                                  }
                                ]}
                              >
                                <Select
                                  placeholder={t('collectibles.fields.creator.placeholder')}
                                  filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  }
                                  onChange={(value) => {
                                    setProfileID(value);
                                    setDoPrintable(false);
                                  }}
                                  disabled={disabled}
                                >
                                  {creators &&
                                    creators.map((creator) => (
                                      <Option
                                        key={`creator-${creator.value}`}
                                        value={creator.value}
                                      >
                                        {creator.label}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </Col>
                          )}
                          <Col span={24}>
                            <Form.Item
                              label={t('collectibles.fields.type.label')}
                              name="category"
                              rules={[
                                { required: true, message: t('collectibles.fields.type.required') }
                              ]}
                            >
                              <Select
                                placeholder={creatorPlaceholder}
                                filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(value) => {
                                  setPreviewData({
                                    ...previewData,
                                    category: value
                                  });
                                }}
                              >
                                {categories &&
                                  categories.map((category) => (
                                    <Option key={`type-${category.value}`} value={category.value}>
                                      {category.name}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          {form.getFieldValue('category') === 'SONG' && (
                            <Col span={24}>
                              <Form.Item
                                name="streamExternal"
                                label={t('collectibles.fields.streamExternal.label')}
                                valuePropName="checked"
                              >
                                <Switch
                                  checkedChildren={t('global.switch.yes')}
                                  unCheckedChildren={t('global.switch.no')}
                                />
                              </Form.Item>
                            </Col>
                          )}

                          {!form.getFieldValue('streamExternal') ? (
                            <Col span={24} md={12}>
                              <Form.Item
                                label={t('collectibles.fields.media.label')}
                                name="assetUrl"
                                valuePropName="file"
                                getValueFromEvent={normalizeForm}
                                rules={[
                                  {
                                    required: true,
                                    message: t('collectibles.fields.media.required')
                                  },
                                  {
                                    min: 6,
                                    message: t('collectibles.fields.media.error')
                                  }
                                ]}
                              >
                                <Dragger
                                  {...draggerProps(handleUploadAsset, 'collectibles', profileID)}
                                  defaultFileList={[defaultFileList(collectible?.assetUrl)]}
                                >
                                  <p className="ant-upload-drag-icon">
                                    <UploadOutlined />
                                  </p>
                                  <p className="ant-upload-text" style={{ fontWeight: '600' }}>
                                    {t('collectibles.fields.media.text')}
                                  </p>
                                  <p className="ant-upload-hint">
                                    {t('collectibles.fields.media.hint')}
                                  </p>
                                </Dragger>
                              </Form.Item>
                            </Col>
                          ) : (
                            <Col span={24}>
                              <Form.Item
                                rules={[
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (
                                        !getFieldValue('streamExternal') ||
                                        getFieldValue('streamSpotify') ||
                                        getFieldValue('streamAppleMusic') ||
                                        getFieldValue('streamYoutube')
                                      ) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(
                                        new Error(t('collectibles.fields.streamExternal.required'))
                                      );
                                    }
                                  })
                                ]}
                                name="streamUrl"
                              >
                                <Col span={24}>
                                  <Form.Item
                                    label={t('collectibles.fields.streamExternal.spotify.label')}
                                    name="streamSpotify"
                                    onChange={(e) => {
                                      setExternalStreaming({
                                        ...externalStreaming,
                                        spotify: e.target.value
                                      });
                                    }}
                                  >
                                    <Input
                                      placeholder={t(
                                        'collectibles.fields.streamExternal.spotify.placeholder'
                                      )}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={24}>
                                  <Form.Item
                                    label={t('collectibles.fields.streamExternal.appleMusic.label')}
                                    name="streamAppleMusic"
                                    onChange={(e) => {
                                      setExternalStreaming({
                                        ...externalStreaming,
                                        appleMusic: e.target.value
                                      });
                                    }}
                                  >
                                    <Input
                                      placeholder={t(
                                        'collectibles.fields.streamExternal.appleMusic.placeholder'
                                      )}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={24}>
                                  <Form.Item
                                    label={t('collectibles.fields.streamExternal.youtube.label')}
                                    name="streamYoutube"
                                    onChange={(e) => {
                                      setExternalStreaming({
                                        ...externalStreaming,
                                        youtube: e.target.value
                                      });
                                    }}
                                  >
                                    <Input
                                      placeholder={t(
                                        'collectibles.fields.streamExternal.youtube.placeholder'
                                      )}
                                    />
                                  </Form.Item>
                                </Col>
                              </Form.Item>
                            </Col>
                          )}

                          <Col span={24} md={!form.getFieldValue('streamExternal') ? 12 : 24}>
                            <Form.Item
                              label={t('collectibles.fields.thumbnail.label')}
                              name="mediaThumbnail"
                              valuePropName="file"
                              getValueFromEvent={normalizeForm}
                              rules={[
                                {
                                  required: true,
                                  message: t('collectibles.fields.thumbnail.required')
                                },
                                {
                                  min: 6,
                                  message: t('collectibles.fields.thumbnail.error')
                                }
                              ]}
                            >
                              <Dragger
                                {...draggerProps(handleUploadThumbnail, 'collectible_thumbnails')}
                                defaultFileList={[defaultFileList(collectible?.mediaThumbnail)]}
                              >
                                <p className="ant-upload-drag-icon">
                                  <UploadOutlined />
                                </p>
                                <p className="ant-upload-text" style={{ fontWeight: '600' }}>
                                  {t('collectibles.fields.thumbnail.text')}
                                </p>
                                <p className="ant-upload-hint">
                                  {t('collectibles.fields.thumbnail.hint')}
                                </p>
                              </Dragger>
                            </Form.Item>

                            <Modal
                              className="image-preview-field__modal"
                              title={t('collectibles.fields.thumbnail.label')}
                              visible={thumbnailModal}
                              width={600}
                              footer={null}
                              onCancel={() => {
                                setThumbnailModal(false);
                              }}
                            >
                              {thumbnailFile?.imageUrl ? (
                                <Cropper
                                  loading={imageResizeLoading}
                                  image={thumbnailFile.imageUrl}
                                  onComplete={async (croppedArea) => {
                                    const resized = await onResize({
                                      key: thumbnailFile.key,
                                      ...croppedArea,
                                      folder: 'collectible_thumbnails'
                                    });

                                    if (resized) {
                                      setMediaThumbnail(resized?.key);
                                    }

                                    setThumbnailFile({});
                                    setThumbnailModal(false);
                                  }}
                                  onCancel={() => {
                                    setThumbnailModal(false);
                                  }}
                                />
                              ) : null}
                            </Modal>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              label={t('collectibles.fields.description.label')}
                              name="description"
                              rules={[
                                {
                                  required: true,
                                  message: t('collectibles.fields.description.required')
                                },
                                {
                                  min: 50,
                                  message: (
                                    <Trans i18nKey="collectibles.fields.description.minLength">
                                      {descriptionLength}
                                    </Trans>
                                  )
                                },
                                {
                                  max: 1000,
                                  message: (
                                    <Trans i18nKey="collectibles.fields.description.maxLength">
                                      {descriptionLength}
                                    </Trans>
                                  )
                                }
                              ]}
                            >
                              <TextArea
                                placeholder={t('collectibles.fields.description.placeholder')}
                                rows={4}
                                onChange={(e) => {
                                  setDescriptionLength(e.target.value.length.toString());
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={30}>
                          <Col span={24}>
                            <Form.Item
                              label={t('collectibles.fields.promo.option')}
                              name="promoEnabled"
                              valuePropName="checked"
                            >
                              <Switch
                                checkedChildren={t('global.switch.yes')}
                                unCheckedChildren={t('global.switch.no')}
                                onChange={handlePromoEnabled}
                                defaultChecked={promoEnabled}
                                disabled={disabled}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={24}>
                            <Form.Item
                              label={t('collectibles.fields.promo.title.label')}
                              name="promoTitle"
                              rules={[
                                {
                                  required: promoEnabled,
                                  message: t('collectibles.fields.promo.title.required')
                                }
                              ]}
                              hidden={!promoEnabled}
                            >
                              <Input placeholder={t('collectibles.fields.promo.title.required')} />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              label={t('collectibles.fields.promo.description.label')}
                              name="promoDescription"
                              rules={[
                                {
                                  required: promoEnabled,
                                  message: t('collectibles.fields.promo.description.required')
                                }
                              ]}
                              hidden={!promoEnabled}
                            >
                              <TextArea
                                rows={4}
                                placeholder={t('collectibles.fields.promo.description.placeholder')}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tab={t('collectibles.fields.group.attributes')} key="2" forceRender>
                        <Row gutter={16}>
                          <Col span={24}>
                            <Form.Item
                              name="featuredInProfile"
                              label={t('collectibles.fields.featured.label')}
                              valuePropName="checked"
                            >
                              <Switch
                                checkedChildren={t('global.switch.yes')}
                                unCheckedChildren={t('global.switch.no')}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              label={t('collectibles.fields.genre.label')}
                              name="genre"
                              rules={[
                                { required: true, message: t('collectibles.fields.genre.required') }
                              ]}
                            >
                              <Select
                                mode="multiple"
                                placeholder={t('collectibles.fields.genre.placeholder')}
                                filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {genres &&
                                  genres.map((genre) => (
                                    <Option key={`genre-${genre.value}`} value={genre.value}>
                                      {genre.name}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              label={t('collectibles.fields.yearOfCreation.label')}
                              name="yearOfCreation"
                              rules={[
                                {
                                  required: true,
                                  message: t('collectibles.fields.yearOfCreation.required')
                                }
                              ]}
                            >
                              <InputNumber
                                placeholder={t('collectibles.fields.yearOfCreation.label')}
                                className="block"
                                controls={false}
                                max={moment().year()}
                              />
                            </Form.Item>
                          </Col>
                          <Col className="select-creatable-artist" flex={1} span={24}>
                            <Form.Item
                              label={t('collectibles.fields.featuredArtist.label')}
                              className="required"
                            >
                              <Creatable
                                onCreate={handleCreateArtist}
                                placeholder={t('collectibles.fields.featuredArtist.placeholder')}
                                onChange={(value) => {
                                  onChangeArtists(value);
                                  setFeaturedArtist(true);
                                  if (value.length > 0) {
                                    setFeaturedArtistError(false);
                                  }
                                }}
                                options={options}
                                value={artistsValue}
                                error={
                                  (artistsValue.length < 1 && featuredArtist) || featuredArtistError
                                    ? t('collectibles.fields.featuredArtist.required')
                                    : null
                                }
                              />
                            </Form.Item>
                          </Col>
                          {!collectionAccount?.id && (
                            <Col span={24}>
                              <Form.Item
                                label={t('collectibles.fields.collection.label')}
                                name="collectionID"
                              >
                                <Select
                                  placeholder={t('collectibles.fields.collection.placeholder')}
                                  filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {collections.map((collection) => (
                                    <Option
                                      key={`collection-${collection.id}`}
                                      value={collection.id}
                                    >
                                      {collection.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                          )}
                        </Row>
                      </TabPane>
                      <TabPane tab={t('collectibles.fields.group.releases')} key="3" forceRender>
                        {process.env.REACT_APP_NAME !== 'patrons' && (
                          <Row gutter={30}>
                            <Col span={24}>
                              <Form.Item
                                label={t('collectibles.fields.group.digitalOriginal.heading')}
                                name="digitalOriginalEnabled"
                              >
                                <Switch
                                  checkedChildren={t('global.switch.on')}
                                  unCheckedChildren={t('global.switch.off')}
                                  onChange={(checked) => {
                                    setDigitalOriginalEnabled(checked);
                                  }}
                                  checked={digitalOriginalEnabled}
                                  disabled={promoEnabled | disabled}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        )}
                        {!promoEnabled &&
                          digitalOriginalEnabled &&
                          process.env.REACT_APP_NAME !== 'patrons' && (
                            <FormGroup
                              heading={t('collectibles.fields.group.digitalOriginal.heading')}
                              description={t(
                                'collectibles.fields.group.digitalOriginal.description'
                              )}
                            >
                              <Form.Item
                                className="admin-form-hidden"
                                value="DIGITAL_ORIGINAL"
                                name={['releases', 0, 'tierType']}
                              >
                                <Input value="DIGITAL_ORIGINAL" hidden />
                              </Form.Item>
                              <Form.Item
                                className="admin-form-hidden"
                                value="1"
                                name={['releases', 0, 'releaseCount']}
                              >
                                <InputNumber value={1} hidden />
                              </Form.Item>
                              <Row gutter={16}>
                                <Col span={24}>
                                  <Form.Item
                                    name={['releases', 0, 'listingType']}
                                    label={t('collectibles.fields.listingType.label')}
                                    rules={[
                                      {
                                        required: true,
                                        message: t('collectibles.fields.listingType.required')
                                      }
                                    ]}
                                  >
                                    <Select
                                      placeholder={t('collectibles.fields.listingType.placeholder')}
                                      disabled={disabled}
                                      defaultValue="AUCTION"
                                    >
                                      <Option value="AUCTION">
                                        {t('collectibles.fields.listingType.options.auction')}
                                      </Option>
                                      <Option value="FIXED_PRICE">
                                        {t('collectibles.fields.listingType.options.fixedPrice')}
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                {doListingType === 'AUCTION' && (
                                  <>
                                    <Col span={24}>
                                      <Form.Item
                                        name={['releases', 0, 'setStartingPrice']}
                                        label={t('collectibles.fields.startingPrice.toggle.label')}
                                        extra={t('collectibles.fields.startingPrice.toggle.extra')}
                                      >
                                        <Switch
                                          checkedChildren={t('global.switch.yes')}
                                          unCheckedChildren={t('global.switch.no')}
                                          valuePropName="checked"
                                          defaultChecked={doSetStartingPrice}
                                        />
                                      </Form.Item>
                                    </Col>
                                    {doSetStartingPrice && (
                                      <Col span={24}>
                                        <Form.Item
                                          name={['releases', 0, 'startingPrice']}
                                          label={t('collectibles.fields.startingPrice.label')}
                                          placeholder={t(
                                            'collectibles.fields.startingPrice.placeholder'
                                          )}
                                          extra={t('collectibles.fields.startingPrice.extra')}
                                          rules={[
                                            {
                                              required: true,
                                              message: t(
                                                'collectibles.fields.startingPrice.required'
                                              )
                                            }
                                          ]}
                                        >
                                          <InputNumber
                                            controls={false}
                                            decimalSeparator="."
                                            formatter={(value) =>
                                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                            }
                                            parser={(value) =>
                                              value.replace(/\$\s?|(,*)/g, '').replace('.', '')
                                            }
                                            className="block"
                                            disabled={disabled}
                                          />
                                        </Form.Item>
                                      </Col>
                                    )}
                                    <Col span={24}>
                                      <Form.Item
                                        name={['releases', 0, 'setFinalPrice']}
                                        label={t('collectibles.fields.reservePrice.toggle.label')}
                                        extra={t('collectibles.fields.reservePrice.toggle.extra')}
                                      >
                                        <Switch
                                          checkedChildren={t('global.switch.yes')}
                                          unCheckedChildren={t('global.switch.no')}
                                          valuePropName="checked"
                                          defaultChecked={doSetReservePrice}
                                        />
                                      </Form.Item>
                                    </Col>
                                    {doSetReservePrice && (
                                      <Col span={24}>
                                        <Form.Item
                                          label={t('collectibles.fields.reservePrice.label')}
                                          placeholder={t(
                                            'collectibles.fields.reservePrice.placeholder'
                                          )}
                                          name={['releases', 0, 'finalPrice']}
                                          dependencies={['releases', 0, 'startingPrice']}
                                          rules={[
                                            {
                                              required: true,
                                              message: t(
                                                'collectibles.fields.reservePrice.required'
                                              )
                                            },
                                            ({ getFieldValue }) => ({
                                              async validator(_, value) {
                                                if (
                                                  parseFloat(
                                                    getFieldValue(['releases', 0, 'startingPrice'])
                                                  ) > parseFloat(value)
                                                ) {
                                                  throw Error(
                                                    t('collectibles.fields.reservePrice.lower')
                                                  );
                                                }
                                              }
                                            })
                                          ]}
                                        >
                                          <InputNumber
                                            controls={false}
                                            decimalSeparator="."
                                            formatter={(value) =>
                                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                            }
                                            parser={(value) =>
                                              value.replace(/\$\s?|(,*)/g, '').replace('.', '')
                                            }
                                            className="block"
                                            disabled={disabled}
                                          />
                                        </Form.Item>
                                      </Col>
                                    )}
                                  </>
                                )}
                                {doListingType === 'FIXED_PRICE' && (
                                  <Col span={24}>
                                    <Form.Item
                                      name={['releases', 0, 'startingPrice']}
                                      label={t('collectibles.fields.sellingPrice.label')}
                                      rules={[
                                        {
                                          required: true,
                                          message: t('collectibles.fields.sellingPrice.required')
                                        }
                                      ]}
                                    >
                                      <InputNumber
                                        placeholder={t(
                                          'collectibles.fields.sellingPrice.placeholder'
                                        )}
                                        controls={false}
                                        decimalSeparator="."
                                        formatter={(value) =>
                                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                        }
                                        parser={(value) =>
                                          value.replace(/\$\s?|(,*)/g, '').replace('.', '')
                                        }
                                        className="block"
                                        disabled={disabled}
                                      />
                                    </Form.Item>
                                  </Col>
                                )}
                                {doListingType === 'MAKE_AN_OFFER' && (
                                  <>
                                    <Col span={24}>
                                      <Form.Item
                                        name={['releases', 0, 'setStartingPrice']}
                                        label={t(
                                          'collectibles.fields.minimumOfferPrice.toggle.label'
                                        )}
                                        extra={t(
                                          'collectibles.fields.minimumOfferPrice.toggle.extra'
                                        )}
                                      >
                                        <Switch
                                          checkedChildren={t('global.switch.yes')}
                                          unCheckedChildren={t('global.switch.no')}
                                          defaultChecked={doSetStartingPrice}
                                        />
                                      </Form.Item>
                                    </Col>
                                    {doSetStartingPrice && (
                                      <>
                                        <Col span={24}>
                                          <Form.Item
                                            name={['releases', 0, 'startingPrice']}
                                            label={t('collectibles.fields.minimumOfferPrice.label')}
                                            placeholder={t(
                                              'collectibles.fields.minimumOfferPrice.placeholder'
                                            )}
                                            rules={[
                                              {
                                                required: true,
                                                message: t(
                                                  'collectibles.fields.minimumOfferPrice.required'
                                                )
                                              }
                                            ]}
                                          >
                                            <InputNumber
                                              controls={false}
                                              decimalSeparator="."
                                              formatter={(value) =>
                                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                              }
                                              parser={(value) =>
                                                value.replace(/\$\s?|(,*)/g, '').replace('.', '')
                                              }
                                              className="block"
                                              disabled={disabled}
                                            />
                                          </Form.Item>
                                        </Col>
                                      </>
                                    )}
                                  </>
                                )}
                                <Col span={24}>
                                  <Form.Item
                                    label={t('collectibles.fields.releaseDate.label')}
                                    extra={t('collectibles.fields.releaseDate.extra')}
                                    name={['releases', 0, 'releaseDate']}
                                    rules={[
                                      {
                                        required: true,
                                        message: t('collectibles.fields.releaseDate.required')
                                      }
                                    ]}
                                  >
                                    <DatePicker
                                      disabledDate={(current) => {
                                        return current && current < moment();
                                      }}
                                      onChange={(date) => {
                                        handleDoReleaseDate(date);
                                      }}
                                      format="DD MMMM YYYY H:mm"
                                      showTime={{ format: 'HH:mm' }}
                                      disabled={disabled}
                                    />
                                  </Form.Item>
                                </Col>
                                {doListingType === 'AUCTION' && (
                                  <Col span={24}>
                                    <Form.Item
                                      name={['releases', 0, 'saleEndDate']}
                                      label={t('collectibles.fields.auctionEndDate.label')}
                                      extra={t('collectibles.fields.auctionEndDate.extra')}
                                      rules={[
                                        {
                                          required: true,
                                          message: t('collectibles.fields.auctionEndDate.required')
                                        }
                                      ]}
                                    >
                                      <DatePicker
                                        disabledDate={auctionEndDate}
                                        value={moment(doReleaseDate).add(1, 'days')}
                                        format="DD MMMM YYYY H:mm"
                                        showTime={{ format: 'HH:mm' }}
                                        disabled={
                                          collectible?.releases?.[0]?.soldCount < 1 &&
                                          collectible?.releases?.[0]?.saleEndDate <
                                            now.toISOString()
                                            ? false
                                            : disabled
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                )}
                              </Row>
                            </FormGroup>
                          )}

                        <FormGroup
                          heading={t('collectibles.fields.group.numberedEdition.heading')}
                          description={
                            process.env.REACT_APP_NAME === 'patrons'
                              ? ''
                              : t('collectibles.fields.group.numberedEdition.description')
                          }
                        >
                          <Row gutter={30}>
                            <Form.Item
                              className="admin-form-hidden"
                              value="NUMBERED_EDITION"
                              name={['releases', numberedIndex, 'tierType']}
                            >
                              <Input value="NUMBERED_EDITION" hidden />
                            </Form.Item>
                            <Form.Item
                              className="admin-form-hidden"
                              value="FIXED_PRICE"
                              name={['releases', numberedIndex, 'listingType']}
                            >
                              <Input value="FIXED_PRICE" hidden />
                            </Form.Item>
                            <Col span={24}>
                              <Form.Item
                                fieldKey={['releases', numberedIndex, 'startingPrice']}
                                name={['releases', numberedIndex, 'startingPrice']}
                                label={t('collectibles.fields.sellingPrice.label')}
                                rules={[
                                  {
                                    required: true,
                                    message: t('collectibles.fields.sellingPrice.required')
                                  }
                                ]}
                              >
                                <InputNumber
                                  placeholder={t('collectibles.fields.sellingPrice.placeholder')}
                                  controls={false}
                                  decimalSeparator="."
                                  formatter={(value) =>
                                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                  }
                                  parser={(value) =>
                                    value.replace(/\$\s?|(,*)/g, '').replace('.', '')
                                  }
                                  className="block"
                                  disabled={disabled}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                fieldKey={['releases', numberedIndex, 'releaseCount']}
                                name={['releases', numberedIndex, 'releaseCount']}
                                label={t('collectibles.fields.releaseQuantity.label')}
                                placeholder={t('collectibles.fields.releaseQuantity.placeholder')}
                                rules={[
                                  {
                                    required: true,
                                    message: t('collectibles.fields.releaseQuantity.required')
                                  }
                                ]}
                              >
                                <InputNumber
                                  className="block"
                                  min={1}
                                  defaultValue={1}
                                  disabled={disabled}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                fieldKey={['releases', numberedIndex, 'releaseDate']}
                                name={['releases', numberedIndex, 'releaseDate']}
                                label={t('collectibles.fields.releaseDate.label')}
                                extra={t('collectibles.fields.releaseDate.extra')}
                                rules={[
                                  {
                                    required: true,
                                    message: t('collectibles.fields.releaseDate.required')
                                  }
                                ]}
                              >
                                <DatePicker
                                  disabledDate={(current) => {
                                    return current && current < moment();
                                  }}
                                  format="DD MMMM YYYY H:mm"
                                  showTime={{ format: 'HH:mm' }}
                                  disabled={disabled}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </FormGroup>
                      </TabPane>
                      <TabPane tab={t('collectibles.fields.group.unlockables')} key="4" forceRender>
                        <Row gutter={30}>
                          <Col span={24}>
                            <Form.Item
                              label={t('collectibles.fields.insideStory.label')}
                              extra={t('collectibles.fields.insideStory.extra')}
                              name="enableInsideStory"
                            >
                              <Switch
                                checkedChildren={t('global.switch.yes')}
                                unCheckedChildren={t('global.switch.no')}
                                onChange={(checked) => {
                                  setEnableInsideStory(checked);
                                }}
                                defaultChecked={enableInsideStory}
                              />
                            </Form.Item>
                          </Col>
                          {enableInsideStory && (
                            <Col span={24}>
                              <Form.Item
                                getValueFromEvent={normalizeInsideStory}
                                name="insideStory"
                                rules={[
                                  {
                                    required: true,
                                    message: t('collectibles.fields.insideStory.required')
                                  },
                                  {
                                    min: 8,
                                    message: (
                                      <Trans i18nKey="collectibles.fields.insideStory.minLength">
                                        {insideStoryLength}
                                      </Trans>
                                    )
                                  },
                                  {
                                    max: 1000,
                                    message: (
                                      <Trans i18nKey="collectibles.fields.insideStory.maxLength">
                                        {insideStoryLength}
                                      </Trans>
                                    )
                                  }
                                ]}
                              >
                                <RichTextEditor
                                  placeholder={t('collectibles.fields.insideStory.placeholder')}
                                  onChange={handleInsideStoryChange}
                                  onBlur={handleInsideStoryChange}
                                  disabled={disabled}
                                  defaultValue={insideStory}
                                />
                              </Form.Item>
                            </Col>
                          )}
                          <Col span={24}>
                            <Tabs defaultActiveKey="1">
                              {!promoEnabled && digitalOriginalEnabled && (
                                <TabPane
                                  tab={t('collectibles.fields.group.digitalOriginal.heading')}
                                  key="1"
                                  forceRender
                                >
                                  <Row gutter={30}>
                                    <Col span={24}>
                                      <Form.Item
                                        label={t('collectibles.fields.fanClubAccess.label')}
                                      >
                                        <Select
                                          mode="multiple"
                                          placeholder={t(
                                            'collectibles.fields.fanClubAccess.placeholder'
                                          )}
                                          value={originalChannelValue}
                                          onChange={(e) => {
                                            setOriginalChannelValue(e);
                                          }}
                                          disabled={disabled}
                                        >
                                          {listChannel &&
                                            listChannel.map((channel) => (
                                              <Option
                                                key={`channel-${channel.value}`}
                                                value={channel.value}
                                              >
                                                {channel.label}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                      <Form.Item
                                        label={t('collectibles.fields.whatsIncluded.label')}
                                        name="whatsIncludedOriginal"
                                      >
                                        <RichTextEditor
                                          defaultValue={whatsIncludedOriginal}
                                          onChange={handleWhatsIncludedOriginalChange}
                                          onBlur={handleWhatsIncludedOriginalChange}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                      <Form.List name="limitedPerksOriginal">
                                        {(fields, { add, remove }, { errors }) => {
                                          return (
                                            <>
                                              {fields.map((field, index) => (
                                                <Form.Item
                                                  {...(index === 0
                                                    ? formItemLayout
                                                    : formItemLayoutWithOutLabel)}
                                                  required={false}
                                                  key={field.key}
                                                  label={
                                                    index === 0
                                                      ? t('collectibles.fields.perks.limited')
                                                      : ''
                                                  }
                                                >
                                                  <Row style={{ alignItems: 'center' }}>
                                                    <Col span={23}>
                                                      <Form.Item
                                                        {...field}
                                                        validateTrigger={['onChange', 'onBlur']}
                                                        noStyle
                                                      >
                                                        <Input
                                                          placeholder={t(
                                                            'collectibles.fields.perks.enterContent'
                                                          )}
                                                        />
                                                      </Form.Item>
                                                    </Col>
                                                    <Col span={1} style={{ textAlign: 'center' }}>
                                                      <MinusCircleOutlined
                                                        className="dynamic-delete-button"
                                                        onClick={() => remove(field.name)}
                                                        style={{
                                                          fontSize: 20
                                                        }}
                                                      />
                                                    </Col>
                                                  </Row>
                                                </Form.Item>
                                              ))}
                                              <Form.Item>
                                                <Row style={{ alignItems: 'center' }}>
                                                  <Col span={23}>
                                                    <Button
                                                      type="dashed"
                                                      onClick={() => add()}
                                                      style={{
                                                        width: '100%',
                                                        padding: '10px 0'
                                                      }}
                                                      icon={<PlusOutlined />}
                                                    >
                                                      Add field
                                                    </Button>
                                                  </Col>
                                                </Row>
                                                <Form.ErrorList errors={errors} />
                                              </Form.Item>
                                            </>
                                          );
                                        }}
                                      </Form.List>
                                    </Col>
                                    <Col span={24}>
                                      <Form.List name="lifetimePerksOriginal">
                                        {(fields, { add, remove }, { errors }) => (
                                          <>
                                            {fields.map((field, index) => (
                                              <Form.Item
                                                {...(index === 0
                                                  ? formItemLayout
                                                  : formItemLayoutWithOutLabel)}
                                                required={false}
                                                key={field.key}
                                                label={
                                                  index === 0
                                                    ? t('collectibles.fields.perks.lifetime')
                                                    : ''
                                                }
                                              >
                                                <Row style={{ alignItems: 'center' }}>
                                                  <Col span={23}>
                                                    <Form.Item
                                                      {...field}
                                                      validateTrigger={['onChange', 'onBlur']}
                                                      noStyle
                                                    >
                                                      <Input
                                                        placeholder={t(
                                                          'collectibles.fields.perks.enterContent'
                                                        )}
                                                      />
                                                    </Form.Item>
                                                  </Col>
                                                  <Col span={1} style={{ textAlign: 'center' }}>
                                                    <MinusCircleOutlined
                                                      className="dynamic-delete-button"
                                                      onClick={() => remove(field.name)}
                                                      style={{
                                                        fontSize: 20
                                                      }}
                                                    />
                                                  </Col>
                                                </Row>
                                              </Form.Item>
                                            ))}
                                            <Form.Item>
                                              <Row style={{ alignItems: 'center' }}>
                                                <Col span={23}>
                                                  <Button
                                                    type="dashed"
                                                    onClick={() => add()}
                                                    style={{
                                                      width: '100%',
                                                      padding: '10px 0'
                                                    }}
                                                    icon={<PlusOutlined />}
                                                  >
                                                    Add field
                                                  </Button>
                                                </Col>
                                              </Row>
                                              <Form.ErrorList errors={errors} />
                                            </Form.Item>
                                          </>
                                        )}
                                      </Form.List>
                                    </Col>
                                  </Row>
                                </TabPane>
                              )}

                              <TabPane
                                tab={t('collectibles.fields.group.numberedEdition.heading')}
                                key="2"
                                forceRender
                              >
                                <Row gutter={30}>
                                  <Col span={24}>
                                    <Form.Item label={t('collectibles.fields.fanClubAccess.label')}>
                                      <Select
                                        mode="multiple"
                                        placeholder={t(
                                          'collectibles.fields.fanClubAccess.placeholder'
                                        )}
                                        value={limitedChannelValue}
                                        onChange={(e) => {
                                          setLimitedChannelValue(e);
                                        }}
                                        disabled={disabled}
                                      >
                                        {listChannel &&
                                          listChannel.map((channel) => (
                                            <Option
                                              key={`channel-${channel.value}`}
                                              value={channel.value}
                                            >
                                              {channel.label}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                  <Col span={24}>
                                    <Form.Item
                                      label={t('collectibles.fields.whatsIncluded.label')}
                                      name="whatsIncludedNumbered"
                                    >
                                      <RichTextEditor
                                        defaultValue={whatsIncludedNumbered}
                                        onChange={handleWhatsIncludedNumberedChange}
                                        onBlur={handleWhatsIncludedNumberedChange}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={24}>
                                    <Form.List name="limitedPerksNumbered">
                                      {(fields, { add, remove }, { errors }) => (
                                        <>
                                          {fields.map((field, index) => (
                                            <Form.Item
                                              {...(index === 0
                                                ? formItemLayout
                                                : formItemLayoutWithOutLabel)}
                                              required={false}
                                              key={field.key}
                                              label={
                                                index === 0
                                                  ? t('collectibles.fields.perks.limited')
                                                  : ''
                                              }
                                            >
                                              <Row style={{ alignItems: 'center' }}>
                                                <Col span={23}>
                                                  <Form.Item
                                                    {...field}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    noStyle
                                                  >
                                                    <Input
                                                      placeholder={t(
                                                        'collectibles.fields.perks.enterContent'
                                                      )}
                                                    />
                                                  </Form.Item>
                                                </Col>
                                                <Col span={1} style={{ textAlign: 'center' }}>
                                                  <MinusCircleOutlined
                                                    className="dynamic-delete-button"
                                                    onClick={() => remove(field.name)}
                                                    style={{
                                                      fontSize: 20
                                                    }}
                                                  />
                                                </Col>
                                              </Row>
                                            </Form.Item>
                                          ))}
                                          <Form.Item>
                                            <Row style={{ alignItems: 'center' }}>
                                              <Col span={23}>
                                                <Button
                                                  type="dashed"
                                                  onClick={() => add()}
                                                  style={{
                                                    width: '100%',
                                                    padding: '10px 0'
                                                  }}
                                                  icon={<PlusOutlined />}
                                                >
                                                  Add field
                                                </Button>
                                              </Col>
                                            </Row>
                                            <Form.ErrorList errors={errors} />
                                          </Form.Item>
                                        </>
                                      )}
                                    </Form.List>
                                  </Col>
                                  <Col span={24}>
                                    <Form.List name="lifetimePerksNumbered">
                                      {(fields, { add, remove }, { errors }) => (
                                        <>
                                          {fields.map((field, index) => (
                                            <Form.Item
                                              {...(index === 0
                                                ? formItemLayout
                                                : formItemLayoutWithOutLabel)}
                                              required={false}
                                              key={field.key}
                                              label={
                                                index === 0
                                                  ? t('collectibles.fields.perks.lifetime')
                                                  : ''
                                              }
                                            >
                                              <Row style={{ alignItems: 'center' }}>
                                                <Col span={23}>
                                                  <Form.Item
                                                    {...field}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    noStyle
                                                  >
                                                    <Input
                                                      placeholder={t(
                                                        'collectibles.fields.perks.enterContent'
                                                      )}
                                                    />
                                                  </Form.Item>
                                                </Col>
                                                <Col span={1} style={{ textAlign: 'center' }}>
                                                  <MinusCircleOutlined
                                                    className="dynamic-delete-button"
                                                    onClick={() => remove(field.name)}
                                                    style={{
                                                      fontSize: 20
                                                    }}
                                                  />
                                                </Col>
                                              </Row>
                                            </Form.Item>
                                          ))}
                                          <Form.Item>
                                            <Row style={{ alignItems: 'center' }}>
                                              <Col span={23}>
                                                <Button
                                                  type="dashed"
                                                  onClick={() => add()}
                                                  style={{
                                                    width: '100%',
                                                    padding: '10px 0'
                                                  }}
                                                  icon={<PlusOutlined />}
                                                >
                                                  Add field
                                                </Button>
                                              </Col>
                                            </Row>
                                            <Form.ErrorList errors={errors} />
                                          </Form.Item>
                                        </>
                                      )}
                                    </Form.List>
                                  </Col>
                                </Row>
                              </TabPane>
                            </Tabs>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tab={t('collectibles.fields.group.sharing')} key="5" forceRender>
                        <Row gutter={30}>
                          <Col span={24}>
                            <Form.Item
                              label={t('collectibles.fields.seo.title.label')}
                              name="metaTitle"
                              rules={[
                                { required: true, message: t('collectibles.fields.title.required') }
                              ]}
                            >
                              <Input placeholder={t('collectibles.fields.seo.title.placeholder')} />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              label={t('collectibles.fields.seo.description.label')}
                              name="metaDescription"
                              rules={[
                                {
                                  required: true,
                                  message: t('collectibles.fields.seo.description.required')
                                },
                                {
                                  min: 100,
                                  message: (
                                    <Trans i18nKey="collectibles.fields.seo.description.minLength">
                                      {metaDescriptionLength}
                                    </Trans>
                                  )
                                },
                                {
                                  max: 160,
                                  message: (
                                    <Trans i18nKey="collectibles.fields.seo.description.maxLength">
                                      {metaDescriptionLength}
                                    </Trans>
                                  )
                                }
                              ]}
                            >
                              <TextArea
                                rows={4}
                                onChange={(e) => {
                                  setMetaDescriptionLength(e.target.value.length.toString());
                                }}
                                placeholder={t('collectibles.fields.seo.description.placeholder')}
                                disabled={disabled}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </TabPane>
                      {process.env.REACT_APP_NAME === 'patrons' && (
                        <TabPane tab="Print" key="6">
                          <PrintFrame
                            originalFrame={originalFrame}
                            collectible={previewData}
                            setOriginalFrame={setOriginalFrame}
                            setOriginalWidth={setLargeWidth}
                            setOriginalHeight={setLargeHeight}
                            setSmallHeight={setSmallHeight}
                            setSmallWidth={setSmallWidth}
                            dimensionWidth={originalWidth}
                            dimensionHeight={originalHeight}
                            smallWidth={smallWidth}
                            smallHeight={smallHeight}
                            originalHeight={largeHeight}
                            originalWidth={largeWidth}
                            smallFrame={smallFrame}
                            setSmallFrame={setSmallFrame}
                            imageUrl={assetUrl}
                            setDoPrintable={setDoPrintable}
                            setDoSmallPrint={setDoSmallPrint}
                            doPrintable={doPrintable}
                            doSmallPrint={doSmallPrint}
                          />
                        </TabPane>
                      )}
                      <TabPane
                        tab={t('collectibles.faq.title')}
                        disabled={process.env.REACT_APP_NAME === 'patrons'}
                        key="7"
                      >
                        <div className="faq">
                          {!validateFaqValue && (
                            <div style={{ color: 'red' }}>{t('collectibles.faq.error')}</div>
                          )}
                          <SortableContainer onSortEnd={onSortEnd} useDragHandle>
                            {items?.map((value, index) => (
                              <SortableItem key={`item-${index}`} index={index} value={value} />
                            ))}
                          </SortableContainer>
                          <Form.Item>
                            <Row style={{ alignItems: 'center' }}>
                              <Col span={24}>
                                <Button
                                  type="dashed"
                                  onClick={() => addFaq()}
                                  style={{
                                    width: '100%',
                                    padding: '10px 0'
                                  }}
                                  icon={<PlusOutlined />}
                                >
                                  {t('collectibles.faq.add')}
                                </Button>
                              </Col>
                            </Row>
                            <Form.ErrorList />
                          </Form.Item>
                        </div>
                      </TabPane>
                    </Tabs>
                  </Col>
                </Row>
                {/* Disable the Collection feature for now
              <FormGroup heading={t('collectibles.fields.group.content.heading')}>
                <Row gutter={30}>
                  <Col span={24}>
                    <Form.Item
                      label={t('collectibles.fields.collection.label')}
                      name="collectionIDs"
                      rules={[
                        { required: true, message: t('collectibles.fields.collection.required') }
                      ]}
                    >
                      <Select
                        placeholder={t('collectibles.fields.collection.placeholder')}
                        mode="multiple"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {collections.map((sub) => (
                          <Option key={`collection-${sub.value}`} value={sub.value}>
                            {sub.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </FormGroup> */}
              </Col>
              <Col span={24} xl={6} lg={8} className="collectible-form-right">
                <Sticky offsetTop={20} offsetBottom={20}>
                  <Form.Item>
                    <CollectibleCardV2
                      data={previewData}
                      assetUrl={assetUrl}
                      mediaThumbnail={mediaThumbnail}
                      isExternalStreaming={
                        form.getFieldValue('streamExternal') &&
                        form.getFieldValue('category') === 'SONG'
                      }
                      externalStreamingValue={externalStreaming}
                    />
                  </Form.Item>

                  <Form.Item>
                    <div className="preview-sales">
                      <Title style={{ marginBottom: 0 }} level={3}>
                        {t('collectibles.previewSales.heading')}
                      </Title>
                      <div className="preview-sales__list">
                        <div className="preview-sales__list__item">
                          <div className="preview-sales__list__item__label">
                            {t('collectibles.previewSales.primaryMarketSales')}
                          </div>
                        </div>
                        {process.env.REACT_APP_NAME !== 'patrons' && (
                          <div className="preview-sales__list__item">
                            <div className="preview-sales__list__item__label">
                              {t('collectibles.previewSales.digitalOriginal')}
                            </div>
                            <div className="preview-sales__list__item__value">
                              {t('collectibles.previewSales.subjectToAuction')}
                            </div>
                          </div>
                        )}
                        <div className="preview-sales__list__item">
                          <div className="preview-sales__list__item__label">
                            {t('collectibles.previewSales.numberedEdition')}
                          </div>
                          <div className="preview-sales__list__item__value">
                            <Price amount={numbEditionTotalPrice || 0} inline /> (
                            {t('collectibles.previewSales.salesNumber', {
                              sales: countNumbEditionReleases
                            })}
                            )
                          </div>
                        </div>
                        <div className="preview-sales__list__item">
                          <div className="preview-sales__list__item__label">
                            {creatorRoyalties}{' '}
                            <Tooltip title={creatorRoyaltiesTitle}>
                              <ExclamationCircleOutlined />
                            </Tooltip>
                          </div>
                          <div className="preview-sales__list__item__value">5%</div>
                        </div>
                        <div className="preview-sales__list__item">
                          <div className="preview-sales__list__item__label">
                            {t('collectibles.previewSales.platformFee')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form.Item>

                  {collectible?.history && collectible?.history?.length > 0 && (
                    <Form.Item>
                      <div className="preview-sales" style={{ padding: 20 }}>
                        <Timeline>
                          {collectible?.history?.map((h) => (
                            <Timeline.Item color={historyColorMapper[h?.status]}>
                              <p>
                                {t(`collectibles.history.${h.status}`, {
                                  title: collectible?.title,
                                  description: h?.value
                                })}
                                <br />{' '}
                                <span className="admin-collectible-timeline__date">
                                  {moment(h?.date).format('DD/MM/YYYY')}
                                </span>
                              </p>
                            </Timeline.Item>
                          ))}
                        </Timeline>
                      </div>
                    </Form.Item>
                  )}

                  <Form.Item
                    name="agree"
                    valuePropName="checked"
                    style={{ marginBottom: 0, display: disabled ? 'none' : '' }}
                  >
                    <Checkbox onChange={({ target }) => setAgreement(target.checked)}>
                      <Trans i18nKey="collectibles.fields.agree.label">
                        <Link target="_blank" to="/terms-and-conditions"></Link>
                        <Link target="_blank" to="/privacy"></Link>
                      </Trans>
                    </Checkbox>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="ghost"
                      block
                      loading={updateLoading}
                      className="admin-form-collectibles-button"
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                      style={{ display: disabled ? 'none' : '' }}
                    >
                      {t('collectibles.buttons.save')}
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      disabled={disabled ? !disabled : !agree}
                      className="admin-form-collectibles-button"
                      loading={reviewLoading}
                    >
                      {disabled
                        ? t('collectibles.buttons.update')
                        : t('collectibles.buttons.submit')}
                    </Button>
                  </Form.Item>
                </Sticky>
              </Col>
            </Row>
          </Form>
        )}
      </DashboardContent>
      <Helmet>
        <title>{t('collectibles.add.title')}</title>
      </Helmet>
    </>
  );
}

export default EditCollectibles;
