import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { RightOutlined } from '@ant-design/icons';
import { Container, Link } from '@components';

function Banner({ title, children }) {
  const { Title } = Typography;
  const { t } = useTranslation();
  return (
    <div className="banner">
      {children}
      <Container>
        <div className="banner-wrapper">
          <Title level={1} className="banner__title">
            {title}
          </Title>
          <div className="banner__breadcrumbs">
            <Link to="/">{t('components.banner.breadcrumbs.parent')}</Link>
            <RightOutlined />
            {title}
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Banner;
