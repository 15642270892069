import cx from 'classnames';
import { useState, useEffect, useMemo } from 'react';
import differenceInSeconds from 'date-fns/differenceInSeconds';

const Countdown = ({
  time,
  className,
  showSeconds,
  showDay = true,
  showOnlyDay = false,
  showOnlyDayOffset = 0,
  showIndicator = true,
  showThreeDigits = false,
  showSeparator = false,
  refetchTierTiles,
  label,
  showInactive = false,
  ...props
}) => {
  const ONE_DAY = 60 * 60 * 24;
  const ONE_HOUR = 60 * 60;
  const ONE_MINUTE = 60;
  const [currentTime, setCurrentTime] = useState(new Date().getTime());
  const [currentInterval, setCurrentInterval] = useState();

  const diffInSeconds = differenceInSeconds(new Date(time), currentTime);

  const getCoundown = () => {
    if (diffInSeconds < 1) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      };
    }
    const days = Math.floor(diffInSeconds / ONE_DAY);
    const hours = Math.floor((diffInSeconds - days * ONE_DAY) / ONE_HOUR);
    const minutes = Math.floor((diffInSeconds - days * ONE_DAY - hours * ONE_HOUR) / ONE_MINUTE);
    const seconds = diffInSeconds - days * ONE_DAY - hours * ONE_HOUR - minutes * ONE_MINUTE;
    return {
      days,
      hours,
      minutes,
      seconds
    };
  };

  const countdown = useMemo(getCoundown, [currentTime]); // eslint-disable-line

  useEffect(() => {
    const diffInSeconds = differenceInSeconds(new Date(time), currentTime);
    if (refetchTierTiles && currentInterval && diffInSeconds < 1) {
      setCurrentInterval();
      refetchTierTiles();
      clearInterval(currentInterval);
    }
  }, [currentTime, currentInterval, time, refetchTierTiles]);

  useEffect(() => {
    let intervalID = setInterval(() => {
      const now = new Date().getTime();
      setCurrentTime(now);
    }, 1000);

    setCurrentInterval(intervalID);

    return () => clearInterval(intervalID);
  }, []);

  return (
    <div className={cx('countdown', className)} {...props}>
      {(showOnlyDay && countdown.days > showOnlyDayOffset) || showInactive ? (
        <span className="days">
          {countdown.days} Day{countdown.days !== 1 && 's'} left
        </span>
      ) : (
        <>
          {label && label}
          {label && showSeparator && <span className="countdown-separator"></span>}
          {showDay && (
            <span className="days">
              {countdown.days}
              {showIndicator && (showThreeDigits ? ' Day' : 'd')}
            </span>
          )}
          {showDay && showSeparator && <span className="countdown-separator"></span>}
          <span className="hours">
            {countdown.hours}
            {showIndicator && (showThreeDigits ? ' Hour' : 'h')}
          </span>
          {showSeparator && <span className="countdown-separator"></span>}
          <span className="minutes">
            {countdown.minutes}
            {showIndicator && (showThreeDigits ? ' Min' : 'm')}
          </span>
          {showSeconds && (
            <>
              {showSeparator && <span className="countdown-separator"></span>}
              <span className="seconds">
                {countdown.seconds}
                {showIndicator && (showThreeDigits ? ' Sec' : 's')}
              </span>
            </>
          )}
        </>
      )}
    </div>
  );
};

Countdown.defaultProps = {
  showSeconds: true
};

export default Countdown;
