import { Avatar as Ava } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Image } from '@components';

function Avatar({ user, size, square = true, style }) {
  return (
    <>
      {user &&
        (user?.media?.avatarUrl === null || user?.media?.avatarUrl === '' ? (
          <Ava
            style={{ verticalAlign: 'middle', backgroundColor: '#0077F7', flexShrink: 0, ...style }}
            size={size ? size : 36}
            shape={square ? 'square' : null}
            icon={<UserOutlined />}
          ></Ava>
        ) : (
          <Ava
            style={{ flexShrink: 0, ...style }}
            shape={square ? 'square' : null}
            src={<Image size={size ? size : 48} src={user?.media?.avatarUrl} />}
            size={size ? size : 36}
          ></Ava>
        ))}
    </>
  );
}

export default Avatar;
