import { Row, Col, Table, Collapse, List, Input, Checkbox, Button, Modal } from 'antd';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { DashboardContent, DisplayDate, Pagination } from '@components';
import { useWaitlist } from '@libs/hooks/user';
import { useEffect, useCallback } from 'react';
import { LoginOutlined } from '@ant-design/icons';
function Waitlist() {
  const { Search } = Input;
  const { t } = useTranslation();
  const { data, loading, nextPageToken, updateLoading, getData, updateWaitlist } = useWaitlist();
  const [waitlists, setWaitlists] = useState([]);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [checked, setChecked] = useState([]);
  const [allChecked, setAllChecked] = useState(false);

  useEffect(() => {
    const newData = data
      ?.filter((x) => checked?.includes(x.id))
      .map((x) => {
        return {
          id: x.id,
          email: x.email
        };
      });
    setWaitlists(newData);
  }, [checked]); //eslint-disable-line

  const handleSearch = (data) => {
    getData(null, data);
  };

  const handleSubmit = useCallback(async () => {
    if (waitlists?.length > 0) {
      await updateWaitlist(waitlists);
      setTimeout(() => {
        getData();
        setShowSubmitModal(false);
      }, 2000);
    }
    setAllChecked(false);
  }, [waitlists]); //eslint-disable-line

  const columns = [
    {
      title: <Checkbox disabled>{t('waitlist.list.name')}</Checkbox>,
      key: 'withdrawlID',
      responsive: ['xs'],
      render: (_, row) => {
        return (
          <div className="mobile-row">
            <div className="mobile-row__info">
              <div className="mobile-row__info__name">
                <Checkbox
                  id="parentCheckbox"
                  onChange={(e) => {
                    if (e?.target?.checked) {
                      setChecked(data?.map((x) => x.id));
                    } else {
                      setChecked([]);
                    }
                  }}
                  checked={allChecked}
                >
                  {t('waitlist.list.name')}
                </Checkbox>
              </div>
            </div>
            <Collapse collapsible ghost className="datalist">
              <Collapse.Panel header={t('offers.list.expand')} key="1">
                <List itemLayout="vertical">
                  <List.Item>
                    <List.Item.Meta
                      title={t('waitlist.list.emailAddress')}
                      description={<span>{row?.email}</span>}
                    />
                    <List.Item.Meta
                      title={t('waitlist.list.phone')}
                      description={<span>{row?.phone}</span>}
                    />
                    <List.Item.Meta
                      title={t('waitlist.list.joinedAt')}
                      description={<DisplayDate date={row?.verifiedAt} />}
                    />
                  </List.Item>
                </List>
              </Collapse.Panel>
            </Collapse>
          </div>
        );
      }
    },
    {
      title: (
        <Checkbox
          checked={allChecked}
          onChange={(e) => {
            if (e?.target?.checked) {
              setAllChecked(true);
              setChecked(data?.map((x) => x.id));
            } else {
              setAllChecked(false);
              setChecked([]);
            }
          }}
        >
          {t('waitlist.list.name')}
        </Checkbox>
      ),
      key: 'name',
      responsive: ['sm'],
      render: (_, row) => (
        <span>
          <Checkbox
            onChange={(e) => {
              if (e?.target?.checked) {
                setChecked([...checked, row?.id]);
              } else {
                setChecked(checked?.filter((x) => x !== row?.id));
              }
            }}
            checked={checked?.includes(row?.id)}
          >
            {row?.firstName} {row?.surName}
          </Checkbox>
        </span>
      )
    },
    {
      title: t('waitlist.list.emailAddress'),
      dataIndex: 'email',
      key: 'email',
      responsive: ['sm'],
      render: (_, row) => <span>{row?.email}</span>
    },
    {
      title: t('waitlist.list.phone'),
      dataIndex: 'phone',
      key: 'phone',
      responsive: ['sm'],
      render: (_, row) => <span>{row?.phone}</span>
    },
    {
      title: t('waitlist.list.joinedAt'),
      dataIndex: 'joinedAt',
      key: 'joinedAt',
      responsive: ['sm'],
      render: (_, row) => <DisplayDate date={row?.verifiedAt} />
    }
  ];

  return (
    <>
      <DashboardContent className="waitlist-page" title={t('waitlist.title')}>
        <div className="waitlist-toolbar">
          <Row className="waitlist-page-search">
            <Col span={24} sm={12}>
              <div className="toolbar-search">
                <Search
                  onChange={(e) => {
                    handleSearch(e.target.value);
                  }}
                  placeholder={t('waitlist.search')}
                />
              </div>
            </Col>
            <Col className="waitlist-toolbar-right" span={24} sm={12}>
              <Button onClick={() => setShowSubmitModal(true)} type="primary">
                {t('waitlist.setActive')}
              </Button>
            </Col>
          </Row>
        </div>
        <div className="waitlist">
          <div className="fans-table">
            <Table
              bordered
              loading={loading}
              dataSource={data}
              columns={columns}
              pagination={false}
            />
            <Pagination nextToken={nextPageToken} loading={loading} fetchData={getData} />
          </div>
        </div>
        <Modal
          className="fanclub-channel-delete-modal"
          visible={showSubmitModal}
          footer={null}
          onCancel={() => setShowSubmitModal(false)}
          width={650}
        >
          <div className="fanclub-channel-delete-modal-container">
            <div className="fanclub-channel-delete-modal-container-header">
              <LoginOutlined style={{ fontSize: '5rem', color: '#27AE60' }} />
              <div className="fanclub-channel-delete-modal-container-header-title">
                {t('waitlist.allowLogin')}
              </div>
            </div>
            <div className="fanclub-channel-delete-modal-container-header-text">
              {t('waitlist.allowLoginDescription')}
            </div>
          </div>
          <div className="fanclub-channel-delete-modal-container-footer">
            <Button
              loading={updateLoading}
              onClick={() => handleSubmit()}
              className="fanclub-channel-delete-modal-container-footer-delete"
              type="primary"
            >
              {t('waitlist.allow')}
            </Button>
            <Button
              onClick={() => setShowSubmitModal(false)}
              className="fanclub-channel-delete-modal-container-footer-cancel"
              type="secondary"
            >
              {t('waitlist.cancel')}
            </Button>
          </div>
        </Modal>
      </DashboardContent>
      <Helmet>
        <title>{t('waitlist.list.name')}</title>
      </Helmet>
    </>
  );
}

export default Waitlist;
