import { Helmet } from 'react-helmet';
import { Button, Form, Input, Select } from 'antd';

import { DashboardContent } from '@components';

import { useAccount } from '@libs/hooks/account';
import { useFanUser } from '@libs/hooks/user';

function DummyCreateOrganization() {
  const { data: managers, loading: managersLoading } = useFanUser();
  const { onCreate, createLoading } = useAccount();

  const { Option } = Select;
  const { TextArea } = Input;

  const onSubmit = (data) => {
    onCreate(data);
  };

  return (
    <>
      <DashboardContent title="[Temporary] Create Organization">
        <Form onFinish={onSubmit} layout="vertical" className="admin-form">
          <Form.Item
            label="Select Manager"
            name="userID"
            rules={[{ required: true, message: 'Please select a user' }]}
          >
            <Select
              placeholder="Select a user"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              loading={managersLoading}
            >
              {managers &&
                managers.map((manager) => (
                  <Option key={`manager-${manager.id}`} value={manager.id}>
                    {`${manager.firstName} ${manager.lastName}`}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Team Name"
            name="name"
            rules={[{ required: true, message: 'Please enter the team name' }]}
          >
            <Input placeholder="Team Name" />
          </Form.Item>
          <Form.Item
            label="Legal Name"
            name="legalName"
            rules={[{ required: true, message: 'Please enter the legal name' }]}
          >
            <Input placeholder="Organization or company legal name" />
          </Form.Item>
          <Form.Item
            label="Address"
            name="address"
            rules={[
              { required: true, message: 'Please enter the organization or company address' }
            ]}
          >
            <TextArea rows={4} placeholder="Organization or company address" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={createLoading}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </DashboardContent>
      <Helmet>
        <title>Create Organization</title>
      </Helmet>
    </>
  );
}

export default DummyCreateOrganization;
