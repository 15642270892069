export const paymentMapper = {
  CHECKOUT: 'Checkout.com',
  BITPAY: 'BitPay',
  PAYPAL: 'PayPal',
  'Fans.Inc account balance': 'Fans.Inc account balance'
};

export const statusOrderMapper = {
  Draft: 'Draft',
  'In Progress': 'In Progress',
  'Download Assets': 'Download Assets',
  'Preparing print asset': 'Preparing print asset',
  'Preparing for production': 'Preparing for production',
  'In production': 'In production',
  PENDING: 'Pending',
  Shipping: 'Shipping',
  Complete: 'Complete',
  Cancelled: 'Cancelled'
};

export const colorStatusOrderMapper = {
  'In Progress': '#E2B93B',
  Draft: '#F8F8FA',
  PENDING: '#E2B93B',
  'Download Assets': '#E2B93B',
  'Preparing print asset': '#E2B93B',
  'Preparing for production': '#E2B93B',
  'In production': '#E2B93B',
  Shipping: '#0077F8',
  Complete: '#27AE60',
  Cancelled: '#D6222A'
};

export const historyColorMapper = {
  IN_REVIEW: 'orange',
  NEED_REVISION: 'red',
  PUBLISHED: 'green'
};
