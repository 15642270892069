import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { Button, Col, Row, Slider } from 'antd';
import { useTranslation } from 'react-i18next';

const ImageCropper = ({ aspect, objectFit, image, onComplete, onCancel, loading }) => {
  const { t } = useTranslation();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState();

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  return (
    <div className="cropper">
      <div className="cropper__inner">
        <div className="cropper__inner__image">
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            objectFit={objectFit}
            aspect={aspect}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        <div className="cropper__inner__control">
          <Row gutter={30} align="middle">
            <Col span={14}>
              <Slider
                value={zoom}
                min={1}
                step={0.1}
                max={3}
                onChange={(value) => setZoom(value)}
              />
            </Col>
            <Col span={10}>
              <div className="cropper__inner__control__button">
                <Button className="cropper-button-cancel" type="link" onClick={() => onCancel()}>
                  {t('components.cropper.cancel')}
                </Button>
                <Button
                  className="cropper-button-save"
                  loading={loading}
                  type="primary"
                  onClick={() => onComplete(croppedArea)}
                  disabled={!croppedArea?.width || !croppedArea?.height}
                >
                  {t('components.cropper.save')}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

ImageCropper.defaultProps = {
  onCancel: () => {},
  aspect: 1 / 1,
  objectFit: 'contain'
};

export default ImageCropper;
