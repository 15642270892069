import WhiteBackground from '@assets/img/white.png';
import QRCode from 'react-qr-code';
import cx from 'classnames';
import { ReactComponent as Logo } from '@assets/svg/qrLogo.svg';
import { useTranslation } from 'react-i18next';
import { useGalleryExperience } from '@libs/hooks/gallery-experience';
import { useState, useEffect, useRef } from 'react';
import { exportComponentAsJPEG } from 'react-component-export-image';

function PrintQrCollectibles({ collectible }) {
  const printRef = useRef();
  const { t } = useTranslation();
  const { data: galleryData } = useGalleryExperience();
  const [showImage, setShowImage] = useState(false);
  const [wrapperStyle, setWrapperStyle] = useState({
    width: '900px'
  });
  const [sectionStyle, setSectionStyle] = useState({
    display: 'block'
  });
  setTimeout(() => {
    setShowImage(true);
  }, 1500);
  useEffect(() => {
    if (galleryData) {
      if (galleryData?.orientation === 'portrait') {
        setWrapperStyle({
          width: '900px'
        });
        setSectionStyle({
          display: 'block'
        });
      } else {
        setWrapperStyle({
          width: '1600px',
          minWidth: '1600px'
        });
        setSectionStyle({
          display: 'flex',
          justifyCcontent: 'space-between',
          gap: '50px'
        });
      }
    }
  }, [galleryData]);
  const download = () => {
    if (printRef.current) {
      exportComponentAsJPEG(printRef);
    }
  };
  return (
    <>
      <span className="print-qr-collectible-download" onClick={() => download()}>
        {t('collectibles.list.meta.download')}
      </span>
      {showImage && (
        <div
          style={{ zIndex: '-1', position: 'absolute', opacity: '0' }}
          className={cx('print-qr-collectible')}
        >
          <div
            ref={printRef}
            style={wrapperStyle}
            className="gallery-experience-page__image__wrapper"
          >
            <div
              style={{
                backgroundImage: `url(${WhiteBackground})`,
                backgroundSize: 'cover'
              }}
              className="gallery-experience-page__image__wrapper__background"
            ></div>
            <div
              style={sectionStyle}
              className={cx('gallery-experience-page__image__wrapper__section')}
            >
              <div className="gallery-experience-page__image__wrapper__section__description">
                <div>
                  {galleryData?.logoPosition === 'top' && (
                    <div className="gallery-experience-page__image__wrapper__section__description__logo">
                      <Logo />
                    </div>
                  )}
                  <div className="gallery-experience-page__image__wrapper__section__description__heading">
                    {galleryData?.heading}
                  </div>
                  <div className="gallery-experience-page__image__wrapper__section__description__description">
                    {galleryData?.description}
                  </div>
                  {galleryData?.logoPosition === 'bottom' &&
                    galleryData?.orientation === 'landscape' && (
                      <div className="gallery-experience-page__image__wrapper__section__description__bottom">
                        <Logo />
                      </div>
                    )}
                </div>
              </div>
              <div
                className={cx(
                  'gallery-experience-page__image__wrapper__section__image',
                  galleryData?.qrPosition === 'left'
                    ? 'gallery-experience-page__image__wrapper__section__order-1'
                    : ''
                )}
              >
                <div className="gallery-experience-page__image__wrapper__section__image__text">
                  {galleryData?.qrText}
                </div>
                <div className="gallery-experience-page__image__wrapper__section__image__image">
                  <QRCode
                    size={666}
                    value={`https://www.patrons.art/collect-collectible/${collectible?.id}/?utm_source=https://www.patrons.art/&utm_medium=qr&utm_campaign=gallery_experience`}
                    viewBox={`0 0 666 666`}
                  />
                </div>
              </div>
              {galleryData?.logoPosition === 'bottom' && galleryData?.orientation === 'portrait' && (
                <div className="gallery-experience-page__image__wrapper__section__bottom">
                  <Logo />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PrintQrCollectibles;
