export const listExchanges = /* GraphQL */ `
  query ListExchanges($filter: ModelExchangeFilterInput, $limit: Int, $nextToken: String) {
    listExchanges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectible {
          title
          mediaThumbnail
        }
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        patronID
        paymentProvider
        paymentReferenceID
        checkoutReceiptID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousPrice
          currentPrice
          currentListingType
          lastPurchasedAt
          currentOwner {
            id
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          media {
            avatarUrl
          }
          bio
          tags
          verified
          fanProfile
          emailVerified
          joinDate
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          media {
            avatarUrl
          }
          bio
          tags
          verified
          fanProfile
          emailVerified
          joinDate
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          media {
            avatarUrl
          }
          bio
          tags
          verified
          fanProfile
          emailVerified
          joinDate
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const searchExchanges = /* GraphQL */ `
  query SearchExchanges(
    $filter: SearchableExchangeFilterInput
    $sort: SearchableExchangeSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchExchanges(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectible {
          title
          mediaThumbnail
        }
        collectibleReleaseIndex
        tierType
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        patronID
        paymentProvider
        paymentReferenceID
        checkoutReceiptID
        createdAt
        updatedAt
        userID
        _version
        _deleted
        _lastChangedAt
        owner
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          media {
            avatarUrl
          }
          fee {
            salesFee
            royaltyFee
          }
          bio
          tags
          verified
          fanProfile
          emailVerified
          joinDate
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          media {
            avatarUrl
          }
          bio
          tags
          verified
          fanProfile
          emailVerified
          joinDate
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          media {
            avatarUrl
          }
          bio
          tags
          verified
          fanProfile
          emailVerified
          joinDate
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        parentExchange {
          fiatCurrencyCode
        }
      }
      nextToken
      total
    }
  }
`;

export const exchangeByID = /* GraphQL */ `
  query getExchange($id: ID!) {
    getExchange(id: $id) {
      id
      exchangeType
      owner
      creatorID
      currentOwnerID
      collectibleID
      serialIndex
      listingType
      startingPrice
      sellingPrice
      currentPrice
      finalPrice
      previousPrice
      auctionMinimumAllowedBidPrice
      offerPrice
      bidPrice
      currentListingType
      expiryDate
      isSold
      isReleased
      patronID
      nftOwnerAddress
      nftTokenIDString
      paymentReferenceID
      checkoutReceiptID
      tierType
      createdAt
      updatedAt
      paymentRequestExpiredAt
      paymentProvider
      bitpayPaymentRequested
      previousExchange {
        listingType
      }
      parentExchange {
        expiryDate
        searchableIsSold
      }
      slug
      collectible {
        id
        accountID
        userID
        type
        category
        title
        slug
        description
        assetUrl
        mediaThumbnail
        featured
        createdAt
        updatedAt
        owner
        creator {
          id
          owner
          accountID
          editors
          email
          username
          displayName
          firstName
          lastName
          bio
          tags
          verified
          fanProfile
          joinDate
          createdAt
          updatedAt
          media {
            avatarUrl
            coverUrl
          }
        }
      }
      currentOwner {
        firstName
        lastName
        username
        media {
          avatarUrl
        }
      }
      release {
        id
        slug
        tierType
        serialIndex
        releaseCount
        releaseDate
        collectibleID
        userID
        previousPrice
        currentPrice
        currentListingType
        lastPurchasedAt
        createdAt
        updatedAt
        currentOwner {
          id
          firstName
          lastName
          username
          media {
            avatarUrl
          }
        }
        collectible {
          id
          accountID
          userID
          type
          category
          title
          slug
          description
          assetUrl
          mediaThumbnail
          featured
          createdAt
          updatedAt
          owner
          releases {
            releaseDate
            tierType
            releaseCount
            listingType
            startingPrice
            finalPrice
            preReleaseAvailability
            saleEndDate
          }
          creator {
            id
            owner
            accountID
            editors
            email
            username
            firstName
            lastName
            bio
            tags
            verified
            fanProfile
            joinDate
            createdAt
            updatedAt
            media {
              avatarUrl
              coverUrl
            }
          }
        }
      }
    }
  }
`;
