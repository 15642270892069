import { Button } from 'antd';
import Image1 from '@assets/img/landing-image-1.png';
import Image2 from '@assets/img/landing-image-2.png';
import Image3 from '@assets/img/landing-image-3.png';
import { useTranslation } from 'react-i18next';
import { ModalPatronsRegister } from '@components';
import { useState } from 'react';

function Landing() {
  const { t } = useTranslation();
  const [registerModal, setRegisterModal] = useState(false);
  return (
    <div className="auth-landing">
      <div className="auth-landing__wrapper">
        <div style={{ width: '100%' }}>
          <div className="auth-landing__top">
            <div className="auth-landing__top__left">{t('auth.landing.title')}</div>
            <div className="auth-landing__top__right">
              <div>{t('auth.landing.description')}</div>
              <Button onClick={() => setRegisterModal(true)} type="primary">
                {t('auth.landing.register')}
              </Button>
            </div>
          </div>
          <div className="auth-landing__image">
            <img alt="img1" src={Image1} />
          </div>
          <div className="auth-landing__grid">
            <div className="auth-landing__grid__image">
              <img alt="img1" src={Image2} />
            </div>
            <div className="auth-landing__grid__text">
              <div className="auth-landing__grid__text__wrapper">
                <div className="auth-landing__grid__text__title">
                  {t('auth.landing.artist.title')}
                </div>
                <div className="auth-landing__grid__text__description">
                  {t('auth.landing.artist.description')}
                </div>
              </div>
            </div>
          </div>
          <div className="auth-landing__grid">
            <div className="auth-landing__grid__text">
              <div className="auth-landing__grid__text__wrapper">
                <div className="auth-landing__grid__text__title">
                  {t('auth.landing.patrons.title')}
                </div>
                <div className="auth-landing__grid__text__description">
                  {t('auth.landing.patrons.description')}
                </div>
              </div>
            </div>
            <div className="auth-landing__grid__image">
              <img alt="img1" src={Image3} />
            </div>
          </div>
          <div className="auth-landing__bottom">
            <div className="auth-landing__bottom__left">{t('auth.landing.bottom')}</div>
            <div className="auth-landing__bottom__right">
              <Button onClick={() => setRegisterModal(true)} type="primary">
                {t('auth.landing.singUp')}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ModalPatronsRegister onShow={registerModal} onClose={() => setRegisterModal(false)} />
    </div>
  );
}
export default Landing;
