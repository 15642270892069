import { useState, useEffect } from 'react';
import { useProfileOrganisation } from '@libs/hooks/organisation';
import gql from '@libs/utils/gql';
import useToast from '@libs/utils/toast';
import { getWallet, getWalletFansInc } from '@libs/custom-queries/wallet';
import { searchWalletHistories } from '@libs/custom-queries/wallet-history';

export const useWalletByOwner = (id) => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const { data: profiles } = useProfileOrganisation(true);

  const getData = async (id) => {
    setLoading(true);
    try {
      const { data: res } = await gql(
        getWallet,
        {
          id
        },
        {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }
      );

      if (res?.getWallet) {
        setData(res?.getWallet);
      } else {
        setData(null);
      }
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) getData(id);

    if (profiles.length > 0 && !id) getData(profiles[0].value);
  }, [profiles, id]); // eslint-disable-line

  return {
    data,
    loading,
    getData
  };
};

export const useWalletFansInc = (id) => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const { data: profiles } = useProfileOrganisation(true);

  const getData = async (id) => {
    setLoading(true);
    try {
      const { data: res } = await gql(
        getWalletFansInc,
        {
          id
        },
        {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }
      );

      if (res?.getWallet) {
        setData(res?.getWallet);
      } else {
        setData(null);
      }
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) getData(id);

    if (profiles.length > 0 && !id) getData(profiles[0].value);
  }, [profiles, id]); // eslint-disable-line

  return {
    data,
    loading,
    getData
  };
};

export const useWalletHistory = (id) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [nextToken, setNextToken] = useState();
  const { data: profiles } = useProfileOrganisation(true);
  const [prevProfile, setPrevProfile] = useState();

  const getData = async (id, nextToken) => {
    setLoading(true);
    try {
      const params = {
        filter: {
          owner: { eq: id },
          or: ['PROCESSED', 'PENDING'].map((d) => ({
            searchableStatus: { eq: d }
          }))
        },
        limit: 10,
        sort: { direction: 'desc', field: 'createdAt' },
        nextToken
      };
      const { data: res } = await gql(searchWalletHistories, params, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      if (res?.searchWalletHistories?.items) {
        if (id === prevProfile && nextToken) {
          setData(res?.searchWalletHistories?.items);
        } else {
          setData(res?.searchWalletHistories?.items);
        }

        setNextToken(res.searchWalletHistories.nextToken);
      } else {
        setData([]);
        setNextToken(null);
      }
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setPrevProfile(id);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) getData(id);

    if (profiles.length > 0 && !id) getData(profiles[0].value);
  }, [profiles, id]); // eslint-disable-line

  return {
    data,
    loading,
    getData,
    nextToken
  };
};
