import FansincCard from './fansinc';
import PatronsCard from './patrons';

const Detail = ({
  data,
  assetUrl,
  mediaThumbnail,
  size = 36,
  isExternalStreaming,
  externalStreamingValue
}) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return (
      <PatronsCard
        data={data}
        assetUrl={assetUrl}
        mediaThumbnail={mediaThumbnail}
        size={size}
        isExternalStreaming={isExternalStreaming}
        externalStreamingValue={externalStreamingValue}
      />
    );
  } else {
    return (
      <FansincCard
        data={data}
        assetUrl={assetUrl}
        mediaThumbnail={mediaThumbnail}
        size={size}
        isExternalStreaming={isExternalStreaming}
        externalStreamingValue={externalStreamingValue}
      />
    );
  }
};

export default Detail;
