import { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import gql from '@libs/utils/gql';
import useToast from '@libs/utils/toast';
import { getUser } from '@libs/custom-queries/user';
import { getUserMedia } from '@graphql/queries';
import {
  updateUserMedia,
  customUpdateVerifiedProfile,
  removeAvatarImage,
  removeCoverImage,
  removeAvatarVideo,
  removeFanclubLogo
} from '@graphql/mutations';

export const useProfile = (userID) => {
  const toast = useToast();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [avatarLoading, setAvatarLoading] = useState(false);
  const [coverLoading, setCoverLoading] = useState(false);
  const [fanclubLogoLoading, setFanclubLogoLoading] = useState(false);
  const [removeAvatarLoading, setRemoveAvatarLoading] = useState(false);
  const [removeCoverLoading, setRemoveCoverLoading] = useState(false);
  const [avatarVideoLoading, setAvatarVideoLoading] = useState(false);
  const [removeFanclubLogoLoading, setRemoveFanclubLogoLoading] = useState(false);
  const [data, setData] = useState();

  const fetchData = async () => {
    setLoading(true);
    try {
      const params = {
        id: userID
      };
      const { data: res } = await gql(getUser, params);
      setData(res.getUser);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userID) fetchData();
  }, [userID]); // eslint-disable-line react-hooks/exhaustive-deps

  const onUpdate = async (payload) => {
    setUpdateLoading(true);
    try {
      const data = {
        input: {
          id: payload.id,
          accountID: payload.accountID,
          editors: payload.editors || [],
          firstName: payload.firstName,
          lastName: payload.lastName,
          displayName: payload.displayName,
          username: payload.username,
          bio: payload.bio,
          facebook: payload.facebook,
          youtube: payload.youtube,
          instagram: payload.instagram,
          twitter: payload.twitter,
          spotify: payload.spotify,
          soundcloud: payload.soundcloud,
          website: payload.website,
          email: payload.email,
          tiktok: payload.tiktok,
          snapchat: payload.snapchat,
          appleMusic: payload.appleMusic,
          clubhouse: payload.clubhouse,
          creatorCategory: payload.creatorCategory,
          profileTemplate: payload.profileTemplate,
          salesFee: payload.salesFee,
          royaltyFee: payload.royaltyFee
        }
      };

      await gql(customUpdateVerifiedProfile, data, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      toast(t('formProfiles.toast.successUpdate'), 'success');
    } catch (error) {
      toast(error.errors[0]?.message, 'error');
      console.log(error);
    } finally {
      setUpdateLoading(false);
    }
  };

  const onUpdateAvatar = useCallback(
    async (avatarUrl) => {
      setAvatarLoading(true);
      try {
        const { data: media } = await gql(getUserMedia, { id: userID });

        const payload = {
          input: {
            id: userID,
            avatarUrl,
            avatarVideoUrl: media?.getUserMedia?.avatarVideoUrl || '',
            coverUrl: media?.getUserMedia?.coverUrl || '',
            fanclubLogoUrl: media?.getUserMedia?.fanclubLogoUrl || ''
          }
        };

        await gql(updateUserMedia, payload, {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });

        toast(t('formProfiles.toast.successUpdateMediaAvatar'), 'success');
      } catch (error) {
        const errorMessage = error.errors[0]?.message;
        toast(errorMessage, 'error');

        console.error('error update user media:', error);
      } finally {
        setAvatarLoading(false);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userID]
  );

  const onRemoveAvatar = useCallback(
    async () => {
      setRemoveAvatarLoading(true);
      const payload = {
        input: {
          field: 'avatarUrl',
          creatorID: userID
        }
      };
      try {
        await gql(removeAvatarImage, payload, {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
      } catch (error) {
        const errorMessage = error?.errors[0]?.message;
        toast(errorMessage, 'error');

        console.error('error update user media:', error);
      } finally {
        setRemoveAvatarLoading(false);
      }
    },

    // eslint-disable-next-line
    [userID]
  );

  const onRemoveCover = useCallback(
    async () => {
      setRemoveCoverLoading(true);
      const payload = {
        input: {
          field: 'coverUrl',
          creatorID: userID
        }
      };
      try {
        await gql(removeCoverImage, payload, {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
      } catch (error) {
        const errorMessage = error?.errors[0]?.message;
        toast(errorMessage, 'error');

        console.error('error update user media:', error);
      } finally {
        setRemoveCoverLoading(false);
      }
    },

    // eslint-disable-next-line
    [userID]
  );

  const onRemoveVideo = useCallback(
    async () => {
      setRemoveCoverLoading(true);
      const payload = {
        input: {
          field: 'avatarVideoUrl',
          creatorID: userID
        }
      };
      try {
        await gql(removeAvatarVideo, payload, {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });

        toast(t('formProfiles.toast.successUpdateMediaAvatarVideo'), 'success');
      } catch (error) {
        const errorMessage = error?.errors[0]?.message;
        toast(errorMessage, 'error');

        console.error('error update user media:', error);
      } finally {
        setRemoveCoverLoading(false);
      }
    },

    // eslint-disable-next-line
    [userID]
  );

  const onUpdateCover = useCallback(
    async (coverUrl) => {
      setCoverLoading(true);
      try {
        const { data: media } = await gql(getUserMedia, { id: userID });

        const payload = {
          input: {
            id: userID,
            coverUrl,
            avatarUrl: media?.getUserMedia?.avatarUrl || '',
            avatarVideoUrl: media?.getUserMedia?.avatarVideoUrl || '',
            fanclubLogoUrl: media?.getUserMedia?.fanclubLogoUrl || ''
          }
        };

        await gql(updateUserMedia, payload, {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });

        toast(t('formProfiles.toast.successUpdateMediaCover'), 'success');
      } catch (error) {
        const errorMessage = error.errors[0]?.message;
        toast(errorMessage, 'error');

        console.error('error update user media:', error);
      } finally {
        setCoverLoading(false);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userID]
  );

  const onUpdateAvatarVideo = useCallback(
    async (avatarVideoUrl) => {
      setAvatarVideoLoading(true);
      try {
        const { data: media } = await gql(getUserMedia, { id: userID });

        const payload = {
          input: {
            id: userID,
            avatarVideoUrl,
            coverUrl: media?.getUserMedia?.coverUrl || '',
            avatarUrl: media?.getUserMedia?.avatarUrl || '',
            fanclubLogoUrl: media?.getUserMedia?.fanclubLogoUrl || ''
          }
        };

        await gql(updateUserMedia, payload, {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });

        toast(t('formProfiles.toast.successUpdateMediaAvatarVideo'), 'success');
      } catch (error) {
        const errorMessage = error.errors[0]?.message;
        toast(errorMessage, 'error');

        console.error('error update user media:', error);
      } finally {
        setAvatarVideoLoading(false);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userID]
  );

  const onUpdateFanclubLogo = useCallback(
    async (fanclubLogoUrl) => {
      setFanclubLogoLoading(true);
      try {
        const { data: media } = await gql(getUserMedia, { id: userID });

        const payload = {
          input: {
            id: userID,
            fanclubLogoUrl,
            coverUrl: media?.getUserMedia?.coverUrl || '',
            avatarUrl: media?.getUserMedia?.avatarUrl || '',
            avatarVideoUrl: media?.getUserMedia?.avatarVideoUrl || ''
          }
        };

        await gql(updateUserMedia, payload, {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });

        toast(t('formProfiles.toast.successUpdateMediaCover'), 'success');
      } catch (error) {
        const errorMessage = error.errors[0]?.message;
        toast(errorMessage, 'error');

        console.error('error update user media:', error);
      } finally {
        setFanclubLogoLoading(false);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userID]
  );

  const onRemoveFanclubLogo = useCallback(
    async () => {
      setRemoveFanclubLogoLoading(true);
      const payload = {
        input: {
          field: 'fanclubLogoUrl',
          creatorID: userID
        }
      };
      try {
        await gql(removeFanclubLogo, payload, {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
      } catch (error) {
        const errorMessage = error?.errors[0]?.message;
        toast(errorMessage, 'error');

        console.error('error update user media:', error);
      } finally {
        setRemoveFanclubLogoLoading(false);
      }
    },

    // eslint-disable-next-line
    [userID]
  );

  return {
    loading,
    updateLoading,
    avatarLoading,
    avatarVideoLoading,
    coverLoading,
    fanclubLogoLoading,
    removeAvatarLoading,
    removeCoverLoading,
    removeFanclubLogoLoading,
    data,
    onUpdate,
    onRemoveAvatar,
    onRemoveCover,
    onUpdateAvatar,
    onRemoveVideo,
    onUpdateAvatarVideo,
    onUpdateCover,
    onUpdateFanclubLogo,
    onRemoveFanclubLogo,
    refetch: fetchData
  };
};
