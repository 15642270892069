export const getWallet = /* GraphQL */ `
  query GetWallet($id: ID!) {
    getWallet(id: $id) {
      id
      amount
      createdAt
      updatedAt
      _version
      _deleted
      walletOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      owner
    }
  }
`;

export const getWalletFansInc = /* GraphQL */ `
  query GetWallet($id: ID!) {
    getWallet(id: $id) {
      id
      amount
      createdAt
      updatedAt
      _version
      _deleted
    }
  }
`;
