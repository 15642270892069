import cx from 'classnames';

const Logo = ({ icon, className }) => {
  const iconClass = {
    'logo--icon': icon
  };
  return (
    <>
      {process.env.REACT_APP_NAME === 'patrons' ? (
        <div className={cx('logo', iconClass, className)}>
          {icon ? (
            <svg
              width="42"
              height="42"
              viewBox="0 0 600 600"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="600" height="600" fill="#FF4307" />
              <path
                d="M139 440C139 445.523 143.477 450 149 450H221.959C227.482 450 231.959 445.523 231.959 440V355C231.959 349.477 236.436 345 241.959 345H343.767C416.591 345 462 307.286 462 247.714C462 186.857 416.163 150 342.91 150H149C143.477 150 139 154.477 139 160V440ZM327.488 213.429C354.048 213.429 369.898 225.857 369.898 249C369.898 272.143 354.048 284.571 327.488 284.571H241.959C236.436 284.571 231.959 280.094 231.959 274.571V223.429C231.959 217.906 236.436 213.429 241.959 213.429H327.488Z"
                fill="white"
              />
            </svg>
          ) : (
            <svg
              width="216"
              height="31"
              viewBox="0 0 216 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.832 24.5H6.474V18.13H13.26C17.68 18.13 20.436 15.842 20.436 12.228C20.436 8.536 17.654 6.3 13.208 6.3H0.832V24.5ZM12.272 10.148C13.884 10.148 14.846 10.902 14.846 12.306C14.846 13.71 13.884 14.464 12.272 14.464H6.474V10.148H12.272ZM17.1598 24.5H22.9058L24.4138 20.938H32.8638L34.3718 24.5H40.4818L32.0058 6.3H25.6358L17.1598 24.5ZM28.6518 10.85L31.3818 17.48H25.8698L28.6518 10.85ZM43.732 24.5H49.4V10.2H57.018V6.3H36.088V10.2H43.732V24.5ZM58.2148 24.5H63.8568V17.766H69.8628C71.2148 17.766 71.7608 18.286 71.8128 19.638C71.8648 22.186 71.9168 23.512 72.4108 24.5H78.2348C77.6108 23.226 77.4808 21.484 77.4028 19.43C77.3248 17.584 76.6488 16.414 74.9328 15.894C76.8308 15.088 77.8708 13.476 77.8708 11.474C77.8708 8.224 75.3228 6.3 70.8768 6.3H58.2148V24.5ZM69.9668 10.096C71.4488 10.096 72.3068 10.798 72.3068 12.124C72.3068 13.424 71.4228 14.126 69.9668 14.126H63.8568V10.096H69.9668ZM89.6443 24.942C96.1443 24.942 100.668 21.328 100.668 15.4C100.668 9.472 96.1443 5.858 89.6443 5.858C83.1183 5.858 78.6203 9.472 78.6203 15.4C78.6203 21.328 83.1183 24.942 89.6443 24.942ZM89.6443 20.886C86.5503 20.886 84.4443 18.962 84.4443 15.4C84.4443 11.838 86.5503 9.94 89.6443 9.94C92.7123 9.94 94.8183 11.838 94.8183 15.4C94.8183 18.962 92.7123 20.886 89.6443 20.886ZM101.937 24.5H106.799V18.806L106.773 13.086L110.933 18.78L115.197 24.5H121.359V6.3H116.497L116.549 17.792L112.337 12.046L108.073 6.3H101.937V24.5ZM133.256 24.89C139.288 24.89 142.85 22.498 142.85 18.988C142.85 14.698 137.78 13.97 133.698 13.346C130.916 12.93 128.576 12.566 128.576 11.188C128.576 10.278 129.59 9.524 132.242 9.524C134.92 9.524 136.688 10.59 137.13 12.384H142.538C141.862 8.276 138.04 5.91 132.06 5.91C126.262 5.91 122.83 8.25 122.83 11.708C122.83 15.972 127.666 16.726 131.722 17.298C134.634 17.714 137.13 18.052 137.13 19.534C137.13 20.496 135.934 21.276 133.1 21.276C130.292 21.276 128.732 20.314 128.316 18.312H122.674C123.402 22.524 127.12 24.89 133.256 24.89Z"
                fill="white"
              />
              <path
                d="M152 12.832C150.296 12.832 148.928 14.2 148.928 15.88C148.928 17.584 150.296 18.952 152 18.952C153.68 18.952 155.048 17.584 155.048 15.88C155.048 14.2 153.68 12.832 152 12.832Z"
                fill="#FF4307"
              />
              <path
                d="M159.844 24.5H162.626L164.732 19.794H174.82L176.926 24.5H179.838L171.336 6.3H168.32L159.844 24.5ZM166.188 16.518L169.776 8.536L173.338 16.518L173.936 17.87H165.59L166.188 16.518ZM180.562 24.5H183.188V16.674H190.962C192.964 16.674 193.9 17.376 193.926 19.352C193.952 22.108 194.108 23.46 194.55 24.5H197.254C196.734 23.252 196.578 21.64 196.526 19.274C196.474 17.272 195.746 16.128 194.108 15.686C196.006 15.01 197.15 13.398 197.15 11.24C197.15 8.12 194.836 6.3 191.222 6.3H180.562V24.5ZM190.988 8.328C193.172 8.328 194.55 9.472 194.55 11.5C194.55 13.528 193.172 14.698 190.988 14.698H183.188V8.328H190.988ZM205.08 24.5H207.732V8.354H215.48V6.3H197.332V8.354H205.08V24.5Z"
                fill="white"
              />
            </svg>
          )}
        </div>
      ) : (
        <div className={cx('logo', iconClass, className)}>
          {icon ? (
            <svg
              width="42"
              height="36"
              viewBox="0 0 42 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 35.5036V0.0240479H25.0144V7.16616H9.35406V14.3068H23.8956V21.4042H9.35557V35.4976L0 35.5036Z"
                fill="white"
              />
              <path d="M28.0713 35.5036V21.9379H42V35.5036H28.0713Z" fill="#D6222A" />
            </svg>
          ) : (
            <svg
              width="139"
              height="39"
              viewBox="0 0 381 59"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 58.1059V0.901367H40.3314V12.4168H15.0818V23.9298H38.5275V35.3731H15.0842V58.0963L0 58.1059Z"
                fill="currentColor"
              />
              <path
                d="M32.9556 58.1059L60.0067 0.901367H71.9031L98.9542 58.1059H83.8554L78.8541 46.5016H53.0387L48.0374 58.1059H32.9556ZM65.8262 16.4788L57.9576 35.0583H73.86L65.9913 16.4788H65.8262Z"
                fill="currentColor"
              />
              <path
                d="M104.108 58.106V0.90146H115.175L141.735 33.6787V0.891846H155.671V58.0964H144.685L118.127 25.3191V58.106H104.108Z"
                fill="currentColor"
              />
              <path
                d="M184.444 59.0001C180.044 59.018 175.661 58.4596 171.409 57.3392C167.257 56.2304 163.842 54.7554 161.163 52.9143L165.344 41.31C167.856 42.9876 170.807 44.3136 174.196 45.2878C177.525 46.2523 180.975 46.7435 184.444 46.7468C188.052 46.7468 190.579 46.2733 192.026 45.3263C193.473 44.3793 194.201 43.2031 194.211 41.7979C194.225 41.2125 194.094 40.6326 193.83 40.1089C193.565 39.5852 193.175 39.1335 192.694 38.7934C191.684 38.0371 189.894 37.3882 187.326 36.8466L177.733 34.818C167.35 32.6003 162.159 27.2171 162.159 18.6685C162.159 14.9366 163.183 11.6629 165.232 8.84757C167.281 6.03221 170.137 3.85459 173.8 2.31471C177.46 0.774838 181.722 0.00409796 186.588 0.00249559C190.435 -0.0180086 194.263 0.542868 197.94 1.66576C201.519 2.7762 204.432 4.27762 206.68 6.17001L202.5 17.0437C200.312 15.4762 197.873 14.2835 195.287 13.5153C192.416 12.6562 189.431 12.2316 186.432 12.2558C183.37 12.2558 181.023 12.7838 179.391 13.8397C177.76 14.8957 176.94 16.2617 176.932 17.9378C176.923 18.506 177.052 19.068 177.308 19.5763C177.565 20.0846 177.941 20.5243 178.406 20.8581C179.391 21.6176 181.14 22.2674 183.652 22.8074L193.162 24.836C203.709 27.0553 208.983 32.303 208.984 40.5793C208.984 44.3128 207.973 47.5592 205.95 50.3185C203.926 53.0777 201.085 55.2145 197.425 56.7287C193.738 58.2414 189.411 58.9985 184.444 59.0001Z"
                fill="currentColor"
              />
              <path d="M217.943 58.106V36.2336H240.401V58.106H217.943Z" fill="#D6222A" />
              <path d="M250.989 58.1059V0.901367H266.071V58.1059H250.989Z" fill="currentColor" />
              <path
                d="M273.286 58.106V0.90146H284.352L310.911 33.6883V0.891846H324.846V58.0964H313.863L287.309 25.3191V58.106H273.286Z"
                fill="currentColor"
              />
              <path
                d="M361.327 59.0001C354.932 59.0001 349.439 57.7823 344.849 55.3467C340.377 53.0295 336.708 49.4399 334.315 45.0403C331.887 40.6065 330.673 35.4125 330.673 29.4581C330.673 23.5037 331.887 18.324 334.315 13.9191C336.717 9.53609 340.385 5.96132 344.849 3.65112C349.441 1.21713 354.934 0.000128076 361.327 0.000128076C365.086 -0.00998791 368.823 0.579211 372.393 1.74511C375.944 2.90843 378.813 4.43708 381 6.33108L376.491 18.0965C374.287 16.4763 371.858 15.1809 369.278 14.2508C366.909 13.4179 364.414 12.9919 361.9 12.9913C356.816 12.9913 352.99 14.4118 350.423 17.2528C347.856 20.0938 346.572 24.1622 346.57 29.4581C346.57 34.8164 347.855 38.9152 350.423 41.7546C352.992 44.594 356.818 46.0137 361.9 46.0137C364.414 46.014 366.909 45.5888 369.278 44.7567C371.858 43.8275 374.288 42.5321 376.491 40.911L381 52.6884C378.813 54.5824 375.944 56.1111 372.393 57.2744C368.822 58.4337 365.085 59.0164 361.327 59.0001Z"
                fill="currentColor"
              />
            </svg>
          )}
        </div>
      )}
    </>
  );
};

Logo.defaultProps = {
  icon: false
};

export default Logo;
