import { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input } from 'antd';
import Recaptcha from 'react-recaptcha';
import useToast from '@libs/utils/toast';
import { useParams, Redirect } from 'react-router-dom';

import { useInvitation } from '@libs/hooks/invitation';
import { useUser } from '@libs/hooks/user';
import config from '@/config';

function AuthJoinMember() {
  const toast = useToast();
  const { t } = useTranslation();
  const { id } = useParams();

  const { data: invitation } = useInvitation(id);
  const { signUp, loading } = useUser();
  const [isVerified, setIsVerified] = useState();
  const [captchaToken, setCaptchaToken] = useState();
  const verifyCallback = async (response) => {
    if (response) {
      setCaptchaToken(response);
      setIsVerified(true);
    }
  };

  const onFinish = useCallback(
    (values) => {
      if (isVerified) {
        const payload = {
          ...values,
          captchaToken
        };
        signUp(payload);
      } else {
        toast(t('auth.joinMember.verify'), 'error');
      }
    },
    [isVerified] // eslint-disable-line
  );

  if (!id) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <div className="auth-join">
        <div className="auth-join__wrapper">
          <div className="auth-join__title">{t('auth.joinMember.patrons.title')}</div>
          <div className="auth-join__description">
            {t('auth.joinMember.instruction', { organization: invitation?.account?.name })}
          </div>
          {invitation && (
            <Form
              name="join"
              onFinish={onFinish}
              initialValues={{
                email: invitation?.email
              }}
            >
              <Form.Item
                name="email"
                rules={[
                  { type: 'email', message: t('auth.fields.email.type') },
                  { required: true, message: t('auth.fields.email.required') }
                ]}
              >
                <Input readOnly placeholder={t('auth.fields.email.placeholder')} />
              </Form.Item>
              <Form.Item
                name="firstName"
                rules={[
                  { required: true, message: t('auth.fields.firstName.required') },
                  { pattern: /^[a-zA-Z\s]*$/, message: t('auth.fields.firstName.invalid') }
                ]}
              >
                <Input autoComplete="off" placeholder={t('auth.fields.firstName.placeholder')} />
              </Form.Item>
              <Form.Item
                name="lastName"
                rules={[
                  { required: true, message: t('auth.fields.lastName.required') },
                  { pattern: /^[a-zA-Z\s]*$/, message: t('auth.fields.lastName.invalid') }
                ]}
              >
                <Input autoComplete="off" placeholder={t('auth.fields.lastName.placeholder')} />
              </Form.Item>
              <Form.Item
                autoComplete="off"
                name="password"
                rules={[
                  { required: true, message: t('auth.fields.password.required') },
                  {
                    pattern: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                    message: t('auth.fields.password.strength')
                  },
                  { min: 8, message: t('auth.fields.password.minLength') },
                  { max: 64, message: t('auth.fields.password.maxLength') }
                ]}
              >
                <Input type="password" placeholder={t('auth.fields.password.placeholder')} />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                dependencies={['password']}
                rules={[
                  { required: true, message: t('auth.fields.confirmPassword.required') },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(t('auth.fields.confirmPassword.notMatch'));
                    }
                  })
                ]}
              >
                <Input type="password" placeholder={t('auth.fields.confirmPassword.placeholder')} />
              </Form.Item>
              <div className="auth-box__recaptcha">
                <div className="auth-box__recaptcha__body">
                  <Recaptcha
                    sitekey={config.cookie.TOKEN_SITE_KEY_CAPTCHA}
                    render="explicit"
                    verifyCallback={verifyCallback}
                  />
                </div>
              </div>
              <div className="auth-join__button">
                <Button block type="primary" htmlType="submit" loading={loading}>
                  {t('auth.buttons.joinMember')}
                </Button>
              </div>
            </Form>
          )}
        </div>
      </div>
      <Helmet>
        <title>{t('auth.joinMember.title')}</title>
      </Helmet>
    </>
  );
}

export default AuthJoinMember;
