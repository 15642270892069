import { useEffect, useState } from 'react';
import { useProfileOrganisation } from '@libs/hooks/organisation';

export const useNotificationParams = (limit) => {
  const deletedType = [
    'COLLECTIBLE_SOLD',
    'COLLECTIBLE_OFFER_MADE',
    'COLLECTIBLE_OFFER_RECEIVED',
    'USER_FOLLOWED',
    'COLLECTIBLE_PUBLISHED',
    'COLLECTIBLE_BID_RECEIVED',
    'COLLECTIBLE_BID_SOLD'
  ];
  const { data: profile } = useProfileOrganisation(true);
  const [query, setQuery] = useState({
    limit: limit,
    sort: { direction: 'desc', field: 'createdAt' },
    filter: {}
  });

  const onChangeFilter = (Filter) => {
    if (Filter === 'Unread') {
      const filter = {
        ...query.filter,
        searchableRead: { eq: 'FALSE' }
      };
      setQuery({ ...query, nextToken: undefined, filter });
    } else {
      const filter = {
        ...query.filter,
        searchableRead: undefined
      };
      setQuery({ ...query, nextToken: undefined, filter });
    }
  };

  const onChangeCreator = (creator) => {
    if (creator === 'All') {
      const filter = {
        ...query.filter,
        userID: undefined,
        or: profile.map((c) => ({ userID: { eq: c.value } }))
      };
      setQuery({ ...query, nextToken: undefined, filter });
    } else {
      const filter = {
        ...query.filter,
        or: undefined,
        userID: { eq: creator }
      };
      setQuery({ ...query, nextToken: undefined, filter });
    }
  };

  const onPaginate = (nextToken) => {
    setQuery({ ...query, nextToken });
  };

  useEffect(() => {
    if (profile.length > 0) {
      setQuery({
        limit: limit,
        sort: { direction: 'desc', field: 'createdAt' },
        filter: {
          or: profile.map((c) => ({ userID: { eq: c.value } })),
          not: { or: deletedType.map((d) => ({ searchableType: { eq: d } })) }
        }
      });
    }
  }, [profile]); // eslint-disable-line

  return {
    query,
    onChangeFilter,
    onChangeCreator,
    onPaginate
  };
};

export const useNotificationAdminParams = (limit, user) => {
  const adminNotifType = [
    'COLLECTIBLE_SUBMITTED_ADMIN',
    'PRINT_ORDER_PAYMENT_CONFIRMED_ADMIN',
    'WITHDRAW_REQUESTED_ADMIN'
  ];
  const [query, setQuery] = useState({
    limit: limit,
    sort: { direction: 'desc', field: 'createdAt' },
    filter: {
      userID: { eq: user?.id },
      or: adminNotifType.map((d) => ({ searchableType: { eq: d } }))
    }
  });

  const onChangeFilter = (Filter) => {
    if (Filter === 'Unread') {
      const filter = {
        ...query.filter,
        searchableRead: { eq: 'FALSE' }
      };
      setQuery({ ...query, nextToken: undefined, filter });
    } else {
      const filter = {
        ...query.filter,
        searchableRead: undefined
      };
      setQuery({ ...query, nextToken: undefined, filter });
    }
  };

  const onPaginate = (nextToken) => {
    setQuery({ ...query, nextToken });
  };

  useEffect(() => {
    if (user) {
      setQuery({
        limit: limit,
        sort: { direction: 'desc', field: 'createdAt' },
        filter: {
          userID: { eq: user?.id },
          or: adminNotifType.map((a) => ({ searchableType: { eq: a } }))
        }
      });
    }
  }, [user]); // eslint-disable-line

  return {
    query,
    onChangeFilter,
    onPaginate
  };
};
