export const searchWalletHistories = /* GraphQL */ `
  query SearchWalletHistories(
    $filter: SearchableWalletHistoryFilterInput
    $sort: SearchableWalletHistorySortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchWalletHistories(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        senderID
        receiverID
        paymentProvider
        debit
        credit
        invoiceID
        referenceID
        exchangeID
        balance
        transactionType
        status
        description
        withdrawal {
          provider
          destination
        }
        createdAt
        updatedAt
        searchableTransactionType
        searchableStatus
        _version
        _deleted
        sender {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address {
            recipientName
            line1
            country
            stateName
            city
            postCode
            phone
          }
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        receiver {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          media {
            avatarUrl
          }
          address {
            recipientName
            line1
            country
            stateName
            city
            postCode
            phone
          }
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        owner
      }
      nextToken
      total
    }
  }
`;
