import { useHistory } from 'react-router';
import { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import gql from '@libs/utils/gql';
import useToast from '@libs/utils/toast';
import { useAuth } from '@libs/contexts/auth';
import { useCollectibleParams, useCollectibleParamsAdmin } from '@libs/hooks/collectible/utils';
import {
  searchCollectibles,
  listCollectibles,
  getCollectible,
  collectibleLikeByCollectible
} from '@libs/custom-queries/collectible';
import {
  customCreateCollectible,
  updateCollectibleDraft,
  publishCollectible,
  requestRevisionCollectible,
  removeData
} from '@graphql/mutations';
import {
  listFeaturedArtists,
  collectibleBySlug,
  featuredArtistsCollectibleByCollectibleAndArtists
} from '@graphql/queries';

import { createFeaturedArtist } from '@graphql/mutations';
import { useProfileOrganisation } from '@libs/hooks/organisation';

export const useCollectibles = (limit = 120, sort = 'DESC', disableQueries = true) => {
  const toast = useToast();
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [reviewLoading, setReviewLoading] = useState(false);
  const [data, setData] = useState();

  const getData = async (limit, sort) => {
    setLoading(true);
    try {
      const params = { limit, sortDirection: sort };
      const { data: res } = await gql(listCollectibles, params);

      if (res?.listCollectibles?.items?.length) {
        setData(res.listCollectibles.items);
      } else {
        setData([]);
      }
    } catch (error) {
      const errorMessage = error.errors[0]?.errorType;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!disableQueries) getData(limit, sort);
  }, [limit, sort]); // eslint-disable-line

  const onCreate = useCallback(
    async (payload, submitForReview = false, noRedirect, hideToast) => {
      if (submitForReview) {
        setReviewLoading(true);
      } else {
        setCreateLoading(true);
      }
      try {
        const data = await gql(
          customCreateCollectible,
          {
            input: {
              userID: payload.userID,
              title: payload.title,
              slug: payload.slug,
              category: payload.selectedCategory,
              collectionID: payload.collectionID,
              genre: payload.genre,
              featuredArtist: payload.featuredArtist,
              description: payload.description,
              insideStory: payload.insideStory,
              assetUrl: payload.assetUrl,
              mediaThumbnail: payload.mediaThumbnail,
              maxNumberedEditions: payload.maxNumberedEditions,
              releases: payload.releases,
              featuredInProfile: payload.featuredInProfile ? 'TRUE' : 'FALSE',
              yearOfCreation: payload.yearOfCreation,
              whatsIncludedOriginal: payload.whatsIncludedOriginal,
              whatsIncludedNumbered: payload.whatsIncludedNumbered,
              marketingText: payload.marketingText,
              metaTitle: payload.metaTitle,
              metaDescription: payload.metaDescription,
              originalFrame: payload.originalFrame,
              smallFrame: payload.smallFrame,
              perksOriginal: payload.perksOriginal,
              perksNumbered: payload.perksNumbered,
              streamExternal: payload.streamExternal ? 'TRUE' : 'FALSE',
              streamUrl: payload.streamUrl,
              submitForReview,
              promoEnabled: payload.promoEnabled,
              printable: payload.printable,
              promoTitle: payload.promoTitle,
              promoDescription: payload.promoDescription,
              faq: payload.faq,
              width: payload.width,
              height: payload.height,
              smallPrint: payload.smallPrint,
              smallWidth: payload.smallWidth,
              smallHeight: payload.smallHeight,
              largeWidth: payload.largeWidth,
              largeHeight: payload.largeHeight
            }
          },
          {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }
        );

        if (!hideToast) {
          toast(
            submitForReview
              ? t('collectibles.toast.submitForReview.success')
              : t('collectibles.toast.draft.success'),
            'success'
          );
        }

        if (!noRedirect) {
          history.push(`/collectibles/edit/${data?.data?.customCreateCollectible}`);
        }
      } catch (error) {
        const errorMessage = error.errors[0]?.message;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setCreateLoading(false);
        setReviewLoading(false);
      }
    },
    [history] // eslint-disable-line
  );
  const isSlugAvailable = async (slug) => {
    if (!slug) {
      return;
    }

    try {
      const { data: res } = await gql(collectibleBySlug, { slug });
      const isExists = !!res?.collectibleBySlug?.items?.length;
      return { available: !isExists, slug: !isExists ? slug : `${slug}-2` };
    } catch (err) {
      return false;
    }
  };

  return {
    data,
    loading,
    createLoading,
    reviewLoading,
    onCreate,
    isSlugAvailable
  };
};

export const useCollectiblesByAccount = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [updateFeaturedLoading, setUpdateFeaturedLoading] = useState(false);
  const [data, setData] = useState();
  const { data: profile } = useProfileOrganisation(true);
  const [nextPageToken, setNextPageToken] = useState();
  const { user } = useAuth();
  const { query: params, onSearch, onPaginate } = useCollectibleParams(user?.accountID);

  const getData = async () => {
    setLoading(true);
    try {
      if (params) {
        const { data: result } = await gql(searchCollectibles, params);
        if (result?.searchCollectibles?.items) {
          setData(result?.searchCollectibles?.items);
        }
        if (result?.searchCollectibles?.items.length < 10) {
          setNextPageToken();
        } else {
          setNextPageToken(result?.searchCollectibles?.nextToken);
        }
      }
    } catch (error) {
      const errorMessage = error.errors[0]?.errorType;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [params]); // eslint-disable-line

  const onUpdateFeatured = async (payload) => {
    const { releases } = payload;

    const updateReleases = releases.map((release) => {
      delete release['soldCount'];
      return release;
    });
    setUpdateFeaturedLoading(true);
    try {
      const { data: artist } = await gql(featuredArtistsCollectibleByCollectibleAndArtists, {
        collectibleID: payload.id
      });
      let featuredArtist = [];

      if (artist?.featuredArtistsCollectibleByCollectibleAndArtists?.items) {
        featuredArtist =
          artist?.featuredArtistsCollectibleByCollectibleAndArtists?.items.map((x) => ({
            label: x.featuredArtist.artistName,
            value: x.featuredArtist.id
          })) || [];
      }

      if (featuredArtist.length < 1) {
        toast(t('collectibles.toast.featuredErrorArtis'), 'error');
        getData();
        return false;
      }

      await gql(
        updateCollectibleDraft,
        {
          input: {
            collectibleID: payload.id,
            userID: payload.userID,
            title: payload.title,
            category: payload.category,
            genre: payload.genre,
            description: payload.description,
            insideStory: payload.insideStory,
            assetUrl: payload.assetUrl,
            mediaThumbnail: payload.mediaThumbnail,
            maxNumberedEditions: payload.maxNumberedEditions,
            releases: updateReleases,
            featuredInProfile: payload.featuredInProfile,
            yearOfCreation: payload.yearOfCreation,
            whatsIncludedOriginal: payload.whatsIncludedOriginal,
            whatsIncludedNumbered: payload.whatsIncludedNumbered,
            marketingText: payload.marketingText,
            metaDescription: payload.metaDescription,
            submitForReview: 'FALSE',
            slug: payload.slug,
            featuredArtist: featuredArtist
          }
        },
        {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }
      );

      toast(t('collectibles.toast.featured'), 'success');
    } catch (error) {
      const errorMessage = error.errors[0]?.message;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setUpdateFeaturedLoading(false);
      getData();
    }
  };

  useEffect(() => {
    getData();
  }, [user, profile]); // eslint-disable-line

  return {
    data,
    loading,
    updateFeaturedLoading,
    nextPageToken,
    getData,
    onSearch,
    onPaginate,
    onUpdateFeatured
  };
};

export const useCollectible = (id) => {
  const toast = useToast();
  const { t } = useTranslation();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [reviewLoading, setReviewLoading] = useState(false);
  const [data, setData] = useState();

  const getData = async (id) => {
    setLoading(true);
    try {
      const { data: res } = await gql(getCollectible, { id });

      if (res?.getCollectible) {
        setData(res.getCollectible);
      } else {
        setData(null);
      }
    } catch (error) {
      const errorMessage = error.errors[0]?.errorType;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) getData(id);
  }, [id]); // eslint-disable-line

  const onUpdate = async (collectibleID, payload, submitForReview = false) => {
    const { releases } = payload;

    const updateReleases = releases.map((release) => {
      delete release['soldCount'];
      return release;
    });

    if (submitForReview) {
      setReviewLoading(true);
    } else {
      setUpdateLoading(true);
    }
    try {
      await gql(
        updateCollectibleDraft,
        {
          input: {
            collectibleID,
            userID: payload.userID,
            title: payload.title,
            slug: payload.slug,
            category: payload.category,
            genre: payload.genre,
            collectionID: payload.collectionID,
            featuredArtist: payload.featuredArtist,
            description: payload.description,
            insideStory: payload.insideStory,
            assetUrl: payload.assetUrl,
            mediaThumbnail: payload.mediaThumbnail,
            maxNumberedEditions: payload.maxNumberedEditions,
            releases: updateReleases,
            featuredInProfile: payload.featuredInProfile ? 'TRUE' : 'FALSE',
            yearOfCreation: payload.yearOfCreation,
            whatsIncludedOriginal: payload.whatsIncludedOriginal,
            whatsIncludedNumbered: payload.whatsIncludedNumbered,
            marketingText: payload.marketingText,
            metaTitle: payload.metaTitle,
            metaDescription: payload.metaDescription,
            perksOriginal: payload.perksOriginal,
            perksNumbered: payload.perksNumbered,
            streamExternal: payload.streamExternal ? 'TRUE' : 'FALSE',
            streamUrl: payload.streamUrl,
            submitForReview,
            promoEnabled: payload.promoEnabled,
            printable: payload.printable,
            originalFrame: payload.originalFrame,
            smallFrame: payload.smallFrame,
            smallPrint: payload.smallPrint,
            promoTitle: payload.promoTitle,
            promoDescription: payload.promoDescription,
            faq: payload.faq,
            width: payload.width,
            height: payload.height,
            smallWidth: payload.smallWidth,
            smallHeight: payload.smallHeight,
            largeWidth: payload.largeWidth,
            largeHeight: payload.largeHeight
          }
        },
        {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }
      );

      toast(
        data.status === 'PUBLISHED'
          ? t('collectibles.toast.update.success')
          : submitForReview
          ? t('collectibles.toast.submitForReview.success')
          : t('collectibles.toast.draft.success'),
        'success'
      );
      if (submitForReview) {
        history.push('/collectibles');
      }
    } catch (error) {
      const errorMessage = error.errors[0]?.message;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setUpdateLoading(false);
      setReviewLoading(false);
    }
  };

  const isSlugAvailable = async (slug) => {
    if (!slug) {
      return;
    }

    try {
      const { data: res } = await gql(collectibleBySlug, { slug });
      const { data: collectible } = await gql(getCollectible, { id });
      if (!!res?.collectibleBySlug?.items?.length) {
        return !!(res?.collectibleBySlug?.items[0].slug === collectible?.getCollectible?.slug);
      } else {
        return true;
      }
    } catch (err) {
      return false;
    }
  };

  return {
    data,
    loading,
    updateLoading,
    reviewLoading,
    getData,
    onUpdate,
    isSlugAvailable
  };
};

export const useCollectiblesInReview = (autoFetch = true) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [approvalLoading, setApprovalLoading] = useState(false);
  const [data, setData] = useState();

  const getData = async () => {
    setLoading(true);
    try {
      const params = {
        filter: { searchableStatus: { eq: 'IN_REVIEW' } }
      };
      const { data: res } = await gql(searchCollectibles, params);

      if (res?.searchCollectibles?.items?.length) {
        setData(res.searchCollectibles.items);
      } else {
        setData([]);
      }
    } catch (error) {
      const errorMessage = error.errors[0]?.errorType;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (autoFetch) {
      getData();
    }
  }, [autoFetch]); // eslint-disable-line

  const onApprove = useCallback(
    async (collectibleID) => {
      setApprovalLoading(true);
      try {
        await gql(
          publishCollectible,
          {
            input: {
              collectibleID
            }
          },
          {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }
        );

        toast('Collectible published succesfully', 'success');
      } catch (error) {
        const errorMessage = error.errors[0]?.errorType;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setApprovalLoading(false);
      }
    },
    [] // eslint-disable-line
  );

  const onRequestRevison = useCallback(
    async (collectibleID, description) => {
      setApprovalLoading(true);
      try {
        await gql(
          requestRevisionCollectible,
          {
            input: {
              collectibleID,
              description
            }
          },
          {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }
        );

        toast('Revision requested', 'success');
      } catch (error) {
        const errorMessage = error.errors[0]?.errorType;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setApprovalLoading(false);
      }
    },
    [] // eslint-disable-line
  );

  return {
    data,
    loading,
    approvalLoading,
    onApprove,
    onRequestRevison
  };
};

export const useAllCollectibles = () => {
  const toast = useToast();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [nextPageToken, setNextPageToken] = useState();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [updateFeaturedLoading, setUpdateFeaturedLoading] = useState(false);

  const [data, setData] = useState();
  const limitConst = 10;

  const {
    query: params,
    onSearch,
    onPaginate,
    onStatusChanges
  } = useCollectibleParamsAdmin(limitConst);

  const getData = async (limit = limitConst) => {
    setLoading(true);
    try {
      const { data: res } = await gql(searchCollectibles, params);
      if (res?.searchCollectibles?.items?.length) {
        setData(res.searchCollectibles.items);
      } else {
        setData([]);
      }

      if (res?.searchCollectibles?.items.length < limit) {
        setNextPageToken();
      } else {
        setNextPageToken(res?.searchCollectibles?.nextToken);
      }
    } catch (error) {
      const errorMessage = error.errors[0]?.errorType;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onDelete = useCallback(
    async (payload) => {
      setDeleteLoading(true);
      try {
        await gql(
          removeData,
          {
            input: {
              id: payload.id,
              item: payload.item
            }
          },
          {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }
        );

        toast('Collectible deleted succesfully', 'success');
      } catch (error) {
        const errorMessage = error.errors[0]?.errorType;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setDeleteLoading(false);
      }
    },
    [] // eslint-disable-line
  );

  const onUpdateFeatured = async (payload) => {
    setUpdateFeaturedLoading(true);

    const { releases } = payload;

    const updateReleases = releases.map((release) => {
      delete release['soldCount'];
      return release;
    });

    try {
      const { data: artist } = await gql(featuredArtistsCollectibleByCollectibleAndArtists, {
        collectibleID: payload.id
      });
      let featuredArtist = [];

      if (artist?.featuredArtistsCollectibleByCollectibleAndArtists?.items) {
        featuredArtist =
          artist?.featuredArtistsCollectibleByCollectibleAndArtists?.items.map((x) => ({
            label: x.featuredArtist.artistName,
            value: x.featuredArtist.id
          })) || [];
      }

      if (featuredArtist.length < 1) {
        toast(t('collectibles.toast.featuredErrorArtis'), 'error');
        getData();
        return false;
      }

      await gql(
        updateCollectibleDraft,
        {
          input: {
            collectibleID: payload.id,
            userID: payload.userID,
            title: payload.title,
            category: payload.category,
            genre: payload.genre,
            description: payload.description,
            insideStory: payload.insideStory,
            assetUrl: payload.assetUrl,
            mediaThumbnail: payload.mediaThumbnail,
            maxNumberedEditions: payload.maxNumberedEditions,
            releases: updateReleases,
            featuredInProfile: payload.featuredInProfile,
            yearOfCreation: payload.yearOfCreation,
            whatsIncludedOriginal: payload.whatsIncludedOriginal,
            whatsIncludedNumbered: payload.whatsIncludedNumbered,
            marketingText: payload.marketingText,
            metaDescription: payload.metaDescription,
            submitForReview: 'FALSE',
            slug: payload.slug,
            featuredArtist: featuredArtist
          }
        },
        {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }
      );

      toast(t('collectibles.toast.featured'), 'success');
    } catch (error) {
      const errorMessage = error.errors[0]?.message;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setUpdateFeaturedLoading(false);
      getData();
    }
  };

  useEffect(() => {
    getData();
  }, [params]); // eslint-disable-line

  return {
    data,
    nextPageToken,
    loading,
    deleteLoading,
    updateFeaturedLoading,
    getData,
    onDelete,
    onSearch,
    onPaginate,
    onUpdateFeatured,
    onStatusChanges
  };
};

export const useFeaturedArtist = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState([]);
  const { user } = useAuth();
  const getData = async () => {
    setLoading(true);
    try {
      if (user) {
        const { data: res } = await gql(listFeaturedArtists);
        if (res?.listFeaturedArtists?.items) {
          setOptions(
            res?.listFeaturedArtists?.items.map((x) => ({ label: x.artistName, value: x.id })) || []
          );
        }
      }
    } catch (error) {
      const errorMessage = error.errors[0]?.errorType;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onCreate = useCallback(
    async (payload) => {
      setLoading(true);
      try {
        const { data: res } = await gql(
          createFeaturedArtist,
          {
            input: {
              artistName: payload.name
            }
          },
          {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }
        );

        if (res?.createFeaturedArtist) {
          const newOpt = {
            label: res.createFeaturedArtist.artistName,
            value: res.createFeaturedArtist.id
          };

          const newValue = Array.isArray(value) && !payload?.reset ? [...value, newOpt] : [newOpt];
          await getData();
          setValue(newValue);
        }
      } catch (error) {
        const errorMessage = error.errors[0]?.errorType;
        toast(errorMessage, 'error');
      } finally {
        setLoading(false);
      }
    },
    [value] // eslint-disable-line
  );

  useEffect(() => {
    getData();
  }, [user]); // eslint-disable-line

  return {
    options,
    loading,
    value,
    onChange: (v) => setValue(v),
    getData,
    onCreate
  };
};

export const useFeaturedArtistByCollectible = (collectibleID) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { user } = useAuth();
  const getData = async (collectibleID) => {
    setLoading(true);
    try {
      if (user) {
        const params = {
          collectibleID: collectibleID
        };

        const { data: res } = await gql(featuredArtistsCollectibleByCollectibleAndArtists, params);
        if (res?.featuredArtistsCollectibleByCollectibleAndArtists?.items) {
          setData(res?.featuredArtistsCollectibleByCollectibleAndArtists?.items);
        }
      }
    } catch (error) {
      const errorMessage = error.errors[0]?.errorType;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData(collectibleID);
  }, [user, collectibleID]); // eslint-disable-line

  return {
    data,
    loading,
    getData
  };
};

export const useCollectibleByCreator = (creatorID) => {
  const toast = useToast();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    try {
      const params = {
        filter: {
          userID: { eq: creatorID },
          searchableStatus: { eq: 'PUBLISHED' }
        }
      };

      const { data: res } = await gql(searchCollectibles, params);
      if (res?.searchCollectibles?.items) {
        setData(res?.searchCollectibles?.items);
      } else {
        setData([]);
      }
    } catch (error) {
      const errorMessage = error.errors[0]?.errorType;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (creatorID) {
      getData();
    }
  }, [creatorID]); // eslint-disable-line

  return {
    data,
    loading
  };
};

export const useCollectibleLikesCountByCollectibleID = (collectibleID) => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(0);
  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        const { data: res } = await gql(collectibleLikeByCollectible, {
          collectibleID
        });

        if (res?.collectibleLikeByCollectible?.items?.length) {
          setData(res?.collectibleLikeByCollectible?.items?.length);
        } else {
          setData(0);
        }
      } catch (error) {
        const errorMessage = error.errors?.message;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    if (collectibleID) {
      getData();
    }
  }, [collectibleID]); // eslint-disable-line

  return {
    data,
    loading
  };
};
