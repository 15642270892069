import { Storage } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { createAssetUrl } from '@libs/utils/mimeType';

const Image = ({ src, alt, size, fallback, ...props }) => {
  const [image, setImage] = useState();

  useEffect(() => {
    async function fetchImage() {
      if (src && !src.startsWith('http')) {
        const s3Key = createAssetUrl(src, size);
        if (s3Key.startsWith('http')) {
          setImage(s3Key);
        } else {
          const result = await Storage.get(s3Key, { contentType: 'image/png' });
          setImage(result);
        }
      }
    }

    fetchImage();
  }, [src, size]);

  return (
    <img
      {...props}
      src={image || src}
      alt={alt}
    />
  );
};

Image.defaultProps = {
  fallback: ''
};

export default Image;
