import { useState, useEffect, useCallback } from 'react';

import gql from '@libs/utils/gql';
import useToast from '@libs/utils/toast';
import { searchNotifications } from '@graphql/queries';
import { updateReadNotification } from '@graphql/mutations';
import { useAuth } from '@libs/contexts/auth';
import { onNotifUpdatedV1 } from '@graphql/subscriptions';
import { useProfileOrganisation } from '@libs/hooks/organisation';
import { useNotificationParams, useNotificationAdminParams } from '@libs/hooks/notification/utils';

export const useNotification = () => {
  const toast = useToast();
  const { data: profile } = useProfileOrganisation(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const { query: params, onChangeFilter, onChangeCreator, onPaginate } = useNotificationParams(10);
  const [nextPageToken, setNextPageToken] = useState();
  const { user } = useAuth();

  const getData = async (params) => {
    if (!user && !profile.length > 0) {
      return;
    }
    setLoading(true);

    try {
      if (params) {
        const { data: result } = await gql(searchNotifications, params, {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
        if (result?.searchNotifications) {
          const notifications = result.searchNotifications.items.map((notification) => {
            notification.event = JSON.parse(notification.event);
            return notification;
          });
          const unreadNotification = notifications.filter((x) => {
            return x.searchableRead === 'FALSE';
          });
          const readNotification = notifications.filter((x) => {
            return x.searchableRead === 'TRUE';
          });
          setData(unreadNotification.concat(readNotification));
        }
        setNextPageToken(result.searchNotifications.nextToken);
      }
    } catch (error) {
      const errorMessage = error?.errors[0]?.message;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const changeFilter = (filter) => {
    setData([]);
    onChangeFilter(filter);
  };

  const changeCreator = (creator) => {
    setData([]);
    onChangeCreator(creator);
  };

  useEffect(() => {
    if (user && profile.length > 0) {
      const payload = {};
      const subscription = gql(onNotifUpdatedV1, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      }).subscribe({
        next: (_) => {
          setTimeout(function () {
            getData(params);
          }, 2000);
        },
        error: (error) => {
          console.warn(error);
        }
      });
      return () => {
        if (subscription) subscription.unsubscribe();
      };
    }
  }, [user, profile, params]); // eslint-disable-line

  useEffect(() => {
    // TODO - implement pagination and filtering of read items
    if (profile) {
      getData();
    }
  }, [user, profile]); // eslint-disable-line

  useEffect(() => {
    getData(params);
  }, [params]); // eslint-disable-line

  return {
    loading,
    data,
    nextPageToken,
    getData,
    changeFilter,
    changeCreator,
    onPaginate
  };
};

export const useUpdateReadNotification = () => {
  const [updateLoading, setUpdateLoading] = useState(false);

  const onUpdateNotification = useCallback(
    async (data) => {
      setUpdateLoading(true);
      try {
        const payload = {
          input: {
            itemType: data.itemType
          }
        };

        if (data.itemType === 'notification') {
          payload.input = {
            notificationIDs: [data.id],
            itemType: data.itemType
          };
          await gql(updateReadNotification, payload, {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          });
        }

        if (data.itemType === 'creator') {
          payload.input = {
            itemType: 'notification',
            userID: data.profile
          };
          await gql(updateReadNotification, payload, {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          });
        }
        if (data.itemType === 'admin') {
          await gql(updateReadNotification, payload, {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          });
        }
      } catch (error) {
        console.error('error update user:', error);
      } finally {
        setTimeout(function () {
          setUpdateLoading(false);
        }, 2000);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    updateLoading,
    onUpdateNotification
  };
};

export const useUnreadNotification = () => {
  const toast = useToast();
  const { data: profile } = useProfileOrganisation(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const { user } = useAuth();

  const getData = async () => {
    if (!user && !profile.length > 0) {
      return;
    }
    setLoading(true);

    try {
      const params = {
        sort: { direction: 'desc', field: 'createdAt' },
        filter: {
          or: profile.map((c) => ({ userID: { eq: c.value } })),
          searchableRead: { eq: 'FALSE' }
        }
      };
      const { data: result } = await gql(searchNotifications, params, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      if (result?.searchNotifications?.items) {
        setTotal(result?.searchNotifications?.total);
        const notifications = result.searchNotifications.items.map((notification) => {
          notification.event = JSON.parse(notification.event);
          return notification;
        });
        setData(notifications);
      }
    } catch (error) {
      const errorMessage = error?.errors[0]?.message;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user && profile.length > 0) {
      const payload = {};
      const subscription = gql(onNotifUpdatedV1, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      }).subscribe({
        next: (_) => {
          setTimeout(function () {
            getData();
          }, 2000);
        },
        error: (error) => {
          console.warn(error);
        }
      });
      return () => {
        if (subscription) subscription.unsubscribe();
      };
    }
  }, [user, profile]); // eslint-disable-line

  useEffect(() => {
    // TODO - implement pagination and filtering of read items
    if (profile) {
      getData();
    }
  }, [user, profile]); // eslint-disable-line

  return {
    loading,
    data,
    total,
    getData
  };
};

export const useAdminNotification = () => {
  const toast = useToast();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [data, setData] = useState([]);
  const { query: params, onChangeFilter, onPaginate } = useNotificationAdminParams(10, user);

  const getData = async (params) => {
    setLoading(true);

    try {
      if (params && user) {
        const { data: result } = await gql(searchNotifications, params, {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
        if (result?.searchNotifications) {
          const notifications = result.searchNotifications.items.map((notification) => {
            notification.event = JSON.parse(notification.event);
            return notification;
          });
          const unreadNotification = notifications.filter((x) => {
            return x.searchableRead === 'FALSE';
          });
          const readNotification = notifications.filter((x) => {
            return x.searchableRead === 'TRUE';
          });
          setData(unreadNotification.concat(readNotification));
        }
        setNextPageToken(result.searchNotifications.nextToken);
      }
    } catch (error) {
      const errorMessage = error?.errors[0]?.message;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const changeFilter = (filter) => {
    setData([]);
    onChangeFilter(filter);
  };

  useEffect(() => {
    getData(params);
  }, [params]); // eslint-disable-line

  useEffect(() => {
    if (user) {
      const payload = {};
      const subscription = gql(onNotifUpdatedV1, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      }).subscribe({
        next: (_) => {
          setTimeout(function () {
            getData(params);
          }, 2000);
        },
        error: (error) => {
          console.warn(error);
        }
      });
      return () => {
        if (subscription) subscription.unsubscribe();
      };
    }
  }, [user, params]); // eslint-disable-line

  return {
    data,
    loading,
    nextPageToken,
    getData,
    changeFilter,
    onPaginate
  };
};

export const useAdminUnreadNotification = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const { user } = useAuth();

  const getData = async () => {
    if (!user) {
      return;
    }
    setLoading(true);

    try {
      const adminNotifType = [
        'COLLECTIBLE_SUBMITTED_ADMIN',
        'PRINT_ORDER_PAYMENT_CONFIRMED_ADMIN',
        'WITHDRAW_REQUESTED_ADMIN'
      ];
      const params = {
        sort: { direction: 'desc', field: 'createdAt' },
        filter: {
          userID: { eq: user?.id },
          searchableRead: { eq: 'FALSE' },
          or: adminNotifType.map((d) => ({ searchableType: { eq: d } }))
        }
      };
      const { data: result } = await gql(searchNotifications, params, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      if (result?.searchNotifications?.items) {
        setTotal(result?.searchNotifications?.total);
        const notifications = result.searchNotifications.items.map((notification) => {
          notification.event = JSON.parse(notification.event);
          return notification;
        });
        setData(notifications);
      }
    } catch (error) {
      const errorMessage = error?.errors[0]?.message;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      const payload = {};
      const subscription = gql(onNotifUpdatedV1, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      }).subscribe({
        next: (_) => {
          setTimeout(function () {
            getData();
          }, 2000);
        },
        error: (error) => {
          console.warn(error);
        }
      });
      return () => {
        if (subscription) subscription.unsubscribe();
      };
    }
  }, [user]); // eslint-disable-line

  useEffect(() => {
    // TODO - implement pagination and filtering of read items
    if (user) {
      getData();
    }
  }, [user]); // eslint-disable-line

  return {
    loading,
    data,
    total,
    getData
  };
};
