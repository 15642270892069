import { Helmet } from 'react-helmet';

const CustomHelmet = () => {
  const envName = process.env.REACT_APP_NAME === 'patrons' ? 'Patrons.Art' : 'Fans.Inc';
  return (
    <Helmet encodeSpecialCharacters={true} titleTemplate={`%s - ${envName}`}>
      <meta name="description" content="Helmet application" />
      <meta property="og:type" content="article" />

      <link rel="canonical" href="https://fans.inc/example" />
      <link rel="apple-touch-icon" href="https://fans.inc/img/apple-touch-icon-57x57.png" />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href="https://fans.inc/img/apple-touch-icon-72x72.png"
      />

      <script type="application/ld+json">{`
        {
            "@context": "http://schema.org"
        }
    `}</script>
      <body className="fans" />
    </Helmet>
  );
};

export default CustomHelmet;
