import { Logo } from '@components';
import { ReactComponent as PatronsLogo } from '@assets/svg/patrons-logo.svg';

const SuspenseLoading = () => {
  return (
    <div className="suspense-loading">
      {process.env.REACT_APP_NAME === 'patrons' ? (
        <PatronsLogo />
      ) : (
        <Logo className="suspense-loading__logo" width="209" height="59" />
      )}
    </div>
  );
};

export default SuspenseLoading;
