import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Table, Input, Collapse, List } from 'antd';
import { Helmet } from 'react-helmet';
import { DashboardContent, DisplayDate, Pagination, Avatar, Link } from '@components';
import { useSearchOrganization } from '@libs/hooks/organisation';
import { useParams } from 'react-router';
import debounce from 'lodash.debounce';

function DetailOrganization() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { Search } = Input;

  const {
    data: organization,
    members,
    nextTokenMembers,
    membersLoading,
    fetchData,
    searchMember
  } = useSearchOrganization(id);

  const columns = [
    {
      title: t('users.title'),
      key: 'withdrawlID',
      responsive: ['xs'],
      render: (_, row) => {
        return (
          <div className="mobile-row">
            <div className="mobile-row__info">
              <div className="mobile-row__info__name">
                {row?.firstName} {row?.lastName}
              </div>
            </div>
            <Collapse collapsible ghost className="datalist">
              <Collapse.Panel header={t('offers.list.expand')} key="1">
                <List itemLayout="vertical">
                  <List.Item>
                    <List.Item.Meta
                      title={t('users.list.emailAddress')}
                      description={<span>{row?.email}</span>}
                    />
                    <List.Item.Meta
                      title={t('users.list.avatar')}
                      description={<Avatar user={row} />}
                    />
                    <List.Item.Meta
                      title={t('users.list.joinedAt')}
                      description={<DisplayDate date={row?.createdAt} />}
                    />
                  </List.Item>
                </List>
              </Collapse.Panel>
            </Collapse>
          </div>
        );
      }
    },
    {
      title: t('users.list.name'),
      key: 'name',
      responsive: ['sm'],
      render: (_, row) => (
        <span>
          {row?.firstName} {row?.lastName}
        </span>
      )
    },
    {
      title: t('users.list.emailAddress'),
      dataIndex: 'email',
      key: 'email',
      responsive: ['sm'],
      render: (_, row) => <span>{row?.email}</span>
    },
    {
      title: t('users.list.avatar'),
      dataIndex: 'avatar',
      key: 'avatar',
      responsive: ['sm'],
      render: (_, row) => (
        <>
          <Avatar user={row} />
        </>
      )
    },
    {
      title: t('users.list.joinedAt'),
      dataIndex: 'joinedAt',
      key: 'joinedAt',
      responsive: ['sm'],
      render: (_, row) => <DisplayDate date={row?.createdAt} />
    }
  ];
  return (
    <>
      <DashboardContent title={organization?.name} backLink="/admin/organization">
        <Row>
          <Col span={24} lg={24} className="admin-organization__heading">
            <h1 className="title">{organization?.name}</h1>
            <Row className="content-container">
              <Col span={24} lg={12}>
                <span className="subtitle">{t('organizations.form.team')}</span>
                <span>{organization?.name}</span>
              </Col>
              <Col span={24} lg={12}>
                <span className="subtitle">{t('organizations.form.address')}</span>
                <span>{organization?.address}</span>
              </Col>
            </Row>
            <Row className="content-container">
              <Col span={24} lg={12}>
                <span className="subtitle">{t('organizations.form.legal')}</span>
                <span>{organization?.legalName}</span>
              </Col>
              <Col span={24} lg={12}>
                <span className="subtitle">{t('organizations.form.createdAt')}</span>
                <span>
                  <DisplayDate date={organization?.createdAt} />
                </span>
              </Col>
            </Row>
            <Link to={`/admin/organization/edit/${id}`}>
              <Button type="primary" className="edit-organization-btn">
                {t('organizations.btn.editOrganization')}
              </Button>
            </Link>
          </Col>
        </Row>
        <Row className="admin-organization__search">
          <Col span={24} sm={12}>
            <div className="toolbar-search">
              <Search
                onChange={debounce((e) => {
                  searchMember(e.target.value);
                }, 500)}
                placeholder={t('organizations.form.searchUser')}
              />
            </div>
          </Col>
        </Row>
        <div className="users">
          <div className="fans-table">
            <Table
              bordered
              loading={membersLoading}
              dataSource={members?.map((e) => e.user)}
              columns={columns}
              pagination={false}
            />
            <Pagination
              nextToken={nextTokenMembers}
              loading={membersLoading}
              fetchData={(token) => fetchData(id, token)}
            />
          </div>
        </div>
      </DashboardContent>
      <Helmet>
        <title>{organization?.name}</title>
      </Helmet>
    </>
  );
}

export default DetailOrganization;
