const FormGroup = ({ heading, description, children }) => {
  return (
    <div className="admin-form__group">
      {heading && <div className="admin-form__group__heading">{heading}</div>}
      {description && <div className="admin-form__group__description">{description}</div>}
      <div className="admin-form__group__content">{children}</div>
    </div>
  );
};
export default FormGroup;
