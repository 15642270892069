import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Input, InputNumber, Button, Radio } from 'antd';
import { Price } from 'components';
import { ReactComponent as BitPay } from '@assets/svg/bitpay.svg';
import { ReactComponent as PayPal } from '@assets/svg/paypal-icon.svg';
import { useWithdrawal } from '@libs/hooks/withdrawal';

const Amount = ({ showModal, onCancel, balance, setModalState, creatorID }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [payment, setPayment] = useState();
  const { request: requestWithdrawal, loading } = useWithdrawal();

  const onSubmit = async (values) => {
    const { amount, destination, method } = values;
    const withdrawalID = await requestWithdrawal(method, destination, amount, creatorID);
    setModalState({ withdrawalID, step: 'verify' });
    form.resetFields();
  };

  return (
    <Modal
      className="withdrawal-modal"
      title={t('balance.withdraw')}
      visible={showModal}
      footer={null}
      width={635}
      onCancel={() => {
        onCancel();
        form.resetFields();
        setPayment();
      }}
    >
      <span className="withdrawal-modal__balance">
        {t('balance.current')}
        {': '}
        <b>
          <Price amount={balance?.amount} />
        </b>
      </span>
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        onFieldsChange={(value) => {
          const nameArr = value[0].name;
          if (nameArr[0] === 'method') {
            form.resetFields(['destination']);
          }
        }}
      >
        <Form.Item
          name="amount"
          label="Amount"
          rules={[
            { required: true, message: t('balance.modal.amount.required') },
            () => ({
              validator(_, value) {
                if (value < parseFloat(balance.amount)) {
                  return Promise.resolve();
                }
                return Promise.reject(t('balance.modal.amount.max'));
              }
            }),
            () => ({
              validator(_, value) {
                if (value > 9) {
                  return Promise.resolve();
                }
                return Promise.reject(t('balance.modal.amount.min'));
              }
            })
          ]}
        >
          <InputNumber
            className="withdrawal-modal__amount"
            style={{ width: '100%' }}
            placeholder={t('balance.modal.amount.min')}
          />
        </Form.Item>
        <Form.Item
          name="method"
          label="Payment Method"
          rules={[{ required: true, message: t('balance.modal.method.required') }]}
        >
          <Radio.Group
            onChange={(event) => {
              setPayment(event.target.value);
            }}
          >
            <div
              className={`withdrawal-modal__payment-method ${
                payment === 'PAYPAL' && 'payment-method-selected'
              }`}
            >
              <Radio value="PAYPAL" className="withdrawal-modal__payment-method-option">
                <PayPal height={20} />
              </Radio>
              {payment === 'PAYPAL' && (
                <div style={{ marginTop: 20 }}>
                  <Form.Item
                    name="destination"
                    label={t('balance.modal.email.name')}
                    rules={[
                      {
                        required: true,
                        message: t('balance.modal.email.required')
                      },
                      {
                        type: 'email',
                        message: t('balance.modal.email.invalid')
                      }
                    ]}
                  >
                    <Input
                      className="withdrawal-modal__payment-method-destination"
                      placeholder={t('balance.modal.email.placeholder')}
                    />
                  </Form.Item>
                </div>
              )}
            </div>
            <div
              className={`withdrawal-modal__payment-method ${
                payment === 'BITPAY' && 'payment-method-selected'
              }`}
            >
              <Radio value="BITPAY" className="withdrawal-modal__payment-method-option">
                <BitPay height={20} />
                Cryptocurrency
              </Radio>
              {payment === 'BITPAY' && (
                <div style={{ marginTop: 20 }}>
                  <Form.Item
                    name="destination"
                    label={t('balance.modal.wallet.name')}
                    rules={[
                      {
                        required: true,
                        message: t('balance.modal.wallet.required')
                      },
                      {
                        pattern: /^(0x[a-fA-F0-9]{40}$)|[a-z|A-Z|0-9]+[^I]\s?.eth$/,
                        message: t('balance.modal.wallet.invalid')
                      }
                    ]}
                  >
                    <Input
                      className="withdrawal-modal__payment-method-destination"
                      placeholder={t('balance.modal.wallet.placeholder')}
                    />
                  </Form.Item>
                </div>
              )}
            </div>
          </Radio.Group>
        </Form.Item>
        <Button type="primary" loading={loading} disabled={loading} htmlType="submit">
          {t('balance.continue')}
        </Button>
      </Form>
    </Modal>
  );
};

export default Amount;
