import { Row, Col, Table, Collapse, List, Input, Modal, Button } from 'antd';
import {
  CheckSquareFilled,
  CloseSquareFilled,
  CheckCircleOutlined,
  CloseCircleOutlined
} from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { DashboardContent, Avatar, Image, DisplayDate, Price, Pagination } from '@components';
import { useWithdrawalInReview } from '@libs/hooks/wallet-history';
import { useCallback, useEffect, useState } from 'react';
import useQuery from '@libs/utils/query';

function ApproveWithdrawal() {
  const query = useQuery();
  const { Search } = Input;
  const [selectedWithdraw, setSelectedWithdraw] = useState();
  const [isModalApproveOpen, setIsModalApproveOpen] = useState(false);
  const [isModalApprovedOpen, setIsModalApprovedOpen] = useState(false);
  const [isModalRejectOpen, setIsModalRejectOpen] = useState(false);
  const [isModalRejectedOpen, setIsModalRejectedOpen] = useState(false);
  const { t } = useTranslation();
  const slug = query.get('slug');
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  const {
    data: withdrawals,
    onApprove,
    approvalLoading,
    getData,
    nextPageToken,
    onReject,
    loading
  } = useWithdrawalInReview();

  const onSubmit = useCallback(
    (values) => {
      onApprove(values.collectibleID);
      getData();
    },
    [onApprove] // eslint-disable-line react-hooks/exhaustive-deps
  );
  const handleSearch = (data) => {
    getData(null, data);
  };

  useEffect(() => {
    if (withdrawals && slug) {
      const selectedCollectibles = withdrawals.filter((col) => {
        return col.slug === slug;
      });

      if (selectedCollectibles.length < 1) return;

      const params = {
        collectibleID: selectedCollectibles[0].id
      };
      onSubmit(params);
    }
  }, [slug, withdrawals, onSubmit]);

  const columns = [
    {
      title: t('withdrawal.title'),
      key: 'withdrawlID',
      responsive: ['xs'],
      render: (_, row) => {
        return (
          <div className="mobile-row">
            <div className="mobile-row__info">
              <div className="mobile-row__info__name">{row?.invoiceID}</div>
            </div>
            <Collapse collapsible ghost className="datalist">
              <Collapse.Panel header={t('offers.list.expand')} key="1">
                <List itemLayout="vertical">
                  <List.Item>
                    <List.Item.Meta
                      title={t('offers.list.creator')}
                      description={
                        <div className="avatar-name">
                          <Avatar
                            shape="square"
                            src={<Image size={200} src={row?.receiver?.media?.avatarUrl} />}
                          />
                          {`${row?.receiver?.firstName} ${row?.receiver?.lastName}`}
                        </div>
                      }
                    />
                    <List.Item.Meta
                      title={t('withdrawal.list.amount')}
                      description={<Price amount={row?.credit} />}
                    />
                    <List.Item.Meta
                      title={t('withdrawal.list.withdrawalTo')}
                      description={`${row?.withdrawal?.provider} ${row?.withdrawal?.destination}`}
                    />
                    <List.Item.Meta
                      title={t('withdrawal.list.withdrawalDate')}
                      description={<DisplayDate date={row?.createdAt} />}
                    />
                    <List.Item.Meta
                      title={t('withdrawal.list.action')}
                      description={
                        <div className="withdrawal-action">
                          <div
                            onClick={() => {
                              setSelectedWithdraw(row);
                              setIsModalApproveOpen(true);
                            }}
                            className="withdrawal-action-approve"
                          >
                            <CheckSquareFilled /> {t('withdrawal.approveButton')}
                          </div>
                          <div
                            onClick={() => {
                              setSelectedWithdraw(row);
                              setIsModalRejectOpen(true);
                            }}
                            className="withdrawal-action-reject"
                          >
                            <CloseSquareFilled /> {t('withdrawal.rejectButton')}
                          </div>
                        </div>
                      }
                    />
                  </List.Item>
                </List>
              </Collapse.Panel>
            </Collapse>
          </div>
        );
      }
    },
    {
      title: t('withdrawal.list.withdrawID'),
      key: 'withdrawID',
      responsive: ['sm'],
      render: (_, row) => <span style={{ textDecoration: 'underline' }}>{row?.invoiceID}</span>
    },
    {
      title: t('withdrawal.list.user'),
      dataIndex: 'user',
      key: 'user',
      responsive: ['sm'],
      render: (_, row) => (
        <>
          <Avatar user={row?.receiver} />
          <span className="avatar-name">{`${row?.receiver?.firstName} ${row?.receiver?.lastName}`}</span>
        </>
      )
    },
    {
      title: t('withdrawal.list.amount'),
      key: 'amount',
      responsive: ['md'],
      render: (_, row) => <Price amount={row?.credit} />
    },
    {
      title: t('withdrawal.list.withdrawalTo'),
      dataIndex: 'withdrawalTo',
      key: 'withdrawalTo',
      responsive: ['lg'],
      render: (_, row) =>
        `${row?.withdrawal?.provider?.replace('PAYPAL', 'PayPal').replace('BITPAY', 'BitPay')} ${
          row?.withdrawal?.destination
        }`
    },
    {
      title: t('withdrawal.list.withdrawalDate'),
      dataIndex: 'withdrawalDate',
      key: 'withdrawalDate',
      responsive: ['sm'],
      render: (_, row) => <DisplayDate date={row?.createdAt} />
    },
    {
      title: t('withdrawal.list.action'),
      key: 'action',
      responsive: ['sm'],
      render: (_, row) => (
        <div className="withdrawal-action">
          <div
            onClick={() => {
              setSelectedWithdraw(row);
              setIsModalApproveOpen(true);
            }}
            className="withdrawal-action-approve"
          >
            <CheckSquareFilled /> {t('withdrawal.approveButton')}
          </div>
          <div
            onClick={() => {
              setSelectedWithdraw(row);
              setIsModalRejectOpen(true);
            }}
            className="withdrawal-action-reject"
          >
            <CloseSquareFilled /> {t('withdrawal.rejectButton')}
          </div>
        </div>
      )
    }
  ];

  return (
    <>
      <DashboardContent className="withdrawal-page" title={t('withdrawal.title')}>
        <div className="withdrawal-toolbar">
          <Row className="withdrawal-page-search">
            <Col span={24} sm={12}>
              <div className="toolbar-search">
                <Search
                  onChange={(e) => {
                    handleSearch(e.target.value);
                  }}
                  placeholder={t('withdrawal.search')}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="withdrawal">
          <div className="fans-table">
            <Table
              bordered
              loading={loading}
              dataSource={withdrawals}
              columns={columns}
              pagination={false}
            />
            <Pagination nextToken={nextPageToken} loading={loading} fetchData={getData} />
          </div>
          <div className="withdrawal-modal">
            <Modal
              className="withdrawl-modal"
              visible={isModalApproveOpen}
              footer={null}
              onCancel={() => setIsModalApproveOpen(false)}
              width={650}
            >
              <div className="withdrawl-modal-container">
                <div className="withdrawl-modal-container-header">
                  <CheckCircleOutlined style={{ fontSize: '5rem', color: '#27AE60' }} />
                  <div className="withdrawl-modal-container-header-title">
                    {t('withdrawal.modal.approve.title')}
                  </div>
                </div>
                <div className="withdrawl-modal-container-detail">
                  <table className="withdrawl-modal-container-detail-table">
                    <tr className="withdrawl-modal-container-detail-table-row">
                      <td className="withdrawl-modal-container-detail-table-row-left">
                        {t('withdrawal.modal.approve.id')}
                      </td>
                      <td>{selectedWithdraw?.invoiceID}</td>
                    </tr>
                    <tr className="withdrawl-modal-container-detail-table-row">
                      <td className="withdrawl-modal-container-detail-table-row-left">
                        {t('withdrawal.modal.approve.user')}
                      </td>
                      <td className="withdrawl-modal-container-detail-table-row-avatar">
                        <div className="avatar-name">
                          <Avatar user={selectedWithdraw?.receiver} />
                        </div>
                        {`${selectedWithdraw?.receiver?.firstName}`}
                      </td>
                    </tr>
                    <tr className="withdrawl-modal-container-detail-table-row">
                      <td className="withdrawl-modal-container-detail-table-row-left">
                        {t('withdrawal.modal.approve.amount')}
                      </td>
                      <td>
                        <Price amount={selectedWithdraw?.credit} />
                      </td>
                    </tr>
                    <tr className="withdrawl-modal-container-detail-table-row">
                      <td className="withdrawl-modal-container-detail-table-row-left">
                        {t('withdrawal.modal.approve.method')}
                      </td>
                      <td>{selectedWithdraw?.withdrawal?.provider}</td>
                    </tr>
                    <tr className="withdrawl-modal-container-detail-table-row">
                      <td className="withdrawl-modal-container-detail-table-row-left">
                        {t('withdrawal.modal.approve.requestDate')}
                      </td>
                      <td>
                        <DisplayDate date={selectedWithdraw?.createdAt} />
                      </td>
                    </tr>
                  </table>
                  <div className="withdrawl-modal-container-footer">
                    <Button
                      loading={approvalLoading}
                      onClick={async () => {
                        const response = await onApprove(selectedWithdraw?.id);
                        setIsModalApproveOpen(false);
                        if (response) {
                          setIsModalApprovedOpen(true);
                        }
                      }}
                      className="withdrawl-modal-container-footer-approve"
                      type="primary"
                    >
                      {t('withdrawal.modal.approve.approve')}
                    </Button>
                    <Button
                      onClick={() => setIsModalApproveOpen(false)}
                      className="withdrawl-modal-container-footer-cancel"
                      type="secondary"
                    >
                      {t('withdrawal.modal.approve.cancel')}
                    </Button>
                  </div>
                </div>
              </div>
            </Modal>
            <Modal
              className="withdrawl-modal"
              visible={isModalApprovedOpen}
              footer={null}
              onCancel={async () => {
                await getData();
                setIsModalApprovedOpen(false);
              }}
              width={650}
            >
              <div className="withdrawl-modal-container">
                <div className="withdrawl-modal-container-header">
                  <CheckCircleOutlined style={{ fontSize: '5rem', color: '#27AE60' }} />
                  <div className="withdrawl-modal-container-header-title">
                    {t('withdrawal.modal.approve.approvedTitle')}
                  </div>
                </div>
                <div className="withdrawl-modal-container-header-text">
                  <Trans i18nKey={`withdrawal.modal.approve.approvedText`}>
                    {{ firstName: selectedWithdraw?.receiver?.firstName }}
                    {{ price: currencyFormatter.format(Number(selectedWithdraw?.credit)) }}
                    {{
                      email: `${selectedWithdraw?.withdrawal?.provider} ${selectedWithdraw?.withdrawal?.destination}`
                    }}
                  </Trans>
                </div>
              </div>
            </Modal>
            <Modal
              className="withdrawl-modal"
              visible={isModalRejectOpen}
              footer={null}
              onCancel={() => setIsModalRejectOpen(false)}
              width={650}
            >
              <div className="withdrawl-modal-container">
                <div className="withdrawl-modal-container-header">
                  <CloseCircleOutlined style={{ fontSize: '5rem', color: '#D6222A' }} />
                  <div className="withdrawl-modal-container-header-title">
                    {t('withdrawal.modal.reject.title')}
                  </div>
                </div>
                <div className="withdrawl-modal-container-detail">
                  <table className="withdrawl-modal-container-detail-table">
                    <tr className="withdrawl-modal-container-detail-table-row">
                      <td className="withdrawl-modal-container-detail-table-row-left">
                        {t('withdrawal.modal.approve.id')}
                      </td>
                      <td>{selectedWithdraw?.invoiceID}</td>
                    </tr>
                    <tr className="withdrawl-modal-container-detail-table-row">
                      <td className="withdrawl-modal-container-detail-table-row-left">
                        {t('withdrawal.modal.approve.user')}
                      </td>
                      <td className="withdrawl-modal-container-detail-table-row-avatar">
                        <div className="avatar-name">
                          <Avatar user={selectedWithdraw?.receiver} />
                        </div>
                        {`${selectedWithdraw?.receiver?.firstName}`}
                      </td>
                    </tr>
                    <tr className="withdrawl-modal-container-detail-table-row">
                      <td className="withdrawl-modal-container-detail-table-row-left">
                        {t('withdrawal.modal.approve.amount')}
                      </td>
                      <td>
                        <Price amount={selectedWithdraw?.credit} />
                      </td>
                    </tr>
                    <tr className="withdrawl-modal-container-detail-table-row">
                      <td className="withdrawl-modal-container-detail-table-row-left">
                        {t('withdrawal.modal.approve.method')}
                      </td>
                      <td>{selectedWithdraw?.withdrawal?.provider}</td>
                    </tr>
                    <tr className="withdrawl-modal-container-detail-table-row">
                      <td className="withdrawl-modal-container-detail-table-row-left">
                        {t('withdrawal.modal.approve.requestDate')}
                      </td>
                      <td>
                        <DisplayDate date={selectedWithdraw?.createdAt} />
                      </td>
                    </tr>
                  </table>
                  <div className="withdrawl-modal-container-footer">
                    <Button
                      loading={approvalLoading}
                      onClick={async () => {
                        const response = await onReject(selectedWithdraw?.id);
                        setIsModalRejectOpen(false);
                        if (response) {
                          setIsModalRejectedOpen(true);
                        }
                      }}
                      className="withdrawl-modal-container-footer-approve"
                      type="primary"
                    >
                      {t('withdrawal.modal.approve.reject')}
                    </Button>
                    <Button
                      onClick={() => setIsModalRejectOpen(false)}
                      className="withdrawl-modal-container-footer-cancel"
                      type="secondary"
                    >
                      {t('withdrawal.modal.approve.cancel')}
                    </Button>
                  </div>
                </div>
              </div>
            </Modal>
            <Modal
              className="withdrawl-modal"
              visible={isModalRejectedOpen}
              footer={null}
              onCancel={async () => {
                await getData();
                setIsModalRejectedOpen(false);
              }}
              width={650}
            >
              <div className="withdrawl-modal-container">
                <div className="withdrawl-modal-container-header">
                  <CloseCircleOutlined style={{ fontSize: '5rem', color: '#D6222A' }} />
                  <div className="withdrawl-modal-container-header-title">
                    {t('withdrawal.modal.reject.rejectedTitle')}
                  </div>
                </div>
                <div className="withdrawl-modal-container-header-text">
                  <Trans i18nKey={`withdrawal.modal.approve.rejectedText`}>
                    {{ firstName: selectedWithdraw?.receiver?.firstName }}
                    {{ price: currencyFormatter.format(Number(selectedWithdraw?.credit)) }}
                    {{
                      email: `${selectedWithdraw?.withdrawal?.provider} ${selectedWithdraw?.withdrawal?.destination}`
                    }}
                  </Trans>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </DashboardContent>
      <Helmet>
        <title>{t('withdrawal.title')}</title>
      </Helmet>
    </>
  );
}

export default ApproveWithdrawal;
