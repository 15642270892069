import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Row, Col, Typography } from 'antd';
import ArrowRight from '@assets/img/arrow-right.svg';
import Logo from '@assets/img/landing-logo.svg';
import LandingBlur from '@assets/img/landing-blur.png';

function Footer({ showBlur, hideLogo, ...props }) {
  const { t } = useTranslation();
  const { Paragraph } = Typography;
  return (
    <footer className="footer">
      <div className="container">
        <Row>
          <Col span={24} md={12} className="footer-left">
            {!hideLogo ? <img className="footer__image" src={Logo} alt="Fans.Inc" /> : null}
            <Paragraph className="footer__copyright">
              {process.env.REACT_APP_NAME === 'patrons'
                ? t('components.footer.copyrightPatrons')
                : t('components.footer.copyright')}
            </Paragraph>
          </Col>
          <Col span={24} md={12} className="footer-right">
            {process.env.REACT_APP_NAME === 'patrons' ? (
              <Link
                className="footer__visit"
                to={{ pathname: 'https://www.patrons.art/' }}
                target="_blank"
              >
                {t('components.footer.visitPatrons')}{' '}
                <img src={ArrowRight} alt={t('components.footer.visitPatrons')} />
              </Link>
            ) : (
              <Link
                className="footer__visit"
                style={{ marginBottom: '2rem !important' }}
                to={{ pathname: 'https://www.fans.inc/' }}
                target="_blank"
              >
                {t('components.footer.visit')}{' '}
                <img src={ArrowRight} alt={t('components.footer.visit')} />
              </Link>
            )}
            {process.env.REACT_APP_NAME !== 'patrons' && (
              <ul className="footer__menu">
                <li>
                  <Link to="/faq">{t('components.footer.menu.faqs')}</Link>
                </li>
                <li>
                  <Link to="/terms-and-conditions">{t('components.footer.menu.terms')}</Link>
                </li>
                <li>
                  <Link to="/privacy">{t('components.footer.menu.privacy')}</Link>
                </li>
              </ul>
            )}
          </Col>
        </Row>
      </div>
      {showBlur && <img className="landing__blur landing__blur-footer" src={LandingBlur} alt="" />}
    </footer>
  );
}

Footer.defaultProps = {
  hideLogo: false
};

export default Footer;
