import Logo from '@assets/img/artist-logo.png';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { Button } from 'antd';
import { useState } from 'react';
import { Link, ModalPatronsRegister } from '@components';

function AuthHeader() {
  const { path } = useRouteMatch();
  const { t } = useTranslation();
  const [registerModal, setRegisterModal] = useState(false);
  return (
    <>
      <div className="auth-header">
        <div className="auth-header__body">
          <div className="auth-header__body__left">
            <Link to="/">
              <img alt="logo" src={Logo} />
            </Link>
          </div>
          <div className="auth-header__body__right">
            <div className="auth-header__body__right__text">
              {t('components.authHeader.description')}
            </div>
            {path === '/login' ? (
              <Button onClick={() => setRegisterModal(true)} type="primary">
                {t('components.authHeader.signUp')}
              </Button>
            ) : (
              <Link to="/login">
                <Button type="primary">{t('components.authHeader.signIn')}</Button>
              </Link>
            )}
          </div>
        </div>
      </div>
      <ModalPatronsRegister onShow={registerModal} onClose={() => setRegisterModal(false)} />
    </>
  );
}

export default AuthHeader;
