import { useTranslation } from 'react-i18next';
import { Modal, Button, Form, Input, Row, Col } from 'antd';
import { DeleteOutlined, CheckCircleOutlined, WarningFilled } from '@ant-design/icons';

const CustomModal = ({ showModal, onCancel, onSubmit, cb, mode, loading, title, ...props }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const CancelBtn = () => (
    <Button
      className="admin-collectible-modal__button admin-collectible-modal__button-default"
      onClick={onCancel}
      type="default"
    >
      {t('collectibles.modal.button.cancel')}
    </Button>
  );

  const handleSubmitRevision = () => {
    form
      .validateFields()
      .then(async (data) => {
        await onSubmit(data);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
  return (
    <Modal
      className="admin-collectible-modal"
      visible={showModal}
      footer={null}
      width={508}
      onCancel={onCancel}
      title={title}
      {...props}
    >
      <div style={{ textAlign: 'center' }}>
        {mode === 'delete' && (
          <>
            <DeleteOutlined
              className="admin-collectible-modal__icon"
              style={{ color: '#D6222A' }}
            />
            <h2>{t('collectibles.modal.delete.label')}</h2>
            <p className="admin-collectible-modal__desc">
              {t('collectibles.modal.delete.description')}
            </p>
            <Button
              danger
              className="admin-collectible-modal__button admin-collectible-modal__button-error"
              onClick={() => {
                onSubmit();
                cb && cb();
              }}
              loading={loading}
            >
              {t('collectibles.modal.button.delete')}
            </Button>
            <CancelBtn />
          </>
        )}
        {mode === 'revisionSent' && (
          <>
            <WarningFilled className="admin-collectible-modal__icon" style={{ color: '#E2B93B' }} />
            <h1>{t('collectibles.modal.revisionSent.label')}</h1>
            <p className="admin-collectible-modal__desc">
              {t('collectibles.modal.revisionSent.description')}
            </p>
          </>
        )}
        {mode === 'revision' && (
          <>
            <Form className="admin-form" form={form} layout="vertical">
              <Row className="admin-collectible-modal__desc-revision">
                <p className="admin-collectible-modal__desc">
                  {t('collectibles.modal.revision.description')}
                </p>
                <Col span={24}>
                  <Form.Item
                    label={t('collectibles.modal.revision.label')}
                    rules={[{ required: true, message: t('collectibles.modal.revision.required') }]}
                    name="description"
                  >
                    <Input placeholder={t('collectibles.modal.revision.placeholder')} />
                  </Form.Item>
                  <Button
                    style={{
                      fontSize: '1rem',
                      paddingBlock: '12.5px'
                    }}
                    type="primary"
                    onClick={() => {
                      handleSubmitRevision(true);
                    }}
                    loading={loading}
                  >
                    {t('collectibles.modal.button.send')}
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
        )}
        {mode === 'approve' && (
          <>
            <CheckCircleOutlined
              className="admin-collectible-modal__icon"
              style={{ color: '#27AE60' }}
            />
            <h1>{t('collectibles.modal.approve.label')}</h1>
            <p className="admin-collectible-modal__desc">
              {t('collectibles.modal.approve.description')}
            </p>
            <Button
              className="admin-collectible-modal__button admin-collectible-modal__button-success"
              onClick={() => {
                onSubmit();
                cb && cb();
              }}
              loading={loading}
            >
              {t('collectibles.modal.button.approve')}
            </Button>
            <CancelBtn />
          </>
        )}
      </div>
    </Modal>
  );
};
export default CustomModal;
