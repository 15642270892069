export const searchCollections = /* GraphQL */ `
  query SearchCollections(
    $filter: SearchableCollectionsFilterInput
    $sort: SearchableCollectionsSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCollections(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        collectionType
        avatarUrl
        coverUrl
        logoUrl
        slug
        collectionUrl
        shortName
        isShowLogo
        createdAt
        searchableCollectionType
      }
      nextToken
      total
    }
  }
`;

export const collectionByAccount = /* GraphQL */ `
  query CollectionByAccount(
    $accountID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    collectionByAccount(
      accountID: $accountID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        owner
        accountID
        name
        slug
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        currentUser {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          bio
          tags
          verified
          fanProfile
          emailVerified
          joinDate
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          media {
            avatarUrl
          }
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          bio
          tags
          verified
          fanProfile
          emailVerified
          joinDate
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          media {
            avatarUrl
          }
        }
        collectibles {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
