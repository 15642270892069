import { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Input, Row, Modal, Popconfirm, Spin, Tabs } from 'antd';
import {
  CameraOutlined,
  LoadingOutlined,
  CloseOutlined,
  VideoCameraAddOutlined
} from '@ant-design/icons';
import { Helmet } from 'react-helmet';

import {
  DashboardContent,
  Image,
  Cropper,
  UnauthorizedAccess,
  Player,
  VideoModal
} from '@components';
import { useOrganizationStaffs, useUserManageCreator } from '@libs/hooks/organisation';
import { useProfile } from '@libs/hooks/verified-profile';
import { useAuth } from '@libs/contexts/auth';
import { useUploader, useImageResizer } from '@libs/utils/uploader';
import {
  FACEBOOK_PATTERN,
  TWITTER_PATTERN,
  INSTAGRAM_PATTERN,
  YOUTUBE_PATTERN,
  SOUNDCLOUD_PATTERN,
  TIKTOK_PATTERN,
  SNAPCHAT_PATTERN,
  SPOTIFY_PATTERN,
  APPLEMUSIC_PATTERN
} from './utils';
import PlayButton from '@assets/img/play-button.svg';

const { TextArea } = Input;
const { TabPane } = Tabs;
const loadingIcon = <LoadingOutlined style={{ fontSize: 20, margin: 5 }} spin />;

function EditCreator() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { user, creators } = useAuth();

  const handleFinishUploadAvatar = () => {
    setAvatarModal(true);
  };
  const {
    inputRef: avatarRef,
    onClick: handleUploadAvatar,
    onUpload: onUploadAvatar,
    validationError: validationErrorAvatar,
    result: avatarResult,
    loading: avatarUploadLoading
  } = useUploader(handleFinishUploadAvatar);

  const { loading: imageResizeLoading, onResize } = useImageResizer();

  const handleFinishUploadCover = () => {
    setCoverModal(true);
  };
  const {
    inputRef: coverRef,
    onClick: handleUploadCover,
    onUpload: onUploadCover,
    validationError: validationErrorCover,
    result: coverResult,
    loading: coverUploadLoading
  } = useUploader(handleFinishUploadCover);

  const handleFinishUploadAvatarVideo = async (props) => {
    await onUpdateAvatarVideo(props.s3?.key.replace('temps/', ''));
    await refetch();
  };
  const {
    inputRef: avatarVideoRef,
    onClick: handleUploadAvatarVideo,
    onUpload: onUploadAvatarVideo,
    validationError: validationErrorAvatarVideo,
    loading: avatarVideoLoading
  } = useUploader(handleFinishUploadAvatarVideo);

  const handleFinishUploadFanclubLogo = () => {
    setFanclubLogoModal(true);
  };
  const {
    inputRef: fanclubLogoRef,
    onClick: handleUploadFanclubLogo,
    onUpload: onUploadFanclubLogo,
    validationError: validationErrorFanclubLogo,
    result: fanclubLogoResult,
    loading: fanclubLogoUploadLoading
  } = useUploader(handleFinishUploadFanclubLogo);

  const { data: editors } = useOrganizationStaffs();
  const {
    data: profile,
    onUpdate,
    onUpdateCover,
    onUpdateAvatar,
    onUpdateAvatarVideo,
    onUpdateFanclubLogo,
    onRemoveAvatar,
    onRemoveCover,
    onRemoveVideo,
    onRemoveFanclubLogo,
    avatarLoading,
    avatarVideoUpdateLoading,
    coverLoading,
    fanclubLogoLoading,
    updateLoading,
    refetch
  } = useProfile(creators?.[0]?.id);

  const { loading: manageLoading, data: manage } = useUserManageCreator(creators?.[0]?.id);
  const [avatarModal, setAvatarModal] = useState(false);
  const [avatarVideoModal, setAvatarVideoModal] = useState(false);
  const [coverModal, setCoverModal] = useState(false);
  const [fanclubLogoModal, setFanclubLogoModal] = useState(false);
  const [editorsValue, setEditorsValue] = useState([]);
  const [salesFeeValue, setSalseFeeValue] = useState(20);
  const [royaltyFeeValue, setRoyaltyFeeValue] = useState(2.5);
  const [firstNameLength, setFirstNameLength] = useState('0');
  const [lastNameLength, setLastNameLength] = useState('0');
  const [displayNameLength, setDisplayNameLength] = useState('0');
  const [bioLength, setBioLength] = useState('0');

  const onSubmit = useCallback(
    (values) => {
      onUpdate({
        id: profile?.id,
        accountID: profile?.accountID,
        username: profile?.username,
        ...values,
        salesFee: salesFeeValue,
        royaltyFee: royaltyFeeValue,
        editors: editorsValue,
        profileTemplate: profile?.profileTemplate || 'default'
      });
    },
    [profile, editorsValue, salesFeeValue, royaltyFeeValue] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleDeleteCover = async () => {
    await onRemoveCover();
    await onUpdateCover('');
    await refetch();
  };

  const handleDeleteAvatar = async () => {
    await onRemoveAvatar();
    await onUpdateAvatar('');
    await refetch();
  };

  const handleDeleteFanclubLogo = async () => {
    await onRemoveFanclubLogo();
    await onUpdateFanclubLogo('');
    await refetch();
  };

  const handleDeleteAvatarVideo = async () => {
    await onRemoveVideo();
    await refetch();
  };

  useEffect(() => {
    if (profile && editors) {
      setEditorsValue(profile?.managers?.items?.map((x) => x.managerID));
      setSalseFeeValue(profile?.fee?.salesFee);
      setRoyaltyFeeValue(profile?.fee?.royaltyFee);
    }
  }, [profile, editors]); // eslint-disable-line

  const formInitialValues = {
    firstName: profile?.firstName,
    lastName: profile?.lastName,
    displayName: profile?.displayName,
    creatorCategory: profile?.creatorCategory,
    bio: profile?.bio,
    editors: editorsValue,
    facebook: profile?.links.facebook,
    youtube: profile?.links.youtube,
    twitter: profile?.links.twitter,
    instagram: profile?.links.instagram,
    soundcloud: profile?.links.soundcloud,
    email: profile?.links.email,
    spotify: profile?.links.spotify,
    website: profile?.links.website,
    tiktok: profile?.links.tiktok,
    snapchat: profile?.links.snapchat,
    appleMusic: profile?.links.appleMusic,
    clubhouse: profile?.links.clubhouse
  };

  if (!manage && !manageLoading) {
    return <UnauthorizedAccess />;
  }

  return (
    <>
      <DashboardContent
        title={
          process.env.REACT_APP_NAME === 'patrons'
            ? t('creators.patrons.edit.title')
            : t('creators.edit.title')
        }
        backLink="/creators"
      >
        {!profile ? null : (
          <>
            <Form
              onFinish={onSubmit}
              form={form}
              initialValues={formInitialValues}
              layout="vertical"
              className="admin-form"
            >
              <Row>
                <Col span={24} lg={16}>
                  <Tabs className="creator-tabs" defaultActiveKey="1" forceRender>
                    <TabPane
                      tab={
                        process.env.REACT_APP_NAME === 'patrons'
                          ? t('creators.patrons.creatorDetails')
                          : t('creators.creatorDetails')
                      }
                      key="1"
                    >
                      <Row
                        className="add-profile-images"
                        gutter={[20]}
                        style={{ marginBottom: '2rem' }}
                      >
                        <Col span={24}>
                          <Form.Item
                            label={t('creators.fields.cover.label')}
                            validateStatus={validationErrorCover ? 'error' : ''}
                            help={validationErrorCover}
                          >
                            <div className="image-preview-field">
                              {(coverLoading || coverUploadLoading) && (
                                <Spin
                                  className="image-preview-field__loading"
                                  indicator={loadingIcon}
                                />
                              )}
                              {profile?.media.coverUrl && (
                                <Image
                                  size={1600}
                                  className="image-preview-field__image"
                                  src={profile?.media.coverUrl}
                                  preview={{ visible: false }}
                                />
                              )}
                              {!profile?.media.coverUrl && (
                                <div className="image-preview-field-description">
                                  <Button type="text" onClick={handleUploadCover}>
                                    <CameraOutlined style={{ fontSize: '30px' }} />
                                  </Button>
                                  <div
                                    className="image-preview-field-description-text"
                                    style={{ fontWeight: '700', color: '#1A1A1A' }}
                                  >
                                    {t('creators.fields.cover.text')}
                                  </div>
                                  <div className="image-preview-field-description-hint">
                                    {process.env.REACT_APP_NAME === 'patrons'
                                      ? t('creators.patrons.fields.cover.extra')
                                      : t('creators.fields.cover.extra')}
                                  </div>
                                  <input
                                    type="file"
                                    ref={coverRef}
                                    onChange={onUploadCover}
                                    style={{ width: 0 }}
                                  />
                                </div>
                              )}
                              {profile?.media.coverUrl && (
                                <div className="image-preview-field-cover-update">
                                  <Button type="text" onClick={handleUploadCover}>
                                    <CameraOutlined style={{ fontSize: '30px', color: 'white' }} />
                                  </Button>
                                  <input
                                    type="file"
                                    ref={coverRef}
                                    onChange={async (e) => {
                                      await onRemoveCover();
                                      await onUploadCover(e);
                                    }}
                                    style={{ width: 0 }}
                                  />
                                </div>
                              )}

                              {profile?.media.coverUrl && (
                                <div className="image-preview-field-remove">
                                  <Popconfirm
                                    title={t('creators.fields.cover.deleteConfirm')}
                                    onConfirm={handleDeleteCover}
                                    okText={t('creators.fields.cover.yes')}
                                    cancelText={t('creators.fields.cover.no')}
                                  >
                                    <Button type="text">
                                      <CloseOutlined style={{ color: 'white', fontSize: '20px' }} />
                                    </Button>
                                  </Popconfirm>
                                </div>
                              )}
                              <Modal
                                className="image-preview-field__modal"
                                title={t('creators.fields.cover.set.label')}
                                visible={coverModal}
                                width={600}
                                footer={null}
                                onCancel={() => {
                                  setCoverModal(false);
                                }}
                              >
                                {coverResult.fileUrl ? (
                                  <Cropper
                                    aspect={10 / 1}
                                    loading={imageResizeLoading || coverLoading}
                                    image={coverResult.fileUrl}
                                    onComplete={async (croppedArea) => {
                                      const resized = await onResize({
                                        key: coverResult.s3?.key,
                                        folder: 'covers',
                                        ...croppedArea
                                      });

                                      if (resized) {
                                        await onUpdateCover(resized?.key);
                                        await refetch();
                                      }

                                      coverRef.current.value = '';
                                      setCoverModal(false);
                                    }}
                                    onCancel={() => {
                                      setCoverModal(false);
                                      coverRef.current.value = '';
                                    }}
                                  />
                                ) : null}
                              </Modal>
                            </div>
                          </Form.Item>
                        </Col>
                        <Col span={24} lg={12}>
                          <Form.Item
                            label={t('creators.fields.avatar.label')}
                            validateStatus={validationErrorAvatar ? 'error' : ''}
                            help={validationErrorAvatar}
                          >
                            <div className="image-preview-avatar-field">
                              {(avatarLoading || avatarUploadLoading) && (
                                <Spin
                                  className="image-preview-field__loading"
                                  indicator={loadingIcon}
                                />
                              )}
                              {profile?.media.avatarUrl && (
                                <Image
                                  size={200}
                                  className="image-preview-field__image"
                                  src={profile?.media.avatarUrl}
                                  preview={{ visible: false }}
                                />
                              )}
                              {!profile?.media.avatarUrl && (
                                <div className="image-preview-avatar-field-description">
                                  <Button type="text" onClick={handleUploadAvatar}>
                                    <CameraOutlined style={{ fontSize: '30px' }} />
                                  </Button>
                                  <div
                                    className="image-preview-avatar-field-description-text"
                                    style={{ fontWeight: '700', color: '#1A1A1A' }}
                                  >
                                    {t('creators.fields.cover.text')}
                                  </div>
                                  <div className="image-preview-avatar-field-description-hint">
                                    {t('creators.fields.avatar.extra')}
                                  </div>
                                  <input
                                    type="file"
                                    ref={avatarRef}
                                    onChange={(e) => onUploadAvatar(e, 'avatar')}
                                    style={{ width: 0 }}
                                  />
                                </div>
                              )}
                              {profile?.media.avatarUrl && (
                                <div className="image-preview-avatar-field-avatar-update">
                                  <Button type="text" onClick={handleUploadAvatar}>
                                    <CameraOutlined style={{ fontSize: '30px', color: 'white' }} />
                                  </Button>
                                  <input
                                    type="file"
                                    ref={avatarRef}
                                    onChange={async (e) => {
                                      onRemoveAvatar();
                                      onUploadAvatar(e, 'avatar');
                                    }}
                                    style={{ width: 0 }}
                                  />
                                </div>
                              )}
                              {profile?.media.avatarUrl && (
                                <div className="image-preview-field-remove">
                                  <Popconfirm
                                    title={t('creators.fields.avatar.deleteConfirm')}
                                    onConfirm={handleDeleteAvatar}
                                    okText={t('creators.fields.avatar.yes')}
                                    cancelText={t('creators.fields.avatar.no')}
                                  >
                                    <Button type="text">
                                      <CloseOutlined style={{ color: 'white', fontSize: '20px' }} />
                                    </Button>
                                  </Popconfirm>
                                </div>
                              )}
                              <Modal
                                className="image-preview-field__modal"
                                title={t('creators.fields.avatar.set.label')}
                                visible={avatarModal}
                                footer={null}
                                width={600}
                                onCancel={() => {
                                  setAvatarModal(false);
                                }}
                              >
                                {avatarResult.fileUrl ? (
                                  <Cropper
                                    loading={imageResizeLoading || avatarLoading}
                                    image={avatarResult.fileUrl}
                                    onComplete={async (croppedArea) => {
                                      const resized = await onResize({
                                        key: avatarResult.s3?.key,
                                        folder: 'avatars',
                                        ...croppedArea
                                      });

                                      if (resized) {
                                        await onUpdateAvatar(resized?.key);
                                        await refetch();
                                      }

                                      avatarRef.current.value = '';
                                      setAvatarModal(false);
                                    }}
                                    onCancel={() => {
                                      setAvatarModal(false);
                                      avatarRef.current.value = '';
                                    }}
                                  />
                                ) : null}
                              </Modal>
                            </div>
                          </Form.Item>
                        </Col>
                        <Col span={24} lg={12}>
                          <Form.Item
                            label={t('creators.fields.avatarVideo.label')}
                            validateStatus={validationErrorAvatarVideo ? 'error' : ''}
                            help={validationErrorAvatarVideo}
                          >
                            <div className="image-preview-avatar-field">
                              {(avatarVideoLoading || avatarVideoUpdateLoading) && (
                                <Spin
                                  className="image-preview-field__loading"
                                  indicator={loadingIcon}
                                />
                              )}

                              {profile?.media.avatarVideoUrl && (
                                <Player
                                  src={profile?.media.avatarVideoUrl}
                                  play={false}
                                  controls={false}
                                  className="image-preview-field__image"
                                />
                              )}
                              {!profile?.media.avatarVideoUrl && (
                                <div className="image-preview-avatar-field-description">
                                  <Button type="text" onClick={handleUploadAvatarVideo}>
                                    <VideoCameraAddOutlined style={{ fontSize: '30px' }} />
                                  </Button>
                                  <div
                                    className="image-preview-avatar-field-description-text"
                                    style={{ fontWeight: '700', color: '#1A1A1A' }}
                                  >
                                    {t('creators.fields.cover.text')}
                                  </div>
                                  <div className="image-preview-avatar-field-description-hint">
                                    {t('creators.fields.avatarVideo.extra')}
                                  </div>
                                  <input
                                    type="file"
                                    ref={avatarVideoRef}
                                    onChange={(e) => onUploadAvatarVideo(e, 'video', null, user.id)}
                                    style={{ width: 0 }}
                                  />
                                </div>
                              )}
                              {profile?.media.avatarVideoUrl && (
                                <div className="image-preview-avatar-field-avatar-update">
                                  <Button type="text" onClick={() => setAvatarVideoModal(true)}>
                                    <img style={{ paddingBottom: 3 }} src={PlayButton} alt="Play" />
                                  </Button>
                                </div>
                              )}
                              {profile?.media.avatarVideoUrl && (
                                <div className="image-preview-field-remove">
                                  <Popconfirm
                                    title={t('creators.fields.avatarVideo.deleteConfirm')}
                                    onConfirm={handleDeleteAvatarVideo}
                                    okText={t('creators.fields.avatarVideo.yes')}
                                    cancelText={t('creators.fields.avatarVideo.no')}
                                  >
                                    <Button type="text">
                                      <CloseOutlined style={{ color: 'white', fontSize: '20px' }} />
                                    </Button>
                                  </Popconfirm>
                                </div>
                              )}
                            </div>
                            {profile?.media.avatarVideoUrl && (
                              <VideoModal
                                url={profile?.media.avatarVideoUrl}
                                show={avatarVideoModal}
                                onClose={() => setAvatarVideoModal(false)}
                              />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Form.Item
                        label={t('creators.fields.firstName.label')}
                        name="firstName"
                        rules={[
                          { required: true, message: t('creators.fields.firstName.required') },
                          {
                            pattern: /^[a-zA-Z\s]*$/,
                            message: t('creators.fields.firstName.invalid')
                          },
                          {
                            max: 50,
                            message: t('creators.fields.firstName.maxLength', {
                              length: firstNameLength
                            })
                          }
                        ]}
                      >
                        <Input
                          placeholder={
                            process.env.REACT_APP_NAME === 'patrons'
                              ? t('creators.patrons.fields.firstName.placeholder')
                              : t('creators.fields.firstName.placeholder')
                          }
                          onChange={(e) => {
                            setFirstNameLength(e.target.value.length.toString());
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        label={t('creators.fields.lastName.label')}
                        name="lastName"
                        rules={[
                          { required: true, message: t('creators.fields.lastName.required') },
                          {
                            pattern: /^[a-zA-Z\s]*$/,
                            message: t('creators.fields.lastName.invalid')
                          },
                          {
                            max: 50,
                            message: t('creators.fields.lastName.maxLength', {
                              length: lastNameLength
                            })
                          }
                        ]}
                      >
                        <Input
                          placeholder={
                            process.env.REACT_APP_NAME === 'patrons'
                              ? t('creators.patrons.fields.lastName.placeholder')
                              : t('creators.fields.lastName.placeholder')
                          }
                          onChange={(e) => {
                            setLastNameLength(e.target.value.length.toString());
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        label={t('creators.fields.displayName.label')}
                        name="displayName"
                        rules={[
                          {
                            pattern: /^[a-zA-Z\s]*$/,
                            message: t('creators.fields.displayName.invalid')
                          },
                          {
                            required: process.env.REACT_APP_NAME === 'patrons' ? true : false,
                            message: t('creators.fields.displayName.required')
                          },
                          {
                            max: 50,
                            message: t('creators.fields.displayName.maxLength', {
                              length: displayNameLength
                            })
                          }
                        ]}
                      >
                        <Input
                          placeholder={
                            process.env.REACT_APP_NAME === 'patrons'
                              ? t('creators.patrons.fields.displayName.placeholder')
                              : t('creators.fields.displayName.placeholder')
                          }
                          onChange={(e) => {
                            setDisplayNameLength(e.target.value.length.toString());
                          }}
                        />
                      </Form.Item>
                      <Form.Item label={t('creators.fields.category.label')} name="creatorCategory">
                        <Input
                          placeholder={
                            process.env.REACT_APP_NAME === 'patrons'
                              ? t('creators.patrons.fields.category.placeholder')
                              : t('creators.fields.category.placeholder')
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        label={t('creators.fields.bio.label')}
                        name="bio"
                        rules={[
                          {
                            max: 800,
                            message: t('creators.fields.bio.maxLength', { length: bioLength })
                          }
                        ]}
                      >
                        <TextArea
                          rows={4}
                          placeholder={
                            process.env.REACT_APP_NAME === 'patrons'
                              ? t('creators.patrons.fields.bio.placeholder')
                              : t('creators.fields.bio.placeholder')
                          }
                          onChange={(e) => {
                            setBioLength(e.target.value.length.toString());
                          }}
                        />
                      </Form.Item>
                      {process.env.REACT_APP_NAME !== 'patrons' && (
                        <Row
                          className="add-profile-images"
                          gutter={[20]}
                          style={{ marginBottom: '2rem' }}
                        >
                          <Col span={24}>
                            <Form.Item
                              label={t('creators.fields.fanclubLogo.label')}
                              validateStatus={validationErrorFanclubLogo ? 'error' : ''}
                              help={validationErrorFanclubLogo}
                            >
                              <div className="image-preview-field">
                                {(fanclubLogoLoading || fanclubLogoUploadLoading) && (
                                  <Spin
                                    className="image-preview-field__loading"
                                    indicator={loadingIcon}
                                  />
                                )}
                                {profile?.media.fanclubLogoUrl && (
                                  <Image
                                    size={800}
                                    className="image-preview-field__image"
                                    src={profile?.media.fanclubLogoUrl}
                                    preview={{ visible: false }}
                                    style={{ objectFit: 'cover' }}
                                  />
                                )}
                                {!profile?.media.fanclubLogoUrl && (
                                  <div className="image-preview-field-description">
                                    <Button type="text" onClick={handleUploadFanclubLogo}>
                                      <CameraOutlined style={{ fontSize: '30px' }} />
                                    </Button>
                                    <div
                                      className="image-preview-field-description-text"
                                      style={{ fontWeight: '700', color: '#1A1A1A' }}
                                    >
                                      {t('creators.fields.fanclubLogo.text')}
                                    </div>
                                    <div className="image-preview-field-description-hint">
                                      {t('creators.fields.fanclubLogo.extra')}
                                    </div>
                                    <input
                                      type="file"
                                      ref={fanclubLogoRef}
                                      onChange={onUploadFanclubLogo}
                                      style={{ width: 0 }}
                                    />
                                  </div>
                                )}
                                {profile?.media.fanclubLogoUrl && (
                                  <div className="image-preview-field-cover-update">
                                    <Button type="text" onClick={handleUploadFanclubLogo}>
                                      <CameraOutlined
                                        style={{ fontSize: '30px', color: 'white' }}
                                      />
                                    </Button>
                                    <input
                                      type="file"
                                      ref={fanclubLogoRef}
                                      onChange={async (e) => {
                                        await onRemoveFanclubLogo();
                                        await onUploadFanclubLogo(e);
                                      }}
                                      style={{ width: 0 }}
                                    />
                                  </div>
                                )}

                                {profile?.media.fanclubLogoUrl && (
                                  <div className="image-preview-field-remove">
                                    <Popconfirm
                                      title={t('creators.fields.fanclubLogo.deleteConfirm')}
                                      onConfirm={handleDeleteFanclubLogo}
                                      okText={t('creators.fields.fanclubLogo.yes')}
                                      cancelText={t('creators.fields.fanclubLogo.no')}
                                    >
                                      <Button type="text">
                                        <CloseOutlined
                                          style={{ color: 'white', fontSize: '20px' }}
                                        />
                                      </Button>
                                    </Popconfirm>
                                  </div>
                                )}
                                <Modal
                                  className="image-preview-field__modal"
                                  title={t('creators.fields.fanclubLogo.set.label')}
                                  visible={fanclubLogoModal}
                                  width={600}
                                  footer={null}
                                  onCancel={() => {
                                    setCoverModal(false);
                                  }}
                                >
                                  {fanclubLogoResult.fileUrl ? (
                                    <Cropper
                                      aspect={19 / 6}
                                      loading={imageResizeLoading || fanclubLogoLoading}
                                      image={fanclubLogoResult.fileUrl}
                                      onComplete={async (croppedArea) => {
                                        const resized = await onResize({
                                          key: fanclubLogoResult.s3?.key,
                                          folder: 'fanclub_logos',
                                          ...croppedArea
                                        });

                                        if (resized) {
                                          await onUpdateFanclubLogo(resized?.key);
                                          await refetch();
                                        }

                                        fanclubLogoRef.current.value = '';
                                        setFanclubLogoModal(false);
                                      }}
                                      onCancel={() => {
                                        setFanclubLogoModal(false);
                                        fanclubLogoRef.current.value = '';
                                      }}
                                    />
                                  ) : null}
                                </Modal>
                              </div>
                            </Form.Item>
                          </Col>
                        </Row>
                      )}
                    </TabPane>
                    <TabPane tab={t('creators.socialMedia')} key="2">
                      <Form.Item
                        label={t('creators.fields.email.label')}
                        name="email"
                        rules={[{ type: 'email', message: t('creators.fields.email.invalid') }]}
                      >
                        <Input placeholder={t('creators.fields.email.placeholder')} />
                      </Form.Item>
                      <Form.Item
                        label={t('creators.fields.website.label')}
                        name="website"
                        rules={[{ type: 'url', message: t('creators.fields.website.invalid') }]}
                      >
                        <Input placeholder={t('creators.fields.website.placeholder')} />
                      </Form.Item>
                      <Form.Item
                        label={t('creators.fields.facebook.label')}
                        name="facebook"
                        rules={[
                          {
                            pattern: FACEBOOK_PATTERN,
                            message: t('creators.fields.facebook.invalid')
                          }
                        ]}
                      >
                        <Input placeholder={t('creators.fields.facebook.placeholder')} />
                      </Form.Item>
                      <Form.Item
                        label={t('creators.fields.twitter.label')}
                        name="twitter"
                        rules={[
                          {
                            pattern: TWITTER_PATTERN,
                            message: t('creators.fields.twitter.invalid')
                          }
                        ]}
                      >
                        <Input placeholder={t('creators.fields.twitter.placeholder')} />
                      </Form.Item>
                      <Form.Item
                        label={t('creators.fields.instagram.label')}
                        name="instagram"
                        rules={[
                          {
                            pattern: INSTAGRAM_PATTERN,
                            message: t('creators.fields.instagram.invalid')
                          }
                        ]}
                      >
                        <Input placeholder={t('creators.fields.instagram.placeholder')} />
                      </Form.Item>
                      <Form.Item
                        label={t('creators.fields.youtube.label')}
                        name="youtube"
                        rules={[
                          {
                            pattern: YOUTUBE_PATTERN,
                            message: t('creators.fields.youtube.invalid')
                          }
                        ]}
                      >
                        <Input placeholder={t('creators.fields.youtube.placeholder')} />
                      </Form.Item>
                      <Form.Item
                        label={t('creators.fields.soundcloud.label')}
                        name="soundcloud"
                        rules={[
                          {
                            pattern: SOUNDCLOUD_PATTERN,
                            message: t('creators.fields.soundcloud.invalid')
                          }
                        ]}
                      >
                        <Input placeholder={t('creators.fields.soundcloud.placeholder')} />
                      </Form.Item>
                      <Form.Item
                        label={t('creators.fields.tiktok.label')}
                        name="tiktok"
                        rules={[
                          {
                            pattern: TIKTOK_PATTERN,
                            message: t('creators.fields.tiktok.invalid')
                          }
                        ]}
                      >
                        <Input placeholder={t('creators.fields.tiktok.placeholder')} />
                      </Form.Item>
                      <Form.Item
                        label={t('creators.fields.snapchat.label')}
                        name="snapchat"
                        rules={[
                          {
                            pattern: SNAPCHAT_PATTERN,
                            message: t('creators.fields.snapchat.invalid')
                          }
                        ]}
                      >
                        <Input placeholder={t('creators.fields.snapchat.placeholder')} />
                      </Form.Item>
                      <Form.Item
                        label={t('creators.fields.spotify.label')}
                        name="spotify"
                        rules={[
                          {
                            pattern: SPOTIFY_PATTERN,
                            message: t('creators.fields.spotify.invalid')
                          }
                        ]}
                      >
                        <Input placeholder={t('creators.fields.spotify.placeholder')} />
                      </Form.Item>
                      <Form.Item
                        label={t('creators.fields.applemusic.label')}
                        name="appleMusic"
                        rules={[
                          {
                            pattern: APPLEMUSIC_PATTERN,
                            message: t('creators.fields.applemusic.invalid')
                          }
                        ]}
                      >
                        <Input placeholder={t('creators.fields.applemusic.placeholder')} />
                      </Form.Item>
                      <Form.Item
                        label={t('creators.fields.clubhouse.label')}
                        name="clubhouse"
                        rules={[{ type: 'url', message: t('creators.fields.clubhouse.invalid') }]}
                      >
                        <Input placeholder={t('creators.fields.clubhouse.placeholder')} />
                      </Form.Item>
                    </TabPane>
                  </Tabs>
                </Col>
                <Col className="creator-page-right" span={24} lg={8}>
                  <Button
                    htmlType="submit"
                    loading={updateLoading}
                    className="creator-page-right-button"
                    type="primary"
                  >
                    {t('creators.buttons.save')}
                  </Button>
                  <div className="creator-page-right-wrapper">
                    <div className="creator-page-right-wrapper-title">
                      {t('creators.patrons.fee')}
                    </div>
                    <div className="creator-page-right-wrapper-fansincfee">
                      <div className="creator-page-right-wrapper-underline"></div>
                      <div className="creator-page-right-wrapper-field">
                        <div className="creator-page-right-wrapper-field-left">
                          {t('creators.fields.salesFee.label')}
                        </div>
                        <div className="creator-page-right-wrapper-field-right-fee">
                          <div className="creator-page-right-wrapper-field-right-fee-icon">
                            <span className="creator-page-right-wrapper-field-right-fee-icon-percent">
                              {salesFeeValue}%
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="creator-page-right-wrapper-underline"></div>
                      <div className="creator-page-right-wrapper-field">
                        <div className="creator-page-right-wrapper-field-left">
                          {t('creators.fields.royaltyFee.label')}
                        </div>
                        <div className="creator-page-right-wrapper-field-right-fee">
                          <div className="creator-page-right-wrapper-field-right-fee-icon">
                            <span className="creator-page-right-wrapper-field-right-fee-icon-percent">
                              {royaltyFeeValue}%
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Button
                  htmlType="submit"
                  loading={updateLoading}
                  className="creator-page-right-button-bottom"
                  type="primary"
                >
                  {t('creators.buttons.save')}
                </Button>
              </Row>
            </Form>
          </>
        )}
      </DashboardContent>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME === 'patrons'
            ? t('creators.patrons.edit.title')
            : t('creators.edit.title')}
        </title>
      </Helmet>
    </>
  );
}

export default EditCreator;
