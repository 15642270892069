import { format } from 'date-fns';

const DisplayDate = ({ date, dateFormat }) => {
  if (!date) {
    return null;
  }
  return <div className="display-date">{format(new Date(date), dateFormat)}</div>;
};

DisplayDate.defaultProps = {
  dateFormat: 'MMM do, yyyy'
};

export default DisplayDate;
