import { useState, useEffect } from 'react';
import gql from '@libs/utils/gql';
import useToast from '@libs/utils/toast';
import { listActivities, activityByTypeAndCreatedAt } from '@libs/custom-queries/activity';

export const useActivity = (type) => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  const getData = async () => {
    setLoading(true);
    const userActivity = [
      'USER_LOGIN',
      'WITHDRAW_REQUESTED',
      'WITHDRAW_CONFIRMATION',
      'COLLECTIBLE_BOUGHT'
    ];
    try {
      const params = {
        limit: 1000,
        filter: {
          or: userActivity.map((a) => ({ type: { eq: a } }))
        }
      };
      const { data: res } = await gql(listActivities, params, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });

      if (res?.listActivities) {
        const activities = res.listActivities.items.map((activity) => {
          activity.event = JSON.parse(activity.event);
          return activity;
        });
        setData(activities);
      } else {
        setData(null);
      }
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getDataByType = async () => {
    setLoading(true);
    try {
      const { data: res } = await gql(activityByTypeAndCreatedAt, {
        type: type,
        limit: 1000,
        sortDirection: 'DESC'
      });

      if (res?.activityByTypeAndCreatedAt) {
        const activities = res.activityByTypeAndCreatedAt.items.map((activity) => {
          activity.event = JSON.parse(activity.event);
          return activity;
        });
        setData(activities);
      } else {
        setData(null);
      }
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (type) {
      getDataByType();
    } else {
      getData();
    }
  }, [type]); // eslint-disable-line

  return {
    data,
    loading
  };
};
