import { Row, Col, Table, Collapse, List, Input, Button, Modal, Select } from 'antd';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { DashboardContent, DisplayDate, Pagination } from '@components';
import { DeleteOutlined } from '@ant-design/icons';
import { useInbox } from '@libs/hooks/inbox';
function Inbox() {
  const { Search } = Input;
  const { Option } = Select;
  const { t } = useTranslation();
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [resetPagination, setResetPagination] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [inbox, setInbox] = useState(false);

  const {
    data,
    loading,
    deleteLoading,
    nextPageToken,
    readAllLoading,
    onRead,
    getData,
    onDelete,
    changeFilter,
    changeSearch,
    onReadAll,
    onPaginate
  } = useInbox();

  const handleRead = async (id) => {
    await onRead({
      id: id
    });
    setTimeout(() => {
      getData();
    }, 1000);
  };

  const handleDelete = useCallback(async () => {
    await onDelete({
      id: inbox?.id
    });
    setTimeout(() => {
      getData();
    }, 1000);
    setShowDeleteModal(false);
  }, [inbox]); //eslint-disable-line

  const handleSearch = (data) => {
    changeSearch(data);
    setResetPagination(!resetPagination);
  };

  const handleChange = (data) => {
    changeFilter(data);
    setResetPagination(!resetPagination);
  };

  const filter = [
    {
      value: false,
      label: 'all'
    },
    {
      value: 'unread',
      label: 'unread'
    },
    {
      value: 'read',
      label: 'read'
    }
  ];

  const columns = [
    {
      title: 'ID',
      key: 'withdrawlID',
      responsive: ['xs'],
      render: (_, row) => {
        return (
          <div className="mobile-row">
            <div className="mobile-row__info">
              <div className="mobile-row__info__name">
                <div className="inbox-id">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      handleRead(row?.id);
                      setInbox(row);
                      setShowDetailModal(true);
                    }}
                    className="inbox-id"
                  >
                    <span>{row?.inboxID}</span>
                    {row?.status === 'unread' && <div className="inbox-id-unread"></div>}
                  </div>
                </div>
              </div>
            </div>
            <Collapse collapsible ghost className="datalist">
              <Collapse.Panel header={t('offers.list.expand')} key="1">
                <List itemLayout="vertical">
                  <List.Item>
                    <List.Item.Meta
                      title={t('inbox.list.fullName')}
                      description={<span>{row?.fullName}</span>}
                    />
                    <List.Item.Meta
                      title={t('inbox.list.email')}
                      description={<span>{row?.email}</span>}
                    />
                    <List.Item.Meta
                      title={t('inbox.list.message')}
                      description={<span className="inbox-page-message">{row?.message}</span>}
                    />
                    <List.Item.Meta
                      title={t('inbox.list.submittedDate')}
                      description={
                        <div className="inbox-submitted-date">
                          <span>
                            <DisplayDate dateFormat="MMM do, yyyy hh:mm" date={row?.createdAt} />
                          </span>
                          <DeleteOutlined
                            onClick={() => {
                              setInbox(row);
                              setShowDeleteModal(true);
                            }}
                            style={{ fontSize: '1rem', color: '#D6222A' }}
                          />
                        </div>
                      }
                    />
                  </List.Item>
                </List>
              </Collapse.Panel>
            </Collapse>
          </div>
        );
      }
    },
    {
      title: t('inbox.list.id'),
      dataIndex: 'email',
      key: 'email',
      responsive: ['sm'],
      render: (_, row) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            handleRead(row?.id);
            setInbox(row);
            setShowDetailModal(true);
          }}
          className="inbox-id"
        >
          <span>{row?.inboxID}</span>
          {row?.status === 'unread' && <div className="inbox-id-unread"></div>}
        </div>
      )
    },
    {
      title: t('inbox.list.fullName'),
      dataIndex: 'email',
      key: 'email',
      responsive: ['sm'],
      render: (_, row) => <span>{row?.fullName}</span>
    },
    {
      title: t('inbox.list.email'),
      dataIndex: 'phone',
      key: 'phone',
      responsive: ['sm'],
      render: (_, row) => <span>{row?.email}</span>
    },
    {
      title: t('inbox.list.message'),
      dataIndex: 'joinedAt',
      key: 'joinedAt',
      responsive: ['sm'],
      render: (_, row) => <span className="inbox-page-message">{row?.message}</span>
    },
    {
      title: t('inbox.list.submittedDate'),
      dataIndex: 'date',
      key: 'date',
      responsive: ['sm'],
      render: (_, row) => (
        <div className="inbox-submitted-date">
          <span>
            <DisplayDate dateFormat="MMM do, yyyy hh:mm" date={row?.createdAt} />
          </span>
          <DeleteOutlined
            onClick={() => {
              setInbox(row);
              setShowDeleteModal(true);
            }}
            style={{ fontSize: '1rem', color: '#D6222A' }}
          />
        </div>
      )
    }
  ];

  return (
    <>
      <DashboardContent className="inbox-page" title={t('waitlist.title')}>
        <div className="inbox-toolbar">
          <Row className="inbox-page-search">
            <Col className="inbox-page-search-readAll" span={24} md={12}>
              <Button
                loading={readAllLoading}
                onClick={async () => {
                  await onReadAll();
                  setTimeout(() => {
                    getData();
                  }, 2000);
                }}
                type="primary"
              >
                {t('inbox.readAll')}
              </Button>
            </Col>
            <Col className="inbox-toolbar-right" span={24} md={12}>
              <div className="inbox-toolbar-right-wrapper">
                <div className="inbox-toolbar-search">
                  <div className="toolbar-search">
                    <Search
                      onChange={(e) => {
                        handleSearch(e.target.value);
                      }}
                      placeholder={t('inbox.search')}
                    />
                  </div>
                </div>
                <div className="toolbar-search">
                  <Select
                    placeholder={t('inbox.filter')}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) => {
                      handleChange(value);
                    }}
                  >
                    {filter &&
                      filter.map((x) => (
                        <Option key={`filter-${x.value}`} value={x.value}>
                          {x.label}
                        </Option>
                      ))}
                  </Select>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="inbox">
          <div className="fans-table">
            <Table
              loading={loading}
              bordered
              dataSource={data}
              columns={columns}
              pagination={false}
            />
            <Pagination
              nextToken={nextPageToken}
              reset={resetPagination}
              loading={loading}
              fetchData={onPaginate}
            />
          </div>
        </div>
        <Modal
          className="inbox-modal"
          visible={showDetailModal}
          footer={null}
          title="Submission Detail"
          onCancel={() => setShowDetailModal(false)}
          width={650}
        >
          <div className="inbox-modal-container">
            <div className="inbox-modal-container-detail">
              <table className="inbox-modal-container-detail-table">
                <tr className="inbox-modal-container-detail-table-row">
                  <td className="inbox-modal-container-detail-table-row-left">
                    {t('inbox.list.id')}
                  </td>
                  <td>{inbox?.inboxID}</td>
                </tr>
                <tr className="inbox-modal-container-detail-table-row">
                  <td className="inbox-modal-container-detail-table-row-left">
                    {t('inbox.list.fullName')}
                  </td>
                  <td className="inbox-modal-container-detail-table-row-avatar">
                    {inbox?.fullName}
                  </td>
                </tr>
                <tr className="inbox-modal-container-detail-table-row">
                  <td className="inbox-modal-container-detail-table-row-left">
                    {t('inbox.list.email')}
                  </td>
                  <td>{inbox?.email}</td>
                </tr>
                <tr className="inbox-modal-container-detail-table-row">
                  <td className="inbox-modal-container-detail-table-row-left">
                    {t('inbox.list.message')}
                  </td>
                  <td>{inbox?.message}</td>
                </tr>
                <tr className="inbox-modal-container-detail-table-row-last">
                  <td className="inbox-modal-container-detail-table-row-left">
                    {t('inbox.list.submittedDate')}
                  </td>
                  <td>{inbox?.createdAt}</td>
                </tr>
              </table>
            </div>
          </div>
        </Modal>
        <Modal
          className="inbox-delete-modal"
          visible={showDeleteModal}
          footer={null}
          onCancel={() => setShowDeleteModal(false)}
          width={650}
        >
          <div className="inbox-delete-modal-container">
            <div className="inbox-delete-modal-container-header">
              <DeleteOutlined style={{ fontSize: '5rem', color: '#D6222A' }} />
              <div className="inbox-delete-modal-container-header-title">
                {t('inbox.delete.header')}
              </div>
            </div>
            <div className="inbox-delete-modal-container-header-text">
              {t('inbox.delete.description')}
            </div>
          </div>
          <div className="inbox-delete-modal-container-footer">
            <Button
              style={{ backgroundColor: '#D6222A', border: 'none' }}
              className="inbox-delete-modal-container-footer-delete"
              type="primary"
              loading={deleteLoading}
              onClick={() => handleDelete()}
            >
              {t('inbox.delete.delete')}
            </Button>
            <Button
              onClick={() => setShowDeleteModal(false)}
              className="inbox-delete-modal-container-footer-cancel"
              type="secondary"
            >
              {t('inbox.delete.cancel')}
            </Button>
          </div>
        </Modal>
      </DashboardContent>
      <Helmet>
        <title>{t('waitlist.list.name')}</title>
      </Helmet>
    </>
  );
}

export default Inbox;
