import mimeType from '@libs/utils/mimeType';
import React, { useState } from 'react';
import LightboxOwn from './lightbox';
import { Player } from '@components';
import { PlayCircleOutlined } from '@ant-design/icons';
import { createAssetUrl } from '@libs/utils/mimeType';

const max = 4;

function ImageCollage({ images, ...props }) {
  const [indexState, setIndexState] = useState(0);
  const [open, setOpen] = useState(false);
  return (
    <>
      {images.length > 0 && (
        <div
          className={`image-collage-container ${
            images.slice(0, max).length - 1 > 0
              ? `image-collage-container-${images.slice(0, max).length - 1}`
              : ''
          }`}
          {...props}
        >
          {images.slice(0, max).map((img, index) =>
            mimeType(img.src) ? (
              <div style={{ position: 'relative' }}>
                <Player
                  src={img.src}
                  controls={false}
                  onClick={() => {
                    setOpen(!open);
                    setIndexState(index);
                  }}
                  fallbackSrc={createAssetUrl(
                    `fanclub_uploads/${img.src.split('/').pop().split('.')[0]}.mp4`,
                    null,
                    true
                  )}
                />
                <PlayCircleOutlined
                  style={{
                    fontSize: 36,
                    position: 'absolute',
                    top: '48%',
                    left: '48%',
                    color: 'white'
                  }}
                  onClick={() => {
                    setOpen(!open);
                    setIndexState(index);
                  }}
                />
              </div>
            ) : (
              <img
                key={`img-${new Date().getTime()}-${index}`}
                src={img.src}
                srcSet={img.srcSet}
                alt={`img-collage-${index}`}
                onClick={() => {
                  setOpen(!open);
                  setIndexState(index);
                }}
              />
            )
          )}
          {images.length - max > 0 && (
            <div
              className="image-collage-overlay"
              onClick={() => {
                setOpen(!open);
                setIndexState(max - 1);
              }}
            >
              +{images.length - max}
            </div>
          )}
        </div>
      )}
      {open && (
        <LightboxOwn
          show={open}
          onClose={() => {
            setOpen(!open);
            setIndexState(0);
          }}
          index={indexState}
          setIndex={setIndexState}
          sources={images}
        />
      )}
    </>
  );
}

export default ImageCollage;
