import { useState } from 'react';
import Recaptcha from 'react-recaptcha';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Form, Input, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import config from '@/config';
import { useInbox } from '@libs/hooks/inbox';

function ModalWaitinglist({ onShow, onClose }) {
  const { Title, Paragraph } = Typography;
  const [waitlistConfirm, setWaitlistConfirm] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { onCreate, createLoading } = useInbox();

  let recaptchaInstance;

  const executeCaptcha = () => {
    recaptchaInstance.execute();
  };

  const verifyCallback = async (response) => {
    await onSubmit(response);
  };

  const onSubmit = async (captchaToken) => {
    form.validateFields().then(async (data) => {
      const response = await onCreate({ ...data, captchaToken });
      if (response) {
        onClose();
        setWaitlistConfirm(true);
        form.resetFields();
      }
    });
  };

  return (
    <>
      <Modal
        className="landing__modal"
        onCancel={onClose}
        visible={onShow}
        footer={null}
        closeIcon={<></>}
        width={790}
      >
        <div>
          <Title level={2} className="landing-h2">
            {process.env.REACT_APP_NAME === 'patrons'
              ? t('components.modalWaitinglist.patrons.title')
              : t('components.modalWaitinglist.title')}
          </Title>
          <Paragraph className="landing-paragraph">
            {process.env.REACT_APP_NAME === 'patrons'
              ? t('components.modalWaitinglist.patrons.description')
              : t('components.modalWaitinglist.description')}
          </Paragraph>
          <Form onFinish={executeCaptcha} form={form}>
            <Form.Item
              name="fullName"
              rules={[
                {
                  required: true,
                  message: t('components.modalWaitinglist.fields.fullName.required')
                }
              ]}
            >
              <Input placeholder={t('components.modalWaitinglist.fields.fullName.placeholder')} />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: t('components.modalWaitinglist.fields.email.required') },
                {
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: t('components.modalWaitinglist.fields.email.invalid')
                }
              ]}
            >
              <Input placeholder={t('components.modalWaitinglist.fields.email.placeholder')} />
            </Form.Item>
            <Form.Item
              name="message"
              rules={[
                {
                  required: true,
                  message: t('components.modalWaitinglist.fields.message.required')
                }
              ]}
            >
              <Input.TextArea
                rows={4}
                placeholder={t('components.modalWaitinglist.fields.message.placeholder')}
              />
            </Form.Item>
            <Button
              htmlType="submit"
              className="landing__button"
              loading={createLoading}
              disabled={createLoading}
            >
              {t('components.modalWaitinglist.button')}
            </Button>
          </Form>
        </div>
      </Modal>
      <Modal
        className="landing__modal"
        visible={waitlistConfirm}
        footer={null}
        closeIcon={<></>}
        width={790}
        onCancel={() => {
          setWaitlistConfirm(false);
        }}
      >
        <div>
          <CheckCircleOutlined className="landing__modal__icon" />
          <Title level={2} className="landing-h2">
            {t('components.modalWaitinglist.confirmation.title')}
          </Title>

          <Paragraph className="landing-paragraph">
            {process.env.REACT_APP_NAME === 'patrons'
              ? t('components.modalWaitinglist.confirmation.patrons.description')
              : t('components.modalWaitinglist.confirmation.description')}
          </Paragraph>
          <Paragraph className="landing-paragraph">
            Please feel free to contact Artist Relations below:
          </Paragraph>
          <div className="landing-paragraph__contact">
            <div>
              <Title
                level={4}
                style={{
                  fontSize: '1.2rem',
                  borderBottom: '1px solid #666',
                  paddingBottom: '10px',
                  color: '#fff'
                }}
              >
                USA: Los Angeles
              </Title>
              <Paragraph className="landing-paragraph">
                <strong>Brandon Reister</strong>
                <br />
                brandon@fans.inc
                <br />
                PST +1( 315) 567-7881
              </Paragraph>
            </div>
            <div>
              <Title
                level={4}
                style={{
                  fontSize: '1.2rem',
                  borderBottom: '1px solid #666',
                  paddingBottom: '10px',
                  color: '#fff'
                }}
              >
                Asia-Pac: Sydney
              </Title>
              <Paragraph className="landing-paragraph">
                <strong>Colin Fabig</strong>
                <br />
                col@fans.inc
                <br />
                AEST +6143-486-5064
              </Paragraph>
            </div>
          </div>
          <Paragraph
            className="landing-paragraph"
            style={{ fontSize: '1.2rem', fontWeight: 'bold' }}
          >
            We're looking forward to connecting
          </Paragraph>
        </div>
      </Modal>
      <Recaptcha
        ref={(e) => (recaptchaInstance = e)}
        sitekey={config.cookie.TOKEN_SITE_KEY_CAPTCHA_CONTACT}
        size="invisible"
        verifyCallback={verifyCallback}
      />
    </>
  );
}

export default ModalWaitinglist;
