import { Storage } from 'aws-amplify';
import { Trans } from 'react-i18next';
import { notification, message } from 'antd';
import { nanoid } from 'nanoid';
import useImage from 'use-image';
import QRCode from 'qrcode';

export const categories = [
  { name: 'Artwork', value: 'ARTWORK' },
  { name: 'Photograph', value: 'PHOTOGRAPH' },
  { name: 'Song', value: 'SONG' },
  { name: 'Song Lyrics', value: 'SONG_LYRICS' },
  { name: 'Video', value: 'VIDEO' },
  { name: 'VIP Pass', value: 'VIP_PASS' },
  { name: 'Other', value: 'OTHER' }
];

export const genres =
  process.env.REACT_APP_NAME === 'patrons'
    ? [
        { name: 'Cubist', value: 'CUBIST' },
        { name: 'Abstract', value: 'ABSTRACT' },
        { name: 'Avant-garde', value: 'AVANT_GARDE' },
        { name: 'Art Nouveau', value: 'ART_NOUVEAU' },
        { name: 'Baroque', value: 'BAROQUE' },
        { name: 'Bauhaus', value: 'BAUHAUS' },
        { name: 'Conceptual Art', value: 'CONCEPTUAL_ART' },
        { name: 'Cubism', value: 'CUBISM' },
        { name: 'Dada', value: 'DADA' },
        { name: 'Expressionism', value: 'EXPRESSIONISM' },
        { name: 'Modernism', value: 'MODERNISM' },
        { name: 'Contemporary Australian', value: 'CONTEMPORARY_AUSTRALIAN' },
        { name: 'Indigenous Australian', value: 'INDIGENOUS_AUSTRALIAN' },
        { name: 'Fluxus', value: 'FLUXUS' },
        { name: 'Other', value: 'OTHER' }
      ]
    : [
        { name: 'Adult Contemporary', value: 'ADULT_CONTEMPORARY' },
        { name: 'Alternative', value: 'ALTERNATIVE' },
        { name: 'Alt-Pop', value: 'ALT_POP' },
        { name: 'Alt-Rock', value: 'ALT_ROCK' },
        { name: 'Blues', value: 'BLUES' },
        { name: 'Classic Rock', value: 'CLASSIC_ROCK' },
        { name: 'Country', value: 'COUNTRY' },
        { name: 'Dance', value: 'DANCE' },
        { name: 'EDM', value: 'EDM' },
        { name: 'Electronic', value: 'ELECTRONIC' },
        { name: 'Folk', value: 'FOLK' },
        { name: 'Heavy Metal', value: 'HEAVY_METAL' },
        { name: 'Hip Hop', value: 'HIPHOP' },
        { name: 'Jazz', value: 'JAZZ' },
        { name: 'New Age', value: 'NEW_AGE' },
        { name: 'Other', value: 'OTHER' },
        { name: 'Pop', value: 'POP' },
        { name: 'Pop-Rock', value: 'POP_ROCK' },
        { name: 'Punk Rock', value: 'PUNK_ROCK' },
        { name: 'Rap', value: 'RAP' },
        { name: 'Reggae', value: 'REGGAE' },
        { name: 'Rock', value: 'ROCK' },
        { name: 'Spiritual', value: 'SPIRITUAL' },
        { name: 'Soul', value: 'SOUL' },
        { name: 'Urban', value: 'URBAN' }
      ];

export const listingTypes = [
  { label: 'Auction', value: 'AUCTION' },
  { label: 'Make An Offer', value: 'MAKE_AN_OFFER' },
  { label: 'Fixed Price', value: 'FIXED_PRICE' }
];

export const categoriesMapper = categories
  .reduce((prev, next) => prev.concat(next), []) // flatten subcategories
  .reduce((prev, next) => {
    prev[next.value] = next.name;
    return prev;
  }, {});

export const getImageRes = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = () => {
      reader.abort();
      reject(new Error('Problem parsing input file.'));
    };

    reader.readAsDataURL(file);
    reader.addEventListener('load', (event) => {
      const _loadedImageUrl = event.target.result;
      const image = document.createElement('img');
      image.src = _loadedImageUrl;
      image.addEventListener('load', () => {
        const { width, height } = image;
        resolve({ width, height });
      });
    });
  });
};

export const draggerProps = (setAssetUrl, folder, creatorID = '') => {
  const supported = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/svg+xml',
    'image/png',
    'video/mp4',
    'audio/mpeg',
    'audio/wav',
    'audio/ogg'
  ];

  const allowed =
    folder === 'collectible_thumbnails'
      ? supported.filter((x) => x.indexOf('image') > -1)
      : supported;

  return {
    maxCount: 1,
    accept: allowed.join(','),
    beforeUpload(file) {
      const fileFormat = file.name.split('.').pop();
      const isJFIF = fileFormat === 'jfif';

      const isAllowed = allowed.includes(file.type) && !isJFIF;

      if (!isAllowed) {
        message.error('File type is not supported.');
      }

      const isLt5M = file.size / 1024 / 1024 < 100;
      if (!isLt5M) {
        message.error('File must smaller than 100MB.');
      }

      return isAllowed && isLt5M;
    },
    customRequest: async (options) => {
      try {
        const ext = options.file.name.split('.').pop();
        const res = await Storage.put(
          `temps/${folder}/${creatorID}_${nanoid()}.${ext}`,
          options.file
        );

        if (res.key) {
          const isImage = options.file.type?.indexOf('image') > -1;
          let imageRes = false;
          if (isImage) {
            imageRes = await getImageRes(options.file);
          }
          await setAssetUrl(res.key, false, isImage, imageRes);
          options.onSuccess(res.key, options.file);
        } else {
          options.onError('Failed when uploading the file.');
        }
      } catch (err) {
        options.onError(err);
      }
    },
    onRemove: async (e) => {
      await setAssetUrl(e.key, true, false);
    },
    onChange(info) {
      if (info.file.status === 'done') {
        notification['success']({
          message: <Trans i18nKey="collectibles.toast.uploadImage.success.title" />,
          description: (
            <Trans
              i18nKey="collectibles.toast.uploadImage.success.message"
              values={{ fileName: info.file.name }}
            />
          )
        });
      }
      if (info.file.status === 'error') {
        notification['error']({
          message: <Trans i18nKey="collectibles.toast.uploadImage.failed.title" />,
          description: (
            <Trans
              i18nKey="collectibles.toast.uploadImage.failed.message"
              values={{ fileName: info.file.name }}
            />
          )
        });
      }
    }
  };
};

export const defaultFileList = (s3Key) => {
  return {
    uid: '1',
    name: s3Key,
    status: 'done',
    url: s3Key,
    response: s3Key
  };
};

export const imageScale = (collectibleImage, width, height) => {
  let widthFrame = width;
  let heightFrame = height;
  let widthTamp = width;
  let heightTamp = height;
  let isVertical = false;
  let imageWidth = collectibleImage ? collectibleImage.width : width;
  let imageHeight = collectibleImage ? collectibleImage.height : height;

  if (imageWidth >= imageHeight) {
    isVertical = true;
  }

  if (isVertical) {
    widthFrame = heightTamp;
    heightFrame = widthTamp;
  } else {
    widthFrame = widthTamp;
    heightFrame = heightTamp;
  }

  return {
    heightFrame,
    widthFrame,
    imageWidth,
    imageHeight
  };
};

export const GetImage = (src) => {
  return useImage(src, 'Anonymous');
};

export const generateQR = async (text) => {
  try {
    const qrCodeRes = await QRCode.toDataURL(text);
    return qrCodeRes;
  } catch (err) {
    console.error(err);
    return err;
  }
};
