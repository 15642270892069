import { Features } from '@vincere/react-vlag';
import { Helmet } from 'react-helmet';
import { Row, Typography } from 'antd';
const BetaFeatures = () => {
  const { Title } = Typography;
  return (
    <>
      <Row className="beta-features">
        <div className="beta-features-content">
          <Title>Beta Features</Title>
          <div className="beta-features-content-feature">
            <Features />
          </div>
        </div>
      </Row>

      <Helmet>
        <title>Beta Features</title>
        <meta property="og:image" content="https://cdn.fans.inc/public/webapp/og-image.png" />
      </Helmet>
    </>
  );
};

export default BetaFeatures;
