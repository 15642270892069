import { useState, useEffect, useCallback } from 'react';
import {
  DashboardContent,
  Avatar,
  RichTextEditor,
  Post,
  UnauthorizedAccess,
  ImageCollage
} from '@components';
import { Helmet } from 'react-helmet';
import useToast from '@libs/utils/toast';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAuth } from '@libs/contexts/auth';
import { formatDistanceToNowStrict } from 'date-fns';
import { Row, Col, Button, Modal, Form, Input, Select, Spin } from 'antd';
import { useParams } from 'react-router';
import {
  EditFilled,
  DeleteFilled,
  CheckCircleOutlined,
  DeleteOutlined,
  ArrowLeftOutlined
} from '@ant-design/icons';
import { useDiscourse, useDiscourseFeatured } from '@libs/hooks/discourse';
import { useChannelByOwner } from '@libs/hooks/discourse';
import UploadImage from './upload-image';
import mimeType, { createAssetUrl } from '@libs/utils/mimeType';

function FanClub() {
  const history = useHistory();
  const { t } = useTranslation();
  const toast = useToast();
  const { creatorID, channelID } = useParams();
  const { creators: profile } = useAuth();
  const { Option } = Select;
  const { TextArea } = Input;
  const [creator, setCreator] = useState();
  const [unauthorize, setUnauthorize] = useState(false);
  const [inputPostValue, setInputPostValue] = useState('');
  const [detailPost, setDetailPost] = useState(false);
  const [channel, setChannel] = useState();
  const [discourseInput, setDiscourseInput] = useState({
    userID: '',
    channelID: ''
  });
  let { data: channels, getData: fetchChannel } = useChannelByOwner(creatorID);
  const {
    data,
    loading: postLoading,
    createPostLoading,
    createChannelLoading,
    deleteChannelLoading,
    updateChannelLoading,
    paginationLoading,
    nextPageToken,
    setLoading,
    createPost,
    getData,
    createChannel,
    deleteChannel,
    updateChannel
  } = useDiscourse(discourseInput);

  const {
    data: featuredPost,
    loading: featuredLoading,
    getData: fetchFeaturedPost
  } = useDiscourseFeatured(discourseInput);

  const [showChannelCreated, setShowChannelCreated] = useState(false);
  const [showChannelDeleteModal, setShowChannelDeleteModal] = useState(false);
  const [showChannelUpdateModal, setShowChannelUpdateModal] = useState(false);
  const [channelValue, setChannelValue] = useState();
  const [channelNameValue, setChannelNameValue] = useState();
  const [showChannelCreateModal, setShowChannelCreateModal] = useState(false);
  const [imageList, setImageList] = useState([]);

  const handleInputPostChange = (editor) => {
    setInputPostValue(editor.getHTML());
  };
  const [crateChannelForm] = Form.useForm();

  const handleCreateChannel = useCallback(async () => {
    crateChannelForm.validateFields().then(async (data) => {
      const payload = {
        name: data.name,
        description: data.description,
        parentCategoryId: creator?.discourseData.category,
        ownerID: creator?.id
      };
      const response = await createChannel(payload);
      await fetchChannel();
      setShowChannelCreateModal(false);
      response && setShowChannelCreated(true);
      crateChannelForm.resetFields();
    });
  }, [creator]); // eslint-disable-line

  const handleUpdateChannel = useCallback(async () => {
    const payload = {
      id: channelValue.id,
      name: channelNameValue
    };
    await updateChannel(payload);
    await fetchChannel();
    setShowChannelUpdateModal(false);
  }, [channelValue, channelNameValue]); // eslint-disable-line

  const profileIds = profile?.map((x) => x.id);

  const handlePost = useCallback(async () => {
    const now = new Date();
    const nowStirng = now.toString();

    const imageCollage = `<div data-component="image-collage">${imageList.map((img) => {
      const isAudioVideo = mimeType(img.url);
      const format = isAudioVideo && isAudioVideo.split('/')?.[0];
      let src = img.url.replace('temps/', '');
      if (format === 'video') {
        src = createAssetUrl(img.url);
      }
      return `<img src="${src}" />`;
    })}</div>`;

    const payload = {
      title:
        (creator?.displayName || creator?.firstName + ' ' + creator?.lastName) +
        ' POST ' +
        nowStirng,
      raw: `${inputPostValue}${imageCollage}`
    };
    await createPost(payload);
    setImageList([]);

    setInputPostValue('');
    await fetchChannel();
    setLoading(true);
    setTimeout(function () {
      getData();
    }, 2000);
  }, [inputPostValue]); // eslint-disable-line
  useEffect(() => {
    if (profile?.length > 0) {
      if (creatorID) {
        const creator = profile?.filter((x) => x.id === creatorID);
        setCreator(creator[0]);
      } else {
        setCreator(profile[0]);
      }
    }
  }, [profile]); // eslint-disable-line

  const profileOption =
    profile?.map((x) => ({
      label: x.firstName + ' ' + x.lastName,
      value: x.id
    })) || [];

  const handleChange = (id) => {
    const selectedProfile = profile?.filter((x) => x.id === id);
    history.push(`/fan-club/${id}/channels/${channelID}`);
    setInputPostValue('');
    setCreator(selectedProfile[0]);
  };

  const handleDeleteChannel = useCallback(
    async (id) => {
      if (data.length < 1) {
        await deleteChannel(id);
        setShowChannelDeleteModal(false);
        setChannel();
        await fetchChannel();
      } else {
        toast(t('fanClub.channel.cantDeleteChannel'), 'error');
        setShowChannelDeleteModal(false);
      }
    },
    [data] // eslint-disable-line
  );

  useEffect(() => {
    if (channels && creatorID && profileIds) {
      if (profileIds?.includes(creatorID)) {
        const channelIDs = channels.map((x) => x?.id);
        if (!channelIDs.includes(channelID)) {
          const defaultChannels = channels?.filter((x) => !x.removeable)[0];
          setChannel(defaultChannels);
          history.push(`/fan-club/${creatorID}/channels/${defaultChannels?.id}`);
        }
      } else {
        setUnauthorize(true);
      }
    }
  }, [channels, creatorID, profileIds]); // eslint-disable-line

  useEffect(() => {
    if (channels) {
      const channelIDs = channels.map((x) => x?.id);
      if (channelIDs.includes(channelID)) {
        const selectedChannel = channels.filter((x) => x?.id === channelID);
        setChannel(selectedChannel[0]);
        setDiscourseInput({
          userID: creatorID,
          channelID: channelID
        });
      }
    }
  }, [channels, channelID]); // eslint-disable-line

  if (unauthorize) {
    return <UnauthorizedAccess />;
  }

  return (
    <>
      <DashboardContent className="fanclub-page" title={t('fanClub.title')}>
        {detailPost ? (
          <div className="fanclub-page-header">
            <ArrowLeftOutlined />{' '}
            <span
              onClick={() => {
                setLoading(true);
                setTimeout(function () {
                  getData();
                }, 2000);
                setDetailPost(false);
              }}
              className="fanclub-page-header-detail"
            >
              <Trans i18nKey={`fanClub.backToChannel`}>{{ channel: channel?.name }}</Trans>
            </span>
          </div>
        ) : (
          <>
            <Row className="toolbar">
              <Col sm={8} xs={24}>
                {profile?.length > 0 && (
                  <div className="toolbar-search">
                    <Select
                      loading={postLoading}
                      placeholder={t('fanClub.input.creatorPlaceholder')}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => {
                        handleChange(value);
                      }}
                      value={creatorID}
                      defaultValue={profile?.[0].id}
                    >
                      {profileOption &&
                        profileOption.map((creator) => (
                          <Option key={`creator-${creator.value}`} value={creator.value}>
                            {creator.label}
                          </Option>
                        ))}
                    </Select>
                  </div>
                )}
              </Col>
            </Row>
            <div className="fanclub-page-header">{channel?.name}</div>
          </>
        )}

        <>
          <Row className="fanclub-page-body">
            <Col span={24} lg={18}>
              {!postLoading && !featuredLoading ? (
                <>
                  {detailPost ? (
                    <Post
                      user={creator}
                      postId={detailPost?.id}
                      post={detailPost}
                      fetchFeaturedPost={fetchFeaturedPost}
                      setDetailPost={setDetailPost}
                      setLoadingPosts={setLoading}
                      fetchPosts={getData}
                      channel={channel}
                    />
                  ) : (
                    <>
                      <div className="fanclub-page-body-create-post">
                        <Avatar size={36} user={creator} />
                        <div className="fanclub-page-body-create-post-input">
                          <div className="fanclub-page-body-create-post-input-editor">
                            <RichTextEditor
                              placeholder={t('fanClub.input.placeholder')}
                              onChange={handleInputPostChange}
                              onBlur={handleInputPostChange}
                              value={inputPostValue}
                              isFanClub={true}
                            />
                          </div>
                          <ImageCollage
                            style={{ marginBlock: 16 }}
                            images={imageList.map((img) => ({ src: img.url }))}
                          />
                          <Button
                            onClick={() => handlePost()}
                            loading={createPostLoading}
                            type="primary"
                          >
                            {t('fanClub.button.postToGeneral')}
                          </Button>
                          <UploadImage
                            imageList={imageList}
                            setImageList={setImageList}
                            creatorID={creatorID}
                          />
                        </div>
                      </div>
                      {featuredPost &&
                        featuredPost?.map((post) => (
                          <Post
                            user={creator}
                            postId={post?.id}
                            setDetailPost={setDetailPost}
                            post={post}
                            fetchFeaturedPost={fetchFeaturedPost}
                            setLoadingPosts={setLoading}
                            fetchPosts={getData}
                            channel={channel}
                            fetchChannel={fetchChannel}
                            featured
                          />
                        ))}
                      {data &&
                        data?.map((post) => (
                          <Post
                            user={creator}
                            postId={post?.id}
                            setDetailPost={setDetailPost}
                            post={post}
                            fetchFeaturedPost={fetchFeaturedPost}
                            setLoadingPosts={setLoading}
                            fetchPosts={getData}
                            channel={channel}
                            fetchChannel={fetchChannel}
                          />
                        ))}
                      {nextPageToken && (
                        <div className="fanclub-page-body-load-post">
                          <Button
                            type="primary"
                            className="fanclub-page-body-load-post-button"
                            loading={paginationLoading}
                            onClick={() => getData(nextPageToken)}
                          >
                            {t('fanClub.button.loadMore')}
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <center>
                  <Spin />
                </center>
              )}
            </Col>

            <Col className="fanclub-page-body-channel" span={24} lg={6}>
              <div className="fanclub-page-body-channel-content">
                <div className="fanclub-page-body-channel-content-header">
                  {t('fanClub.channel.title')}
                </div>
                <div className="fanclub-page-body-channel-content-list"></div>
                {channels &&
                  channels.map((x) => (
                    <div
                      onClick={() => {
                        setChannel(x);
                        history.push(`/fan-club/${creatorID}/channels/${x?.id}`);
                      }}
                      className={`fanclub-page-body-channel-content-list-item${
                        x?.id === channel?.id ? '-active' : ''
                      }`}
                    >
                      <Row>
                        <Col className="fanclub-page-body-channel-content-list-item-left" span={16}>
                          <div className="fanclub-page-body-channel-content-list-item-left-name">
                            {x.name}
                          </div>
                          <div className="fanclub-page-body-channel-content-list-item-left-activity">
                            {t('fanClub.channel.lastActivity')}{' '}
                            {formatDistanceToNowStrict(new Date(x.lastActivityAt), {
                              addSuffix: true
                            })}
                          </div>
                        </Col>
                        <Col className="fanclub-page-body-channel-content-list-item-right" span={8}>
                          <EditFilled
                            onClick={() => {
                              setChannelValue(x);
                              setShowChannelUpdateModal(true);
                              setChannelNameValue(x.name);
                            }}
                          />
                          {x.removeable && (
                            <DeleteFilled
                              onClick={() => {
                                setChannelValue(x);
                                setShowChannelDeleteModal(true);
                                setChannelNameValue(x.name);
                              }}
                              style={{ color: '#D6222A', marginLeft: '8px' }}
                            />
                          )}
                        </Col>
                      </Row>
                    </div>
                  ))}
              </div>
              <Button
                onClick={() => setShowChannelCreateModal(true)}
                type="primary"
                className="fanclub-page-body-channel-button"
              >
                {t('fanClub.button.addNewChannel')}
              </Button>
            </Col>
          </Row>
        </>

        <Modal
          className="fanclub-channel-created-modal"
          visible={showChannelCreated}
          footer={null}
          onCancel={() => setShowChannelCreated(false)}
          width={650}
        >
          <div className="fanclub-channel-created-modal-container">
            <div className="fanclub-channel-created-modal-container-header">
              <CheckCircleOutlined style={{ fontSize: '5rem', color: '#27AE60' }} />
              <div className="fanclub-channel-created-modal-container-header-title">
                {t('fanClub.channel.created')}
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          className="fanclub-channel-delete-modal"
          visible={showChannelDeleteModal}
          footer={null}
          onCancel={() => setShowChannelDeleteModal(false)}
          width={650}
        >
          <div className="fanclub-channel-delete-modal-container">
            <div className="fanclub-channel-delete-modal-container-header">
              <DeleteOutlined style={{ fontSize: '5rem', color: '#D6222A' }} />
              <div className="fanclub-channel-delete-modal-container-header-title">
                {t('fanClub.channel.deleteChannel')}
              </div>
            </div>
            <div className="fanclub-channel-delete-modal-container-header-text">
              <Trans i18nKey={`fanClub.channel.deleteConfirm`}>
                {{ channel: channelValue?.name }}
              </Trans>
            </div>
          </div>
          <div className="fanclub-channel-delete-modal-container-footer">
            <Button
              style={{ backgroundColor: '#D6222A', border: 'none' }}
              className="fanclub-channel-delete-modal-container-footer-delete"
              type="primary"
              onClick={() => handleDeleteChannel(channelValue?.id)}
              loading={deleteChannelLoading}
            >
              {t('fanClub.channel.delete')}
            </Button>
            <Button
              onClick={() => setShowChannelDeleteModal(false)}
              className="fanclub-channel-delete-modal-container-footer-cancel"
              type="secondary"
            >
              {t('fanClub.channel.cancel')}
            </Button>
          </div>
        </Modal>
        <Modal
          className="fanclub-channel-update-modal"
          visible={showChannelUpdateModal}
          footer={null}
          title={t('fanClub.channel.updateChannel')}
          onCancel={() => setShowChannelUpdateModal(false)}
          width={650}
        >
          <Form layout="vertical" className="admin-form">
            <Form.Item label={t('fanClub.channel.form.name.label')} required>
              <Input
                value={channelNameValue}
                onChange={(e) => {
                  setChannelNameValue(e.target.value);
                }}
                placeholder={t('fanClub.channel.form.name.placeholder')}
              />
            </Form.Item>
            <Form.Item label={t('fanClub.channel.form.description.label')}>
              <TextArea
                value={channelValue?.description}
                rows={2}
                placeholder={t('fanClub.channel.form.description.placeholder')}
              />
            </Form.Item>
            <Button
              onClick={() => handleUpdateChannel()}
              loading={updateChannelLoading}
              htmlType="submit"
              className="fanclub-channel-update-modal-button"
              type="primary"
            >
              {t('fanClub.channel.save')}
            </Button>
          </Form>
        </Modal>
        <Modal
          className="fanclub-channel-update-modal"
          visible={showChannelCreateModal}
          footer={null}
          title={t('fanClub.channel.createChannel')}
          onCancel={() => setShowChannelCreateModal(false)}
          width={650}
        >
          <Form
            onFinish={() => handleCreateChannel()}
            form={crateChannelForm}
            layout="vertical"
            className="admin-form"
          >
            <Form.Item name="name" label={t('fanClub.channel.form.name.label')} required>
              <Input placeholder={t('fanClub.channel.form.name.placeholder')} />
            </Form.Item>
            <Form.Item name="description" label={t('fanClub.channel.form.description.label')}>
              <TextArea rows={2} placeholder={t('fanClub.channel.form.description.placeholder')} />
            </Form.Item>
            <Button
              loading={createChannelLoading}
              htmlType="submit"
              className="fanclub-channel-update-modal-button"
              type="primary"
            >
              {t('fanClub.channel.save')}
            </Button>
          </Form>
        </Modal>
      </DashboardContent>
      <Helmet>
        <title>{t('fanClub.title')}</title>
      </Helmet>
    </>
  );
}

export default FanClub;
