import { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import gql from '@libs/utils/gql';
import useToast from '@libs/utils/toast';
import { useAuth } from '@libs/contexts/auth';
import {
  profileManagerByAccountAndManager,
  searchAccounts
} from '@libs/custom-queries/organisation';
import {
  accountUserByAccount,
  accountUserInvitedByAccount
} from '@libs/custom-queries/organisation';
import { getAccount, getAccountStatistic, accountUserByUser } from '@graphql/queries';
import {
  customCreateUser,
  inviteMember,
  revokeMembership,
  cancelMemberInvitation,
  switchOrganisation,
  removeData,
  customUpdateAccount
} from '@graphql/mutations';

export const useOrganisations = () => {
  const { user } = useAuth();
  const toast = useToast();

  const [data, setData] = useState([]);
  const [nextPageToken, setNextPageToken] = useState();
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const limit = 20;

  const fetchData = async (search, nextToken) => {
    setLoading(true);
    try {
      if (user?.isAdmin) {
        let params = { limit };
        if (search) {
          const filter = {
            name: { matchPhrasePrefix: search }
          };
          const filterMembers = {
            lastName: { ne: 'WaitingForConfirmation' }
          };
          params = { filter, filterMembers };
        }

        if (nextToken) {
          params = { ...params, nextToken };
        }
        const { data: res } = await gql(searchAccounts, params);

        setData(res?.searchAccounts?.items);
        setNextPageToken(res?.searchAccounts?.nextToken);
      }
    } catch (error) {
      console.error(error);
      const errorMessage = error.errors[0]?.message;
      toast(errorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  const onDelete = useCallback(
    async (id, cb) => {
      setDeleteLoading(true);
      try {
        await gql(
          removeData,
          {
            input: {
              id: id,
              item: 'account'
            }
          },
          {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }
        );

        toast('Account deleted succesfully', 'success');
        setData(data.filter((d) => d.id !== id));
      } catch (error) {
        console.error(error);
        const errorMessage = error.errors[0]?.errorType;
        toast(errorMessage, 'error');
      } finally {
        setDeleteLoading(false);
        cb && cb();
        setTimeout(() => fetchData(), 1000);
      }
    },
    [data] // eslint-disable-line
  );

  const onSearch = useCallback(
    async (name) => {
      fetchData(name);
    },
    [] // eslint-disable-line
  );

  const onNext = useCallback(
    async (token) => {
      fetchData(null, token);
    },
    [] // eslint-disable-line
  );

  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    data,
    nextPageToken: data.length < limit ? null : nextPageToken,
    loading,
    deleteLoading,
    fetchData,
    onDelete,
    onSearch,
    onNext
  };
};

export const useOrganisation = (id) => {
  const { user, setCreators } = useAuth();
  const toast = useToast();
  const { t } = useTranslation();
  const history = useHistory();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const params = {
        id: id ? id : user?.accountID
      };

      const { data: res } = await gql(getAccount, params);
      setData(res?.getAccount);
    } catch (error) {
      const errorMessage = error.errors[0]?.message;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?.accountID || id) fetchData();
  }, [user?.accountID, id]); // eslint-disable-line react-hooks/exhaustive-deps

  const onUpdate = useCallback(
    async (payload) => {
      setLoading(true);

      if (!user?.accountID && !id) {
        return;
      }

      try {
        await gql(
          customUpdateAccount,
          {
            input: {
              id: id ? id : user?.accountID,
              ownerID: user?.id,
              name: payload.name,
              legalName: payload.legalName,
              address: payload.address,
              phone: payload.phone,
              website: payload.website,
              country: payload.country,
              city: payload.city,
              state: payload.state,
              postcode: payload.postcode
            }
          },
          {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }
        );

        toast(t('settings.toast.successUpdate'), 'success');
      } catch (error) {
        const errorMessage = error.errors[0]?.message;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [user?.accountID] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onSwitchOrganisation = useCallback(
    async (accountID) => {
      setLoading(true);

      if (!user?.accountID) {
        return;
      }

      try {
        const payload = {
          input: {
            accountID: accountID
          }
        };
        await gql(switchOrganisation, payload, {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
        const params = {
          accountID: accountID,
          managerID: { eq: user?.id }
        };
        const { data: listCreators } = await gql(profileManagerByAccountAndManager, params);
        if (listCreators?.profileManagerByAccountAndManager?.items) {
          const profiles = listCreators?.profileManagerByAccountAndManager?.items?.map(
            (x) => x.profile
          );
          setCreators(profiles);
        }

        history.push('/collectibles');
      } catch (error) {
        const errorMessage = error.errors[0]?.message;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [user?.accountID] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return {
    data,
    loading,
    onUpdate,
    onSwitchOrganisation
  };
};

export const useOrganizationStaffs = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [invitationLoading, setInvitationLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [data, setData] = useState([]);
  const [nextPageToken, setNextPageToken] = useState();

  const getData = async (Token, FirstName) => {
    setLoading(true);
    try {
      if (user?.accountID) {
        const params = {
          accountID: user.accountID,
          filter: { lastName: { ne: 'WaitingForConfirmation' } }
        };
        if (Token) {
          params.nextToken = Token;
        }
        if (FirstName) {
          params.filter = {
            lastName: { ne: 'WaitingForConfirmation' },
            firstName: { contains: FirstName }
          };
        }
        const { data: res } = await gql(accountUserByAccount, params);
        let result = [];
        if (res?.accountUserByAccount?.items) {
          result = res?.accountUserByAccount?.items;
          setNextPageToken(res?.accountUserByAccount?.nextToken);
        }
        setData(result);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!user?.accountID) {
      return;
    }

    getData();
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const onInvite = useCallback(
    async (email) => {
      setInvitationLoading(true);
      try {
        await gql(
          inviteMember,
          {
            input: {
              accountID: user?.accountID,
              email,
              roleType: 11
            }
          },
          {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }
        );
        await getData();
        toast(t('teams.toast.successInvite', { email }), 'success');
      } catch (error) {
        const errorMessage = error.errors[0]?.errorType;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setInvitationLoading(false);
      }
    },
    [user] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onDelete = useCallback(
    async (id, accountID, type) => {
      setDeleteLoading(true);
      try {
        const payload = { input: { userID: id, accountID } };
        await gql(revokeMembership, payload, {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });

        await getData();
        type === 'leave'
          ? toast(t('teams.toast.leave'), 'success')
          : toast(t('teams.toast.remove'), 'success');
      } catch (error) {
        const errorMessage = error.errors[0]?.message;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setDeleteLoading(false);
      }
    },
    [user] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return {
    loading,
    invitationLoading,
    deleteLoading,
    data: data,
    nextPageToken,
    getData,
    onInvite,
    onDelete
  };
};

export const useOrganizationStaffInvited = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const { user } = useAuth();
  const toast = useToast();
  const { t } = useTranslation();
  const [deleteLoading, setDeleteLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      if (user?.accountID) {
        const params = {
          accountID: user.accountID,
          filter: { lastName: { eq: 'WaitingForConfirmation' } }
        };
        const { data: res } = await gql(accountUserInvitedByAccount, params);
        if (res?.accountUserByAccountRole?.items) {
          setData(res?.accountUserByAccountRole?.items);
        } else {
          setData([]);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const cancelInvite = useCallback(
    async (id) => {
      setDeleteLoading(true);
      try {
        const payload = { input: { id } };
        await gql(cancelMemberInvitation, payload, {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });

        await fetchData();
        toast(t('teams.toast.cancelInvite'), 'success');
      } catch (error) {
        const errorMessage = error.errors[0]?.message;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setDeleteLoading(false);
      }
    },
    [user] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    fetchData();
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading,
    deleteLoading,
    data,
    cancelInvite,
    fetchData
  };
};

export const useOrganizationAccountUser = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const { user } = useAuth();

  const fetchData = async () => {
    setLoading(true);
    try {
      if (user?.accountID) {
        const params = {
          accountID: { eq: user?.accountID },
          userID: user?.id
        };
        const { data: res } = await gql(accountUserByUser, params);
        if (res?.accountUserByUser?.items[0]) {
          setData(res?.accountUserByUser?.items[0]);
        } else {
          setData([]);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading,
    data
  };
};

export const useProfileOrganisation = (form = false) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { user } = useAuth();
  const history = useHistory();
  const [nextPageToken, setNextPageToken] = useState();
  const toast = useToast();

  const getData = async (Token, FirstName) => {
    setLoading(true);
    try {
      if (user?.accountID) {
        const params = {
          accountID: user.accountID,
          managerID: { eq: user.id }
        };
        if (Token) {
          params.nextToken = Token;
        }

        if (!form) {
          params.limit = 10;
        }

        if (FirstName) {
          params.filter = { firstName: { contains: FirstName } };
          params.limit = 100;
        }

        const { data: res } = await gql(profileManagerByAccountAndManager, params);

        let result = [];
        if (res?.profileManagerByAccountAndManager?.items) {
          result = res?.profileManagerByAccountAndManager?.items;
          setNextPageToken(res?.profileManagerByAccountAndManager?.nextToken);
        }
        if (form === true) {
          result =
            res?.profileManagerByAccountAndManager?.items.map((x) => ({
              label: x.profile.firstName + ' ' + x.profile.lastName,
              value: x.profile.id
            })) || [];
        }
        setData(result);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, [user]); // eslint-disable-line

  const onCreate = useCallback(
    async (payload) => {
      setLoading(true);
      try {
        await gql(
          customCreateUser,
          {
            input: {
              accountID: user.accountID,
              editors: payload.editors || [],
              username: payload.username,
              firstName: payload.firstName,
              lastName: payload.lastName,
              displayName: payload.displayName,
              bio: payload.bio,
              avatarUrl: payload.avatarUrl,
              avatarVideoUrl: payload.avatarVideoUrl,
              coverUrl: payload.coverUrl,
              facebook: payload.facebook,
              youtube: payload.youtube,
              instagram: payload.instagram,
              twitter: payload.twitter,
              soundcloud: payload.soundcloud,
              spotify: payload.spotify,
              email: payload.email,
              website: payload.website,
              tiktok: payload.tiktok,
              snapchat: payload.snapchat,
              appleMusic: payload.appleMusic,
              clubhouse: payload.clubhouse,
              creatorCategory: payload.creatorCategory,
              profileTemplate: payload.profileTemplate,
              salesFee: payload.salesFee,
              royaltyFee: payload.royaltyFee,
              fanclubLogoUrl: payload.fanclubLogoUrl
            }
          },
          {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }
        );
        history.push('/creators');
      } catch (error) {
        const errorMessage = error.errors[0]?.message;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [user, history] /* eslint-disable-line */
  );

  return {
    loading,
    data,
    nextPageToken,
    onCreate,
    getData
  };
};

export const useAccountStatsCounter = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const { user } = useAuth();

  const fetchData = async () => {
    setLoading(true);
    try {
      if (user?.accountID) {
        const params = {
          id: user?.accountID
        };
        const { data: res } = await gql(getAccountStatistic, params);
        setData(res.getAccountStatistic);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading,
    data
  };
};

export const useUserManageCreator = (creatorID) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const { user } = useAuth();

  const fetchData = async () => {
    setLoading(true);
    try {
      if (user?.accountID && creatorID) {
        const params = {
          accountID: user.accountID,
          managerID: { eq: user.id },
          filter: { profileID: { eq: creatorID } }
        };
        const { data: res } = await gql(profileManagerByAccountAndManager, params);
        if (res?.profileManagerByAccountAndManager?.items?.length) {
          setData(res?.profileManagerByAccountAndManager?.items);
        } else {
          setData(false);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [creatorID, user]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading,
    data
  };
};

export const useOrganizationAccountByUser = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const { user } = useAuth();

  const fetchData = async () => {
    setLoading(true);
    try {
      if (user?.id) {
        const params = {
          userID: user?.id
        };
        const { data: res } = await gql(accountUserByUser, params);
        if (res?.accountUserByUser?.items) {
          setData(res?.accountUserByUser?.items);
        } else {
          setData([]);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading,
    data
  };
};

export const useSearchOrganization = (id) => {
  const toast = useToast();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const [membersLoading, setMembersLoading] = useState(false);
  const [nextTokenMembers, setNextTokenMembers] = useState(null);

  const fetchData = async (id, nextTokenMembers, searchMember) => {
    setMembersLoading(true);
    if (!nextTokenMembers) {
      setLoading(true);
    }

    try {
      const params = {
        filter: {
          id: { eq: id }
        },
        limitMembers: 10
      };

      if (nextTokenMembers) {
        params.nextTokenMembers = nextTokenMembers;
      }

      if (searchMember) {
        params.filterMembers = {
          or: [
            { firstName: { contains: searchMember } },
            { lastName: { contains: searchMember } },
            { email: { contains: searchMember } }
          ]
        };
      }

      const { data: res } = await gql(searchAccounts, params);
      const detail = res?.searchAccounts?.items?.[0];

      setData(detail);
      setMembers(detail?.members?.items);
      setNextTokenMembers(detail?.members?.nextToken);
    } catch (error) {
      const errorMessage = error?.errors?.[0]?.message;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setMembersLoading(false);
      if (!nextTokenMembers) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (id) fetchData(id);
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    data,
    members,
    nextTokenMembers,
    membersLoading,
    loading,
    searchMember: (value) => fetchData(id, nextTokenMembers, value)
  };
};
