import { useEffect, useState } from 'react';
import { Button, Row, Col } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

const Pagination = ({ nextToken, fetchData, loading, reset }) => {
  const [tokens, setTokens] = useState([]);
  const [index, setIndex] = useState(0);
  const onNext = () => {
    if (index < tokens.length) {
      setIndex(index + 1);
      fetchData(tokens[index]);
    } else {
      setTokens([...tokens, nextToken]);
      setIndex(index + 1);
      fetchData(nextToken);
    }
  };
  const onPrev = () => {
    fetchData(tokens[index - 2]);
    setIndex(index - 1);
  };
  useEffect(() => {
    setTokens([]);
    setIndex(0)
  }, [reset]); // eslint-disable-line
  return (
    <Row className="pagination">
      <Col className="pagination__button">
        <Button
          className="pagination__button__prev"
          disabled={index === 0 || loading}
          onClick={onPrev}
        >
          <LeftOutlined />
        </Button>
        <Button
          className="pagination__button__next"
          disabled={!nextToken || loading}
          onClick={onNext}
        >
          <RightOutlined />
        </Button>
      </Col>
    </Row>
  );
};

export default Pagination;
