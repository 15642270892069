import { Helmet } from 'react-helmet';
import { Button, Col, Row, Typography } from 'antd';
import { DashboardContent, Link } from '@components';
import GenerateCollectible from '@pages/dummy/generate-collectible';

function Dummy() {
  const { Text } = Typography;

  return (
    <>
      <DashboardContent title="Dummy Admin Pages">
        <div style={{ marginBottom: '2rem' }}>
          <Text>This is a dummy admin pages</Text>
        </div>
        <Row gutter={[40, 40]}>
          <Col>
            <Button type="primary">
              <Link to="/dummy/create-organization">Create Organization</Link>
            </Button>
          </Col>
          <Col>
            <Button type="primary">
              <Link to="/dummy/approve-collectible">Approve Collectible</Link>
            </Button>
          </Col>
          <Col>
            <Button type="primary">
              <Link to="/dummy/delete-collectible">Delete Collectible</Link>
            </Button>
          </Col>
          <Col>
            <Button type="primary">
              <Link to="/dummy/delete-user">Delete User</Link>
            </Button>
          </Col>
          <Col>
            <GenerateCollectible />
          </Col>
          <Col>
            <Button type="primary">
              <Link to="/dummy/upsert-collectible">Bulk Upsert Collectible</Link>
            </Button>
          </Col>
          <Col>
            <Button type="primary">
              <Link to="/dummy/upsert-creator">Bulk Upsert Creator</Link>
            </Button>
          </Col>
        </Row>
      </DashboardContent>
      <Helmet>
        <title>Dummy Admin Pages</title>
      </Helmet>
    </>
  );
}

export default Dummy;
