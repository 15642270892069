export const userByAccountAndVerified = /* GraphQL */ `
  query UserByAccountAndVerified(
    $accountID: ID
    $verified: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByAccountAndVerified(
      accountID: $accountID
      verified: $verified
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
        }
        tags
        verified
        fanProfile
        emailVerified
        joinDate
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          bio
          tags
          verified
          fanProfile
          emailVerified
          joinDate
          createdAt
          updatedAt
          media {
            avatarUrl
          }
        }
        managers {
          items {
            id
            manager {
              firstName
              lastName
              username
              media {
                avatarUrl
              }
            }
          }
          nextToken
          startedAt
        }
        profileManaged {
          items {
            profile {
              firstName
              lastName
              username
              media {
                avatarUrl
              }
            }
          }
          nextToken
          startedAt
        }
        __typename
      }
      nextToken
      startedAt
    }
  }
`;

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      owner
      accountID
      editors
      email
      username
      creatorCategory
      firstName
      lastName
      displayName
      bio
      profileTemplate
      fee {
        salesFee
        royaltyFee
      }
      links {
        facebook
        youtube
        instagram
        twitter
        spotify
        soundcloud
        email
        website
        tiktok
        snapchat
        appleMusic
        clubhouse
      }
      discourseData {
        userID
        username
        channels
        category
      }
      tags
      verified
      fanProfile
      emailVerified
      joinDate
      createdAt
      updatedAt
      media {
        id
        avatarUrl
        avatarVideoUrl
        coverUrl
        fanclubLogoUrl
        createdAt
        updatedAt
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        emailVerified
        joinDate
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          coverUrl
          avatarVideoUrl
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          bio
          tags
          verified
          fanProfile
          emailVerified
          joinDate
          createdAt
          updatedAt
        }
      }
      managers {
        items {
          id
          profileID
          managerID
          accountID
          manager {
            firstName
            lastName
            media {
              avatarUrl
            }
          }
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      profileManaged {
        items {
          id
          profileID
          managerID
          accountID
          manager {
            firstName
            lastName
            media {
              avatarUrl
            }
          }
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;

export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: SearchableUserSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUsers(filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken, from: $from) {
      items {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        status
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      nextToken
      total
    }
  }
`;
