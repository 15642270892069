import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Form, Input } from 'antd';

import { Link } from '@components';

import { useUser } from '@libs/hooks/user';

function AuthForgot() {
  const { t } = useTranslation();

  const { forgotPass, loading: formLoading, error } = useUser();

  const onFinish = (values) => {
    forgotPass(values.email);
  };

  return (
    <>
      <div className="auth-forgot">
        <div className="auth-forgot__wrapper">
          <div className="auth-forgot__title">{t('auth.forgot.title')}</div>
          <Form name="forgot" onFinish={onFinish}>
            {error && (
              <Form.Item>{error && <Alert message={error} type="error" showIcon />}</Form.Item>
            )}
            <Form.Item
              name="email"
              rules={[
                { type: 'email', message: t('auth.fields.email.type') },
                { required: true, message: t('auth.fields.email.required') }
              ]}
            >
              <Input placeholder={t('auth.fields.email.placeholder')} />
            </Form.Item>
            <div className="auth-forgot__button">
              <Button type="primary" block loading={formLoading} htmlType="submit">
                {t('auth.buttons.recover')}
              </Button>
            </div>
          </Form>
          <div className="auth-box__link" style={{ textAlign: 'center' }}>
            <Link to="/login">{t('auth.forgot.back')}</Link>
          </div>
        </div>
      </div>
      <Helmet>
        <title>{t('auth.forgot.title')}</title>
      </Helmet>
    </>
  );
}

export default AuthForgot;
