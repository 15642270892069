import debounce from 'lodash.debounce';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import config from '@/config';
import gql from '@libs/utils/gql';
import { useAuth } from '@libs/contexts/auth';
import useToast from '@libs/utils/toast';
import {
  userByUsername,
  userByVerifiedJoinDate,
  getUserMedia,
  userByEmail,
  listUsers
} from '@graphql/queries';
import {
  updateUsername,
  updateEmail,
  verifyEmail,
  updateUserMedia,
  removeData,
  updateAccountUser,
  updateStatusWaitlist
} from '@graphql/mutations';

import { searchUsers } from '@libs/custom-queries/user';
import { searchEmailWaitlists } from '@libs/custom-queries/waitlist';

// @todo: move this hook handlers to contexts/auth.js
export const useUser = () => {
  const toast = useToast();
  const history = useHistory();
  const { user } = useAuth();
  const { t } = useTranslation();
  const [cookies, setCookie, removeCookie] = useCookies([config.cookie.USER_ACTIVATION]);

  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [usernameAvailabilityLoading, setUsernameAvailabilityLoading] = useState(false);
  const [usernameAvailability, setUsernameAvailability] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [avatarLoading, setAvatarLoading] = useState(false);
  const [coverLoading, setCoverLoading] = useState(false);

  const signUp = async ({ firstName, lastName, password, email, captchaToken }) => {
    setLoading(true);
    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          given_name: firstName,
          family_name: lastName,
          'custom:user_role': 'Creator',
          'custom:captcha_token': captchaToken
        }
      });

      const invID = window.localStorage.getItem(config.storage.INVITATION_ID);
      const redirecTo = invID ? `/accept-invitation/${invID}` : '/login';
      history.push(redirecTo);
    } catch (error) {
      console.error('error signing up:', error);
      toast(error.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const changePass = async ({ oldPassword, newPassword }) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword);

      history.push('/profile/password');
    } catch (error) {
      console.error('error change pass:', error);
      toast(error.message, 'error');
    }
  };

  const forgotPass = async (email) => {
    setLoading(true);
    try {
      await Auth.forgotPassword(email);

      if (cookies[config.cookie.FORGOT_PASS]) {
        removeCookie(config.cookie.FORGOT_PASS);
      }

      setCookie(config.cookie.FORGOT_PASS, email);
      history.push('/reset-password');
    } catch (error) {
      console.error('error forgot pass:', error);
      toast(error.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const resendForgotPass = useCallback(async () => {
    try {
      await Auth.forgotPassword(cookies[config.cookie.FORGOT_PASS]);
      toast(t('auth.reset.verificationSent'), 'success');
    } catch (error) {
      console.error('error resending code: ', error);
      toast(error.message, 'error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies]);

  const resetPass = useCallback(
    async ({ code, newPassword }) => {
      setLoading(true);
      try {
        await Auth.forgotPasswordSubmit(cookies[config.cookie.FORGOT_PASS], code, newPassword);
        removeCookie(config.cookie.FORGOT_PASS);
        toast(t('auth.reset.success'), 'success');
        setTimeout(() => {
          history.push('/login?passwordChanged=1');
        }, 500);
      } catch (error) {
        console.error('error reset pass:', error);
        toast(error.message, 'error');
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cookies]
  );

  const onUpdate = useCallback(
    async ({ firstName, lastName, bio, links }) => {
      setUpdateLoading(true);
      try {
        const payload = {
          input: {
            firstName,
            lastName,
            bio,
            links: {
              facebook: links.facebook,
              instagram: links.instagram,
              twitter: links.twitter,
              email: links.email,
              website: links.website
            },
            address: {
              line1: user?.address?.line1,
              country: user?.address?.country,
              stateName: user?.address?.stateName,
              city: user?.address?.city,
              postCode: user?.address?.postCode,
              phone: user?.address?.phone,
              recipientName: user?.address?.recipientName
            },
            shippingAddress: {
              line1: user?.shippingAddress?.line1,
              country: user?.shippingAddress?.country,
              stateName: user?.shippingAddress?.stateName,
              city: user?.shippingAddress?.city,
              postCode: user?.shippingAddress?.postCode,
              phone: user?.shippingAddress?.phone,
              recipientName: user?.shippingAddress?.recipientName
            }
          }
        };

        await gql(updateAccountUser, payload, {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });

        toast(t('userSettings.editUser.success'), 'success');
      } catch (error) {
        console.error('error updating user:', error);
        toast(error.message, 'error');
      } finally {
        setUpdateLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  const onUpdateUsername = useCallback(
    async (username) => {
      setUsernameAvailabilityLoading(true);
      try {
        const payload = {
          input: {
            username: username
          }
        };
        await gql(updateUsername, payload, {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
        toast(t('userSettings.editUsername.success'), 'success');
      } catch (error) {
        toast(error.message, 'error');
        console.error('error update user:', error);
      } finally {
        setUsernameAvailabilityLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  const onCheckUsername = async (username) => {
    if (!username) {
      return;
    }

    setUsernameAvailabilityLoading(true);
    try {
      const { data: res } = await gql(userByUsername, { username });
      const isAvailable = !!res?.userByUsername?.items?.length;
      setUsernameAvailability(isAvailable);
    } catch (err) {
      console.error(err);
    } finally {
      setUsernameAvailabilityLoading(false);
    }
  };

  const isUsernameAvailable = async (username) => {
    if (!username) {
      return;
    }

    try {
      const { data: res } = await gql(userByUsername, { username });
      const isExists = !!res?.userByUsername?.items?.length;
      return !isExists;
    } catch (err) {
      return false;
    }
  };

  const onUpdateEmail = useCallback(
    async (email) => {
      setEmailLoading(true);
      const payload = { input: { email } };
      try {
        const { data: res } = await gql(updateEmail, payload, {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
        if (res) {
          toast(t('userSettings.editEmail.success'), 'success');
        } else {
          toast(t('userSettings.wentWrong'), 'error');
        }
      } catch (err) {
        toast(err.toString(), 'error');
        console.error(err);
      } finally {
        setEmailLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );
  const onVerifyEmail = useCallback(
    async (code) => {
      setEmailLoading(true);
      const payload = { input: { code } };
      try {
        const { data: res } = await gql(verifyEmail, payload, {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
        if (res) {
          toast(t('userSettings.editEmail.verified'), 'success');
        } else {
          toast(t('userSettings.wentWrong'), 'error');
        }
      } catch (err) {
        toast(err.toString(), 'error');
        console.error(err);
      } finally {
        setEmailLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  const onUpdateAvatar = useCallback(
    async (avatarUrl) => {
      setAvatarLoading(true);
      try {
        const { data: media } = await gql(getUserMedia, { id: user.id });

        const payload = {
          input: {
            id: user.id,
            avatarUrl,
            coverUrl: media?.getUserMedia?.coverUrl || ''
          }
        };

        await gql(updateUserMedia, payload, {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });

        toast(t('userSettings.editMedia.success'), 'success');
      } catch (error) {
        toast(error.message, 'error');

        console.error('error update user media:', error);
      } finally {
        setAvatarLoading(false);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  const onUpdateCover = useCallback(
    async (coverUrl) => {
      setCoverLoading(true);
      try {
        const { data: media } = await gql(getUserMedia, { id: user.id });

        const payload = {
          input: {
            id: user.id,
            coverUrl,
            avatarUrl: media?.getUserMedia?.avatarUrl || ''
          }
        };

        await gql(updateUserMedia, payload, {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });

        toast(t('userSettings.editMedia.success'), 'success');
      } catch (error) {
        toast(error.message, 'error');

        console.error('error update user media:', error);
      } finally {
        setCoverLoading(false);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  return {
    loading,
    updateLoading,
    usernameAvailabilityLoading,
    usernameAvailability,
    emailLoading,
    avatarLoading,
    coverLoading,
    signUp,
    changePass,
    forgotPass,
    resendForgotPass,
    resetPass,
    onUpdate,
    onUpdateUsername,
    onUpdateEmail,
    onVerifyEmail,
    onUpdateAvatar,
    onUpdateCover,
    onCheckUsername: debounce(onCheckUsername, 500),
    isUsernameAvailable
  };
};

export const useUserByUsername = (username) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        const { data: res } = await gql(userByUsername, { username });
        if (res?.userByUsername?.items?.length) {
          setData(res.userByUsername.items[0]);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }

    getData();
  }, [username]);

  return {
    data,
    loading
  };
};

export const useRelatedCreators = (username, limit = 6) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        const { data: res } = await gql(userByVerifiedJoinDate, {
          verified: 'TRUE',
          filter: {
            username: {
              ne: username
            }
          },
          sortDirection: 'DESC',
          limit
        });
        if (res?.userByVerifiedJoinDate?.items?.length) {
          setData(res.userByVerifiedJoinDate.items);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }

    getData();
  }, [username, limit]);

  return {
    data,
    loading
  };
};

export const useFanUser = (limit = 99) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    let nextToken = false;
    async function getData() {
      setLoading(true);
      try {
        let params = {
          verified: 'FALSE',
          sortDirection: 'DESC',
          limit
        };
        if (nextToken) {
          params.nextToken = nextToken;
        }

        const { data: res } = await gql(userByVerifiedJoinDate, params);

        if (res?.userByVerifiedJoinDate?.items?.length) {
          setData(data.concat(res.userByVerifiedJoinDate.items));
        }

        nextToken = res?.userByVerifiedJoinDate?.nextToken;
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }

    do {
      getData();
    } while (nextToken);
  }, [limit]); // eslint-disable-line

  return {
    data,
    loading
  };
};

export const useUserByEmail = (email) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const getData = async (email) => {
    setLoading(true);
    try {
      if (!email) {
        return;
      }

      const { data: res } = await gql(userByEmail, {
        email
      });
      if (res?.userByEmail?.items?.length) {
        setData(res.userByEmail.items);
      } else {
        setData([]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (email) getData(email);
  }, [email]);

  return {
    data,
    loading
  };
};

export const useCreatorUser = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const signUp = async ({
    firstName,
    lastName,
    password,
    email,
    organization_name,
    captchaToken,
    type
  }) => {
    setLoading(true);
    setSuccess(false);
    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          given_name: firstName,
          family_name: lastName,
          'custom:captcha_token': captchaToken,
          'custom:user_role': 'Creator',
          'custom:organization_name': organization_name,
          'custom:type': type
        }
      });

      setSuccess(true);
    } catch (error) {
      console.error('error signing up:', error);
      toast(error.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    signUp,
    success
  };
};

export const useAllUsers = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [data, setData] = useState();

  const getData = async () => {
    setLoading(true);
    try {
      const { data: res } = await gql(listUsers);
      if (res?.listUsers?.items?.length) {
        setData(res.listUsers.items);
      } else {
        setData([]);
      }
    } catch (error) {
      const errorMessage = error.errors[0]?.errorType;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []); // eslint-disable-line

  const onDelete = useCallback(
    async (payload) => {
      setDeleteLoading(true);
      try {
        await gql(
          removeData,
          {
            input: {
              id: payload.id,
              item: payload.item
            }
          },
          {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }
        );

        toast('User deleted succesfully', 'success');
      } catch (error) {
        const errorMessage = error.errors[0]?.errorType;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setDeleteLoading(false);
      }
    },
    [] // eslint-disable-line
  );

  return {
    data,
    loading,
    deleteLoading,
    onDelete
  };
};

export const useUsers = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [deactivateLoading, setDeactivateLoading] = useState(false);
  const [data, setData] = useState();
  const [nextPageToken, setNextPageToken] = useState();

  const getData = async (token, values) => {
    setLoading(true);
    const params = {
      limit: 10
    };
    if (values) {
      params.filter = {
        or: [
          { firstName: { matchPhrasePrefix: values } },
          { lastName: { matchPhrasePrefix: values } },
          { email: { matchPhrasePrefix: values } }
        ]
      };
    }
    if (token) {
      params.nextToken = token;
    }
    try {
      const { data: result } = await gql(searchUsers, params);
      if (result?.searchUsers?.items) {
        setData(result?.searchUsers?.items);
      }
      if (result?.searchUsers?.items.length < 10) {
        setNextPageToken();
      } else {
        setNextPageToken(result?.searchUsers?.nextToken);
      }
    } catch (error) {
      const errorMessage = error.errors[0]?.errorType;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onDeactivate = useCallback(
    async (id, cb) => {
      setDeactivateLoading(true);
      try {
        await gql(
          removeData,
          {
            input: {
              id: id,
              item: 'deactivate_user'
            }
          },
          {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }
        );

        toast('User deactivate succesfully', 'success');
      } catch (error) {
        const errorMessage = error.errors[0]?.errorType;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setDeactivateLoading(false);
        cb && cb();
        setTimeout(() => getData(), 1000);
      }
    },
    [] // eslint-disable-line
  );

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line

  return {
    data,
    loading,
    deactivateLoading,
    nextPageToken,
    getData,
    onDeactivate
  };
};

export const useWaitlist = () => {
  const toast = useToast();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [nextPageToken, setNextPageToken] = useState(false);

  const getData = async (token, filter) => {
    try {
      setLoading(true);
      const params = {
        filter: { status: { eq: 'NOT_ACTIVE' } },
        sort: { field: 'verifiedAt', direction: 'desc' },
        limit: 12
      };
      if (filter) {
        params.filter = { ...params.filter, email: { matchPhrasePrefix: filter } };
      }
      if (token) {
        params.nextToken = token;
      }
      const { data: res } = await gql(searchEmailWaitlists, params);
      if (res?.searchEmailWaitlists?.items?.length) {
        setData(res?.searchEmailWaitlists?.items);
        setNextPageToken(res?.searchEmailWaitlists?.nextToken);
      } else {
        setNextPageToken();
        setData([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateWaitlist = async (payload) => {
    setUpdateLoading(true);
    try {
      await gql(
        updateStatusWaitlist,
        {
          input: {
            waitlists: payload
          }
        },
        {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }
      );
      toast('Users successfully activated', 'success');
    } catch (error) {
      console.error(error);
    } finally {
      setUpdateLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return {
    data,
    loading,
    nextPageToken,
    updateLoading,
    getData,
    updateWaitlist
  };
};
