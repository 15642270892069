import { useEffect, useState } from 'react';
import { useProfileOrganisation } from '@libs/hooks/organisation';

export const useExchangeParams = (limit, exchangeType) => {
  const { data: profiles } = useProfileOrganisation(true);
  const now = new Date().toISOString();
  const [query, setQuery] = useState({
    limit: limit,
    sort: { direction: 'desc', field: 'createdAt' },
    filter: {
      searchableIsSold: { eq: 'FALSE' },
      expiryDate: { gt: now },
      searchableExchangeType: { eq: exchangeType },
      or: profiles.map((c) => ({ creatorID: { eq: c.value }, currentOwnerID: { eq: c.value } }))
    }
  });

  const onChangeCollectible = (collectibleID) => {
    if (collectibleID) {
      const filter = {
        ...query.filter,
        collectibleID: { eq: collectibleID }
      };
      setQuery({ ...query, filter, nextToken: undefined });
    } else {
      setQuery({
        sort: { direction: 'desc', field: 'createdAt' },
        limit: limit,
        filter: {
          searchableIsSold: { eq: 'FALSE' },
          expiryDate: { gt: now },
          searchableExchangeType: { eq: exchangeType },
          or: profiles.map((c) => ({ creatorID: { eq: c.value }, currentOwnerID: { eq: c.value } }))
        }
      });
    }
  };

  const onPaginate = (nextToken) => {
    setQuery({ ...query, nextToken });
  };

  useEffect(() => {
    if (profiles) {
      setQuery({
        sort: { direction: 'desc', field: 'createdAt' },
        limit: limit,
        filter: {
          searchableIsSold: { eq: 'FALSE' },
          expiryDate: { gt: now },
          searchableExchangeType: { eq: exchangeType },
          or: profiles.map((c) => ({ creatorID: { eq: c.value }, currentOwnerID: { eq: c.value } }))
        }
      });
    }
  }, [profiles]); // eslint-disable-line

  return {
    query,
    onChangeCollectible,
    onPaginate
  };
};
