import { Redirect, Route } from 'react-router-dom';

import { useAuth } from '@libs/contexts/auth';

const GuestOnlyRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthenticated ? (
          <Redirect to={{ pathname: '/collectibles' }} />
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
};

export default GuestOnlyRoute;
