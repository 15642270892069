import { roundPrice } from './price';
export const TAX_PERCENTAGE = { AUD: 0.1, USD: 0.1 };

const calculateTax = (amount, currencyCode = 'USD') => {
  const taxPercentage = TAX_PERCENTAGE[currencyCode]
    ? TAX_PERCENTAGE[currencyCode]
    : TAX_PERCENTAGE['USD'];
  const parsedAmount = parseFloat(amount);
  const amountBeforeTax = roundPrice(parsedAmount / (1 + taxPercentage));
  const taxOnAmount = roundPrice(parsedAmount - amountBeforeTax);

  return {
    tax: taxOnAmount,
    beforeTax: amountBeforeTax,
    afterTax: parsedAmount,
    percentage: taxPercentage * 100
  };
};

export default calculateTax;
