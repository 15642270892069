import FansincPost from './fansinc';
import PatronsPost from './patrons';

function Post({
  user,
  postId,
  setDetailPost,
  isDetail,
  post,
  fetchPosts,
  setLoadingPosts,
  fetchFeaturedPost,
  featured
}) {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return (
      <PatronsPost
        user={user}
        postId={postId}
        setDetailPost={setDetailPost}
        isDetail={isDetail}
        post={post}
        fetchPosts={fetchPosts}
        setLoadingPosts={setLoadingPosts}
        fetchFeaturedPost={fetchFeaturedPost}
        featured={featured}
      />
    );
  } else {
    return (
      <FansincPost
        user={user}
        postId={postId}
        setDetailPost={setDetailPost}
        isDetail={isDetail}
        post={post}
        fetchPosts={fetchPosts}
        setLoadingPosts={setLoadingPosts}
        fetchFeaturedPost={fetchFeaturedPost}
        featured={featured}
      />
    );
  }
}

export default Post;
