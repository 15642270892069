import { useCookies } from 'react-cookie';

import config from '@/config';

export const useTokenPrefix = () => {
  const [cookies, setCookie, removeCookie] = useCookies([config.cookie.USER_ACTIVATION]);
  const tokenPrefix = cookies[config.cookie.TOKEN_PREFIX] || null;

  const setTokenPrefix = (keyPrefix, username) => {
    setCookie(config.cookie.TOKEN_PREFIX, `${keyPrefix}.${username}.accessToken`);
  };

  const removeTokenPrefix = () => {
    removeCookie(config.cookie.TOKEN_PREFIX);
  };

  return {
    tokenPrefix,
    setTokenPrefix,
    removeTokenPrefix
  };
};
