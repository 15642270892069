import { Helmet } from 'react-helmet';
import { Button, Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { DashboardContent } from '@components';

import { useAccount } from '@libs/hooks/account';
import { useFanUser } from '@libs/hooks/user';

function DummyCreateOrganization() {
  const { t } = useTranslation();
  const { data: managers, loading: managersLoading } = useFanUser();
  const { onCreate, createLoading } = useAccount();

  const { Option } = Select;
  const { TextArea } = Input;

  const onSubmit = (data) => {
    onCreate(data);
  };

  return (
    <>
      <DashboardContent title={t('organizations.title.add')} backLink="/admin/organization">
        <Form onFinish={onSubmit} layout="vertical" className="admin-form">
          <Form.Item
            label={t('organizations.form.owner')}
            name="userID"
            rules={[{ required: true, message: t('organizations.form.required.owner') }]}
          >
            <Select
              placeholder={t('organizations.form.placeholder.owner')}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              loading={managersLoading}
            >
              {managers &&
                managers.map((manager) => (
                  <Option key={`manager-${manager.id}`} value={manager.id}>
                    {`${manager.firstName} ${manager.lastName}`}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={t('organizations.form.team')}
            name="name"
            rules={[{ required: true, message: t('organizations.form.required.team') }]}
          >
            <Input placeholder={t('organizations.form.placeholder.team')} />
          </Form.Item>
          <Form.Item
            label={t('organizations.form.legal')}
            name="legalName"
            rules={[{ required: true, message: t('organizations.form.required.legal') }]}
          >
            <Input placeholder={t('organizations.form.placeholder.legal')} />
          </Form.Item>
          <Form.Item
            label={t('organizations.form.address')}
            name="address"
            rules={[{ required: true, message: t('organizations.form.required.address') }]}
          >
            <TextArea rows={4} placeholder={t('organizations.form.placeholder.address')} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={createLoading}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </DashboardContent>
      <Helmet>
        <title>Create Organization</title>
      </Helmet>
    </>
  );
}

export default DummyCreateOrganization;
