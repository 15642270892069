import React from 'react';
import cx from 'classnames';
import { Player } from '@components';
import { useOutsider } from '@libs/utils/outsider';

function VideoModal({ url, show, onClose }) {
    const handleCloseModal = () => onClose();
    const { ref: refModal } = useOutsider(handleCloseModal);
    const defaultStyle = {
        'video-modal': true,
        'video-modal-show': show
    };

    return (
        <div className={cx(defaultStyle)}>
            <div className="video-modal__wrapper">
                <div className="video-modal__wrapper__content" ref={refModal}>
                    <Player
                        src={url}
                        play={show}
                        className="video-modal__image"
                    />
                </div>
            </div>
        </div>
    );
}

VideoModal.defaultProps = {
    onClose: () => { },
    show: false
};

export default VideoModal;