import CreatorArtist from './artist';
import CreatorGallery from './gallery';
import { useAuth } from '@libs/contexts/auth';

const Creators = () => {
  const { user } = useAuth();
  if (user?.type === 'Artist') {
    return <CreatorArtist />;
  } else {
    return <CreatorGallery />;
  }
};

export default Creators;
