import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Button, Col, Input, Row, Form, Modal, Skeleton } from 'antd';
import { CameraOutlined, DeleteOutlined } from '@ant-design/icons';

import { Cropper, DashboardContent, Image, FormGroup } from '@components';
import { useAuth } from '@libs/contexts/auth';
import { useUser } from '@libs/hooks/user';
import { useUploader, useImageResizer } from '@libs/utils/uploader';
import useToast from '@libs/utils/toast';

function UserSettings() {
  const { t } = useTranslation();
  const { TextArea } = Input;
  const [verifyForm] = Form.useForm();
  const { user, loading, getCurrentUser } = useAuth();
  const [emailEdit, setEmailEdit] = useState(false);
  const [avatarModal, setAvatarModal] = useState(false);
  const [coverModal, setCoverModal] = useState(false);
  const [isEmailUser, setIsEmailUser] = useState(true);
  const [usernameLength, setUsernameLength] = useState('0');
  const toast = useToast();

  const handleFinishUploadAvatar = (props) => {
    setAvatarModal(true);
  };
  const {
    inputRef: avatarRef,
    onClick: handleUploadAvatar,
    onUpload: onUploadAvatar,
    onDelete: onDeleteAvatar,
    result: avatarResult
  } = useUploader(handleFinishUploadAvatar);

  const { loading: imageResizeLoading, onResize } = useImageResizer();

  const handleFinishUploadCover = (props) => {
    setCoverModal(true);
  };
  const {
    inputRef: coverRef,
    onClick: handleUploadCover,
    onUpload: onUploadCover,
    onDelete: onDeleteCover,
    result: coverResult
  } = useUploader(handleFinishUploadCover);

  const {
    updateLoading,
    usernameAvailabilityLoading: checkingUsername,
    emailLoading,
    avatarLoading,
    coverLoading,
    onUpdate,
    isUsernameAvailable,
    onUpdateEmail,
    onVerifyEmail,
    onUpdateUsername,
    onUpdateAvatar,
    onUpdateCover
  } = useUser();
  const onSubmit = async (data) => {
    const payload = {
      ...data,
      links: {
        facebook: data.facebook,
        email: data.emailSocialLink,
        instagram: data.instagram,
        twitter: data.twitter,
        website: data.website
      }
    };
    await onUpdate(payload);
    await getCurrentUser();
  };
  const handleChangeUsername = async (data) => {
    onUpdateUsername(data.username);
  };
  const handleUpdateEmail = async (data) => {
    await onUpdateEmail(data.email);
    await getCurrentUser();
  };
  const handleEmailEditOpen = () => {
    setEmailEdit(true);
  };
  const handleEmailEditClose = () => {
    setEmailEdit();
  };
  const handleVerifyEmail = async () => {
    verifyForm.validateFields().then(async (values) => {
      await verifyForm.resetFields();
      await setEmailEdit(false);
      await onVerifyEmail(values.code);
      await getCurrentUser();
      setIsEmailUser(true);
    });
  };

  const handleDeleteAvatar = async () => {
    const deleteRes = await onDeleteAvatar(user?.media?.avatarUrl);
    if (deleteRes) {
      await onUpdateAvatar('');
      await getCurrentUser();
    }
  };

  const handleDeleteCover = async () => {
    const deleteRes = await onDeleteCover(user?.media?.coverUrl);
    if (deleteRes) {
      await onUpdateCover('');
      await getCurrentUser();
    }
  };

  const checkEmail = (val) => {
    if (val === user?.email) {
      setIsEmailUser(true);
    } else {
      setIsEmailUser(false);
    }
  };

  return (
    <>
      <DashboardContent title={t('userSettings.title')}>
        <div className="user-settings">
          {loading || !user ? (
            <Skeleton active={true} />
          ) : (
            <>
              <Row
                className="add-user-images"
                gutter={[40, 40]}
                style={{ marginBottom: '2rem' }}
                align="middle"
              >
                <Col span={12} xl={4}>
                  <div className="image-preview-field showOverlay" style={{ paddingTop: '100%' }}>
                    {user?.media?.avatarUrl && (
                      <Image
                        size={200}
                        className="image-preview-field__image"
                        src={user?.media?.avatarUrl}
                        preview={{ visible: false }}
                      />
                    )}
                    <div className="image-preview-field__overlay">
                      <Button type="text" onClick={handleUploadAvatar}>
                        <CameraOutlined />
                      </Button>

                      {user?.media?.avatarUrl && (
                        <Button type="text" onClick={handleDeleteAvatar}>
                          <DeleteOutlined />
                        </Button>
                      )}

                      <input
                        type="file"
                        ref={avatarRef}
                        onChange={(e) => onUploadAvatar(e, 'avatar', toast)}
                        style={{ width: 0 }}
                      />
                    </div>
                    <Modal
                      className="image-preview-field__modal"
                      title={t('userSettings.fields.avatar.set.label')}
                      visible={avatarModal}
                      footer={null}
                      width={600}
                      onCancel={() => {
                        setAvatarModal(false);
                      }}
                    >
                      {avatarResult.fileUrl ? (
                        <Cropper
                          loading={imageResizeLoading || avatarLoading}
                          image={avatarResult.fileUrl}
                          onComplete={async (croppedArea) => {
                            const resized = await onResize({
                              key: avatarResult.s3?.key,
                              folder: 'avatars',
                              ...croppedArea
                            });

                            if (resized) {
                              await onUpdateAvatar(resized?.key);
                              await getCurrentUser();
                            }

                            avatarRef.current.value = '';
                            setAvatarModal(false);
                          }}
                          onCancel={() => {
                            setAvatarModal(false);
                            avatarRef.current.value = '';
                          }}
                        />
                      ) : null}
                    </Modal>
                  </div>
                  <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                    {t('userSettings.fields.avatar.label')}
                  </div>
                </Col>
                <Col span={24} xl={20}>
                  <div className="image-preview-field showOverlay" style={{ paddingTop: '10%' }}>
                    {user?.media?.coverUrl && (
                      <Image
                        size={1600}
                        className="image-preview-field__image"
                        src={user?.media?.coverUrl}
                        preview={{ visible: false }}
                      />
                    )}
                    <div className="image-preview-field__overlay">
                      <Button type="text" onClick={handleUploadCover}>
                        <CameraOutlined />
                      </Button>

                      {user?.media?.coverUrl && (
                        <Button type="text" onClick={handleDeleteCover}>
                          <DeleteOutlined />
                        </Button>
                      )}

                      <input
                        type="file"
                        ref={coverRef}
                        onChange={(e) => onUploadCover(e, '', toast)}
                        style={{ width: 0 }}
                      />
                    </div>
                    <Modal
                      className="image-preview-field__modal"
                      title={t('userSettings.fields.cover.set.label')}
                      visible={coverModal}
                      width={600}
                      footer={null}
                      onCancel={() => {
                        setCoverModal(false);
                      }}
                    >
                      {coverResult.fileUrl ? (
                        <Cropper
                          aspect={10 / 1}
                          loading={imageResizeLoading || coverLoading}
                          image={coverResult.fileUrl}
                          onComplete={async (croppedArea) => {
                            const resized = await onResize({
                              key: coverResult.s3?.key,
                              folder: 'covers',
                              ...croppedArea
                            });

                            if (resized) {
                              await onUpdateCover(resized?.key);
                              await getCurrentUser();
                            }

                            coverRef.current.value = '';
                            setCoverModal(false);
                          }}
                          onCancel={() => {
                            setCoverModal(false);
                            coverRef.current.value = '';
                          }}
                        />
                      ) : null}
                    </Modal>
                  </div>
                  <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                    {t('userSettings.fields.cover.label')}
                  </div>
                </Col>
              </Row>
              <FormGroup heading={t('userSettings.fields.group.content.about')}>
                <Form
                  layout="vertical"
                  onFinish={handleChangeUsername}
                  name="form-username"
                  initialValues={{ username: user.username }}
                  className="admin-form"
                >
                  <Row>
                    <Col span={15}>
                      <Form.Item
                        name="username"
                        label={t('userSettings.fields.username.label')}
                        extra={
                          process.env.REACT_APP_NAME === 'patrons'
                            ? t('userSettings.patrons.fields.username.extra')
                            : t('userSettings.fields.username.extra')
                        }
                        rules={[
                          { required: true, message: t('userSettings.fields.username.required') },
                          {
                            pattern: /^(?=[a-z0-9._]{0,999}$)(?!.*[_.]{2})[^_.].*[^_.]$/,
                            message: t('userSettings.fields.username.invalid')
                          },
                          {
                            min: 4,
                            message: t('userSettings.fields.username.minLength', {
                              length: usernameLength
                            })
                          },
                          {
                            max: 101,
                            message: t('userSettings.fields.username.maxLength', {
                              length: usernameLength
                            })
                          },
                          {
                            async validator(_, value) {
                              if (!value) {
                                return Promise.resolve();
                              }

                              const isAvailable = await isUsernameAvailable(value);

                              if (!isAvailable) {
                                return Promise.reject(
                                  new Error(
                                    t('userSettings.fields.username.taken', { username: value })
                                  )
                                );
                              }

                              return Promise.resolve();
                            }
                          }
                        ]}
                      >
                        <Input
                          defaultValue={user?.username}
                          onChange={(e) => {
                            setUsernameLength(e.target.value.length.toString());
                          }}
                          placeholder={t('userSettings.fields.username.placeholder')}
                        />
                      </Form.Item>
                    </Col>
                    <Col className="input-w-button" span={2}>
                      <Col span={2} className="toolbar__left">
                        <Form.Item>
                          <Button type="primary" loading={checkingUsername} htmlType="submit">
                            {t('userSettings.fields.username.update')}
                          </Button>
                        </Form.Item>
                      </Col>
                    </Col>
                  </Row>
                </Form>

                <Form
                  layout="vertical"
                  onFinish={handleUpdateEmail}
                  name="form-email"
                  initialValues={{ email: user.email }}
                  className="admin-form"
                >
                  <Row>
                    <Col span={15}>
                      <Form.Item
                        name="email"
                        label={t('userSettings.fields.email.label')}
                        rules={[
                          { required: true, message: t('userSettings.fields.email.required') },
                          {
                            pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                            message: t('userSettings.fields.email.invalid')
                          }
                        ]}
                      >
                        <Input
                          type="text"
                          defaultValue={user?.email}
                          size="small"
                          onChange={(e) => {
                            checkEmail(e.target.value);
                          }}
                          placeholder={t('userSettings.fields.email.placeholder')}
                          disabled={user?.emailVerified === 'FALSE'}
                        />
                      </Form.Item>
                    </Col>
                    <Col className="input-w-button" span={2}>
                      {user?.emailVerified === 'FALSE' ? (
                        <Col span={2} className="toolbar__left">
                          <Button
                            loading={emailLoading}
                            onClick={handleEmailEditOpen}
                            type="primary"
                          >
                            {t('userSettings.fields.email.verify')}
                          </Button>
                        </Col>
                      ) : (
                        <Col span={2} className="toolbar__left">
                          <Button
                            disabled={isEmailUser}
                            loading={emailLoading}
                            type="primary"
                            htmlType="submit"
                          >
                            {t('userSettings.fields.email.change')}
                          </Button>
                        </Col>
                      )}
                    </Col>
                  </Row>
                </Form>
                <Modal
                  className="modal-email"
                  title={t('userSettings.modal.verify.title')}
                  visible={emailEdit}
                  onOk={handleVerifyEmail}
                  onCancel={handleEmailEditClose}
                >
                  <Form
                    form={verifyForm}
                    layout="vertical"
                    onFinish={handleVerifyEmail}
                    name="form-email"
                  >
                    <Row>
                      <Col span={22}>
                        <Form.Item name="code" label={t('userSettings.fields.code.label')}>
                          <Input type="text" size="small" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Modal>
              </FormGroup>
              <Form
                layout="vertical"
                onFinish={onSubmit}
                name="form-settings"
                initialValues={{
                  firstName: user.firstName,
                  lastName: user.lastName,
                  email: user.email,
                  bio: user.bio,
                  facebook: user.links.facebook,
                  emailSocialLink: user.links.email,
                  instagram: user.links.instagram,
                  twitter: user.links.twitter,
                  website: user.links.website
                }}
                className="admin-form"
              >
                <Form.Item
                  name="firstName"
                  label={t('userSettings.fields.firstname.label')}
                  rules={[{ required: true, message: t('userSettings.fields.firstname.required') }]}
                >
                  <Input
                    type="text"
                    defaultValue={user?.firstName}
                    size="small"
                    placeholder={t('userSettings.fields.firstname.placeholder')}
                  />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  label={t('userSettings.fields.lastname.label')}
                  rules={[{ required: true, message: t('userSettings.fields.lastname.required') }]}
                >
                  <Input
                    defaultValue={user?.lastName}
                    type="text"
                    size="small"
                    placeholder={t('userSettings.fields.lastname.placeholder')}
                  />
                </Form.Item>
                <Form.Item name="bio" label={t('userSettings.fields.bio.label')}>
                  <TextArea
                    defaultValue={user?.bio}
                    rows={5}
                    type="text"
                    size="small"
                    placeholder={t('userSettings.fields.bio.placeholder')}
                  />
                </Form.Item>
                <FormGroup heading={t('userSettings.fields.group.content.sociallinks')}>
                  <Form.Item
                    name="website"
                    label={t('userSettings.fields.website.label')}
                    rules={[{ type: 'url', message: t('global.fields.url.invalid') }]}
                  >
                    <Input
                      defaultValue={user?.links?.website}
                      type="url"
                      size="small"
                      placeholder={t('userSettings.fields.website.placeholder')}
                    />
                  </Form.Item>
                  <Form.Item
                    name="emailSocialLink"
                    label={t('userSettings.fields.emailSocialLink.label')}
                    rules={[{ type: 'email', message: t('global.fields.email.invalid') }]}
                  >
                    <Input
                      defaultValue={user?.links?.email}
                      type="email"
                      size="small"
                      placeholder={t('userSettings.fields.emailSocialLink.placeholder')}
                    />
                  </Form.Item>
                  <Form.Item
                    name="facebook"
                    label={t('userSettings.fields.facebook.label')}
                    rules={[{ type: 'url', message: t('global.fields.url.invalid') }]}
                  >
                    <Input
                      defaultValue={user?.links?.facebook}
                      type="url"
                      size="small"
                      placeholder={t('userSettings.fields.facebook.placeholder')}
                    />
                  </Form.Item>
                  <Form.Item
                    name="instagram"
                    label={t('userSettings.fields.instagram.label')}
                    rules={[{ type: 'url', message: t('global.fields.url.invalid') }]}
                  >
                    <Input
                      defaultValue={user?.links?.instagram}
                      type="url"
                      size="small"
                      placeholder={t('userSettings.fields.instagram.placeholder')}
                    />
                  </Form.Item>
                  <Form.Item
                    name="twitter"
                    label={t('userSettings.fields.twitter.label')}
                    rules={[{ type: 'url', message: t('global.fields.url.invalid') }]}
                  >
                    <Input
                      defaultValue={user?.links?.twitter}
                      type="url"
                      size="small"
                      placeholder={t('userSettings.fields.twitter.placeholder')}
                    />
                  </Form.Item>
                </FormGroup>
                <Col span={24} className="toolbar__left">
                  <Button loading={updateLoading} type="primary" htmlType="submit">
                    {t('userSettings.buttons.save')}
                  </Button>
                </Col>
              </Form>
            </>
          )}
        </div>
      </DashboardContent>
      <Helmet>
        <title>{t('userSettings.title')}</title>
      </Helmet>
    </>
  );
}

export default UserSettings;
