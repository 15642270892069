import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Button, Typography, Row, Col } from 'antd';
import { useState } from 'react';
import { PlayCircleOutlined } from '@ant-design/icons';
import ImageSection1 from '@assets/img/img-section-1.png';
import ImageSection2 from '@assets/img/img-section-2.png';
import WebView from '@assets/img/webview.png';
import ToolbarSafari from '@assets/img/toolbar-safari.png';
import { Blur, Footer, Navigation, ModalRegister, ModalWaitinglist } from '@components';

function Landing() {
  const { t } = useTranslation();
  const { Title, Paragraph } = Typography;
  const [waitlistModal, setWaitlistModal] = useState(false);
  const [registerModal, setRegisterModal] = useState(false);

  return (
    <div className="landing">
      {/* HEADER  */}

      <header className="header">
        <Navigation />
        <div className="hero">
          <Title className="landing-h1">{t('landing.hero.title')}</Title>
          <Paragraph className="landing-paragraph">{t('landing.hero.description')}</Paragraph>
          <div className="hero__button">
            <Button
              className="landing__button hero__button__register"
              onClick={() => {
                setRegisterModal(true);
              }}
            >
              {t('landing.hero.button.registerNow')}
            </Button>
            <Button
              icon={<PlayCircleOutlined />}
              className="landing__button landing__button-outline"
            >
              {t('landing.hero.button.playVideo')}
            </Button>
          </div>
        </div>
        <ModalRegister onShow={registerModal} onClose={() => setRegisterModal(false)} />
      </header>
      {/* BROWSER  */}
      <section className="landing__section-browser">
        <div className="landing__section-browser__wrapper">
          <img src={ToolbarSafari} alt="" />
          <div className="landing__section-browser__scroller">
            <img src={WebView} alt="" srcset="" />
          </div>
        </div>
        <Blur className="landing__blur landing__blur-browser" />
      </section>
      <section className="landing__section-creator">
        <div className="container">
          <Row className="row-reverse" justify="space-around" align="middle">
            <Col span={24} md={14} className="landing__section-creator__image">
              <img src={ImageSection1} alt={t('landing.creatorPortal.title')} />
            </Col>
            <Col span={24} md={10}>
              <Title level={2} className="landing-h2">
                {t('landing.creatorPortal.title')}
              </Title>
              <Paragraph className="landing-paragraph">
                {t('landing.creatorPortal.description')}
              </Paragraph>
              <Button className="landing__button">{t('landing.creatorPortal.button')}</Button>
            </Col>
          </Row>
        </div>
        <Blur className="landing__blur landing__blur-creator" />
      </section>
      <section className="landing__section-platform">
        <div className="container">
          <Row justify="space-around" align="middle">
            <Col span={24} md={14} className="landing__section-platform__image">
              <img src={ImageSection2} alt={t('landing.platform.title')} />
            </Col>
            <Col span={24} md={10}>
              <Title level={2} className="landing-h2">
                {t('landing.platform.title')}
              </Title>
              <Paragraph className="landing-paragraph">
                {t('landing.platform.description')}
              </Paragraph>
              <Button className="landing__button">{t('landing.platform.button')}</Button>
            </Col>
          </Row>
        </div>
        <Blur className="landing__blur landing__blur-platform" />
      </section>
      <section className="landing__section-cta">
        <div className="landing__section-cta__wrapper">
          <Title level={2} className="landing-h2 landing-title-center">
            {t('landing.cta.title')}
          </Title>
          <Button
            className="landing__button"
            onClick={() => {
              setWaitlistModal(true);
            }}
          >
            {t('landing.cta.joinWaitlist')}
          </Button>
          <ModalWaitinglist onShow={waitlistModal} onClose={() => setWaitlistModal(false)} />
        </div>
      </section>
      <Footer showBlur />
      <Helmet>
        <title>{t('landing.title')}</title>
      </Helmet>
    </div>
  );
}

export default Landing;
