import { useTranslation } from 'react-i18next';
import { Button, Row, Col, Typography } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import { useEffect } from 'react';
import { Container } from '@components';
import Logo from '@assets/img/landing-logo.svg';
import PatronsLogo from '@assets/img/patrons-logo.png';

function Navigation({ auth }) {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const { Paragraph } = Typography;

  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });

  const isSticky = (e) => {
    if (!auth) {
      const header = document.querySelector('.navigation');
      const scrollTop = window.scrollY;
      if (scrollTop >= 200) {
        header.classList.remove('navigation-scroll');
        header.classList.add('navigation-fixed');
      } else {
        header.classList.remove('navigation-fixed');
        header.classList.add('navigation-scroll');
      }
    }
  };
  return (
    <div className="navigation">
      <Container>
        <Row justify="space-around" align="middle">
          <Col span={12} className="navigation__left">
            <Link to="/">
              {process.env.REACT_APP_NAME === 'patrons' ? (
                <img
                  className="navigation__logo"
                  src={PatronsLogo}
                  alt={t('components.navigation.title')}
                />
              ) : (
                <img
                  className="navigation__logo"
                  src={Logo}
                  alt={t('components.navigation.title')}
                />
              )}
            </Link>
          </Col>
          <Col span={12} className="navigation__right" align="middle">
            {path !== '/login' && path !== '/' ? (
              <div className="navigation__right__wrapper">
                <Paragraph className="navigation__text">
                  {process.env.REACT_APP_NAME === 'patrons'
                    ? t('components.navigation.patrons.alreadyHaveAnAccount')
                    : t('components.navigation.alreadyHaveAnAccount')}
                </Paragraph>
                <Link to="/login">
                  <Button className="landing__button landing__button-outline">
                    {t('components.navigation.login')}
                  </Button>
                </Link>
              </div>
            ) : null}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Navigation;
