export const listActivities = /* GraphQL */ `
  query ListActivities($filter: ModelActivityFilterInput, $limit: Int, $nextToken: String) {
    listActivities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        event
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const activityByTypeAndCreatedAt = /* GraphQL */ `
  query ActivityByTypeAndCreatedAt(
    $type: ActivityType
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activityByTypeAndCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        event
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
