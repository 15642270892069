import { useState, useEffect } from 'react';
import Amount from './amount';
import Verify from './verify';
import Success from './success';

const Index = ({ balance, visible, onCancel, creatorID, refetch }) => {
  const [modalState, setModalState] = useState({ withdrawalID: null, step: null });

  useEffect(() => {
    if (visible === true) setModalState({ ...modalState, step: 'amount' });

    // eslint-disable-next-line
  }, [visible]);

  const handleCancel = () => {
    setModalState({ ...modalState, step: null });
    onCancel();
  };

  return (
    <div>
      <Amount
        showModal={modalState.step === 'amount'}
        setModalState={setModalState}
        balance={balance}
        onCancel={() => handleCancel()}
        creatorID={creatorID}
      />
      <Verify
        showModal={modalState.step === 'verify'}
        setModalState={setModalState}
        balance={balance}
        onCancel={() => handleCancel()}
        withdrawalID={modalState.withdrawalID}
      />
      <Success
        showModal={modalState.step === 'success'}
        setModalState={setModalState}
        onCancel={() => handleCancel()}
        refetch={refetch}
      />
    </div>
  );
};

export default Index;
