import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Avatar as Avatars, Row, Col, Button, Collapse, List, Table, Select, Modal } from 'antd';

import { Avatar, DashboardContent, DisplayDate, Image, Pagination, Price } from '@components';
import { useExchangeByAccountAndProfiles } from '@libs/hooks/exchange/';
import { useCollectiblesByAccount } from '@libs/hooks/collectible';
import calculateTax from '@libs/utils/tax';
import { roundPrice } from '@libs/utils/price';

const tierType = {
  DIGITAL_ORIGINAL: 'Digital Original',
  NUMBERED_EDITION: 'Limited Edition'
};

const Offers = () => {
  const { t } = useTranslation();
  const {
    data,
    loading,
    onAcceptOffer,
    mutationLoading,
    fetch: fetchOffers,
    nextPageToken: nextOffersToken,
    onPaginate,
    handleChangeCollectible
  } = useExchangeByAccountAndProfiles();
  const { data: collectibles } = useCollectiblesByAccount();
  const [selectedOffer, setSelectedOffer] = useState();
  const [resetPagination, setResetPagination] = useState(false);
  const handleSearchCollectible = (value) => {
    handleChangeCollectible(value);
    setResetPagination(!resetPagination);
  };

  const columns = [
    {
      title: t('offers.list.collectible'),
      key: 'collectible',
      responsive: ['xs'],
      render: (_, row) => {
        return (
          <div className="mobile-row">
            <div className="mobile-row__info">
              <div className="mobile-row__info__name">{row?.collectible?.title}</div>
            </div>
            <Collapse collapsible ghost className="datalist">
              <Collapse.Panel header={t('offers.list.expand')} key="1">
                <List itemLayout="vertical">
                  <List.Item>
                    <List.Item.Meta
                      title={
                        process.env.REACT_APP_NAME === 'patrons'
                          ? t('offers.patrons.list.creator')
                          : t('offers.list.creator')
                      }
                      description={
                        <div className="avatar-name">
                          <Avatar
                            shape="square"
                            src={<Image size={200} src={row?.creator?.media?.avatarUrl} />}
                          />
                          {`${row?.creator?.firstName} ${row?.creator?.lastName}`}
                        </div>
                      }
                    />
                    <List.Item.Meta title={t('offers.list.tierType')} description={row.tierType} />
                    <List.Item.Meta
                      title={t('offers.list.expiration')}
                      description={<DisplayDate date={row.expiryDate} />}
                    />
                    <List.Item.Meta
                      title={t('offers.list.offerAmount')}
                      description={<Price amount={row.offerPrice} />}
                    />
                    <List.Item.Meta
                      title={t('offers.list.profile')}
                      description={
                        <div className="avatar-name">
                          <Avatar
                            shape="square"
                            src={<Image size={200} src={row?.patron?.media?.avatarUrl} />}
                          />
                          {`${row?.patron?.firstName} ${row?.patron?.lastName}`}
                        </div>
                      }
                    />
                    <List.Item.Meta
                      title={t('offers.list.createdAt')}
                      description={<DisplayDate date={row.createdAt} />}
                    />
                  </List.Item>
                </List>
              </Collapse.Panel>
            </Collapse>
          </div>
        );
      }
    },
    {
      title: t('offers.list.collectible'),
      key: 'collectible',
      responsive: ['sm'],
      render: (_, row) => row?.collectible?.title
    },
    {
      title:
        process.env.REACT_APP_NAME === 'patrons'
          ? t('offers.patrons.list.creator')
          : t('offers.list.creator'),
      dataIndex: 'creator',
      key: 'creator',
      responsive: ['sm'],
      render: (value) => (
        <>
          <Avatar user={value} />
          <span className="avatar-name">{`${value?.firstName} ${value?.lastName}`}</span>
        </>
      )
    },
    {
      title: t('offers.list.tierType'),
      key: 'tierType',
      responsive: ['md'],
      render: (_, row) => tierType[row?.tierType]
    },
    {
      title: t('offers.list.profile'),
      dataIndex: 'patron',
      key: 'patron',
      responsive: ['lg'],
      render: (value) => (
        <>
          <Avatar user={value} />
          <span className="avatar-name">{`${value?.firstName} ${value?.lastName}`}</span>
        </>
      )
    },
    {
      title: t('offers.list.offerAmount'),
      dataIndex: 'offerPrice',
      key: 'offerPrice',
      responsive: ['md'],
      render: (value) => <Price amount={value} />
    },
    {
      title: t('offers.list.authStatus'),
      dataIndex: 'paymentProvider',
      key: 'paymentProvider',
      responsive: ['md'],
      render: (value) => (value === 'BITPAY' ? t('offers.list.unauth') : t('offers.list.preauth'))
    },
    {
      title: t('offers.list.expiration'),
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      responsive: ['md'],
      render: (value) => <DisplayDate date={value} />
    },
    {
      title: t('offers.list.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      responsive: ['lg'],
      render: (value) => <DisplayDate date={value} />
    },
    {
      key: 'action',
      responsive: ['lg'],
      render: (_, row) => (
        <>
          <Button key="acceptOffer" type="primary" onClick={() => setSelectedOffer(row.id)}>
            {t('offers.list.acceptOffer')}
          </Button>
        </>
      )
    }
  ];

  const selectedExchange = data?.find((exc) => exc.id === selectedOffer);

  const fansFee = selectedExchange
    ? roundPrice(
        parseFloat(selectedExchange?.offerPrice) *
          (parseFloat(selectedExchange?.creator?.fee?.salesFee) / 100)
      )
    : 0;
  const vatAmount = selectedExchange
    ? calculateTax(selectedExchange?.offerPrice, selectedExchange?.parentExchange?.fiatCurrencyCode)
    : 0;
  const totalReceived = selectedExchange
    ? parseFloat(selectedExchange?.offerPrice) - fansFee - vatAmount.tax
    : 0;

  const onAccept = async (exchangeID) => {
    await onAcceptOffer(exchangeID);
    setSelectedOffer(null);
    await fetchOffers();
  };

  return (
    <>
      <DashboardContent title={t('offers.title')}>
        <div className="offers-toolbar">
          <Row className="toolbar">
            <Col sm={8} xs={24}>
              <div className="toolbar-search">
                <Select
                  showSearch
                  placeholder={t('offers.toolbar.filterByCollectible')}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) => handleSearchCollectible(value)}
                >
                  <Select.Option key={'all'} value="">
                    {t('offers.toolbar.allCollectibles')}
                  </Select.Option>
                  {collectibles?.map((x) => (
                    <Select.Option key={x.id} value={x.id}>
                      {x.title}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </Col>
          </Row>
        </div>
        <div className="offers">
          <div className="fans-table">
            <Table
              bordered
              loading={loading}
              dataSource={data}
              columns={columns}
              pagination={false}
            />
            <Pagination
              nextToken={nextOffersToken}
              loading={loading}
              reset={resetPagination}
              fetchData={onPaginate}
            />
          </div>
        </div>
        <Modal
          className="offers-modal"
          title={t('offers.modal.title')}
          visible={!!selectedOffer}
          onOk={() => onAccept(selectedOffer)}
          onCancel={() => setSelectedOffer(null)}
          okText={t('offers.modal.accept')}
          cancelText={t('offers.modal.cancel')}
          confirmLoading={mutationLoading}
          width={650}
        >
          <div className="offers-modal-container">
            <div>
              <Avatars
                size={232}
                shape="square"
                src={
                  <Image
                    size={600}
                    className="image-preview-field__image"
                    src={selectedExchange?.collectible?.mediaThumbnail}
                    preview={{ visible: false }}
                  />
                }
              />
            </div>
            <div>
              <h3 className="">{selectedExchange?.collectible?.title}</h3>
              <p>{`${tierType[selectedExchange?.tierType]} #${selectedExchange?.serialIndex}`}</p>
              <div className="user-row">
                <div className="user-row-item">
                  <Avatar size={36} user={selectedExchange?.creator} />
                  <p className="user-information">
                    <span className="title">{t('offers.modal.creator')}</span>
                    <br />
                    <span>{`${selectedExchange?.creator?.firstName} ${selectedExchange?.creator?.lastName}`}</span>
                  </p>
                </div>
                <div className="user-row-item">
                  <Avatar size={36} user={selectedExchange?.patron} />
                  <p className="user-information">
                    <span className="title">{t('offers.modal.buyer')}</span>
                    <br />
                    <span>{`${selectedExchange?.patron?.firstName} ${selectedExchange?.patron?.lastName}`}</span>
                  </p>
                </div>
              </div>
              <div className="offer-detail">
                <p>{t('offers.list.offerAmount')}</p>
                <p style={{ fontWeight: 600 }}>
                  <Price amount={selectedExchange?.offerPrice} />
                </p>
              </div>
              <div className="offer-detail">
                <p>{t('offers.modal.fee')}</p>
                <p style={{ fontWeight: 600 }}>
                  <Price amount={fansFee} />
                </p>
              </div>
              <div className="offer-detail">
                <p>{t('offers.modal.gstVat')}</p>
                <p style={{ fontWeight: 600 }}>
                  <Price amount={vatAmount.tax} />
                </p>
              </div>
              <div className="offer-detail">
                <p>{t('offers.modal.total')}</p>
                <p style={{ fontWeight: 600 }}>
                  <Price amount={totalReceived} />
                </p>
              </div>
            </div>
          </div>
        </Modal>
      </DashboardContent>
      <Helmet>
        <title>{t('offers.title')}</title>
      </Helmet>
    </>
  );
};

export default Offers;
