import { useCallback, useEffect, useRef } from 'react';

export const useOutsider = (cb) => {
  const ref = useRef();

  const onClick = useCallback((event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      cb();
    }
  }, [cb]);

  useEffect(() => {
    document.addEventListener('click', onClick, true);
    return () => {
      document.removeEventListener('click', onClick, true);
    };
  });

  return {
    ref
  };
};
