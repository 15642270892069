import { formatDistanceToNowStrict } from 'date-fns';
import { Trans, useTranslation } from 'react-i18next';
import config from '@/config';
import { Row, Col, Typography } from 'antd';
import { useUpdateReadNotification } from '@libs/hooks/notification';
import { Avatar, Link } from '@components';
import { reportList } from '@components/post/utils';

const NotificationMenuItem = ({ notification, className, updateData, ...props }) => {
  const notificationTime = notification?.createdAt
    ? formatDistanceToNowStrict(new Date(notification?.createdAt), { addSuffix: true })
    : '';
  const { t } = useTranslation();
  const unreadClass = notification.searchableRead === 'FALSE' ? 'unread' : '';
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });
  const event = notification?.event;
  const { Text } = Typography;
  let doer = {};
  let user = {};
  switch (notification?.type) {
    case 'USER_FOLLOWED_CREATOR':
      {
        const follower = event.followerUser;
        doer.id = follower.id;
        doer.fullName = follower.firstName + ' ' + follower.lastName;
        doer.username = follower.username;
        user.fullName = (follower.firstName + ' ' + follower.lastName).trim();
        user.media = {
          avatarUrl: follower.avatarUrl
        };
      }
      break;

    case 'COLLECTIBLE_LIKED':
      {
        const creator = event.followeeUser;
        const follower = event.followerUser;
        doer.id = follower.id;
        doer.fullName = follower.firstName + ' ' + follower.lastName;
        doer.username = follower.username;
        user.fullName = (creator.firstName + ' ' + creator.lastName).trim();
        user.media = {
          avatarUrl: creator.avatarUrl
        };
      }
      break;

    case 'COLLECTIBLE_OFFER_MADE':
    case 'COLLECTIBLE_BID_MADE':
      {
        const creator = event.creatorUser;
        const owner = event.ownerUser;
        doer.id = owner.id;
        doer.tier =
          event.exchange.serialIndex === 1
            ? '(Digital Original #1)'
            : '(Limited Edition #' + event.exchange.serialIndex + ')';
        doer.price = event.exchange.offerPrice
          ? currencyFormatter.format(Number(event.exchange.offerPrice))
          : currencyFormatter.format(Number(event.exchange.bidPrice));
        doer.fullName = (owner.firstName + ' ' + owner.lastName).trim();
        doer.username = owner.username;
        user.fullName = (creator.firstName + ' ' + creator.lastName).trim();
        user.media = {
          avatarUrl: creator.avatarUrl
        };
      }
      break;

    case 'COLLECTIBLE_OFFER_RECEIVED':
    case 'COLLECTIBLE_BID_RECEIVED':
      {
        const creator = event.creatorUser;
        const patron = event.patronUser;
        doer.id = patron.id;
        doer.tier =
          event.exchange.serialIndex === 1
            ? '(Digital Original #1)'
            : '(Limited Edition #' + event.exchange.serialIndex + ')';
        doer.price = event.exchange.offerPrice
          ? currencyFormatter.format(Number(event.exchange.offerPrice))
          : currencyFormatter.format(Number(event.exchange.bidPrice));
        doer.destination = event.exchange.offerPrice ? '/offers' : '/bids';
        doer.fullName = (patron.firstName + ' ' + patron.lastName).trim();
        doer.username = patron.username;
        user.fullName = (creator.firstName + ' ' + creator.lastName).trim();
        user.media = {
          avatarUrl: creator.avatarUrl
        };
      }
      break;

    case 'AUCTION_BID_SUBMITTED_CREATORSELLER':
      {
        const creator = event.creatorUser;
        const patron = event.patronUser;
        doer.id = patron.id;
        doer.tier =
          event.exchange.serialIndex === 1
            ? '(Digital Original #1)'
            : '(Limited Edition #' + event.exchange.serialIndex + ')';
        doer.price = event.exchange.offerPrice
          ? currencyFormatter.format(Number(event.exchange.offerPrice))
          : currencyFormatter.format(Number(event.exchange.bidPrice));
        doer.fullName = (patron.firstName + ' ' + patron.lastName).trim();
        doer.creatorName = (
          creator.displayName || creator.firstName + ' ' + creator.lastName
        ).trim();
        doer.username = patron.username;
        user.fullName = (patron.firstName + ' ' + patron.lastName).trim();
        user.media = {
          avatarUrl: patron.avatarUrl
        };
      }
      break;

    case 'AUCTION_WON_CREATOR':
      {
        const owner = event.ownerUser;
        doer.id = owner.id;
        doer.tier =
          event.exchange.serialIndex === 1
            ? '(Digital Original #1)'
            : '(Limited Edition #' + event.exchange.serialIndex + ')';
        doer.price = currencyFormatter.format(Number(event.exchange.sellingPrice));
        doer.fullName = (owner.firstName + ' ' + owner.lastName).trim();
        doer.username = owner.username;
        user.fullName = (
          event.creatorUser.displayName ||
          event.creatorUser.firstName + ' ' + event.creatorUser.lastName
        ).trim();
        user.media = {
          avatarUrl: event.collectible.mediaThumbnail
        };
      }
      break;

    case 'OFFER_SUBMITTED_CREATOROWNER':
      {
        const patron = event.patronUser;
        doer.id = patron.id;
        doer.tier =
          event.exchange.serialIndex === 1
            ? '(Digital Original #1)'
            : '(Limited Edition #' + event.exchange.serialIndex + ')';
        doer.price = event.exchange.offerPrice
          ? currencyFormatter.format(Number(event.exchange.offerPrice))
          : currencyFormatter.format(Number(event.exchange.bidPrice));
        doer.destination = event.exchange.offerPrice ? '/offers' : '/bids';
        doer.fullName = (patron.firstName + ' ' + patron.lastName).trim();
        doer.username = patron.username;
        user.fullName = (patron.firstName + ' ' + patron.lastName).trim();
        user.media = {
          avatarUrl: patron.avatarUrl
        };
      }
      break;

    case 'COLLECTIBLE_SOLD_CREATOROWNER':
      {
        const creator = event.creatorUser;
        const owner = event.ownerUser;
        doer.id = owner.id;
        doer.price = currencyFormatter.format(Number(event.exchange.sellingPrice));
        doer.fullName = (owner.firstName + ' ' + owner.lastName).trim();
        doer.username = owner.username;
        user.fullName = (creator.firstName + ' ' + creator.lastName).trim();
        user.media = {
          avatarUrl: event.collectible.mediaThumbnail
        };
      }
      break;

    case 'COLLECTIBLE_SOLD':
    case 'COLLECTIBLE_SOLD_CREATOR':
      {
        const creator = event.creatorUser;
        const owner = event.ownerUser;
        doer.id = owner.id;
        doer.fullName = (owner.firstName + ' ' + owner.lastName).trim();
        doer.username = owner.username;
        doer.tier =
          event.exchange.serialIndex === 1
            ? '(Digital Original #1)'
            : '(Limited Edition #' + event.exchange.serialIndex + ')';
        doer.price = currencyFormatter.format(Number(event.prevExchange.sellingPrice));
        doer.media = {
          avatarUrl: owner.avatarUrl
        };
        user.fullName = (creator.firstName + ' ' + creator.lastName).trim();
        user.media = {
          avatarUrl: creator.avatarUrl
        };
      }
      break;

    case 'COLLECTIBLE_OFFER_SOLD_CREATOR':
      {
        const creator = event.creatorUser;
        const owner = event.ownerUser;
        doer.id = owner.id;
        doer.fullName = (owner.firstName + ' ' + owner.lastName).trim();
        doer.username = owner.username;
        doer.tier =
          event.exchange.serialIndex === 1
            ? '(Digital Original #1)'
            : '(Limited Edition #' + event.exchange.serialIndex + ')';
        doer.price = currencyFormatter.format(Number(event.prevExchange.sellingPrice));
        user.fullName = (creator.firstName + ' ' + creator.lastName).trim();
        user.media = {
          avatarUrl: creator.avatarUrl
        };
      }
      break;

    case 'COLLECTIBLE_OFFER_ACCEPTED':
      {
        const creator = event.creatorUser;
        const owner = event.ownerUser;
        doer.id = owner.id;
        doer.fullName = (owner.firstName + ' ' + owner.lastName).trim();
        doer.username = owner.username;
        doer.tier =
          event.exchange.serialIndex === 1
            ? '(Digital Original #1)'
            : '(Limited Edition #' + event.exchange.serialIndex + ')';
        doer.price = currencyFormatter.format(Number(event.previousExchange.sellingPrice));
        user.fullName = (creator.firstName + ' ' + creator.lastName).trim();
        user.media = {
          avatarUrl: creator.avatarUrl
        };
      }
      break;

    case 'COLLECTIBLE_SUBMITTED_ADMIN':
      {
        const creator = event.creatorProfile;
        doer.id = creator.id;
        doer.fullName = (creator.displayName || creator.firstName + ' ' + creator.lastName).trim();
        doer.username = creator.username;
        user.fullName = (creator.displayName || creator.firstName + ' ' + creator.lastName).trim();
        user.media = {
          avatarUrl: event.collectible.mediaThumbnail
        };
      }
      break;

    case 'COLLECTIBLE_PUBLISHED':
      {
        const creator = event.creatorProfile;
        user.fullName = (creator.firstName + ' ' + creator.lastName).trim();
        user.media = {
          avatarUrl: creator.avatarUrl
        };
      }
      break;

    case 'COLLECTIBLE_APPROVED_CREATOR':
      {
        const creator = event.creatorProfile;
        const collectible = event.collectible;
        user.fullName = (creator.firstName + ' ' + creator.lastName).trim();
        user.media = {
          avatarUrl: collectible.mediaThumbnail
        };
      }
      break;

    case 'COLLECTIBLE_REVISION_REQUESTED':
      {
        const creator = event.creatorUser;
        doer.id = creator.id;
        doer.fullName = (creator.displayName || creator.firstName + ' ' + creator.lastName).trim();
        doer.username = creator.username;
        user.fullName = (creator.displayName || creator.firstName + ' ' + creator.lastName).trim();
        user.media = {
          avatarUrl: creator.avatarUrl
        };
      }
      break;

    case 'OFFER_WITHDRAWN_SELLER':
      {
        const patron = event.patronUser;
        doer.id = patron.id;
        doer.destination = '/offers';
        doer.tier =
          event.exchange.serialIndex === 1
            ? '(Digital Original #1)'
            : '(Limited Edition #' + event.exchange.serialIndex + ')';
        doer.fullName = (patron.firstName + ' ' + patron.lastName).trim();
        user.fullName = (patron.firstName + ' ' + patron.lastName).trim();
        user.media = {
          avatarUrl: patron.avatarUrl
        };
      }
      break;

    case 'OFFER_PAYMENT_REQUESTED_SELLER_BITPAY':
      {
        const creator = event.creatorUser;
        const owner = event.ownerUser;
        doer.id = owner.id;
        doer.fullName = (owner.firstName + ' ' + owner.lastName).trim();
        doer.username = owner.username;
        doer.tier =
          event.exchange.serialIndex === 1
            ? '(Digital Original #1)'
            : '(Limited Edition #' + event.exchange.serialIndex + ')';
        doer.price = currencyFormatter.format(Number(event.offer.offerPrice));
        user.fullName = (creator.firstName + ' ' + creator.lastName).trim();
        user.media = {
          avatarUrl: creator.avatarUrl
        };
      }
      break;

    case 'OFFER_PAYMENT_CANCELLED_SELLER_BITPAY':
      {
        const patron = event.patronUser;
        doer.id = patron.id;
        doer.tier =
          event.exchange.serialIndex === 1
            ? '(Digital Original #1)'
            : '(Limited Edition #' + event.exchange.serialIndex + ')';
        doer.destination = '/offers';
        doer.fullName = (patron.firstName + ' ' + patron.lastName).trim();
        doer.username = patron.username;
        user.fullName = (patron.firstName + ' ' + patron.lastName).trim();
        user.media = {
          avatarUrl: patron.avatarUrl
        };
      }
      break;

    case 'COLLECTIBLE_RELEASE_STARTED':
      {
        const creator = event.creatorProfile;
        user.fullName = (creator.firstName + ' ' + creator.lastName).trim();
        user.media = {
          avatarUrl: creator.avatarUrl
        };
      }
      break;

    case 'COLLECTIBLE_BOUGHT':
      const creator = event.creatorUser;
      const owner = event.ownerUser;

      doer.id = owner.id;
      doer.fullName = (owner.firstName + ' ' + owner.lastName).trim();
      doer.username = owner.username;
      user.fullName = (creator.firstName + ' ' + creator.lastName).trim();
      user.media = {
        avatarUrl: creator.avatarUrl
      };
      break;

    case 'COLLECTIBLE_RESERVED_SELLER':
      {
        const creator = event.creatorUser;
        const owner = event.patronUser;
        doer.id = owner.id;
        doer.fullName = (owner.firstName + ' ' + owner.lastName).trim();
        doer.username = owner.username;
        user.fullName = (creator.firstName + ' ' + creator.lastName).trim();
        user.media = {
          avatarUrl: creator.avatarUrl
        };
      }
      break;

    case 'PRINT_ORDER_PAYMENT_CONFIRMED_ADMIN':
      {
        const buyer = event.buyer;
        doer.id = buyer.id;
        doer.fullName = (buyer.firstName + ' ' + buyer.lastName).trim();
        user.fullName = (buyer.firstName + ' ' + buyer.lastName).trim();
        user.media = {
          avatarUrl: buyer.avatarUrl
        };
      }
      break;

    case 'WITHDRAW_REQUESTED_ADMIN':
      {
        const owner = event.ownerUser;
        doer.id = owner.id;
        doer.fullName = (owner.firstName + ' ' + owner.lastName).trim();
        doer.amount = `${currencyFormatter.format(
          Number(event?.wallet?.credit || event?.amount)
        )} USD`;
        user.fullName = (owner.firstName + ' ' + owner.lastName).trim();
        user.media = {
          avatarUrl: owner.avatarUrl
        };
      }
      break;

    case 'FCLUB_THREAD_CREATED_FANS':
      {
        const creator = event?.creatorUser;
        const owner = event?.ownerUser;
        doer.id = creator?.id;
        doer.fullName = (creator?.firstName + ' ' + creator?.lastName).trim();
        user.fullName = (owner?.displayName || owner?.firstName + ' ' + owner?.lastName).trim();
        user.media = {
          avatarUrl: owner?.avatarUrl
        };
      }
      break;

    case 'FCLUB_COMMENT_CREATED':
      {
        const creator = event.creatorUser;
        const owner = event.ownerUser;
        doer.id = creator.id;
        doer.fullName = (creator.firstName + ' ' + creator.lastName).trim();
        user.fullName = (owner?.displayName || owner?.firstName + ' ' + owner?.lastName).trim();
        user.media = {
          avatarUrl: owner?.avatarUrl
        };
      }
      break;

    case 'FCLUB_THREAD_LIKED':
      {
        const creator = event.creatorUser;
        const owner = event.ownerUser;
        doer.id = creator.id;
        doer.fullName = (creator.firstName + ' ' + creator.lastName).trim();
        user.fullName = (owner.displayName || owner.firstName + ' ' + owner.lastName).trim();
        user.media = {
          avatarUrl: owner.avatarUrl
        };
      }
      break;

    case 'FCLUB_THREAD_REPORTED':
      {
        const creator = event.creatorUser;
        const owner = event.ownerUser;
        doer.id = creator.id;
        doer.fullName = (creator.firstName + ' ' + creator.lastName).trim();
        user.fullName = (owner.displayName || owner.firstName + ' ' + owner.lastName).trim();
        user.media = {
          avatarUrl: owner.avatarUrl
        };
      }
      break;

    default:
      doer.id = '';
      doer.fullName = '';
      doer.username = '';
      break;
  }
  const { onUpdateNotification } = useUpdateReadNotification(notification?.id);
  const onUpdate = async () => {
    if (notification.read === 'FALSE') {
      const data = {
        id: notification?.id,
        itemType: 'notification'
      };
      await onUpdateNotification(data);
    }
  };

  return (
    <Row onClick={() => onUpdate()} className="notification-menu">
      <Col xl={12} span={24}>
        <Row>
          <Col xl={1} span={24}>
            <div className={unreadClass}></div>
          </Col>
          <Col xl={2} span={24} className="notification-menu-avatar">
            <Avatar size={36} user={user} />
          </Col>
          <Col span={19}>
            {(() => {
              switch (notification?.type) {
                case 'USER_FOLLOWED_CREATOR':
                  return (
                    <>
                      <Link target="_blank" to={{ pathname: `${config.fansUrl}/${doer.username}` }}>
                        <div className="notification-menu-event-name">
                          <Text>{user.fullName}</Text>
                        </div>
                        <div className="notification-menu-event-description">
                          <Trans i18nKey="notifications.USER_FOLLOWED_CREATOR">
                            {{ fullName: doer.fullName }}
                          </Trans>
                        </div>
                      </Link>
                    </>
                  );

                case 'COLLECTIBLE_LIKE': {
                  const collectible = notification?.event?.collectible;
                  const collectibleName = collectible.title;
                  const creator = notification?.event?.creatorUser;
                  return (
                    <>
                      <div className="notification-menu-event-name">
                        <Text>{user.fullName}</Text>
                      </div>
                      <div className="notification-menu-event-description">
                        <Trans i18nKey="notifications.COLLECTIBLE_LIKE">
                          <Link
                            target="_blank"
                            to={{ pathname: `${config.fansUrl}/${doer.username}` }}
                          >
                            {{ fullName: doer.fullName }}
                          </Link>
                          <Link
                            target="_blank"
                            to={{
                              pathname: `${config.fansUrl}/${creator.username}/${collectible.slug}`
                            }}
                          >
                            {{ collectibleName }}
                          </Link>
                        </Trans>
                      </div>
                    </>
                  );
                }

                case 'COLLECTIBLE_PUBLISHED': {
                  const collectible = notification?.event?.collectible;
                  const collectibleName = collectible.title;
                  const creator = notification?.event?.creatorProfile;
                  return (
                    <>
                      <div className="notification-menu-event-name">
                        <Text>{user.fullName}</Text>
                      </div>
                      <div className="notification-menu-event-description">
                        <Trans i18nKey="notifications.COLLECTIBLE_PUBLISHED">
                          <Link
                            target="_blank"
                            to={{
                              pathname: `${config.fansUrl}/${creator?.username}/${collectible.slug}`
                            }}
                          >
                            {{ collectibleName }}
                          </Link>
                        </Trans>
                      </div>
                    </>
                  );
                }

                case 'COLLECTIBLE_APPROVED_CREATOR': {
                  const collectible = notification?.event?.collectible;
                  const collectibleName = collectible.title;
                  const creator = notification?.event?.creatorProfile;
                  return (
                    <>
                      <Link
                        target="_blank"
                        to={{
                          pathname: `${config.fansUrl}/${creator?.username}/${collectible.slug}`
                        }}
                      >
                        <div className="notification-menu-event-name">
                          <Text>{user.fullName}</Text>
                        </div>
                        <div className="notification-menu-event-description">
                          <Trans i18nKey={`notifications.${notification?.type}`}>
                            {{ collectibleName }}
                          </Trans>
                        </div>
                      </Link>
                    </>
                  );
                }

                case 'COLLECTIBLE_RELEASE_STARTED': {
                  const collectible = notification?.event?.collectible;
                  const collectibleName = collectible.title;
                  const creator = notification?.event?.creatorProfile;
                  return (
                    <>
                      <div className="notification-menu-event-name">
                        <Text>{user.fullName}</Text>
                      </div>
                      <div className="notification-menu-event-description">
                        <Trans i18nKey="notifications.COLLECTIBLE_RELEASE_STARTED">
                          <Link
                            target="_blank"
                            to={{
                              pathname: `${config.fansUrl}/${creator?.username}/${collectible.slug}`
                            }}
                          >
                            {{ collectibleName }}
                          </Link>
                        </Trans>
                      </div>
                    </>
                  );
                }

                case 'COLLECTIBLE_OFFER_ACCEPTED': {
                  const creator = notification?.event?.creatorUser;
                  const collectible = notification?.event?.collectible;
                  return (
                    <>
                      <Link
                        target="_blank"
                        to={{
                          pathname: `${config.fansUrl}/${creator.username}/${collectible.slug}`
                        }}
                      >
                        <div className="notification-menu-event-name">
                          <Text>{user.fullName}</Text>
                        </div>
                        <div className="notification-menu-event-description">
                          <Trans i18nKey={`notifications.${notification?.type}`}>
                            <Text>{{ price: doer.price }}</Text>
                            <Text>{{ collectibleName: collectible.title }}</Text>
                            <Text>{{ tier: doer.tier }}</Text>
                          </Trans>
                        </div>
                      </Link>
                    </>
                  );
                }

                case 'OFFER_PAYMENT_REQUESTED_SELLER_BITPAY': {
                  const creator = notification?.event?.creatorUser;
                  const collectible = notification?.event?.collectible;

                  return (
                    <>
                      <Link
                        target="_blank"
                        to={{
                          pathname: `${config.fansUrl}/${creator.username}/${collectible.slug}`
                        }}
                      >
                        <div className="notification-menu-event-name">
                          <Text>{user.fullName}</Text>
                        </div>
                        <div className="notification-menu-event-description">
                          <Trans i18nKey={`notifications.${notification?.type}`}>
                            {{ collectibleName: collectible.title }}
                            {{ tier: doer.tier }}
                            {{ price: doer.price }}
                          </Trans>
                        </div>
                      </Link>
                    </>
                  );
                }

                case 'OFFER_PAYMENT_CANCELLED_SELLER_BITPAY': {
                  const collectible = notification?.event?.collectible;

                  return (
                    <>
                      <Link to={doer.destination}>
                        <div className="notification-menu-event-name">
                          <Text>{user.fullName}</Text>
                        </div>
                        <div className="notification-menu-event-description">
                          <Trans i18nKey={`notifications.${notification?.type}`}>
                            <Text>{{ fullName: doer.fullName }}</Text>
                            <Text>{{ price: doer.price }}</Text>
                            <Text>{{ collectibleName: collectible.title }}</Text>
                            <Text>{{ tier: doer.tier }}</Text>
                          </Trans>
                        </div>
                      </Link>
                    </>
                  );
                }

                case 'OFFER_WITHDRAWN_SELLER': {
                  const collectible = notification?.event?.collectible;

                  return (
                    <>
                      <Link to={doer.destination}>
                        <div className="notification-menu-event-name">
                          <Text>{user.fullName}</Text>
                        </div>
                        <div className="notification-menu-event-description">
                          <Trans i18nKey={`notifications.${notification?.type}`}>
                            {{ collectibleName: collectible.title }}
                            {{ tier: doer.tier }}
                            {{ fullName: doer.fullName }}
                          </Trans>
                        </div>
                      </Link>
                    </>
                  );
                }

                case 'COLLECTIBLE_SOLD':
                case 'COLLECTIBLE_SOLD_CREATOR':
                case 'COLLECTIBLE_OFFER_SOLD_CREATOR': {
                  const creator = notification?.event?.creatorUser;
                  const collectible = notification?.event?.collectible;
                  return (
                    <>
                      <Link
                        target="_blank"
                        to={{
                          pathname: `${config.fansUrl}/${creator.username}/${collectible.slug}`
                        }}
                      >
                        <div className="notification-menu-event-name">
                          <Text>{user.fullName}</Text>
                        </div>
                        <div className="notification-menu-event-description">
                          <Trans i18nKey="notifications.COLLECTIBLE_SOLD">
                            <Text>{{ collectibleName: collectible.title }}</Text>
                            <Text>{{ tier: doer.tier }}</Text>
                            <Text>{{ fullName: doer.fullName }}</Text>
                            <Text>{{ price: doer.price }}</Text>
                          </Trans>
                        </div>
                      </Link>
                    </>
                  );
                }

                case 'COLLECTIBLE_OFFER_MADE':
                case 'COLLECTIBLE_BID_MADE': {
                  const creator = notification?.event?.creatorUser;
                  const collectible = notification?.event?.collectible;

                  return (
                    <>
                      <Link
                        target="_blank"
                        to={{
                          pathname: `${config.fansUrl}/${creator.username}/${collectible.slug}`
                        }}
                      >
                        <div className="notification-menu-event-name">
                          <Text>{user.fullName}</Text>
                        </div>
                        <div className="notification-menu-event-description">
                          <Trans i18nKey={`notifications.${notification?.type}`}>
                            <Text>{{ price: doer.price }}</Text>
                            <Text>{{ collectibleName: collectible.title }}</Text>
                            <Text>{{ tier: doer.tier }}</Text>
                          </Trans>
                        </div>
                      </Link>
                    </>
                  );
                }

                case 'COLLECTIBLE_OFFER_RECEIVED':
                case 'COLLECTIBLE_BID_RECEIVED': {
                  const collectible = notification?.event?.collectible;

                  return (
                    <>
                      <Link to={doer.destination}>
                        <div className="notification-menu-event-name">
                          <Text>{user.fullName}</Text>
                        </div>
                        <div className="notification-menu-event-description">
                          <Trans i18nKey={`notifications.${notification?.type}`}>
                            <Text>{{ price: doer.price }}</Text>
                            <Text>{{ collectibleName: collectible.title }}</Text>
                            <Text>{{ tier: doer.tier }}</Text>
                          </Trans>
                        </div>
                      </Link>
                    </>
                  );
                }

                case 'AUCTION_BID_SUBMITTED_CREATORSELLER': {
                  const collectible = notification?.event?.collectible;
                  const creator = notification?.event?.creatorUser;
                  const exchange = notification?.event?.exchange;

                  return (
                    <>
                      <Link
                        target="_blank"
                        to={{
                          pathname: `${config.fansUrl}/${creator.username}/${collectible.slug}/${exchange.serialIndex}`
                        }}
                      >
                        <div className="notification-menu-event-name">
                          <Text>{user.fullName}</Text>
                        </div>
                        <div className="notification-menu-event-description">
                          <Trans i18nKey={`notifications.${notification?.type}`}>
                            <Text>{{ fullName: doer.fullName }}</Text>
                            <Text>{{ collectibleName: collectible.title }}</Text>
                            <Text>{{ price: doer.price }}</Text>
                            <Text>{{ tier: doer.tier }}</Text>
                            <Text>{{ creatorName: doer.creatorName }}</Text>
                          </Trans>
                        </div>
                      </Link>
                    </>
                  );
                }

                case 'AUCTION_WON_CREATOR': {
                  const collectible = notification?.event?.collectible;
                  const creator = notification?.event?.creatorUser;
                  const exchange = notification?.event?.exchange;

                  return (
                    <>
                      <Link
                        target="_blank"
                        to={{
                          pathname: `${config.fansUrl}/${creator.username}/${collectible.slug}/${exchange.serialIndex}`
                        }}
                      >
                        <div className="notification-menu-event-name">
                          <Text>{user.fullName}</Text>
                        </div>
                        <div className="notification-menu-event-description">
                          <Trans i18nKey={`notifications.${notification?.type}`}>
                            <Text>{{ collectibleName: collectible.title }}</Text>
                            <Text>{{ tier: doer.tier }}</Text>
                            <Text>{{ fullName: doer.fullName }}</Text>
                            <Text>{{ price: doer.price }}</Text>
                          </Trans>
                        </div>
                      </Link>
                    </>
                  );
                }

                case 'OFFER_SUBMITTED_CREATOROWNER': {
                  const collectible = notification?.event?.collectible;

                  return (
                    <>
                      <Link to={doer.destination}>
                        <div className="notification-menu-event-name">
                          <Text>{user.fullName}</Text>
                        </div>
                        <div className="notification-menu-event-description">
                          <Trans i18nKey={`notifications.${notification?.type}`}>
                            <Text>{{ price: doer.price }}</Text>
                            <Text>{{ collectibleName: collectible.title }}</Text>
                            <Text>{{ tier: doer.tier }}</Text>
                            <Text>{{ fullName: doer.fullName }}</Text>
                          </Trans>
                        </div>
                      </Link>
                    </>
                  );
                }

                case 'COLLECTIBLE_SUBMITTED_ADMIN': {
                  const collectible = notification?.event?.collectible;
                  return (
                    <>
                      <Link to={`/admin/collectibles/${collectible.id}`}>
                        <div className="notification-menu-event-name">
                          <Text>{user.fullName}</Text>
                        </div>
                        <div className="notification-menu-event-description">
                          <Trans i18nKey={`notifications.${notification?.type}`}>
                            <Text>{{ collectibleName: collectible.title }}</Text>
                            <Text>{{ price: doer.price }}</Text>
                            <Text>{{ fullName: doer.fullName }}</Text>
                          </Trans>
                        </div>
                      </Link>
                    </>
                  );
                }

                case 'COLLECTIBLE_REVISION_REQUESTED': {
                  const collectible = notification?.event?.collectible;
                  return (
                    <>
                      <Link to={`collectibles/edit/${collectible.id}`}>
                        <div className="notification-menu-event-name">
                          <Text>{user.fullName}</Text>
                        </div>
                        <div className="notification-menu-event-description">
                          <Trans i18nKey={`notifications.${notification?.type}`}>
                            <Text>{{ collectibleName: collectible.title }}</Text>
                          </Trans>
                        </div>
                      </Link>
                    </>
                  );
                }

                case 'COLLECTIBLE_SOLD_CREATOROWNER': {
                  const collectible = notification?.event?.collectible;
                  const creator = notification?.event?.creatorUser;

                  return (
                    <>
                      <Link
                        target="_blank"
                        to={{
                          pathname: `${config.fansUrl}/${creator.username}/${collectible.slug}`
                        }}
                      >
                        <div className="notification-menu-event-name">
                          <Text>{user.fullName}</Text>
                        </div>
                        <div className="notification-menu-event-description">
                          <Trans i18nKey={`notifications.${notification?.type}`}>
                            <Text>{{ collectibleName: collectible.title }}</Text>
                            <Text>{{ price: doer.price }}</Text>
                            <Text>{{ fullName: doer.fullName }}</Text>
                          </Trans>
                        </div>
                      </Link>
                    </>
                  );
                }

                case 'COLLECTIBLE_BOUGHT':
                  const creator = notification?.event?.creatorUser;
                  const collectible = notification?.event?.collectible;

                  return (
                    <>
                      <div className="notification-menu-event-name">
                        <Text>{user.fullName}</Text>
                      </div>
                      <div className="notification-menu-event-description">
                        <Trans i18nKey={`notifications.${notification?.type}`}>
                          <Link
                            target="_blank"
                            to={{ pathname: `${config.fansUrl}/${doer.username}` }}
                          >
                            {{ fullName: doer.fullName }}
                          </Link>
                          <Link
                            target="_blank"
                            to={{
                              pathname: `${config.fansUrl}/${creator.username}/${collectible.slug}`
                            }}
                          >
                            {{ collectibleName: collectible.title }}
                          </Link>
                        </Trans>
                      </div>
                    </>
                  );

                case 'COLLECTIBLE_RESERVED_SELLER': {
                  const creator = notification?.event?.creatorUser;
                  const collectible = notification?.event?.collectible;
                  return (
                    <>
                      <div className="notification-menu-event-name">
                        <Text>{user.fullName}</Text>
                      </div>
                      <div className="notification-menu-event-description">
                        <Trans i18nKey={`notifications.${notification?.type}`}>
                          <Link
                            target="_blank"
                            to={{ pathname: `${config.fansUrl}/${doer.username}` }}
                          >
                            {{ fullName: doer.fullName }}
                          </Link>
                          <Link
                            target="_blank"
                            to={{
                              pathname: `${config.fansUrl}/${creator.username}/${collectible.slug}`
                            }}
                          >
                            {{ collectibleName: collectible.title }}
                          </Link>
                        </Trans>
                      </div>
                    </>
                  );
                }

                case 'PRINT_ORDER_PAYMENT_CONFIRMED_ADMIN': {
                  return (
                    <>
                      <Link target="_blank" to="/admin/order-history">
                        <div className="notification-menu-event-name">
                          <Text>{user.fullName}</Text>
                        </div>
                        <div className="notification-menu-event-description">
                          <Trans i18nKey={`notifications.${notification?.type}`}>
                            <Text>{{ fullName: doer.fullName }}</Text>
                          </Trans>
                        </div>
                      </Link>
                    </>
                  );
                }

                case 'FCLUB_THREAD_CREATED_FANS': {
                  const channel = notification?.event?.channelData;
                  return (
                    <>
                      <Link to={`/fan-club/${channel?.owner}/channels/${channel?.id}`}>
                        <div className="notification-menu-event-name">
                          <Text>{user.fullName}</Text>
                        </div>
                        <div className="notification-menu-event-description">
                          <Trans i18nKey={`notifications.${notification?.type}`}>
                            <Text>{{ fullName: doer.fullName }}</Text>
                            <Text>{{ channelName: channel?.name }}</Text>
                          </Trans>
                        </div>
                      </Link>
                    </>
                  );
                }

                case 'FCLUB_COMMENT_CREATED': {
                  const channel = notification?.event?.channelData;
                  return (
                    <>
                      <Link to={`/fan-club/${channel?.owner}/channels/${channel?.id}`}>
                        <div className="notification-menu-event-name">
                          <Text>{user.fullName}</Text>
                        </div>
                        <div className="notification-menu-event-description">
                          <Trans
                            i18nKey={`notifications.${notification?.type}_${notification?.event?.type}`}
                          >
                            <Text>{{ fullName: doer.fullName }}</Text>
                            <Text>{{ channelName: channel?.name }}</Text>
                          </Trans>
                        </div>
                      </Link>
                    </>
                  );
                }

                case 'FCLUB_THREAD_LIKED': {
                  const channel = notification?.event?.channelData;
                  return (
                    <>
                      <Link to={`/fan-club/${channel?.owner}/channels/${channel?.id}`}>
                        <div className="notification-menu-event-name">
                          <Text>{user.fullName}</Text>
                        </div>
                        <div className="notification-menu-event-description">
                          <Trans i18nKey={`notifications.${notification?.type}`}>
                            <Text>{{ fullName: doer.fullName }}</Text>
                          </Trans>
                        </div>
                      </Link>
                    </>
                  );
                }

                case 'FCLUB_THREAD_REPORTED': {
                  const channel = notification?.event?.channelData;
                  const reason = reportList.filter((x) => x.value === notification?.event?.reason);
                  return (
                    <>
                      <Link to={`/fan-club/${channel?.owner}/channels/${channel?.id}`}>
                        <div className="notification-menu-event-name">
                          <Text>{user.fullName}</Text>
                        </div>
                        <div className="notification-menu-event-description">
                          <Trans i18nKey={`notifications.${notification?.type}`}>
                            <Text>{{ fullName: doer.fullName }}</Text>
                            <Text>
                              {{ reason: t(`components.post.reportList.${reason[0]?.label}`) }}
                            </Text>
                          </Trans>
                        </div>
                      </Link>
                    </>
                  );
                }

                case 'WITHDRAW_REQUESTED_ADMIN': {
                  const provider = notification?.event?.wallet?.withdrawal?.provider || 'PAYPAL';
                  return (
                    <>
                      <Link target="_blank" to="/admin/withdrawal">
                        <div className="notification-menu-event-name">
                          <Text>{user.fullName}</Text>
                        </div>
                        <div className="notification-menu-event-description">
                          <Trans i18nKey={`notifications.${notification?.type}`}>
                            <Text>{{ fullName: doer.fullName }}</Text>
                            <Text>{{ amount: doer.amount }}</Text>
                            <Text>{{ withdrawMethod: provider }}</Text>
                          </Trans>
                        </div>
                      </Link>
                    </>
                  );
                }

                default:
                  return;
              }
            })()}
          </Col>
        </Row>
      </Col>

      <Col className="notification-menu-event" xl={9} span={24}></Col>
      <Col className="notification-menu-event-time" xl={3} span={24}>
        {notificationTime}
      </Col>
    </Row>
  );
};

export default NotificationMenuItem;
