import React, { useState } from 'react';
import { Storage } from 'aws-amplify';
import { Tooltip, Button, Modal, Upload, Form, Image, Spin, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { createAssetUrl } from '@libs/utils/mimeType';
import MimeType from '@libs/utils/mimeType';
import { Player } from '@components';

import { CameraOutlined, UploadOutlined, LoadingOutlined, CloseOutlined } from '@ant-design/icons';
const loadingIcon = <LoadingOutlined style={{ fontSize: 20, margin: 5 }} spin />;

const formatImgSrc = async (src) => {
  if (src && !src.startsWith('http')) {
    const s3Key = createAssetUrl(src, null, true);
    if (s3Key.startsWith('http')) {
      return s3Key;
    } else {
      const result = await Storage.get(src, { contentType: 'image/png' });
      return result;
    }
  }
};

const props = (imageList, setImageList, setLoading, creatorID) => ({
  name: 'file',
  multiple: true,
  showUploadList: false,
  beforeUpload(file) {
    const supported = ['image/jpeg', 'image/png', 'image/gif', 'video/mp4'];
    const fileFormat = file.name.split('.').pop();
    const isJFIF = fileFormat === 'jfif';

    const isAllowed = supported.includes(file.type) && !isJFIF;

    if (!isAllowed) {
      message.error('File type is not supported.');
    }

    const isLt5M = file.size / 1024 / 1024 < 100;
    if (!isLt5M) {
      message.error('File must smaller than 100MB.');
    }

    return isAllowed && isLt5M;
  },
  customRequest: async (options) => {
    try {
      const ext = options.file.name.split('.').pop();
      const res = await Storage.put(
        `temps/fanclub_uploads/${creatorID}_${nanoid()}.${ext}`,
        options.file
      );

      if (res.key) {
        options.onSuccess(res.key, options.file);
      } else {
        options.onError('Failed when uploading the file.');
      }
    } catch (err) {
      options.onError(err);
    }
  },
  async onChange(info) {
    const { status } = info.file;

    if (status === 'uploading') {
      setLoading(true);
    }

    if (status === 'done') {
      const updatedImageList = [...imageList];
      if (info.file.response) {
        updatedImageList.push({
          ...info.file,
          url: await formatImgSrc(info.file.response)
        });
      }

      setImageList(updatedImageList);
      setLoading(false);
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      setLoading(false);
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {}
});

function UploadImage({ imageList, setImageList, creatorID }) {
  const { t } = useTranslation();
  const [uploadImage, setUploadImage] = useState(false);
  const [loading, setLoading] = useState(false);

  const [uploadImageForm] = Form.useForm();
  const { Dragger } = Upload;

  const handleRemoveImg = async (index) => {
    await Storage.remove(imageList[index].response);
    const updatedImageList = [...imageList];
    updatedImageList.splice(index, 1);
    setImageList(updatedImageList);
  };

  return (
    <>
      <Tooltip title={t('fanClub.button.uploadImage')}>
        <Button
          style={{ width: 'auto' }}
          icon={<CameraOutlined />}
          onClick={() => setUploadImage(!uploadImage)}
        />
      </Tooltip>
      <Modal
        className="fanclub-channel-update-modal"
        visible={uploadImage}
        footer={null}
        onCancel={() => setUploadImage(false)}
        width={650}
        title={t('fanClub.uploadImage')}
      >
        <Form
          onFinish={() => setUploadImage(false)}
          form={uploadImageForm}
          layout="vertical"
          className="admin-form"
        >
          <div className="fanclub-upload-image">
            <Image.PreviewGroup>
              {imageList?.map((img, index) => (
                <div className="fanclub-image-container">
                  {MimeType(img.url) ? (
                    <div>
                      <Player src={img.url} />
                    </div>
                  ) : (
                    <Image style={{ objectFit: 'cover' }} width={200} height={200} src={img.url} />
                  )}
                  <Button
                    type="text"
                    className="fanclub-image-remove"
                    onClick={() => handleRemoveImg(index)}
                  >
                    <CloseOutlined style={{ color: 'white' }} />
                  </Button>
                </div>
              ))}
            </Image.PreviewGroup>
            <Form.Item name="image" className="fanclub-dragger">
              <Dragger
                {...props(imageList, setImageList, setLoading, creatorID)}
                style={{ width: 200, height: 200 }}
              >
                <p className="ant-upload-drag-icon">
                  <UploadOutlined />
                </p>
                <p className="ant-upload-text" style={{ fontWeight: '600' }}>
                  {t('collectibles.fields.media.text')}
                </p>
                <p className="ant-upload-hint">{t('fanClub.media.hint')}</p>
              </Dragger>
              {loading && <Spin className="image-preview-field__loading" indicator={loadingIcon} />}
            </Form.Item>
          </div>

          <Button htmlType="submit" className="fanclub-channel-update-modal-button" type="primary">
            {t('fanClub.channel.save')}
          </Button>
        </Form>
      </Modal>
    </>
  );
}

export default UploadImage;
