import { Select, Form, Button } from 'antd';
import { Helmet } from 'react-helmet';
import { DashboardContent } from '@components';
import { useCollectiblesInReview } from '@libs/hooks/collectible';
import { useCallback, useEffect } from 'react';
import useQuery from '@libs/utils/query';

const { Option } = Select;

function DummyApproveCollectible() {
  const query = useQuery();
  const slug = query.get('slug');

  const { data: collectibles, onApprove, approvalLoading } = useCollectiblesInReview();

  const onSubmit = useCallback(
    (values) => {
      onApprove(values.collectibleID);
    },
    [onApprove]
  );

  useEffect(() => {
    if (collectibles && slug) {
      const selectedCollectibles = collectibles.filter((col) => {
        return col.slug === slug;
      });

      if (selectedCollectibles.length < 1) return;

      const params = {
        collectibleID: selectedCollectibles[0].id
      };
      onSubmit(params);
    }
  }, [slug, collectibles, onSubmit]);

  return (
    <>
      <DashboardContent title="[Temporary] Approve A Collectible">
        <Form onFinish={onSubmit} layout="vertical" className="admin-form">
          <Form.Item
            className="approve-collectible-input"
            label="Select Collectibles"
            name="collectibleID"
            rules={[{ required: true, message: 'Collectible is required.' }]}
          >
            <Select
              placeholder="Select collectible to approve"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {collectibles?.map((c, i) => {
                const option = `${c.title} by ${c.creator.firstName} ${c.creator.lastName}`;
                return (
                  <Option key={`collectible-${i}`} value={c.id}>
                    {option}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={approvalLoading}>
              Approve
            </Button>
          </Form.Item>
        </Form>
      </DashboardContent>
      <Helmet>
        <title>Approve Collectible</title>
      </Helmet>
    </>
  );
}

export default DummyApproveCollectible;
