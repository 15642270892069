import { useState, useEffect, useRef } from 'react';
import { imageScale, GetImage } from './utils';
import { Col, Form, Row, Select, Spin } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { FormGroup } from '@components';
import { toPng } from 'html-to-image';

const ShowOriginalImage = ({
  frame,
  height,
  width,
  setWidth,
  setHeight,
  setFrame,
  image,
  optionFrame,
  groupName,
  padding
}) => {
  const { t } = useTranslation();
  const { Option } = Select;
  const onChangeFrame = (value) => {
    const frame = optionFrame?.filter((x) => x.value === value)[0];
    setWidth(frame.width);
    setHeight(frame.height);
    setFrame(value);
  };
  const [heightFrame, setHeightFrame] = useState();
  const [widthFrame, setWidthFrame] = useState();
  const [collectibleImage] = GetImage(image);
  const [imageUri, setImageUri] = useState();
  const mediaRef = useRef();
  useEffect(() => {
    const { heightFrame, widthFrame } = imageScale(collectibleImage, width * 80, height * 80);
    setHeightFrame(heightFrame);
    setWidthFrame(widthFrame);
  }, [collectibleImage, height, width]);

  useEffect(() => {
    async function getImage() {
      const imageUrl = await toPng(mediaRef.current);
      setImageUri(imageUrl);
    }
    setTimeout(() => {
      if (mediaRef.current && heightFrame && widthFrame) {
        getImage();
      }
    }, 500);
  }, [mediaRef.current, heightFrame, widthFrame]); //eslint-disable-line

  return (
    <>
      <Row gutter={26} style={{ marginBottom: '30px' }}>
        <Col span={24} lg={6} className="print-frame__flex__left">
          <FormGroup heading={groupName}> </FormGroup>
          <Row gutter={26}>
            <Col span={24}>
              <Form.Item
                label={t('collectibles.fields.size.label')}
                rules={[
                  {
                    required: true,
                    message: t('collectibles.fields.size.required')
                  }
                ]}
              >
                <Select
                  suffixIcon="Inch"
                  value={frame}
                  onChange={(e) => onChangeFrame(e)}
                  placeholder={t('collectibles.fields.size.placeholder')}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {optionFrame.map((print) => (
                    <Option key={`print-${print.value}`} value={print.value}>
                      {print.name}
                    </Option>
                  ))}
                </Select>
                <div className="print-frame__text-cm">
                  <Trans i18nKey="collectibles.fields.size.cm">
                    {{
                      width: (width * 2.54).toFixed(1),
                      height: (height * 2.54).toFixed(1)
                    }}
                  </Trans>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24} lg={18} className="print-frame__wrapper">
          {heightFrame && widthFrame && image && (
            <>
              {imageUri ? (
                <div className="print-frame__uri">
                  <img alt="" src={imageUri} />
                </div>
              ) : (
                <Spin />
              )}
              <div className="print-frame__show">
                <div
                  ref={mediaRef}
                  style={{ height: heightFrame, width: widthFrame, padding: padding }}
                  className="print-frame__show__wrapper"
                >
                  <img
                    style={{ objectFit: 'contain', height: '100%', width: '100%' }}
                    src={image}
                    alt=""
                  />
                </div>
              </div>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ShowOriginalImage;
