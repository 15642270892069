import cx from 'classnames';
import { Link } from 'react-router-dom';

const FansLink = ({ target, to, className, children, ...props }) => {
  return (
    <Link to={to} target={target} className={cx(className)} {...props}>
      {children}
    </Link>
  );
};

export default FansLink;
