import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { FileImageOutlined } from '@ant-design/icons';
import { Image, Player, VIPCard } from '@components';
import MimeType from '@libs/utils/mimeType';

const CollectibleCard = ({ data, assetUrl, mediaThumbnail }) => {
  const { t } = useTranslation();
  const { Title } = Typography;
  const mediaThumbnailUrl = data.mediaThumbnail || mediaThumbnail;
  const collectibleAssetUrl = data.assetUrl || assetUrl;
  const isPlayable = MimeType(collectibleAssetUrl);

  return (
    <>
      {data?.category === 'VIP_PASS' ? (
        <VIPCard data={data} mediaThumbnailUrl={mediaThumbnailUrl} />
      ) : (
        <div className="collectible-card-v2">
          <div className="collectible-card-v2__image">
            {mediaThumbnailUrl ? (
              <>
                <Image size={600} src={mediaThumbnailUrl} />
                {isPlayable && (
                  <Player
                    src={data.assetUrl || assetUrl}
                    className="collectible-card-v2__image__player"
                  />
                )}
              </>
            ) : (
              <div className="collectible-card-v2__placeholder">
                <div className="collectible-card-v2__placeholder__content">
                  <FileImageOutlined />
                  <div>{t('components.collectibleCard.preview.instruction')}</div>
                </div>
              </div>
            )}
          </div>
          <div className="collectible-card-v2__detail">
            <Title className="collectible-card-v2__detail__title" level={3}>
              {data?.title === '' ? <>{t('components.collectibleCard.title')}</> : data?.title}
            </Title>
            <div className="collectible-card-v2__detail__container">
              <div className="collectible-card-v2__detail__text">
                By{' '}
                <span className="collectible-card-v2__detail__text__bold">
                  {data?.creator?.displayName ||
                    data?.creator?.firstName + ' ' + data?.creator?.lastName}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CollectibleCard;
