import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import moment from 'moment';
import Sticky from 'react-stickynode';

import {
  Col,
  Form,
  Row,
  Tooltip,
  Typography,
  Tabs,
  Image as ImageANTD,
  Timeline,
  Button
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { CollectibleCardV2, DashboardContent, Price, Avatar } from '@components';
import CollectibleModal from './modal';
import { categories, genres, listingTypes } from '@pages/collectibles/utils';
import { useChannelByOwner } from '@libs/hooks/discourse';
import { createAssetUrl } from '@libs/utils/mimeType';
import { historyColorMapper } from '@libs/utils/mapper';

import {
  useCollectible,
  useFeaturedArtist,
  useFeaturedArtistByCollectible,
  useCollectiblesInReview
} from '@libs/hooks/collectible';
const { TabPane } = Tabs;

function DetailCollectible() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: collectible, loading, getData } = useCollectible(id);
  let { data: channels } = useChannelByOwner(collectible?.userID);
  const { onApprove, onRequestRevison, approvalLoading } = useCollectiblesInReview(false);
  const { data: artistByCollectible, loading: artistLoading } = useFeaturedArtistByCollectible(id);
  const { onChange: onChangeArtists } = useFeaturedArtist();

  const [form] = Form.useForm();
  const disabled = collectible?.status === 'IN_REVIEW' || collectible?.status === 'PUBLISHED';

  const { Title } = Typography;

  // Pricing fields
  const doListingType = form.getFieldValue(['releases', 0, 'listingType']);
  const perksLimitedOriginal = collectible?.perksOriginal?.limitedPerks?.filter((e) => e);
  const perksLifetimeOriginal = collectible?.perksOriginal?.lifetimePerks?.filter((e) => e);
  const perksLimitedNumbered = collectible?.perksNumbered?.limitedPerks?.filter((e) => e);
  const perksLifetimeNumbered = collectible?.perksNumbered?.lifetimePerks?.filter((e) => e);

  const [assetUrl, setAssetUrl] = useState();
  const [visibleAsset, setVisibleAsset] = useState(false);
  const [mediaRes, setMediaRes] = useState();
  const [mediaThumbnail, setMediaThumbnail] = useState();
  const [visibleThumbnail, setVisibleThumbnail] = useState(false);
  const [thumbnailRes, setThumbnailRes] = useState();
  const [, triggerChanges] = useState();
  const [isDirty, setIsDirty] = useState(false);
  const [modalData, setModalData] = useState({
    status: false,
    mode: 'revisionSent',
    submit: () => {}
  });
  const [previewData, setPreviewData] = useState({
    title: collectible?.title || '',
    slug: collectible?.slug || '',
    creator: collectible?.creator || '',
    category: collectible?.category || '',
    lowestPrice: collectible?.searchableLowestPrice || '',
    highestPrice: collectible?.searchableHighestPrice || '',
    listingType: collectible?.releases[0].listingType || '',
    releaseCount: collectible?.releases?.[collectible?.releases?.length - 1]?.releaseCount || '',
    auctionEndDate: collectible?.releases[0].saleEndDate || ''
  });

  useEffect(() => {
    onChangeArtists(
      artistByCollectible?.map((x) => ({
        label: x.featuredArtist.artistName,
        value: x.featuredArtist.id
      })) || []
    );
  }, [artistByCollectible]); // eslint-disable-line

  useEffect(() => {
    const beforeUnload = isDirty ? () => '' : null;
    window.onbeforeunload = beforeUnload;
  }, [isDirty]);

  useEffect(() => {
    const getImageRes = async (file) => {
      return new Promise(async (resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = () => {
          reader.abort();
          reject(new Error('Problem parsing input file.'));
        };

        let response = await fetch(file);
        let data = await response.blob();

        reader.readAsDataURL(data);
        reader.addEventListener('load', (event) => {
          const _loadedImageUrl = event.target.result;
          const image = document.createElement('img');
          image.src = _loadedImageUrl;
          image.addEventListener('load', () => {
            const { width, height } = image;
            resolve(`${width}px x ${height}px`);
          });
        });
      });
    };

    const getImageResolution = async (file, cb) => {
      const res = await getImageRes(file);
      cb && cb(res);
    };

    if (collectible) {
      getImageResolution(createAssetUrl(collectible?.assetUrl, undefined, true), setMediaRes);
      getImageResolution(
        createAssetUrl(collectible?.mediaThumbnail, undefined, true),
        setThumbnailRes
      );
      setAssetUrl(collectible?.assetUrl);
      setMediaThumbnail(collectible?.mediaThumbnail);
      setPreviewData({
        title: collectible?.title,
        slug: collectible?.slug,
        creator: collectible?.creator,
        category: collectible?.category,
        lowestPrice: collectible?.searchableLowestPrice,
        highestPrice: collectible?.searchableHighestPrice,
        listingType: collectible?.releases ? collectible?.releases[0].listingType : 'FIXED_PRICE',
        releaseCount: collectible?.releases
          ? collectible?.releases?.[collectible?.releases?.length - 1]?.releaseCount
          : 1,
        auctionEndDate: collectible?.releases ? collectible?.releases[0].saleEndDate : null
      });
    }
  }, [collectible]);

  const featuredArtistValue = artistByCollectible?.map(
    (x, index) =>
      `${x.featuredArtist.artistName}${index < artistByCollectible.length - 1 ? ', ' : ''}`
  );
  const initialData = {
    title: collectible?.title,
    category: collectible?.category,
    genre: collectible?.genre,
    featuredArtist: featuredArtistValue,
    userID: collectible?.userID,
    slug: collectible?.slug,
    description: collectible?.description,
    insideStory: collectible?.insideStory,
    enableInsideStory: collectible?.insideStory !== '',
    maxNumberedEditions: collectible?.maxNumberedEditions,
    assetUrl: collectible?.assetUrl,
    mediaThumbnail: collectible?.mediaThumbnail,
    yearOfCreation: collectible?.yearOfCreation,
    featuredInProfile: collectible?.featuredInProfile === 'TRUE',
    whatsIncludedOriginal: collectible?.whatsIncludedOriginal,
    whatsIncludedNumbered: collectible?.whatsIncludedNumbered,
    marketingText: collectible?.marketingText,
    metaTitle: collectible?.metaTitle,
    metaDescription: collectible?.metaDescription,
    fanClubAccessOriginal: collectible?.perksOriginal?.fanClubAccess,
    fanClubAccessNumbered: collectible?.perksNumbered?.fanClubAccess,
    //collectionIDs: collectible?.collections?.items.map((c) => c.collectionID),
    releases: collectible?.releases.map((t) => ({
      ...t,
      setStartingPrice: t.setStartingPrice === 'TRUE',
      setFinalPrice: t.setFinalPrice === 'TRUE',
      releaseDate: moment(t.releaseDate),
      saleEndDate: t.saleEndDate ? moment(t.saleEndDate) : undefined
    }))
  };

  const numbEdition = (form.getFieldValue('releases') || []).filter(
    (r) => r.tierType === 'NUMBERED_EDITION'
  );
  const countNumbEditionReleases = numbEdition.reduce(
    (a, b) => (a += b?.releaseCount ? parseInt(b.releaseCount) : 0),
    0
  );
  const numbEditionTotalPrice = numbEdition.reduce((a, b) => {
    const releaseCount = parseInt(b.releaseCount);
    const finalPrice = b.finalPrice || b.startingPrice || 0;
    a += releaseCount * finalPrice;
    return a;
  }, 0);

  const promoEnabled = collectible?.releases?.length - 1 < 1;

  return (
    <>
      <DashboardContent title={collectible?.title} backLink="/admin/collectibles">
        {!collectible || loading || !artistByCollectible || artistLoading ? null : (
          <Form
            form={form}
            layout="vertical"
            className="admin-form admin-form-collectible admin-collectible"
            initialValues={initialData}
            onFieldsChange={(_, fields) => {
              triggerChanges(fields);
              setIsDirty(true);
            }}
            disabled={disabled}
          >
            <Row gutter={30}>
              <Col span={24} xl={18} lg={16} className="collectible-form-left">
                <Row gutter={30} style={{ height: '100%' }}>
                  {collectible?.history && collectible?.history?.length > 0 && (
                    <Col span={24} className="admin-collectible-timeline">
                      <Timeline>
                        {collectible?.history?.map((h) => (
                          <Timeline.Item color={historyColorMapper[h?.status]}>
                            <p>
                              {t(`collectibles.history.${h.status}`, {
                                title: collectible?.title,
                                description: h?.value
                              })}
                              <br />{' '}
                              <span className="admin-collectible-timeline__date">
                                {moment(h?.date).format('DD/MM/YYYY')}
                              </span>
                            </p>
                          </Timeline.Item>
                        ))}
                      </Timeline>
                      {collectible?.status === 'IN_REVIEW' && (
                        <Row gutter={10}>
                          <Col span={12}>
                            <Button
                              className="btn-action"
                              style={{ width: '100%' }}
                              onClick={() => {
                                setModalData({
                                  status: true,
                                  mode: 'revision',
                                  title: t('collectibles.modal.revision.label'),
                                  submit: async (data) => {
                                    const description = data?.description;
                                    await onRequestRevison(collectible?.id, description);

                                    setModalData({
                                      status: true,
                                      id: '',
                                      mode: 'revisionSent',
                                      submit: null
                                    });

                                    await getData(collectible?.id);
                                  }
                                });
                              }}
                            >
                              {t('collectibles.buttons.requestRevision')}
                            </Button>
                          </Col>
                          <Col span={12}>
                            <Button
                              className="btn-action btn-approve"
                              type="primary"
                              style={{ width: '100%' }}
                              onClick={() => {
                                setModalData({
                                  status: true,
                                  id: '',
                                  mode: 'approve',
                                  submit: async () => {
                                    await onApprove(collectible?.id);
                                    await getData(collectible?.id);

                                    setModalData({
                                      status: false,
                                      id: '',
                                      mode: '',
                                      submit: null
                                    });
                                  }
                                });
                              }}
                            >
                              {t('collectibles.buttons.approve')}
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  )}

                  <Col span={24}>
                    <Title className="title" level={3}>
                      {collectible?.title}
                    </Title>
                  </Col>
                  <Col className="subtitle" span={24}>
                    <span>{`fans.inc/${collectible?.creator?.username}/${collectible?.slug}`}</span>
                  </Col>
                  <Col span={24} style={{ height: '-webkit-fill-available' }}>
                    <Tabs
                      defaultActiveKey="1"
                      className="collectible-form-tabs"
                      style={{ height: '100%' }}
                    >
                      <TabPane
                        tab={t('collectibles.fields.group.content.heading')}
                        key="1"
                        forceRender
                      >
                        <Row gutter={16}>
                          <Col span={24}>
                            <Form.Item
                              label={
                                process.env.REACT_APP_NAME === 'patrons'
                                  ? t('collectibles.fields.creator.patrons.label')
                                  : t('collectibles.fields.creator.label')
                              }
                              name="userID"
                            >
                              <Avatar user={collectible?.creator} style={{ marginRight: 7 }} />
                              {`${collectible?.creator?.firstName} ${collectible?.creator?.lastName}`}
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item label={t('collectibles.fields.type.label')} name="category">
                              {categories.map((cat) => {
                                if (cat.value === collectible?.category) {
                                  return <span>{cat.name}</span>;
                                } else {
                                  return null;
                                }
                              })}
                            </Form.Item>
                          </Col>
                          {collectible?.streamExternal === 'TRUE' ? (
                            <>
                              <Col span={24}>
                                <Form.Item
                                  label={t('collectibles.fields.streamExternal.spotify.label')}
                                  name="streamSpotify"
                                >
                                  {collectible?.streamUrl?.spotify}
                                </Form.Item>
                              </Col>
                              <Col span={24}>
                                <Form.Item
                                  label={t('collectibles.fields.streamExternal.appleMusic.label')}
                                  name="streamAppleMusic"
                                >
                                  {collectible?.streamUrl?.appleMusic}
                                </Form.Item>
                              </Col>
                              <Col span={24}>
                                <Form.Item
                                  label={t('collectibles.fields.streamExternal.youtube.label')}
                                  name="streamYoutube"
                                >
                                  {collectible?.streamUrl?.youtube}
                                </Form.Item>
                              </Col>
                            </>
                          ) : (
                            <Col span={12}>
                              <Form.Item name="assetUrl" valuePropName="file">
                                <div className="collectible-media">
                                  <ImageANTD
                                    width={'auto'}
                                    src={
                                      collectible?.assetUrl
                                        ? collectible?.assetUrl?.startsWith('http')
                                          ? collectible?.assetUrl
                                          : createAssetUrl(collectible?.assetUrl, null, true)
                                        : ''
                                    }
                                    preview={{
                                      visible: visibleAsset,
                                      src: collectible?.assetUrl
                                        ? collectible?.assetUrl?.startsWith('http')
                                          ? collectible?.assetUrl
                                          : createAssetUrl(collectible?.assetUrl, null, true)
                                        : '',
                                      onVisibleChange: (value) => {
                                        setVisibleAsset(value);
                                      }
                                    }}
                                  />
                                  <div style={{ marginLeft: 10 }}>
                                    <span className="text text-title">
                                      {t('collectibles.fields.media.label')}
                                    </span>
                                    <span className="text text-desc">{mediaRes}</span>
                                    <span
                                      className="text"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setVisibleAsset(true);
                                      }}
                                    >
                                      View Detail
                                    </span>
                                  </div>
                                </div>
                              </Form.Item>
                            </Col>
                          )}

                          <Col span={collectible?.streamExternal === 'TRUE' ? 24 : 12}>
                            <Form.Item name="mediaThumbnail" valuePropName="file">
                              <div className="collectible-media">
                                <ImageANTD
                                  width={'auto'}
                                  src={
                                    collectible?.mediaThumbnail
                                      ? collectible?.mediaThumbnail?.startsWith('http')
                                        ? collectible?.mediaThumbnail
                                        : createAssetUrl(collectible?.mediaThumbnail, null, true)
                                      : ''
                                  }
                                  preview={{
                                    visible: visibleThumbnail,
                                    src: collectible?.mediaThumbnail
                                      ? collectible?.mediaThumbnail?.startsWith('http')
                                        ? collectible?.mediaThumbnail
                                        : createAssetUrl(collectible?.mediaThumbnail, null, true)
                                      : '',
                                    onVisibleChange: (value) => {
                                      setVisibleThumbnail(value);
                                    }
                                  }}
                                />
                                <div style={{ marginLeft: 10 }}>
                                  <span className="text text-title">
                                    {t('collectibles.fields.thumbnail.label')}
                                  </span>
                                  <span className="text text-desc">{thumbnailRes}</span>
                                  <span
                                    className="text"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      setVisibleThumbnail(true);
                                    }}
                                  >
                                    View Detail
                                  </span>
                                </div>
                              </div>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col span={24}>
                            <Form.Item
                              label={t('collectibles.fields.description.label')}
                              name="description"
                            >
                              <p>{collectible?.description}</p>
                            </Form.Item>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tab={t('collectibles.fields.group.attributes')} key="2" forceRender>
                        <Row gutter={16}>
                          <Col span={24}>
                            <Form.Item
                              name="featuredInProfile"
                              label={t('collectibles.fields.featured.label')}
                              valuePropName="checked"
                            >
                              {t(
                                `global.switch.${
                                  collectible?.featuredInProfile === 'TRUE' ? 'yes' : 'no'
                                }`
                              )}
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item label={t('collectibles.fields.genre.label')} name="genre">
                              {collectible?.genre.map((genre, index) => {
                                let all = [];
                                genres.forEach((g) => {
                                  if (g.value === genre) {
                                    all.push(
                                      <span>{`${g.name}${
                                        index < collectible?.genre.length - 1 ? ', ' : ''
                                      }`}</span>
                                    );
                                  }
                                });
                                return all;
                              })}
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              label={t('collectibles.fields.yearOfCreation.label')}
                              name="yearOfCreation"
                            >
                              {collectible?.yearOfCreation}
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item label={t('collectibles.fields.featuredArtist.label')}>
                              {featuredArtistValue}
                            </Form.Item>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tab={t('collectibles.fields.group.releases')} key="3" forceRender>
                        <Row gutter={20}>
                          {process.env.REACT_APP_NAME !== 'patrons' && (
                            <>
                              <Col span={12} style={{ display: promoEnabled ? 'none' : 'block' }}>
                                <Row gutter={26}>
                                  <Col span={24}>
                                    <Title level={5} className="group-title">
                                      {t('collectibles.fields.group.digitalOriginal.heading')}
                                    </Title>
                                  </Col>
                                  <Col span={24}>
                                    <Form.Item
                                      name={['releases', 0, 'listingType']}
                                      label={t('collectibles.fields.listingType.label')}
                                    >
                                      {listingTypes.map((listing) => {
                                        if (
                                          listing.value === collectible?.releases?.[0].listingType
                                        ) {
                                          return listing.label;
                                        } else {
                                          return '';
                                        }
                                      })}
                                    </Form.Item>
                                  </Col>
                                  {doListingType === 'AUCTION' && (
                                    <>
                                      <Col span={24}>
                                        <Form.Item
                                          name={['releases', 0, 'startingPrice']}
                                          label={t(
                                            'collectibles.fields.startingPrice.auctionStartingPrice'
                                          )}
                                        >
                                          {collectible?.releases?.[0]?.setStartingPrice ===
                                          'TRUE' ? (
                                            <Price
                                              amount={collectible?.releases?.[0]?.startingPrice}
                                              inline
                                            />
                                          ) : (
                                            t('global.notSet')
                                          )}
                                        </Form.Item>
                                      </Col>
                                      <Col span={24}>
                                        <Form.Item
                                          name={['releases', 0, 'reservePrice']}
                                          label={t('collectibles.fields.reservePrice.label')}
                                        >
                                          {collectible?.releases?.[0]?.setFinalPrice === 'TRUE' ? (
                                            <Price
                                              amount={collectible?.releases?.[0]?.finalPrice}
                                              inline
                                            />
                                          ) : (
                                            t('global.notSet')
                                          )}
                                        </Form.Item>
                                      </Col>
                                    </>
                                  )}
                                  {doListingType !== 'AUCTION' && (
                                    <Col span={24}>
                                      <Form.Item
                                        name={['releases', 0, 'startingPrice']}
                                        label={t(
                                          `collectibles.fields.${
                                            doListingType === 'FIXED_PRICE'
                                              ? 'sellingPrice'
                                              : 'minimumOfferPrice'
                                          }.label`
                                        )}
                                      >
                                        {doListingType === 'FIXED_PRICE' ? (
                                          <Price
                                            amount={collectible?.releases?.[0]?.startingPrice}
                                            inline
                                          />
                                        ) : collectible?.release?.[0]?.setStartingPrice ===
                                          'TRUE' ? (
                                          <Price
                                            amount={collectible?.releases?.[0]?.startingPrice}
                                            inline
                                          />
                                        ) : (
                                          t('global.notSet')
                                        )}
                                      </Form.Item>
                                    </Col>
                                  )}
                                  <Col span={24}>
                                    <Form.Item
                                      name={['releases', 0, 'releaseDate']}
                                      label={t('collectibles.fields.releaseDate.label')}
                                    >
                                      {moment(collectible?.releases?.[0].releaseDate).format(
                                        'D MMMM, YYYY'
                                      )}
                                    </Form.Item>
                                  </Col>
                                  {doListingType === 'AUCTION' && (
                                    <Col span={24}>
                                      <Form.Item
                                        name={['releases', 0, 'saleEndDate']}
                                        label={t('collectibles.fields.saleEndDate.label')}
                                      >
                                        {moment(collectible?.releases?.[0].saleEndDate).format(
                                          'D MMMM, YYYY'
                                        )}
                                      </Form.Item>
                                    </Col>
                                  )}
                                </Row>
                              </Col>
                              <Col span={24}>
                                <Form.Item
                                  name="promoEnabled"
                                  label={t('collectibles.fields.promo.option')}
                                >
                                  {collectible?.promoEnabled === 'TRUE'
                                    ? t('global.switch.yes')
                                    : t('global.switch.no')}
                                </Form.Item>
                              </Col>
                              <Col span={24}>
                                <Form.Item
                                  name="promoTitle"
                                  label={t('collectibles.fields.promo.title.label')}
                                >
                                  {promoEnabled
                                    ? collectible?.promoTitle
                                    : t('collectibles.fields.promo.option')}
                                </Form.Item>
                              </Col>
                              <Col span={24}>
                                <Form.Item
                                  name="promoDescription"
                                  label={t('collectibles.fields.promo.description.label')}
                                >
                                  {promoEnabled
                                    ? collectible?.promoDescription
                                    : t('collectibles.fields.promo.option')}
                                </Form.Item>
                              </Col>
                            </>
                          )}
                          <Col span={promoEnabled ? 24 : 12}>
                            <Title level={5} className="group-title">
                              {t('collectibles.fields.group.numberedEdition.heading')}
                            </Title>
                            <Row gutter={26}>
                              <Col span={24}>
                                <Form.Item
                                  name={['releases', promoEnabled ? 0 : 1, 'startingPrice']}
                                  label={t('collectibles.fields.sellingPrice.label')}
                                >
                                  <Price
                                    amount={
                                      collectible?.releases?.[promoEnabled ? 0 : 1]?.startingPrice
                                    }
                                    inline
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={24}>
                                <Form.Item
                                  name={[
                                    'releases',
                                    collectible?.promoEnabled === 'TRUE' ? 0 : 1,
                                    'releaseCount'
                                  ]}
                                  label={t('collectibles.fields.releaseQuantity.label')}
                                >
                                  {collectible?.releases?.[promoEnabled ? 0 : 1].releaseCount}
                                </Form.Item>
                              </Col>
                              <Col span={24}>
                                <Form.Item
                                  name={['releases', promoEnabled ? 0 : 1, 'releaseDate']}
                                  label={t('collectibles.fields.releaseDate.label')}
                                >
                                  {moment(
                                    collectible?.releases?.[promoEnabled ? 0 : 1].releaseDate
                                  ).format('D MMMM, YYYY HH:mm')}
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tab={t('collectibles.fields.group.unlockables')} key="4" forceRender>
                        <Row gutter={26}>
                          <Col span={24}>
                            <Form.Item
                              label={t('collectibles.fields.insideStory.label')}
                              name="insideStory"
                            >
                              <div
                                style={{ marginTop: 12 }}
                                dangerouslySetInnerHTML={{
                                  __html: collectible?.insideStory
                                    ? collectible?.insideStory
                                    : `<p>${t('global.notSet')}</p>`
                                }}
                              ></div>
                            </Form.Item>
                          </Col>
                          <Col span={12} style={{ display: promoEnabled ? 'none' : 'block' }}>
                            <Row gutter={26}>
                              <Col span={24}>
                                <Title level={5} className="group-title">
                                  {t('collectibles.fields.group.digitalOriginal.heading')}
                                </Title>
                              </Col>
                              <Col span={24}>
                                <Form.Item
                                  label={t('collectibles.fields.fanClubAccess.label')}
                                  name="fanClubAccessOriginal"
                                >
                                  {channels?.map((x) => {
                                    let channelName = '';
                                    if (collectible?.perksOriginal?.fanClubAccess.includes(x.id)) {
                                      channelName = (
                                        <>
                                          {x.name}
                                          <br />
                                        </>
                                      );
                                    }
                                    return <>{channelName}</>;
                                  })}
                                </Form.Item>
                              </Col>
                              <Col span={24}>
                                <Form.Item
                                  label={t('collectibles.fields.whatsIncluded.label')}
                                  name="whatsIncludedOriginal"
                                >
                                  <div
                                    style={{ marginTop: 12 }}
                                    dangerouslySetInnerHTML={{
                                      __html: collectible?.whatsIncludedOriginal
                                        ? collectible?.whatsIncludedOriginal
                                        : `<p>${t('global.notSet')}</p>`
                                    }}
                                  ></div>
                                </Form.Item>
                              </Col>
                              <Col span={24}>
                                <Form.Item label={t('collectibles.fields.perks.limited')}>
                                  {perksLimitedOriginal?.length > 0 ? (
                                    <ul style={{ padding: 0, listStylePosition: 'inside' }}>
                                      {perksLimitedOriginal.map((perk) => (
                                        <li>{perk}</li>
                                      ))}
                                    </ul>
                                  ) : (
                                    t('global.notSet')
                                  )}
                                </Form.Item>
                              </Col>
                              <Col span={24}>
                                <Form.Item label={t('collectibles.fields.perks.lifetime')}>
                                  {perksLifetimeOriginal?.length > 0 ? (
                                    <ul style={{ padding: 0, listStylePosition: 'inside' }}>
                                      {perksLifetimeOriginal.map((perk) => (
                                        <li>{perk}</li>
                                      ))}
                                    </ul>
                                  ) : (
                                    t('global.notSet')
                                  )}
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={promoEnabled ? 24 : 12}>
                            <Row gutter={26}>
                              <Col span={24}>
                                <Title level={5} className="group-title">
                                  {t('collectibles.fields.group.numberedEdition.heading')}
                                </Title>
                              </Col>
                              <Col span={24}>
                                <Form.Item
                                  label={t('collectibles.fields.fanClubAccess.label')}
                                  name="fanClubAccessNumbered"
                                >
                                  {channels?.map((x) => {
                                    let channelName = '';
                                    if (collectible?.perksNumbered?.fanClubAccess.includes(x.id)) {
                                      channelName = (
                                        <>
                                          {x.name}
                                          <br />
                                        </>
                                      );
                                    }
                                    return <>{channelName}</>;
                                  })}
                                </Form.Item>
                              </Col>
                              <Col span={24}>
                                <Form.Item
                                  label={t('collectibles.fields.whatsIncluded.label')}
                                  name="whatsIncludedNumbered"
                                >
                                  <div
                                    style={{ marginTop: 12 }}
                                    dangerouslySetInnerHTML={{
                                      __html: collectible?.whatsIncludedNumbered
                                        ? collectible?.whatsIncludedNumbered
                                        : `<p>${t('global.notSet')}</p>`
                                    }}
                                  ></div>
                                </Form.Item>
                              </Col>
                              <Col span={24}>
                                <Form.Item label={t('collectibles.fields.perks.limited')}>
                                  {perksLimitedNumbered?.length > 0 ? (
                                    <ul style={{ padding: 0, listStylePosition: 'inside' }}>
                                      {perksLimitedNumbered.map((perk) => (
                                        <li>{perk}</li>
                                      ))}
                                    </ul>
                                  ) : (
                                    t('global.notSet')
                                  )}
                                </Form.Item>
                              </Col>
                              <Col span={24}>
                                <Form.Item label={t('collectibles.fields.perks.lifetime')}>
                                  {perksLifetimeNumbered?.length > 0 ? (
                                    <ul style={{ padding: 0, listStylePosition: 'inside' }}>
                                      {perksLifetimeNumbered.map((perk) => (
                                        <li>{perk}</li>
                                      ))}
                                    </ul>
                                  ) : (
                                    t('global.notSet')
                                  )}
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tab={t('collectibles.fields.group.sharing')} key="5" forceRender>
                        <Row gutter={26}>
                          <Col span={24}>
                            <Form.Item
                              label={t('collectibles.fields.seo.title.label')}
                              name="metaTitle"
                            >
                              {collectible?.metaTitle || t('global.notSet')}
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              label={t('collectibles.fields.seo.description.label')}
                              name="metaDescription"
                            >
                              {collectible?.metaDescription || t('global.notSet')}
                            </Form.Item>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tab={t('collectibles.faq.title')} key="6" forceRender>
                        <Row gutter={26}>
                          <Col span={24}>
                            {collectible?.faq?.map((x) => (
                              <div className="admin-faq">
                                <div className="admin-faq-question">{x?.title}</div>
                                <div className="admin-faq-answer">{x.content}</div>
                              </div>
                            ))}
                          </Col>
                        </Row>
                      </TabPane>
                    </Tabs>
                  </Col>
                </Row>
              </Col>
              <Col span={24} xl={6} lg={8} className="collectible-form-right">
                <Sticky offsetTop={20} offsetBottom={20}>
                  <Form.Item>
                    <CollectibleCardV2
                      data={previewData}
                      assetUrl={assetUrl}
                      mediaThumbnail={mediaThumbnail}
                      isExternalStreaming={collectible?.category === 'SONG'}
                      externalStreamingValue={{
                        spotify: collectible?.streamUrl?.spotify,
                        appleMusic: collectible?.streamUrl?.appleMusic,
                        youtube: collectible?.streamUrl?.youtube
                      }}
                    />
                  </Form.Item>

                  <Form.Item>
                    <div className="preview-sales">
                      <Title style={{ marginBottom: 0 }} level={3}>
                        {t('collectibles.previewSales.heading')}
                      </Title>
                      <div className="preview-sales__list">
                        <div className="preview-sales__list__item">
                          <div className="preview-sales__list__item__label">
                            {t('collectibles.previewSales.primaryMarketSales')}
                          </div>
                        </div>
                        {process.env.REACT_APP_NAME !== 'patrons' && (
                          <div className="preview-sales__list__item">
                            <div className="preview-sales__list__item__label">
                              {t('collectibles.previewSales.digitalOriginal')}
                            </div>
                            <div className="preview-sales__list__item__value">
                              {t('collectibles.previewSales.subjectToAuction')}
                            </div>
                          </div>
                        )}
                        <div className="preview-sales__list__item">
                          <div className="preview-sales__list__item__label">
                            {t('collectibles.previewSales.numberedEdition')}
                          </div>
                          <div className="preview-sales__list__item__value">
                            <Price amount={numbEditionTotalPrice} inline /> (
                            {t('collectibles.previewSales.salesNumber', {
                              sales: countNumbEditionReleases
                            })}
                            )
                          </div>
                        </div>
                        <div className="preview-sales__list__item">
                          <div className="preview-sales__list__item__label">
                            {process.env.REACT_APP_NAME === 'patrons'
                              ? t('collectibles.previewSales.patrons.creatorRoyalties')
                              : t('collectibles.previewSales.creatorRoyalties')}{' '}
                            <Tooltip
                              title={
                                process.env.REACT_APP_NAME === 'patrons'
                                  ? t('collectibles.previewSales.patrons.creatorRoyaltiesInfo')
                                  : t('collectibles.previewSales.creatorRoyaltiesInfo')
                              }
                            >
                              <ExclamationCircleOutlined />
                            </Tooltip>
                          </div>
                          <div className="preview-sales__list__item__value">5%</div>
                        </div>
                        <div className="preview-sales__list__item">
                          <div className="preview-sales__list__item__label">
                            {t('collectibles.previewSales.platformFee')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form.Item>
                </Sticky>
              </Col>
            </Row>
          </Form>
        )}
      </DashboardContent>
      <CollectibleModal
        className="admin-collectible-modal"
        mode={modalData.mode}
        title={modalData?.title}
        showModal={modalData.status}
        onCancel={() => setModalData({ status: false, id: '', submit: null })}
        onSubmit={(data) => {
          modalData?.submit(data);
        }}
        loading={approvalLoading}
      />
      <Helmet>
        <title>{t('collectibles.add.title')}</title>
      </Helmet>
    </>
  );
}

export default DetailCollectible;
