import { Duration, format as formatDuration } from '@libs/utils/duration';
import { Button, Slider } from 'antd';
import { PauseOutlined } from '@ant-design/icons';
import { Storage } from 'aws-amplify';
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import MimeType, { createAssetUrl } from '@libs/utils/mimeType';
import PlayButton from '@assets/img/play-button.svg';

const Player = ({ src, controls = true, play, fallbackSrc, ...props }) => {
  const [error, setError] = useState(false);
  const [playerSrc, setPlayerSrc] = useState();
  const [playing, setPlaying] = useState(play || false);
  const [playerData, setPlayerData] = useState({
    played: 0,
    loaded: 0,
    duration: 0,
    seeking: false
  });

  useEffect(() => {
    async function fetchSource() {
      if (src && !src.startsWith('http')) {
        const contentType = MimeType(src);
        const s3Key = createAssetUrl(src, null, true);
        if (s3Key.startsWith('http')) {
          setPlayerSrc(s3Key);
        } else {
          const result = await Storage.get(src, { contentType: contentType });
          setPlayerSrc(result);
        }
      }
    }

    fetchSource();
  }, [src]);

  useEffect(() => {
    setPlaying(play);
  }, [play]);

  const handleProgress = (state) => {
    // We only want to update time slider if we are not currently seeking
    if (!playerData.seeking) {
      setPlayerData({ ...playerData, ...state });
    }
  };

  const handleDuration = (duration) => {
    setPlayerData({ ...playerData, duration });
  };

  const handleEnded = () => setPlaying(false);

  return (
    <div {...props}>
      <ReactPlayer
        width="100%"
        height="100%"
        playing={playing}
        url={playerSrc || src}
        onProgress={handleProgress}
        onDuration={handleDuration}
        onEnded={handleEnded}
        onError={() => {
          if (!error) {
            setPlayerSrc(fallbackSrc);
            setError(true);
          }
        }}
      />

      {controls && (
        <div className="player">
          <div className="player__controls">
            <Button
              className="player__controls__button"
              type="text"
              onClick={() => setPlaying(!playing)}
            >
              {playing ? (
                <PauseOutlined style={{ color: 'white' }} />
              ) : (
                <img style={{ paddingBottom: 3 }} src={PlayButton} alt="Play" />
              )}
            </Button>

            <Duration
              className="player__controls__duration"
              seconds={playerData.duration * playerData.played}
            />
            <Slider
              className="player__controls__seek"
              min={0}
              max={playerData.duration}
              value={playerData.duration * playerData.played}
              step={0.01}
              tipFormatter={formatDuration}
              trackStyle={{ backgroundColor: 'white' }}
              handleStyle={{ display: 'none' }}
            />
            <Duration seconds={playerData.duration} className="player__controls__duration" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Player;
