export const reportList = [
  {
    label: 'itsSpam',
    value: 'ITS_SPAM'
  },
  {
    label: 'nudityOrSexualActivity',
    value: 'NUDITY_OR_SEXUAL_ACTIVITY'
  },
  {
    label: 'hateSpeechOrSymbols',
    value: 'HATE_SPEECH_OR_SYMBOLS'
  },
  {
    label: 'violenceOrDangerousOrganizations',
    value: 'VIOLENCE_OR_DANGEROUS_ORGANIZATIONS'
  },
  {
    label: 'saleOfIllegalOrRegulatedGoods',
    value: 'SALE_OF_ILLEGAL_OR_REGULATED_GOODS'
  },
  {
    label: 'bullyingOrHarassment',
    value: 'BULLYING_OR_HARASSMENT'
  },
  {
    label: 'intellectualPropertyViolation',
    value: 'INTELLECTUAL_PROPERTY_VIOLATION'
  },
  {
    label: 'suicideOrSelfInjury',
    value: 'SUICIDE_OR_SELF_INJURY'
  },
  {
    label: 'eatingDisorders',
    value: 'EATING_DISORDERS'
  },
  {
    label: 'scamOrFraud',
    value: 'SCAM_OR_FRAUD'
  },
  {
    label: 'falseInformation',
    value: 'FALSE_INFORMATION'
  },
  {
    label: 'iJustDontLikeIt',
    value: 'I_JUST_DONT_LIKE_IT'
  }
];
