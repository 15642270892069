import cx from 'classnames';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Blur, Footer, Navigation, Container } from '@components';
import AuthLogin from './login';
import AuthForgot from './forgot';
import AuthResetPassword from './reset';
import AuthAcceptInvitation from './accept-invitation';
import AuthJoinMember from './join-member';

function Auth() {
  const { path } = useRouteMatch();

  const authClass = (path) => {
    switch (path) {
      case '/login':
        return 'auth--login';
      default:
        return null;
    }
  };

  return (
    <>
      <Blur className="auth-first-blur" />
      <Blur className="auth-second-blur" />
      <div className={cx('auth', authClass(path))}>
        <Navigation auth />
        <div className="auth-wrapper">
          <Container>
            <div className="auth-container">
              <Switch>
                <Route exact path="/" component={AuthLogin} />
                <Route exact path="/login" component={AuthLogin} />
                <Route exact path="/forgot-password" component={AuthForgot} />
                <Route exact path="/reset-password" component={AuthResetPassword} />
                <Route exact path="/accept-invitation/:id" component={AuthAcceptInvitation} />
                <Route exact path="/join-team/:id" component={AuthJoinMember} />
              </Switch>
            </div>
          </Container>
        </div>
        <Footer hideLogo />
      </div>
    </>
  );
}

export default Auth;
