import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback } from 'react';
import gql from '@libs/utils/gql';
import { listGalleryExperiences } from '@libs/custom-queries/gallery-experience';
import { updateGalleryExperience } from '@graphql/mutations';
import useToast from '@libs/utils/toast';
import { useAuth } from '@libs/contexts/auth';

export const useGalleryExperience = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [updateLoading, setUpdateLoading] = useState(false);
  const toast = useToast();

  const getData = async () => {
    setLoading(true);
    const params = { filter: { accountID: { eq: user?.accountID } } };
    try {
      const { data: result } = await gql(listGalleryExperiences, params);
      if (result?.listGalleryExperiences?.items) {
        setData(result?.listGalleryExperiences?.items[0]);
      }
    } catch (error) {
      const errorMessage = error.errors[0]?.errorType;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onUpdate = useCallback(
    async (payload) => {
      setUpdateLoading(true);

      try {
        await gql(
          updateGalleryExperience,
          {
            input: {
              id: payload.id,
              orientation: payload.orientation,
              heading: payload.heading,
              description: payload.description,
              qrPosition: payload.qrPosition,
              qrText: payload.qrText,
              logoPosition: payload.logoPosition
            }
          },
          {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }
        );

        toast(t('galleryExperience.successUpdate'), 'success');
      } catch (error) {
        const errorMessage = error.errors[0]?.message;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setUpdateLoading(false);
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (user?.accountID) {
      getData();
    }
  }, [user?.accountID]); // eslint-disable-line

  return {
    data,
    loading,
    updateLoading,
    getData,
    onUpdate
  };
};
