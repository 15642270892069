import { Redirect, Route } from 'react-router-dom';

import { useAuth } from '@libs/contexts/auth';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthenticated ? <Component {...props} /> : <Redirect to={{ pathname: '/' }} />;
      }}
    />
  );
};

export default ProtectedRoute;
