import FansincDetail from './fansinc';
import PatronsDetail from './patrons';

const Detail = () => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsDetail />;
  } else {
    return <FansincDetail />;
  }
};

export default Detail;
