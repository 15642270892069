import { useState, useEffect } from 'react';
import gql from '@libs/utils/gql';
import { searchInboxes } from '@libs/custom-queries/inbox';
import { readInbox, deleteInbox, readAllInbox, createInbox } from '@graphql/mutations';
import useToast from '@libs/utils/toast';
import { useAuth } from '@libs/contexts/auth';
import { useInboxParams } from '@libs/hooks/inbox/utils';

export const useInbox = () => {
  const [loading, setLoading] = useState(false);
  const [readLoading, setReadLoading] = useState(false);
  const [readAllLoading, setReadAllLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [nextPageToken, setNextPageToken] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const { query: params, onChangeFilter, onPaginate, onChangeSearch } = useInboxParams(10);
  const [data, setData] = useState([]);
  const toast = useToast();

  const getData = async () => {
    setLoading(true);

    try {
      const { data: result } = await gql(searchInboxes, params);
      if (result?.searchInboxes?.items?.length > 0) {
        setData(result?.searchInboxes?.items);
        setNextPageToken(result?.searchInboxes?.nextToken);
      } else {
        setData([]);
      }
    } catch (error) {
      const errorMessage = error?.errors[0]?.message;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [params]); //eslint-disable-line

  const onRead = async (payload) => {
    setReadLoading(true);

    try {
      await gql(
        readInbox,
        {
          input: payload
        },
        {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }
      );
      return true;
    } catch (error) {
      const errorMessage = error?.errors[0]?.message;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setReadLoading(false);
    }
  };

  const onReadAll = async () => {
    setReadAllLoading(true);

    try {
      await gql(
        readAllInbox,
        {
          input: { id: 'admin' }
        },
        {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }
      );
      return true;
    } catch (error) {
      const errorMessage = error?.errors[0]?.message;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setReadAllLoading(false);
    }
  };

  const changeFilter = (filter) => {
    setData([]);
    onChangeFilter(filter);
  };

  const changeSearch = (data) => {
    setData([]);
    onChangeSearch(data);
  };

  const onDelete = async (payload) => {
    setDeleteLoading(true);
    console.log(payload);

    try {
      await gql(
        deleteInbox,
        {
          input: payload
        },
        {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }
      );
      return true;
    } catch (error) {
      const errorMessage = error?.errors[0]?.message;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setDeleteLoading(false);
    }
  };

  const onCreate = async (payload) => {
    setCreateLoading(true);

    try {
      await gql(createInbox, {
        input: payload
      });
      return true;
    } catch (error) {
      const errorMessage = error?.errors[0]?.message;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setCreateLoading(false);
    }
  };

  return {
    loading,
    data,
    readLoading,
    deleteLoading,
    nextPageToken,
    readAllLoading,
    createLoading,
    onCreate,
    getData,
    onDelete,
    changeFilter,
    onPaginate,
    changeSearch,
    onReadAll,
    onRead
  };
};

export const useAdminUnreadInbox = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const { user } = useAuth();

  const getData = async () => {
    if (!user) {
      return;
    }
    setLoading(true);
    try {
      const params = {
        sort: { direction: 'desc', field: 'createdAt' },
        filter: {
          status: { eq: 'unread' }
        }
      };
      const { data: result } = await gql(searchInboxes, params);
      if (result?.searchInboxes?.items) {
        setTotal(result?.searchInboxes?.total);
        setData(result?.searchInboxes?.item);
      }
    } catch (error) {
      const errorMessage = error?.errors[0]?.message;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // TODO - implement pagination and filtering of read items
    if (user) {
      getData();
    }
  }, [user]); // eslint-disable-line

  return {
    loading,
    data,
    total,
    getData
  };
};
