import { useHistory } from 'react-router-dom';
import { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import config from '@/config';
import gql from '@libs/utils/gql';
import useToast from '@libs/utils/toast';
import { getAccountUser } from '@libs/custom-queries/organisation';
import { acceptInvitation } from '@graphql/mutations';
import { useAuth } from '@libs/contexts/auth';

export const useInvitation = (invitationID) => {
  const { getCurrentUser } = useAuth();
  const history = useHistory();
  const toast = useToast();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [invitationLoading, setInvitationLoading] = useState(false);

  const getData = async (invitationID) => {
    setLoading(true);
    try {
      const { data: res } = await gql(getAccountUser, { id: invitationID });
      if (res?.getAccountUser) {
        setData(res.getAccountUser);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (invitationID) {
      getData(invitationID);
      window.localStorage.setItem(config.storage.INVITATION_ID, invitationID);
    }
  }, [invitationID]); // eslint-disable-line react-hooks/exhaustive-deps

  const onAcceptInvitation = useCallback(async (invitationID) => {
    setInvitationLoading(true);
    const payload = { input: { invitationID } };
    try {
      await gql(acceptInvitation, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });

      await getCurrentUser();

      toast(t('auth.acceptInvite.success'), 'success');
      window.localStorage.removeItem(config.storage.INVITATION_ID);

      history.push('/collectibles');
    } catch (error) {
      const errorMessage = error.errors?.message;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setInvitationLoading(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading,
    data,
    invitationLoading,
    onAcceptInvitation
  };
};
