import { useState, useEffect, useCallback } from 'react';
import cx from 'classnames';
import Recaptcha from 'react-recaptcha';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Modal, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import useToast from '@libs/utils/toast';
import { useCreatorUser } from '@libs/hooks/user';
import { useUser } from '@libs/hooks/user';
import Lottie from 'react-lottie';
import Success from '@lotties/Success';
import { useCollection } from '@libs/hooks/collection';
import slugify from 'slugify';

import config from '@/config';

function ModalWaitinglist({ onShow, onClose }) {
  const [form] = Form.useForm();
  const toast = useToast();
  const { isUsernameAvailable } = useUser();
  const { isNameAvailable } = useCollection();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Success
  };
  const { t } = useTranslation();
  const [registerConfirm, setRegisterConfirm] = useState(false);
  const [captchaToken, setCaptchaToken] = useState();
  const [isVerified, setIsVerified] = useState();
  const [usernameLength, setUsernameLength] = useState('0');
  const [type, setType] = useState('Public Gallery');
  const verifyCallback = async (response) => {
    if (response) {
      setCaptchaToken(response);
      setIsVerified(true);
    }
  };
  const { signUp, loading, success: successRegister } = useCreatorUser();
  const handleRegister = useCallback(
    async (values) => {
      if (isVerified) {
        await signUp({
          ...values,
          type: type === 'Artist' ? 'Artist' : 'Gallery',
          captchaToken: captchaToken,
          organization_name: type === 'Artist' ? values.username : values.organization_name
        });
      } else {
        toast(t('auth.verify'), 'error');
      }
    },
    [isVerified, captchaToken, type] //eslint-disable-line
  );
  useEffect(() => {
    if (successRegister === true) {
      setRegisterConfirm(true);
      onClose();
      form.resetFields();
    }
  }, [successRegister]); // eslint-disable-line

  return (
    <>
      <Modal
        className="patrons-modal"
        onCancel={onClose}
        visible={onShow}
        footer={null}
        width={600}
      >
        <div>
          <div className="patrons-modal__title">
            {t('components.modalWaitinglist.patrons.title')}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <div className="patrons-modal__description">
              {t('components.modalWaitinglist.patrons.description')}
            </div>
          </div>
          <Form
            className="patrons-modal__form patrons-modal__register"
            layout="vertical"
            onFinish={handleRegister}
            form={form}
          >
            <div className="patrons-modal__register__label">Register as</div>
            <div className="patrons-modal__register__interested">
              <div
                className={cx(
                  'patrons-modal__register__interested__option patrons-modal__register__interested__left',
                  type === 'Public Gallery' ? 'interested__active' : ''
                )}
                onClick={() => setType('Public Gallery')}
              >
                {t('components.modalWaitinglist.patrons.publicGallery')}
              </div>
              <div
                className={cx(
                  'patrons-modal__register__interested__option',
                  type === 'Private Gallery' ? 'interested__active' : ''
                )}
                onClick={() => setType('Private Gallery')}
              >
                {t('components.modalWaitinglist.patrons.privateGallery')}
              </div>
              <div
                className={cx(
                  'patrons-modal__register__interested__option patrons-modal__register__interested__right',
                  type === 'Artist' ? 'interested__active' : ''
                )}
                onClick={() => setType('Artist')}
              >
                {t('components.modalWaitinglist.patrons.artist')}
              </div>
            </div>
            {type === 'Artist' ? (
              <Form.Item
                name="username"
                label={t('auth.fields.patrons.username.label')}
                rules={[
                  { required: true, message: t('creators.fields.username.required') },
                  {
                    pattern: /^(?=[a-z0-9._]{0,999}$)(?!.*[_.]{2})[^_.].*[^_.]$/,
                    message: t('creators.fields.username.invalid')
                  },
                  {
                    min: 4,
                    message: t('creators.fields.username.minLength', { length: usernameLength })
                  },
                  {
                    max: 101,
                    message: t('creators.fields.username.maxLength', { length: usernameLength })
                  },
                  {
                    async validator(_, value) {
                      if (!value) {
                        return Promise.resolve();
                      }

                      const isAvailable = await isUsernameAvailable(value);

                      if (!isAvailable) {
                        return Promise.reject(
                          new Error(t('creators.fields.username.taken', { username: value }))
                        );
                      }

                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <Input
                  onChange={(e) => {
                    setUsernameLength(e.target.value.length.toString());
                  }}
                  placeholder={t('auth.fields.patrons.username.label')}
                />
              </Form.Item>
            ) : (
              <Form.Item
                name="organization_name"
                label={t('auth.fields.patrons.gallery.label')}
                rules={[
                  { required: true, message: t('auth.fields.patrons.gallery.required') },
                  { min: 4, message: t('collections.fields.name.minLength') },
                  { max: 32, message: t('collections.fields.name.maxLength') },
                  {
                    async validator(_, value) {
                      if (!value) {
                        return Promise.resolve();
                      }

                      const slug = slugify(value, {
                        lower: true,
                        remove: /[*+~%<>/;.(){}?,'"!:@#^|]/g
                      });

                      const isAvailable = await isNameAvailable(slug);

                      if (!isAvailable) {
                        return Promise.reject(
                          new Error(t('userSettings.fields.username.taken', { username: value }))
                        );
                      }

                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <Input placeholder={t('auth.fields.patrons.gallery.label')} />
              </Form.Item>
            )}
            <div className="patrons-modal__form__flex">
              <Form.Item
                name="firstName"
                label="First Name"
                className="patrons-modal__form__flex__item"
                rules={[
                  {
                    required: true,
                    message: t('auth.fields.patrons.firstName.required')
                  }
                ]}
              >
                <Input placeholder={t('auth.fields.patrons.firstName.placeholder')} />
              </Form.Item>
              <Form.Item
                name="lastName"
                label="Surname"
                className="patrons-modal__form__flex__item"
                rules={[
                  {
                    required: true,
                    message: t('auth.fields.patrons.surName.required')
                  }
                ]}
              >
                <Input placeholder={t('auth.fields.patrons.surName.placeholder')} />
              </Form.Item>
            </div>
            <Form.Item
              name="email"
              label="Email Address"
              rules={[
                { required: true, message: t('auth.fields.patrons.email.required') },
                {
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: t('auth.fields.patrons.email.type')
                }
              ]}
            >
              <Input placeholder={t('auth.fields.patrons.email.placeholder')} />
            </Form.Item>
            {/* <Form.Item
              name="phone"
              label="Phone Number"
              rules={[{ required: true, message: t('auth.fields.patrons.phone.required') }]}
            >
              <Input placeholder={t('auth.fields.patrons.phone.placeholder')} />
            </Form.Item> */}
            <Form.Item
              name="password"
              label="Password"
              rules={[
                { required: true, message: t('auth.fields.patrons.password.required') },
                {
                  pattern: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                  message: t('components.modalRegister.fields.password.strength')
                },
                { min: 8, message: t('components.modalRegister.fields.password.minLength') },
                { max: 64, message: t('components.modalRegister.fields.password.maxLength') }
              ]}
            >
              <Input.Password
                type="password"
                placeholder={t('auth.fields.patrons.password.placeholder')}
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              rules={[
                { required: true, message: t('auth.fields.patrons.confirmPassword.required') },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      t('components.modalRegister.fields.confirmPassword.notMatch')
                    );
                  }
                })
              ]}
            >
              <Input.Password
                type="password"
                placeholder={t('auth.fields.patrons.confirmPassword.placeholder')}
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Recaptcha
                sitekey={config.cookie.TOKEN_SITE_KEY_CAPTCHA}
                render="explicit"
                verifyCallback={verifyCallback}
              />
            </div>
            <div className="patrons-modal__button">
              <Button htmlType="submit" type="primary" loading={loading} disabled={loading}>
                {t('components.modalWaitinglist.patrons.signUp')}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
      <Modal
        className="patrons-modal"
        visible={registerConfirm}
        footer={null}
        width={600}
        onCancel={() => {
          setRegisterConfirm(false);
        }}
      >
        <div className="patrons-modal__success">
          <Lottie height={200} width={200} options={defaultOptions} />
          <div className="patrons-modal__success__title">{t('auth.success.title')}</div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <div className="patrons-modal__success__description">
              {t('auth.success.description')}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ModalWaitinglist;
