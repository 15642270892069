import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { DashboardContent } from '@components';

function Performance() {
  const { t } = useTranslation();
  return (
    <>
      <DashboardContent title={t('performance.title')}></DashboardContent>
      <Helmet>
        <title>{t('performance.title')}</title>
      </Helmet>
    </>
  );
}

export default Performance;
