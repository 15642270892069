import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Collapse, List, Statistic, Row, Col, Typography, Table, Input } from 'antd';
import { ContainerOutlined, EyeOutlined } from '@ant-design/icons';
import { useCollectible } from '@libs/hooks/collectible';
import { useReleaseByCollectible } from '@libs/hooks/release';
import { Avatar, DashboardContent, DisplayDate, Price, SerialItem } from '@components';

function CollectiblesDetail() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { Title } = Typography;
  const { Search } = Input;
  const { Panel } = Collapse;
  const { data: collectible } = useCollectible(id);
  const {
    total: releasesTotal,
    data: releases,
    getData: fetchReleases,
    loading: releasesLoading
  } = useReleaseByCollectible(id);
  const columns = [
    {
      title: t('collectibles.releases.list.releaseMobile'),
      dataIndex: 'releaseMobile',
      key: 'releaseMobile',
      responsive: ['xs']
    },
    {
      title: t('collectibles.releases.list.unitNumber'),
      dataIndex: 'unitNumber',
      key: 'unitNumber',
      responsive: ['sm']
    },
    {
      title: t('collectibles.releases.list.owner'),
      dataIndex: 'owner',
      key: 'owner',
      responsive: ['sm']
    },
    {
      title: t('collectibles.releases.list.lastPurchased'),
      dataIndex: 'lastPurchased',
      key: 'lastPurchased',
      responsive: ['sm']
    },
    {
      title: t('collectibles.releases.list.lastSold'),
      dataIndex: 'lastSold',
      key: 'lastSold',
      responsive: ['sm']
    },
    {
      title: t('collectibles.releases.list.price'),
      dataIndex: 'price',
      key: 'price',
      responsive: ['sm']
    }
  ];

  const handleSearch = async (data) => {
    await fetchReleases(data);
  };

  const dataSource =
    releasesLoading && releases === []
      ? []
      : releases.map((row, i) => ({
          key: i,
          releaseMobile: (
            <div className="mobile-row">
              <div className="mobile-row__info">
                <div className="mobile-row__info__name">
                  <SerialItem release={row} />
                </div>
              </div>
              <Collapse collapsible ghost className="datalist">
                <Panel header={t('collectibles.releases.list.expand')} key="1">
                  <List itemLayout="vertical">
                    <List.Item>
                      <List.Item.Meta
                        title={t('collectibles.releases.list.owner')}
                        description={
                          <div className="profile-avatar">
                            <Avatar user={row?.currentOwner} />
                            <div className="profile-avatar__info">
                              <div className="profile-avatar__info__name">{`${row?.currentOwner?.firstName} ${row?.currentOwner?.lastName}`}</div>
                            </div>
                          </div>
                        }
                      />
                      <List.Item.Meta
                        title={t('collectibles.releases.list.lastPurchased')}
                        description={
                          row.lastPurchasedAt ? <DisplayDate date={row.lastPurchasedAt} /> : '-'
                        }
                      />
                      <List.Item.Meta
                        title={t('collectibles.releases.list.lastSold')}
                        description={
                          row.currentPrice ? (
                            <Price amount={row.previousPrice || row.currentPrice} />
                          ) : (
                            '-'
                          )
                        }
                      />

                      <List.Item.Meta
                        title={t('collectibles.releases.list.price')}
                        description={row.currentPrice ? <Price amount={row.currentPrice} /> : '-'}
                      />
                    </List.Item>
                  </List>
                </Panel>
              </Collapse>
            </div>
          ),
          unitNumber: <SerialItem release={row} />,
          owner: (
            <div className="profile-avatar">
              <Avatar user={row?.currentOwner} />
              <div className="profile-avatar__info">
                <div className="profile-avatar__info__name">{`${row?.currentOwner?.firstName} ${row?.currentOwner?.lastName}`}</div>
              </div>
            </div>
          ),
          lastPurchased: row.lastPurchasedAt ? <DisplayDate date={row.lastPurchasedAt} /> : '-',
          lastSold: row.currentPrice ? (
            <Price amount={row.previousPrice || row.currentPrice} />
          ) : (
            '-'
          ),
          price: row.currentPrice ? <Price amount={row.currentPrice} /> : '-'
        }));
  return (
    <>
      <DashboardContent title={collectible?.title} backLink="/collectibles">
        <Row className="collectibles-detail__stats" gutter={16}>
          <Col lg={12} span={24}>
            <div className="collectibles-detail__stats__box">
              <Statistic
                title={t('collectibles.stats.views')}
                value={567}
                prefix={<EyeOutlined />}
              />
            </div>
          </Col>
          <Col lg={12} span={24}>
            <div className="collectibles-detail__stats__box">
              <Statistic
                title={t('collectibles.stats.releases')}
                value={releasesTotal}
                prefix={<ContainerOutlined />}
              />
            </div>
          </Col>
        </Row>

        <Row className="toolbar">
          <Col span={24} lg={12} className="toolbar__left">
            <Title className="collectibles-detail__title" level={2}>
              {t('collectibles.allReleases')}
            </Title>
          </Col>
          <Col span={24} lg={12} className="toolbar__right">
            <div className="toolbar-search">
              <Search
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
                placeholder={t('collectibles.releases.search')}
              />
            </div>
          </Col>
        </Row>
        <div className="manage-content">
          <div className="fans-table">
            <Table
              bordered
              loading={releasesLoading}
              dataSource={dataSource}
              columns={columns}
              pagination={false}
            />
          </div>
        </div>
      </DashboardContent>

      <Helmet>
        <title>{collectible?.title}</title>
      </Helmet>
    </>
  );
}

export default CollectiblesDetail;
