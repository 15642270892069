import config from '@/config';

const mimeType = (filename) => {
  if (!filename) {
    return false;
  }

  const filenameSplit = filename.split('.');
  // File extension always in last position
  const fileExt = filenameSplit[filenameSplit.length - 1];

  const extToMimes = {
    mp4: 'video/mp4',
    mp3: 'audio/mpeg',
    ogg: 'audio/ogg',
    wav: 'audio/wav',
    m3u8: 'video/m3u8'
  };

  if (extToMimes[fileExt]) {
    return extToMimes[fileExt];
  } else {
    return false;
  }
};

export const createAssetUrl = (src, size = 600, originalFile) => {
  const availableSize = [48, 200, 600, 800, 1080, 1600, 1920];
  const isAudioVideo = mimeType(src);
  const format = isAudioVideo && isAudioVideo.split('/')?.[0];

  if (originalFile) {
    return config.cdnUrl ? `${config.cdnUrl}/public/${src}` : src;
  }

  if (format === 'video') {
    let filename = src.substring(src.lastIndexOf('/') + 1);
    filename = filename.replace(/\.[^/.]+$/, '');
    return `https://${config.awsOutputVideo}/${filename}/${filename}.m3u8`;
  }

  if (!availableSize.includes(size) || isAudioVideo) {
    return src;
  }

  const resized = src
    .split('/')
    .map((x, i, arr) => (i === arr.length - 1 ? `${size}_${x}` : x))
    .join('/');

  return config.cdnUrl ? `${config.cdnUrl}/public/${resized}` : resized;
};

export default mimeType;
