import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import {
  Button,
  Col,
  Collapse,
  Input,
  List,
  Row,
  Table,
  Tag,
  Tooltip,
  Typography,
  Switch
} from 'antd';
import { Avatar, DashboardContent, DisplayDate, Link, Pagination } from '@components';

import { useCollectiblesByAccount } from '@libs/hooks/collectible';
import PrintCollectibles from './printQrCollectible';
import config from '@/config';

function Collectibles() {
  const { t } = useTranslation();

  const { Panel } = Collapse;
  const { Search } = Input;
  const { Text, Link: AntLink } = Typography;

  const columns = [
    {
      title: t('collectibles.list.collectible'),
      dataIndex: 'collectibleMobile',
      key: 'collectibleMobile',
      responsive: ['xs']
    },
    {
      title: t('collectibles.list.collectible'),
      dataIndex: 'collectible',
      key: 'collectible',
      responsive: ['sm']
    },
    {
      title: t('collectibles.list.status.column'),
      dataIndex: 'status',
      key: 'status',
      width: 230,
      responsive: ['md']
    },
    {
      title: t('collectibles.list.views.column'),
      dataIndex: 'views',
      key: 'views',
      width: 230,
      responsive: ['md']
    },
    {
      title:
        process.env.REACT_APP_NAME === 'patrons'
          ? t('collectibles.list.patrons.profile')
          : t('collectibles.list.profile'),
      dataIndex: 'profile',
      key: 'profile',
      responsive: ['lg']
    },
    {
      title: t('collectibles.list.featured'),
      dataIndex: 'featuredInProfile',
      key: 'featuredInProfile',
      width: 230,
      responsive: ['lg']
    },
    {
      title: t('collectibles.list.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 230,
      responsive: ['lg']
    }
  ];

  const collectibleTag = (status) => {
    switch (status) {
      case 'PUBLISHED':
        return <Tag className="success">{t('collectibles.list.status.live')}</Tag>;
      case 'IN_REVIEW':
        return (
          <Tooltip title={t('collectibles.list.status.inReview.tooltip')}>
            <Tag className="warning">{t('collectibles.list.status.inReview.tag')}</Tag>
          </Tooltip>
        );
      case 'NEED_REVISION':
        return <Tag className="error">{t('collectibles.list.status.revisions')}</Tag>;
      default:
        return <Tag>{t('collectibles.list.status.draft.tag')}</Tag>;
    }
  };
  const [resetPagination, setResetPagination] = useState(false);
  const {
    data,
    loading: dataLoading,
    nextPageToken,
    onUpdateFeatured,
    onSearch,
    onPaginate
  } = useCollectiblesByAccount();

  const handleSearch = async (data) => {
    setResetPagination(!resetPagination);
    onSearch(data);
  };

  const handleFeaturedChange = async (data, featuredInProfile) => {
    const payload = { ...data, featuredInProfile: featuredInProfile ? 'TRUE' : 'FALSE' };
    onUpdateFeatured(payload);
  };

  const dataSource =
    dataLoading && data === []
      ? []
      : data?.map((row, i) => ({
          key: `collectible-${i}`,
          collectibleMobile: (
            <div className="mobile-row">
              <div className="mobile-row__info">
                <div className="mobile-row__info__name">{row.title}</div>
                <div className="meta">
                  <span>
                    <Link to={`/collectibles/edit/${row.id}`}>
                      {t('collectibles.list.meta.edit')}
                    </Link>
                  </span>
                  {row.title && row.assetUrl && row.category && (
                    <span>
                      <AntLink
                        target="_blank"
                        href={`${config.fansUrl}/${row.creator.username}/${row.slug}${
                          row.status === 'PUBLISHED' ? '' : '?draft=1'
                        }`}
                      >
                        {row.status === 'PUBLISHED'
                          ? t('collectibles.list.meta.view')
                          : t('collectibles.list.meta.preview')}
                      </AntLink>
                    </span>
                  )}
                  <span>
                    <AntLink target="_blank" href="#">
                      {t('collectibles.list.meta.report')}
                    </AntLink>
                  </span>
                  {row.status === 'PUBLISHED' && (
                    <>
                      <span style={{ cursor: 'pointer' }}>
                        <PrintCollectibles collectible={row} />
                      </span>
                    </>
                  )}
                </div>
              </div>
              <Collapse collapsible ghost className="datalist">
                <Panel header={t('collectibles.list.expand')} key="1">
                  <List itemLayout="vertical">
                    <List.Item>
                      <List.Item.Meta
                        title={t('collectibles.list.status.column')}
                        description={collectibleTag(row.status)}
                      />
                      <List.Item.Meta
                        title={
                          process.env.REACT_APP_NAME === 'patrons'
                            ? t('collectibles.list.patrons.profile')
                            : t('collectibles.list.profile')
                        }
                        description={
                          <div className="avatar-name">
                            <Avatar user={row.creator} />
                            {`${
                              row?.creator?.displayName ||
                              row?.creator?.firstName + ' ' + row?.creator?.lastName
                            }`}
                          </div>
                        }
                      />
                      <List.Item.Meta
                        title={t('collectibles.list.featured')}
                        description={
                          <Switch
                            checkedChildren={t('global.switch.yes')}
                            unCheckedChildren={t('global.switch.no')}
                            defaultChecked={row.featuredInProfile === 'TRUE'}
                            onChange={(checked) => {
                              handleFeaturedChange(row, checked);
                            }}
                          />
                        }
                      />
                      <List.Item.Meta
                        title={t('collectibles.list.createdAt')}
                        description={<DisplayDate date={row.createdAt} />}
                      />
                    </List.Item>
                  </List>
                </Panel>
              </Collapse>
            </div>
          ),
          collectible: (
            <>
              <div className="title">
                {row.status === 'PUBLISHED' ? (
                  <Link to={`/collectibles/details/${row.id}`}>
                    <Text>{row.title}</Text>
                  </Link>
                ) : (
                  <Text>{row.title}</Text>
                )}
              </div>
              <div className="meta">
                <span>
                  <Link to={`/collectibles/edit/${row.id}`}>
                    {t('collectibles.list.meta.edit')}
                  </Link>
                </span>
                {row.title && row.assetUrl && row.category && (
                  <span>
                    <AntLink
                      target="_blank"
                      href={`${config.fansUrl}/${row.creator.username}/${row.slug}${
                        row.status === 'PUBLISHED' ? '' : '?draft=1'
                      }`}
                    >
                      {row.status === 'PUBLISHED'
                        ? t('collectibles.list.meta.view')
                        : t('collectibles.list.meta.preview')}
                    </AntLink>
                  </span>
                )}
                <span>
                  <AntLink target="_blank" href="#">
                    {t('collectibles.list.meta.report')}
                  </AntLink>
                </span>
                {row.status === 'PUBLISHED' && (
                  <>
                    <span style={{ cursor: 'pointer' }}>
                      <PrintCollectibles collectible={row} />
                    </span>
                  </>
                )}
              </div>
            </>
          ),
          status: collectibleTag(row.status),
          views: t('collectibles.list.views.content', { views: 1234 }),
          profile: (
            <div className="profile-avatar">
              <Avatar user={row.creator}></Avatar>
              <div className="profile-avatar__info">
                <div className="profile-avatar__info__name">{`${
                  row?.creator?.displayName ||
                  row?.creator?.firstName + ' ' + row?.creator?.lastName
                }`}</div>
              </div>
            </div>
          ),
          featuredInProfile:
            row.status !== 'IN_REVIEW' ? (
              <Switch
                checkedChildren={t('global.switch.yes')}
                unCheckedChildren={t('global.switch.no')}
                defaultChecked={row.featuredInProfile === 'TRUE'}
                onChange={(checked) => {
                  handleFeaturedChange(row, checked);
                }}
              />
            ) : null,
          createdAt: <DisplayDate date={row.createdAt} />
        }));

  return (
    <>
      <DashboardContent title={t('collectibles.title')}>
        <Row className="toolbar">
          <Col span={24} lg={12} className="toolbar__left">
            <Button type="primary">
              <Link to="/collectibles/edit">{t('collectibles.createNew')}</Link>
            </Button>
          </Col>
          <Col span={24} lg={12} className="toolbar__right">
            <div className="toolbar-search">
              <Search
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
                placeholder={t('collectibles.search')}
              />
            </div>
          </Col>
        </Row>
        <div className="manage-content">
          <div className="fans-table">
            <Table
              bordered
              loading={dataLoading}
              dataSource={dataSource}
              columns={columns}
              pagination={false}
            />
            <Pagination
              nextToken={nextPageToken}
              loading={dataLoading}
              resetPagination={resetPagination}
              fetchData={onPaginate}
            />
          </div>
        </div>
      </DashboardContent>
      <Helmet>
        <title>{t('collectibles.title')}</title>
      </Helmet>
    </>
  );
}

export default Collectibles;
