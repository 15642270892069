export const listCollectibles = /* GraphQL */ `
  query ListCollectiblesCustomQuery(
    $filter: ModelCollectibleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCollectibles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        type
        title
        category
        genre
        slug
        description
        assetUrl
        mediaThumbnail
        featured
        createdAt
        updatedAt
        maxNumberedEditions
        releases {
          tierType
          releaseDate
          saleEndDate
          releaseCount
          listingType
          startingPrice
          finalPrice
          preReleaseAvailability
          setStartingPrice
          setFinalPrice
        }
        creator {
          id
          owner
          email
          username
          firstName
          lastName
          bio
          verified
          joinDate
          createdAt
          updatedAt
          media {
            avatarUrl
            coverUrl
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const latestCollectible = /* GraphQL */ `
  query LatestCollectible(
    $type: CollectibleType
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCollectibleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    latestCollectible(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        type
        title
        category
        genre
        slug
        description
        assetUrl
        featured
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        maxNumberedEditions
        releases {
          tierType
          releaseDate
          saleEndDate
          releaseCount
          listingType
          startingPrice
          finalPrice
          preReleaseAvailability
          setStartingPrice
          setFinalPrice
        }
        creator {
          id
          owner
          email
          username
          firstName
          lastName
          bio
          tags
          verified
          joinDate
          createdAt
          updatedAt
          media {
            avatarUrl
            coverUrl
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const collectibleBySlug = /* GraphQL */ `
  query CollectibleBySlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelCollectibleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    collectibleBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        title
        category
        genre
        userID
        slug
        description
        assetUrl
        featured
        createdAt
        updatedAt
        maxNumberedEditions
        releases {
          tierType
          releaseDate
          saleEndDate
          releaseCount
          listingType
          startingPrice
          finalPrice
          preReleaseAvailability
          setStartingPrice
          setFinalPrice
        }
        creator {
          username
          firstName
          lastName
          bio
        }
      }
    }
  }
`;
export const collectibleByAccount = /* GraphQL */ `
  query CollectibleByAccount(
    $accountID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCollectibleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    collectibleByAccount(
      accountID: $accountID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        userID
        type
        category
        genre
        title
        slug
        description
        assetUrl
        mediaThumbnail
        featured
        featuredInProfile
        status
        createdAt
        updatedAt
        maxNumberedEditions
        releases {
          tierType
          releaseDate
          saleEndDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          preReleaseAvailability
          setStartingPrice
          setFinalPrice
        }
        _version
        _deleted
        _lastChangedAt
        owner
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          bio
          tags
          verified
          fanProfile
          emailVerified
          joinDate
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          media {
            avatarUrl
            coverUrl
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const searchCollectibles = /* GraphQL */ `
  query SearchCollectibles(
    $filter: SearchableCollectibleFilterInput
    $sort: SearchableCollectibleSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCollectibles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        accountID
        userID
        type
        category
        genre
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        metaDescription
        featured
        featuredInProfile
        status
        yearOfCreation
        createdAt
        updatedAt
        maxNumberedEditions
        releases {
          tierType
          releaseDate
          saleEndDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          preReleaseAvailability
          setStartingPrice
          setFinalPrice
        }
        _version
        _deleted
        _lastChangedAt
        owner
        creator {
          id
          owner
          accountID
          editors
          email
          username
          displayName
          firstName
          lastName
          bio
          tags
          verified
          fanProfile
          emailVerified
          joinDate
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          media {
            avatarUrl
            coverUrl
          }
        }
      }
      nextToken
      total
    }
  }
`;
export const collectibleByCategoryAndCreatedAt = /* GraphQL */ `
  query CollectibleByCategoryAndCreatedAt(
    $category: CollectibleCategory
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCollectibleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    collectibleByCategoryAndCreatedAt(
      category: $category
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        userID
        type
        category
        genre
        title
        slug
        description
        assetUrl
        featured
        createdAt
        updatedAt
        maxNumberedEditions
        releases {
          tierType
          releaseDate
          saleEndDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          preReleaseAvailability
          setStartingPrice
          setFinalPrice
        }
        owner
        creator {
          id
          owner
          email
          username
          firstName
          lastName
          bio
          tags
          verified
          joinDate
          createdAt
          updatedAt
          media {
            avatarUrl
            coverUrl
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const collectibleByCategoryAndLowestPrice = /* GraphQL */ `
  query CollectibleByCategoryAndLowestPrice(
    $category: CollectibleCategory
    $lowestPrice: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCollectibleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    collectibleByCategoryAndLowestPrice(
      category: $category
      lowestPrice: $lowestPrice
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        userID
        type
        category
        genre
        title
        slug
        description
        assetUrl
        featured
        createdAt
        updatedAt
        maxNumberedEditions
        releases {
          tierType
          releaseDate
          saleEndDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          preReleaseAvailability
          setStartingPrice
          setFinalPrice
        }
        owner
        creator {
          id
          owner
          email
          username
          firstName
          lastName
          bio
          tags
          verified
          joinDate
          createdAt
          updatedAt
          media {
            avatarUrl
            coverUrl
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const collectibleByTypeAndCreatedAt = /* GraphQL */ `
  query CollectibleByTypeAndCreatedAt(
    $type: CollectibleType
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCollectibleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    collectibleByTypeAndCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        userID
        type
        category
        genre
        title
        slug
        description
        assetUrl
        featured
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          saleEndDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          preReleaseAvailability
          setStartingPrice
          setFinalPrice
        }
        owner
        creator {
          id
          owner
          email
          username
          firstName
          lastName
          bio
          tags
          verified
          joinDate
          createdAt
          updatedAt
          media {
            avatarUrl
            coverUrl
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const collectibleByTypeAndLowestPrice = /* GraphQL */ `
  query CollectibleByTypeAndLowestPrice(
    $type: CollectibleType
    $lowestPrice: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCollectibleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    collectibleByTypeAndLowestPrice(
      type: $type
      lowestPrice: $lowestPrice
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        userID
        type
        category
        genre
        title
        slug
        description
        assetUrl
        featured
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          saleEndDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          preReleaseAvailability
          setStartingPrice
          setFinalPrice
        }
        owner
        creator {
          id
          owner
          email
          username
          firstName
          lastName
          bio
          tags
          verified
          joinDate
          createdAt
          updatedAt
          media {
            avatarUrl
            coverUrl
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getCollectible = /* GraphQL */ `
  query GetCollectible($id: ID!) {
    getCollectible(id: $id) {
      id
      accountID
      userID
      type
      category
      genre
      title
      slug
      collectionID
      viewCount
      description
      insideStory
      assetUrl
      mediaThumbnail
      featured
      featuredInProfile
      faq {
        key
        title
        content
      }
      maxNumberedEditions
      status
      yearOfCreation
      metaDescription
      metaTitle
      marketingText
      whatsIncludedOriginal
      whatsIncludedNumbered
      promoEnabled
      printable
      promoTitle
      width
      height
      smallWidth
      smallHeight
      largeWidth
      largeHeight
      originalFrame
      smallFrame
      smallPrint
      promoDescription
      searchableLowestPrice
      searchableHighestPrice
      perksOriginal {
        fanClubAccess
        limitedPerks
        lifetimePerks
      }
      perksNumbered {
        fanClubAccess
        limitedPerks
        lifetimePerks
      }
      history {
        status
        value
        date
      }
      streamExternal
      streamUrl {
        spotify
        appleMusic
        youtube
      }
      createdAt
      updatedAt
      releases {
        tierType
        releaseDate
        saleEndDate
        releaseCount
        soldCount
        listingType
        startingPrice
        finalPrice
        preReleaseAvailability
        availableForPreRelease
        setStartingPrice
        setFinalPrice
      }
      _version
      _deleted
      _lastChangedAt
      owner
      creator {
        id
        owner
        accountID
        editors
        email
        username
        displayName
        firstName
        lastName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
        }
        tags
        verified
        fanProfile
        emailVerified
        joinDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          bio
          tags
          verified
          fanProfile
          emailVerified
          joinDate
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;

export const collectibleLikeByCollectible = /* GraphQL */ `
  query CollectibleLikeByCollectible(
    $collectibleID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCollectibleLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    collectibleLikeByCollectible(
      collectibleID: $collectibleID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
    }
  }
`;
