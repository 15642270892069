export const searchEmailWaitlists = /* GraphQL */ `
  query SearchEmailWaitlists(
    $filter: SearchableEmailWaitlistFilterInput
    $sort: SearchableEmailWaitlistSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchEmailWaitlists(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        email
        firstName
        surName
        phone
        status
        interestedAs
        verified
        verifiedAt
      }
      nextToken
      total
    }
  }
`;
