import { useState, useEffect, useRef } from 'react';
import { createAssetUrl } from '@libs/utils/mimeType';

import ShowImage from './showImage';
import { FormGroup } from '@components';
import { Form, Switch, Row, Col, Input, Spin } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { generateQR } from './utils';
import { toPng } from 'html-to-image';

function FrameCollectible({
  setOriginalWidth,
  setOriginalHeight,
  smallWidth,
  smallHeight,
  originalFrame,
  setOriginalFrame,
  originalHeight,
  originalWidth,
  setSmallWidth,
  setSmallHeight,
  smallFrame,
  setSmallFrame,
  imageUrl,
  setDoPrintable,
  setDoSmallPrint,
  doPrintable,
  doSmallPrint,
  collectible,
  dimensionHeight,
  dimensionWidth
}) {
  const { t } = useTranslation();
  const smallPrint = [
    {
      name: '6" x 6"',
      value: 'GLOBAL-CAN-6X6',
      width: 6,
      height: 6
    },
    {
      name: '8" x 8"',
      value: 'GLOBAL-CAN-8X8',
      width: 8,
      height: 8
    },
    {
      name: '8" x 10"',
      value: 'GLOBAL-CAN-8X10',
      width: 8,
      height: 10
    },
    {
      name: '8" x 12"',
      value: 'GLOBAL-CAN-8X12',
      width: 8,
      height: 12
    },
    {
      name: '9" x 12"',
      value: 'GLOBAL-CAN-9X12',
      width: 9,
      height: 12
    },
    {
      name: '10" x 10"',
      value: 'GLOBAL-CAN-10X10',
      width: 10,
      height: 10
    },
    {
      name: '10" x 12"',
      value: 'GLOBAL-CAN-10X12',
      width: 10,
      height: 12
    },
    {
      name: '11" x 14"',
      value: 'GLOBAL-CAN-11X14',
      width: 11,
      height: 14
    },
    {
      name: '12" x 12"',
      value: 'GLOBAL-CAN-12X12',
      width: 12,
      height: 12
    },
    {
      name: '12" x 16"',
      value: 'GLOBAL-CAN-12X16',
      width: 14,
      height: 14
    },
    {
      name: '14" x 14"',
      value: 'GLOBAL-CAN-14X14',
      width: 14,
      height: 14
    },
    {
      name: '16" x 16"',
      value: 'GLOBAL-CAN-16X16',
      width: 16,
      height: 16
    }
  ];

  const bigPrint = [
    {
      name: '10" x 30"',
      value: 'GLOBAL-CAN-10X30',
      width: 10,
      height: 30
    },
    {
      name: '12" x 18"',
      value: 'GLOBAL-CAN-12X18',
      width: 12,
      height: 18
    },
    {
      name: '12" x 24"',
      value: 'GLOBAL-CAN-12X24',
      width: 12,
      height: 24
    },
    {
      name: '12" x 36"',
      value: 'GLOBAL-CAN-12X36',
      width: 12,
      height: 36
    },
    {
      name: '15" x 30"',
      value: 'GLOBAL-CAN-15X30',
      width: 15,
      height: 30
    },
    {
      name: '16" x 20"',
      value: 'GLOBAL-CAN-16X20',
      width: 16,
      height: 20
    },
    {
      name: '16" x 32"',
      value: 'GLOBAL-CAN-16X32',
      width: 16,
      height: 32
    },
    {
      name: '18" x 18"',
      value: 'GLOBAL-CAN-18X18',
      width: 18,
      height: 18
    },
    {
      name: '18" x 24"',
      value: 'GLOBAL-CAN-18X24',
      width: 18,
      height: 24
    },
    {
      name: '18" x 36"',
      value: 'GLOBAL-CAN-18X36',
      width: 18,
      height: 36
    },
    {
      name: '20" x 20"',
      value: 'GLOBAL-CAN-20X20',
      width: 20,
      height: 20
    },
    {
      name: '20" x 24"',
      value: 'GLOBAL-CAN-20X24',
      width: 20,
      height: 24
    },
    {
      name: '20" x 28"',
      value: 'GLOBAL-CAN-20X28',
      width: 20,
      height: 28
    },
    {
      name: '20" x 30"',
      value: 'GLOBAL-CAN-20X30',
      width: 20,
      height: 30
    },
    {
      name: '20" x 40"',
      value: 'GLOBAL-CAN-20X40',
      width: 20,
      height: 40
    },
    {
      name: '20" x 60"',
      value: 'GLOBAL-CAN-20X60',
      width: 20,
      height: 60
    },
    {
      name: '24" x 24"',
      value: 'GLOBAL-CAN-24X24',
      width: 24,
      height: 24
    },
    {
      name: '24" x 30"',
      value: 'GLOBAL-CAN-24X30',
      width: 24,
      height: 30
    },
    {
      name: '24" x 32"',
      value: 'GLOBAL-CAN-24X32',
      width: 24,
      height: 32
    },
    {
      name: '24" x 36"',
      value: 'GLOBAL-CAN-24X36',
      width: 24,
      height: 36
    },
    {
      name: '24" x 48"',
      value: 'GLOBAL-CAN-24X48',
      width: 24,
      height: 48
    },
    {
      name: '28" x 28"',
      value: 'GLOBAL-CAN-28X28',
      width: 28,
      height: 28
    },
    {
      name: '28" x 40"',
      value: 'GLOBAL-CAN-28X40',
      width: 28,
      height: 40
    },
    {
      name: '30" x 30"',
      value: 'GLOBAL-CAN-30X30',
      width: 30,
      height: 30
    },
    {
      name: '30" x 40"',
      value: 'GLOBAL-CAN-30X40',
      width: 30,
      height: 40
    },
    {
      name: '30" x 60"',
      value: 'GLOBAL-CAN-30X60',
      width: 30,
      height: 60
    },
    {
      name: '32" x 32"',
      value: 'GLOBAL-CAN-32X32',
      width: 32,
      height: 32
    },
    {
      name: '36" x 36"',
      value: 'GLOBAL-CAN-36X36',
      width: 36,
      height: 36
    },
    {
      name: '36" x 48"',
      value: 'GLOBAL-CAN-36X48',
      width: 36,
      height: 48
    },
    {
      name: '36" x 60"',
      value: 'GLOBAL-CAN-36X60',
      width: 36,
      height: 60
    },
    {
      name: '40" x 40"',
      value: 'GLOBAL-CAN-40X40',
      width: 40,
      height: 40
    },
    {
      name: '40" x 60"',
      value: 'GLOBAL-CAN-40X60',
      width: 40,
      height: 60
    }
  ];

  const [image, setImage] = useState();

  const onChangeOriginalWidth = (value) => {
    setOriginalWidth(value);
  };

  const onChangeOriginalHeight = (value) => {
    setOriginalHeight(value);
  };

  const onChangeOriginalFrame = (value) => {
    setOriginalFrame(value);
  };

  const onChangeSmallWidth = (value) => {
    setSmallWidth(value);
  };

  const onChangeSmallHeight = (value) => {
    setSmallHeight(value);
  };

  const onChangeSmallFrame = (value) => {
    setSmallFrame(value);
  };

  const [qrCode, setQrCode] = useState(null);
  const [barcodeUri, setBarcodeUri] = useState(null);
  const [cmWidth, setCmWidth] = useState(0);
  const [cmHeight, setCmHeight] = useState(0);
  const [certificateLoading, setCertificateLoading] = useState(true);

  useEffect(() => {
    if (dimensionWidth && dimensionHeight) {
      setCmWidth(Number(dimensionWidth));
      setCmHeight(Number(dimensionHeight));
    }
  }, [dimensionWidth, dimensionHeight]);

  useEffect(() => {
    async function createBarcode() {
      const qr = await generateQR(
        `patrons.art/${collectible?.creator?.username}/${collectible?.slug}`
      );
      setQrCode(qr);
    }
    createBarcode();
  }, [collectible]);

  useEffect(() => {
    async function certificate() {
      if (qrCode && doPrintable) {
        setCertificateLoading(true);
        const dataUrl = await toPng(barcodeRef.current);
        setBarcodeUri(dataUrl);
        setCertificateLoading(false);
      }
    }
    certificate();
  }, [qrCode, doPrintable, collectible]);

  const barcodeRef = useRef();

  const Separator = () => {
    return <div className="print-frame__separator"></div>;
  };
  useEffect(() => {
    async function fetchImage() {
      if (imageUrl && !imageUrl.startsWith('http')) {
        const s3Key = createAssetUrl(imageUrl?.replace('temps/', ''), 1080);
        if (s3Key.startsWith('http')) {
          setImage(s3Key);
        } else {
          const result = await Storage.get(s3Key, { contentType: 'image/png' });
          setImage(result);
        }
      }
    }

    fetchImage();
  }, [imageUrl]);

  return (
    <>
      {imageUrl ? (
        <div className="print-frame__form">
          <Form.Item label={t('collectibles.fields.printable.label')} name="printable">
            <Switch
              checkedChildren={t('global.switch.yes')}
              unCheckedChildren={t('global.switch.no')}
              checked={doPrintable}
              onChange={(checked) => {
                setDoPrintable(checked);
              }}
            />
          </Form.Item>
          <Separator />
          <FormGroup heading="Original Artwork Size">
            <Row gutter={26}>
              <Col span={24} lg={12}>
                <div className="print-frame__top">
                  <Form.Item
                    label={t('collectibles.fields.width.label')}
                    name="width"
                    rules={[
                      {
                        required: true,
                        message: t('collectibles.fields.width.required')
                      }
                    ]}
                  >
                    <Input
                      suffix="Inch"
                      type="number"
                      onChange={(e) => setCmWidth(e.target.value)}
                      placeholder={t('collectibles.fields.width.label')}
                      className="block"
                    />
                  </Form.Item>
                </div>
                <div style={{ marginBottom: '30px' }} className="print-frame__text-cm">
                  {(cmWidth * 2.54).toFixed(1)} cm
                </div>
              </Col>
              <Col span={24} lg={12}>
                <div className="print-frame__top">
                  <Form.Item
                    label={t('collectibles.fields.height.label')}
                    name="height"
                    onChange={(e) => setCmHeight(e.target.value)}
                    rules={[
                      {
                        required: true,
                        message: t('collectibles.fields.height.required')
                      }
                    ]}
                  >
                    <Input
                      suffix="Inch"
                      type="number"
                      placeholder={t('collectibles.fields.height.label')}
                      className="block"
                    />
                  </Form.Item>
                </div>
                <div style={{ marginBottom: '30px' }} className="print-frame__text-cm">
                  {(cmHeight * 2.54).toFixed(1)} cm
                </div>
              </Col>
            </Row>
          </FormGroup>
          {doPrintable && (
            <>
              <Separator style={{ marginTop: '10px' }} />
              <ShowImage
                image={image}
                height={originalHeight}
                width={originalWidth}
                frame={originalFrame}
                setWidth={onChangeOriginalWidth}
                setHeight={onChangeOriginalHeight}
                setFrame={onChangeOriginalFrame}
                optionFrame={bigPrint}
                padding="30px"
                groupName={t('collectibles.fields.printable.largeLabel')}
              />
              <Separator />
              <Form.Item label={t('collectibles.fields.printable.smallPrint')} name="smallPrint">
                <Switch
                  checkedChildren={t('global.switch.yes')}
                  unCheckedChildren={t('global.switch.no')}
                  checked={doSmallPrint}
                  onChange={(checked) => {
                    setDoSmallPrint(checked);
                  }}
                />
              </Form.Item>
              {doSmallPrint && (
                <ShowImage
                  image={image}
                  height={smallHeight}
                  width={smallWidth}
                  frame={smallFrame}
                  setWidth={onChangeSmallWidth}
                  setHeight={onChangeSmallHeight}
                  setFrame={onChangeSmallFrame}
                  optionFrame={smallPrint}
                  padding="18px"
                  groupName={t('collectibles.fields.printable.smallLabel')}
                />
              )}
              {doPrintable && (
                <>
                  <Separator />
                  <Row gutter={26} style={{ marginBottom: '30px' }}>
                    <Col span={24} lg={6}>
                      <div className="print-frame__barcode__label">
                        {t('collectibles.fields.printable.certificate')}
                      </div>
                    </Col>
                    <Col span={24} lg={18}>
                      <div className="print-frame__wrapper">
                        {barcodeUri && !certificateLoading ? (
                          <div className="print-frame__uri">
                            <img src={barcodeUri} alt="" />
                          </div>
                        ) : (
                          <Spin />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <div className="print-frame__barcode__download">
                    <div className="print-frame__barcode__download__wrapper">
                      <div
                        id="barcode-main-image"
                        ref={barcodeRef}
                        className="print-frame__barcode__download__content"
                      >
                        <div className="print-frame__barcode__download__content__wrapper">
                          <div className="print-frame__barcode__download__content__right">
                            <img src={qrCode} alt="" />
                          </div>
                          <div className="print-frame__barcode__download__content__left">
                            <div className="print-frame__barcode__download__content__title">
                              {collectible?.title || 'Collectible Title'}
                            </div>
                            <div className="print-frame__barcode__download__content__description">
                              <Trans i18nKey="collectibles.fields.printable.collectibleDescription">
                                <span className="print-frame__barcode__download__content__description__medium">
                                  {{
                                    creatorName: collectible?.creator?.displayName || 'Creator Name'
                                  }}
                                </span>
                              </Trans>
                            </div>
                            <div className="print-frame__barcode__download__content__year">
                              <Trans i18nKey="collectibles.fields.printable.year">
                                {{ year: new Date().getFullYear() }}
                              </Trans>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      ) : (
        <div>
          <div>{t('collectibles.printAlert')}</div>
        </div>
      )}
    </>
  );
}

export default FrameCollectible;
