import { faker } from '@faker-js/faker';
import { genres } from '@pages/collectibles/utils';
import gql from '@libs/utils/gql';
import { collectibleBySlug } from '@graphql/queries';

const categories = [
  { name: 'Artwork', value: 'ARTWORK' },
  { name: 'Photograph', value: 'PHOTOGRAPH' }
]

const isSlugAvailable = async (slug) => {
  if (!slug) {
    return;
  }

  try {
    const { data: res } = await gql(collectibleBySlug, { slug });
    const isExists = !!res?.collectibleBySlug?.items?.length;
    return !isExists;
  } catch (err) {
    return false;
  }
};

export const generateCollectibleData = async ({ creators }) => {
  const userID = creators[faker.datatype.number({
    'min': 0,
    'max': creators.length < 1
  })].value;
  const title = faker.commerce.productName();

  let slugIsAvailable = false;
  let slug = faker.helpers.slugify(faker.datatype.string(10)).toLowerCase();
  while (!slugIsAvailable) {
    const res = await isSlugAvailable(slug)
    if (res && slug.match(/^[a-z0-9]+(?:-[a-z0-9]+)*$/)) {
      slugIsAvailable = true;
    } else {
      slug = faker.helpers.slugify(faker.datatype.string(10)).toLowerCase()
    }
  }

  const selectedCategory = categories[faker.datatype.number({
    'min': 0,
    'max': categories.length - 1
  })].value;

  const genre = [
    genres[faker.datatype.number({
      'min': 0,
      'max': genres.length - 1
    })].value
  ];
  const featuredArtist = [];

  let loopDesc = true;
  let description = "";
  while (loopDesc) {
    description = faker.commerce.productDescription();
    if (description.length > 49) loopDesc = false;
  }
  const insideStory = "";

  const assetUrl = faker.image.lorempicsum.imageUrl(800, 600, false, null, slug);
  const mediaThumbnail = faker.image.lorempicsum.imageUrl(500, 500, false, null, slug);
  const collectionIDs = [];

  const featuredInProfile = faker.datatype.boolean();

  const whatsIncludedOriginal = "";
  const whatsIncludedNumbered = "";
  const metaDescription = "By clicking \"Submit for review\", you agree to the FANS.INC Terms and Privacy Policy. Once approved, this collectible will be published on FANS.INC.";

  const maxNumberedEditions = faker.datatype.number({
    min: 1,
    max: 10
  });

  const price = faker.datatype.number({ min: 1, max: 10 });

  const releases = [
    {
      "tierType": "DIGITAL_ORIGINAL",
      "listingType": "FIXED_PRICE",
      "releaseCount": 1,
      "startingPrice": price,
      "releaseDate": faker.date.soon(),
      "saleEndDate": faker.date.soon(30),
      "preReleaseAvailability": 0,
      "availableForPreRelease": "FALSE",
      "setStartingPrice": "FALSE",
      "setFinalPrice": "FALSE",
      "finalPrice": price
    },
    {
      "releaseCount": maxNumberedEditions,
      "tierType": "NUMBERED_EDITION",
      "availableForPreRelease": "TRUE",
      "listingType": "FIXED_PRICE",
      "startingPrice": price,
      "releaseDate": faker.date.soon(),
      "saleEndDate": faker.date.soon(30),
      "preReleaseAvailability": 0,
      "setStartingPrice": "FALSE",
      "setFinalPrice": "FALSE",
      "finalPrice": price
    }
  ];

  const submitForReview = true;

  const result = {
    userID,
    title,
    slug,
    selectedCategory,
    genre,
    featuredArtist,
    description,
    insideStory,
    assetUrl,
    mediaThumbnail,
    collectionIDs,
    featuredInProfile,
    whatsIncludedOriginal,
    whatsIncludedNumbered,
    metaDescription,
    maxNumberedEditions,
    releases,
    submitForReview
  }

  return result;
}