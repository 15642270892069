import { useTranslation } from 'react-i18next';
import { DashboardContent } from '@components';

const UnauthorizedAccess = () => {
  const { t } = useTranslation();
  return (
    <DashboardContent title={t('components.unauthorizedAccess.title')}>
      {t('components.unauthorizedAccess.body')}
    </DashboardContent>
  );
};

export default UnauthorizedAccess;
