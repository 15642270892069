import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { DashboardContent } from '@components';

import { useAccount } from '@libs/hooks/account';
import { useFanUser } from '@libs/hooks/user';
import { useParams } from 'react-router';

import { useSearchOrganization } from '@libs/hooks/organisation';

function DummyCreateOrganization() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: managers, loading: managersLoading } = useFanUser();
  const { onUpdate, updateLoading } = useAccount();
  const { data, loading } = useSearchOrganization(id);

  const [form] = Form.useForm();

  const { Option } = Select;
  const { TextArea } = Input;

  const onSubmit = (dataForm) => {
    const payload = {
      ...dataForm,
      id: data?.id,
      phone: data.phone,
      website: data.website,
      country: data.country,
      city: data.city,
      state: data.state,
      postcode: data.postcode
    };
    onUpdate(payload);
  };

  useEffect(() => {
    form.setFieldsValue({
      owner: data?.owner,
      name: data?.name,
      legalName: data?.legalName,
      address: data?.address
    });
  }, [data]); // eslint-disable-line

  return (
    <>
      <DashboardContent title={data?.name} backLink={`/admin/organization/${id}`}>
        {loading ? null : (
          <Form
            form={form}
            onFinish={onSubmit}
            layout="vertical"
            className="admin-form"
            initialValues={{
              owner: data?.owner,
              name: data?.name,
              legalName: data?.legalName,
              address: data?.address
            }}
          >
            <Form.Item
              label={t('organizations.form.owner')}
              name="owner"
              rules={[{ required: true, message: t('organizations.form.required.owner') }]}
            >
              <Select
                placeholder={t('organizations.form.placeholder.owner')}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                loading={managersLoading}
                defaultValue={data?.owner}
              >
                {managers &&
                  managers.map((manager) => (
                    <Option key={`manager-${manager.id}`} value={manager.id}>
                      {`${manager.firstName} ${manager.lastName}`}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={t('organizations.form.team')}
              name="name"
              rules={[{ required: true, message: t('organizations.form.required.team') }]}
            >
              <Input
                placeholder={t('organizations.form.placeholder.team')}
                defaultValue={data?.name}
              />
            </Form.Item>
            <Form.Item
              label={t('organizations.form.legal')}
              name="legalName"
              rules={[{ required: true, message: t('organizations.form.required.legal') }]}
            >
              <Input
                placeholder={t('organizations.form.placeholder.legal')}
                defaultValue={data?.legalName}
              />
            </Form.Item>
            <Form.Item
              label={t('organizations.form.address')}
              name="address"
              rules={[{ required: true, message: t('organizations.form.required.address') }]}
            >
              <TextArea
                rows={4}
                placeholder={t('organizations.form.placeholder.address')}
                defaultValue={data?.address}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={updateLoading}>
                Save
              </Button>
            </Form.Item>
          </Form>
        )}
      </DashboardContent>
      <Helmet>
        <title>{data?.name}</title>
      </Helmet>
    </>
  );
}

export default DummyCreateOrganization;
