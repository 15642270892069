import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Empty, Menu, Row, Col, Button, Dropdown } from 'antd';
import { DashboardContent, Pagination, NotificationMenuItem } from '@components';
import { FilterFilled } from '@ant-design/icons';
import { useAdminNotification, useUpdateReadNotification } from '@libs/hooks/notification';

function AdminNotification() {
  const { t } = useTranslation();
  const [resetPagination, setResetPagination] = useState(false);
  const {
    data: notifications,
    nextPageToken: nextNotificationToken,
    loading,
    onPaginate,
    changeFilter
  } = useAdminNotification();
  const { onUpdateNotification, updateLoading } = useUpdateReadNotification();
  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          changeFilter('All');
          setResetPagination(!resetPagination);
        }}
      >
        {t('components.notificationMenu.filters.all')}
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          changeFilter('Unread');
          setResetPagination(!resetPagination);
        }}
      >
        {t('components.notificationMenu.filters.unread')}
      </Menu.Item>
    </Menu>
  );

  const onUpdate = async () => {
    const data = {
      itemType: 'admin'
    };
    await onUpdateNotification(data);
  };
  return (
    <>
      <DashboardContent title={t('notifications.title')}></DashboardContent>
      <Helmet>
        <title>{t('notifications.title')}</title>
      </Helmet>
      <Row className="notification-admin-header">
        <Col className="notification-admin-header-left" span={12}>
          <Button
            onClick={() => onUpdate()}
            type="primary"
            loading={updateLoading}
            className="notification-admin-header-right-readAll"
          >
            {t('components.notificationMenu.buttons.markAllAsRead')}
          </Button>
        </Col>
        <Col className="notification-admin-header-right" span={12}>
          <Dropdown overlay={menu} placement="bottomLeft" arrow>
            <Button
              className="notification-admin-header-right-button"
              loading={loading}
              type="primary"
            >
              {t('components.notificationMenu.buttons.filter')}
              <FilterFilled />
            </Button>
          </Dropdown>
        </Col>
      </Row>
      {notifications?.length > 0 ? (
        notifications.map(function (notification, i) {
          return <NotificationMenuItem updateData key={i} index={i} notification={notification} />;
        })
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
      <Pagination
        nextToken={nextNotificationToken}
        loading={loading}
        fetchData={onPaginate}
        reset={resetPagination}
      />
    </>
  );
}

export default AdminNotification;
