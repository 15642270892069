import { Component } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

class RichTextEditor extends Component {
  handleOnChange = (html, delta, source, editor) => {
    if (source === 'user') {
      this.props.onChange(editor);
    }
  };

  handleOnBlur = (range, source, editor) => {
    if (source === 'user') {
      this.props.onBlur(editor);
    }
  };

  render() {
    const {
      defaultValue = '',
      value,
      isFanClub = false,
      placeholder,
      disabled = false
    } = this.props;
    if (isFanClub) {
      return (
        <ReactQuill
          readOnly={disabled}
          className="ant-input"
          theme="snow"
          modules={RichTextEditor.modules}
          value={value}
          placeholder={placeholder}
          onChange={this.handleOnChange}
          onBlur={this.handleOnBlur}
        />
      );
    }
    return (
      <ReactQuill
        readOnly={disabled}
        className="ant-input"
        theme="snow"
        modules={RichTextEditor.modules}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={this.handleOnChange}
        onBlur={this.handleOnBlur}
      />
    );
  }
}

RichTextEditor.modules = {
  toolbar: {
    container: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image', 'video']
    ],
    handlers: {
      image: imageHandler
    }
  }
};

function imageHandler() {
  const tooltip = this.quill.theme.tooltip;
  const originalSave = tooltip.save;
  const originalHide = tooltip.hide;

  tooltip.save = function () {
    const range = this.quill.getSelection(true);
    const value = this.textbox.value;
    if (value) {
      this.quill.insertEmbed(range.index, 'image', value, 'user');
    }
  };
  // Called on hide and save.
  tooltip.hide = function () {
    tooltip.save = originalSave;
    tooltip.hide = originalHide;
    tooltip.hide();
  };
  tooltip.edit('image');
  tooltip.textbox.placeholder = 'Embed URL';
  tooltip.title = 'Embed URL';
}

export default RichTextEditor;
