import { Helmet } from 'react-helmet';
import { DashboardContent } from '@components';
import { useTranslation } from 'react-i18next';
import { Form, Button, Radio, Input, Spin } from 'antd';
import { useEffect, useState, useCallback } from 'react';
import cx from 'classnames';
import { ReactComponent as Logo } from '@assets/svg/qrLogo.svg';
import { useGalleryExperience } from '@libs/hooks/gallery-experience';
import QRCode from 'react-qr-code';
function GalleryExperience() {
  const { data, onUpdate, updateLoading } = useGalleryExperience();
  const { t } = useTranslation();
  const { TextArea } = Input;
  const [orientation, setOrientation] = useState(data?.orientation);
  const [logoPosition, setLogoPosition] = useState(data?.logoPosition);
  const [qrPosition, setQrPosition] = useState(data?.qrPosition);
  const [heading, setHeading] = useState(data?.heading);
  const [description, setDescription] = useState(data?.description);
  const [qrText, setQrText] = useState(data?.qrText);
  const [wrapperStyle, setWrapperStyle] = useState({
    width: '314px'
  });
  const [sectionStyle, setSectionStyle] = useState({
    display: 'block'
  });
  useEffect(() => {
    if (data) {
      setOrientation(data?.orientation);
      setHeading(data?.heading);
      setLogoPosition(data?.logoPosition);
      setDescription(data?.description);
      setQrText(data?.qrText);
      setQrPosition(data?.qrPosition);
      if (data?.orientation === 'portrait') {
        setWrapperStyle({
          width: '314px'
        });
        setSectionStyle({
          display: 'block'
        });
      } else {
        setWrapperStyle({
          width: '598px',
          minWidth: '598px'
        });
        setSectionStyle({
          display: 'flex',
          justifyCcontent: 'space-between',
          gap: '20px'
        });
      }
    }
  }, [data]);
  const onOrientationChange = (value) => {
    setOrientation(value);
    if (value === 'portrait') {
      setWrapperStyle({
        width: '314px'
      });
      setSectionStyle({
        display: 'block'
      });
    } else {
      setWrapperStyle({
        width: '598px',
        minWidth: '598px'
      });
      setSectionStyle({
        display: 'flex',
        justifyCcontent: 'space-between',
        gap: '20px'
      });
    }
  };
  const onSubmit = useCallback(
    async (values) => {
      await onUpdate({
        ...values,
        id: data?.id,
        orientation,
        logoPosition,
        qrPosition
      });
    },
    [orientation, heading, logoPosition, description, qrText, data, qrPosition] //eslint-disable-line
  );
  const initialValues = {
    heading: data?.heading,
    description: data?.description,
    qrText: data?.qrText
  };
  return (
    <>
      <DashboardContent title={t('galleryExperience.title')}></DashboardContent>
      {data ? (
        <div className="gallery-experience-page">
          <div className="gallery-experience-page__grid">
            <div
              className={cx(
                'gallery-experience-page__image',
                orientation === 'landscape' ? 'gallery-experience-page__image__overflow' : ''
              )}
            >
              <div style={wrapperStyle} className="gallery-experience-page__image__wrapper">
                <div
                  style={sectionStyle}
                  className={cx('gallery-experience-page__image__wrapper__section')}
                >
                  <div className="gallery-experience-page__image__wrapper__section__description">
                    <div>
                      {logoPosition === 'top' && (
                        <div className="gallery-experience-page__image__wrapper__section__description__logo">
                          <Logo />
                        </div>
                      )}
                      <div className="gallery-experience-page__image__wrapper__section__description__heading">
                        {heading}
                      </div>
                      <div className="gallery-experience-page__image__wrapper__section__description__description">
                        {description}
                      </div>
                      {logoPosition === 'bottom' && orientation === 'landscape' && (
                        <div className="gallery-experience-page__image__wrapper__section__description__bottom">
                          <Logo />
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={cx(
                      'gallery-experience-page__image__wrapper__section__image',
                      qrPosition === 'left'
                        ? 'gallery-experience-page__image__wrapper__section__order-1'
                        : ''
                    )}
                  >
                    <div className="gallery-experience-page__image__wrapper__section__image__text">
                      {qrText}
                    </div>
                    <div className="gallery-experience-page__image__wrapper__section__image__image">
                      <QRCode size={222} value="https://www.patrons.art/" viewBox={`0 0 222 222`} />
                    </div>
                  </div>
                  {logoPosition === 'bottom' && orientation === 'portrait' && (
                    <div className="gallery-experience-page__image__wrapper__section__bottom">
                      <Logo />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="gallery-experience-page__form">
              <Form
                onFinish={onSubmit}
                initialValues={initialValues}
                layout="vertical"
                className="admin-form"
              >
                <Form.Item label={t('galleryExperience.orientation.label')} required>
                  <Radio.Group
                    onChange={(e) => onOrientationChange(e.target.value)}
                    defaultValue={data?.orientation}
                  >
                    <Radio.Button value="portrait">
                      {t('galleryExperience.orientation.portrait')}
                    </Radio.Button>
                    <Radio.Button value="landscape">
                      {t('galleryExperience.orientation.landscape')}
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
                {orientation === 'landscape' && (
                  <Form.Item label={t('galleryExperience.qrPosition.label')} required>
                    <Radio.Group
                      onChange={(e) => setQrPosition(e.target.value)}
                      defaultValue={data?.qrPosition}
                    >
                      <Radio.Button value="left">
                        {t('galleryExperience.qrPosition.left')}
                      </Radio.Button>
                      <Radio.Button value="right">
                        {t('galleryExperience.qrPosition.right')}
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                )}
                <Form.Item
                  label={t('galleryExperience.heading.label')}
                  name="heading"
                  rules={[{ required: true, message: t('galleryExperience.heading.required') }]}
                >
                  <Input
                    onChange={(e) => setHeading(e.target.value)}
                    placeholder={t('galleryExperience.heading.placeholder')}
                  />
                </Form.Item>
                <Form.Item
                  name="description"
                  label={t('galleryExperience.description.label')}
                  rules={[{ required: true, message: t('galleryExperience.description.required') }]}
                >
                  <TextArea
                    onChange={(e) => setDescription(e.target.value)}
                    rows={8}
                    placeholder={t('galleryExperience.description.placeholder')}
                  />
                </Form.Item>
                <Form.Item
                  label={t('galleryExperience.qrText.label')}
                  name="qrText"
                  rules={[{ required: true, message: t('galleryExperience.qrText.required') }]}
                >
                  <Input
                    onChange={(e) => setQrText(e.target.value)}
                    placeholder={t('galleryExperience.qrText.placeholder')}
                  />
                </Form.Item>
                <Form.Item label={t('galleryExperience.logoPosition.label')} required>
                  <Radio.Group
                    onChange={(e) => setLogoPosition(e.target.value)}
                    defaultValue={data?.logoPosition}
                  >
                    <Radio.Button value="top">
                      {t('galleryExperience.logoPosition.top')}
                    </Radio.Button>
                    <Radio.Button value="bottom">
                      {t('galleryExperience.logoPosition.bottom')}
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <Button loading={updateLoading} type="primary" htmlType="submit">
                  {t('galleryExperience.save')}
                </Button>
              </Form>
            </div>
          </div>
        </div>
      ) : (
        <center>
          <Spin />
        </center>
      )}
      <Helmet>
        <title>{t('galleryExperience.title')}</title>
      </Helmet>
    </>
  );
}

export default GalleryExperience;
