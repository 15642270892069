import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Row, Col, Button, Dropdown, Menu, Collapse, List, Table } from 'antd';
import { DashboardContent, DisplayDate, Avatar, Link } from '@components';
import { useActivity } from '@libs/hooks/activity';
import { FilterFilled } from '@ant-design/icons';

function UserActivities() {
  const [activityType, setActivityType] = useState();
  const { t } = useTranslation();
  const avatar = (row) => {
    let user = {};
    switch (row?.type) {
      case 'WITHDRAW_REQUESTED':
      case 'WITHDRAW_CONFIRMATION':
      case 'COLLECTIBLE_BOUGHT':
        user.media = {
          avatarUrl: row?.event?.ownerUser?.avatarUrl
        };
        return (
          <>
            <Avatar user={user} />
            <span className="avatar-name">{`${row?.event?.ownerUser?.firstName} ${row?.event?.ownerUser?.lastName}`}</span>
          </>
        );
      case 'USER_LOGIN':
        user.media = {
          avatarUrl: row?.event?.user?.avatarUrl
        };
        return (
          <>
            <Avatar user={user} />
            <span className="avatar-name">{`${row?.event?.user?.firstName} ${row?.event?.user?.lastName}`}</span>
          </>
        );
      default:
        return;
    }
  };

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setActivityType();
        }}
        key="1"
      >
        {t(`userActivities.activityType.all`)}
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setActivityType('USER_LOGIN');
        }}
        key="2"
      >
        {t(`userActivities.activityType.USER_LOGIN`)}
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setActivityType('WITHDRAW_CONFIRMATION');
        }}
        key="3"
      >
        {t(`userActivities.activityType.WITHDRAW_CONFIRMATION`)}
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setActivityType('WITHDRAW_REQUESTED');
        }}
        key="4"
      >
        {t(`userActivities.activityType.WITHDRAW_REQUESTED`)}
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setActivityType('COLLECTIBLE_BOUGHT');
        }}
        key="5"
      >
        {t(`userActivities.activityType.COLLECTIBLE_BOUGHT`)}
      </Menu.Item>
    </Menu>
  );

  const { data, loading } = useActivity(activityType);

  const columns = [
    {
      title: t('orderHistory.title'),
      key: 'orderID',
      responsive: ['xs'],
      render: (_, row) => {
        return (
          <div className="mobile-row">
            <div className="mobile-row__info">
              <div className="mobile-row__info__name">
                {' '}
                <Link to={`/admin/order-history/${row.id}`}>
                  <span className="order-history-id">{row.prodigiOrderID}</span>
                </Link>
              </div>
            </div>
            <Collapse collapsible ghost className="datalist">
              <Collapse.Panel header={t('offers.list.expand')} key="1">
                <List itemLayout="vertical">
                  <List.Item>
                    <List.Item.Meta
                      title={t('userActivities.column.user')}
                      description={avatar(row)}
                    />
                    <List.Item.Meta
                      title={t('userActivities.column.type')}
                      description={
                        <>
                          <span>{t(`userActivities.activityType.${row?.type}`)}</span>
                        </>
                      }
                    />
                    <List.Item.Meta
                      title={t('userActivities.column.date')}
                      description={<DisplayDate date={row?.createdAt} />}
                    />
                  </List.Item>
                </List>
              </Collapse.Panel>
            </Collapse>
          </div>
        );
      }
    },
    {
      title: t('userActivities.column.user'),
      key: 'owner',
      width: 300,
      responsive: ['sm'],
      render: (_, row) => {
        let user = {};
        switch (row?.type) {
          case 'WITHDRAW_REQUESTED':
          case 'WITHDRAW_CONFIRMATION':
          case 'COLLECTIBLE_BOUGHT':
            user.media = {
              avatarUrl: row?.event?.ownerUser?.avatarUrl
            };
            return (
              <>
                <Avatar user={user} />
                <span className="avatar-name">{`${row?.event?.ownerUser?.firstName} ${row?.event?.ownerUser?.lastName}`}</span>
              </>
            );
          case 'USER_LOGIN':
            user.media = {
              avatarUrl: row?.event?.user?.avatarUrl
            };
            return (
              <>
                <Avatar user={user} />
                <span className="avatar-name">{`${row?.event?.user?.firstName} ${row?.event?.user?.lastName}`}</span>
              </>
            );
          default:
            return;
        }
      }
    },
    {
      title: t('userActivities.column.type'),
      key: 'id',
      width: 250,
      responsive: ['sm'],
      render: (_, row) => <span>{t(`userActivities.activityType.${row?.type}`)}</span>
    },
    {
      title: t('userActivities.column.date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 250,
      responsive: ['md'],
      render: (value) => <DisplayDate date={value} />
    }
  ];

  return (
    <>
      <DashboardContent title={t('userActivities.title')}></DashboardContent>
      <Helmet>
        <title>{t('userActivities.title')}</title>
      </Helmet>
      <Row className="user-activities">
        <Col className="user-activities-header-left" span={12}></Col>
        <Col className="user-activities-header-right" span={12}>
          <Dropdown overlay={menu} placement="bottomLeft" arrow>
            <Button className="user-activities-header-right-button" type="primary">
              {t('components.notificationMenu.buttons.filter')}
              <FilterFilled />
            </Button>
          </Dropdown>
        </Col>
      </Row>
      <div className="user-activities-table">
        <div className="fans-table">
          <Table
            bordered
            columns={columns}
            pagination={false}
            loading={loading}
            dataSource={data}
          />
        </div>
      </div>
    </>
  );
}

export default UserActivities;
