import { useCallback, useState, useEffect } from 'react';

import gql from '@libs/utils/gql';
import useToast from '@libs/utils/toast';
import { searchWalletHistories } from '@libs/custom-queries/wallet-history';
import { acceptWithdrawal, rejectWithdrawal } from '@graphql/mutations';

export const useWithdrawalInReview = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [approvalLoading, setApprovalLoading] = useState(false);
  const [nextPageToken, setNextPageToken] = useState();
  const [data, setData] = useState();

  const getData = async (Token, withdrawID) => {
    setLoading(true);
    try {
      const params = {
        filter: {
          searchableStatus: { eq: 'PENDING' },
          searchableTransactionType: { eq: 'WITHDRAWAL' }
        },
        limit: 10,
        sort: {
          direction: 'desc',
          field: 'createdAt'
        }
      };
      if (withdrawID) {
        params.filter = {
          ...params.filter,
          invoiceID: { eq: withdrawID }
        };
      }
      if (Token) {
        params.nextToken = Token;
      }
      const { data: res } = await gql(searchWalletHistories, params, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });

      if (res?.searchWalletHistories?.items?.length) {
        setData(res.searchWalletHistories.items);
      } else {
        setData([]);
      }
      setNextPageToken(res?.searchWalletHistories?.nextToken);
    } catch (error) {
      const errorMessage = error.errors[0]?.errorType;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line

  const onApprove = useCallback(
    async (withdrawalID) => {
      setApprovalLoading(true);
      console.log(withdrawalID);
      try {
        await gql(
          acceptWithdrawal,
          {
            input: {
              withdrawalID
            }
          },
          {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }
        );
        toast('Withdrawal accepted succesfully', 'success');
        return true;
      } catch (error) {
        const errorMessage = error.errors[0]?.errorType;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setApprovalLoading(false);
      }
    },
    [] // eslint-disable-line
  );

  const onReject = useCallback(
    async (withdrawalID) => {
      setApprovalLoading(true);
      console.log(withdrawalID);
      try {
        await gql(
          rejectWithdrawal,
          {
            input: {
              withdrawalID
            }
          },
          {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }
        );

        toast('Withdrawal has been rejected', 'success');
        return true;
      } catch (error) {
        const errorMessage = error.errors[0]?.errorType;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setApprovalLoading(false);
      }
    },
    [] // eslint-disable-line
  );

  return {
    data,
    loading,
    approvalLoading,
    nextPageToken,
    onApprove,
    getData,
    onReject
  };
};
