import { EditionBadge } from '@components';

const SerialItem = ({ exchange, children, ...props }) => {
  return (
    <div className="serial-item">
      <EditionBadge exchange={exchange} white size="base" />
      <span className="serial-item__index">#{exchange.serialIndex}</span>
    </div>
  );
};

export default SerialItem;
