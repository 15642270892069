import { Row, Col, Table, Collapse, List, Input, Modal, Button, Tag } from 'antd';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { DashboardContent, Avatar, DisplayDate, Pagination } from '@components';
import { useUsers } from '@libs/hooks/user';

const DeactivateModal = ({ showModal, onCancel, onSubmit, cb, loading, ...props }) => {
  const { t } = useTranslation();

  return (
    <Modal
      className="admin-collectible-modal"
      visible={showModal}
      footer={null}
      width={508}
      onCancel={onCancel}
      {...props}
    >
      <div style={{ textAlign: 'center' }}>
        <DeleteOutlined className="users-modal__icon" style={{ color: '#D6222A' }} />
        <h2>{t('users.modal.deactivate.title')}</h2>
        <p className="users-modal__desc">{t('users.modal.deactivate.desc')}</p>
        <Button
          danger
          className="users-modal__button users-modal__button-error"
          onClick={() => {
            onSubmit();
            cb && cb();
          }}
          loading={loading}
        >
          {t('users.modal.button.deactivate')}
        </Button>
        <Button
          className="users-modal__button users-modal__button-default"
          onClick={onCancel}
          type="default"
        >
          {t('users.modal.button.cancel')}
        </Button>
      </div>
    </Modal>
  );
};

function AdminUsers() {
  const { Search } = Input;
  const { t } = useTranslation();
  const {
    data: users,
    loading,
    nextPageToken,
    getData,
    deactivateLoading,
    onDeactivate
  } = useUsers();
  const [deactivateData, setDeactivateData] = useState(false);

  const handleSearch = (data) => {
    getData(null, data);
  };

  const columns = [
    {
      title: t('users.title'),
      key: 'withdrawlID',
      responsive: ['xs'],
      render: (_, row) => {
        return (
          <div className="mobile-row">
            <div className="mobile-row__info">
              <div className="mobile-row__info__name">
                {row?.firstName} {row?.lastName}
                {row?.status !== 'NOT_ACTIVE' && (
                  <div className="users-action" onClick={() => setDeactivateData(row.id)}>
                    {t('users.data.deactivate')}
                  </div>
                )}
              </div>
            </div>
            <Collapse collapsible ghost className="datalist">
              <Collapse.Panel header={t('offers.list.expand')} key="1">
                <List itemLayout="vertical">
                  <List.Item>
                    <List.Item.Meta
                      title={t('users.list.emailAddress')}
                      description={<span>{row?.email}</span>}
                    />
                    <List.Item.Meta
                      title={t('users.list.role')}
                      description={
                        <span>
                          {row?.verified === 'FALSE'
                            ? t('users.data.fan')
                            : process.env.REACT_APP_NAME === 'patrons'
                            ? t('users.data.patrons.creator')
                            : t('users.data.creator')}
                        </span>
                      }
                    />
                    <List.Item.Meta
                      title={t('users.list.avatar')}
                      description={<Avatar user={row} />}
                    />
                    <List.Item.Meta
                      title={t('users.list.joinedAt')}
                      description={<DisplayDate date={row?.createdAt} />}
                    />
                  </List.Item>
                </List>
              </Collapse.Panel>
            </Collapse>
          </div>
        );
      }
    },
    {
      title: t('users.list.name'),
      key: 'name',
      responsive: ['sm'],
      render: (_, row) => (
        <span>
          {row?.firstName} {row?.lastName}
          {row?.status !== 'NOT_ACTIVE' && (
            <div className="users-action" onClick={() => setDeactivateData(row.id)}>
              {t('users.data.deactivate')}
            </div>
          )}
        </span>
      )
    },
    {
      title: t('users.list.emailAddress'),
      dataIndex: 'email',
      key: 'email',
      responsive: ['sm'],
      render: (_, row) => <span>{row?.email}</span>
    },
    {
      title: t('users.list.role'),
      dataIndex: 'email',
      key: 'email',
      responsive: ['sm'],
      render: (_, row) => (
        <span>
          {row?.verified === 'FALSE'
            ? t('users.data.fan')
            : process.env.REACT_APP_NAME === 'patrons'
            ? t('users.data.patrons.creator')
            : t('users.data.creator')}
        </span>
      )
    },
    {
      title: t('users.list.status'),
      dataIndex: 'status',
      key: 'status',
      responsive: ['sm'],
      render: (_, row) => (
        <span>
          {row?.status === 'NOT_ACTIVE' ? (
            <Tag
              color="red"
              style={{ color: '#cf1322', background: '#fff1f0', borderColor: '#ffa39e' }}
            >
              {t('users.data.not_active')}
            </Tag>
          ) : (
            <Tag
              color="blue"
              style={{ color: '#096dd9', background: '#e6f7ff', borderColor: '#91d5ff' }}
            >
              {t('users.data.active')}
            </Tag>
          )}
        </span>
      )
    },
    {
      title: t('users.list.avatar'),
      dataIndex: 'avatar',
      key: 'avatar',
      responsive: ['sm'],
      render: (_, row) => (
        <>
          <Avatar user={row} />
        </>
      )
    },
    {
      title: t('users.list.joinedAt'),
      dataIndex: 'joinedAt',
      key: 'joinedAt',
      responsive: ['sm'],
      render: (_, row) => <DisplayDate date={row?.createdAt} />
    }
  ];

  return (
    <>
      <DashboardContent className="users-page" title={t('users.title')}>
        <div className="users-toolbar">
          <Row className="users-page-search">
            <Col span={24} sm={12}>
              <div className="toolbar-search">
                <Search
                  onChange={(e) => {
                    handleSearch(e.target.value);
                  }}
                  placeholder={t('users.search')}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="users">
          <div className="fans-table">
            <Table
              bordered
              loading={loading}
              dataSource={users}
              columns={columns}
              pagination={false}
            />
            <Pagination nextToken={nextPageToken} loading={loading} fetchData={getData} />
            <DeactivateModal
              showModal={deactivateData}
              onCancel={() => setDeactivateData(null)}
              onSubmit={() => {
                onDeactivate(deactivateData, () => setDeactivateData(null));
              }}
              loading={deactivateLoading}
            />
          </div>
        </div>
      </DashboardContent>
      <Helmet>
        <title>{t('users.title')}</title>
      </Helmet>
    </>
  );
}

export default AdminUsers;
