import NotificationArtist from './artist';
import NotificationGallery from './gallery';
import { useAuth } from '@libs/contexts/auth';

const Notifications = () => {
  const { user } = useAuth();
  if (user?.type === 'Artist') {
    return <NotificationArtist />;
  } else {
    return <NotificationGallery />;
  }
};

export default Notifications;
