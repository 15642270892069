import { useState, useEffect } from 'react';
import gql from '@libs/utils/gql';
import useToast from '@libs/utils/toast';
import { useTranslation } from 'react-i18next';

import { channelByOwnerAndCreatedAt } from 'libs/custom-queries/channel';
import { searchDiscoursePosts } from 'libs/custom-queries/post';
import {
  discourseCreatePost,
  discourseReplyPost,
  discourseCreateChannel,
  discourseDelete,
  discourseLikePost,
  discourseUnlikePost,
  discourseUpdate,
  discourseFeaturedPost,
  collectibleCreateComment,
  collectibleReplyComment,
  collectibleLikeComment,
  collectibleUnlikeComment,
  collectibleDeleteComment
} from '@graphql/mutations';
import { onCommentaryUpdated } from '@graphql/subscriptions';

export const useDiscourse = ({ userID, channelID }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [createPostLoading, setCreatePostLoading] = useState(false);
  const [createChannelLoading, setCreateChannelLoading] = useState(false);
  const [deleteChannelLoading, setDeleteChannelLoading] = useState(false);
  const [updateChannelLoading, setUpdateChannelLoading] = useState(false);
  const [nextPageToken, setNextPageToken] = useState();
  const [data, setData] = useState([]);

  const getData = async (nextToken) => {
    if (userID && channelID) {
      nextToken ? setPaginationLoading(true) : setLoading(true);
      try {
        const params = {
          filter: {
            channelID: { eq: channelID },
            parentID: { exists: false },
            searchableFeatured: { ne: 'TRUE' }
          },
          limit: 10
        };
        if (nextToken) {
          params.nextToken = nextToken;
        }
        const { data: res } = await gql(searchDiscoursePosts, params);

        if (res?.searchDiscoursePosts?.items) {
          if (nextToken) {
            setData(data.concat(res?.searchDiscoursePosts?.items));
          } else {
            setData(res?.searchDiscoursePosts?.items);
          }
          setNextPageToken(res?.searchDiscoursePosts?.nextToken);
        } else {
          setNextPageToken(null);
          setData(null);
        }
      } catch (error) {
        const errorMessage = error?.errors
          ? error?.errors[0]?.message
          : error.message ?? 'unknown error';
        toast(errorMessage, 'error');
        console.error(error);
      }
      setLoading(false);
      setPaginationLoading(false);
    }
  };

  const createPost = async (post) => {
    setCreatePostLoading(true);
    try {
      const payload = {
        input: {
          raw: post.raw,
          title: post.title,
          category: channelID,
          userID: userID
        }
      };
      await gql(discourseCreatePost, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      toast(t('discourse.successCreatePost'), 'success');
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setCreatePostLoading(false);
    }
  };

  const createChannel = async (channel) => {
    setCreateChannelLoading(true);
    try {
      const payload = {
        input: {
          name: channel.name,
          parentCategoryId: channel.parentCategoryId,
          ownerID: channel.ownerID
        }
      };
      await gql(discourseCreateChannel, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      return true;
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setCreateChannelLoading(false);
    }
  };

  const deleteChannel = async (id) => {
    setDeleteChannelLoading(true);
    try {
      const payload = {
        input: {
          id: id,
          mode: 'CHANNEL',
          userID: userID
        }
      };
      await gql(discourseDelete, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      toast(t('discourse.successDeleteChannel'), 'success');
      return true;
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setDeleteChannelLoading(false);
    }
  };

  const updateChannel = async (data) => {
    setUpdateChannelLoading(true);
    try {
      const payload = {
        input: {
          id: data.id,
          name: data.name,
          userID: userID
        }
      };
      await gql(discourseUpdate, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      toast(t('discourse.successUpdateChannel'), 'success');
      return true;
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setUpdateChannelLoading(false);
    }
  };

  useEffect(() => {
    async function updateData() {
      setLoading(true);
      await getData();
      setLoading(false);
    }
    if (userID && channelID) {
      updateData();
    }
  }, [userID, channelID]); // eslint-disable-line

  return {
    data,
    loading,
    createPostLoading,
    createChannelLoading,
    deleteChannelLoading,
    updateChannelLoading,
    paginationLoading,
    nextPageToken,
    getData,
    setLoading,
    createPost,
    createChannel,
    deleteChannel,
    updateChannel
  };
};

export const usePost = (topicID, userID) => {
  const toast = useToast();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [likeLoading, setLikeLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [featuredLoading, setFeaturedLoading] = useState(false);
  const [data, setData] = useState();

  const getData = async () => {
    if (topicID && userID) {
      setLoading(true);
      try {
        const params = {
          filter: { id: { eq: topicID } }
        };
        const { data: res } = await gql(searchDiscoursePosts, params);

        if (res?.searchDiscoursePosts?.items[0]) {
          setData(res?.searchDiscoursePosts?.items[0]);
        } else {
          setData(null);
        }
      } catch (error) {
        const errorMessage = error?.errors
          ? error?.errors[0]?.message
          : error.message ?? 'unknown error';
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const updatePost = async (data) => {
    setUpdateLoading(true);
    try {
      const payload = {
        input: {
          id: data.id,
          raw: data.raw,
          category: data.channelID,
          userID: data.userID
        }
      };
      await gql(discourseUpdate, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      toast(t('discourse.successUpdatePost'), 'success');
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setUpdateLoading(false);
    }
  };

  const deletePost = async () => {
    setDeleteLoading(true);
    try {
      const payload = {
        input: {
          id: topicID,
          mode: 'POST',
          userID
        }
      };
      await gql(discourseDelete, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      toast(t('discourse.successDeletePost'), 'success');
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setDeleteLoading(false);
    }
  };

  const featuredPost = async () => {
    setFeaturedLoading(true);
    try {
      const payload = {
        input: {
          postID: topicID,
          userID: userID,
          featured: 'TRUE'
        }
      };
      await gql(discourseFeaturedPost, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      toast(t('discourse.successFeaturedPost'), 'success');
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setFeaturedLoading(false);
    }
  };

  const likePost = async (postID) => {
    setLikeLoading(true);
    try {
      const payload = {
        input: {
          id: postID,
          userID: userID
        }
      };
      await gql(discourseLikePost, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLikeLoading(false);
    }
  };

  const unlikePost = async (postID) => {
    setLikeLoading(true);
    try {
      const payload = {
        input: {
          id: postID,
          userID: userID
        }
      };
      await gql(discourseUnlikePost, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLikeLoading(false);
    }
  };

  return {
    data,
    loading,
    deleteLoading,
    updateLoading,
    likeLoading,
    featuredLoading,
    getData,
    updatePost,
    deletePost,
    likePost,
    unlikePost,
    featuredPost
  };
};

export const useReplyPost = (topicID) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [data, setData] = useState();

  const getData = async () => {
    if (topicID) {
      setLoading(true);
      try {
        const params = {
          filter: { parentID: { eq: topicID } },
          sort: { direction: 'asc', field: 'createdAt' }
        };
        const { data: res } = await gql(searchDiscoursePosts, params);

        if (res?.searchDiscoursePosts?.items) {
          setData(res?.searchDiscoursePosts?.items);
        } else {
          setData(null);
        }
      } catch (error) {
        const errorMessage = error?.errors
          ? error?.errors[0]?.message
          : error.message ?? 'unknown error';
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const replyPost = async (post) => {
    setLoading(true);
    try {
      const payload = {
        input: {
          raw: post.raw,
          topicId: post.topicID,
          replyToPostNumber: post.postNumber,
          userID: post.userID,
          category: post.category,
          parentID: post.parentID
        }
      };
      await gql(discourseReplyPost, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteComment = async (userID) => {
    setDeleteLoading(true);
    try {
      const payload = {
        input: {
          id: topicID,
          mode: 'REPLY',
          userID
        }
      };
      await gql(discourseDelete, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setDeleteLoading(false);
    }
  };

  return {
    data,
    loading,
    deleteLoading,
    getData,
    replyPost,
    deleteComment
  };
};

export const useChannelByOwner = (ownerID) => {
  const toast = useToast();
  const [data, setData] = useState();
  const [loading, setLoading] = useState();

  const getData = async () => {
    try {
      const params = {
        owner: ownerID,
        sortDirection: 'DESC'
      };
      const { data: res } = await gql(channelByOwnerAndCreatedAt, params);
      if (res?.channelByOwnerAndCreatedAt?.items?.length) {
        setData(res?.channelByOwnerAndCreatedAt?.items);
      } else {
        setData([]);
      }
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (ownerID) {
      getData();
    }
  }, [ownerID]); // eslint-disable-line

  return {
    loading,
    data,
    getData
  };
};

export const useDiscourseFeatured = ({ userID, channelID }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const getData = async () => {
    setLoading(true);
    try {
      const params = {
        filter: {
          channelID: { eq: channelID },
          parentID: { exists: false },
          searchableFeatured: { eq: 'TRUE' }
        }
      };
      const { data: res } = await gql(searchDiscoursePosts, params);

      if (res?.searchDiscoursePosts?.items) {
        setData(res?.searchDiscoursePosts?.items);
      } else {
        setData(null);
      }
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userID && channelID) {
      getData();
    }
  }, [userID, channelID]); // eslint-disable-line

  return {
    data,
    loading,
    setLoading,
    getData
  };
};

export const useCommentCollectible = ({ collectibleID }) => {
  const toast = useToast();
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const [createCommentLoading, setCreateCommentLoading] = useState(false);

  const getData = async (loading = false) => {
    loading && setLoading(true);
    try {
      const params = {
        filter: { channelID: { eq: collectibleID }, parentID: { eq: collectibleID } },
        sort: { direction: 'asc', field: 'createdAt' }
      };
      const { data: res } = await gql(searchDiscoursePosts, params);

      if (res?.searchDiscoursePosts?.items) {
        setData(res?.searchDiscoursePosts?.items);
      } else {
        setData([]);
      }
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      loading && setLoading(false);
    }
  };

  const createComment = async (post) => {
    setCreateCommentLoading(true);
    try {
      const payload = {
        input: {
          raw: post.raw,
          category: collectibleID,
          userID: post.userID
        }
      };
      await gql(collectibleCreateComment, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setCreateCommentLoading(false);
    }
  };

  useEffect(() => {
    if (collectibleID) {
      const payload = {};
      const subscription = gql(onCommentaryUpdated, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      }).subscribe({
        next: ({ value: { data, errors } }) => {
          setTimeout(function () {
            getData();
          }, 1000);
        },
        error: (error) => console.warn(error)
      });
      return () => {
        if (subscription) subscription.unsubscribe();
      };
    }
  }, [collectibleID]); // eslint-disable-line

  useEffect(() => {
    if (collectibleID) {
      getData(true);
    }
  }, [collectibleID]); // eslint-disable-line

  return {
    loading,
    createCommentLoading,
    data,
    getData,
    createComment
  };
};

export const useReplyCommentCollectible = ({ commentID, collectibleID }) => {
  const toast = useToast();
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const [likeLoading, setLikeLoading] = useState();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [createReplyLoading, setReplyLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    try {
      const params = {
        filter: { parentID: { eq: commentID } },
        sort: { direction: 'asc', field: 'createdAt' }
      };
      const { data: res } = await gql(searchDiscoursePosts, params);

      if (res?.searchDiscoursePosts?.items) {
        setData(res?.searchDiscoursePosts?.items);
      } else {
        setData([]);
      }
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const createReplyComment = async (post) => {
    setReplyLoading(true);
    try {
      const payload = {
        input: {
          raw: post.raw,
          parentID: commentID,
          category: collectibleID,
          userID: post.userID
        }
      };
      await gql(collectibleReplyComment, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setReplyLoading(false);
    }
  };

  const likeComment = async (commentID) => {
    setLikeLoading(true);
    try {
      const payload = {
        input: {
          id: commentID
        }
      };
      await gql(collectibleLikeComment, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLikeLoading(false);
    }
  };

  const unlikeComment = async (commentID) => {
    setLikeLoading(true);
    try {
      const payload = {
        input: {
          id: commentID
        }
      };
      await gql(collectibleUnlikeComment, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLikeLoading(false);
    }
  };

  const deleteComment = async () => {
    setDeleteLoading(true);
    try {
      const payload = {
        input: {
          id: commentID
        }
      };
      await gql(collectibleDeleteComment, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setDeleteLoading(false);
    }
  };

  useEffect(() => {
    if (commentID && collectibleID) {
      const payload = {};
      const subscription = gql(onCommentaryUpdated, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      }).subscribe({
        next: ({ value: { data, errors } }) => {
          setTimeout(function () {
            getData();
          }, 1000);
        },
        error: (error) => console.warn(error)
      });
      return () => {
        if (subscription) subscription.unsubscribe();
      };
    }
  }, [commentID, collectibleID]); // eslint-disable-line

  useEffect(() => {
    if (commentID && collectibleID) {
      getData();
    }
  }, [commentID, collectibleID]); // eslint-disable-line

  return {
    loading,
    createReplyLoading,
    data,
    likeLoading,
    deleteLoading,
    deleteComment,
    createReplyComment,
    likeComment,
    unlikeComment,
    getData
  };
};
