import cx from 'classnames';

const Price = ({ amount, inline, minus, showCurrency, currencyTextColor, className, ...props }) => {
  const inlineClass = inline ? 'price-inline-block' : false;
  const currencyTextColorClass = currencyTextColor === 'grey' ? 'text-gray-400' : false;

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  return (
    <div className={cx('price', inlineClass, className)} {...props}>
      {minus && '-'}
      {currencyFormatter.format(amount)}
      <span className={currencyTextColorClass}>{showCurrency && ' USD'}</span>
    </div>
  );
};

Price.defaultProps = {
  inline: false,
  minus: false,
  showCurrency: false
};

export default Price;
