export const TAX_PERCENTAGE = { AU: 0.1, US: 0.1 };
const tax = TAX_PERCENTAGE['US'];

export const calculateTax = (amount) => {
  const parsedAmount = parseFloat(amount);
  const amountBeforeTax = parsedAmount / (1 + tax);
  const taxOnAmount = parsedAmount - amountBeforeTax;
  const amountAfterTax = parsedAmount;
  return { tax: taxOnAmount, beforeTax: amountBeforeTax, afterTax: amountAfterTax };
};

export const phoneCodes = {
  BD: '880',
  BE: '32',
  BF: '226',
  BG: '359',
  BA: '387',
  BB: '1-246',
  WF: '681',
  BL: '590',
  BM: '1-441',
  BN: '673',
  BO: '591',
  BH: '973',
  BI: '257',
  BJ: '229',
  BT: '975',
  JM: '1-876',
  BV: '',
  BW: '267',
  WS: '685',
  BQ: '599',
  BR: '55',
  BS: '1-242',
  JE: '44-1534',
  BY: '375',
  BZ: '501',
  RU: '7',
  RW: '250',
  RS: '381',
  TL: '670',
  RE: '262',
  TM: '993',
  TJ: '992',
  RO: '40',
  TK: '690',
  GW: '245',
  GU: '1-671',
  GT: '502',
  GS: '',
  GR: '30',
  GQ: '240',
  GP: '590',
  JP: '81',
  GY: '592',
  GG: '44-1481',
  GF: '594',
  GE: '995',
  GD: '1-473',
  GB: '44',
  GA: '241',
  SV: '503',
  GN: '224',
  GM: '220',
  GL: '299',
  GI: '350',
  GH: '233',
  OM: '968',
  TN: '216',
  JO: '962',
  HR: '385',
  HT: '509',
  HU: '36',
  HK: '852',
  HN: '504',
  HM: ' ',
  VE: '58',
  PR: '1-787 and 1-939',
  PS: '970',
  PW: '680',
  PT: '351',
  SJ: '47',
  PY: '595',
  IQ: '964',
  PA: '507',
  PF: '689',
  PG: '675',
  PE: '51',
  PK: '92',
  PH: '63',
  PN: '870',
  PL: '48',
  PM: '508',
  ZM: '260',
  EH: '212',
  EE: '372',
  EG: '20',
  ZA: '27',
  EC: '593',
  IT: '39',
  VN: '84',
  SB: '677',
  ET: '251',
  SO: '252',
  ZW: '263',
  SA: '966',
  ES: '34',
  ER: '291',
  ME: '382',
  MD: '373',
  MG: '261',
  MF: '590',
  MA: '212',
  MC: '377',
  UZ: '998',
  MM: '95',
  ML: '223',
  MO: '853',
  MN: '976',
  MH: '692',
  MK: '389',
  MU: '230',
  MT: '356',
  MW: '265',
  MV: '960',
  MQ: '596',
  MP: '1-670',
  MS: '1-664',
  MR: '222',
  IM: '44-1624',
  UG: '256',
  TZ: '255',
  MY: '60',
  MX: '52',
  IL: '972',
  FR: '33',
  IO: '246',
  SH: '290',
  FI: '358',
  FJ: '679',
  FK: '500',
  FM: '691',
  FO: '298',
  NI: '505',
  NL: '31',
  NO: '47',
  NA: '264',
  VU: '678',
  NC: '687',
  NE: '227',
  NF: '672',
  NG: '234',
  NZ: '64',
  NP: '977',
  NR: '674',
  NU: '683',
  CK: '682',
  XK: '',
  CI: '225',
  CH: '41',
  CO: '57',
  CN: '86',
  CM: '237',
  CL: '56',
  CC: '61',
  CA: '1',
  CG: '242',
  CF: '236',
  CD: '243',
  CZ: '420',
  CY: '357',
  CX: '61',
  CR: '506',
  CW: '599',
  CV: '238',
  CU: '53',
  SZ: '268',
  SY: '963',
  SX: '599',
  KG: '996',
  KE: '254',
  SS: '211',
  SR: '597',
  KI: '686',
  KH: '855',
  KN: '1-869',
  KM: '269',
  ST: '239',
  SK: '421',
  KR: '82',
  SI: '386',
  KP: '850',
  KW: '965',
  SN: '221',
  SM: '378',
  SL: '232',
  SC: '248',
  KZ: '7',
  KY: '1-345',
  SG: '65',
  SE: '46',
  SD: '249',
  DO: '1-809',
  DM: '1-767',
  DJ: '253',
  DK: '45',
  VG: '1-284',
  DE: '49',
  YE: '967',
  DZ: '213',
  US: '1',
  UY: '598',
  YT: '262',
  UM: '1',
  LB: '961',
  LC: '1-758',
  LA: '856',
  TV: '688',
  TW: '886',
  TT: '1-868',
  TR: '90',
  LK: '94',
  LI: '423',
  LV: '371',
  TO: '676',
  LT: '370',
  LU: '352',
  LR: '231',
  LS: '266',
  TH: '66',
  TF: '',
  TG: '228',
  TD: '235',
  TC: '1-649',
  LY: '218',
  VA: '379',
  VC: '1-784',
  AE: '971',
  AD: '376',
  AG: '1-268',
  AF: '93',
  AI: '1-264',
  VI: '1-340',
  IS: '354',
  IR: '98',
  AM: '374',
  AL: '355',
  AO: '244',
  AQ: '',
  AS: '1-684',
  AR: '54',
  AU: '61',
  AT: '43',
  AW: '297',
  IN: '91',
  AX: '358-18',
  AZ: '994',
  IE: '353',
  ID: '62',
  UA: '380',
  QA: '974',
  MZ: '258'
};

export const SKUSize = [
  {
    name: '6" x 6"',
    value: 'GLOBAL-CAN-6X6',
    width: 6,
    height: 6
  },
  {
    name: '8" x 8"',
    value: 'GLOBAL-CAN-8X8',
    width: 8,
    height: 8
  },
  {
    name: '8" x 10"',
    value: 'GLOBAL-CAN-8X10',
    width: 8,
    height: 10
  },
  {
    name: '8" x 12"',
    value: 'GLOBAL-CAN-8X12',
    width: 8,
    height: 12
  },
  {
    name: '9" x 12"',
    value: 'GLOBAL-CAN-9X12',
    width: 9,
    height: 12
  },
  {
    name: '10" x 10"',
    value: 'GLOBAL-CAN-10X10',
    width: 10,
    height: 10
  },
  {
    name: '10" x 12"',
    value: 'GLOBAL-CAN-10X12',
    width: 10,
    height: 12
  },
  {
    name: '11" x 14"',
    value: 'GLOBAL-CAN-11X14',
    width: 11,
    height: 14
  },
  {
    name: '12" x 12"',
    value: 'GLOBAL-CAN-12X12',
    width: 12,
    height: 12
  },
  {
    name: '12" x 16"',
    value: 'GLOBAL-CAN-12X16',
    width: 14,
    height: 14
  },
  {
    name: '14" x 14"',
    value: 'GLOBAL-CAN-14X14',
    width: 14,
    height: 14
  },
  {
    name: '16" x 16"',
    value: 'GLOBAL-CAN-16X16',
    width: 16,
    height: 16
  },
  {
    name: '10" x 30"',
    value: 'GLOBAL-CAN-10X30',
    width: 10,
    height: 30
  },
  {
    name: '12" x 18"',
    value: 'GLOBAL-CAN-12X18',
    width: 12,
    height: 18
  },
  {
    name: '12" x 24"',
    value: 'GLOBAL-CAN-12X24',
    width: 12,
    height: 24
  },
  {
    name: '12" x 36"',
    value: 'GLOBAL-CAN-12X36',
    width: 12,
    height: 36
  },
  {
    name: '15" x 30"',
    value: 'GLOBAL-CAN-15X30',
    width: 15,
    height: 30
  },
  {
    name: '16" x 20"',
    value: 'GLOBAL-CAN-16X20',
    width: 16,
    height: 20
  },
  {
    name: '16" x 32"',
    value: 'GLOBAL-CAN-16X32',
    width: 16,
    height: 32
  },
  {
    name: '18" x 18"',
    value: 'GLOBAL-CAN-18X18',
    width: 18,
    height: 18
  },
  {
    name: '18" x 24"',
    value: 'GLOBAL-CAN-18X24',
    width: 18,
    height: 24
  },
  {
    name: '18" x 36"',
    value: 'GLOBAL-CAN-18X36',
    width: 18,
    height: 36
  },
  {
    name: '20" x 20"',
    value: 'GLOBAL-CAN-20X20',
    width: 20,
    height: 20
  },
  {
    name: '20" x 24"',
    value: 'GLOBAL-CAN-20X24',
    width: 20,
    height: 24
  },
  {
    name: '20" x 28"',
    value: 'GLOBAL-CAN-20X28',
    width: 20,
    height: 28
  },
  {
    name: '20" x 30"',
    value: 'GLOBAL-CAN-20X30',
    width: 20,
    height: 30
  },
  {
    name: '20" x 40"',
    value: 'GLOBAL-CAN-20X40',
    width: 20,
    height: 40
  },
  {
    name: '20" x 60"',
    value: 'GLOBAL-CAN-20X60',
    width: 20,
    height: 60
  },
  {
    name: '24" x 24"',
    value: 'GLOBAL-CAN-24X24',
    width: 24,
    height: 24
  },
  {
    name: '24" x 30"',
    value: 'GLOBAL-CAN-24X30',
    width: 24,
    height: 30
  },
  {
    name: '24" x 32"',
    value: 'GLOBAL-CAN-24X32',
    width: 24,
    height: 32
  },
  {
    name: '24" x 36"',
    value: 'GLOBAL-CAN-24X36',
    width: 24,
    height: 36
  },
  {
    name: '24" x 48"',
    value: 'GLOBAL-CAN-24X48',
    width: 24,
    height: 48
  },
  {
    name: '28" x 28"',
    value: 'GLOBAL-CAN-28X28',
    width: 28,
    height: 28
  },
  {
    name: '28" x 40"',
    value: 'GLOBAL-CAN-28X40',
    width: 28,
    height: 40
  },
  {
    name: '30" x 30"',
    value: 'GLOBAL-CAN-30X30',
    width: 30,
    height: 30
  },
  {
    name: '30" x 40"',
    value: 'GLOBAL-CAN-30X40',
    width: 30,
    height: 40
  },
  {
    name: '30" x 60"',
    value: 'GLOBAL-CAN-30X60',
    width: 30,
    height: 60
  },
  {
    name: '32" x 32"',
    value: 'GLOBAL-CAN-32X32',
    width: 32,
    height: 32
  },
  {
    name: '36" x 36"',
    value: 'GLOBAL-CAN-36X36',
    width: 36,
    height: 36
  },
  {
    name: '36" x 48"',
    value: 'GLOBAL-CAN-36X48',
    width: 36,
    height: 48
  },
  {
    name: '36" x 60"',
    value: 'GLOBAL-CAN-36X60',
    width: 36,
    height: 60
  },
  {
    name: '40" x 40"',
    value: 'GLOBAL-CAN-40X40',
    width: 40,
    height: 40
  },
  {
    name: '40" x 60"',
    value: 'GLOBAL-CAN-40X60',
    width: 40,
    height: 60
  }
];

export const prodigiCountries = [
  {
    name: 'AFGHANISTAN',
    isoCode: 'AF'
  },
  {
    name: 'ALAND ISLANDS',
    isoCode: 'AX'
  },
  {
    name: 'ALBANIA',
    isoCode: 'AL'
  },
  {
    name: 'ALGERIA',
    isoCode: 'DZ'
  },
  {
    name: 'AMERICAN SAMOA',
    isoCode: 'AS'
  },
  {
    name: 'ANDORRA',
    isoCode: 'AD'
  },
  {
    name: 'ANGOLA',
    isoCode: 'AO'
  },
  {
    name: 'ANGUILLA',
    isoCode: 'AI'
  },
  {
    name: 'ANTARCTICA',
    isoCode: 'AQ'
  },
  {
    name: 'ANTIGUA AND BARBUDA',
    isoCode: 'AG'
  },
  {
    name: 'ARGENTINA',
    isoCode: 'AR'
  },
  {
    name: 'ARMENIA',
    isoCode: 'AM'
  },
  {
    name: 'ARUBA',
    isoCode: 'AW'
  },
  {
    name: 'AUSTRALIA',
    isoCode: 'AU'
  },
  {
    name: 'AUSTRIA',
    isoCode: 'AT'
  },
  {
    name: 'AZERBAIJAN',
    isoCode: 'AZ'
  },
  {
    name: 'BAHAMAS',
    isoCode: 'BS'
  },
  {
    name: 'BAHRAIN',
    isoCode: 'BH'
  },
  {
    name: 'BANGLADESH',
    isoCode: 'BD'
  },
  {
    name: 'BARBADOS',
    isoCode: 'BB'
  },
  {
    name: 'BELARUS',
    isoCode: 'BY'
  },
  {
    name: 'BELGIUM',
    isoCode: 'BE'
  },
  {
    name: 'BELIZE',
    isoCode: 'BZ'
  },
  {
    name: 'BENIN',
    isoCode: 'BJ'
  },
  {
    name: 'BERMUDA',
    isoCode: 'BM'
  },
  {
    name: 'BHUTAN',
    isoCode: 'BT'
  },
  {
    name: 'BOLIVIA',
    isoCode: 'BO'
  },
  {
    name: 'BOSNIA AND HERZEGOVINA',
    isoCode: 'BA'
  },
  {
    name: 'BOTSWANA',
    isoCode: 'BW'
  },
  {
    name: 'BOUVET ISLAND',
    isoCode: 'BV'
  },
  {
    name: 'BRAZIL',
    isoCode: 'BR'
  },
  {
    name: 'BRITISH INDIAN OCEAN TERRITORY',
    isoCode: 'IO'
  },
  {
    name: 'BRITISH VIRGIN ISLANDS',
    isoCode: 'VG'
  },
  {
    name: 'BRUNEI DARUSSALAM',
    isoCode: 'BN'
  },
  {
    name: 'BULGARIA',
    isoCode: 'BG'
  },
  {
    name: 'BURKINA FASO',
    isoCode: 'BF'
  },
  {
    name: 'BURUNDI',
    isoCode: 'BI'
  },
  {
    name: 'CAMBODIA',
    isoCode: 'KH'
  },
  {
    name: 'CAMEROON',
    isoCode: 'CM'
  },
  {
    name: 'CANADA',
    isoCode: 'CA'
  },
  {
    name: 'CAPE VERDE',
    isoCode: 'CV'
  },
  {
    name: 'CAYMAN ISLANDS',
    isoCode: 'KY'
  },
  {
    name: 'CENTRAL AFRICAN REPUBLIC',
    isoCode: 'CF'
  },
  {
    name: 'CHAD',
    isoCode: 'TD'
  },
  {
    name: 'CHILE',
    isoCode: 'CL'
  },
  {
    name: 'CHINA',
    isoCode: 'CN'
  },
  {
    name: 'CHRISTMAS ISLAND',
    isoCode: 'CX'
  },
  {
    name: 'COCOS (KEELING) ISLANDS',
    isoCode: 'CC'
  },
  {
    name: 'COLOMBIA',
    isoCode: 'CO'
  },
  {
    name: 'COMOROS',
    isoCode: 'KM'
  },
  {
    name: 'CONGO',
    isoCode: 'CG'
  },
  {
    name: 'CONGO',
    isoCode: 'CD'
  },
  {
    name: 'COOK ISLANDS',
    isoCode: 'CK'
  },
  {
    name: 'COSTA RICA',
    isoCode: 'CR'
  },
  {
    name: 'CROATIA',
    isoCode: 'HR'
  },
  {
    name: 'CUBA',
    isoCode: 'CU'
  },
  {
    name: 'CYPRUS',
    isoCode: 'CY'
  },
  {
    name: 'CZECH REPUBLIC',
    isoCode: 'CZ'
  },
  {
    name: 'DENMARK',
    isoCode: 'DK'
  },
  {
    name: 'DJIBOUTI',
    isoCode: 'DJ'
  },
  {
    name: 'DOMINICA',
    isoCode: 'DM'
  },
  {
    name: 'DOMINICAN REPUBLIC',
    isoCode: 'DO'
  },
  {
    name: 'ECUADOR',
    isoCode: 'EC'
  },
  {
    name: 'EGYPT',
    isoCode: 'EG'
  },
  {
    name: 'EL SALVADOR',
    isoCode: 'SV'
  },
  {
    name: 'EQUATORIAL GUINEA',
    isoCode: 'GQ'
  },
  {
    name: 'ERITREA',
    isoCode: 'ER'
  },
  {
    name: 'ESTONIA',
    isoCode: 'EE'
  },
  {
    name: 'ETHIOPIA',
    isoCode: 'ET'
  },
  {
    name: 'FALKLAND ISLANDS',
    isoCode: 'FK'
  },
  {
    name: 'FAROE ISLANDS',
    isoCode: 'FO'
  },
  {
    name: 'FIJI',
    isoCode: 'FJ'
  },
  {
    name: 'FINLAND',
    isoCode: 'FI'
  },
  {
    name: 'FRANCE',
    isoCode: 'FR'
  },
  {
    name: 'FRENCH GUIANA',
    isoCode: 'GF'
  },
  {
    name: 'FRENCH POLYNESIA',
    isoCode: 'PF'
  },
  {
    name: 'FRENCH SOUTHERN TERRITORIES',
    isoCode: 'TF'
  },
  {
    name: 'GABON',
    isoCode: 'GA'
  },
  {
    name: 'GAMBIA',
    isoCode: 'GM'
  },
  {
    name: 'GEORGIA',
    isoCode: 'GE'
  },
  {
    name: 'GERMANY',
    isoCode: 'DE'
  },
  {
    name: 'GHANA',
    isoCode: 'GH'
  },
  {
    name: 'GIBRALTAR',
    isoCode: 'GI'
  },
  {
    name: 'GREECE',
    isoCode: 'GR'
  },
  {
    name: 'GREENLAND',
    isoCode: 'GL'
  },
  {
    name: 'GRENADA',
    isoCode: 'GD'
  },
  {
    name: 'GUADELOUPE',
    isoCode: 'GP'
  },
  {
    name: 'GUAM',
    isoCode: 'GU'
  },
  {
    name: 'GUATEMALA',
    isoCode: 'GT'
  },
  {
    name: 'GUERNSEY',
    isoCode: 'GG'
  },
  {
    name: 'GUINEA',
    isoCode: 'GN'
  },
  {
    name: 'GUINEA-BISSAU',
    isoCode: 'GW'
  },
  {
    name: 'GUYANA',
    isoCode: 'GY'
  },
  {
    name: 'HAITI',
    isoCode: 'HT'
  },
  {
    name: 'HEARD ISLAND AND MCDONALD ISLANDS',
    isoCode: 'HM'
  },
  {
    name: 'HONDURAS',
    isoCode: 'HN'
  },
  {
    name: 'HONG KONG',
    isoCode: 'HK'
  },
  {
    name: 'HUNGARY',
    isoCode: 'HU'
  },
  {
    name: 'ICELAND',
    isoCode: 'IS'
  },
  {
    name: 'INDIA',
    isoCode: 'IN'
  },
  {
    name: 'INDONESIA',
    isoCode: 'ID'
  },
  {
    name: 'IRAN',
    isoCode: 'IR'
  },
  {
    name: 'IRAQ',
    isoCode: 'IQ'
  },
  {
    name: 'IRELAND',
    isoCode: 'IE'
  },
  {
    name: 'ISLE OF MAN',
    isoCode: 'IM'
  },
  {
    name: 'ISRAEL',
    isoCode: 'IL'
  },
  {
    name: 'ITALY',
    isoCode: 'IT'
  },
  {
    name: 'IVORY COAST',
    isoCode: 'CI'
  },
  {
    name: 'JAMAICA',
    isoCode: 'JM'
  },
  {
    name: 'JAPAN',
    isoCode: 'JP'
  },
  {
    name: 'JERSEY',
    isoCode: 'JE'
  },
  {
    name: 'JORDAN',
    isoCode: 'JO'
  },
  {
    name: 'KAZAKHSTAN',
    isoCode: 'KZ'
  },
  {
    name: 'KENYA',
    isoCode: 'KE'
  },
  {
    name: 'KIRIBATI',
    isoCode: 'KI'
  },
  {
    name: 'KOSOVO',
    isoCode: 'XK'
  },
  {
    name: 'KUWAIT',
    isoCode: 'KW'
  },
  {
    name: 'KYRGYZSTAN',
    isoCode: 'KG'
  },
  {
    name: 'LAOS',
    isoCode: 'LA'
  },
  {
    name: 'LATVIA',
    isoCode: 'LV'
  },
  {
    name: 'LEBANON',
    isoCode: 'LB'
  },
  {
    name: 'LESOTHO',
    isoCode: 'LS'
  },
  {
    name: 'LIBERIA',
    isoCode: 'LR'
  },
  {
    name: 'LIBYA',
    isoCode: 'LY'
  },
  {
    name: 'LIECHTENSTEIN',
    isoCode: 'LI'
  },
  {
    name: 'LITHUANIA',
    isoCode: 'LT'
  },
  {
    name: 'LUXEMBOURG',
    isoCode: 'LU'
  },
  {
    name: 'MACAO',
    isoCode: 'MO'
  },
  {
    name: 'MACEDONIA',
    isoCode: 'MK'
  },
  {
    name: 'MADAGASCAR',
    isoCode: 'MG'
  },
  {
    name: 'MALAWI',
    isoCode: 'MW'
  },
  {
    name: 'MALAYSIA',
    isoCode: 'MY'
  },
  {
    name: 'MALDIVES',
    isoCode: 'MV'
  },
  {
    name: 'MALI',
    isoCode: 'ML'
  },
  {
    name: 'MALTA',
    isoCode: 'MT'
  },
  {
    name: 'MARSHALL ISLANDS',
    isoCode: 'MH'
  },
  {
    name: 'MARTINIQUE',
    isoCode: 'MQ'
  },
  {
    name: 'MAURITANIA',
    isoCode: 'MR'
  },
  {
    name: 'MAURITIUS',
    isoCode: 'MU'
  },
  {
    name: 'MAYOTTE',
    isoCode: 'YT'
  },
  {
    name: 'MEXICO',
    isoCode: 'MX'
  },
  {
    name: 'MICRONESIA',
    isoCode: 'FM'
  },
  {
    name: 'MOLDOVA',
    isoCode: 'MD'
  },
  {
    name: 'MONACO',
    isoCode: 'MC'
  },
  {
    name: 'MONGOLIA',
    isoCode: 'MN'
  },
  {
    name: 'MONTENEGRO',
    isoCode: 'ME'
  },
  {
    name: 'MONTSERRAT',
    isoCode: 'MS'
  },
  {
    name: 'MOROCCO',
    isoCode: 'MA'
  },
  {
    name: 'MOZAMBIQUE',
    isoCode: 'MZ'
  },
  {
    name: 'MYANMAR',
    isoCode: 'MM'
  },
  {
    name: 'NAMIBIA',
    isoCode: 'NA'
  },
  {
    name: 'NAURU',
    isoCode: 'NR'
  },
  {
    name: 'NEPAL',
    isoCode: 'NP'
  },
  {
    name: 'NETHERLANDS',
    isoCode: 'NL'
  },
  {
    name: 'NETHERLANDS ANTILLES',
    isoCode: 'AN'
  },
  {
    name: 'NEW CALEDONIA',
    isoCode: 'NC'
  },
  {
    name: 'NEW ZEALAND',
    isoCode: 'NZ'
  },
  {
    name: 'NICARAGUA',
    isoCode: 'NI'
  },
  {
    name: 'NIGER',
    isoCode: 'NE'
  },
  {
    name: 'NIGERIA',
    isoCode: 'NG'
  },
  {
    name: 'NIUE',
    isoCode: 'NU'
  },
  {
    name: 'NORFOLK ISLAND',
    isoCode: 'NF'
  },
  {
    name: 'NORTH KOREA',
    isoCode: 'KP'
  },
  {
    name: 'NORTHERN MARIANA ISLANDS',
    isoCode: 'MP'
  },
  {
    name: 'NORWAY',
    isoCode: 'NO'
  },
  {
    name: 'OMAN',
    isoCode: 'OM'
  },
  {
    name: 'PAKISTAN',
    isoCode: 'PK'
  },
  {
    name: 'PALAU',
    isoCode: 'PW'
  },
  {
    name: 'PALESTINIAN TERRITORY OCCUPIED',
    isoCode: 'PS'
  },
  {
    name: 'PANAMA',
    isoCode: 'PA'
  },
  {
    name: 'PAPUA NEW GUINEA',
    isoCode: 'PG'
  },
  {
    name: 'PARAGUAY',
    isoCode: 'PY'
  },
  {
    name: 'PERU',
    isoCode: 'PE'
  },
  {
    name: 'PHILIPPINES',
    isoCode: 'PH'
  },
  {
    name: 'PITCAIRN',
    isoCode: 'PN'
  },
  {
    name: 'POLAND',
    isoCode: 'PL'
  },
  {
    name: 'PORTUGAL',
    isoCode: 'PT'
  },
  {
    name: 'PUERTO RICO',
    isoCode: 'PR'
  },
  {
    name: 'QATAR',
    isoCode: 'QA'
  },
  {
    name: 'REUNION',
    isoCode: 'RE'
  },
  {
    name: 'ROMANIA',
    isoCode: 'RO'
  },
  {
    name: 'RUSSIA',
    isoCode: 'RU'
  },
  {
    name: 'RWANDA',
    isoCode: 'RW'
  },
  {
    name: 'Saint Barth?lemy',
    isoCode: 'BL'
  },
  {
    name: 'SAINT HELENA',
    isoCode: 'SH'
  },
  {
    name: 'SAINT KITTS AND NEVIS',
    isoCode: 'KN'
  },
  {
    name: 'SAINT LUCIA',
    isoCode: 'LC'
  },
  {
    name: 'Saint Martin',
    isoCode: 'MF'
  },
  {
    name: 'SAINT PIERRE AND MIQUELON',
    isoCode: 'PM'
  },
  {
    name: 'SAINT VINCENT AND THE GRENADINES',
    isoCode: 'VC'
  },
  {
    name: 'SAMOA',
    isoCode: 'WS'
  },
  {
    name: 'SAN MARINO',
    isoCode: 'SM'
  },
  {
    name: 'SAO TOME AND PRINCIPE',
    isoCode: 'ST'
  },
  {
    name: 'SAUDI ARABIA',
    isoCode: 'SA'
  },
  {
    name: 'SENEGAL',
    isoCode: 'SN'
  },
  {
    name: 'SERBIA',
    isoCode: 'RS'
  },
  {
    name: 'SERBIA AND MONTENEGRO',
    isoCode: 'CS'
  },
  {
    name: 'SEYCHELLES',
    isoCode: 'SC'
  },
  {
    name: 'SIERRA LEONE',
    isoCode: 'SL'
  },
  {
    name: 'SINGAPORE',
    isoCode: 'SG'
  },
  {
    name: 'Sint Maarten',
    isoCode: 'SX'
  },
  {
    name: 'SLOVAKIA',
    isoCode: 'SK'
  },
  {
    name: 'SLOVENIA',
    isoCode: 'SI'
  },
  {
    name: 'SOLOMON ISLANDS',
    isoCode: 'SB'
  },
  {
    name: 'SOMALIA',
    isoCode: 'SO'
  },
  {
    name: 'SOUTH AFRICA',
    isoCode: 'ZA'
  },
  {
    name: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS',
    isoCode: 'GS'
  },
  {
    name: 'SOUTH KOREA',
    isoCode: 'KR'
  },
  {
    name: 'SPAIN',
    isoCode: 'ES'
  },
  {
    name: 'SRI LANKA',
    isoCode: 'LK'
  },
  {
    name: 'SUDAN',
    isoCode: 'SD'
  },
  {
    name: 'SURINAME',
    isoCode: 'SR'
  },
  {
    name: 'SVALBARD AND JANMAYEN',
    isoCode: 'SJ'
  },
  {
    name: 'SWAZILAND',
    isoCode: 'SZ'
  },
  {
    name: 'SWEDEN',
    isoCode: 'SE'
  },
  {
    name: 'SWITZERLAND',
    isoCode: 'CH'
  },
  {
    name: 'SYRIA',
    isoCode: 'SY'
  },
  {
    name: 'TAIWAN',
    isoCode: 'TW'
  },
  {
    name: 'TAJIKISTAN',
    isoCode: 'TJ'
  },
  {
    name: 'TANZANIA',
    isoCode: 'TZ'
  },
  {
    name: 'THAILAND',
    isoCode: 'TH'
  },
  {
    name: 'TIMOR-LESTE',
    isoCode: 'TL'
  },
  {
    name: 'TOGO',
    isoCode: 'TG'
  },
  {
    name: 'TOKELAU',
    isoCode: 'TK'
  },
  {
    name: 'TONGA',
    isoCode: 'TO'
  },
  {
    name: 'TRINIDAD ANDTOBAGO',
    isoCode: 'TT'
  },
  {
    name: 'TUNISIA',
    isoCode: 'TN'
  },
  {
    name: 'TURKEY',
    isoCode: 'TR'
  },
  {
    name: 'TURKMENISTAN',
    isoCode: 'TM'
  },
  {
    name: 'TURKS AND CAICOSISLANDS',
    isoCode: 'TC'
  },
  {
    name: 'TUVALU',
    isoCode: 'TV'
  },
  {
    name: 'U.S. VIRGIN ISLANDS',
    isoCode: 'VI'
  },
  {
    name: 'UGANDA',
    isoCode: 'UG'
  },
  {
    name: 'UKRAINE',
    isoCode: 'UA'
  },
  {
    name: 'UNITED ARAB EMIRATES',
    isoCode: 'AE'
  },
  {
    name: 'UNITED KINGDOM',
    isoCode: 'GB'
  },
  {
    name: 'UNITED STATES',
    isoCode: 'US'
  },
  {
    name: 'UNITED STATES MINOR OUTLYING ISLANDS',
    isoCode: 'UM'
  },
  {
    name: 'URUGUAY',
    isoCode: 'UY'
  },
  {
    name: 'UZBEKISTAN',
    isoCode: 'UZ'
  },
  {
    name: 'V1DUMMY',
    isoCode: 'XX'
  },
  {
    name: 'VANUATU',
    isoCode: 'VU'
  },
  {
    name: 'VATICAN',
    isoCode: 'VA'
  },
  {
    name: 'VENEZUELA',
    isoCode: 'VE'
  },
  {
    name: 'VIETNAM',
    isoCode: 'VN'
  },
  {
    name: 'WALLIS AND FUTUNA',
    isoCode: 'WF'
  },
  {
    name: 'WESTERN SAHARA',
    isoCode: 'EH'
  },
  {
    name: 'YEMEN',
    isoCode: 'YE'
  },
  {
    name: 'ZAMBIA',
    isoCode: 'ZM'
  },
  {
    name: 'ZIMBABWE',
    isoCode: 'ZW'
  }
];
