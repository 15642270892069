import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Button, Col, Collapse, Input, List, Row, Table, Typography, Modal } from 'antd';
import { Avatar, DashboardContent, DisplayDate, Link, Pagination } from '@components';

import { useOrganisations } from '@libs/hooks/organisation';
import { DeleteOutlined } from '@ant-design/icons';

import debounce from 'lodash.debounce';

const DeleteModal = ({ showModal, onCancel, onSubmit, cb, loading, ...props }) => {
  const { t } = useTranslation();

  return (
    <Modal
      className="admin-collectible-modal"
      visible={showModal}
      footer={null}
      width={508}
      onCancel={onCancel}
      {...props}
    >
      <div style={{ textAlign: 'center' }}>
        <DeleteOutlined className="admin-organization-modal__icon" style={{ color: '#D6222A' }} />
        <h2>{t('organizations.modal.delete.title')}</h2>
        <p className="admin-organization-modal__desc">{t('organizations.modal.delete.desc')}</p>
        <Button
          danger
          className="admin-organization-modal__button admin-organization-modal__button-error"
          onClick={() => {
            onSubmit();
            cb && cb();
          }}
          loading={loading}
        >
          {t('collectibles.modal.button.delete')}
        </Button>
        <Button
          className="admin-organization-modal__button admin-organization-modal__button-default"
          onClick={onCancel}
          type="default"
        >
          {t('collectibles.modal.button.cancel')}
        </Button>
      </div>
    </Modal>
  );
};

function Organization() {
  const { t } = useTranslation();

  const { Panel } = Collapse;
  const { Search } = Input;
  const { Text } = Typography;

  const [deleteData, setDeleteData] = useState(false);

  const columns = [
    {
      title: t('organizations.table.team'),
      dataIndex: 'collectibleMobile',
      key: 'collectibleMobile',
      responsive: ['xs']
    },
    {
      title: t('organizations.table.team'),
      dataIndex: 'organization',
      key: 'organization',
      responsive: ['sm']
    },
    {
      title: t('organizations.table.legal'),
      dataIndex: 'legalName',
      key: 'legalName',
      responsive: ['sm']
    },
    {
      title: t('organizations.table.manager'),
      dataIndex: 'manager',
      key: 'manager',
      width: 230,
      responsive: ['md']
    },
    {
      title: t('organizations.table.address'),
      dataIndex: 'address',
      key: 'address',
      width: 230,
      responsive: ['md']
    },
    {
      title: t('organizations.table.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      responsive: ['lg']
    }
  ];

  const [resetPagination, setResetPagination] = useState(false);
  const {
    data,
    loading: dataLoading,
    deleteLoading,
    nextPageToken,
    onDelete,
    onSearch,
    onNext
  } = useOrganisations();

  const handleSearch = async (data) => {
    setResetPagination(!resetPagination);
    onSearch(data);
  };

  const dataSource =
    dataLoading && data === []
      ? []
      : data?.map((row, i) => {
          return {
            key: `organization-${i}`,
            collectibleMobile: (
              <div className="mobile-row">
                <div className="mobile-row__info">
                  <div className="mobile-row__info__name">{row.name}</div>
                  <div className="meta">
                    <span style={{ cursor: 'pointer' }} onClick={() => setDeleteData(row.id)}>
                      {t('organizations.btn.delete')}
                    </span>
                  </div>
                </div>
                <Collapse collapsible ghost className="datalist">
                  <Panel header={t('collectibles.list.expand')} key="1">
                    <List itemLayout="vertical">
                      <List.Item>
                        <List.Item.Meta
                          title={t('organizations.table.legal')}
                          description={row.legalName}
                        />
                        <List.Item.Meta
                          title={t('organizations.table.manager')}
                          description={
                            <div>
                              <Avatar user={row.currentOwner} />
                              <Text
                                style={{ marginLeft: 10 }}
                              >{`${row.currentOwner.firstName} ${row.currentOwner.lastName}`}</Text>
                            </div>
                          }
                        />
                        <List.Item.Meta
                          title={t('organizations.table.address')}
                          description={row.address}
                        />
                      </List.Item>
                    </List>
                  </Panel>
                </Collapse>
              </div>
            ),
            organization: (
              <>
                <div className="title">
                  <Text>{row.name}</Text>
                </div>
                <div className="meta">
                  <span style={{ cursor: 'pointer' }} onClick={() => setDeleteData(row.id)}>
                    <Link to={`/admin/organization/${row.id}`}>
                      {t('organizations.btn.detail')}
                    </Link>
                  </span>
                  <span style={{ cursor: 'pointer' }} onClick={() => setDeleteData(row.id)}>
                    <Link to={`/admin/organization/edit/${row.id}`}>
                      {t('organizations.btn.edit')}
                    </Link>
                  </span>
                  <span style={{ cursor: 'pointer' }} onClick={() => setDeleteData(row.id)}>
                    {t('organizations.btn.delete')}
                  </span>
                </div>
              </>
            ),
            legalName: row.legalName,
            manager: (
              <div>
                <Avatar user={row.currentOwner} />
                <Text
                  style={{ marginLeft: 10 }}
                >{`${row.currentOwner.firstName} ${row.currentOwner.lastName}`}</Text>
              </div>
            ),
            address: row.address,
            createdAt: <DisplayDate date={row.createdAt} />
          };
        });

  return (
    <>
      <DashboardContent title={t('organizations.title.index')}>
        <Row className="toolbar">
          <Col span={24} lg={12} className="toolbar__left">
            <Button type="primary">
              <Link to="/admin/organization/edit">{t('organizations.btn.add')}</Link>
            </Button>
          </Col>
          <Col span={24} lg={12} className="toolbar__right">
            <div className="toolbar-search">
              <Search
                onChange={debounce((e) => {
                  handleSearch(e.target.value);
                }, 500)}
                placeholder={t('organizations.form.search')}
              />
            </div>
          </Col>
        </Row>
        <div className="manage-content">
          <div className="fans-table">
            <Table
              bordered
              loading={dataLoading}
              dataSource={dataSource}
              columns={columns}
              pagination={false}
            />
            <Pagination
              nextToken={nextPageToken}
              loading={dataLoading}
              resetPagination={resetPagination}
              fetchData={(nextPageToken) => {
                onNext(nextPageToken);
              }}
            />
            <DeleteModal
              showModal={deleteData}
              onCancel={() => setDeleteData(null)}
              onSubmit={() => {
                onDelete(deleteData, () => setDeleteData(null));
              }}
              loading={deleteLoading}
            />
          </div>
        </div>
      </DashboardContent>
      <Helmet>
        <title>{t('organizations.title.index')}</title>
      </Helmet>
    </>
  );
}

export default Organization;
