import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Spin,
  Tabs,
  Dropdown,
  Menu
} from 'antd';
import {
  CameraOutlined,
  EditOutlined,
  LoadingOutlined,
  CloseOutlined,
  VideoCameraAddOutlined,
  MinusCircleOutlined,
  CheckOutlined
} from '@ant-design/icons';
import { Helmet } from 'react-helmet';

import { DashboardContent, Image, Cropper, Player, VideoModal, Avatar } from '@components';
import { useProfileOrganisation, useOrganizationStaffs } from '@libs/hooks/organisation';
import { useUser } from '@libs/hooks/user';
import { useAuth } from '@libs/contexts/auth';
import useToast from '@libs/utils/toast';
import { useUploader, useImageResizer } from '@libs/utils/uploader';
import {
  FACEBOOK_PATTERN,
  TWITTER_PATTERN,
  INSTAGRAM_PATTERN,
  YOUTUBE_PATTERN,
  SOUNDCLOUD_PATTERN,
  TIKTOK_PATTERN,
  SNAPCHAT_PATTERN,
  SPOTIFY_PATTERN,
  APPLEMUSIC_PATTERN
} from './utils';
import PlayButton from '@assets/img/play-button.svg';

const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;
const loadingIcon = <LoadingOutlined style={{ fontSize: 20, margin: 5 }} spin />;

function AddCreator() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const toast = useToast();
  const [form] = Form.useForm();

  const { onCreate, loading: formLoading } = useProfileOrganisation();
  const { data: editors } = useOrganizationStaffs();

  const { isUsernameAvailable } = useUser();

  const handleFinishUploadAvatar = (props) => {
    setAvatarModal(true);
  };
  const {
    inputRef: avatarRef,
    onClick: handleUploadAvatar,
    onUpload: onUploadAvatar,
    onDelete: onDeleteAvatar,
    validationError: validationErrorAvatar,
    result: avatarResult,
    loading: avatarLoading
  } = useUploader(handleFinishUploadAvatar);

  const { loading: imageResizeLoading, onResize } = useImageResizer();

  const handleFinishUploadCover = (props) => {
    setCoverModal(true);
  };
  const {
    inputRef: coverRef,
    onClick: handleUploadCover,
    onUpload: onUploadCover,
    onDelete: onDeleteCover,
    validationError: validationErrorCover,
    result: coverResult,
    loading: coverLoading
  } = useUploader(handleFinishUploadCover);

  const handleFinishUploadAvatarVideo = (props) => {
    setAvatarVideoUrl(props.s3?.key);
  };
  const {
    inputRef: avatarVideoRef,
    onClick: handleUploadAvatarVideo,
    onUpload: onUploadAvatarVideo,
    onDelete: onDeleteAvatarVideo,
    validationError: validationErrorAvatarVideo,
    loading: avatarVideoLoading
  } = useUploader(handleFinishUploadAvatarVideo);

  const handleFinishUploadFanclubLogo = () => {
    setFanclubLogoModal(true);
  };
  const {
    inputRef: fanclubLogoRef,
    onClick: handleUploadFanclubLogo,
    onUpload: onUploadFanclubLogo,
    onDelete: onDeleteFanclubLogo,
    validationError: validationErrorFanclubLogo,
    result: fanclubLogoResult,
    loading: fanclubLogoLoading
  } = useUploader(handleFinishUploadFanclubLogo);

  const [avatarModal, setAvatarModal] = useState(false);
  const [avatarVideoModal, setAvatarVideoModal] = useState(false);
  const [coverModal, setCoverModal] = useState(false);
  const [fanclubLogoModal, setFanclubLogoModal] = useState(false);
  const [editorsValue, setEditorsValue] = useState([]);
  const [editorsValueObject, setEditorsValueObject] = useState([]);
  const [editorsOptions, setEditorsOptions] = useState([]);
  const [avatarUrl, setAvatarUrl] = useState();
  const [editSalesFee, setEditSalesFee] = useState(false);
  const [salesFeeValue, setSalseFeeValue] = useState(20);
  const [editSalesFeeValue, setEditSalesFeeValue] = useState();
  const [royaltyFeeValue, setRoyaltyFeeValue] = useState(2.5);
  const [editRoyaltyFeeValue, setEditRoyaltyFeeValue] = useState();
  const [editRoyaltyFee, setEditRoyaltyFee] = useState(false);
  const [avatarVideoUrl, setAvatarVideoUrl] = useState();
  const [coverUrl, setCoverUrl] = useState();
  const [fanclubLogoUrl, setFanclubLogoUrl] = useState();
  const [firstNameLength, setFirstNameLength] = useState('0');
  const [lastNameLength, setLastNameLength] = useState('0');
  const [displayNameLength, setDisplayNameLength] = useState('0');
  const [usernameLength, setUsernameLength] = useState('0');
  const [bioLength, setBioLength] = useState('0');

  const onSubmit = (data) => {
    const payload = {
      ...data,
      avatarUrl,
      coverUrl,
      fanclubLogoUrl,
      avatarVideoUrl: avatarVideoUrl?.replace('temps/', ''),
      salesFee: salesFeeValue,
      royaltyFee: royaltyFeeValue,
      editors: editorsValue
    };
    onCreate(payload);
  };

  const handleDeleteAvatar = async () => {
    const deleteRes = await onDeleteAvatar();
    if (deleteRes) {
      setAvatarUrl('');
    }
  };

  const handleDeleteAvatarVideo = async () => {
    const deleteRes = await onDeleteAvatarVideo();
    if (deleteRes) {
      setAvatarVideoUrl('');
    }
  };

  const handleDeleteCover = async () => {
    const deleteRes = await onDeleteCover();
    if (deleteRes) {
      setCoverUrl('');
    }
  };

  const handleDeleteFanclubLogo = async () => {
    const deleteRes = await onDeleteFanclubLogo();
    if (deleteRes) {
      setFanclubLogoUrl('');
    }
  };

  useEffect(() => {
    if (editors) {
      setEditorsOptions(
        editors?.filter((x) => !editorsValue?.includes(x.userID) && x.roleType >= 11)
      );
      setEditorsValueObject(editors?.filter((x) => editorsValue?.includes(x.userID)));
    }
  }, [editors]); // eslint-disable-line

  const onAddEditors = (data) => {
    setEditorsValue((editorsValue) => [...editorsValue, data?.user?.id]);
    const newObject = editors.filter((x) => x?.userID === data?.userID);
    setEditorsValueObject(editorsValueObject.concat(newObject));
    const newOptions = editorsOptions.filter(
      (x) => ![...editorsValue, data?.userID].includes(x?.user?.id)
    );
    setEditorsOptions(newOptions);
  };

  const onRemoveEditors = (data) => {
    const newEditorsValue = editorsValue.filter((x) => x !== data?.userID);
    setEditorsValue(newEditorsValue);
    const newValueObject = editorsValueObject.filter((x) => x?.userID !== data?.userID);
    setEditorsValueObject(newValueObject);
    const newObject = editors.filter((x) => x?.userID === data?.userID);
    setEditorsOptions(editorsOptions.concat(newObject));
  };

  return (
    <>
      <DashboardContent
        title={
          process.env.REACT_APP_NAME === 'patrons'
            ? t('creators.patrons.add.title')
            : t('creators.add.title')
        }
        backLink="/creators"
      >
        <Form
          onFinish={onSubmit}
          form={form}
          layout="vertical"
          className="admin-form"
          initialValues={{ salesFee: 20, royaltyFee: 2.5 }}
        >
          <Row>
            <Col span={24} lg={16}>
              <Tabs className="creator-tabs" defaultActiveKey="1">
                <TabPane
                  tab={
                    process.env.REACT_APP_NAME === 'patrons'
                      ? t('creators.patrons.creatorDetails')
                      : t('creators.creatorDetails')
                  }
                  key="1"
                >
                  <Row
                    className="add-profile-images"
                    gutter={[20]}
                    style={{ marginBottom: '2rem' }}
                  >
                    <Col span={24}>
                      <Form.Item
                        label={t('creators.fields.cover.label')}
                        validateStatus={validationErrorCover ? 'error' : ''}
                        help={validationErrorCover}
                      >
                        <div className="image-preview-field">
                          {coverLoading && (
                            <Spin
                              className="image-preview-field__loading"
                              indicator={loadingIcon}
                            />
                          )}
                          {coverUrl && (
                            <Image
                              size={1600}
                              className="image-preview-field__image"
                              src={coverUrl}
                              preview={{ visible: false }}
                            />
                          )}
                          {!coverUrl && (
                            <div className="image-preview-field-description">
                              <Button type="text" onClick={handleUploadCover}>
                                <CameraOutlined style={{ fontSize: '30px' }} />
                              </Button>
                              <div
                                className="image-preview-field-description-text"
                                style={{ fontWeight: '700', color: '#1A1A1A' }}
                              >
                                {t('creators.fields.cover.text')}
                              </div>
                              <div className="image-preview-field-description-hint">
                                {process.env.REACT_APP_NAME === 'patrons'
                                  ? t('creators.patrons.fields.cover.extra')
                                  : t('creators.fields.cover.extra')}
                              </div>
                              <input
                                type="file"
                                ref={coverRef}
                                onChange={onUploadCover}
                                style={{ width: 0 }}
                              />
                            </div>
                          )}
                          {coverUrl && (
                            <div className="image-preview-field-cover-update">
                              <Button type="text" onClick={handleUploadCover}>
                                <CameraOutlined style={{ fontSize: '30px', color: 'white' }} />
                              </Button>
                              <input
                                type="file"
                                ref={coverRef}
                                onChange={onUploadCover}
                                style={{ width: 0 }}
                              />
                            </div>
                          )}

                          {coverUrl && (
                            <div className="image-preview-field-remove">
                              <Button type="text">
                                <CloseOutlined
                                  onClick={handleDeleteCover}
                                  style={{ color: 'white', fontSize: '20px' }}
                                />
                              </Button>
                            </div>
                          )}
                          <Modal
                            className="image-preview-field__modal"
                            title={t('creators.fields.cover.set.label')}
                            visible={coverModal}
                            width={600}
                            footer={null}
                            onCancel={() => {
                              setCoverModal(false);
                            }}
                          >
                            {coverResult.fileUrl ? (
                              <Cropper
                                aspect={10 / 1}
                                loading={imageResizeLoading}
                                image={coverResult.fileUrl}
                                onComplete={async (croppedArea) => {
                                  const resized = await onResize({
                                    key: coverResult.s3?.key,
                                    folder: 'covers',
                                    ...croppedArea
                                  });

                                  if (resized) {
                                    setCoverUrl(resized?.key);
                                  }

                                  coverRef.current.value = '';
                                  setCoverModal(false);
                                }}
                                onCancel={() => {
                                  setCoverModal(false);
                                  coverRef.current.value = '';
                                }}
                              />
                            ) : null}
                          </Modal>
                        </div>
                      </Form.Item>
                    </Col>
                    <Col span={24} lg={12}>
                      <Form.Item
                        label={t('creators.fields.avatar.label')}
                        validateStatus={validationErrorAvatar ? 'error' : ''}
                        help={validationErrorAvatar}
                      >
                        <div className="image-preview-avatar-field">
                          {avatarLoading && (
                            <Spin
                              className="image-preview-field__loading"
                              indicator={loadingIcon}
                            />
                          )}
                          {avatarUrl && (
                            <Image
                              size={200}
                              className="image-preview-field__image"
                              src={avatarUrl}
                              preview={{ visible: false }}
                            />
                          )}
                          {!avatarUrl && (
                            <div className="image-preview-avatar-field-description">
                              <Button type="text" onClick={handleUploadAvatar}>
                                <CameraOutlined style={{ fontSize: '30px' }} />
                              </Button>
                              <div
                                className="image-preview-avatar-field-description-text"
                                style={{ fontWeight: '700', color: '#1A1A1A' }}
                              >
                                {t('creators.fields.cover.text')}
                              </div>
                              <div className="image-preview-avatar-field-description-hint">
                                {t('creators.fields.avatar.extra')}
                              </div>
                              <input
                                type="file"
                                ref={avatarRef}
                                onChange={(e) => onUploadAvatar(e, 'avatar')}
                                style={{ width: 0 }}
                              />
                            </div>
                          )}
                          {avatarUrl && (
                            <div className="image-preview-avatar-field-avatar-update">
                              <Button type="text" onClick={handleUploadAvatar}>
                                <CameraOutlined style={{ fontSize: '30px', color: 'white' }} />
                              </Button>
                              <input
                                type="file"
                                ref={avatarRef}
                                onChange={(e) => onUploadAvatar(e, 'avatar')}
                                style={{ width: 0 }}
                              />
                            </div>
                          )}
                          {avatarUrl && (
                            <div className="image-preview-field-remove">
                              <Button type="text">
                                <CloseOutlined
                                  onClick={handleDeleteAvatar}
                                  style={{ color: 'white', fontSize: '20px' }}
                                />
                              </Button>
                            </div>
                          )}
                          <Modal
                            className="image-preview-field__modal"
                            title={t('creators.fields.avatar.set.label')}
                            visible={avatarModal}
                            footer={null}
                            width={600}
                            onCancel={() => {
                              setAvatarModal(false);
                            }}
                          >
                            {avatarResult.fileUrl ? (
                              <Cropper
                                loading={imageResizeLoading}
                                image={avatarResult.fileUrl}
                                onComplete={async (croppedArea) => {
                                  const resized = await onResize({
                                    key: avatarResult.s3?.key,
                                    folder: 'avatars',
                                    ...croppedArea
                                  });

                                  if (resized) {
                                    setAvatarUrl(resized?.key);
                                  }

                                  avatarRef.current.value = '';
                                  setAvatarModal(false);
                                }}
                                onCancel={() => {
                                  setAvatarModal(false);
                                  avatarRef.current.value = '';
                                }}
                              />
                            ) : null}
                          </Modal>
                        </div>
                      </Form.Item>
                    </Col>
                    <Col span={24} lg={12}>
                      <Form.Item
                        label={t('creators.fields.avatarVideo.label')}
                        validateStatus={validationErrorAvatarVideo ? 'error' : ''}
                        help={validationErrorAvatarVideo}
                      >
                        <div className="image-preview-avatar-field">
                          {avatarVideoLoading && (
                            <Spin
                              className="image-preview-field__loading"
                              indicator={loadingIcon}
                            />
                          )}

                          {avatarVideoUrl && (
                            <Player
                              src={avatarVideoUrl}
                              play={false}
                              controls={false}
                              className="image-preview-field__image"
                            />
                          )}
                          {!avatarVideoUrl && (
                            <div className="image-preview-avatar-field-description">
                              <Button type="text" onClick={handleUploadAvatarVideo}>
                                <VideoCameraAddOutlined style={{ fontSize: '30px' }} />
                              </Button>
                              <div
                                className="image-preview-avatar-field-description-text"
                                style={{ fontWeight: '700', color: '#1A1A1A' }}
                              >
                                {t('creators.fields.cover.text')}
                              </div>
                              <div className="image-preview-avatar-field-description-hint">
                                {t('creators.fields.avatarVideo.extra')}
                              </div>
                              <input
                                type="file"
                                ref={avatarVideoRef}
                                onChange={(e) => onUploadAvatarVideo(e, 'video', null, user.id)}
                                style={{ width: 0 }}
                              />
                            </div>
                          )}
                          {avatarVideoUrl && (
                            <div className="image-preview-avatar-field-avatar-update">
                              <Button type="text" onClick={() => setAvatarVideoModal(true)}>
                                <img style={{ paddingBottom: 3 }} src={PlayButton} alt="Play" />
                              </Button>
                            </div>
                          )}
                          {avatarVideoUrl && (
                            <div className="image-preview-field-remove">
                              <Button type="text">
                                <CloseOutlined
                                  onClick={handleDeleteAvatarVideo}
                                  style={{ color: 'white', fontSize: '20px' }}
                                />
                              </Button>
                            </div>
                          )}
                        </div>
                        {avatarVideoUrl && (
                          <VideoModal
                            url={avatarVideoUrl}
                            show={avatarVideoModal}
                            onClose={() => setAvatarVideoModal(false)}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    label={t('creators.fields.firstName.label')}
                    name="firstName"
                    rules={[
                      { required: true, message: t('creators.fields.firstName.required') },
                      { pattern: /^[a-zA-Z\s]*$/, message: t('creators.fields.firstName.invalid') },
                      {
                        max: 50,
                        message: t('creators.fields.firstName.maxLength', {
                          length: firstNameLength
                        })
                      }
                    ]}
                  >
                    <Input
                      placeholder={
                        process.env.REACT_APP_NAME === 'patrons'
                          ? t('creators.patrons.fields.firstName.placeholder')
                          : t('creators.fields.firstName.placeholder')
                      }
                      onChange={(e) => {
                        setFirstNameLength(e.target.value.length.toString());
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t('creators.fields.lastName.label')}
                    name="lastName"
                    rules={[
                      { required: true, message: t('creators.fields.lastName.required') },
                      { pattern: /^[a-zA-Z\s]*$/, message: t('creators.fields.lastName.invalid') },
                      {
                        max: 50,
                        message: t('creators.fields.lastName.maxLength', { length: lastNameLength })
                      }
                    ]}
                  >
                    <Input
                      placeholder={
                        process.env.REACT_APP_NAME === 'patrons'
                          ? t('creators.patrons.fields.lastName.placeholder')
                          : t('creators.fields.lastName.placeholder')
                      }
                      onChange={(e) => {
                        setLastNameLength(e.target.value.length.toString());
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t('creators.fields.displayName.label')}
                    name="displayName"
                    rules={[
                      {
                        pattern: /^[a-zA-Z\s]*$/,
                        message: t('creators.fields.displayName.invalid')
                      },
                      {
                        required:
                          (process.env.REACT_APP_NAME === 'patrons') === 'patrons' ? true : false,
                        message: t('creators.fields.displayName.required')
                      },
                      {
                        max: 50,
                        message: t('creators.fields.displayName.maxLength', {
                          length: displayNameLength
                        })
                      }
                    ]}
                  >
                    <Input
                      placeholder={
                        process.env.REACT_APP_NAME === 'patrons'
                          ? t('creators.patrons.fields.displayName.placeholder')
                          : t('creators.fields.displayName.placeholder')
                      }
                      onChange={(e) => {
                        setDisplayNameLength(e.target.value.length.toString());
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="username"
                    label={t('creators.fields.username.label')}
                    rules={[
                      { required: true, message: t('creators.fields.username.required') },
                      {
                        pattern: /^(?=[a-z0-9._]{0,999}$)(?!.*[_.]{2})[^_.].*[^_.]$/,
                        message: t('creators.fields.username.invalid')
                      },
                      {
                        min: 4,
                        message: t('creators.fields.username.minLength', { length: usernameLength })
                      },
                      {
                        max: 101,
                        message: t('creators.fields.username.maxLength', { length: usernameLength })
                      },
                      {
                        async validator(_, value) {
                          if (!value) {
                            return Promise.resolve();
                          }

                          const isAvailable = await isUsernameAvailable(value);

                          if (!isAvailable) {
                            return Promise.reject(
                              new Error(t('creators.fields.username.taken', { username: value }))
                            );
                          }

                          return Promise.resolve();
                        }
                      }
                    ]}
                  >
                    <Input
                      placeholder={
                        process.env.REACT_APP_NAME === 'patrons'
                          ? t('creators.patrons.fields.username.placeholder')
                          : t('creators.fields.username.placeholder')
                      }
                      onChange={(e) => {
                        setUsernameLength(e.target.value.length.toString());
                      }}
                    />
                  </Form.Item>
                  <Form.Item label={t('creators.fields.category.label')} name="creatorCategory">
                    <Input
                      placeholder={
                        process.env.REACT_APP_NAME === 'patrons'
                          ? t('creators.patrons.fields.category.placeholder')
                          : t('creators.fields.category.placeholder')
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label={t('creators.fields.bio.label')}
                    name="bio"
                    rules={[
                      {
                        max: 800,
                        message: t('creators.fields.bio.maxLength', { length: bioLength })
                      }
                    ]}
                  >
                    <TextArea
                      rows={4}
                      placeholder={
                        process.env.REACT_APP_NAME === 'patrons'
                          ? t('creators.patrons.fields.bio.placeholder')
                          : t('creators.fields.bio.placeholder')
                      }
                      onChange={(e) => {
                        setBioLength(e.target.value.length.toString());
                      }}
                    />
                  </Form.Item>
                  {process.env.REACT_APP_NAME !== 'patrons' && (
                    <Row
                      className="add-profile-images"
                      gutter={[20]}
                      style={{ marginBottom: '2rem' }}
                    >
                      <Col span={24}>
                        <Form.Item
                          label={t('creators.fields.fanclubLogo.label')}
                          validateStatus={validationErrorFanclubLogo ? 'error' : ''}
                          help={validationErrorFanclubLogo}
                        >
                          <div className="image-preview-field">
                            {fanclubLogoLoading && (
                              <Spin
                                className="image-preview-field__loading"
                                indicator={loadingIcon}
                              />
                            )}
                            {fanclubLogoUrl && (
                              <Image
                                size={800}
                                className="image-preview-field__image"
                                src={fanclubLogoUrl}
                                preview={{ visible: false }}
                                style={{ objectFit: 'cover' }}
                              />
                            )}
                            {!fanclubLogoUrl && (
                              <div className="image-preview-field-description">
                                <Button type="text" onClick={handleUploadFanclubLogo}>
                                  <CameraOutlined style={{ fontSize: '30px' }} />
                                </Button>
                                <div
                                  className="image-preview-field-description-text"
                                  style={{ fontWeight: '700', color: '#1A1A1A' }}
                                >
                                  {t('creators.fields.fanclubLogo.text')}
                                </div>
                                <div className="image-preview-field-description-hint">
                                  {t('creators.fields.fanclubLogo.extra')}
                                </div>
                                <input
                                  type="file"
                                  ref={fanclubLogoRef}
                                  onChange={onUploadFanclubLogo}
                                  style={{ width: 0 }}
                                />
                              </div>
                            )}
                            {fanclubLogoUrl && (
                              <div className="image-preview-field-cover-update">
                                <Button type="text" onClick={handleUploadFanclubLogo}>
                                  <CameraOutlined style={{ fontSize: '30px', color: 'white' }} />
                                </Button>
                                <input
                                  type="file"
                                  ref={fanclubLogoRef}
                                  onChange={onUploadFanclubLogo}
                                  style={{ width: 0 }}
                                />
                              </div>
                            )}

                            {fanclubLogoUrl && (
                              <div className="image-preview-field-remove">
                                <Button type="text">
                                  <CloseOutlined
                                    onClick={handleDeleteFanclubLogo}
                                    style={{ color: 'white', fontSize: '20px' }}
                                  />
                                </Button>
                              </div>
                            )}
                            <Modal
                              className="image-preview-field__modal"
                              title={t('creators.fields.fanclubLogo.set.label')}
                              visible={fanclubLogoModal}
                              width={600}
                              footer={null}
                              onCancel={() => {
                                setFanclubLogoModal(false);
                              }}
                            >
                              {fanclubLogoResult.fileUrl ? (
                                <Cropper
                                  aspect={19 / 6}
                                  loading={imageResizeLoading}
                                  image={fanclubLogoResult.fileUrl}
                                  onComplete={async (croppedArea) => {
                                    const resized = await onResize({
                                      key: fanclubLogoResult.s3?.key,
                                      folder: 'fanclub_logos',
                                      ...croppedArea
                                    });

                                    if (resized) {
                                      setFanclubLogoUrl(resized?.key);
                                    }

                                    fanclubLogoRef.current.value = '';
                                    setFanclubLogoModal(false);
                                  }}
                                  onCancel={() => {
                                    setFanclubLogoModal(false);
                                    fanclubLogoRef.current.value = '';
                                  }}
                                />
                              ) : null}
                            </Modal>
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </TabPane>
                <TabPane tab={t('creators.socialMedia')} key="2">
                  <Form.Item
                    label={t('creators.fields.email.label')}
                    name="email"
                    rules={[{ type: 'email', message: t('creators.fields.email.invalid') }]}
                  >
                    <Input placeholder={t('creators.fields.email.placeholder')} />
                  </Form.Item>
                  <Form.Item
                    label={t('creators.fields.website.label')}
                    name="website"
                    rules={[{ type: 'url', message: t('creators.fields.website.invalid') }]}
                  >
                    <Input placeholder={t('creators.fields.website.placeholder')} />
                  </Form.Item>
                  <Form.Item
                    label={t('creators.fields.facebook.label')}
                    name="facebook"
                    rules={[
                      { pattern: FACEBOOK_PATTERN, message: t('creators.fields.facebook.invalid') }
                    ]}
                  >
                    <Input placeholder={t('creators.fields.facebook.placeholder')} />
                  </Form.Item>
                  <Form.Item
                    label={t('creators.fields.twitter.label')}
                    name="twitter"
                    rules={[
                      { pattern: TWITTER_PATTERN, message: t('creators.fields.twitter.invalid') }
                    ]}
                  >
                    <Input placeholder={t('creators.fields.twitter.placeholder')} />
                  </Form.Item>
                  <Form.Item
                    label={t('creators.fields.instagram.label')}
                    name="instagram"
                    rules={[
                      {
                        pattern: INSTAGRAM_PATTERN,
                        message: t('creators.fields.instagram.invalid')
                      }
                    ]}
                  >
                    <Input placeholder={t('creators.fields.instagram.placeholder')} />
                  </Form.Item>
                  <Form.Item
                    label={t('creators.fields.youtube.label')}
                    name="youtube"
                    rules={[
                      { pattern: YOUTUBE_PATTERN, message: t('creators.fields.youtube.invalid') }
                    ]}
                  >
                    <Input placeholder={t('creators.fields.youtube.placeholder')} />
                  </Form.Item>
                  <Form.Item
                    label={t('creators.fields.soundcloud.label')}
                    name="soundcloud"
                    rules={[
                      {
                        pattern: SOUNDCLOUD_PATTERN,
                        message: t('creators.fields.soundcloud.invalid')
                      }
                    ]}
                  >
                    <Input placeholder={t('creators.fields.soundcloud.placeholder')} />
                  </Form.Item>
                  <Form.Item
                    label={t('creators.fields.tiktok.label')}
                    name="tiktok"
                    rules={[
                      { pattern: TIKTOK_PATTERN, message: t('creators.fields.tiktok.invalid') }
                    ]}
                  >
                    <Input placeholder={t('creators.fields.tiktok.placeholder')} />
                  </Form.Item>
                  <Form.Item
                    label={t('creators.fields.snapchat.label')}
                    name="snapchat"
                    rules={[
                      { pattern: SNAPCHAT_PATTERN, message: t('creators.fields.snapchat.invalid') }
                    ]}
                  >
                    <Input placeholder={t('creators.fields.snapchat.placeholder')} />
                  </Form.Item>
                  <Form.Item
                    label={t('creators.fields.spotify.label')}
                    name="spotify"
                    rules={[
                      { pattern: SPOTIFY_PATTERN, message: t('creators.fields.spotify.invalid') }
                    ]}
                  >
                    <Input placeholder={t('creators.fields.spotify.placeholder')} />
                  </Form.Item>
                  <Form.Item
                    label={t('creators.fields.applemusic.label')}
                    name="appleMusic"
                    rules={[
                      {
                        pattern: APPLEMUSIC_PATTERN,
                        message: t('creators.fields.applemusic.invalid')
                      }
                    ]}
                  >
                    <Input placeholder={t('creators.fields.applemusic.placeholder')} />
                  </Form.Item>
                  <Form.Item
                    label={t('creators.fields.clubhouse.label')}
                    name="clubhouse"
                    rules={[{ type: 'url', message: t('creators.fields.clubhouse.invalid') }]}
                  >
                    <Input placeholder={t('creators.fields.clubhouse.placeholder')} />
                  </Form.Item>
                </TabPane>
              </Tabs>
            </Col>
            <Col className="creator-page-right" span={24} lg={8}>
              <Button
                htmlType="submit"
                loading={formLoading}
                className="creator-page-right-button"
                type="primary"
              >
                {t('creators.buttons.save')}
              </Button>
              <div className="creator-page-right-wrapper">
                <div className="creator-page-right-wrapper-title">
                  {t('creators.fields.group.content.fee')}
                </div>
                <div className="creator-page-right-wrapper-fansincfee">
                  <div className="creator-page-right-wrapper-underline"></div>
                  <div className="creator-page-right-wrapper-field">
                    <div className="creator-page-right-wrapper-field-left">
                      {t('creators.fields.salesFee.label')}
                    </div>
                    <div className="creator-page-right-wrapper-field-right-fee">
                      {editSalesFee && (
                        <InputNumber
                          type="number"
                          autoFocus
                          bordered={false}
                          controls={false}
                          placeholder={20}
                          disabled={!user?.isAdmin}
                          onBlur={(e) => {
                            setEditSalesFeeValue(e?.target?.value);
                          }}
                        />
                      )}
                      <div className="creator-page-right-wrapper-field-right-fee-icon">
                        <span className="creator-page-right-wrapper-field-right-fee-icon-percent">
                          {!editSalesFee && salesFeeValue}%
                        </span>
                        {editSalesFee ? (
                          <span style={{ display: 'flex', marginTop: '0.225rem' }}>
                            <CheckOutlined
                              width={12.75}
                              height={12.75}
                              style={{
                                cursor: 'pointer',
                                marginRight: '0.4rem'
                              }}
                              onClick={() => {
                                if (Number(editSalesFeeValue) !== 0) {
                                  setSalseFeeValue(editSalesFeeValue);
                                } else {
                                  toast(t('creators.fields.salesFee.required'), 'error');
                                }
                                setEditSalesFee(false);
                              }}
                            />
                            <CloseOutlined
                              width={9.89}
                              height={10.19}
                              style={{
                                cursor: 'pointer',
                                color: 'red'
                              }}
                              onClick={() => {
                                setEditSalesFee(false);
                              }}
                            />
                          </span>
                        ) : (
                          <EditOutlined
                            style={{ cursor: 'pointer', marginTop: '0.2rem' }}
                            onClick={() => {
                              setEditSalesFee(true);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="creator-page-right-wrapper-underline"></div>
                  <div className="creator-page-right-wrapper-field">
                    <div className="creator-page-right-wrapper-field-left">
                      {t('creators.fields.royaltyFee.label')}
                    </div>
                    <div className="creator-page-right-wrapper-field-right-fee">
                      {editRoyaltyFee && (
                        <InputNumber
                          type="number"
                          autoFocus
                          bordered={false}
                          controls={false}
                          placeholder={2.5}
                          disabled={!user?.isAdmin}
                          onBlur={(e) => {
                            setEditRoyaltyFeeValue(e?.target?.value);
                          }}
                        />
                      )}
                      <div className="creator-page-right-wrapper-field-right-fee-icon">
                        <span className="creator-page-right-wrapper-field-right-fee-icon-percent">
                          {!editRoyaltyFee && royaltyFeeValue}%
                        </span>
                        {editRoyaltyFee ? (
                          <span style={{ display: 'flex', marginTop: '0.225rem' }}>
                            <CheckOutlined
                              width={12.75}
                              height={12.75}
                              style={{
                                cursor: 'pointer',
                                marginRight: '0.4rem'
                              }}
                              onClick={() => {
                                if (Number(editRoyaltyFeeValue) !== 0) {
                                  setRoyaltyFeeValue(editRoyaltyFeeValue);
                                } else {
                                  toast(t('creators.fields.royaltyFee.required'), 'error');
                                }
                                setEditRoyaltyFee(false);
                              }}
                            />
                            <CloseOutlined
                              width={9.89}
                              height={10.19}
                              style={{
                                cursor: 'pointer',
                                color: 'red'
                              }}
                              onClick={() => {
                                setEditRoyaltyFee(false);
                              }}
                            />
                          </span>
                        ) : (
                          <EditOutlined
                            style={{ cursor: 'pointer', marginTop: '0.2rem' }}
                            onClick={() => {
                              setEditRoyaltyFee(true);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="creator-page-right-wrapper">
                <div className="creator-page-right-wrapper-title">
                  {t('creators.fields.editors.label')}
                </div>
                {editorsValueObject?.map((x) => (
                  <div key={x?.user?.id} className="creator-page-right-wrapper-field">
                    <div className="creator-page-right-wrapper-field-left">
                      <div className="creator-page-right-wrapper-editors">
                        <Avatar user={x?.user} />
                        <span className="creator-page-right-wrapper-editors-avatar-name">{`${x?.user.firstName} ${x?.user.lastName}`}</span>
                      </div>
                    </div>
                    <div className="creator-page-right-wrapper-field-right">
                      {x?.roleType < 11 ? (
                        <div className="creator-page-right-wrapper-field-right-owner">
                          {t('creators.fields.editors.owner')}
                        </div>
                      ) : (
                        <span onClick={() => onRemoveEditors(x)}>
                          <MinusCircleOutlined className="creator-page-right-wrapper-field-right-icon" />
                        </span>
                      )}
                    </div>
                  </div>
                ))}
                <Dropdown
                  overlay={
                    <Menu>
                      {editorsOptions.map((x) => {
                        return (
                          <Menu.Item key={x?.user?.id} onClick={() => onAddEditors(x)}>
                            <div>
                              <Avatar user={x?.user} />
                              <span className="creator-page-right-wrapper-editors-avatar-name">{`${x?.user.firstName} ${x?.user.lastName}`}</span>
                            </div>
                          </Menu.Item>
                        );
                      })}
                    </Menu>
                  }
                >
                  <Button className="creator-page-right-wrapper-editors-button">
                    {t('creators.fields.editors.addEditors')}
                  </Button>
                </Dropdown>
              </div>
              <div className="creator-page-right-wrapper">
                <div className="creator-page-right-wrapper-title">
                  {t('creators.fields.profileTemplate.label')}
                </div>
                <Form.Item name="profileTemplate">
                  <Select optionLabelProp="label" defaultValue="default">
                    {[
                      { value: 'default', label: 'Default' },
                      { value: 'tonymott', label: 'Tony Mott' }
                    ].map((template, i) => (
                      <Option key={`editor-${i}`} value={template.value} label={template.label}>
                        {template.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Button
              htmlType="submit"
              loading={formLoading}
              className="creator-page-right-button-bottom"
              type="primary"
            >
              {t('creators.buttons.save')}
            </Button>
          </Row>
        </Form>
      </DashboardContent>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME === 'patrons'
            ? t('creators.patrons.add.title')
            : t('creators.add.title')}
        </title>
      </Helmet>
    </>
  );
}

export default AddCreator;
