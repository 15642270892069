export const searchInboxes = /* GraphQL */ `
  query SearchInboxes(
    $filter: SearchableInboxFilterInput
    $sort: SearchableInboxSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchInboxes(filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken, from: $from) {
      items {
        id
        inboxID
        fullName
        email
        message
        status
        updatedAt
        createdAt
      }
      nextToken
      total
    }
  }
`;
