import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, useRouteMatch } from 'react-router-dom';
import cx from 'classnames';
import { Badge, Layout, Menu, Skeleton, Select, Row, Col } from 'antd';
import {
  HomeOutlined,
  BellOutlined,
  ContainerOutlined,
  LogoutOutlined,
  SettingOutlined,
  TeamOutlined,
  UserOutlined,
  DollarOutlined,
  ThunderboltOutlined,
  DownOutlined,
  LoadingOutlined,
  WalletOutlined,
  SolutionOutlined,
  ToolOutlined,
  UserSwitchOutlined,
  ShoppingCartOutlined,
  PrinterOutlined,
  HistoryOutlined,
  CommentOutlined,
  FolderOutlined,
  UnorderedListOutlined,
  MailOutlined,
  QrcodeOutlined
} from '@ant-design/icons';

import { Avatar, Link, Logo, ProtectedRoute } from '@components';

import { useAuth } from '@libs/contexts/auth';
import { useUnreadNotification, useAdminUnreadNotification } from '@libs/hooks/notification';
import { useAdminUnreadInbox } from '@libs/hooks/inbox';
import { useSider } from '@libs/utils/sider';
import {
  useOrganizationAccountUser,
  useOrganizationAccountByUser,
  useOrganisation
} from '@libs/hooks/organisation';

//import DashboardContent from '@pages/dashboard/dashboard';
import Collectibles from '@pages/collectibles';
import AddCollectiblesNew from '@pages/collectibles/add.v2';
import EditCollectiblesNew from '@pages/collectibles/edit.v2';
import CollectiblesDetail from '@pages/collectibles/detail';
import Bids from '@pages/bids';
import Offers from '@pages/offers';
import Performance from '@pages/performance';
import Creators from '@pages/creators';
import AddCreator from '@pages/creators/fansinc/add';
import EditCreator from '@pages/creators/fansinc/edit';
import Balance from '@pages/balance';
import Settings from '@pages/settings';
import UserSettings from '@pages/user-settings';
import Notifications from '@pages/notifications';
import Teams from '@pages/teams';
import CreatorOrderHistory from '@pages/order-history';
import CreatorDetailOrder from '@pages/order-history/detail-order';

import Dummy from '@pages/dummy';
import DummyApproveCollectible from '@pages/dummy/approve-collectible';
import DummyCreateOrganization from '@pages/dummy/create-organization';
import DummyDeleteCollectible from '@pages/dummy/delete-collectible';
import DummyDeleteUser from '@pages/dummy/delete-user';
import DummyUpsertCollectible from '@pages/dummy/bulk-upsert-collectible';
import DummyUpsertCreator from '@pages/dummy/bulk-upsert-creator';
import ApproveWithdrawal from '@pages/admin/withdrawal';
import AdminNotification from '@pages/admin/notifications';
import OrderHistory from '@pages/admin/order-history';
import Transaction from '@pages/admin/transactions';
import AdminCollectibles from '@pages/admin/collectibles';
import AdminDetailCollectibles from '@pages/admin/collectibles/detail';
import AdminUsers from '@pages/admin/users';
import useToast from '@libs/utils/toast';
import DetailOrder from '@pages/admin/order-history/detail-order';
import UserActivities from '@pages/admin/user-activities';
import Organization from '@pages/admin/organization';
import AddOrganization from '@pages/admin/organization/add';
import EditOrganization from '@pages/admin/organization/edit';
import DetailOrganization from '@pages/admin/organization/detail';
import Waitlist from '@pages/admin/waitlist';
import Inbox from '@pages/admin/inbox';
// import { useVlag } from '@vincere/react-vlag';
import FanClub from '@pages/fan-club';
import Commentary from '@pages/commentary';
import EditCollections from '@pages/admin/collections/edit';
import Collections from '@pages/admin/collections';
import AddCollections from '@pages/admin/collections/add';
import Gallery from '@pages/collections';
import { useChannelByOwner } from '@libs/hooks/discourse';
import { useCollectibleByCreator } from '@libs/hooks/collectible';
import GalleyExperience from '@pages/gallery-experience';

function Dashboard() {
  const toast = useToast();
  // const ff = useVlag();
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const { total: unreadInbox } = useAdminUnreadInbox(); // eslint-disable-line
  const { total: unreadNotification } = useUnreadNotification(); // eslint-disable-line
  const { total: unreadAdminNotifications } = useAdminUnreadNotification(); // eslint-disable-line
  const { user, loading: userLoading, logout, getCurrentUser, creators } = useAuth();
  const { data: channels } = useChannelByOwner(creators?.[0]?.id);
  const { data: collectibles } = useCollectibleByCreator(creators?.[0]?.id);
  const defaultChannels = channels?.filter((x) => x.default)[0];
  const { Sider, Content } = Layout;
  const { Option } = Select;

  const { data: accountUser, loading: acountUserLoading } = useOrganizationAccountUser();
  const { data: listAccount } = useOrganizationAccountByUser();
  const { onSwitchOrganisation, loading: switchOrganisationLoading } = useOrganisation();

  const { isCollapse, toggleSider } = useSider();

  const collapseMenu = () => {
    window.innerWidth < 1200 && toggleSider(true);
  };

  const siderToggleClass = {
    siderOpened: !isCollapse,
    siderClosed: isCollapse
  };

  const handleChange = async (value) => {
    await onSwitchOrganisation(value);
    await getCurrentUser();
  };

  useEffect(() => {
    if (user && (!user?.accountID || user?.accountID === 'NULL')) {
      logout();
      toast(t('dashboard.guard.noAccountID'), 'error');
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout className={cx('dash', siderToggleClass)}>
      <Sider
        width={250}
        collapsible
        collapsedWidth={90}
        collapsed={isCollapse}
        className="dash-sider"
        breakpoint="xl"
        onCollapse={(collapsed) => {
          toggleSider(collapsed);
        }}
      >
        <div className="dash-sider__top">
          {process.env.REACT_APP_NAME === 'patrons' ? (
            <div className="dash-sider__top__logo">
              <Logo icon={isCollapse} />
            </div>
          ) : (
            <div className="dash-sider__top__logo">
              <Logo icon={isCollapse} />
            </div>
          )}
          {user && user?.isAdmin && (
            <>
              <div className="dash-sider__group__name sub-title">
                {t('dashboard.sidebar.menu.admin')}
              </div>
              <Menu
                className="dash-sider__menu"
                selectedKeys={[path.split('/')[2]]}
                inlineIndent={0}
                mode="inline"
              >
                <Menu.Item key="collectibles">
                  <Link to="/admin/collectibles" onClick={collapseMenu}>
                    <ContainerOutlined /> <span>{t('dashboard.sidebar.menu.collectibles')}</span>
                  </Link>
                </Menu.Item>
                {process.env.REACT_APP_NAME === 'patrons' && (
                  <Menu.Item key="/admin/collections">
                    <Link to="/admin/collections" onClick={collapseMenu}>
                      <FolderOutlined /> <span>{t('dashboard.sidebar.menu.collections')}</span>
                    </Link>
                  </Menu.Item>
                )}
                <Menu.Item key="organization">
                  <Link to="/admin/organization" onClick={collapseMenu}>
                    <SolutionOutlined />
                    <span>{t('dashboard.sidebar.menu.organizations')}</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="user">
                  <Link to="/admin/users" onClick={collapseMenu}>
                    <UserOutlined /> <span>{t('dashboard.sidebar.menu.users')}</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="user-activities">
                  <Link to="/admin/user-activities" onClick={collapseMenu}>
                    <HistoryOutlined /> <span>{t('dashboard.sidebar.menu.userActivities')}</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="withdrawal">
                  <Link to="/admin/withdrawal" onClick={collapseMenu}>
                    <WalletOutlined /> <span>{t('dashboard.sidebar.menu.withdrawal')}</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="transactions">
                  <Link to="/admin/transactions" onClick={collapseMenu}>
                    <ShoppingCartOutlined /> <span>{t('dashboard.sidebar.menu.transactions')}</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="admin-order-history">
                  <Link to="/admin/order-history" onClick={collapseMenu}>
                    <PrinterOutlined /> <span>{t('dashboard.sidebar.menu.printOrder')}</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="notifications">
                  <Link to="/admin/notifications" onClick={collapseMenu}>
                    <Row>
                      <Col span={24}>
                        <div>
                          <BellOutlined />{' '}
                          <span>
                            {t('dashboard.sidebar.menu.notifications')}{' '}
                            {unreadAdminNotifications > 0 && (
                              <div className="dash-sider__top__notif">
                                <Badge
                                  className="dash-sider__top__notif__value"
                                  count={unreadAdminNotifications}
                                />
                              </div>
                            )}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Link>
                </Menu.Item>
                {process.env.REACT_APP_NAME === 'patrons' && (
                  <>
                    <Menu.Item key="waitlist">
                      <Link to="/admin/waitlist" onClick={collapseMenu}>
                        <UnorderedListOutlined />
                        <span>{t('dashboard.sidebar.menu.waitlist')}</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="inbox">
                      <Link to="/admin/inbox" onClick={collapseMenu}>
                        <Row>
                          <Col span={24}>
                            <div>
                              <MailOutlined />
                              <span>
                                {t('dashboard.sidebar.menu.inbox')}{' '}
                                {unreadInbox > 0 && (
                                  <div className="dash-sider__top__notif">
                                    <Badge
                                      className="dash-sider__top__notif__value"
                                      count={unreadInbox}
                                    />
                                  </div>
                                )}
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </Link>
                    </Menu.Item>
                  </>
                )}

                {false && (
                  <>
                    <Menu.Item key="/dummy">
                      <Link to="/dummy/" onClick={collapseMenu}>
                        <HomeOutlined /> <span>{t('dashboard.sidebar.menu.dashboard')}</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="/dummy/approve-collectible">
                      <Link to="/dummy/approve-collectible" onClick={collapseMenu}>
                        <ContainerOutlined />{' '}
                        <span>{t('dashboard.sidebar.menu.collectibles')}</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="/dummy/create-organization">
                      <Link to="/dummy/create-organization" onClick={collapseMenu}>
                        <SolutionOutlined />{' '}
                        <span>{t('dashboard.sidebar.menu.organizations')}</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="/dummy/delete-user">
                      <Link to="/dummy/delete-user" onClick={collapseMenu}>
                        <UserOutlined /> <span>{t('dashboard.sidebar.menu.users')}</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="/tools">
                      <Link to="/tools" onClick={collapseMenu}>
                        <ToolOutlined /> <span>{t('dashboard.sidebar.menu.tools')}</span>
                      </Link>
                    </Menu.Item>
                  </>
                )}
              </Menu>
            </>
          )}

          <div className="dash-sider__group__name sub-title">
            {process.env.REACT_APP_NAME === 'patrons'
              ? t('dashboard.sidebar.menu.patrons.creator')
              : t('dashboard.sidebar.menu.creator')}
          </div>
          <Menu
            className="dash-sider__menu"
            selectedKeys={[path.split('/')[1]]}
            inlineIndent={0}
            mode="inline"
          >
            <Menu.Item key="collectibles">
              <Link to="/collectibles" onClick={collapseMenu}>
                <ContainerOutlined /> <span>{t('dashboard.sidebar.menu.collectibles')}</span>
              </Link>
            </Menu.Item>
            {user?.type === 'Gallery' && (
              <Menu.Item key="collections">
                <Link to="/collections" onClick={collapseMenu}>
                  <FolderOutlined /> <span>{t('dashboard.sidebar.menu.collections')}</span>
                </Link>
              </Menu.Item>
            )}
            <Menu.Item key="notifications">
              <Link to="/notifications" onClick={collapseMenu}>
                <Row>
                  <Col span={24}>
                    <div>
                      <BellOutlined />{' '}
                      <span>
                        {t('dashboard.sidebar.menu.notifications')}{' '}
                        {unreadNotification > 0 && (
                          <div className="dash-sider__top__notif">
                            <Badge
                              className="dash-sider__top__notif__value"
                              count={unreadNotification}
                            />
                          </div>
                        )}
                      </span>
                    </div>
                  </Col>
                </Row>
              </Link>
            </Menu.Item>
            <Menu.Item key="offers">
              <Link to="/offers" onClick={collapseMenu}>
                <DollarOutlined /> <span>{t('dashboard.sidebar.menu.offers')}</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="bids">
              <Link to="/bids" onClick={collapseMenu}>
                <ThunderboltOutlined /> <span>{t('dashboard.sidebar.menu.bids')}</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="order-history">
              <Link to="/order-history" onClick={collapseMenu}>
                <PrinterOutlined /> <span>{t('dashboard.sidebar.menu.printOrder')}</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="creators">
              <Link to="/creators" onClick={collapseMenu}>
                <UserOutlined />{' '}
                <span>
                  {process.env.REACT_APP_NAME
                    ? t('dashboard.sidebar.menu.patrons.creators')
                    : t('dashboard.sidebar.menu.creators')}
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="balance">
              <Link to="/balance" onClick={collapseMenu}>
                <WalletOutlined /> <span>{t('dashboard.sidebar.menu.balance')}</span>
              </Link>
            </Menu.Item>
            {process.env.REACT_APP_NAME === 'patrons' ? (
              <>
                <Menu.Item key="commentary">
                  <Link
                    to={`/commentary/${creators?.[0]?.id}/channels/${collectibles?.[0]?.id}`}
                    onClick={collapseMenu}
                  >
                    <CommentOutlined /> <span>{t('dashboard.sidebar.menu.commentary')}</span>
                  </Link>
                </Menu.Item>
                {user?.type !== 'Artist' && (
                  <Menu.Item key="gallery-experience">
                    <Link to={`/gallery-experience`} onClick={collapseMenu}>
                      <QrcodeOutlined />{' '}
                      <span>{t('dashboard.sidebar.menu.galleryExperience')}</span>
                    </Link>
                  </Menu.Item>
                )}
              </>
            ) : (
              <Menu.Item key="fan-club">
                <Link
                  to={`/fan-club/${creators?.[0]?.id}/channels/${defaultChannels?.id}`}
                  onClick={collapseMenu}
                >
                  <CommentOutlined /> <span>{t('dashboard.sidebar.menu.fanClub')}</span>
                </Link>
              </Menu.Item>
            )}
          </Menu>
          {user?.type !== 'Artist' && (
            <div className="dash-sider__group">
              <div className="dash-sider__group__name sub-title">
                {t('dashboard.sidebar.menu.organization')}
              </div>
              <Menu
                className="dash-sider__menu"
                selectedKeys={[path.split('/')[1]]}
                inlineIndent={0}
                mode="inline"
              >
                {user?.accountID && listAccount?.length > 1 && (
                  <Menu.Item key="listAccount">
                    <UserSwitchOutlined />
                    <Select
                      className="dash-sider__menu__switch"
                      value={user?.accountID}
                      suffixIcon={
                        !isCollapse ? (
                          switchOrganisationLoading ? (
                            <LoadingOutlined />
                          ) : (
                            <DownOutlined />
                          )
                        ) : null
                      }
                      loading={switchOrganisationLoading}
                      style={{ width: '80%' }}
                      onChange={handleChange}
                    >
                      {listAccount?.map((o, i) => {
                        return (
                          <Option
                            className="account-option"
                            key={`organisation-${i}`}
                            value={o.account.id}
                          >
                            {o.account.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Menu.Item>
                )}
                {accountUser?.roleType === 1 && !acountUserLoading && (
                  <Menu.Item key="settings">
                    <Link to="/settings" onClick={collapseMenu}>
                      <SettingOutlined /> <span>{t('dashboard.sidebar.menu.settings')}</span>
                    </Link>
                  </Menu.Item>
                )}
                <Menu.Item key="teams">
                  <Link to="/teams" onClick={collapseMenu}>
                    <TeamOutlined /> <span>{t('dashboard.sidebar.menu.teams')}</span>
                  </Link>
                </Menu.Item>
              </Menu>
            </div>
          )}
        </div>
        <div className="dash-sider__bottom">
          <Menu
            selectedKeys={path}
            className="dash-sider__menu dash-sider__menu--logout"
            mode="inline"
          >
            <Menu.Item key="/user-settings" className="menu-user">
              <Link to="/user-settings" onClick={collapseMenu}>
                <Avatar user={user} loading={userLoading} />
                <div className="menu-user__name">
                  {userLoading ? (
                    <Skeleton paragraph={false} title={{ width: 120 }} loading active />
                  ) : (
                    `${user?.firstName} ${user?.lastName}`
                  )}
                </div>
              </Link>
            </Menu.Item>
            <Menu.Item key="logout">
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  logout();
                }}
              >
                <LogoutOutlined /> <span>{t('dashboard.sidebar.menu.logout')}</span>
              </Link>
            </Menu.Item>
          </Menu>
        </div>
      </Sider>
      <Layout className="dash-content">
        <div className={cx('dash-content__overlay', siderToggleClass)} onClick={collapseMenu}></div>
        <Content>
          <Switch>
            {/*
            <ProtectedRoute exact path="/dashboard" component={DashboardContent} />
            */}
            <ProtectedRoute exact path="/bids" component={Bids} />
            <ProtectedRoute exact path="/collectibles/edit" component={AddCollectiblesNew} />
            <ProtectedRoute exact path="/collectibles/edit/:id" component={EditCollectiblesNew} />
            <ProtectedRoute exact path="/collectibles/details/:id" component={CollectiblesDetail} />
            <ProtectedRoute exact path="/collectibles" component={Collectibles} />
            <ProtectedRoute exact path="/performance" component={Performance} />
            <ProtectedRoute exact path="/creators" component={Creators} />
            <ProtectedRoute exact path="/creators/edit" component={AddCreator} />
            <ProtectedRoute exact path="/creators/edit/:id" component={EditCreator} />
            <ProtectedRoute exact path="/balance" component={Balance} />
            <ProtectedRoute exact path="/offers" component={Offers} />
            <ProtectedRoute exact path="/settings" component={Settings} />
            <ProtectedRoute exact path="/user-settings" component={UserSettings} />
            <ProtectedRoute exact path="/notifications" component={Notifications} />
            <ProtectedRoute exact path="/teams" component={Teams} />
            <ProtectedRoute exact path="/order-history" component={CreatorOrderHistory} />
            <ProtectedRoute exact path="/order-history/:id" component={CreatorDetailOrder} />
            <ProtectedRoute
              exact
              path="/fan-club/:creatorID/channels/:channelID"
              component={FanClub}
            />
            <ProtectedRoute
              exact
              path="/commentary/:creatorID/channels/:channelID"
              component={Commentary}
            />
            <ProtectedRoute exact path="/dummy" component={Dummy} />
            <ProtectedRoute
              exact
              path="/dummy/approve-collectible"
              component={DummyApproveCollectible}
            />
            <ProtectedRoute
              exact
              path="/dummy/create-organization"
              component={DummyCreateOrganization}
            />
            <ProtectedRoute
              exact
              path="/dummy/delete-collectible"
              component={DummyDeleteCollectible}
            />
            <ProtectedRoute exact path="/dummy/delete-user" component={DummyDeleteUser} />
            <ProtectedRoute
              exact
              path="/dummy/upsert-collectible"
              component={DummyUpsertCollectible}
            />
            <ProtectedRoute exact path="/dummy/upsert-creator" component={DummyUpsertCreator} />
            <ProtectedRoute exact path="/admin/withdrawal" component={ApproveWithdrawal} />
            <ProtectedRoute exact path="/admin/notifications" component={AdminNotification} />
            <ProtectedRoute exact path="/admin/order-history" component={OrderHistory} />
            <ProtectedRoute exact path="/admin/order-history/:id" component={DetailOrder} />
            <ProtectedRoute exact path="/admin/transactions" component={Transaction} />
            <ProtectedRoute
              exact
              path="/admin/collectibles/:id"
              component={AdminDetailCollectibles}
            />
            <ProtectedRoute exact path="/admin/collectibles" component={AdminCollectibles} />
            <ProtectedRoute exact path="/admin/user-activities" component={UserActivities} />
            <ProtectedRoute exact path="/admin/organization" component={Organization} />
            <ProtectedRoute exact path="/admin/organization/edit" component={AddOrganization} />
            <ProtectedRoute exact path="/admin/organization/:id" component={DetailOrganization} />
            <ProtectedRoute
              exact
              path="/admin/organization/edit/:id"
              component={EditOrganization}
            />
            <ProtectedRoute exact path="/admin/users" component={AdminUsers} />
            {process.env.REACT_APP_NAME === 'patrons' && (
              <>
                <ProtectedRoute
                  exact
                  path="/admin/collections/edit/:id"
                  component={EditCollections}
                />
                <ProtectedRoute exact path="/admin/collections/edit" component={AddCollections} />
                <ProtectedRoute exact path="/admin/collections" component={Collections} />
                <ProtectedRoute exact path="/collections" component={Gallery} />
                <ProtectedRoute exact path="/admin/waitlist" component={Waitlist} />
                <ProtectedRoute exact path="/admin/inbox" component={Inbox} />
                <ProtectedRoute exact path="/gallery-experience" component={GalleyExperience} />
              </>
            )}
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Dashboard;
