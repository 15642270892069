import { useTranslation } from 'react-i18next';
import { Form, Input, Button, Select } from 'antd';
import { Helmet } from 'react-helmet';
import { DashboardContent, UnauthorizedAccess } from '@components';
import { useOrganisation, useOrganizationAccountUser } from '@libs/hooks/organisation';
import countries from '@libs/json/countries.json';

const { TextArea } = Input;
const { Option } = Select;

function Settings() {
  const { t } = useTranslation();
  const { data: organisation, onUpdate, loading } = useOrganisation();
  const { data: accountUser, loading: accountUserLoading } = useOrganizationAccountUser();

  const onSubmit = (values) => {
    onUpdate(values);
  };
  if (accountUser?.roleType >= 11 && !accountUserLoading) {
    return <UnauthorizedAccess />;
  }
  return (
    <>
      <DashboardContent title={t('settings.title')}>
        {!organisation ? null : (
          <Form
            onFinish={onSubmit}
            initialValues={organisation}
            layout="vertical"
            className="admin-form"
          >
            <Form.Item
              label={t('settings.fields.name.label')}
              name="name"
              rules={[{ required: true, message: t('settings.fields.name.required') }]}
            >
              <Input defaultValue={organisation?.name} />
            </Form.Item>
            <Form.Item
              label={t('settings.fields.legalName.label')}
              name="legalName"
              rules={[{ required: true, message: t('settings.fields.legalName.required') }]}
            >
              <Input defaultValue={organisation?.legalName} />
            </Form.Item>
            <Form.Item
              label={t('settings.fields.address.label')}
              name="address"
              rules={[{ required: true, message: t('settings.fields.address.required') }]}
            >
              <TextArea rows={4} defaultValue={organisation?.address} />
            </Form.Item>
            <Form.Item
              label={t('settings.fields.country.label')}
              name="country"
              rules={[{ required: true, message: t('settings.fields.country.required') }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {countries &&
                  countries.map((country) => (
                    <Option key={`type-${country.value}`} value={country.value}>
                      {country.label}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={t('settings.fields.state.label')}
              name="state"
              rules={[{ required: true, message: t('settings.fields.state.required') }]}
            >
              <Input defaultValue={organisation?.state} />
            </Form.Item>
            <Form.Item
              label={t('settings.fields.city.label')}
              name="city"
              rules={[{ required: true, message: t('settings.fields.city.required') }]}
            >
              <Input defaultValue={organisation?.city} />
            </Form.Item>
            <Form.Item
              label={t('settings.fields.postcode.label')}
              name="postcode"
              rules={[{ required: true, message: t('settings.fields.postcode.required') }]}
            >
              <Input defaultValue={organisation?.postcode} />
            </Form.Item>
            <Form.Item
              label={t('settings.fields.phone.label')}
              name="phone"
              rules={[
                { required: true, message: t('settings.fields.phone.required') },
                { pattern: /^(?:\d*)$/, message: t('settings.fields.phone.numberValidation') }
              ]}
            >
              <Input defaultValue={organisation?.phone} />
            </Form.Item>
            <Form.Item
              label={t('settings.fields.website.label')}
              name="website"
              rules={[
                { required: true, message: t('settings.fields.website.required') },
                { type: 'url', message: t('settings.fields.website.url') }
              ]}
            >
              <Input placeholder="https://yourwebsite.com" defaultValue={organisation?.website} />
            </Form.Item>
            <Form.Item className="settings-button">
              <Button type="primary" htmlType="submit" loading={loading}>
                {t('settings.buttons.save')}
              </Button>
            </Form.Item>
          </Form>
        )}
      </DashboardContent>
      <Helmet>
        <title>{t('settings.title')}</title>
      </Helmet>
    </>
  );
}

export default Settings;
