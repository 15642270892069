import { useEffect, useState } from 'react';
import gql from '@libs/utils/gql';
import useToast from '@libs/utils/toast';
import { searchHistoryOrderPrints } from '@libs/custom-queries/order-print';
import { useProfileOrganisation } from '@libs/hooks/organisation';

export const useOrderHistory = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [nextPageToken, setNextPageToken] = useState();

  const getData = async (nextToken, orderID) => {
    setLoading(true);
    try {
      const params = {
        limit: 10,
        sort: { direction: 'desc', field: 'createdAt' }
      };
      if (orderID) {
        params.limit = undefined;
        params.nextToken = undefined;
        params.filter = { prodigiOrderID: { matchPhrasePrefix: orderID } };
      }
      if (nextToken) {
        params.nextToken = nextToken;
      }
      const { data: res } = await gql(searchHistoryOrderPrints, params);
      if (res?.searchOrderPrints?.items?.length) {
        setData(res?.searchOrderPrints?.items);
        if (res?.searchOrderPrints?.items?.length < 10) {
          setNextPageToken();
        } else {
          setNextPageToken(res?.searchOrderPrints?.nextToken);
        }
      } else {
        setData([]);
        setNextPageToken();
      }
    } catch (error) {
      toast(error.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading,
    data,
    getData,
    nextPageToken
  };
};

export const useOrderHistoryByCreator = () => {
  const toast = useToast();
  const { data: profiles } = useProfileOrganisation(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [nextPageToken, setNextPageToken] = useState();

  const getData = async (nextToken, orderID) => {
    setLoading(true);
    try {
      const params = {
        limit: 10,
        sort: { direction: 'desc', field: 'createdAt' },
        filter: {
          or: profiles.map((c) => ({ creatorID: { eq: c.value } }))
        }
      };
      if (orderID) {
        params.limit = undefined;
        params.nextToken = undefined;
        params.filter = { prodigiOrderID: { matchPhrasePrefix: orderID } };
      }
      if (nextToken) {
        params.nextToken = nextToken;
      }
      const { data: res } = await gql(searchHistoryOrderPrints, params);
      if (res?.searchOrderPrints?.items?.length) {
        setData(res?.searchOrderPrints?.items);
        if (res?.searchOrderPrints?.items?.length < 10) {
          setNextPageToken();
        } else {
          setNextPageToken(res?.searchOrderPrints?.nextToken);
        }
      } else {
        setData([]);
        setNextPageToken();
      }
    } catch (error) {
      toast(error.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (profiles.length > 0) {
      getData();
    }
  }, [profiles]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading,
    data,
    getData,
    nextPageToken
  };
};

export const useOrderHistoryById = (orderID) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const getData = async () => {
    setLoading(true);
    try {
      const params = {
        filter: { id: { eq: orderID } }
      };
      const { data: res } = await gql(searchHistoryOrderPrints, params);
      if (res?.searchOrderPrints?.items?.length) {
        setData(res?.searchOrderPrints?.items[0]);
      } else {
        setData([]);
      }
    } catch (error) {
      toast(error.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [orderID]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading,
    data,
    getData
  };
};
