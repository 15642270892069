import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  pageWrapper: {
    color: '#333333',
    backgroundColor: 'white'
  },
  page: {
    width: '100%',
    height: '100%'
  },
  pageContent: {
    padding: '50px',
    backgroundColor: 'white',
    height: '100%',
    width: '100%'
  },
  bodyWrapper: {
    marginTop: '20px'
  },
  headerWrapper: {
    paddingBottom: '50px',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%'
  },
  standardText: {
    fontSize: '9px',
    marginBottom: '2px',
    fontWeight: 400,
    fontFamily: 'Helvetica'
  },
  textSecondary: {
    color: '#A3A3A3'
  },
  my8: {
    marginBottom: '8px',
    marginTop: '8px'
  },
  textItalic: {
    fontStyle: 'italic'
  },
  standardTextMargin: {
    marginBottom: '6px'
  },
  standardTitle: {
    marginBottom: '6px',
    marginTop: '4px',
    fontSize: '12px',
    fontWeight: 700,
    fontFamily: 'HelveticaBold'
  },
  standardTitleOrg: {
    marginBottom: '2px',
    marginTop: '4px',
    fontSize: '12px',
    fontWeight: 700,
    fontFamily: 'HelveticaBold'
  },
  standardSubtitle: {
    fontSize: '9px',
    fontWeight: 700,
    fontFamily: 'HelveticaBold'
  },
  spacer: {
    height: '24px'
  },
  logoImage: {
    width: '110px',
    marginBottom: '24px',
    marginLeft: 'auto'
  },
  colRight: {
    textAlign: 'right'
  },
  textRight: {
    marginLeft: 'auto'
  },
  textAlignRight: {
    textAlign: 'right'
  },
  tableCol20: {
    width: '20%'
  },
  tableCol50: {
    width: '50%'
  },
  tableCol80: {
    width: '80%'
  },
  tableCol100: {
    width: '100%'
  },
  table: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  tableHeadWrapper: {
    borderBottom: '2px solid #181818'
  },
  tableHead: {
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  tableBody: {
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  tableBodySummary: {
    paddingTop: '5px',
    paddingBottom: '5px'
  },
  pb5: {
    paddingBottom: '5px'
  },
  capitalize: {
    textTransform: 'capitalize'
  }
});

export const formatPrice = (amount) => {
  return (((amount ?? 0) * 100) / 100).toFixed(2);
};

export const captilizeAllWords = (sentence) => {
  if (typeof sentence !== 'string') return sentence;
  return sentence
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const tierMapper = {
  NUMBERED_EDITION: 'Limited Edition',
  DIGITAL_ORIGINAL: 'Digital Original'
};

export const printDescription = (history) => {
  return `Print collectible ${history?.exchange?.collectible?.title} (${
    tierMapper[history?.exchange?.tierType]
  } #${history?.exchange?.serialIndex}) by ${
    history?.exchange?.collectible?.creator?.displayName ||
    history?.exchange?.collectible?.creator?.firstName +
      ' ' +
      history?.exchange?.collectible?.creator?.lastName
  }`;
};
