import { useTranslation } from 'react-i18next';
import { FileImageOutlined } from '@ant-design/icons';
import { ReactComponent as VIP } from '@assets/svg/vip-gold.svg';
import { ReactComponent as VIPBadge } from '@assets/svg/vip-badge.svg';
import { Image } from '@components';

const VIPCard = ({ data, mediaThumbnailUrl }) => {
  const { t } = useTranslation();

  return (
    <div className="vip-card">
      <div className="vip-card__bg">
        <VIP />
      </div>
      <div className="vip-card__content">
        {mediaThumbnailUrl ? (
          <Image className="vip-card__content__image" size={600} src={mediaThumbnailUrl} />
        ) : (
          <div className="vip-card__content__image-placeholder">
            <FileImageOutlined />
            <div>{t('components.collectibleCard.preview.instructionVIP')}</div>
          </div>
        )}
        <div className="vip-card__content__title">
          {data?.title === '' ? <>{t('components.collectibleCard.title')}</> : data?.title}
        </div>
        <div className="vip-card__content__badge">
          <VIPBadge />
        </div>
      </div>
    </div>
  );
};

export default VIPCard;
