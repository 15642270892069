import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Select, Row, Col, Spin, Modal, Switch } from 'antd';
import { Helmet } from 'react-helmet';
import { DashboardContent, Image, Cropper } from '@components';
import { CameraOutlined, LoadingOutlined, CloseOutlined } from '@ant-design/icons';
import { useCollection } from '@libs/hooks/collection';
import { useUploader, useImageResizer } from '@libs/utils/uploader';
import slugify from 'slugify';
import { useCallback } from 'react';

function AddCollections() {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { onCreate, createLoading, isNameAvailable } = useCollection();

  const loadingIcon = <LoadingOutlined style={{ fontSize: 20, margin: 5 }} spin />;
  const handleFinishUploadAvatar = (props) => {
    setAvatarModal(true);
  };
  const {
    inputRef: avatarRef,
    onClick: handleUploadAvatar,
    onUpload: onUploadAvatar,
    onDelete: onDeleteAvatar,
    validationError: validationErrorAvatar,
    result: avatarResult,
    loading: avatarLoading
  } = useUploader(handleFinishUploadAvatar);

  const { loading: imageResizeLoading, onResize } = useImageResizer();
  const handleFinishUploadFanclubLogo = () => {
    setFanclubLogoModal(true);
  };

  const handleFinishUploadCover = (props) => {
    setCoverModal(true);
  };
  const {
    inputRef: coverRef,
    onClick: handleUploadCover,
    onUpload: onUploadCover,
    onDelete: onDeleteCover,
    validationError: validationErrorCover,
    result: coverResult,
    loading: coverLoading
  } = useUploader(handleFinishUploadCover);

  const {
    inputRef: fanclubLogoRef,
    onClick: handleUploadFanclubLogo,
    onUpload: onUploadFanclubLogo,
    onDelete: onDeleteFanclubLogo,
    validationError: validationErrorFanclubLogo,
    result: fanclubLogoResult,
    loading: fanclubLogoLoading
  } = useUploader(handleFinishUploadFanclubLogo);

  const [avatarModal, setAvatarModal] = useState(false);
  const [coverModal, setCoverModal] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState();
  const [coverUrl, setCoverUrl] = useState();
  const [fanclubLogoModal, setFanclubLogoModal] = useState(false);
  const [fanclubLogoUrl, setFanclubLogoUrl] = useState();

  const handleDeleteFanclubLogo = async () => {
    const deleteRes = await onDeleteFanclubLogo();
    if (deleteRes) {
      setFanclubLogoUrl('');
    }
  };

  const handleDeleteAvatar = async () => {
    const deleteRes = await onDeleteAvatar();
    if (deleteRes) {
      setAvatarUrl('');
    }
  };

  const handleDeleteCover = async () => {
    const deleteRes = await onDeleteCover();
    if (deleteRes) {
      setCoverUrl('');
    }
  };

  const onSubmit = useCallback(
    (data) => {
      const payload = {
        ...data,
        avatarUrl: avatarUrl,
        coverUrl: coverUrl,
        logoUrl: fanclubLogoUrl,
        slug: slugify(data.name, {
          lower: true,
          remove: /[*+~%<>/;.(){}?,'"!:@#^|]/g
        }),
        isShowLogo: data?.isShowLogo ? 'TRUE' : 'FALSE'
      };
      onCreate(payload);
    },
    [avatarUrl, coverUrl, fanclubLogoUrl] // eslint-disable-line
  );

  const { Option } = Select;
  return (
    <>
      <DashboardContent title={t('collections.add.title')} backLink="/admin/collections">
        <Row className="add-profile-images" gutter={[20]} style={{ marginBottom: '2rem' }}>
          <Col span={24} lg={8}>
            <Form.Item
              label={t('collections.image1.label')}
              validateStatus={validationErrorCover ? 'error' : ''}
              help={validationErrorCover}
            >
              <div className="image-preview-avatar-field">
                {coverLoading && (
                  <Spin className="image-preview-field__loading" indicator={loadingIcon} />
                )}
                {coverUrl && (
                  <Image
                    size={1600}
                    className="image-preview-field__image"
                    src={coverUrl}
                    preview={{ visible: false }}
                  />
                )}
                {!coverUrl && (
                  <div className="image-preview-field-description">
                    <Button type="text" onClick={handleUploadCover}>
                      <CameraOutlined style={{ fontSize: '30px' }} />
                    </Button>
                    <div
                      className="image-preview-field-description-text"
                      style={{ fontWeight: '700', color: '#1A1A1A' }}
                    >
                      {t('creators.fields.cover.text')}
                    </div>
                    <div className="image-preview-field-description-hint">
                      {t('collections.image1.description')}
                    </div>
                    <input
                      type="file"
                      ref={coverRef}
                      onChange={(e) => onUploadCover(e, 'avatar')}
                      style={{ width: 0 }}
                    />
                  </div>
                )}
                {coverUrl && (
                  <div className="image-preview-field-cover-update">
                    <Button type="text" onClick={handleUploadCover}>
                      <CameraOutlined style={{ fontSize: '30px', color: 'white' }} />
                    </Button>
                    <input
                      type="file"
                      ref={coverRef}
                      onChange={(e) => onUploadCover(e, 'avatar')}
                      style={{ width: 0 }}
                    />
                  </div>
                )}

                {coverUrl && (
                  <div className="image-preview-field-remove">
                    <Button type="text">
                      <CloseOutlined
                        onClick={handleDeleteCover}
                        style={{ color: 'white', fontSize: '20px' }}
                      />
                    </Button>
                  </div>
                )}
                <Modal
                  className="image-preview-field__modal"
                  title={t('creators.fields.cover.set.label')}
                  visible={coverModal}
                  width={600}
                  footer={null}
                  onCancel={() => {
                    setCoverModal(false);
                  }}
                >
                  {coverResult.fileUrl ? (
                    <Cropper
                      aspect={6 / 2}
                      loading={imageResizeLoading}
                      image={coverResult.fileUrl}
                      onComplete={async (croppedArea) => {
                        const resized = await onResize({
                          key: coverResult.s3?.key,
                          folder: 'covers',
                          ...croppedArea
                        });

                        if (resized) {
                          setCoverUrl(resized?.key);
                        }

                        coverRef.current.value = '';
                        setCoverModal(false);
                      }}
                      onCancel={() => {
                        setCoverModal(false);
                        coverRef.current.value = '';
                      }}
                    />
                  ) : null}
                </Modal>
              </div>
            </Form.Item>
          </Col>
          <Col span={24} lg={8}>
            <Form.Item
              label={t('collections.image2.label')}
              validateStatus={validationErrorAvatar ? 'error' : ''}
              help={validationErrorAvatar}
            >
              <div className="image-preview-avatar-field">
                {avatarLoading && (
                  <Spin className="image-preview-field__loading" indicator={loadingIcon} />
                )}
                {avatarUrl && (
                  <Image
                    size={200}
                    className="image-preview-field__image"
                    src={avatarUrl}
                    preview={{ visible: false }}
                  />
                )}
                {!avatarUrl && (
                  <div className="image-preview-avatar-field-description">
                    <Button type="text" onClick={handleUploadAvatar}>
                      <CameraOutlined style={{ fontSize: '30px' }} />
                    </Button>
                    <div
                      className="image-preview-avatar-field-description-text"
                      style={{ fontWeight: '700', color: '#1A1A1A' }}
                    >
                      {t('creators.fields.cover.text')}
                    </div>
                    <div className="image-preview-avatar-field-description-hint">
                      {t('collections.image2.description')}
                    </div>
                    <input
                      type="file"
                      ref={avatarRef}
                      onChange={(e) => onUploadAvatar(e, 'avatar')}
                      style={{ width: 0 }}
                    />
                  </div>
                )}
                {avatarUrl && (
                  <div className="image-preview-avatar-field-avatar-update">
                    <Button type="text" onClick={handleUploadAvatar}>
                      <CameraOutlined style={{ fontSize: '30px', color: 'white' }} />
                    </Button>
                    <input
                      type="file"
                      ref={avatarRef}
                      onChange={(e) => onUploadAvatar(e, 'avatar')}
                      style={{ width: 0 }}
                    />
                  </div>
                )}
                {avatarUrl && (
                  <div className="image-preview-field-remove">
                    <Button type="text">
                      <CloseOutlined
                        onClick={handleDeleteAvatar}
                        style={{ color: 'white', fontSize: '20px' }}
                      />
                    </Button>
                  </div>
                )}
                <Modal
                  className="image-preview-field__modal"
                  title={t('creators.fields.avatar.set.label')}
                  visible={avatarModal}
                  footer={null}
                  width={600}
                  onCancel={() => {
                    setAvatarModal(false);
                  }}
                >
                  {avatarResult.fileUrl ? (
                    <Cropper
                      aspect={5 / 6}
                      loading={imageResizeLoading}
                      image={avatarResult.fileUrl}
                      onComplete={async (croppedArea) => {
                        const resized = await onResize({
                          key: avatarResult.s3?.key,
                          folder: 'avatars',
                          ...croppedArea
                        });

                        if (resized) {
                          setAvatarUrl(resized?.key);
                        }

                        avatarRef.current.value = '';
                        setAvatarModal(false);
                      }}
                      onCancel={() => {
                        setAvatarModal(false);
                        avatarRef.current.value = '';
                      }}
                    />
                  ) : null}
                </Modal>
              </div>
            </Form.Item>
          </Col>
          <Col span={24} lg={8}>
            <Form.Item
              label={t('collections.logo.label')}
              validateStatus={validationErrorFanclubLogo ? 'error' : ''}
              help={validationErrorFanclubLogo}
            >
              <div className="image-preview-avatar-field">
                {fanclubLogoLoading && (
                  <Spin className="image-preview-field__loading" indicator={loadingIcon} />
                )}
                {fanclubLogoUrl && (
                  <Image
                    size={800}
                    className="image-preview-field__image"
                    src={fanclubLogoUrl}
                    preview={{ visible: false }}
                    style={{ objectFit: 'cover' }}
                  />
                )}
                {!fanclubLogoUrl && (
                  <div className="image-preview-field-description">
                    <Button type="text" onClick={handleUploadFanclubLogo}>
                      <CameraOutlined style={{ fontSize: '30px' }} />
                    </Button>
                    <div
                      className="image-preview-field-description-text"
                      style={{ fontWeight: '700', color: '#1A1A1A' }}
                    >
                      {t('creators.fields.fanclubLogo.text')}
                    </div>
                    <div className="image-preview-field-description-hint">
                      {t('collections.logo.description')}
                    </div>
                    <input
                      type="file"
                      ref={fanclubLogoRef}
                      onChange={(e) => onUploadFanclubLogo(e, 'avatar')}
                      style={{ width: 0 }}
                    />
                  </div>
                )}
                {fanclubLogoUrl && (
                  <div className="image-preview-field-cover-update">
                    <Button type="text" onClick={handleUploadFanclubLogo}>
                      <CameraOutlined style={{ fontSize: '30px', color: 'white' }} />
                    </Button>
                    <input
                      type="file"
                      ref={fanclubLogoRef}
                      onChange={(e) => onUploadFanclubLogo(e, 'avatar')}
                      style={{ width: 0 }}
                    />
                  </div>
                )}

                {fanclubLogoUrl && (
                  <div className="image-preview-field-remove">
                    <Button type="text">
                      <CloseOutlined
                        onClick={handleDeleteFanclubLogo}
                        style={{ color: 'white', fontSize: '20px' }}
                      />
                    </Button>
                  </div>
                )}
                <Modal
                  className="image-preview-field__modal"
                  title={t('creators.fields.fanclubLogo.set.label')}
                  visible={fanclubLogoModal}
                  width={600}
                  footer={null}
                  onCancel={() => {
                    setFanclubLogoModal(false);
                  }}
                >
                  {fanclubLogoResult.fileUrl ? (
                    <Cropper
                      loading={imageResizeLoading}
                      image={fanclubLogoResult.fileUrl}
                      onComplete={async (croppedArea) => {
                        const resized = await onResize({
                          key: fanclubLogoResult.s3?.key,
                          folder: 'fanclub_logos',
                          ...croppedArea
                        });

                        if (resized) {
                          setFanclubLogoUrl(resized?.key);
                        }

                        fanclubLogoRef.current.value = '';
                        setFanclubLogoModal(false);
                      }}
                      onCancel={() => {
                        setFanclubLogoModal(false);
                        fanclubLogoRef.current.value = '';
                      }}
                    />
                  ) : null}
                </Modal>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Form onFinish={onSubmit} form={form} layout="vertical" className="admin-form">
          <Col span={24}>
            <Form.Item
              name="isShowLogo"
              label={t('collections.fields.showLogo.label')}
              valuePropName="checked"
              extra={t('collections.fields.showLogo.extra')}
            >
              <Switch
                checkedChildren={t('global.switch.yes')}
                unCheckedChildren={t('global.switch.no')}
              />
            </Form.Item>
          </Col>
          <Row gutter={[20]}>
            <Col span={24} lg={12}>
              <Form.Item
                label={t('collections.fields.name.label')}
                name="name"
                rules={[
                  { required: true, message: t('collections.fields.name.required') },
                  { min: 4, message: t('collections.fields.name.minLength') },
                  { max: 32, message: t('collections.fields.name.maxLength') },
                  {
                    async validator(_, value) {
                      if (!value) {
                        return Promise.resolve();
                      }

                      const slug = slugify(value, {
                        lower: true,
                        remove: /[*+~%<>/;.(){}?,'"!:@#^|]/g
                      });

                      const isAvailable = await isNameAvailable(slug);

                      if (!isAvailable) {
                        return Promise.reject(
                          new Error(t('userSettings.fields.username.taken', { username: value }))
                        );
                      }

                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <Input placeholder={t('collections.fields.name.placeholder')} />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item
                label={t('collections.fields.shortName.label')}
                name="shortName"
                rules={[
                  { required: true, message: t('collections.fields.shortName.required') },
                  { max: 10, message: t('collections.fields.shortName.maxLength') }
                ]}
              >
                <Input placeholder={t('collections.fields.shortName.placeholder')} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label={t('collections.fields.location.label')} name="collectionUrl">
            <Input placeholder={t('collections.fields.location.placeholder')} />
          </Form.Item>
          <Form.Item
            label="Type"
            name="collectionType"
            rules={[{ required: true, message: t('collections.fields.type.required') }]}
          >
            <Select
              placeholder={t('collections.fields.type.placeholder')}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option key="1" value="MUSEUM">
                {t('collections.museum')}
              </Option>
              <Option key="2" value="GALLERY">
                {t('collections.gallery')}
              </Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={createLoading}>
              {t('collections.buttons.save')}
            </Button>
          </Form.Item>
        </Form>
      </DashboardContent>
      <Helmet>
        <title>{t('collections.add.title')}</title>
      </Helmet>
    </>
  );
}

export default AddCollections;
