import { useState, useCallback, useEffect } from 'react';
import { Dropdown, Menu, Button, Modal, Input } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { formatDistanceToNowStrict } from 'date-fns';
import { Avatar } from '@components';
import ChildComment from './child-comment';
import useToast from '@libs/utils/toast';
import { DeleteOutlined } from '@ant-design/icons';
import { useReplyCommentCollectible } from '@libs/hooks/discourse';

function Comment({ user, comment, totalComment, setTotalComment, removeComment }) {
  const { t } = useTranslation();
  const { TextArea } = Input;
  const toast = useToast();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCreateComment, setShowCreateComment] = useState(false);
  const [inputComment, setInputComment] = useState('');
  const [listComment, setListComment] = useState(
    comment?.replies?.items
      ?.map((childComment) => {
        return {
          ...childComment.post,
          replyID: childComment.id
        };
      })
      .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
  );
  const { createReplyComment, loading, data, getData, deleteComment, deleteLoading } =
    useReplyCommentCollectible({
      commentID: comment.id,
      collectibleID: comment.channelID
    });
  const commentCreator = comment?.user;

  const handleSubmitComment = useCallback(async () => {
    if (inputComment.length < 1) {
      toast(t('components.post.emptyComment'), 'error');
      return;
    }
    const payload = {
      raw: inputComment,
      userID: user?.id
    };
    const newComment = {
      channelID: comment.channelID,
      user: user,
      content: inputComment,
      createdAt: new Date()
    };
    setInputComment('');
    setShowCreateComment(false);
    setListComment(listComment.concat(newComment));
    setTotalComment(totalComment + 1);
    await createReplyComment(payload);
    setTimeout(function () {
      getData();
    }, 2000);
  }, [inputComment]); // eslint-disable-line

  const handleDeleteComment = async () => {
    if (listComment?.length > 0) {
      toast(t('components.post.unableDelete'), 'error');
      setShowDeleteModal(false);
      return;
    }
    setShowDeleteModal(false);
    setTotalComment(totalComment - 1);
    removeComment(comment?.id);
    await deleteComment(user?.id);
  };

  const deleteCountComment = () => {
    setTotalComment(totalComment - 1);
  };

  const removeChildComment = (id) => {
    setListComment(listComment?.filter((comment) => comment?.id !== id));
  };

  useEffect(() => {
    if (data?.length > 0) {
      setListComment(data);
    }
  }, [data]);

  return (
    <>
      <div>
        <div className="comment">
          <Avatar size={36} user={commentCreator} />
          <div className="comment-padding">
            <div className="comment-wrapper">
              <div className="comment-wrapper-header">
                <div className="comment-wrapper-header-left">
                  {commentCreator?.displayName ||
                    commentCreator?.firstName + ' ' + commentCreator?.lastName}
                  <span className="comment-wrapper-header-left-time">
                    {formatDistanceToNowStrict(new Date(comment?.createdAt), {
                      addSuffix: true
                    })}
                  </span>
                </div>
                {comment?.id && (
                  <div className="comment-wrapper-header-right">
                    <Dropdown
                      placement="bottomRight"
                      overlay={
                        <Menu className="comment-wrapper-header-right-menu">
                          <Menu.Item
                            onClick={() => setShowCreateComment(true)}
                            className="comment-wrapper-header-right-menu-reply"
                            key="1"
                          >
                            <div>{t('components.post.reply')}</div>
                          </Menu.Item>
                          <Menu.Item
                            onClick={() => setShowDeleteModal(true)}
                            className="comment-wrapper-header-right-menu-delete"
                            key="2"
                          >
                            <div>{t('components.post.delete')}</div>
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <MoreOutlined style={{ fontSize: '13px' }} />
                    </Dropdown>
                  </div>
                )}
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: comment?.content
                }}
                className="comment-wrapper-body"
              ></div>
            </div>
            {listComment?.map((childComment) => (
              <ChildComment
                user={user}
                removeComment={removeChildComment}
                comment={childComment}
                deleteCountComment={deleteCountComment}
                setShowCreateComment={setShowCreateComment}
              />
            ))}
            {showCreateComment && (
              <div className="comment-create">
                <Avatar size={36} user={user} />
                <div className="comment-create-input">
                  <div className="comment-create-input-editor">
                    <TextArea
                      rows={4}
                      value={inputComment}
                      onChange={(e) => setInputComment(e.target.value)}
                      placeholder="Type here..."
                      onKeyDown={(e) => e.key === 'Enter' && handleSubmitComment()}
                    />
                  </div>
                  <Button onClick={() => handleSubmitComment()} loading={loading} type="primary">
                    {t('components.post.button.send')}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        className="comment-delete-modal"
        visible={showDeleteModal}
        footer={null}
        onCancel={() => setShowDeleteModal(false)}
        width={650}
      >
        <div className="comment-delete-modal-container">
          <div className="comment-delete-modal-container-header">
            <DeleteOutlined style={{ fontSize: '5rem', color: '#D6222A' }} />
            <div className="comment-delete-modal-container-header-title">
              {t('components.post.comment.modal.delete.title')}
            </div>
          </div>
          <div className="comment-delete-modal-container-header-text">
            {t('components.post.comment.modal.delete.description')}
          </div>
        </div>
        <div className="comment-delete-modal-container-footer">
          <Button
            style={{ backgroundColor: '#D6222A', border: 'none' }}
            className="comment-delete-modal-container-footer-delete"
            type="primary"
            loading={deleteLoading}
            onClick={() => handleDeleteComment()}
          >
            {t('components.post.comment.modal.delete.delete')}
          </Button>
          <Button
            onClick={() => setShowDeleteModal(false)}
            className="comment-delete-modal-container-footer-cancel"
            type="secondary"
          >
            {t('components.post.comment.modal.delete.cancel')}
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default Comment;
