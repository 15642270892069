import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { DashboardContent, NotificationMenu, Pagination } from '@components';
import { useNotification, useUnreadNotification } from '@libs/hooks/notification';
import { useState, useEffect } from 'react';
import { useAuth } from '@libs/contexts/auth';
function Notifications() {
  const { t } = useTranslation();
  const [filter, setFilter] = useState(false);
  const { creators } = useAuth();
  const [creator, setCreator] = useState(creators?.[0]?.id);
  const [resetPagination, setResetPagination] = useState(false);
  const { data: unreadNotification } = useUnreadNotification();
  const {
    data: notifications,
    getData: fetchNotification,
    changeFilter,
    changeCreator,
    onPaginate,
    nextPageToken: nextNotificationToken,
    loading: notificationLoading
  } = useNotification(); // eslint-disable-line
  const getDataNotifications = () => {
    fetchNotification(nextNotificationToken);
  };
  useEffect(() => {
    if (filter) {
      changeFilter(filter);
      setResetPagination(!resetPagination);
    }
  }, [filter]); // eslint-disable-line

  useEffect(() => {
    if (creator) {
      changeCreator(creator);
      setResetPagination(!resetPagination);
    }
  }, [creator]); // eslint-disable-line

  useEffect(() => {
    if (creators) {
      setCreator(creators?.[0]?.id);
    }
  }, [creators]); // eslint-disable-line

  console.log(creator);

  return (
    <>
      <DashboardContent title={t('notifications.title')}></DashboardContent>
      <Helmet>
        <title>{t('notifications.title')}</title>
      </Helmet>
      {notifications && creators?.length > 0 && (
        <NotificationMenu
          notifications={notifications}
          unread={unreadNotification?.length}
          fetchNotification={getDataNotifications}
          setFilter={setFilter}
          setCreator={setCreator}
          isHideCreators={true}
          creator={creators?.[0]?.id}
          notificationLoading={notificationLoading}
        />
      )}
      <Pagination
        nextToken={nextNotificationToken}
        loading={notificationLoading}
        reset={resetPagination}
        fetchData={onPaginate}
      />
    </>
  );
}

export default Notifications;
