export const searchDiscoursePosts = /* GraphQL */ `
  query SearchDiscoursePosts(
    $filter: SearchableDiscoursePostFilterInput
    $sort: SearchableDiscoursePostSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchDiscoursePosts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        featured
        parentID
        createdAt
        replies {
          items {
            id
            parentPostID
            postID
            post {
              id
              likedBy
              parentID
              replies {
                items {
                  id
                  parentPostID
                  postID
                  post {
                    parentID
                    likedBy
                    content
                    createdAt
                    id
                    channelID
                    topicID
                    user {
                      media {
                        avatarUrl
                      }
                      lastName
                      firstName
                      displayName
                    }
                    userID
                  }
                }
              }
              content
              user {
                firstName
                lastName
                media {
                  avatarUrl
                }
                displayName
              }
              channelID
              topicID
              createdAt
              userID
            }
          }
        }
        channelID
        userID
        topicID
        content
        likedBy
        user {
          media {
            avatarUrl
          }
          username
          firstName
          lastName
          displayName
        }
      }
      nextToken
    }
  }
`;
