import { Storage } from 'aws-amplify';
import { useState, useCallback, useEffect } from 'react';
import { Select, Form, Button, Upload, notification, Typography } from 'antd';
import { Helmet } from 'react-helmet';
import { DashboardContent } from '@components';
import { bulkUpsertCollectible } from '@graphql/mutations';
import useToast from '@libs/utils/toast';
import gql from '@libs/utils/gql';

const { Option } = Select;
const { Title, Text } = Typography;

export const uploadProps = (onUpload) => {
  return {
    maxCount: 1,
    accept: '.xlsx, .xls, .csv',
    customRequest: async (options) => {
      try {
        const res = await onUpload(options.file);

        if (res.key) {
          options.onSuccess(res.key, options.file);
        } else {
          options.onError('Failed when uploading the file.');
        }
      } catch (err) {
        options.onError(err);
      }
    },
    onChange(info) {
      if (info.file.status === 'done') {
        notification['success']({
          message: 'Success',
          description: 'File upload success'
        });
      }
      if (info.file.status === 'error') {
        notification['error']({
          message: 'Oops',
          description: 'Failed to upload CSV.'
        });
      }
    }
  };
};

export const useS3Storage = () => {
  const toast = useToast();
  const [files, setFiles] = useState();
  const [loading, setLoading] = useState(false);

  const fetchFiles = async () => {
    setLoading(true);
    try {
      const result = await Storage.list('collectible-csv/');
      setFiles(result.filter((x) => x.size > 0));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  const onUpload = async (file) => {
    const res = await Storage.put(`collectible-csv/${file.name}`, file);
    await fetchFiles();

    return res;
  };

  const onDelete = async (value) => {
    await Storage.remove(value);
    await fetchFiles();
    toast('File removed successfully.', 'success');
  };

  const onUpsert = async (value) => {
    setLoading(true);
    try {
      await gql(
        bulkUpsertCollectible,
        {
          input: {
            s3Key: value
          }
        },
        {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }
      );

      toast('Collectible upserted successfully.', 'success');
    } catch (error) {
      const errorMessage = error.errors[0]?.message;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    files,
    loading,
    onUpload,
    onUpsert,
    onDelete
  };
};

function DummyBulkUpsertCollectible() {
  const { files, loading, onUpload, onUpsert } = useS3Storage();

  const onSubmit = useCallback((values) => {
    onUpsert(values.s3Key);
  }, []); // eslint-disable-line

  return (
    <>
      <DashboardContent title="[Temporary] Bulk Upserting Collectible">
        <div>
          <Title level={4}>Upload File</Title>
          <Text>Before upsert the collectible, you'll need to upload the .csv file first.</Text>
        </div>
        <div style={{ margin: '16px 0 32px' }}>
          <Upload name="files" listType="text" {...uploadProps(onUpload)}>
            <Button>Upload</Button>
          </Upload>
        </div>
        <Title level={4}>Upsert Collectible</Title>
        <Form onFinish={onSubmit} layout="vertical" className="admin-form">
          <Form.Item
            label="Select .csv files"
            name="s3Key"
            rules={[{ required: true, message: 'File is required.' }]}
          >
            <Select placeholder="Select files to submit">
              {files?.map((c, i) => {
                return (
                  <Option key={`collectible-${i}`} value={c.key}>
                    {c.key}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Bulk Upsert
            </Button>
          </Form.Item>
        </Form>
      </DashboardContent>
      <Helmet>
        <title>Bulk Upserting Collectible</title>
      </Helmet>
    </>
  );
}

export default DummyBulkUpsertCollectible;
