import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { DashboardContent, NotificationMenu, Pagination } from '@components';
import { useNotification, useUnreadNotification } from '@libs/hooks/notification';
import { useState, useEffect } from 'react';

function Notifications() {
  const { t } = useTranslation();
  const [filter, setFilter] = useState(false);
  const [creator, setCreator] = useState('All');
  const [resetPagination, setResetPagination] = useState(false);
  const { data: unreadNotification } = useUnreadNotification();
  const {
    data: notifications,
    getData: fetchNotification,
    changeFilter,
    changeCreator,
    onPaginate,
    nextPageToken: nextNotificationToken,
    loading: notificationLoading
  } = useNotification(); // eslint-disable-line
  const getDataNotifications = () => {
    fetchNotification(nextNotificationToken);
  };
  useEffect(() => {
    if (filter) {
      changeFilter(filter);
      setResetPagination(!resetPagination);
    }
  }, [filter]); // eslint-disable-line

  useEffect(() => {
    if (creator) {
      changeCreator(creator);
      setResetPagination(!resetPagination);
    }
  }, [creator]); // eslint-disable-line
  console.log(creator);
  return (
    <>
      <DashboardContent title={t('notifications.title')}></DashboardContent>
      <Helmet>
        <title>{t('notifications.title')}</title>
      </Helmet>
      {notifications && (
        <NotificationMenu
          notifications={notifications}
          unread={unreadNotification?.length}
          fetchNotification={getDataNotifications}
          setFilter={setFilter}
          setCreator={setCreator}
          creator={creator}
          notificationLoading={notificationLoading}
        />
      )}
      <Pagination
        nextToken={nextNotificationToken}
        loading={notificationLoading}
        reset={resetPagination}
        fetchData={onPaginate}
      />
    </>
  );
}

export default Notifications;
