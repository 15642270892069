import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { GuestRoute, ProtectedRoute } from '@components';

import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@assets/less/app.less';

// Guest only
import Auth from '@pages/auth';
import SinglePage from '@pages/single-page';
// Member only
import Dashboard from '@pages/dashboard';
import BetaFeatures from '@pages/beta-features';
// import { useVlag } from '@vincere/react-vlag';

import ScrollTop from '@libs/utils/scrollTop';

if (process.env.REACT_APP_NAME === 'patrons') {
  require('@assets/less/patrons.less');
} else {
  require('@assets/less/fansinc.less');
}

function App() {
  // const ff = useVlag();
  return (
    <div className="App">
      <main className="main">
        <ScrollTop />
        <Switch>
          {/* <Route exact path="/" component={SinglePage} /> */}
          <Route exact path="/" component={Auth} />
          <Route exact path="/login" component={Auth} />
          <Route exact path="/faq" component={SinglePage} />
          <Route exact path="/privacy" component={SinglePage} />
          <Route exact path="/terms-and-conditions" component={SinglePage} />
          <Route exact path="/login" component={Auth} />
          <Route exact path="/beta-features" component={BetaFeatures} />
          <GuestRoute exact path="/forgot-password" component={Auth} />
          <GuestRoute exact path="/reset-password" component={Auth} />
          <Route exact path="/accept-invitation/:id" component={Auth} />
          <Route exact path="/join-team/:id" component={Auth} />
          <ProtectedRoute exact path="/dashboard" component={Dashboard} />
          <ProtectedRoute exact path="/collectibles/edit" component={Dashboard} />
          <ProtectedRoute exact path="/collectibles" component={Dashboard} />
          <ProtectedRoute exact path="/collectibles/edit/:id?" component={Dashboard} />
          <ProtectedRoute exact path="/collectibles/details/:id" component={Dashboard} />
          <ProtectedRoute exact path="/collections" component={Dashboard} />

          <ProtectedRoute exact path="/performance" component={Dashboard} />
          <ProtectedRoute exact path="/bids" component={Dashboard} />
          <ProtectedRoute exact path="/creators" component={Dashboard} />
          <ProtectedRoute exact path="/creators/edit" component={Dashboard} />
          <ProtectedRoute exact path="/creators/edit/:id" component={Dashboard} />
          <ProtectedRoute exact path="/balance" component={Dashboard} />
          <ProtectedRoute exact path="/notifications" component={Dashboard} />
          <ProtectedRoute exact path="/offers" component={Dashboard} />
          <ProtectedRoute exact path="/order-history" component={Dashboard} />
          <ProtectedRoute exact path="/order-history/:id" component={Dashboard} />
          <ProtectedRoute exact path="/settings" component={Dashboard} />
          <ProtectedRoute exact path="/teams" component={Dashboard} />
          <ProtectedRoute exact path="/user-settings" component={Dashboard} />
          <ProtectedRoute exact path="/dummy" component={Dashboard} />
          <ProtectedRoute exact path="/dummy/approve-collectible" component={Dashboard} />
          <ProtectedRoute exact path="/dummy/create-organization" component={Dashboard} />
          <ProtectedRoute exact path="/dummy/delete-collectible" component={Dashboard} />
          <ProtectedRoute exact path="/dummy/delete-user" component={Dashboard} />
          <ProtectedRoute exact path="/dummy/upsert-collectible" component={Dashboard} />
          <ProtectedRoute exact path="/dummy/upsert-creator" component={Dashboard} />
          <ProtectedRoute exact path="/admin/withdrawal" component={Dashboard} />
          <ProtectedRoute exact path="/dummy/order-history" component={Dashboard} />
          <ProtectedRoute exact path="/admin/transactions" component={Dashboard} />
          <ProtectedRoute exact path="/admin/notifications" component={Dashboard} />
          <ProtectedRoute exact path="/admin/collectibles/:id" component={Dashboard} />
          <ProtectedRoute exact path="/admin/collectibles" component={Dashboard} />
          <ProtectedRoute exact path="/admin/order-history" component={Dashboard} />
          <ProtectedRoute exact path="/admin/order-history/:id" component={Dashboard} />
          <ProtectedRoute exact path="/admin/user-activities" component={Dashboard} />
          <ProtectedRoute exact path="/admin/organization" component={Dashboard} />
          <ProtectedRoute exact path="/admin/organization/edit" component={Dashboard} />
          <ProtectedRoute exact path="/admin/organization/edit/:id" component={Dashboard} />
          <ProtectedRoute exact path="/admin/organization/:id" component={Dashboard} />
          <ProtectedRoute exact path="/admin/users" component={Dashboard} />
          {process.env.REACT_APP_NAME === 'patrons' && (
            <ProtectedRoute exact path="/gallery-experience" component={Dashboard} />
          )}
          {process.env.REACT_APP_NAME === 'patrons' ? (
            <ProtectedRoute
              exact
              path="/commentary/:creatorID/channels/:channelID"
              component={Dashboard}
            />
          ) : (
            <ProtectedRoute
              exact
              path="/fan-club/:creatorID/channels/:channelID"
              component={Dashboard}
            />
          )}
          {process.env.REACT_APP_NAME === 'patrons' && (
            <>
              <ProtectedRoute exact path="/admin/collections/edit/:id" component={Dashboard} />
              <ProtectedRoute exact path="/admin/collections/edit" component={Dashboard} />
              <ProtectedRoute exact path="/admin/collections" component={Dashboard} />
              <ProtectedRoute exact path="/admin/waitlist" component={Dashboard} />
              <ProtectedRoute exact path="/admin/inbox" component={Dashboard} />
            </>
          )}
        </Switch>
      </main>
    </div>
  );
}

export default App;
