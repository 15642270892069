import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Collapse, Input, List, Row, Table, Modal } from 'antd';
import { Helmet } from 'react-helmet';
import { DashboardContent, DisplayDate, Link, Pagination, Image } from '@components';
import { DeleteOutlined } from '@ant-design/icons';
import { useCollection } from '@libs/hooks/collection';
import PatronsLogo from '@assets/img/patrons-logo-square.png';

function Collections() {
  const { t } = useTranslation();
  const { Search } = Input;
  const { Panel } = Collapse;
  const [showDeleteModal, setShowDeleteModal] = useState();
  const [selectedCollection, setSelectedCollection] = useState();
  const {
    data,
    loading: dataLoading,
    getData: fetchCollection,
    deleteLoading,
    onDelete,
    nextPageToken: nextCollectionToken
  } = useCollection();
  const handleDeleteCollection = useCallback(async () => {
    await onDelete(selectedCollection?.id);
    setShowDeleteModal(false);
    setTimeout(function () {
      fetchCollection();
    }, 1000);
  }, [selectedCollection]); // eslint-disable-line
  const columns = [
    {
      title: t('collections.list.name'),
      dataIndex: 'collectionMobile',
      key: 'collectionMobile',
      responsive: ['xs']
    },
    {
      title: t('collections.list.name'),
      dataIndex: 'name',
      key: 'name',
      responsive: ['sm']
    },
    {
      title: t('collections.list.type'),
      dataIndex: 'verifiedProfile',
      key: 'verifiedProfile',
      responsive: ['sm']
    },
    {
      title: t('collections.list.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 180,
      responsive: ['md']
    }
  ];

  const handleSearch = async (data) => {
    await fetchCollection(null, data);
  };

  const dataSource =
    dataLoading && data === []
      ? []
      : data.map((row, i) => ({
          key: i,
          collectionMobile: (
            <div className="mobile-row">
              <div className="profile-avatar">
                {row?.logoUrl ? (
                  <Image style={{ width: '36px', marginRight: '10px' }} src={row?.logoUrl} />
                ) : (
                  <img style={{ width: '36px', marginRight: '10px' }} src={PatronsLogo} alt="" />
                )}
                <div className="profile-avatar__info">
                  <div className="profile-avatar__info__name">{row?.name}</div>
                  <div className="meta">
                    <span>
                      <Link to={`/admin/collections/edit/${row?.id}`}>
                        {t('collections.list.edit')}
                      </Link>
                    </span>
                    <span
                      onClick={() => {
                        setSelectedCollection(row);
                        setShowDeleteModal(true);
                      }}
                      style={{ color: '#D6222A', cursor: 'pointer' }}
                    >
                      {t('collections.list.delete')}
                    </span>
                  </div>
                </div>
              </div>
              <Collapse collapsible ghost className="datalist">
                <Panel header={t('collections.list.expand')} key="1">
                  <List itemLayout="vertical">
                    <List.Item>
                      <List.Item.Meta
                        title={t('collections.list.type')}
                        description={<span>{row?.collectionType}</span>}
                      />
                      <List.Item.Meta
                        title={t('collections.list.createdAt')}
                        description={<DisplayDate date={row.createdAt} />}
                      />
                    </List.Item>
                  </List>
                </Panel>
              </Collapse>
            </div>
          ),
          name: (
            <>
              <div className="profile-avatar">
                {row?.logoUrl ? (
                  <Image style={{ width: '36px', marginRight: '10px' }} src={row?.logoUrl} />
                ) : (
                  <img style={{ width: '36px', marginRight: '10px' }} src={PatronsLogo} alt="" />
                )}
                <div className="profile-avatar__info">
                  <div className="profile-avatar__info__name">{row?.name}</div>
                  <div className="meta">
                    <span>
                      <Link to={`/admin/collections/edit/${row?.id}`}>
                        {t('collections.list.edit')}
                      </Link>
                    </span>
                    <span
                      onClick={() => {
                        setSelectedCollection(row);
                        setShowDeleteModal(true);
                      }}
                      style={{ color: '#D6222A', cursor: 'pointer' }}
                    >
                      {t('collections.list.delete')}
                    </span>
                  </div>
                </div>
              </div>
            </>
          ),
          verifiedProfile: <div className="profile-avatar__info__name">{row?.collectionType}</div>,
          createdAt: <DisplayDate date={row.createdAt} />
        }));
  return (
    <>
      <DashboardContent title={t('collections.title')}>
        <Row className="toolbar">
          <Col span={24} lg={12} className="toolbar__left">
            <Button type="primary">
              <Link to="/admin/collections/edit">{t('collections.addNew')}</Link>
            </Button>
          </Col>
          <Col span={24} lg={12} className="toolbar__right">
            <div className="toolbar-search">
              <Search
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
                placeholder={t('collections.search')}
              />
            </div>
          </Col>
        </Row>
        <div className="manage-content">
          <div className="fans-table">
            <Table
              bordered
              dataSource={dataSource}
              loading={dataLoading}
              columns={columns}
              pagination={false}
            />
            <Pagination
              nextToken={nextCollectionToken}
              loading={dataLoading}
              fetchData={fetchCollection}
            />
          </div>
        </div>
        <Modal
          className="post-delete-modal"
          visible={showDeleteModal}
          footer={null}
          onCancel={() => setShowDeleteModal(false)}
          width={650}
        >
          <div className="post-delete-modal-container">
            <div className="post-delete-modal-container-header">
              <DeleteOutlined style={{ fontSize: '5rem', color: '#D6222A' }} />
              <div className="post-delete-modal-container-header-title">
                {t('collections.delete.title')}
              </div>
            </div>
            <div className="post-delete-modal-container-header-text">
              {t('collections.delete.description')}
            </div>
          </div>
          <div className="post-delete-modal-container-footer">
            <Button
              style={{ backgroundColor: '#D6222A', border: 'none' }}
              className="post-delete-modal-container-footer-delete"
              type="primary"
              loading={deleteLoading}
              onClick={() => handleDeleteCollection()}
            >
              {t('components.post.modal.delete.delete')}
            </Button>
            <Button
              onClick={() => setShowDeleteModal(false)}
              className="post-delete-modal-container-footer-cancel"
              type="secondary"
            >
              {t('components.post.modal.delete.cancel')}
            </Button>
          </div>
        </Modal>
      </DashboardContent>
      <Helmet>
        <title>{t('collections.title')}</title>
      </Helmet>
    </>
  );
}

export default Collections;
