import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import {
  Col,
  Collapse,
  Input,
  List,
  Row,
  Table,
  Tag,
  Tooltip,
  Typography,
  Switch,
  Select
} from 'antd';
import { Avatar, DashboardContent, DisplayDate, Link, Pagination } from '@components';
import CollectibleModal from './modal';

import { useAllCollectibles } from '@libs/hooks/collectible';
import config from '@/config';

function Collectibles() {
  const { t } = useTranslation();

  const { Panel } = Collapse;
  const { Search } = Input;
  const { Text, Link: AntLink } = Typography;

  const columns = [
    {
      title: t('collectibles.list.collectible'),
      dataIndex: 'collectibleMobile',
      key: 'collectibleMobile',
      width: 320,
      responsive: ['xs']
    },
    {
      title: t('collectibles.list.collectible'),
      dataIndex: 'collectible',
      key: 'collectible',
      width: 320,
      responsive: ['sm']
    },
    {
      title: t('collectibles.list.status.column'),
      dataIndex: 'status',
      key: 'status',
      responsive: ['md']
    },
    {
      title: t('collectibles.list.views.column'),
      dataIndex: 'views',
      key: 'views',
      responsive: ['md']
    },
    {
      title:
        process.env.REACT_APP_NAME === 'patrons'
          ? t('collectibles.list.patrons.profile')
          : t('collectibles.list.profile'),
      dataIndex: 'profile',
      key: 'profile',
      responsive: ['lg']
    },
    {
      title: t('collectibles.list.featured'),
      dataIndex: 'featuredInProfile',
      key: 'featuredInProfile',
      responsive: ['lg']
    },
    {
      title: t('collectibles.list.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      responsive: ['lg']
    }
  ];

  const collectibleTag = (status) => {
    switch (status) {
      case 'PUBLISHED':
        return <Tag className="success">{t('collectibles.list.status.live')}</Tag>;
      case 'IN_REVIEW':
        return (
          <Tooltip title={t('collectibles.list.status.inReview.tooltip')}>
            <Tag className="warning">{t('collectibles.list.status.inReview.tag')}</Tag>
          </Tooltip>
        );
      case 'NEED_REVISION':
        return <Tag className="error">{t('collectibles.list.status.revisions')}</Tag>;
      default:
        return <Tag>{t('collectibles.list.status.draft.tag')}</Tag>;
    }
  };
  const [resetPagination, setResetPagination] = useState(false);
  const [modalData, setModalData] = useState({ status: false, id: '' });
  const {
    data,
    loading: dataLoading,
    deleteLoading,
    nextPageToken,
    getData,
    onUpdateFeatured,
    onSearch,
    onPaginate,
    onStatusChanges,
    onDelete
  } = useAllCollectibles();

  const handleSearch = async (data) => {
    setResetPagination(!resetPagination);
    onSearch(data);
  };

  const handleFeaturedChange = async (data, featuredInProfile) => {
    const payload = { ...data, featuredInProfile: featuredInProfile ? 'TRUE' : 'FALSE' };
    onUpdateFeatured(payload);
  };

  const dataSource =
    dataLoading && data === []
      ? []
      : data?.map((row, i) => ({
          key: `collectible-${i}`,
          collectibleMobile: (
            <div className="mobile-row">
              <div className="mobile-row__info">
                <div className="mobile-row__info__name">{row.title}</div>
                <div className="meta">
                  <span>
                    <Link to={`/admin/collectibles/${row.id}`}>
                      {t('collectibles.list.meta.edit')}
                    </Link>
                  </span>
                  {row.title && row.assetUrl && row.category && (
                    <span>
                      <AntLink
                        target="_blank"
                        href={`${config.fansUrl}/${row.creator.username}/${row.slug}${
                          row.status === 'PUBLISHED' ? '' : '?draft=1'
                        }`}
                      >
                        {row.status === 'PUBLISHED'
                          ? t('collectibles.list.meta.view')
                          : t('collectibles.list.meta.preview')}
                      </AntLink>
                    </span>
                  )}
                  <span>
                    <AntLink target="_blank" href="#">
                      {t('collectibles.list.meta.report')}
                    </AntLink>
                  </span>
                </div>
              </div>
              <Collapse collapsible ghost className="datalist">
                <Panel header={t('collectibles.list.expand')} key="1">
                  <List itemLayout="vertical">
                    <List.Item>
                      <List.Item.Meta
                        title={t('collectibles.list.status.column')}
                        description={collectibleTag(row.status)}
                      />
                      <List.Item.Meta
                        title={
                          process.env.REACT_APP_NAME === 'patrons'
                            ? t('collectibles.list.patrons.profile')
                            : t('collectibles.list.profile')
                        }
                        description={
                          <div className="avatar-name">
                            <Avatar user={row.creator} />
                            {`${
                              row?.displayName ||
                              row?.creator?.firstName + ' ' + row?.creator?.lastName
                            }`}
                          </div>
                        }
                      />
                      <List.Item.Meta
                        title={t('collectibles.list.featured')}
                        description={
                          <Switch
                            checkedChildren={t('global.switch.yes')}
                            unCheckedChildren={t('global.switch.no')}
                            defaultChecked={row.featuredInProfile === 'TRUE'}
                            onChange={(checked) => {
                              handleFeaturedChange(row, checked);
                            }}
                          />
                        }
                      />
                      <List.Item.Meta
                        title={t('collectibles.list.createdAt')}
                        description={<DisplayDate date={row.createdAt} />}
                      />
                    </List.Item>
                  </List>
                </Panel>
              </Collapse>
            </div>
          ),
          collectible: (
            <>
              <div className="title">
                <Link to={`/admin/collectibles/${row.id}`}>
                  <Text>{row.title}</Text>
                </Link>
              </div>
              <div className="meta">
                {row.title && row.assetUrl && row.category && (
                  <span>
                    <AntLink
                      target="_blank"
                      href={`${config.fansUrl}/${row.creator.username}/${row.slug}${
                        row.status === 'PUBLISHED' ? '' : '?draft=1'
                      }`}
                    >
                      {row.status === 'PUBLISHED'
                        ? t('collectibles.list.meta.view')
                        : t('collectibles.list.meta.preview')}
                    </AntLink>
                  </span>
                )}
                <span>
                  <AntLink
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      setModalData({
                        status: true,
                        id: row.id
                      });
                    }}
                  >
                    {t('collectibles.list.meta.delete')}
                  </AntLink>
                </span>
              </div>
            </>
          ),
          status: collectibleTag(row.status),
          views: t('collectibles.list.views.content', { views: 1234 }),
          profile: (
            <div className="profile-avatar">
              <Avatar user={row.creator}></Avatar>
              <div className="profile-avatar__info">
                <div className="profile-avatar__info__name">{`${
                  row.creator.displayName || row.creator.firstName + ' ' + row.creator.lastName
                }`}</div>
              </div>
            </div>
          ),
          featuredInProfile:
            row.status !== 'IN_REVIEW' ? (
              <Switch
                checkedChildren={t('global.switch.yes')}
                unCheckedChildren={t('global.switch.no')}
                defaultChecked={row.featuredInProfile === 'TRUE'}
                onChange={(checked) => {
                  handleFeaturedChange(row, checked);
                }}
              />
            ) : null,
          createdAt: <DisplayDate date={row.createdAt} />
        }));

  const handleCollectibleStatusChange = (status) => {
    onStatusChanges(status);
  };

  const handleDeleteCollectible = async (values) => {
    const payload = {
      id: values,
      item: 'collectible'
    };
    await onDelete(payload);
  };

  return (
    <>
      <DashboardContent title={t('collectibles.title')}>
        <Row className="toolbar admin-toolbar">
          <Col span={24} lg={6} className="toolbar__left">
            <div className="toolbar-search">
              <Search
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
                placeholder={t('collectibles.search')}
              />
            </div>
          </Col>
          <Col span={24} lg={12} className="toolbar__right">
            <Select
              showSearch
              placeholder={t('collectibles.toolbar.allStatus')}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => handleCollectibleStatusChange(value)}
            >
              <Select.Option key={'all'} value="">
                {t('collectibles.toolbar.allStatus')}
              </Select.Option>
              <Select.Option key={'live'} value="PUBLISHED">
                {t('collectibles.list.status.live')}
              </Select.Option>
              <Select.Option key={'review'} value="IN_REVIEW">
                {t('collectibles.list.status.needReview')}
              </Select.Option>
              <Select.Option key={'revision'} value="NEED_REVISION">
                {t('collectibles.list.status.revisions')}
              </Select.Option>
              <Select.Option key={'approved'} value="APPROVED">
                {t('collectibles.list.status.approved')}
              </Select.Option>
              <Select.Option key={'draft'} value="DRAFT">
                {t('collectibles.list.status.draft.tag')}
              </Select.Option>
            </Select>
          </Col>
        </Row>
        <div className="manage-content">
          <div className="fans-table">
            <Table
              bordered
              loading={dataLoading}
              dataSource={dataSource}
              columns={columns}
              pagination={false}
            />
            <Pagination
              nextToken={nextPageToken}
              loading={dataLoading}
              resetPagination={resetPagination}
              fetchData={onPaginate}
            />
          </div>
        </div>
      </DashboardContent>
      <CollectibleModal
        showModal={modalData.status}
        onCancel={() => setModalData({ status: false, id: '' })}
        onSubmit={async () => {
          await handleDeleteCollectible(modalData.id);
          setTimeout(() => getData(), 1000);

          setModalData({ status: false, id: '' });
        }}
        loading={deleteLoading}
        mode="delete"
        className="admin-collectible-modal"
      />
      <Helmet>
        <title>{t('collectibles.title')}</title>
      </Helmet>
    </>
  );
}

export default Collectibles;
