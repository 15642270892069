import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Button, Col, Collapse, List, Row, Table, Typography, Input } from 'antd';
import { Helmet } from 'react-helmet';
import config from '@/config';
import { Avatar, AvatarGroup, DashboardContent, DisplayDate, Link, Pagination } from '@components';
import { useProfileOrganisation } from '@libs/hooks/organisation';

const { Link: AntLink } = Typography;
const { Search } = Input;
const { Panel } = Collapse;

function Creators() {
  const { t } = useTranslation();
  const {
    data,
    loading,
    getData: fetchProfile,
    nextPageToken: nextProfileToken
  } = useProfileOrganisation();

  const columns = [
    {
      title:
        process.env.REACT_APP_NAME === 'patrons'
          ? t('creators.patrons.list.profile')
          : t('creators.list.profile'),
      responsive: ['xs'],
      render: (_, row) => (
        <div className="profile-mobile">
          <div className="profile-avatar">
            <Avatar user={row?.profile} />
            <div className="profile-avatar__info">
              <div className="profile-avatar__info__name">{`${
                row?.firstName || row?.profile?.firstName
              } ${row?.lastName || row?.profile?.lastName}`}</div>
              <div className="meta">
                <span>
                  <Link to={`/creators/edit/${row?.profile?.id}`}>{t('creators.list.edit')}</Link>
                </span>
                <span>
                  <AntLink target="_blank" href={`${config.fansUrl}/${row?.profile?.username}`}>
                    {process.env.REACT_APP_NAME === 'patrons'
                      ? t('creators.patrons.list.view')
                      : t('creators.list.view')}
                  </AntLink>
                </span>
              </div>
            </div>
          </div>
          <Collapse collapsible ghost className="datalist">
            <Panel header={t('creators.list.expand')} key="1">
              <List itemLayout="vertical">
                <List.Item>
                  <List.Item.Meta title={t('creators.list.editors')} description="" />
                  <List.Item.Meta
                    title={t('creators.list.createdAt')}
                    description={format(new Date(row?.profile?.createdAt), 'd MMMM yyyy H:ii:s')}
                  />
                  <List.Item.Meta
                    title={t('creators.list.owner')}
                    description={<Avatar user={row?.profile?.currentOwner} />}
                  />
                </List.Item>
              </List>
            </Panel>
          </Collapse>
        </div>
      )
    },
    {
      title:
        process.env.REACT_APP_NAME === 'patrons'
          ? t('creators.patrons.list.profile')
          : t('creators.list.profile'),
      responsive: ['sm'],
      width: 480,
      render: (_, row) => (
        <div className="profile-avatar">
          <Avatar user={row?.profile}></Avatar>
          <div className="profile-avatar__info">
            <div className="profile-avatar__info__name">{`${
              row?.firstName || row?.profile?.firstName
            } ${row?.lastName || row?.profile?.lastName}`}</div>
            <div className="meta">
              <span>
                <Link to={`/creators/edit/${row?.profile?.id}`}>{t('creators.list.edit')}</Link>
              </span>
              <span>
                <AntLink target="_blank" href={`${config.fansUrl}/${row?.profile?.username}`}>
                  {process.env.REACT_APP_NAME === 'patrons'
                    ? t('creators.patrons.list.view')
                    : t('creators.list.view')}
                </AntLink>
              </span>
            </div>
          </div>
        </div>
      )
    },
    {
      title: t('creators.list.owner'),
      dataIndex: 'currentOwner',
      key: 'currentOwner',
      width: 480,
      responsive: ['md'],
      render: (_, row) => (
        <div className="profile-avatar">
          <Avatar user={row?.profile?.currentOwner}></Avatar>
          <div className="profile-avatar__info">
            <div className="profile-avatar__info__name">{`${row?.profile?.currentOwner?.firstName} ${row?.profile?.currentOwner?.lastName}`}</div>
          </div>
        </div>
      )
    },
    {
      title: t('creators.list.editors'),
      dataIndex: 'managers.items.manager',
      key: 'editors',
      width: 480,
      responsive: ['sm'],
      render: (_, row) => <AvatarGroup users={row?.profile?.managers?.items} dataIndex="manager" />
    },
    {
      title: t('creators.list.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      responsive: ['md'],
      render: (value) => <DisplayDate date={value} />
    }
  ];

  const handleSearch = async (data) => {
    await fetchProfile(null, data);
  };
  return (
    <>
      <DashboardContent
        title={
          process.env.REACT_APP_NAME === 'patrons'
            ? t('creators.patrons.title')
            : t('creators.title')
        }
      >
        <Row className="toolbar">
          <Col span={24} lg={12} className="toolbar__left">
            <Button type="primary">
              <Link to="/creators/edit">
                {process.env.REACT_APP_NAME === 'patrons'
                  ? t('creators.patrons.addNew')
                  : t('creators.addNew')}
              </Link>
            </Button>
          </Col>
          <Col span={24} lg={12} className="toolbar__right">
            <div className="toolbar-search">
              <Search
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
                placeholder={
                  process.env.REACT_APP_NAME === 'patrons'
                    ? t('creators.patrons.search')
                    : t('creators.search')
                }
              />
            </div>
          </Col>
        </Row>
        <div className="manage-content">
          <div className="fans-table">
            <Table
              bordered
              dataSource={data}
              columns={columns}
              loading={loading}
              pagination={false}
            />
            <Pagination nextToken={nextProfileToken} loading={loading} fetchData={fetchProfile} />
          </div>
        </div>
      </DashboardContent>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME === 'patrons'
            ? t('creators.patrons.title')
            : t('creators.title')}
        </title>
      </Helmet>
    </>
  );
}

export default Creators;
