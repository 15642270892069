const env = process.env.REACT_APP_AMPLIFY_ENV;
const creatorPortalUrl = process.env.REACT_APP_CREATOR_PORTAL_DOMAIN;
const captchaKey = process.env.REACT_APP_TOKEN_SITE_KEY_CAPTCHA;
const captchaContactKey = process.env.REACT_APP_TOKEN_SITE_KEY_CAPTCHA_CONTACT;
const fansPublicAppUrl = process.env.REACT_APP_CURRENT_PUBLIC_APP_URL;
const cdnUrl = process.env.REACT_APP_CDN_URL;
const fansIncWallet = process.env.REACT_APP_FANS_INC_WALLET;
const awsOutputVideo = process.env.REACT_APP_AWS_OUTPUT_VIDEO;

if (env !== 'main') {
  console.info('AMPLIFY ENV VARS:', process.env);
}

const config = {
  fansUrl: fansPublicAppUrl || `https://${env}.dkcaq2fjgc03l.amplifyapp.com`,
  currentUrl: creatorPortalUrl || `https://${env}.d660qbx2wy295.amplifyapp.com/`,
  cookie: {
    ACTIVATE_USERNAME: 'activateUsername',
    FORGOT_PASS: 'forgotPassword',
    TOKEN_PREFIX: 'tokenPrefix',
    INVITATION: 'invitation',
    TOKEN_SITE_KEY_CAPTCHA: captchaKey || '6LcIFRIiAAAAAByS3PmbvzHBBazGpk4rziFpCMym',
    TOKEN_SITE_KEY_CAPTCHA_CONTACT: captchaContactKey || '6LfTm6ckAAAAAGmCcepK4K3dB3PImlPuxZBx_Y9D'
  },
  storage: {
    INVITATION_ID: 'invitationID'
  },
  cdnUrl: cdnUrl || 'https://d7zeymq3tadw6.cloudfront.net',
  fansIncWallet: fansIncWallet || '3deba0d8-2215-55d5-ae39-45a75a2338fd',
  awsOutputVideo: awsOutputVideo || 'djda7woe69j8s.cloudfront.net'
};

export default config;
