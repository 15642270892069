import { Avatar as Ava, Tooltip } from 'antd';
import { Avatar } from '@components';

function AvatarGroup({ users, dataIndex, accountID }) {
  return (
    <Ava.Group size={36}>
      {users?.map((item, i) => {
        let avatar;
        if (dataIndex === 'profile') {
          if (accountID === item[dataIndex].accountID) {
            avatar = <Avatar user={item[dataIndex]} key={`${dataIndex}group-${i}`} />;
          }
        } else {
          avatar = (
            <Tooltip
              placement="right"
              title={item[dataIndex].firstName + ' ' + item[dataIndex].lastName}
            >
              <span className="avatar-group-manager">
                <Avatar user={item[dataIndex]} key={`${dataIndex}group-${i}`} />
              </span>
            </Tooltip>
          );
        }
        return avatar;
      })}
    </Ava.Group>
  );
}

export default AvatarGroup;
