import { Route, Switch } from 'react-router-dom';
import Landing from './landing';
import Faq from './faq';
import Privacy from './privacy';
import TermsAndConditions from './terms-and-conditions';

function SinglePage() {
  return (
    <Switch>
      <Route exact path="/" component={Landing} />
      <Route exact path="/faq" component={Faq} />
      <Route exact path="/privacy" component={Privacy} />
      <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
    </Switch>
  );
}

export default SinglePage;
