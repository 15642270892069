import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { DashboardContent, Price, Link } from '@components';
import { Row, Col, Image, Tag, Spin } from 'antd';
import { useOrderHistoryById } from '@libs/hooks/order-print';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { colorStatusOrderMapper, statusOrderMapper as statusMapper } from '@libs/utils/mapper';
import { phoneCodes, prodigiCountries, calculateTax } from '@libs/utils/prodigi';

function DetailOrder() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data, loading } = useOrderHistoryById(id);
  const url = data?.shippingProduct?.assets.slice(1, -1);
  const title = data?.exchange?.collectible?.title;
  const textColor = statusMapper[data?.orderStatus] === 'Draft' ? 'black' : 'white';
  const qty = `${data?.shippingProduct?.copies} Item`;
  const tier =
    data?.exchange?.serialIndex === 1
      ? 'Digital Original #1'
      : 'Limited Edition #' + data?.exchange?.serialIndex;

  const checkSKU = (sku) =>
    sku === 'FRA_BOX_LPP_MOUNT1_GLA_20X30'
      ? '20X30CM / 8X12"'
      : sku === 'FRA_BOX_LPP_MOUNT1_GLA_40X60'
      ? '40X60CM / 16X24"'
      : sku === 'FRA_BOX_LPP_MOUNT1_GLA_60X60'
      ? '60X60cm / 24x24"'
      : sku === 'FRA_BOX_LPP_MOUNT1_GLA_60X80'
      ? '60X80CM / 24X32"'
      : '90X90cm / 36x36"';
  const shippingDetail = data?.shippingDetail;
  const { tax, beforeTax, afterTax } = calculateTax(data?.totalPrice);
  const country = prodigiCountries.filter((x) => x.isoCode === shippingDetail?.country);
  const phoneCode = phoneCodes[shippingDetail?.country];
  const address = `${shippingDetail?.address}, ${shippingDetail?.city} ${shippingDetail?.postalCode}, ${country[0]?.name}`;
  const phone = `+${phoneCode} ${shippingDetail?.phone}`;
  const size = checkSKU(data?.shippingProduct?.sku);
  const frame = data?.shippingProduct?.frame;
  const selectedFrame = frame === 'White' ? '14px solid #E0E0E0' : '14px solid black';

  return (
    <DashboardContent
      title={
        <Link to="/order-history">
          <div>
            <ArrowLeftOutlined style={{ marginRight: '14px' }} />
            {t('detailOrder.title')}
          </div>
        </Link>
      }
    >
      <Helmet>
        <title>{t('detailOrder.title')}</title>
      </Helmet>
      {loading ? (
        <center>
          <Spin />
        </center>
      ) : (
        <Row className="detail-order">
          <Col className="detial-order-image" lg={15} span={24}>
            <Image style={{ border: selectedFrame, margin: '0px' }} preview={false} src={url} />
          </Col>
          <Col className="detail-order-space" lg={1} span={24}></Col>
          <Col lg={8} span={24}>
            <div className="detail-order-wrapper">
              <div className="detail-order-wrapper-title">{title}</div>
              <div>{tier}</div>
              <div className="detail-order-wrapper-underline"></div>
              <div className="detail-order-wrapper-field">
                <div className="detail-order-wrapper-field-left">{t('detailOrder.size')}</div>
                <div className="detail-order-wrapper-field-right">{size}</div>
              </div>
              <div className="detail-order-wrapper-underline"></div>
              <div className="detail-order-wrapper-field">
                <div className="detail-order-wrapper-field-left">{t('detailOrder.qty')}</div>
                <div className="detail-order-wrapper-field-right">{qty}</div>
              </div>
              <div className="detail-order-wrapper-underline"></div>
              <div className="detail-order-wrapper-field">
                <div className="detail-order-wrapper-field-left">{t('detailOrder.frame')}</div>
                <div className="detail-order-wrapper-field-right">{frame}</div>
              </div>
              <div className="detail-order-wrapper-underline"></div>
              <div className="detail-order-wrapper-field">
                <div className="detail-order-wrapper-field-left">{t('detailOrder.status')}</div>
                <div className="detail-order-wrapper-field-right">
                  {' '}
                  <Tag
                    style={{ color: textColor, fontSize: '10px', borderColor: '#E9E9EB' }}
                    color={colorStatusOrderMapper[data?.orderStatus]}
                  >
                    {statusMapper[data?.orderStatus]}
                  </Tag>
                </div>
              </div>
            </div>
            <div className="detail-order-wrapper">
              <div className="detail-order-wrapper-title">{t('detailOrder.shippingDetails')}</div>
              <div className="detail-order-wrapper-shipping">
                <div className="detail-order-wrapper-shipping-owner">{address}</div>
                <div>{phone}</div>
              </div>
            </div>
            <div className="detail-order-wrapper">
              <div className="detail-order-wrapper-title">{t('detailOrder.orderSummary')}</div>
              <div className="detail-order-wrapper-orderSummary">
                <div className="detail-order-wrapper-underline"></div>
                <div className="detail-order-wrapper-field">
                  <div className="detail-order-wrapper-field-left">{t('detailOrder.subtotal')}</div>
                  <div className="detail-order-wrapper-field-right">
                    <Price amount={beforeTax} />
                  </div>
                </div>
                <div className="detail-order-wrapper-underline"></div>
                <div className="detail-order-wrapper-field">
                  <div className="detail-order-wrapper-field-left">{t('detailOrder.gstVat')}</div>
                  <div className="detail-order-wrapper-field-right">
                    <Price amount={tax} />
                  </div>
                </div>
                <div className="detail-order-wrapper-underline"></div>
                <div className="detail-order-wrapper-field">
                  <div className="detail-order-wrapper-field-left">{t('detailOrder.total')}</div>
                  <div className="detail-order-wrapper-field-right">
                    <Price amount={afterTax} />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </DashboardContent>
  );
}

export default DetailOrder;
