import { NotificationMenuItem } from '@components';
import { Row, Button, Col, Menu, Dropdown, Empty, Select, Form } from 'antd';
import { FilterFilled } from '@ant-design/icons';
import { useUpdateReadNotification } from '@libs/hooks/notification';
import { useProfileOrganisation } from '@libs/hooks/organisation';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const NotificationMenu = ({
  unread,
  notifications,
  fetchNotification,
  className,
  notificationLoading,
  setFilter,
  setCreator,
  creator,
  isHideCreators,
  ...props
}) => {
  const { t } = useTranslation();
  const { Option } = Select;
  const { onUpdateNotification, updateLoading } = useUpdateReadNotification();
  const { data: profile } = useProfileOrganisation(true);
  const onUpdate = useCallback(
    async () => {
      if (creator === 'All') {
        const data = {
          allCreator: true,
          itemType: 'creator',
          profile: profile.map((p) => p.value)
        };
        await onUpdateNotification(data);
      } else {
        const data = {
          itemType: 'creator',
          profile: [creator]
        };
        await onUpdateNotification(data);
      }
    },
    [profile, creator] // eslint-disable-line
  );

  const creatorNotification = (value) => {
    setCreator(value);
  };

  const buttonStyle = isHideCreators ? { height: '40px', marginLeft: '0px' } : {};

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => setFilter('All')}>
        {t('components.notificationMenu.filters.all')}
      </Menu.Item>
      <Menu.Item key="2" onClick={() => setFilter('Unread')}>
        {t('components.notificationMenu.filters.unread')}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Row className="notification-header">
        <Col className="notification-header-left" sm={16} span={24}>
          <Form>
            <Row gutter={30}>
              <Col style={{ display: 'flex' }} span={24}>
                {!isHideCreators && (
                  <Form.Item>
                    <div className="toolbar-search">
                      <Select
                        loading={notificationLoading}
                        placeholder={
                          process.env.REACT_APP_NAME === 'patrons'
                            ? t('components.notificationMenu.patrons.creator.placeholder')
                            : t('components.notificationMenu.creator.placeholder')
                        }
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {
                          creatorNotification(value);
                        }}
                      >
                        <Option key={`all`} value={'All'}>
                          All
                        </Option>
                        {profile &&
                          profile.map((creator) => (
                            <Option key={`creator-${creator.value}`} value={creator.value}>
                              {creator.label}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </Form.Item>
                )}
                <Button
                  type="primary"
                  style={buttonStyle}
                  loading={updateLoading}
                  onClick={() => onUpdate()}
                  className="notification-header-left-readAll"
                >
                  {t('components.notificationMenu.buttons.markAllAsRead')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col className="notification-header-right" sm={8} span={24}>
          <Dropdown overlay={menu} placement="bottomLeft" arrow>
            <Button
              className="notification-header-right-button"
              loading={notificationLoading}
              type="primary"
            >
              {t('components.notificationMenu.buttons.filter')}
              <FilterFilled />
            </Button>
          </Dropdown>
        </Col>
      </Row>
      {notifications.length > 0 ? (
        notifications.map(function (notification, i) {
          return <NotificationMenuItem updateData key={i} index={i} notification={notification} />;
        })
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
  );
};

NotificationMenu.defaultProps = {
  unread: 0
};

export default NotificationMenu;
