import { useState } from 'react';

export const useInboxParams = (limit) => {
  const [query, setQuery] = useState({
    limit: limit,
    sort: { direction: 'desc', field: 'createdAt' },
    filter: { message: { exists: true } }
  });

  const onChangeFilter = (Filter) => {
    if (Filter) {
      const filter = {
        ...query.filter,
        status: { eq: Filter }
      };
      setQuery({ ...query, nextToken: undefined, filter });
    } else {
      const filter = {
        ...query.filter,
        status: undefined
      };
      setQuery({ ...query, nextToken: undefined, filter });
    }
  };

  const onChangeSearch = (data) => {
    if (data) {
      const filter = {
        ...query.filter,
        or: [
          { message: { matchPhrasePrefix: data } },
          { inboxID: { matchPhrasePrefix: data } },
          { fullName: { matchPhrasePrefix: data } },
          { email: { matchPhrasePrefix: data } }
        ]
      };
      setQuery({ ...query, nextToken: undefined, filter });
    } else {
      const filter = {
        ...query.filter,
        or: undefined
      };
      setQuery({ ...query, nextToken: undefined, filter });
    }
  };

  const onPaginate = (nextToken) => {
    setQuery({ ...query, nextToken });
  };

  return {
    query,
    onChangeFilter,
    onPaginate,
    onChangeSearch
  };
};
