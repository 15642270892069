export const URL_PATTERN =
  /(http(s?):\/\/)[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi;
export const FACEBOOK_PATTERN =
  /(http(s?):\/\/)(facebook.com\/){1,6}([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi;
export const TWITTER_PATTERN =
  /(http(s?):\/\/)(twitter.com\/){1,6}([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi;
export const INSTAGRAM_PATTERN =
  /(http(s?):\/\/)(instagram.com\/){1,6}([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi;
export const YOUTUBE_PATTERN =
  /(http(s?):\/\/)(youtube.com\/channel\/){1,6}([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi;
export const SOUNDCLOUD_PATTERN =
  /(http(s?):\/\/)(soundcloud.com\/){1,6}([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi;
export const TIKTOK_PATTERN =
  /(http(s?):\/\/)(tiktok.com\/@){1,6}([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi;
export const SNAPCHAT_PATTERN =
  /(http(s?):\/\/)(snapchat.com\/add\/){1,6}([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi;
export const SPOTIFY_PATTERN =
  /(http(s?):\/\/)(open.spotify.com\/){1,6}([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi;
export const APPLEMUSIC_PATTERN =
  /(http(s?):\/\/)(music.apple.com\/){1,6}([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi;
export const CLUBHOUSE_PATTERN =
  /(http(s?):\/\/)(clubhouse.com\/){1,6}([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi;

export const creatorCategories = [
  { name: 'Iconic Photographer', value: 'ICONIC_PHOTOGRAPHER' },
  { name: 'Iconic Musician', value: 'ICONIC_MUSICIAN' }
];
