export const accountUserByAccount = /* GraphQL */ `
  query AccountUserByAccount(
    $accountID: ID
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccountUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountUserByAccount(
      accountID: $accountID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        userID
        firstName
        lastName
        email
        roleType
        createdAt
        updatedAt
        owner
        user {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          bio
          tags
          verified
          fanProfile
          emailVerified
          joinDate
          createdAt
          updatedAt
          media {
            avatarUrl
          }
          profileManaged {
            items {
              profile {
                accountID
                firstName
                lastName
                username
                media {
                  avatarUrl
                }
              }
            }
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const accountUserInvitedByAccount = /* GraphQL */ `
  query AccountUserByAccountRole(
    $accountID: ID
    $roleType: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccountUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountUserByAccountRole(
      accountID: $accountID
      roleType: $roleType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        userID
        firstName
        lastName
        email
        roleType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        account {
          id
          name
          legalName
          address
          phone
          website
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
      }
      nextToken
      startedAt
    }
  }
`;

export const getAccountUser = /* GraphQL */ `
  query GetAccountUser($id: ID!) {
    getAccountUser(id: $id) {
      id
      accountID
      userID
      firstName
      lastName
      email
      roleType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      account {
        id
        name
        legalName
        address
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        members {
          nextToken
          startedAt
        }
      }
      owner
    }
  }
`;
export const profileManagerByAccountAndManager = /* GraphQL */ `
  query ProfileManagerByAccountAndManager(
    $accountID: ID
    $managerID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProfileManagerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    profileManagerByAccountAndManager(
      accountID: $accountID
      managerID: $managerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        profileID
        managerID
        accountID
        createdAt
        updatedAt
        firstName
        lastName
        _version
        _deleted
        _lastChangedAt
        manager {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          bio
          tags
          verified
          fanProfile
          emailVerified
          joinDate
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        profile {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          bio
          links {
            facebook
            youtube
            instagram
            twitter
            spotify
            soundcloud
            email
          }
          tags
          verified
          fanProfile
          emailVerified
          joinDate
          createdAt
          updatedAt
          media {
            id
            avatarUrl
            coverUrl
            createdAt
            updatedAt
            owner
          }
          discourseData {
            userID
            username
            channels
            category
          }
          statistics {
            id
            followerCount
            followingCount
            collectibleCreatedCount
            collectibleCollectedCount
            createdAt
            updatedAt
            owner
          }
          currentOwner {
            id
            owner
            accountID
            editors
            email
            username
            firstName
            lastName
            bio
            tags
            verified
            fanProfile
            emailVerified
            joinDate
            createdAt
            updatedAt
            media {
              avatarUrl
            }
          }
          managers {
            items {
              id
              manager {
                firstName
                lastName
                username
                media {
                  avatarUrl
                }
              }
            }
            nextToken
            startedAt
          }
          profileManaged {
            items {
              profile {
                firstName
                lastName
                username
                media {
                  avatarUrl
                }
              }
            }
            nextToken
            startedAt
          }
          __typename
        }
        owner
        editors
      }
      nextToken
      startedAt
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts($filter: ModelAccountFilterInput, $limit: Int, $nextToken: String) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        legalName
        address
        country
        state
        city
        postcode
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        members {
          items {
            roleType
            user {
              firstName
              lastName
              media {
                avatarUrl
              }
            }
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const searchAccounts = /* GraphQL */ `
  query SearchAccounts(
    $filter: SearchableAccountFilterInput
    $sort: SearchableAccountSortInput
    $limit: Int
    $nextToken: String
    $from: Int
    $filterMembers: ModelAccountUserFilterInput
    $limitMembers: Int
    $nextTokenMembers: String
  ) {
    searchAccounts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        legalName
        address
        country
        state
        city
        postcode
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        currentOwner {
          firstName
          lastName
          media {
            avatarUrl
          }
        }
        members(filter: $filterMembers, limit: $limitMembers, nextToken: $nextTokenMembers) {
          items {
            user {
              firstName
              lastName
              email
              media {
                avatarUrl
              }
              createdAt
            }
          }
          nextToken
          startedAt
        }
      }
      nextToken
      total
    }
  }
`;
