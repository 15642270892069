import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { DashboardContent, Pagination, Price, DisplayDate, Avatar, Link } from '@components';
import { Row, Col, Input, Table, Tag, Collapse, List } from 'antd';
import { useOrderHistoryByCreator } from '@libs/hooks/order-print';
import { colorStatusOrderMapper, statusOrderMapper as statusMapper } from '@libs/utils/mapper';

function OrderHistory() {
  const { Search } = Input;
  const { t } = useTranslation();
  const [resetPagination, setResetPagination] = useState(false);
  const { data, loading, getData, nextPageToken } = useOrderHistoryByCreator();
  const tierMapper = {
    NUMBERED_EDITION: 'Limited Edition',
    DIGITAL_ORIGINAL: 'Digital Original'
  };
  const description = (history) => {
    return `Print Collectible ${history?.exchange?.collectible?.title} (${
      tierMapper[history?.exchange?.tierType]
    } #${history?.exchange?.serialIndex}) by ${
      history?.exchange?.collectible?.creator?.displayName ||
      history?.exchange?.collectible?.creator?.firstName +
        ' ' +
        history?.exchange?.collectible?.creator?.lastName
    }`;
  };
  const handleSearch = (value) => {
    setResetPagination(!resetPagination);
    getData(null, value);
  };
  const columns = [
    {
      title: t('orderHistory.title'),
      key: 'orderID',
      responsive: ['xs'],
      render: (_, row) => {
        return (
          <div className="mobile-row">
            <div className="mobile-row__info">
              <div className="mobile-row__info__name">
                {' '}
                <Link to={`/order-history/${row.id}`}>
                  <span className="order-history-id">{row.prodigiOrderID}</span>
                </Link>
              </div>
            </div>
            <Collapse collapsible ghost className="datalist">
              <Collapse.Panel header={t('offers.list.expand')} key="1">
                <List itemLayout="vertical">
                  <List.Item>
                    <List.Item.Meta
                      title={t('orderHistory.column.date')}
                      description={<DisplayDate date={row?.createdAt} />}
                    />
                    <List.Item.Meta
                      title={t('orderHistory.column.collectible')}
                      description={
                        <>
                          <div>{description(row)}</div>
                          <div className="order-history-payment">{t('orderHistory.payment')}</div>
                        </>
                      }
                    />
                    <List.Item.Meta
                      title={t('orderHistory.column.from')}
                      description={
                        <>
                          <Avatar user={row?.user} />
                          <span className="avatar-name">{`${row?.user?.firstName}`}</span>
                        </>
                      }
                    />
                    <List.Item.Meta
                      title={t('orderHistory.column.status')}
                      description={
                        <Tag
                          style={{ color: 'white', fontSize: '10px' }}
                          color={colorStatusOrderMapper[row?.orderStatus]}
                        >
                          {statusMapper[row?.orderStatus]}
                        </Tag>
                      }
                    />
                    <List.Item.Meta
                      title={t('orderHistory.column.amount')}
                      description={<Price amount={row?.totalPrice} />}
                    />
                  </List.Item>
                </List>
              </Collapse.Panel>
            </Collapse>
          </div>
        );
      }
    },
    {
      title: '#',
      dataIndex: 'prodigiOrderID',
      key: 'id',
      width: 250,
      responsive: ['sm'],
      render: (_, row) => (
        <Link to={`/order-history/${row.id}`}>
          <span className="order-history-id">{row.prodigiOrderID}</span>
        </Link>
      )
    },
    {
      title: t('orderHistory.column.date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 250,
      responsive: ['md'],
      render: (value) => <DisplayDate date={value} />
    },
    {
      title: t('orderHistory.column.collectible'),
      key: 'description',
      responsive: ['md'],
      width: 400,
      render: (value) => (
        <>
          <div className="order-history-description">
            <div>{description(value)}</div>
            <div className="order-history-payment">{t('orderHistory.payment')}</div>
          </div>
        </>
      )
    },
    {
      title: t('orderHistory.column.from'),
      dataIndex: 'user',
      key: 'owner',
      width: 300,
      responsive: ['sm'],
      render: (value) => (
        <>
          <Avatar user={value} />
          <span className="avatar-name">{`${value?.firstName}`}</span>
        </>
      )
    },
    {
      title: t('orderHistory.column.status'),
      dataIndex: 'orderStatus',
      key: 'orderStatus',
      width: 350,
      responsive: ['md'],
      render: (value) => {
        const textColor = statusMapper[value] === 'Draft' ? 'black' : 'white';
        return (
          <Tag style={{ color: textColor, fontSize: '10px' }} color={colorStatusOrderMapper[value]}>
            {statusMapper[value]}
          </Tag>
        );
      }
    },
    {
      title: t('orderHistory.column.amount'),
      key: 'total',
      responsive: ['md'],
      render: (value) => <Price amount={value.totalPrice} />
    }
  ];
  return (
    <DashboardContent className="order-history-page" title={t('orderHistory.title')}>
      <Helmet>
        <title>{t('orderHistory.title')}</title>
      </Helmet>
      <div className="order-toolbar">
        <Row className="order-page-search">
          <Col xs={0} sm={4}>
            <div className="toolbar-search">
              <Search
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
                placeholder={t('orderHistory.search')}
              />
            </div>
          </Col>
          <Col sm={8} xs={24}></Col>
        </Row>
      </div>
      <div className="order-history">
        <div className="fans-table">
          <Table
            bordered
            dataSource={data}
            columns={columns}
            loading={loading}
            pagination={false}
          />
          <Pagination
            nextToken={nextPageToken}
            loading={loading}
            fetchData={getData}
            reset={resetPagination}
          />
        </div>
      </div>
    </DashboardContent>
  );
}

export default OrderHistory;
