export const listChannels = /* GraphQL */ `
  query ListChannels($filter: ModelChannelFilterInput, $limit: Int, $nextToken: String) {
    listChannels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        owner
        removable
        members {
          collectibleID
          serialIndex
          userID
        }
        collectibles
        lastActivityAt
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;

export const channelByOwnerAndCreatedAt = /* GraphQL */ `
  query ChannelByOwnerAndCreatedAt(
    $owner: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDiscourseChannelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    channelByOwnerAndCreatedAt(
      owner: $owner
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        owner
        removable
        members {
          collectibleID
          serialIndex
          userID
        }
        collectibles
        lastActivityAt
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;
