import React, { useEffect, useCallback, useState } from 'react';
import cx from 'classnames';
import { Player } from '@components';
import { useOutsider } from '@libs/utils/outsider';
import MimeType, { createAssetUrl } from '@libs/utils/mimeType';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

function Lightbox({ show, onClose, index, setIndex, sources }) {
  const [play, setPlay] = useState(show);
  const handleCloseModal = () => onClose();
  const { ref: refModal } = useOutsider(handleCloseModal);
  const keyFunction = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        handleCloseModal();
      }

      if (event.key === 'ArrowLeft' && index > 0) {
        setIndex && setIndex(index - 1);
      }
      if (event.key === 'ArrowRight' && index < sources.length - 1) {
        setIndex && setIndex(index + 1);
      }
    },
    [index] //eslint-disable-line
  );

  useEffect(() => {
    document.addEventListener('keydown', keyFunction, false);

    return () => {
      document.removeEventListener('keydown', keyFunction, false);
    };
  }, [index]); //eslint-disable-line

  const url = sources?.[index]?.srcSet || sources?.[index]?.src;
  return (
    <div
      className={cx({
        'lightbox-container': true,
        'lightbox-container-show': show
      })}
    >
      <div className="lightbox-container__wrapper">
        <LeftOutlined
          style={{ color: index === 0 ? 'darkgray' : 'white', fontSize: 20, marginLeft: 50 }}
        />
        <div className="lightbox-container__wrapper__content" ref={refModal}>
          {MimeType(url) ? (
            <div onClick={() => setPlay(!play)}>
              <Player
                src={url}
                play={play}
                className="lightbox-container__image"
                fallbackSrc={createAssetUrl(
                  `fanclub_uploads/${url.split('/').pop().split('.')[0]}.mp4`,
                  null,
                  true
                )}
              />
            </div>
          ) : (
            <div>
              <img src={url} alt={`img-${url}`} className="lightbox-container__image" />
            </div>
          )}
        </div>
        <RightOutlined
          style={{
            color: index > sources.length - 2 ? 'darkgray' : 'white',
            fontSize: 20,
            marginRight: 50
          }}
        />
      </div>
    </div>
  );
}

Lightbox.defaultProps = {
  onClose: () => {},
  show: false
};

export default Lightbox;
