import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { DashboardContent, Price, Link } from '@components';
import { Row, Col, Image, Tag, Spin } from 'antd';
import { useOrderHistoryById } from '@libs/hooks/order-print';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { colorStatusOrderMapper, statusOrderMapper as statusMapper } from '@libs/utils/mapper';
import { phoneCodes, prodigiCountries, calculateTax, SKUSize } from '@libs/utils/prodigi';
import { ReactComponent as ZoomIn } from '@assets/svg/zoom-in.svg';
import { ReactComponent as CrossZoom } from '@assets/svg/cross-zoom.svg';

function DetailOrder() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [showZoom, setShowZoom] = useState(false);
  const { data, loading } = useOrderHistoryById(id);
  const url = data?.shippingProduct?.assets?.slice(1, -1);
  const certificateUrl = data?.shippingProduct?.certificate?.slice(1, -1);
  const Framesize = SKUSize?.filter(
    (x) => x.value === data?.shippingProduct?.sku.replace(/_/g, '-')
  )[0];
  const title = data?.exchange?.collectible?.title;
  const textColor = statusMapper[data?.orderStatus] === 'Draft' ? 'black' : 'white';
  const qty = `${data?.shippingProduct?.copies} Item`;
  const tier = 'Limited Edition #' + data?.exchange?.serialIndex;

  const shippingDetail = data?.shippingDetail;
  const { tax, beforeTax, afterTax } = calculateTax(data?.totalPrice);
  const country = prodigiCountries.filter((x) => x.isoCode === shippingDetail?.country);
  const phoneCode = phoneCodes[shippingDetail?.country];
  const address = `${shippingDetail?.address}, ${shippingDetail?.city} ${shippingDetail?.postalCode}, ${country[0]?.name}`;
  const phone = `+${phoneCode} ${shippingDetail?.phone}`;
  const size = `${Framesize?.width}" x ${Framesize?.height}" (${(Framesize?.width * 2.54).toFixed(
    1
  )} x ${(Framesize?.height * 2.54).toFixed(1)} cm)`;

  return (
    <DashboardContent
      title={
        <Link to="/order-history">
          <div>
            <ArrowLeftOutlined style={{ marginRight: '14px' }} />
            {t('detailOrder.title')}
          </div>
        </Link>
      }
    >
      <Helmet>
        <title>{t('detailOrder.title')}</title>
      </Helmet>
      {loading ? (
        <center>
          <Spin />
        </center>
      ) : (
        <Row className="detail-order">
          <Col className="detail-order-image-patrons" lg={15} span={24}>
            <div className="detial-order-image-patrons__wrapper">
              <Image
                style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.08)', margin: '0px' }}
                preview={false}
                src={url}
              />
              <div
                onClick={() => setShowZoom(true)}
                className="detail-order-image-patrons__wrapper__zoom"
              >
                <ZoomIn />
              </div>
            </div>
            <Image
              style={{
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.08)',
                margin: 'auto',
                marginTop: '30px',
                width: '78%'
              }}
              preview={false}
              src={certificateUrl}
            />
          </Col>
          <Col className="detail-order-space" lg={1} span={24}></Col>
          <Col lg={8} span={24}>
            <div className="detail-order-wrapper">
              <div className="detail-order-wrapper-title">{title}</div>
              <div>{tier}</div>
              <div className="detail-order-wrapper-underline"></div>
              <div className="detail-order-wrapper-field">
                <div className="detail-order-wrapper-field-left">{t('detailOrder.size')}</div>
                <div className="detail-order-wrapper-field-right">{size}</div>
              </div>
              <div className="detail-order-wrapper-underline"></div>
              <div className="detail-order-wrapper-field">
                <div className="detail-order-wrapper-field-left">{t('detailOrder.qty')}</div>
                <div className="detail-order-wrapper-field-right">{qty}</div>
              </div>
              <div className="detail-order-wrapper-underline"></div>
              <div className="detail-order-wrapper-field">
                <div className="detail-order-wrapper-field-left">{t('detailOrder.status')}</div>
                <div className="detail-order-wrapper-field-right">
                  {' '}
                  <Tag
                    style={{ color: textColor, fontSize: '10px', borderColor: '#E9E9EB' }}
                    color={colorStatusOrderMapper[data?.orderStatus]}
                  >
                    {statusMapper[data?.orderStatus]}
                  </Tag>
                </div>
              </div>
            </div>
            <div className="detail-order-wrapper">
              <div className="detail-order-wrapper-title">{t('detailOrder.shippingDetails')}</div>
              <div className="detail-order-wrapper-shipping">
                <div className="detail-order-wrapper-shipping-owner">{address}</div>
                <div>{phone}</div>
              </div>
            </div>
            <div className="detail-order-wrapper">
              <div className="detail-order-wrapper-title">{t('detailOrder.orderSummary')}</div>
              <div className="detail-order-wrapper-orderSummary">
                <div className="detail-order-wrapper-underline"></div>
                <div className="detail-order-wrapper-field">
                  <div className="detail-order-wrapper-field-left">{t('detailOrder.subtotal')}</div>
                  <div className="detail-order-wrapper-field-right">
                    <Price amount={beforeTax} />
                  </div>
                </div>
                <div className="detail-order-wrapper-underline"></div>
                <div className="detail-order-wrapper-field">
                  <div className="detail-order-wrapper-field-left">{t('detailOrder.gstVat')}</div>
                  <div className="detail-order-wrapper-field-right">
                    <Price amount={tax} />
                  </div>
                </div>
                <div className="detail-order-wrapper-underline"></div>
                <div className="detail-order-wrapper-field">
                  <div className="detail-order-wrapper-field-left">{t('detailOrder.total')}</div>
                  <div className="detail-order-wrapper-field-right">
                    <Price amount={afterTax} />
                  </div>
                </div>
              </div>
            </div>
          </Col>
          {showZoom && (
            <div className="detail-order-image-patrons__zoom">
              <div className="detail-order-image-patrons__zoom__image">
                <div className="detail-order-image-patrons__zoom__image__media">
                  <img src={url} alt="" />
                </div>
                <div className="detail-order-image-patrons__zoom__image__close">
                  <CrossZoom onClick={() => setShowZoom(false)} />
                </div>
              </div>
            </div>
          )}
        </Row>
      )}
    </DashboardContent>
  );
}

export default DetailOrder;
