import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Button, Col, Form, Input, Popconfirm, Row, Table, Typography } from 'antd';

import { Avatar, AvatarGroup, DashboardContent } from '@components';

import { useOrganizationStaffs, useOrganizationStaffInvited } from '@libs/hooks/organisation';
import { useAuth } from '@libs/contexts/auth';
import { roleMapper } from './utils';

const { Search } = Input;
const { Link: AntLink } = Typography;

function Teams() {
  const { t } = useTranslation();
  const { user, permission, logout } = useAuth();
  const {
    loading: dataLoading,
    data: members,
    getData: fetchOrganizationStaff,
    onInvite,
    onDelete,
    invitationLoading
  } = useOrganizationStaffs();

  const {
    loading: invitedLoading,
    data: invited,
    cancelInvite,
    fetchData
  } = useOrganizationStaffInvited();

  const handleLeave = async (id, accountID) => {
    await onDelete(id, accountID, 'leave');
    logout();
  };
  const [showInvited, setShowInvited] = useState(true);

  const rowData = members.concat(invited);
  const columns = [
    {
      title: t('teams.list.name'),
      render: (_, row) => (
        <div className="profile-avatar">
          {row?.userID !== 'NULL' && (
            <>
              <Avatar user={row?.user} />
              <div className="profile-avatar__info">
                <div className="profile-avatar__info__name">{`${
                  row?.firstName || row?.user?.firstName || ''
                } ${row?.lastName || row?.user?.lastName || ''}`}</div>
              </div>
            </>
          )}
        </div>
      )
    },
    {
      title: t('teams.list.email'),
      dataIndex: 'email',
      key: 'email',
      render: (email, row) => (
        <div>
          {row?.userID !== 'NULL' ? (
            <AntLink href={`mailto:${email || row?.user?.email}`}>
              {email || row?.user?.email}
            </AntLink>
          ) : (
            <AntLink href={`mailto:${email}`}>{email}</AntLink>
          )}
        </div>
      )
    },
    {
      title: t('teams.list.role'),
      dataIndex: 'roleType',
      key: 'roleType',
      render: (role) => roleMapper[role]
    },
    {
      title: t('teams.list.managedProfiles'),
      dataIndex: 'profileManaged',
      key: 'profileManaged.items',
      render: (_, row) => (
        <>
          {row?.userID !== 'NULL' && (
            <AvatarGroup
              users={row?.user?.profileManaged?.items}
              accountID={user?.accountID}
              dataIndex="profile"
            />
          )}
        </>
      )
    },
    {
      title: '',
      render: (_, row) => {
        if (row?.userID !== 'NULL') {
          const isInvitation = !row?.userID;
          const isMyself = row?.user?.id === user?.id;
          const isAdmin = permission?.roleType === 1;

          if (isAdmin) {
            return (
              <Popconfirm
                disabled={row?.user?.id === user?.id}
                title={
                  isMyself
                    ? t('teams.list.removeAccess.leaveConfirm')
                    : t('teams.list.removeAccess.confirm')
                }
                onConfirm={() =>
                  isMyself
                    ? handleLeave(row?.userID, row?.accountID)
                    : isInvitation
                    ? cancelInvite(row?.id)
                    : onDelete(row?.userID, row?.accountID, 'remove')
                }
                okText={t('teams.list.removeAccess.yes')}
                cancelText={t('teams.list.removeAccess.no')}
              >
                <Button type="link" danger>
                  {isMyself
                    ? t('teams.list.removeAccess.leave')
                    : isInvitation
                    ? t('teams.list.removeAccess.cancel')
                    : t('teams.list.removeAccess.label')}
                </Button>
              </Popconfirm>
            );
          }

          return isMyself ? (
            <Popconfirm
              title={t('teams.list.removeAccess.leaveConfirm')}
              onConfirm={() => handleLeave(row?.userID, row?.accountID)}
              okText={t('teams.list.removeAccess.yes')}
              cancelText={t('teams.list.removeAccess.no')}
            >
              <Button type="link" danger>
                {t('teams.list.removeAccess.leave')}
              </Button>
            </Popconfirm>
          ) : null;
        } else {
          return (
            <Button type="link" danger>
              {t('teams.list.waitingConfirmation')}
            </Button>
          );
        }
      }
    }
  ];

  const handleSearch = async (data) => {
    await fetchOrganizationStaff(null, data);
  };

  const onInviteSubmit = async (values) => {
    await onInvite(values.email);
    fetchData();
  };
  return (
    <>
      <DashboardContent title={t('teams.title')}>
        <Row className="toolbar">
          <Col span={24} lg={8} className="toolbar__left">
            <div className="toolbar-search">
              <Search
                onChange={(e) => {
                  setShowInvited(!e.target.value);
                  handleSearch(e.target.value);
                }}
                placeholder={t('teams.search')}
              />
            </div>
          </Col>
          <Col span={24} lg={16} className="toolbar__right">
            <div className="toolbar-invite">
              <Form onFinish={onInviteSubmit} className="quick-invite-form" layout="inline">
                <Form.Item
                  name="email"
                  rules={[
                    { type: 'email', message: t('auth.fields.email.type') },
                    { required: true, message: t('auth.fields.email.required') }
                  ]}
                >
                  <Input placeholder={t('teams.invite.placeholder')} />
                </Form.Item>
                <Button htmlType="submit" type="primary" loading={invitationLoading}>
                  {t('teams.invite.button')}
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
        <div className="manage-content">
          <div className="fans-table">
            <Table
              bordered
              data
              loading={dataLoading && invitedLoading}
              dataSource={rowData}
              columns={columns}
              rowClassName={(record) => record?.userID === 'NULL' && !showInvited && 'hide'}
              pagination={false}
            />
          </div>
        </div>
      </DashboardContent>
      <Helmet>
        <title>{t('teams.title')}</title>
      </Helmet>
    </>
  );
}

export default Teams;
