import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter as Router } from 'react-router-dom';

import { Helmet, SuspenseLoading } from '@components';
import { AuthProvider } from '@libs/contexts/auth';
import { SidebarProvider } from '@libs/utils/sider';
import { VlagProvider } from '@vincere/react-vlag';
import aws_exports from './aws-exports';

import '@plugins/i18n';

import App from './App';

import reportWebVitals from './reportWebVitals';

const flags = [];

Amplify.configure(aws_exports);

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <Helmet />
      <Router>
        <AuthProvider>
          <SidebarProvider>
            <Suspense fallback={<SuspenseLoading />}>
              <VlagProvider flags={flags}>
                <App />
              </VlagProvider>
            </Suspense>
          </SidebarProvider>
        </AuthProvider>
      </Router>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
