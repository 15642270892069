/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const subscribeWithoutLogin = /* GraphQL */ `
  mutation SubscribeWithoutLogin($input: SubscribeWithoutLoginInput!) {
    subscribeWithoutLogin(input: $input)
  }
`;
export const updateStatusWaitlist = /* GraphQL */ `
  mutation UpdateStatusWaitlist($input: UpdateStatusWaitlistInput!) {
    updateStatusWaitlist(input: $input)
  }
`;
export const discourseUpdate = /* GraphQL */ `
  mutation DiscourseUpdate($input: DiscourseUpdateInput!) {
    discourseUpdate(input: $input)
  }
`;
export const collectibleCreateComment = /* GraphQL */ `
  mutation CollectibleCreateComment($input: DiscoursePostInput!) {
    collectibleCreateComment(input: $input)
  }
`;
export const collectibleReplyComment = /* GraphQL */ `
  mutation CollectibleReplyComment($input: DiscoursePostInput!) {
    collectibleReplyComment(input: $input)
  }
`;
export const collectibleLikeComment = /* GraphQL */ `
  mutation CollectibleLikeComment($input: DiscourseActionInput!) {
    collectibleLikeComment(input: $input)
  }
`;
export const collectibleDeleteComment = /* GraphQL */ `
  mutation CollectibleDeleteComment($input: DiscourseDeleteInput!) {
    collectibleDeleteComment(input: $input)
  }
`;
export const collectibleUnlikeComment = /* GraphQL */ `
  mutation CollectibleUnlikeComment($input: DiscourseActionInput!) {
    collectibleUnlikeComment(input: $input)
  }
`;
export const createCollections = /* GraphQL */ `
  mutation CreateCollections($input: CreateCollectionsInput!) {
    createCollections(input: $input)
  }
`;
export const updateCollections = /* GraphQL */ `
  mutation UpdateCollections($input: UpdateCollectionsInput!) {
    updateCollections(input: $input)
  }
`;
export const deleteCollections = /* GraphQL */ `
  mutation DeleteCollections($input: UpdateCollectionsInput!) {
    deleteCollections(input: $input)
  }
`;
export const deleteInbox = /* GraphQL */ `
  mutation DeleteInbox($input: DeleteInboxInput!) {
    deleteInbox(input: $input)
  }
`;
export const readInbox = /* GraphQL */ `
  mutation ReadInbox($input: DeleteInboxInput!) {
    readInbox(input: $input)
  }
`;
export const readAllInbox = /* GraphQL */ `
  mutation ReadAllInbox($input: DeleteInboxInput!) {
    readAllInbox(input: $input)
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount($input: CreateAccountInput!, $condition: ModelAccountConditionInput) {
    createAccount(input: $input, condition: $condition) {
      id
      name
      legalName
      address
      country
      state
      city
      postcode
      phone
      website
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      members {
        items {
          id
          accountID
          userID
          firstName
          lastName
          email
          roleType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount($input: UpdateAccountInput!, $condition: ModelAccountConditionInput) {
    updateAccount(input: $input, condition: $condition) {
      id
      name
      legalName
      address
      country
      state
      city
      postcode
      phone
      website
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      members {
        items {
          id
          accountID
          userID
          firstName
          lastName
          email
          roleType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount($input: DeleteAccountInput!, $condition: ModelAccountConditionInput) {
    deleteAccount(input: $input, condition: $condition) {
      id
      name
      legalName
      address
      country
      state
      city
      postcode
      phone
      website
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      members {
        items {
          id
          accountID
          userID
          firstName
          lastName
          email
          roleType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createAccountCreator = /* GraphQL */ `
  mutation CreateAccountCreator(
    $input: CreateAccountCreatorInput!
    $condition: ModelAccountCreatorConditionInput
  ) {
    createAccountCreator(input: $input, condition: $condition) {
      id
      accountID
      userID
      firstName
      lastName
      email
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      account {
        id
        name
        legalName
        address
        country
        state
        city
        postcode
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        members {
          nextToken
          startedAt
        }
      }
      owner
      user {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateAccountCreator = /* GraphQL */ `
  mutation UpdateAccountCreator(
    $input: UpdateAccountCreatorInput!
    $condition: ModelAccountCreatorConditionInput
  ) {
    updateAccountCreator(input: $input, condition: $condition) {
      id
      accountID
      userID
      firstName
      lastName
      email
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      account {
        id
        name
        legalName
        address
        country
        state
        city
        postcode
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        members {
          nextToken
          startedAt
        }
      }
      owner
      user {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteAccountCreator = /* GraphQL */ `
  mutation DeleteAccountCreator(
    $input: DeleteAccountCreatorInput!
    $condition: ModelAccountCreatorConditionInput
  ) {
    deleteAccountCreator(input: $input, condition: $condition) {
      id
      accountID
      userID
      firstName
      lastName
      email
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      account {
        id
        name
        legalName
        address
        country
        state
        city
        postcode
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        members {
          nextToken
          startedAt
        }
      }
      owner
      user {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;

export const createInbox = /* GraphQL */ `
  mutation CreateInbox($input: InboxObjectInput!) {
    createInbox(input: $input)
  }
`;

export const createActivity = /* GraphQL */ `
  mutation CreateActivity($input: CreateActivityInput!, $condition: ModelActivityConditionInput) {
    createActivity(input: $input, condition: $condition) {
      id
      userID
      type
      event
      lambdaEventID
      lambdaEventName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateActivity = /* GraphQL */ `
  mutation UpdateActivity($input: UpdateActivityInput!, $condition: ModelActivityConditionInput) {
    updateActivity(input: $input, condition: $condition) {
      id
      userID
      type
      event
      lambdaEventID
      lambdaEventName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteActivity = /* GraphQL */ `
  mutation DeleteActivity($input: DeleteActivityInput!, $condition: ModelActivityConditionInput) {
    deleteActivity(input: $input, condition: $condition) {
      id
      userID
      type
      event
      lambdaEventID
      lambdaEventName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createFeaturedArtist = /* GraphQL */ `
  mutation CreateFeaturedArtist(
    $input: CreateFeaturedArtistInput!
    $condition: ModelFeaturedArtistConditionInput
  ) {
    createFeaturedArtist(input: $input, condition: $condition) {
      id
      artistName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateFeaturedArtist = /* GraphQL */ `
  mutation UpdateFeaturedArtist(
    $input: UpdateFeaturedArtistInput!
    $condition: ModelFeaturedArtistConditionInput
  ) {
    updateFeaturedArtist(input: $input, condition: $condition) {
      id
      artistName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteFeaturedArtist = /* GraphQL */ `
  mutation DeleteFeaturedArtist(
    $input: DeleteFeaturedArtistInput!
    $condition: ModelFeaturedArtistConditionInput
  ) {
    deleteFeaturedArtist(input: $input, condition: $condition) {
      id
      artistName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createFeaturedArtistCollectible = /* GraphQL */ `
  mutation CreateFeaturedArtistCollectible(
    $input: CreateFeaturedArtistCollectibleInput!
    $condition: ModelFeaturedArtistCollectibleConditionInput
  ) {
    createFeaturedArtistCollectible(input: $input, condition: $condition) {
      id
      collectibleID
      featuredArtistID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaDescription
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableGenre
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      featuredArtist {
        id
        artistName
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const updateFeaturedArtistCollectible = /* GraphQL */ `
  mutation UpdateFeaturedArtistCollectible(
    $input: UpdateFeaturedArtistCollectibleInput!
    $condition: ModelFeaturedArtistCollectibleConditionInput
  ) {
    updateFeaturedArtistCollectible(input: $input, condition: $condition) {
      id
      collectibleID
      featuredArtistID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaDescription
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableGenre
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      featuredArtist {
        id
        artistName
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const deleteFeaturedArtistCollectible = /* GraphQL */ `
  mutation DeleteFeaturedArtistCollectible(
    $input: DeleteFeaturedArtistCollectibleInput!
    $condition: ModelFeaturedArtistCollectibleConditionInput
  ) {
    deleteFeaturedArtistCollectible(input: $input, condition: $condition) {
      id
      collectibleID
      featuredArtistID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaDescription
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableGenre
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      featuredArtist {
        id
        artistName
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const createCollection = /* GraphQL */ `
  mutation CreateCollection(
    $input: CreateCollectionInput!
    $condition: ModelCollectionConditionInput
  ) {
    createCollection(input: $input, condition: $condition) {
      id
      userID
      owner
      accountID
      name
      slug
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      collectibles {
        items {
          id
          collectionID
          collectibleID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      currentUser {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateCollection = /* GraphQL */ `
  mutation UpdateCollection(
    $input: UpdateCollectionInput!
    $condition: ModelCollectionConditionInput
  ) {
    updateCollection(input: $input, condition: $condition) {
      id
      userID
      owner
      accountID
      name
      slug
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      collectibles {
        items {
          id
          collectionID
          collectibleID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      currentUser {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteCollection = /* GraphQL */ `
  mutation DeleteCollection(
    $input: DeleteCollectionInput!
    $condition: ModelCollectionConditionInput
  ) {
    deleteCollection(input: $input, condition: $condition) {
      id
      userID
      owner
      accountID
      name
      slug
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      collectibles {
        items {
          id
          collectionID
          collectibleID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      currentUser {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createCollectionCollectible = /* GraphQL */ `
  mutation CreateCollectionCollectible(
    $input: CreateCollectionCollectibleInput!
    $condition: ModelCollectionCollectibleConditionInput
  ) {
    createCollectionCollectible(input: $input, condition: $condition) {
      id
      collectionID
      collectibleID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaDescription
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableGenre
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      collection {
        id
        userID
        owner
        accountID
        name
        slug
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        collectibles {
          nextToken
          startedAt
        }
        currentUser {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      owner
    }
  }
`;
export const updateCollectionCollectible = /* GraphQL */ `
  mutation UpdateCollectionCollectible(
    $input: UpdateCollectionCollectibleInput!
    $condition: ModelCollectionCollectibleConditionInput
  ) {
    updateCollectionCollectible(input: $input, condition: $condition) {
      id
      collectionID
      collectibleID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaDescription
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableGenre
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      collection {
        id
        userID
        owner
        accountID
        name
        slug
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        collectibles {
          nextToken
          startedAt
        }
        currentUser {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      owner
    }
  }
`;
export const deleteCollectionCollectible = /* GraphQL */ `
  mutation DeleteCollectionCollectible(
    $input: DeleteCollectionCollectibleInput!
    $condition: ModelCollectionCollectibleConditionInput
  ) {
    deleteCollectionCollectible(input: $input, condition: $condition) {
      id
      collectionID
      collectibleID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaDescription
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableGenre
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      collection {
        id
        userID
        owner
        accountID
        name
        slug
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        collectibles {
          nextToken
          startedAt
        }
        currentUser {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      owner
    }
  }
`;
export const createExchange = /* GraphQL */ `
  mutation CreateExchange($input: CreateExchangeInput!, $condition: ModelExchangeConditionInput) {
    createExchange(input: $input, condition: $condition) {
      id
      exchangeType
      creatorID
      currentOwnerID
      collectibleID
      collectibleReleaseIndex
      releaseID
      serialIndex
      listingType
      releaseDate
      isReleased
      sellingPrice
      offerPrice
      startingPrice
      lastPrice
      auctionMinimumAllowedBidPrice
      isMinimumOfferVisible
      isAutoAcceptActive
      bidPrice
      maxBidPrice
      expiryDate
      isSold
      status
      patronID
      paymentProvider
      bitpayPaymentRequested
      paymentRequestExpiredAt
      paymentReferenceID
      checkoutReceiptID
      createdAt
      updatedAt
      nftTransactionHash
      nftOwnerAddress
      nftContractAddress
      nftTokenID
      userID
      currentListingType
      lastPurchasedAt
      slug
      tierType
      releaseCount
      previousOwnerID
      previousPrice
      finalPrice
      currentPrice
      previousExchangeID
      parentExchangeID
      bitpayReceiptID
      order {
        tax
        platformFee
        royaltyFee
      }
      searchableExchangeType
      searchableIsSold
      _version
      _deleted
      _lastChangedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaDescription
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableGenre
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      owner
      previousExchange {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        paymentProvider
        bitpayPaymentRequested
        paymentRequestExpiredAt
        paymentReferenceID
        checkoutReceiptID
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        order {
          tax
          platformFee
          royaltyFee
        }
        searchableExchangeType
        searchableIsSold
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      parentExchange {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        paymentProvider
        bitpayPaymentRequested
        paymentRequestExpiredAt
        paymentReferenceID
        checkoutReceiptID
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        order {
          tax
          platformFee
          royaltyFee
        }
        searchableExchangeType
        searchableIsSold
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      release {
        id
        collectibleReleaseIndex
        slug
        tierType
        serialIndex
        releaseCount
        releaseDate
        collectibleID
        userID
        previousOwnerID
        previousPrice
        currentPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        finalPrice
        currentListingType
        lastPurchasedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      creator {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      patron {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      previousOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateExchange = /* GraphQL */ `
  mutation UpdateExchange($input: UpdateExchangeInput!, $condition: ModelExchangeConditionInput) {
    updateExchange(input: $input, condition: $condition) {
      id
      exchangeType
      creatorID
      currentOwnerID
      collectibleID
      collectibleReleaseIndex
      releaseID
      serialIndex
      listingType
      releaseDate
      isReleased
      sellingPrice
      offerPrice
      startingPrice
      lastPrice
      auctionMinimumAllowedBidPrice
      isMinimumOfferVisible
      isAutoAcceptActive
      bidPrice
      maxBidPrice
      expiryDate
      isSold
      status
      patronID
      paymentProvider
      bitpayPaymentRequested
      paymentRequestExpiredAt
      paymentReferenceID
      checkoutReceiptID
      createdAt
      updatedAt
      nftTransactionHash
      nftOwnerAddress
      nftContractAddress
      nftTokenID
      userID
      currentListingType
      lastPurchasedAt
      slug
      tierType
      releaseCount
      previousOwnerID
      previousPrice
      finalPrice
      currentPrice
      previousExchangeID
      parentExchangeID
      bitpayReceiptID
      order {
        tax
        platformFee
        royaltyFee
      }
      searchableExchangeType
      searchableIsSold
      _version
      _deleted
      _lastChangedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaDescription
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableGenre
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      owner
      previousExchange {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        paymentProvider
        bitpayPaymentRequested
        paymentRequestExpiredAt
        paymentReferenceID
        checkoutReceiptID
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        order {
          tax
          platformFee
          royaltyFee
        }
        searchableExchangeType
        searchableIsSold
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      parentExchange {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        paymentProvider
        bitpayPaymentRequested
        paymentRequestExpiredAt
        paymentReferenceID
        checkoutReceiptID
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        order {
          tax
          platformFee
          royaltyFee
        }
        searchableExchangeType
        searchableIsSold
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      release {
        id
        collectibleReleaseIndex
        slug
        tierType
        serialIndex
        releaseCount
        releaseDate
        collectibleID
        userID
        previousOwnerID
        previousPrice
        currentPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        finalPrice
        currentListingType
        lastPurchasedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      creator {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      patron {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      previousOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteExchange = /* GraphQL */ `
  mutation DeleteExchange($input: DeleteExchangeInput!, $condition: ModelExchangeConditionInput) {
    deleteExchange(input: $input, condition: $condition) {
      id
      exchangeType
      creatorID
      currentOwnerID
      collectibleID
      collectibleReleaseIndex
      releaseID
      serialIndex
      listingType
      releaseDate
      isReleased
      sellingPrice
      offerPrice
      startingPrice
      lastPrice
      auctionMinimumAllowedBidPrice
      isMinimumOfferVisible
      isAutoAcceptActive
      bidPrice
      maxBidPrice
      expiryDate
      isSold
      status
      patronID
      paymentProvider
      bitpayPaymentRequested
      paymentRequestExpiredAt
      paymentReferenceID
      checkoutReceiptID
      createdAt
      updatedAt
      nftTransactionHash
      nftOwnerAddress
      nftContractAddress
      nftTokenID
      userID
      currentListingType
      lastPurchasedAt
      slug
      tierType
      releaseCount
      previousOwnerID
      previousPrice
      finalPrice
      currentPrice
      previousExchangeID
      parentExchangeID
      bitpayReceiptID
      order {
        tax
        platformFee
        royaltyFee
      }
      searchableExchangeType
      searchableIsSold
      _version
      _deleted
      _lastChangedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaDescription
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableGenre
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      owner
      previousExchange {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        paymentProvider
        bitpayPaymentRequested
        paymentRequestExpiredAt
        paymentReferenceID
        checkoutReceiptID
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        order {
          tax
          platformFee
          royaltyFee
        }
        searchableExchangeType
        searchableIsSold
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      parentExchange {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        paymentProvider
        bitpayPaymentRequested
        paymentRequestExpiredAt
        paymentReferenceID
        checkoutReceiptID
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        order {
          tax
          platformFee
          royaltyFee
        }
        searchableExchangeType
        searchableIsSold
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      release {
        id
        collectibleReleaseIndex
        slug
        tierType
        serialIndex
        releaseCount
        releaseDate
        collectibleID
        userID
        previousOwnerID
        previousPrice
        currentPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        finalPrice
        currentListingType
        lastPurchasedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      creator {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      patron {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      previousOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const acceptOffer = /* GraphQL */ `
  mutation AcceptOffer($input: AcceptOfferInput!) {
    acceptOffer(input: $input)
  }
`;
export const cancelOffer = /* GraphQL */ `
  mutation CancelOffer($input: CancelOfferInput!) {
    cancelOffer(input: $input)
  }
`;
export const customCreateCollectible = /* GraphQL */ `
  mutation CustomCreateCollectible($input: CustomCreateCollectibleInput!) {
    customCreateCollectible(input: $input)
  }
`;
export const updateCollectibleDraft = /* GraphQL */ `
  mutation UpdateCollectibleDraft($input: CustomUpdateCollectibleInput!) {
    updateCollectibleDraft(input: $input)
  }
`;
export const bulkUpsertCollectible = /* GraphQL */ `
  mutation BulkUpsertCollectible($input: BulkUpsertInput!) {
    bulkUpsertCollectible(input: $input)
  }
`;
export const customCreateAccount = /* GraphQL */ `
  mutation CustomCreateAccount($input: CustomCreateAccountInput!) {
    customCreateAccount(input: $input)
  }
`;
export const customUpdateAccount = /* GraphQL */ `
  mutation CustomUpdateAccount($input: CustomUpdateAccountInput!) {
    customUpdateAccount(input: $input)
  }
`;
export const customCreateUser = /* GraphQL */ `
  mutation CustomCreateUser($input: CustomCreateVerifiedProfileInput!) {
    customCreateUser(input: $input)
  }
`;
export const bulkUpsertCreator = /* GraphQL */ `
  mutation BulkUpsertCreator($input: BulkUpsertInput!) {
    bulkUpsertCreator(input: $input)
  }
`;
export const customUpdateVerifiedProfile = /* GraphQL */ `
  mutation CustomUpdateVerifiedProfile($input: CustomUpdateVerifiedProfileInput!) {
    customUpdateVerifiedProfile(input: $input)
  }
`;
export const customCreateCollection = /* GraphQL */ `
  mutation CustomCreateCollection($input: CustomCreateCollectionInput!) {
    customCreateCollection(input: $input)
  }
`;
export const createFollower = /* GraphQL */ `
  mutation CreateFollower($input: CreateFollowerInput!) {
    createFollower(input: $input)
  }
`;
export const deleteFollower = /* GraphQL */ `
  mutation DeleteFollower($input: DeleteFollowerInput!) {
    deleteFollower(input: $input)
  }
`;
export const fixedPricePurchase = /* GraphQL */ `
  mutation FixedPricePurchase($input: FixedPricePurchaseInput!) {
    fixedPricePurchase(input: $input)
  }
`;
export const generateNFTImageCard = /* GraphQL */ `
  mutation GenerateNFTImageCard($input: GenerateNFTImageCardInput!) {
    generateNFTImageCard(input: $input)
  }
`;
export const resizeImage = /* GraphQL */ `
  mutation ResizeImage($input: ResizeImageInput!) {
    resizeImage(input: $input) {
      key
    }
  }
`;
export const removeAvatarImage = /* GraphQL */ `
  mutation RemoveAvatarImage($input: removeUserImageInput!) {
    removeAvatarImage(input: $input)
  }
`;
export const removeCoverImage = /* GraphQL */ `
  mutation RemoveCoverImage($input: removeUserImageInput!) {
    removeCoverImage(input: $input)
  }
`;
export const removeFanclubLogo = /* GraphQL */ `
  mutation RemoveFanclubLogo($input: removeUserImageInput!) {
    removeFanclubLogo(input: $input)
  }
`;
export const removeAvatarVideo = /* GraphQL */ `
  mutation RemoveAvatarVideo($input: removeUserImageInput!) {
    removeAvatarVideo(input: $input)
  }
`;
export const updateEmail = /* GraphQL */ `
  mutation UpdateEmail($input: UpdateEmailInput!) {
    updateEmail(input: $input)
  }
`;
export const updateUsername = /* GraphQL */ `
  mutation UpdateUsername($input: UpdateUsernameInput!) {
    updateUsername(input: $input)
  }
`;
export const verifyEmail = /* GraphQL */ `
  mutation VerifyEmail($input: VerifyEmailInput!) {
    verifyEmail(input: $input)
  }
`;
export const makeOffer = /* GraphQL */ `
  mutation MakeOffer($input: MakeOfferInput!) {
    makeOffer(input: $input)
  }
`;
export const placeBid = /* GraphQL */ `
  mutation PlaceBid($input: PlaceBidInput!) {
    placeBid(input: $input)
  }
`;
export const passwordVerifier = /* GraphQL */ `
  mutation PasswordVerifier($input: PasswordVerifierInput!) {
    passwordVerifier(input: $input)
  }
`;
export const publishCollectible = /* GraphQL */ `
  mutation PublishCollectible($input: PublishCollectibleInput!) {
    publishCollectible(input: $input)
  }
`;
export const requestRevisionCollectible = /* GraphQL */ `
  mutation RequestRevisionCollectible($input: RequestRevisionCollectibleInput!) {
    requestRevisionCollectible(input: $input)
  }
`;
export const inviteMember = /* GraphQL */ `
  mutation InviteMember($input: InviteMemberInput!) {
    inviteMember(input: $input)
  }
`;
export const acceptInvitation = /* GraphQL */ `
  mutation AcceptInvitation($input: AcceptInvitationInput!) {
    acceptInvitation(input: $input)
  }
`;
export const revokeMembership = /* GraphQL */ `
  mutation RevokeMembership($input: RevokeMembershipInput!) {
    revokeMembership(input: $input)
  }
`;
export const cancelMemberInvitation = /* GraphQL */ `
  mutation CancelMemberInvitation($input: CancelMemberInvitationInput!) {
    cancelMemberInvitation(input: $input)
  }
`;
export const removeData = /* GraphQL */ `
  mutation RemoveData($input: RemoveDataInput!) {
    removeData(input: $input)
  }
`;
export const customUpdateListing = /* GraphQL */ `
  mutation CustomUpdateListing($input: UpdateListingInput!) {
    customUpdateListing(input: $input)
  }
`;
export const updateReadNotification = /* GraphQL */ `
  mutation UpdateReadNotification($input: UpdateReadNotificationInput!) {
    updateReadNotification(input: $input)
  }
`;
export const mintNFT = /* GraphQL */ `
  mutation MintNFT($input: MintNFTInput!) {
    mintNFT(input: $input)
  }
`;
export const switchOrganisation = /* GraphQL */ `
  mutation SwitchOrganisation($input: switchOrganisationInput!) {
    switchOrganisation(input: $input)
  }
`;
export const syncWalletAddressWithUser = /* GraphQL */ `
  mutation SyncWalletAddressWithUser($input: SyncWalletAddressWithUserInput!) {
    syncWalletAddressWithUser(input: $input)
  }
`;
export const updateShowNotifications = /* GraphQL */ `
  mutation UpdateShowNotifications($input: UpdateShowNotificationsInput!) {
    updateShowNotifications(input: $input)
  }
`;
export const requestWithdrawal = /* GraphQL */ `
  mutation RequestWithdrawal($input: RequestWithdrawalInput!) {
    requestWithdrawal(input: $input)
  }
`;
export const acceptWithdrawal = /* GraphQL */ `
  mutation AcceptWithdrawal($input: AcceptWithdrawalInput!) {
    acceptWithdrawal(input: $input)
  }
`;
export const rejectWithdrawal = /* GraphQL */ `
  mutation RejectWithdrawal($input: RejectWithdrawalInput!) {
    rejectWithdrawal(input: $input)
  }
`;
export const verifyWithdrawal = /* GraphQL */ `
  mutation VerifyWithdrawal($input: VerifyWithdrawalInput!) {
    verifyWithdrawal(input: $input)
  }
`;
export const requestWithdrawalCode = /* GraphQL */ `
  mutation RequestWithdrawalCode($input: AcceptWithdrawalInput!) {
    requestWithdrawalCode(input: $input)
  }
`;
export const updateAccountUser = /* GraphQL */ `
  mutation UpdateAccountUser($input: UpdateAccountUserInput!) {
    updateAccountUser(input: $input)
  }
`;
export const discourseCreatePost = /* GraphQL */ `
  mutation DiscourseCreatePost($input: DiscoursePostInput!) {
    discourseCreatePost(input: $input)
  }
`;
export const discourseReplyPost = /* GraphQL */ `
  mutation DiscourseReplyPost($input: DiscoursePostInput!) {
    discourseReplyPost(input: $input)
  }
`;
export const discourseCreateChannel = /* GraphQL */ `
  mutation DiscourseCreateChannel($input: DiscourseChannelInput!) {
    discourseCreateChannel(input: $input)
  }
`;
export const discourseDelete = /* GraphQL */ `
  mutation discourseDelete($input: DiscourseDeleteInput!) {
    discourseDelete(input: $input)
  }
`;
export const discourseLikePost = /* GraphQL */ `
  mutation DiscourseLikePost($input: DiscourseActionInput!) {
    discourseLikePost(input: $input)
  }
`;
export const discourseUnlikePost = /* GraphQL */ `
  mutation DiscourseUnlikePost($input: DiscourseActionInput!) {
    discourseUnlikePost(input: $input)
  }
`;
export const updateGalleryExperience = /* GraphQL */ `
  mutation UpdateGalleryExperience($input: UpdateGalleryExperienceInput!) {
    updateGalleryExperience(input: $input)
  }
`;
export const discourseFeaturedPost = /* GraphQL */ `
  mutation DiscourseFeaturedPost($input: DiscourseFeaturedInput!) {
    discourseFeaturedPost(input: $input)
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      userID
      type
      event
      read
      activityID
      lambdaEventID
      lambdaEventName
      createdAt
      updatedAt
      searchableType
      searchableRead
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      userID
      type
      event
      read
      activityID
      lambdaEventID
      lambdaEventName
      createdAt
      updatedAt
      searchableType
      searchableRead
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      userID
      type
      event
      read
      activityID
      lambdaEventID
      lambdaEventName
      createdAt
      updatedAt
      searchableType
      searchableRead
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createNotificationSubscription = /* GraphQL */ `
  mutation CreateNotificationSubscription(
    $input: CreateNotificationSubscriptionInput!
    $condition: ModelNotificationSubscriptionConditionInput
  ) {
    createNotificationSubscription(input: $input, condition: $condition) {
      userID
      type
      inApp
      sms
      email
      push
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateNotificationSubscription = /* GraphQL */ `
  mutation UpdateNotificationSubscription(
    $input: UpdateNotificationSubscriptionInput!
    $condition: ModelNotificationSubscriptionConditionInput
  ) {
    updateNotificationSubscription(input: $input, condition: $condition) {
      userID
      type
      inApp
      sms
      email
      push
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteNotificationSubscription = /* GraphQL */ `
  mutation DeleteNotificationSubscription(
    $input: DeleteNotificationSubscriptionInput!
    $condition: ModelNotificationSubscriptionConditionInput
  ) {
    deleteNotificationSubscription(input: $input, condition: $condition) {
      userID
      type
      inApp
      sms
      email
      push
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createOrderPrint = /* GraphQL */ `
  mutation CreateOrderPrint(
    $input: CreateOrderPrintInput!
    $condition: ModelOrderPrintConditionInput
  ) {
    createOrderPrint(input: $input, condition: $condition) {
      id
      exchangeID
      userID
      checkoutReceiptID
      prodigiResponse
      shipmentMethod
      currencyCode
      shippingProduct {
        sku
        copies
        size
        frame
        attributes
        assets
      }
      shippingDetail {
        fullName
        address
        postalCode
        city
        country
        state
        email
        phone
      }
      price {
        tax
        items
        shipping
        shipment
        platformFee
      }
      totalPrice
      createdAt
      updatedAt
      orderStatus
      paymentStatus
      _version
      _deleted
      _lastChangedAt
      exchange {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        paymentProvider
        bitpayPaymentRequested
        paymentRequestExpiredAt
        paymentReferenceID
        checkoutReceiptID
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        order {
          tax
          platformFee
          royaltyFee
        }
        searchableExchangeType
        searchableIsSold
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      owner
      user {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateOrderPrint = /* GraphQL */ `
  mutation UpdateOrderPrint(
    $input: UpdateOrderPrintInput!
    $condition: ModelOrderPrintConditionInput
  ) {
    updateOrderPrint(input: $input, condition: $condition) {
      id
      exchangeID
      userID
      checkoutReceiptID
      prodigiResponse
      shipmentMethod
      currencyCode
      shippingProduct {
        sku
        copies
        size
        frame
        attributes
        assets
      }
      shippingDetail {
        fullName
        address
        postalCode
        city
        country
        state
        email
        phone
      }
      price {
        tax
        items
        shipping
        shipment
        platformFee
      }
      totalPrice
      createdAt
      updatedAt
      orderStatus
      paymentStatus
      _version
      _deleted
      _lastChangedAt
      exchange {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        paymentProvider
        bitpayPaymentRequested
        paymentRequestExpiredAt
        paymentReferenceID
        checkoutReceiptID
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        order {
          tax
          platformFee
          royaltyFee
        }
        searchableExchangeType
        searchableIsSold
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      owner
      user {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteOrderPrint = /* GraphQL */ `
  mutation DeleteOrderPrint(
    $input: DeleteOrderPrintInput!
    $condition: ModelOrderPrintConditionInput
  ) {
    deleteOrderPrint(input: $input, condition: $condition) {
      id
      exchangeID
      userID
      checkoutReceiptID
      prodigiResponse
      shipmentMethod
      currencyCode
      shippingProduct {
        sku
        copies
        size
        frame
        attributes
        assets
      }
      shippingDetail {
        fullName
        address
        postalCode
        city
        country
        state
        email
        phone
      }
      price {
        tax
        items
        shipping
        shipment
        platformFee
      }
      totalPrice
      createdAt
      updatedAt
      orderStatus
      paymentStatus
      _version
      _deleted
      _lastChangedAt
      exchange {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        paymentProvider
        bitpayPaymentRequested
        paymentRequestExpiredAt
        paymentReferenceID
        checkoutReceiptID
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        order {
          tax
          platformFee
          royaltyFee
        }
        searchableExchangeType
        searchableIsSold
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      owner
      user {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createRelease = /* GraphQL */ `
  mutation CreateRelease($input: CreateReleaseInput!, $condition: ModelReleaseConditionInput) {
    createRelease(input: $input, condition: $condition) {
      id
      collectibleReleaseIndex
      slug
      tierType
      serialIndex
      releaseCount
      releaseDate
      collectibleID
      userID
      previousOwnerID
      previousPrice
      currentPrice
      isMinimumOfferVisible
      isAutoAcceptActive
      finalPrice
      currentListingType
      lastPurchasedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaDescription
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableGenre
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      previousOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateRelease = /* GraphQL */ `
  mutation UpdateRelease($input: UpdateReleaseInput!, $condition: ModelReleaseConditionInput) {
    updateRelease(input: $input, condition: $condition) {
      id
      collectibleReleaseIndex
      slug
      tierType
      serialIndex
      releaseCount
      releaseDate
      collectibleID
      userID
      previousOwnerID
      previousPrice
      currentPrice
      isMinimumOfferVisible
      isAutoAcceptActive
      finalPrice
      currentListingType
      lastPurchasedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaDescription
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableGenre
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      previousOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteRelease = /* GraphQL */ `
  mutation DeleteRelease($input: DeleteReleaseInput!, $condition: ModelReleaseConditionInput) {
    deleteRelease(input: $input, condition: $condition) {
      id
      collectibleReleaseIndex
      slug
      tierType
      serialIndex
      releaseCount
      releaseDate
      collectibleID
      userID
      previousOwnerID
      previousPrice
      currentPrice
      isMinimumOfferVisible
      isAutoAcceptActive
      finalPrice
      currentListingType
      lastPurchasedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaDescription
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableGenre
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      previousOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createUserMedia = /* GraphQL */ `
  mutation CreateUserMedia(
    $input: CreateUserMediaInput!
    $condition: ModelUserMediaConditionInput
  ) {
    createUserMedia(input: $input, condition: $condition) {
      id
      avatarUrl
      avatarVideoUrl
      coverUrl
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUserMedia = /* GraphQL */ `
  mutation UpdateUserMedia(
    $input: UpdateUserMediaInput!
    $condition: ModelUserMediaConditionInput
  ) {
    updateUserMedia(input: $input, condition: $condition) {
      id
      avatarUrl
      avatarVideoUrl
      coverUrl
      fanclubLogoUrl
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUserMedia = /* GraphQL */ `
  mutation DeleteUserMedia(
    $input: DeleteUserMediaInput!
    $condition: ModelUserMediaConditionInput
  ) {
    deleteUserMedia(input: $input, condition: $condition) {
      id
      avatarUrl
      avatarVideoUrl
      coverUrl
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createUserStatistics = /* GraphQL */ `
  mutation CreateUserStatistics(
    $input: CreateUserStatisticsInput!
    $condition: ModelUserStatisticsConditionInput
  ) {
    createUserStatistics(input: $input, condition: $condition) {
      id
      followerCount
      followingCount
      collectibleCreatedCount
      collectibleCollectedCount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUserStatistics = /* GraphQL */ `
  mutation UpdateUserStatistics(
    $input: UpdateUserStatisticsInput!
    $condition: ModelUserStatisticsConditionInput
  ) {
    updateUserStatistics(input: $input, condition: $condition) {
      id
      followerCount
      followingCount
      collectibleCreatedCount
      collectibleCollectedCount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUserStatistics = /* GraphQL */ `
  mutation DeleteUserStatistics(
    $input: DeleteUserStatisticsInput!
    $condition: ModelUserStatisticsConditionInput
  ) {
    deleteUserStatistics(input: $input, condition: $condition) {
      id
      followerCount
      followingCount
      collectibleCreatedCount
      collectibleCollectedCount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!, $condition: ModelUserConditionInput) {
    createUser(input: $input, condition: $condition) {
      id
      owner
      accountID
      editors
      email
      username
      firstName
      lastName
      displayName
      bio
      links {
        facebook
        youtube
        instagram
        twitter
        spotify
        soundcloud
        email
        website
        tiktok
        snapchat
        appleMusic
        clubhouse
      }
      notifications {
        outbid {
          inApp
          email
        }
        auctionExpiration {
          inApp
          email
        }
        offerResponses {
          inApp
          email
        }
        successfulPurchase {
          inApp
          email
        }
        collectibleSold {
          inApp
          email
        }
        offerReceived {
          inApp
          email
        }
        newCollectibles {
          inApp
          email
        }
        collectibleRelease {
          inApp
          email
        }
        fansincNewsletter {
          inApp
          email
        }
      }
      tags
      verified
      fanProfile
      creatorCategory
      emailVerified
      joinDate
      isHidden
      profileTemplate
      address
      country
      state
      city
      postcode
      walletAddresses
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      media {
        id
        avatarUrl
        avatarVideoUrl
        coverUrl
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      statistics {
        id
        followerCount
        followingCount
        collectibleCreatedCount
        collectibleCollectedCount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      managers {
        items {
          id
          profileID
          managerID
          accountID
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          editors
        }
        nextToken
        startedAt
      }
      profileManaged {
        items {
          id
          profileID
          managerID
          accountID
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          editors
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
    updateUser(input: $input, condition: $condition) {
      id
      owner
      accountID
      editors
      email
      username
      firstName
      lastName
      displayName
      bio
      links {
        facebook
        youtube
        instagram
        twitter
        spotify
        soundcloud
        email
        website
        tiktok
        snapchat
        appleMusic
        clubhouse
      }
      notifications {
        outbid {
          inApp
          email
        }
        bidResponses {
          inApp
          email
        }
        offerResponses {
          inApp
          email
        }
        successfulPurchase {
          inApp
          email
        }
        collectibleSold {
          inApp
          email
        }
        collectibleMinted {
          inApp
          email
        }
        collectiblePrinted {
          inApp
          email
        }
        offerReceived {
          inApp
          email
        }
        bidReceived {
          inApp
          email
        }
        newCollectibles {
          inApp
          email
        }
        collectibleRelease {
          inApp
          email
        }
        fansincNewsletter {
          inApp
          email
        }
      }
      tags
      verified
      fanProfile
      creatorCategory
      emailVerified
      joinDate
      isHidden
      profileTemplate
      address {
        recipientName
        line1
        country
        stateName
        city
        postCode
        phone
      }
      shippingAddress {
        recipientName
        line1
        country
        stateName
        city
        postCode
        phone
      }
      walletAddresses
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      media {
        id
        avatarUrl
        avatarVideoUrl
        coverUrl
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      statistics {
        id
        followerCount
        followingCount
        collectibleCreatedCount
        collectibleCollectedCount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      managers {
        items {
          id
          profileID
          managerID
          accountID
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          editors
        }
        nextToken
        startedAt
      }
      profileManaged {
        items {
          id
          profileID
          managerID
          accountID
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          editors
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($input: DeleteUserInput!, $condition: ModelUserConditionInput) {
    deleteUser(input: $input, condition: $condition) {
      id
      owner
      accountID
      editors
      email
      username
      firstName
      lastName
      displayName
      bio
      links {
        facebook
        youtube
        instagram
        twitter
        spotify
        soundcloud
        email
        website
        tiktok
        snapchat
        appleMusic
        clubhouse
      }
      notifications {
        outbid {
          inApp
          email
        }
        auctionExpiration {
          inApp
          email
        }
        offerResponses {
          inApp
          email
        }
        successfulPurchase {
          inApp
          email
        }
        collectibleSold {
          inApp
          email
        }
        offerReceived {
          inApp
          email
        }
        newCollectibles {
          inApp
          email
        }
        collectibleRelease {
          inApp
          email
        }
        fansincNewsletter {
          inApp
          email
        }
      }
      tags
      verified
      fanProfile
      creatorCategory
      emailVerified
      joinDate
      isHidden
      profileTemplate
      address
      country
      state
      city
      postcode
      walletAddresses
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      media {
        id
        avatarUrl
        avatarVideoUrl
        coverUrl
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      statistics {
        id
        followerCount
        followingCount
        collectibleCreatedCount
        collectibleCollectedCount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      managers {
        items {
          id
          profileID
          managerID
          accountID
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          editors
        }
        nextToken
        startedAt
      }
      profileManaged {
        items {
          id
          profileID
          managerID
          accountID
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          editors
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createProfileManager = /* GraphQL */ `
  mutation CreateProfileManager(
    $input: CreateProfileManagerInput!
    $condition: ModelProfileManagerConditionInput
  ) {
    createProfileManager(input: $input, condition: $condition) {
      id
      profileID
      managerID
      accountID
      firstName
      lastName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      manager {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      profile {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      owner
      editors
    }
  }
`;
export const updateProfileManager = /* GraphQL */ `
  mutation UpdateProfileManager(
    $input: UpdateProfileManagerInput!
    $condition: ModelProfileManagerConditionInput
  ) {
    updateProfileManager(input: $input, condition: $condition) {
      id
      profileID
      managerID
      accountID
      firstName
      lastName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      manager {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      profile {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      owner
      editors
    }
  }
`;
export const deleteProfileManager = /* GraphQL */ `
  mutation DeleteProfileManager(
    $input: DeleteProfileManagerInput!
    $condition: ModelProfileManagerConditionInput
  ) {
    deleteProfileManager(input: $input, condition: $condition) {
      id
      profileID
      managerID
      accountID
      firstName
      lastName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      manager {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      profile {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      owner
      editors
    }
  }
`;
export const createWallet = /* GraphQL */ `
  mutation CreateWallet($input: CreateWalletInput!, $condition: ModelWalletConditionInput) {
    createWallet(input: $input, condition: $condition) {
      id
      amount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      walletOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      owner
    }
  }
`;
export const updateWallet = /* GraphQL */ `
  mutation UpdateWallet($input: UpdateWalletInput!, $condition: ModelWalletConditionInput) {
    updateWallet(input: $input, condition: $condition) {
      id
      amount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      walletOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      owner
    }
  }
`;
export const deleteWallet = /* GraphQL */ `
  mutation DeleteWallet($input: DeleteWalletInput!, $condition: ModelWalletConditionInput) {
    deleteWallet(input: $input, condition: $condition) {
      id
      amount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      walletOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      owner
    }
  }
`;
export const createWalletHistory = /* GraphQL */ `
  mutation CreateWalletHistory(
    $input: CreateWalletHistoryInput!
    $condition: ModelWalletHistoryConditionInput
  ) {
    createWalletHistory(input: $input, condition: $condition) {
      id
      senderID
      receiverID
      paymentProvider
      debit
      credit
      invoiceID
      referenceID
      exchangeID
      balance
      transactionType
      status
      description
      withdrawal {
        provider
        destination
        OTP
        OTPVerified
      }
      owner
      createdAt
      updatedAt
      searchableTransactionType
      searchableStatus
      _version
      _deleted
      _lastChangedAt
      sender {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      receiver {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateWalletHistory = /* GraphQL */ `
  mutation UpdateWalletHistory(
    $input: UpdateWalletHistoryInput!
    $condition: ModelWalletHistoryConditionInput
  ) {
    updateWalletHistory(input: $input, condition: $condition) {
      id
      senderID
      receiverID
      paymentProvider
      debit
      credit
      invoiceID
      referenceID
      exchangeID
      balance
      transactionType
      status
      description
      withdrawal {
        provider
        destination
        OTP
        OTPVerified
      }
      owner
      createdAt
      updatedAt
      searchableTransactionType
      searchableStatus
      _version
      _deleted
      _lastChangedAt
      sender {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      receiver {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteWalletHistory = /* GraphQL */ `
  mutation DeleteWalletHistory(
    $input: DeleteWalletHistoryInput!
    $condition: ModelWalletHistoryConditionInput
  ) {
    deleteWalletHistory(input: $input, condition: $condition) {
      id
      senderID
      receiverID
      paymentProvider
      debit
      credit
      invoiceID
      referenceID
      exchangeID
      balance
      transactionType
      status
      description
      withdrawal {
        provider
        destination
        OTP
        OTPVerified
      }
      owner
      createdAt
      updatedAt
      searchableTransactionType
      searchableStatus
      _version
      _deleted
      _lastChangedAt
      sender {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      receiver {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address
        country
        state
        city
        postcode
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address
          country
          state
          city
          postcode
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
