export const listGalleryExperiences = /* GraphQL */ `
  query ListGalleryExperiences(
    $filter: ModelGalleryExperienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGalleryExperiences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        orientation
        heading
        description
        qrPosition
        qrText
        logoPosition
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
