import cx from 'classnames';
import { ReactComponent as DigitalOriginalTier } from '@assets/img/digital_original.svg';
import { ReactComponent as NumberedTier } from '@assets/img/numbered_edition.svg';

const EditionBadge = ({
  release,
  size,
  animate,
  serialIndex,
  className,
  white,
  showEdition,
  ...props
}) => {
  let source = <></>;

  if (release?.tierType === 'DIGITAL_ORIGINAL') {
    source = <DigitalOriginalTier />;
  }
  if (release?.tierType === 'NUMBERED_EDITION') {
    source = <NumberedTier />;
  }

  const defaultStyle = {
    'edition-badge': true,
    'edition-badge--digital': release?.tierType === 'DIGITAL_ORIGINAL',
    'edition-badge--white': white
  };

  const sizeClass = {
    sm: 'edition-badge--small',
    base: 'edition-badge--base',
    md: 'edition-badge--medium'
  };

  const editionStyle = {
    'animate-edition': animate === true
  };

  return (
    <span className={cx(defaultStyle, sizeClass[size], className)} {...props}>
      <div className={cx(editionStyle)}>{source}</div>
      {showEdition === true ? (
        <span className={cx('edition-badge__edition')}>
          {serialIndex ? serialIndex : release?.releaseCount}
        </span>
      ) : (
        <></>
      )}
    </span>
  );
};

EditionBadge.defaultProps = {
  size: 'md',
  showEdition: true,
  animate: false
};

export default EditionBadge;
