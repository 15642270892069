/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onPublishedNotification = /* GraphQL */ `
  subscription OnPublishedNotification($owner: ID!) {
    onPublishedNotification(owner: $owner) {
      id
      owner
      type
      event
      read
    }
  }
`;
export const onCommentaryUpdated = /* GraphQL */ `
  subscription OnCommentaryUpdated {
    onCommentaryUpdated
  }
`;
export const onProcessPaymentV1 = /* GraphQL */ `
  subscription OnProcessPaymentV1 {
    onProcessPaymentV1
  }
`;
export const onNotifUpdatedV1 = /* GraphQL */ `
  subscription OnNotifUpdatedV1 {
    onNotifUpdatedV1
  }
`;
export const onCreateAccount = /* GraphQL */ `
  subscription OnCreateAccount {
    onCreateAccount {
      id
      name
      legalName
      address
      country
      state
      city
      postcode
      phone
      website
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      members {
        items {
          id
          accountID
          userID
          firstName
          lastName
          email
          roleType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onUpdateAccount = /* GraphQL */ `
  subscription OnUpdateAccount {
    onUpdateAccount {
      id
      name
      legalName
      address
      country
      state
      city
      postcode
      phone
      website
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      members {
        items {
          id
          accountID
          userID
          firstName
          lastName
          email
          roleType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onDeleteAccount = /* GraphQL */ `
  subscription OnDeleteAccount {
    onDeleteAccount {
      id
      name
      legalName
      address
      country
      state
      city
      postcode
      phone
      website
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      members {
        items {
          id
          accountID
          userID
          firstName
          lastName
          email
          roleType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onCreateAccountCreator = /* GraphQL */ `
  subscription OnCreateAccountCreator($owner: String) {
    onCreateAccountCreator(owner: $owner) {
      id
      accountID
      userID
      firstName
      lastName
      email
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      account {
        id
        name
        legalName
        address
        country
        state
        city
        postcode
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        members {
          nextToken
          startedAt
        }
      }
      owner
      user {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onUpdateAccountCreator = /* GraphQL */ `
  subscription OnUpdateAccountCreator($owner: String) {
    onUpdateAccountCreator(owner: $owner) {
      id
      accountID
      userID
      firstName
      lastName
      email
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      account {
        id
        name
        legalName
        address
        country
        state
        city
        postcode
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        members {
          nextToken
          startedAt
        }
      }
      owner
      user {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onDeleteAccountCreator = /* GraphQL */ `
  subscription OnDeleteAccountCreator($owner: String) {
    onDeleteAccountCreator(owner: $owner) {
      id
      accountID
      userID
      firstName
      lastName
      email
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      account {
        id
        name
        legalName
        address
        country
        state
        city
        postcode
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        members {
          nextToken
          startedAt
        }
      }
      owner
      user {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onCreateActivity = /* GraphQL */ `
  subscription OnCreateActivity {
    onCreateActivity {
      id
      userID
      type
      event
      lambdaEventID
      lambdaEventName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateActivity = /* GraphQL */ `
  subscription OnUpdateActivity {
    onUpdateActivity {
      id
      userID
      type
      event
      lambdaEventID
      lambdaEventName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteActivity = /* GraphQL */ `
  subscription OnDeleteActivity {
    onDeleteActivity {
      id
      userID
      type
      event
      lambdaEventID
      lambdaEventName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateFeaturedArtist = /* GraphQL */ `
  subscription OnCreateFeaturedArtist {
    onCreateFeaturedArtist {
      id
      artistName
      collectibleCount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateFeaturedArtist = /* GraphQL */ `
  subscription OnUpdateFeaturedArtist {
    onUpdateFeaturedArtist {
      id
      artistName
      collectibleCount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteFeaturedArtist = /* GraphQL */ `
  subscription OnDeleteFeaturedArtist {
    onDeleteFeaturedArtist {
      id
      artistName
      collectibleCount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateFeaturedArtistCollectible = /* GraphQL */ `
  subscription OnCreateFeaturedArtistCollectible {
    onCreateFeaturedArtistCollectible {
      id
      collectibleID
      featuredArtistID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        streamExternal
        streamUrl {
          spotify
          appleMusic
          youtube
        }
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaTitle
        metaDescription
        perksOriginal {
          fanClubAccess
          limitedPerks
          lifetimePerks
        }
        perksNumbered {
          fanClubAccess
          limitedPerks
          lifetimePerks
        }
        history {
          status
          value
          date
        }
        lastSoldAt
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableHighestBidPrice
        searchableGenre
        searchableFeaturedArtist
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      featuredArtist {
        id
        artistName
        collectibleCount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const onUpdateFeaturedArtistCollectible = /* GraphQL */ `
  subscription OnUpdateFeaturedArtistCollectible {
    onUpdateFeaturedArtistCollectible {
      id
      collectibleID
      featuredArtistID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        streamExternal
        streamUrl {
          spotify
          appleMusic
          youtube
        }
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaTitle
        metaDescription
        perksOriginal {
          fanClubAccess
          limitedPerks
          lifetimePerks
        }
        perksNumbered {
          fanClubAccess
          limitedPerks
          lifetimePerks
        }
        history {
          status
          value
          date
        }
        lastSoldAt
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableHighestBidPrice
        searchableGenre
        searchableFeaturedArtist
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      featuredArtist {
        id
        artistName
        collectibleCount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const onDeleteFeaturedArtistCollectible = /* GraphQL */ `
  subscription OnDeleteFeaturedArtistCollectible {
    onDeleteFeaturedArtistCollectible {
      id
      collectibleID
      featuredArtistID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        streamExternal
        streamUrl {
          spotify
          appleMusic
          youtube
        }
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaTitle
        metaDescription
        perksOriginal {
          fanClubAccess
          limitedPerks
          lifetimePerks
        }
        perksNumbered {
          fanClubAccess
          limitedPerks
          lifetimePerks
        }
        history {
          status
          value
          date
        }
        lastSoldAt
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableHighestBidPrice
        searchableGenre
        searchableFeaturedArtist
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      featuredArtist {
        id
        artistName
        collectibleCount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const onCreateCollectibleLike = /* GraphQL */ `
  subscription OnCreateCollectibleLike {
    onCreateCollectibleLike {
      id
      userID
      collectibleID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        streamExternal
        streamUrl {
          spotify
          appleMusic
          youtube
        }
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaTitle
        metaDescription
        perksOriginal {
          fanClubAccess
          limitedPerks
          lifetimePerks
        }
        perksNumbered {
          fanClubAccess
          limitedPerks
          lifetimePerks
        }
        history {
          status
          value
          date
        }
        lastSoldAt
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableHighestBidPrice
        searchableGenre
        searchableFeaturedArtist
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      owner
      user {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onUpdateCollectibleLike = /* GraphQL */ `
  subscription OnUpdateCollectibleLike {
    onUpdateCollectibleLike {
      id
      userID
      collectibleID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        streamExternal
        streamUrl {
          spotify
          appleMusic
          youtube
        }
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaTitle
        metaDescription
        perksOriginal {
          fanClubAccess
          limitedPerks
          lifetimePerks
        }
        perksNumbered {
          fanClubAccess
          limitedPerks
          lifetimePerks
        }
        history {
          status
          value
          date
        }
        lastSoldAt
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableHighestBidPrice
        searchableGenre
        searchableFeaturedArtist
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      owner
      user {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onDeleteCollectibleLike = /* GraphQL */ `
  subscription OnDeleteCollectibleLike {
    onDeleteCollectibleLike {
      id
      userID
      collectibleID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        streamExternal
        streamUrl {
          spotify
          appleMusic
          youtube
        }
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaTitle
        metaDescription
        perksOriginal {
          fanClubAccess
          limitedPerks
          lifetimePerks
        }
        perksNumbered {
          fanClubAccess
          limitedPerks
          lifetimePerks
        }
        history {
          status
          value
          date
        }
        lastSoldAt
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableHighestBidPrice
        searchableGenre
        searchableFeaturedArtist
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      owner
      user {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onCreateCollection = /* GraphQL */ `
  subscription OnCreateCollection {
    onCreateCollection {
      id
      userID
      owner
      accountID
      name
      slug
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      collectibles {
        items {
          id
          collectionID
          collectibleID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      currentUser {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onUpdateCollection = /* GraphQL */ `
  subscription OnUpdateCollection {
    onUpdateCollection {
      id
      userID
      owner
      accountID
      name
      slug
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      collectibles {
        items {
          id
          collectionID
          collectibleID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      currentUser {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onDeleteCollection = /* GraphQL */ `
  subscription OnDeleteCollection {
    onDeleteCollection {
      id
      userID
      owner
      accountID
      name
      slug
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      collectibles {
        items {
          id
          collectionID
          collectibleID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      currentUser {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onCreateCollectionCollectible = /* GraphQL */ `
  subscription OnCreateCollectionCollectible($owner: String) {
    onCreateCollectionCollectible(owner: $owner) {
      id
      collectionID
      collectibleID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        streamExternal
        streamUrl {
          spotify
          appleMusic
          youtube
        }
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaTitle
        metaDescription
        perksOriginal {
          fanClubAccess
          limitedPerks
          lifetimePerks
        }
        perksNumbered {
          fanClubAccess
          limitedPerks
          lifetimePerks
        }
        history {
          status
          value
          date
        }
        lastSoldAt
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableHighestBidPrice
        searchableGenre
        searchableFeaturedArtist
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      collection {
        id
        userID
        owner
        accountID
        name
        slug
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        collectibles {
          nextToken
          startedAt
        }
        currentUser {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      owner
    }
  }
`;
export const onUpdateCollectionCollectible = /* GraphQL */ `
  subscription OnUpdateCollectionCollectible($owner: String) {
    onUpdateCollectionCollectible(owner: $owner) {
      id
      collectionID
      collectibleID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        streamExternal
        streamUrl {
          spotify
          appleMusic
          youtube
        }
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaTitle
        metaDescription
        perksOriginal {
          fanClubAccess
          limitedPerks
          lifetimePerks
        }
        perksNumbered {
          fanClubAccess
          limitedPerks
          lifetimePerks
        }
        history {
          status
          value
          date
        }
        lastSoldAt
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableHighestBidPrice
        searchableGenre
        searchableFeaturedArtist
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      collection {
        id
        userID
        owner
        accountID
        name
        slug
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        collectibles {
          nextToken
          startedAt
        }
        currentUser {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      owner
    }
  }
`;
export const onDeleteCollectionCollectible = /* GraphQL */ `
  subscription OnDeleteCollectionCollectible($owner: String) {
    onDeleteCollectionCollectible(owner: $owner) {
      id
      collectionID
      collectibleID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        streamExternal
        streamUrl {
          spotify
          appleMusic
          youtube
        }
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaTitle
        metaDescription
        perksOriginal {
          fanClubAccess
          limitedPerks
          lifetimePerks
        }
        perksNumbered {
          fanClubAccess
          limitedPerks
          lifetimePerks
        }
        history {
          status
          value
          date
        }
        lastSoldAt
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableHighestBidPrice
        searchableGenre
        searchableFeaturedArtist
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      collection {
        id
        userID
        owner
        accountID
        name
        slug
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        collectibles {
          nextToken
          startedAt
        }
        currentUser {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      owner
    }
  }
`;
export const onCreateDiscourseChannel = /* GraphQL */ `
  subscription OnCreateDiscourseChannel {
    onCreateDiscourseChannel {
      id
      name
      owner
      default
      members {
        collectibleID
        serialIndex
        userID
      }
      collectibles
      lastActivityAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateDiscourseChannel = /* GraphQL */ `
  subscription OnUpdateDiscourseChannel {
    onUpdateDiscourseChannel {
      id
      name
      owner
      default
      members {
        collectibleID
        serialIndex
        userID
      }
      collectibles
      lastActivityAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteDiscourseChannel = /* GraphQL */ `
  subscription OnDeleteDiscourseChannel {
    onDeleteDiscourseChannel {
      id
      name
      owner
      default
      members {
        collectibleID
        serialIndex
        userID
      }
      collectibles
      lastActivityAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateExchange = /* GraphQL */ `
  subscription OnCreateExchange($owner: String) {
    onCreateExchange(owner: $owner) {
      id
      exchangeType
      creatorID
      currentOwnerID
      collectibleID
      collectibleReleaseIndex
      releaseID
      serialIndex
      listingType
      releaseDate
      isReleased
      sellingPrice
      offerPrice
      startingPrice
      lastPrice
      auctionMinimumAllowedBidPrice
      isMinimumOfferVisible
      isAutoAcceptActive
      bidPrice
      maxBidPrice
      expiryDate
      isSold
      status
      patronID
      fiatCurrencyCode
      paymentReferenceID
      paymentReferenceIDInProcess
      paymentProvider
      paymentProviderInProcess
      bitpayPaymentRequested
      paymentRequestExpiredAt
      checkoutReceiptID
      checkoutReceiptIDInProcess
      createdAt
      updatedAt
      nftTransactionHash
      nftOwnerAddress
      nftContractAddress
      nftTokenID
      nftTokenIDString
      nftERC20PaymentTokenAddress
      nftERC20PaymentTokenSymbol
      nftERC20PaymentTokenDecimals
      nftERC20PaymentAmount
      nftERC20FeeTokenAddress
      nftERC20FeeTokenSymbol
      nftERC20FeeTokenDecimals
      nftERC20FeeAmount
      userID
      currentListingType
      lastPurchasedAt
      slug
      tierType
      releaseCount
      previousOwnerID
      previousPrice
      finalPrice
      currentPrice
      previousExchangeID
      parentExchangeID
      bitpayReceiptID
      order {
        tax
        platformFee
        salesFee
        royalty
        royaltyFee
        invoiceID
        checkoutReceiptID
        bitpayReceiptID
      }
      print {
        FRA_BOX_LPP_MOUNT1_GLA_60X80 {
          url
          cost
        }
        FRA_BOX_HGE_MOUNT2_GLA_A2 {
          url
          cost
        }
      }
      searchableExchangeType
      searchableIsSold
      searchableStatus
      searchableHighestPrice
      searchableTitle
      searchableCategory
      searchableGenre
      searchableYearOfCreation
      searchableFeaturedArtists
      _version
      _deleted
      _lastChangedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        streamExternal
        streamUrl {
          spotify
          appleMusic
          youtube
        }
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaTitle
        metaDescription
        perksOriginal {
          fanClubAccess
          limitedPerks
          lifetimePerks
        }
        perksNumbered {
          fanClubAccess
          limitedPerks
          lifetimePerks
        }
        history {
          status
          value
          date
        }
        lastSoldAt
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableHighestBidPrice
        searchableGenre
        searchableFeaturedArtist
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      owner
      previousExchange {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        fiatCurrencyCode
        paymentReferenceID
        paymentReferenceIDInProcess
        paymentProvider
        paymentProviderInProcess
        bitpayPaymentRequested
        paymentRequestExpiredAt
        checkoutReceiptID
        checkoutReceiptIDInProcess
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        nftTokenIDString
        nftERC20PaymentTokenAddress
        nftERC20PaymentTokenSymbol
        nftERC20PaymentTokenDecimals
        nftERC20PaymentAmount
        nftERC20FeeTokenAddress
        nftERC20FeeTokenSymbol
        nftERC20FeeTokenDecimals
        nftERC20FeeAmount
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        order {
          tax
          platformFee
          salesFee
          royalty
          royaltyFee
          invoiceID
          checkoutReceiptID
          bitpayReceiptID
        }
        searchableExchangeType
        searchableIsSold
        searchableStatus
        searchableHighestPrice
        searchableTitle
        searchableCategory
        searchableGenre
        searchableYearOfCreation
        searchableFeaturedArtists
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          streamExternal
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaTitle
          metaDescription
          lastSoldAt
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableHighestBidPrice
          searchableGenre
          searchableFeaturedArtist
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          fiatCurrencyCode
          paymentReferenceID
          paymentReferenceIDInProcess
          paymentProvider
          paymentProviderInProcess
          bitpayPaymentRequested
          paymentRequestExpiredAt
          checkoutReceiptID
          checkoutReceiptIDInProcess
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          nftTokenIDString
          nftERC20PaymentTokenAddress
          nftERC20PaymentTokenSymbol
          nftERC20PaymentTokenDecimals
          nftERC20PaymentAmount
          nftERC20FeeTokenAddress
          nftERC20FeeTokenSymbol
          nftERC20FeeTokenDecimals
          nftERC20FeeAmount
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          searchableStatus
          searchableHighestPrice
          searchableTitle
          searchableCategory
          searchableGenre
          searchableYearOfCreation
          searchableFeaturedArtists
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          fiatCurrencyCode
          paymentReferenceID
          paymentReferenceIDInProcess
          paymentProvider
          paymentProviderInProcess
          bitpayPaymentRequested
          paymentRequestExpiredAt
          checkoutReceiptID
          checkoutReceiptIDInProcess
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          nftTokenIDString
          nftERC20PaymentTokenAddress
          nftERC20PaymentTokenSymbol
          nftERC20PaymentTokenDecimals
          nftERC20PaymentAmount
          nftERC20FeeTokenAddress
          nftERC20FeeTokenSymbol
          nftERC20FeeTokenDecimals
          nftERC20FeeAmount
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          searchableStatus
          searchableHighestPrice
          searchableTitle
          searchableCategory
          searchableGenre
          searchableYearOfCreation
          searchableFeaturedArtists
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      parentExchange {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        fiatCurrencyCode
        paymentReferenceID
        paymentReferenceIDInProcess
        paymentProvider
        paymentProviderInProcess
        bitpayPaymentRequested
        paymentRequestExpiredAt
        checkoutReceiptID
        checkoutReceiptIDInProcess
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        nftTokenIDString
        nftERC20PaymentTokenAddress
        nftERC20PaymentTokenSymbol
        nftERC20PaymentTokenDecimals
        nftERC20PaymentAmount
        nftERC20FeeTokenAddress
        nftERC20FeeTokenSymbol
        nftERC20FeeTokenDecimals
        nftERC20FeeAmount
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        order {
          tax
          platformFee
          salesFee
          royalty
          royaltyFee
          invoiceID
          checkoutReceiptID
          bitpayReceiptID
        }
        searchableExchangeType
        searchableIsSold
        searchableStatus
        searchableHighestPrice
        searchableTitle
        searchableCategory
        searchableGenre
        searchableYearOfCreation
        searchableFeaturedArtists
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          streamExternal
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaTitle
          metaDescription
          lastSoldAt
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableHighestBidPrice
          searchableGenre
          searchableFeaturedArtist
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          fiatCurrencyCode
          paymentReferenceID
          paymentReferenceIDInProcess
          paymentProvider
          paymentProviderInProcess
          bitpayPaymentRequested
          paymentRequestExpiredAt
          checkoutReceiptID
          checkoutReceiptIDInProcess
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          nftTokenIDString
          nftERC20PaymentTokenAddress
          nftERC20PaymentTokenSymbol
          nftERC20PaymentTokenDecimals
          nftERC20PaymentAmount
          nftERC20FeeTokenAddress
          nftERC20FeeTokenSymbol
          nftERC20FeeTokenDecimals
          nftERC20FeeAmount
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          searchableStatus
          searchableHighestPrice
          searchableTitle
          searchableCategory
          searchableGenre
          searchableYearOfCreation
          searchableFeaturedArtists
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          fiatCurrencyCode
          paymentReferenceID
          paymentReferenceIDInProcess
          paymentProvider
          paymentProviderInProcess
          bitpayPaymentRequested
          paymentRequestExpiredAt
          checkoutReceiptID
          checkoutReceiptIDInProcess
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          nftTokenIDString
          nftERC20PaymentTokenAddress
          nftERC20PaymentTokenSymbol
          nftERC20PaymentTokenDecimals
          nftERC20PaymentAmount
          nftERC20FeeTokenAddress
          nftERC20FeeTokenSymbol
          nftERC20FeeTokenDecimals
          nftERC20FeeAmount
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          searchableStatus
          searchableHighestPrice
          searchableTitle
          searchableCategory
          searchableGenre
          searchableYearOfCreation
          searchableFeaturedArtists
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      release {
        id
        collectibleReleaseIndex
        slug
        tierType
        serialIndex
        releaseCount
        releaseDate
        collectibleID
        userID
        previousOwnerID
        previousPrice
        currentPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        finalPrice
        currentListingType
        lastPurchasedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          streamExternal
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaTitle
          metaDescription
          lastSoldAt
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableHighestBidPrice
          searchableGenre
          searchableFeaturedArtist
          _version
          _deleted
          _lastChangedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      creator {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      patron {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      previousOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onUpdateExchange = /* GraphQL */ `
  subscription OnUpdateExchange($owner: String) {
    onUpdateExchange(owner: $owner) {
      id
      exchangeType
      creatorID
      currentOwnerID
      collectibleID
      collectibleReleaseIndex
      releaseID
      serialIndex
      listingType
      releaseDate
      isReleased
      sellingPrice
      offerPrice
      startingPrice
      lastPrice
      auctionMinimumAllowedBidPrice
      isMinimumOfferVisible
      isAutoAcceptActive
      bidPrice
      maxBidPrice
      expiryDate
      isSold
      status
      patronID
      fiatCurrencyCode
      paymentReferenceID
      paymentReferenceIDInProcess
      paymentProvider
      paymentProviderInProcess
      bitpayPaymentRequested
      paymentRequestExpiredAt
      checkoutReceiptID
      checkoutReceiptIDInProcess
      createdAt
      updatedAt
      nftTransactionHash
      nftOwnerAddress
      nftContractAddress
      nftTokenID
      nftTokenIDString
      nftERC20PaymentTokenAddress
      nftERC20PaymentTokenSymbol
      nftERC20PaymentTokenDecimals
      nftERC20PaymentAmount
      nftERC20FeeTokenAddress
      nftERC20FeeTokenSymbol
      nftERC20FeeTokenDecimals
      nftERC20FeeAmount
      userID
      currentListingType
      lastPurchasedAt
      slug
      tierType
      releaseCount
      previousOwnerID
      previousPrice
      finalPrice
      currentPrice
      previousExchangeID
      parentExchangeID
      bitpayReceiptID
      order {
        tax
        platformFee
        salesFee
        royalty
        royaltyFee
        invoiceID
        checkoutReceiptID
        bitpayReceiptID
      }
      print {
        FRA_BOX_LPP_MOUNT1_GLA_60X80 {
          url
          cost
        }
        FRA_BOX_HGE_MOUNT2_GLA_A2 {
          url
          cost
        }
      }
      searchableExchangeType
      searchableIsSold
      searchableStatus
      searchableHighestPrice
      searchableTitle
      searchableCategory
      searchableGenre
      searchableYearOfCreation
      searchableFeaturedArtists
      _version
      _deleted
      _lastChangedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        streamExternal
        streamUrl {
          spotify
          appleMusic
          youtube
        }
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaTitle
        metaDescription
        perksOriginal {
          fanClubAccess
          limitedPerks
          lifetimePerks
        }
        perksNumbered {
          fanClubAccess
          limitedPerks
          lifetimePerks
        }
        history {
          status
          value
          date
        }
        lastSoldAt
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableHighestBidPrice
        searchableGenre
        searchableFeaturedArtist
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      owner
      previousExchange {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        fiatCurrencyCode
        paymentReferenceID
        paymentReferenceIDInProcess
        paymentProvider
        paymentProviderInProcess
        bitpayPaymentRequested
        paymentRequestExpiredAt
        checkoutReceiptID
        checkoutReceiptIDInProcess
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        nftTokenIDString
        nftERC20PaymentTokenAddress
        nftERC20PaymentTokenSymbol
        nftERC20PaymentTokenDecimals
        nftERC20PaymentAmount
        nftERC20FeeTokenAddress
        nftERC20FeeTokenSymbol
        nftERC20FeeTokenDecimals
        nftERC20FeeAmount
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        order {
          tax
          platformFee
          salesFee
          royalty
          royaltyFee
          invoiceID
          checkoutReceiptID
          bitpayReceiptID
        }
        searchableExchangeType
        searchableIsSold
        searchableStatus
        searchableHighestPrice
        searchableTitle
        searchableCategory
        searchableGenre
        searchableYearOfCreation
        searchableFeaturedArtists
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          streamExternal
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaTitle
          metaDescription
          lastSoldAt
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableHighestBidPrice
          searchableGenre
          searchableFeaturedArtist
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          fiatCurrencyCode
          paymentReferenceID
          paymentReferenceIDInProcess
          paymentProvider
          paymentProviderInProcess
          bitpayPaymentRequested
          paymentRequestExpiredAt
          checkoutReceiptID
          checkoutReceiptIDInProcess
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          nftTokenIDString
          nftERC20PaymentTokenAddress
          nftERC20PaymentTokenSymbol
          nftERC20PaymentTokenDecimals
          nftERC20PaymentAmount
          nftERC20FeeTokenAddress
          nftERC20FeeTokenSymbol
          nftERC20FeeTokenDecimals
          nftERC20FeeAmount
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          searchableStatus
          searchableHighestPrice
          searchableTitle
          searchableCategory
          searchableGenre
          searchableYearOfCreation
          searchableFeaturedArtists
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          fiatCurrencyCode
          paymentReferenceID
          paymentReferenceIDInProcess
          paymentProvider
          paymentProviderInProcess
          bitpayPaymentRequested
          paymentRequestExpiredAt
          checkoutReceiptID
          checkoutReceiptIDInProcess
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          nftTokenIDString
          nftERC20PaymentTokenAddress
          nftERC20PaymentTokenSymbol
          nftERC20PaymentTokenDecimals
          nftERC20PaymentAmount
          nftERC20FeeTokenAddress
          nftERC20FeeTokenSymbol
          nftERC20FeeTokenDecimals
          nftERC20FeeAmount
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          searchableStatus
          searchableHighestPrice
          searchableTitle
          searchableCategory
          searchableGenre
          searchableYearOfCreation
          searchableFeaturedArtists
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      parentExchange {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        fiatCurrencyCode
        paymentReferenceID
        paymentReferenceIDInProcess
        paymentProvider
        paymentProviderInProcess
        bitpayPaymentRequested
        paymentRequestExpiredAt
        checkoutReceiptID
        checkoutReceiptIDInProcess
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        nftTokenIDString
        nftERC20PaymentTokenAddress
        nftERC20PaymentTokenSymbol
        nftERC20PaymentTokenDecimals
        nftERC20PaymentAmount
        nftERC20FeeTokenAddress
        nftERC20FeeTokenSymbol
        nftERC20FeeTokenDecimals
        nftERC20FeeAmount
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        order {
          tax
          platformFee
          salesFee
          royalty
          royaltyFee
          invoiceID
          checkoutReceiptID
          bitpayReceiptID
        }
        searchableExchangeType
        searchableIsSold
        searchableStatus
        searchableHighestPrice
        searchableTitle
        searchableCategory
        searchableGenre
        searchableYearOfCreation
        searchableFeaturedArtists
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          streamExternal
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaTitle
          metaDescription
          lastSoldAt
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableHighestBidPrice
          searchableGenre
          searchableFeaturedArtist
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          fiatCurrencyCode
          paymentReferenceID
          paymentReferenceIDInProcess
          paymentProvider
          paymentProviderInProcess
          bitpayPaymentRequested
          paymentRequestExpiredAt
          checkoutReceiptID
          checkoutReceiptIDInProcess
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          nftTokenIDString
          nftERC20PaymentTokenAddress
          nftERC20PaymentTokenSymbol
          nftERC20PaymentTokenDecimals
          nftERC20PaymentAmount
          nftERC20FeeTokenAddress
          nftERC20FeeTokenSymbol
          nftERC20FeeTokenDecimals
          nftERC20FeeAmount
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          searchableStatus
          searchableHighestPrice
          searchableTitle
          searchableCategory
          searchableGenre
          searchableYearOfCreation
          searchableFeaturedArtists
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          fiatCurrencyCode
          paymentReferenceID
          paymentReferenceIDInProcess
          paymentProvider
          paymentProviderInProcess
          bitpayPaymentRequested
          paymentRequestExpiredAt
          checkoutReceiptID
          checkoutReceiptIDInProcess
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          nftTokenIDString
          nftERC20PaymentTokenAddress
          nftERC20PaymentTokenSymbol
          nftERC20PaymentTokenDecimals
          nftERC20PaymentAmount
          nftERC20FeeTokenAddress
          nftERC20FeeTokenSymbol
          nftERC20FeeTokenDecimals
          nftERC20FeeAmount
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          searchableStatus
          searchableHighestPrice
          searchableTitle
          searchableCategory
          searchableGenre
          searchableYearOfCreation
          searchableFeaturedArtists
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      release {
        id
        collectibleReleaseIndex
        slug
        tierType
        serialIndex
        releaseCount
        releaseDate
        collectibleID
        userID
        previousOwnerID
        previousPrice
        currentPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        finalPrice
        currentListingType
        lastPurchasedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          streamExternal
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaTitle
          metaDescription
          lastSoldAt
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableHighestBidPrice
          searchableGenre
          searchableFeaturedArtist
          _version
          _deleted
          _lastChangedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      creator {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      patron {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      previousOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onDeleteExchange = /* GraphQL */ `
  subscription OnDeleteExchange($owner: String) {
    onDeleteExchange(owner: $owner) {
      id
      exchangeType
      creatorID
      currentOwnerID
      collectibleID
      collectibleReleaseIndex
      releaseID
      serialIndex
      listingType
      releaseDate
      isReleased
      sellingPrice
      offerPrice
      startingPrice
      lastPrice
      auctionMinimumAllowedBidPrice
      isMinimumOfferVisible
      isAutoAcceptActive
      bidPrice
      maxBidPrice
      expiryDate
      isSold
      status
      patronID
      fiatCurrencyCode
      paymentReferenceID
      paymentReferenceIDInProcess
      paymentProvider
      paymentProviderInProcess
      bitpayPaymentRequested
      paymentRequestExpiredAt
      checkoutReceiptID
      checkoutReceiptIDInProcess
      createdAt
      updatedAt
      nftTransactionHash
      nftOwnerAddress
      nftContractAddress
      nftTokenID
      nftTokenIDString
      nftERC20PaymentTokenAddress
      nftERC20PaymentTokenSymbol
      nftERC20PaymentTokenDecimals
      nftERC20PaymentAmount
      nftERC20FeeTokenAddress
      nftERC20FeeTokenSymbol
      nftERC20FeeTokenDecimals
      nftERC20FeeAmount
      userID
      currentListingType
      lastPurchasedAt
      slug
      tierType
      releaseCount
      previousOwnerID
      previousPrice
      finalPrice
      currentPrice
      previousExchangeID
      parentExchangeID
      bitpayReceiptID
      order {
        tax
        platformFee
        salesFee
        royalty
        royaltyFee
        invoiceID
        checkoutReceiptID
        bitpayReceiptID
      }
      print {
        FRA_BOX_LPP_MOUNT1_GLA_60X80 {
          url
          cost
        }
        FRA_BOX_HGE_MOUNT2_GLA_A2 {
          url
          cost
        }
      }
      searchableExchangeType
      searchableIsSold
      searchableStatus
      searchableHighestPrice
      searchableTitle
      searchableCategory
      searchableGenre
      searchableYearOfCreation
      searchableFeaturedArtists
      _version
      _deleted
      _lastChangedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        streamExternal
        streamUrl {
          spotify
          appleMusic
          youtube
        }
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaTitle
        metaDescription
        perksOriginal {
          fanClubAccess
          limitedPerks
          lifetimePerks
        }
        perksNumbered {
          fanClubAccess
          limitedPerks
          lifetimePerks
        }
        history {
          status
          value
          date
        }
        lastSoldAt
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableHighestBidPrice
        searchableGenre
        searchableFeaturedArtist
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      owner
      previousExchange {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        fiatCurrencyCode
        paymentReferenceID
        paymentReferenceIDInProcess
        paymentProvider
        paymentProviderInProcess
        bitpayPaymentRequested
        paymentRequestExpiredAt
        checkoutReceiptID
        checkoutReceiptIDInProcess
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        nftTokenIDString
        nftERC20PaymentTokenAddress
        nftERC20PaymentTokenSymbol
        nftERC20PaymentTokenDecimals
        nftERC20PaymentAmount
        nftERC20FeeTokenAddress
        nftERC20FeeTokenSymbol
        nftERC20FeeTokenDecimals
        nftERC20FeeAmount
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        order {
          tax
          platformFee
          salesFee
          royalty
          royaltyFee
          invoiceID
          checkoutReceiptID
          bitpayReceiptID
        }
        searchableExchangeType
        searchableIsSold
        searchableStatus
        searchableHighestPrice
        searchableTitle
        searchableCategory
        searchableGenre
        searchableYearOfCreation
        searchableFeaturedArtists
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          streamExternal
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaTitle
          metaDescription
          lastSoldAt
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableHighestBidPrice
          searchableGenre
          searchableFeaturedArtist
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          fiatCurrencyCode
          paymentReferenceID
          paymentReferenceIDInProcess
          paymentProvider
          paymentProviderInProcess
          bitpayPaymentRequested
          paymentRequestExpiredAt
          checkoutReceiptID
          checkoutReceiptIDInProcess
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          nftTokenIDString
          nftERC20PaymentTokenAddress
          nftERC20PaymentTokenSymbol
          nftERC20PaymentTokenDecimals
          nftERC20PaymentAmount
          nftERC20FeeTokenAddress
          nftERC20FeeTokenSymbol
          nftERC20FeeTokenDecimals
          nftERC20FeeAmount
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          searchableStatus
          searchableHighestPrice
          searchableTitle
          searchableCategory
          searchableGenre
          searchableYearOfCreation
          searchableFeaturedArtists
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          fiatCurrencyCode
          paymentReferenceID
          paymentReferenceIDInProcess
          paymentProvider
          paymentProviderInProcess
          bitpayPaymentRequested
          paymentRequestExpiredAt
          checkoutReceiptID
          checkoutReceiptIDInProcess
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          nftTokenIDString
          nftERC20PaymentTokenAddress
          nftERC20PaymentTokenSymbol
          nftERC20PaymentTokenDecimals
          nftERC20PaymentAmount
          nftERC20FeeTokenAddress
          nftERC20FeeTokenSymbol
          nftERC20FeeTokenDecimals
          nftERC20FeeAmount
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          searchableStatus
          searchableHighestPrice
          searchableTitle
          searchableCategory
          searchableGenre
          searchableYearOfCreation
          searchableFeaturedArtists
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      parentExchange {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        fiatCurrencyCode
        paymentReferenceID
        paymentReferenceIDInProcess
        paymentProvider
        paymentProviderInProcess
        bitpayPaymentRequested
        paymentRequestExpiredAt
        checkoutReceiptID
        checkoutReceiptIDInProcess
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        nftTokenIDString
        nftERC20PaymentTokenAddress
        nftERC20PaymentTokenSymbol
        nftERC20PaymentTokenDecimals
        nftERC20PaymentAmount
        nftERC20FeeTokenAddress
        nftERC20FeeTokenSymbol
        nftERC20FeeTokenDecimals
        nftERC20FeeAmount
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        order {
          tax
          platformFee
          salesFee
          royalty
          royaltyFee
          invoiceID
          checkoutReceiptID
          bitpayReceiptID
        }
        searchableExchangeType
        searchableIsSold
        searchableStatus
        searchableHighestPrice
        searchableTitle
        searchableCategory
        searchableGenre
        searchableYearOfCreation
        searchableFeaturedArtists
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          streamExternal
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaTitle
          metaDescription
          lastSoldAt
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableHighestBidPrice
          searchableGenre
          searchableFeaturedArtist
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          fiatCurrencyCode
          paymentReferenceID
          paymentReferenceIDInProcess
          paymentProvider
          paymentProviderInProcess
          bitpayPaymentRequested
          paymentRequestExpiredAt
          checkoutReceiptID
          checkoutReceiptIDInProcess
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          nftTokenIDString
          nftERC20PaymentTokenAddress
          nftERC20PaymentTokenSymbol
          nftERC20PaymentTokenDecimals
          nftERC20PaymentAmount
          nftERC20FeeTokenAddress
          nftERC20FeeTokenSymbol
          nftERC20FeeTokenDecimals
          nftERC20FeeAmount
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          searchableStatus
          searchableHighestPrice
          searchableTitle
          searchableCategory
          searchableGenre
          searchableYearOfCreation
          searchableFeaturedArtists
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          fiatCurrencyCode
          paymentReferenceID
          paymentReferenceIDInProcess
          paymentProvider
          paymentProviderInProcess
          bitpayPaymentRequested
          paymentRequestExpiredAt
          checkoutReceiptID
          checkoutReceiptIDInProcess
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          nftTokenIDString
          nftERC20PaymentTokenAddress
          nftERC20PaymentTokenSymbol
          nftERC20PaymentTokenDecimals
          nftERC20PaymentAmount
          nftERC20FeeTokenAddress
          nftERC20FeeTokenSymbol
          nftERC20FeeTokenDecimals
          nftERC20FeeAmount
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          searchableStatus
          searchableHighestPrice
          searchableTitle
          searchableCategory
          searchableGenre
          searchableYearOfCreation
          searchableFeaturedArtists
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      release {
        id
        collectibleReleaseIndex
        slug
        tierType
        serialIndex
        releaseCount
        releaseDate
        collectibleID
        userID
        previousOwnerID
        previousPrice
        currentPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        finalPrice
        currentListingType
        lastPurchasedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          streamExternal
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaTitle
          metaDescription
          lastSoldAt
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableHighestBidPrice
          searchableGenre
          searchableFeaturedArtist
          _version
          _deleted
          _lastChangedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      creator {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      patron {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      previousOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification {
    onCreateNotification {
      id
      userID
      type
      event
      read
      activityID
      lambdaEventID
      lambdaEventName
      createdAt
      updatedAt
      searchableType
      searchableRead
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification {
    onUpdateNotification {
      id
      userID
      type
      event
      read
      activityID
      lambdaEventID
      lambdaEventName
      createdAt
      updatedAt
      searchableType
      searchableRead
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification {
    onDeleteNotification {
      id
      userID
      type
      event
      read
      activityID
      lambdaEventID
      lambdaEventName
      createdAt
      updatedAt
      searchableType
      searchableRead
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateNotificationSubscription = /* GraphQL */ `
  subscription OnCreateNotificationSubscription($userID: String) {
    onCreateNotificationSubscription(userID: $userID) {
      userID
      type
      inApp
      sms
      email
      push
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateNotificationSubscription = /* GraphQL */ `
  subscription OnUpdateNotificationSubscription($userID: String) {
    onUpdateNotificationSubscription(userID: $userID) {
      userID
      type
      inApp
      sms
      email
      push
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteNotificationSubscription = /* GraphQL */ `
  subscription OnDeleteNotificationSubscription($userID: String) {
    onDeleteNotificationSubscription(userID: $userID) {
      userID
      type
      inApp
      sms
      email
      push
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateOrderPrint = /* GraphQL */ `
  subscription OnCreateOrderPrint {
    onCreateOrderPrint {
      id
      exchangeID
      userID
      checkoutReceiptID
      prodigiOrderID
      prodigiResponse
      shipmentMethod
      currencyCode
      shippingProduct {
        sku
        copies
        size
        frame
        attributes
        assets
      }
      shippingDetail {
        fullName
        address
        postalCode
        city
        country
        state
        email
        phone
      }
      totalPrice
      createdAt
      updatedAt
      orderStatus
      paymentStatus
      _version
      _deleted
      _lastChangedAt
      exchange {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        fiatCurrencyCode
        paymentReferenceID
        paymentReferenceIDInProcess
        paymentProvider
        paymentProviderInProcess
        bitpayPaymentRequested
        paymentRequestExpiredAt
        checkoutReceiptID
        checkoutReceiptIDInProcess
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        nftTokenIDString
        nftERC20PaymentTokenAddress
        nftERC20PaymentTokenSymbol
        nftERC20PaymentTokenDecimals
        nftERC20PaymentAmount
        nftERC20FeeTokenAddress
        nftERC20FeeTokenSymbol
        nftERC20FeeTokenDecimals
        nftERC20FeeAmount
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        order {
          tax
          platformFee
          salesFee
          royalty
          royaltyFee
          invoiceID
          checkoutReceiptID
          bitpayReceiptID
        }
        searchableExchangeType
        searchableIsSold
        searchableStatus
        searchableHighestPrice
        searchableTitle
        searchableCategory
        searchableGenre
        searchableYearOfCreation
        searchableFeaturedArtists
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          streamExternal
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaTitle
          metaDescription
          lastSoldAt
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableHighestBidPrice
          searchableGenre
          searchableFeaturedArtist
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          fiatCurrencyCode
          paymentReferenceID
          paymentReferenceIDInProcess
          paymentProvider
          paymentProviderInProcess
          bitpayPaymentRequested
          paymentRequestExpiredAt
          checkoutReceiptID
          checkoutReceiptIDInProcess
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          nftTokenIDString
          nftERC20PaymentTokenAddress
          nftERC20PaymentTokenSymbol
          nftERC20PaymentTokenDecimals
          nftERC20PaymentAmount
          nftERC20FeeTokenAddress
          nftERC20FeeTokenSymbol
          nftERC20FeeTokenDecimals
          nftERC20FeeAmount
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          searchableStatus
          searchableHighestPrice
          searchableTitle
          searchableCategory
          searchableGenre
          searchableYearOfCreation
          searchableFeaturedArtists
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          fiatCurrencyCode
          paymentReferenceID
          paymentReferenceIDInProcess
          paymentProvider
          paymentProviderInProcess
          bitpayPaymentRequested
          paymentRequestExpiredAt
          checkoutReceiptID
          checkoutReceiptIDInProcess
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          nftTokenIDString
          nftERC20PaymentTokenAddress
          nftERC20PaymentTokenSymbol
          nftERC20PaymentTokenDecimals
          nftERC20PaymentAmount
          nftERC20FeeTokenAddress
          nftERC20FeeTokenSymbol
          nftERC20FeeTokenDecimals
          nftERC20FeeAmount
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          searchableStatus
          searchableHighestPrice
          searchableTitle
          searchableCategory
          searchableGenre
          searchableYearOfCreation
          searchableFeaturedArtists
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      owner
      user {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onUpdateOrderPrint = /* GraphQL */ `
  subscription OnUpdateOrderPrint {
    onUpdateOrderPrint {
      id
      exchangeID
      userID
      checkoutReceiptID
      prodigiOrderID
      prodigiResponse
      shipmentMethod
      currencyCode
      shippingProduct {
        sku
        copies
        size
        frame
        attributes
        assets
      }
      shippingDetail {
        fullName
        address
        postalCode
        city
        country
        state
        email
        phone
      }
      totalPrice
      createdAt
      updatedAt
      orderStatus
      paymentStatus
      _version
      _deleted
      _lastChangedAt
      exchange {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        fiatCurrencyCode
        paymentReferenceID
        paymentReferenceIDInProcess
        paymentProvider
        paymentProviderInProcess
        bitpayPaymentRequested
        paymentRequestExpiredAt
        checkoutReceiptID
        checkoutReceiptIDInProcess
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        nftTokenIDString
        nftERC20PaymentTokenAddress
        nftERC20PaymentTokenSymbol
        nftERC20PaymentTokenDecimals
        nftERC20PaymentAmount
        nftERC20FeeTokenAddress
        nftERC20FeeTokenSymbol
        nftERC20FeeTokenDecimals
        nftERC20FeeAmount
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        order {
          tax
          platformFee
          salesFee
          royalty
          royaltyFee
          invoiceID
          checkoutReceiptID
          bitpayReceiptID
        }
        searchableExchangeType
        searchableIsSold
        searchableStatus
        searchableHighestPrice
        searchableTitle
        searchableCategory
        searchableGenre
        searchableYearOfCreation
        searchableFeaturedArtists
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          streamExternal
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaTitle
          metaDescription
          lastSoldAt
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableHighestBidPrice
          searchableGenre
          searchableFeaturedArtist
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          fiatCurrencyCode
          paymentReferenceID
          paymentReferenceIDInProcess
          paymentProvider
          paymentProviderInProcess
          bitpayPaymentRequested
          paymentRequestExpiredAt
          checkoutReceiptID
          checkoutReceiptIDInProcess
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          nftTokenIDString
          nftERC20PaymentTokenAddress
          nftERC20PaymentTokenSymbol
          nftERC20PaymentTokenDecimals
          nftERC20PaymentAmount
          nftERC20FeeTokenAddress
          nftERC20FeeTokenSymbol
          nftERC20FeeTokenDecimals
          nftERC20FeeAmount
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          searchableStatus
          searchableHighestPrice
          searchableTitle
          searchableCategory
          searchableGenre
          searchableYearOfCreation
          searchableFeaturedArtists
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          fiatCurrencyCode
          paymentReferenceID
          paymentReferenceIDInProcess
          paymentProvider
          paymentProviderInProcess
          bitpayPaymentRequested
          paymentRequestExpiredAt
          checkoutReceiptID
          checkoutReceiptIDInProcess
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          nftTokenIDString
          nftERC20PaymentTokenAddress
          nftERC20PaymentTokenSymbol
          nftERC20PaymentTokenDecimals
          nftERC20PaymentAmount
          nftERC20FeeTokenAddress
          nftERC20FeeTokenSymbol
          nftERC20FeeTokenDecimals
          nftERC20FeeAmount
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          searchableStatus
          searchableHighestPrice
          searchableTitle
          searchableCategory
          searchableGenre
          searchableYearOfCreation
          searchableFeaturedArtists
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      owner
      user {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onDeleteOrderPrint = /* GraphQL */ `
  subscription OnDeleteOrderPrint {
    onDeleteOrderPrint {
      id
      exchangeID
      userID
      checkoutReceiptID
      prodigiOrderID
      prodigiResponse
      shipmentMethod
      currencyCode
      shippingProduct {
        sku
        copies
        size
        frame
        attributes
        assets
      }
      shippingDetail {
        fullName
        address
        postalCode
        city
        country
        state
        email
        phone
      }
      totalPrice
      createdAt
      updatedAt
      orderStatus
      paymentStatus
      _version
      _deleted
      _lastChangedAt
      exchange {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        fiatCurrencyCode
        paymentReferenceID
        paymentReferenceIDInProcess
        paymentProvider
        paymentProviderInProcess
        bitpayPaymentRequested
        paymentRequestExpiredAt
        checkoutReceiptID
        checkoutReceiptIDInProcess
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        nftTokenIDString
        nftERC20PaymentTokenAddress
        nftERC20PaymentTokenSymbol
        nftERC20PaymentTokenDecimals
        nftERC20PaymentAmount
        nftERC20FeeTokenAddress
        nftERC20FeeTokenSymbol
        nftERC20FeeTokenDecimals
        nftERC20FeeAmount
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        order {
          tax
          platformFee
          salesFee
          royalty
          royaltyFee
          invoiceID
          checkoutReceiptID
          bitpayReceiptID
        }
        searchableExchangeType
        searchableIsSold
        searchableStatus
        searchableHighestPrice
        searchableTitle
        searchableCategory
        searchableGenre
        searchableYearOfCreation
        searchableFeaturedArtists
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          streamExternal
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaTitle
          metaDescription
          lastSoldAt
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableHighestBidPrice
          searchableGenre
          searchableFeaturedArtist
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          fiatCurrencyCode
          paymentReferenceID
          paymentReferenceIDInProcess
          paymentProvider
          paymentProviderInProcess
          bitpayPaymentRequested
          paymentRequestExpiredAt
          checkoutReceiptID
          checkoutReceiptIDInProcess
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          nftTokenIDString
          nftERC20PaymentTokenAddress
          nftERC20PaymentTokenSymbol
          nftERC20PaymentTokenDecimals
          nftERC20PaymentAmount
          nftERC20FeeTokenAddress
          nftERC20FeeTokenSymbol
          nftERC20FeeTokenDecimals
          nftERC20FeeAmount
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          searchableStatus
          searchableHighestPrice
          searchableTitle
          searchableCategory
          searchableGenre
          searchableYearOfCreation
          searchableFeaturedArtists
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          fiatCurrencyCode
          paymentReferenceID
          paymentReferenceIDInProcess
          paymentProvider
          paymentProviderInProcess
          bitpayPaymentRequested
          paymentRequestExpiredAt
          checkoutReceiptID
          checkoutReceiptIDInProcess
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          nftTokenIDString
          nftERC20PaymentTokenAddress
          nftERC20PaymentTokenSymbol
          nftERC20PaymentTokenDecimals
          nftERC20PaymentAmount
          nftERC20FeeTokenAddress
          nftERC20FeeTokenSymbol
          nftERC20FeeTokenDecimals
          nftERC20FeeAmount
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          searchableStatus
          searchableHighestPrice
          searchableTitle
          searchableCategory
          searchableGenre
          searchableYearOfCreation
          searchableFeaturedArtists
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      owner
      user {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onCreateRelease = /* GraphQL */ `
  subscription OnCreateRelease {
    onCreateRelease {
      id
      collectibleReleaseIndex
      slug
      tierType
      serialIndex
      releaseCount
      releaseDate
      collectibleID
      userID
      previousOwnerID
      previousPrice
      currentPrice
      isMinimumOfferVisible
      isAutoAcceptActive
      finalPrice
      currentListingType
      lastPurchasedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        streamExternal
        streamUrl {
          spotify
          appleMusic
          youtube
        }
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaTitle
        metaDescription
        perksOriginal {
          fanClubAccess
          limitedPerks
          lifetimePerks
        }
        perksNumbered {
          fanClubAccess
          limitedPerks
          lifetimePerks
        }
        history {
          status
          value
          date
        }
        lastSoldAt
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableHighestBidPrice
        searchableGenre
        searchableFeaturedArtist
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      previousOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onUpdateRelease = /* GraphQL */ `
  subscription OnUpdateRelease {
    onUpdateRelease {
      id
      collectibleReleaseIndex
      slug
      tierType
      serialIndex
      releaseCount
      releaseDate
      collectibleID
      userID
      previousOwnerID
      previousPrice
      currentPrice
      isMinimumOfferVisible
      isAutoAcceptActive
      finalPrice
      currentListingType
      lastPurchasedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        streamExternal
        streamUrl {
          spotify
          appleMusic
          youtube
        }
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaTitle
        metaDescription
        perksOriginal {
          fanClubAccess
          limitedPerks
          lifetimePerks
        }
        perksNumbered {
          fanClubAccess
          limitedPerks
          lifetimePerks
        }
        history {
          status
          value
          date
        }
        lastSoldAt
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableHighestBidPrice
        searchableGenre
        searchableFeaturedArtist
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      previousOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onDeleteRelease = /* GraphQL */ `
  subscription OnDeleteRelease {
    onDeleteRelease {
      id
      collectibleReleaseIndex
      slug
      tierType
      serialIndex
      releaseCount
      releaseDate
      collectibleID
      userID
      previousOwnerID
      previousPrice
      currentPrice
      isMinimumOfferVisible
      isAutoAcceptActive
      finalPrice
      currentListingType
      lastPurchasedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        streamExternal
        streamUrl {
          spotify
          appleMusic
          youtube
        }
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaTitle
        metaDescription
        perksOriginal {
          fanClubAccess
          limitedPerks
          lifetimePerks
        }
        perksNumbered {
          fanClubAccess
          limitedPerks
          lifetimePerks
        }
        history {
          status
          value
          date
        }
        lastSoldAt
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableHighestBidPrice
        searchableGenre
        searchableFeaturedArtist
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      previousOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onCreateSettings = /* GraphQL */ `
  subscription OnCreateSettings {
    onCreateSettings {
      id
      whitelisted_email
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateSettings = /* GraphQL */ `
  subscription OnUpdateSettings {
    onUpdateSettings {
      id
      whitelisted_email
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteSettings = /* GraphQL */ `
  subscription OnDeleteSettings {
    onDeleteSettings {
      id
      whitelisted_email
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateUserMedia = /* GraphQL */ `
  subscription OnCreateUserMedia {
    onCreateUserMedia {
      id
      avatarUrl
      avatarVideoUrl
      coverUrl
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateUserMedia = /* GraphQL */ `
  subscription OnUpdateUserMedia {
    onUpdateUserMedia {
      id
      avatarUrl
      avatarVideoUrl
      coverUrl
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteUserMedia = /* GraphQL */ `
  subscription OnDeleteUserMedia {
    onDeleteUserMedia {
      id
      avatarUrl
      avatarVideoUrl
      coverUrl
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateUserStatistics = /* GraphQL */ `
  subscription OnCreateUserStatistics {
    onCreateUserStatistics {
      id
      followerCount
      followingCount
      collectibleCreatedCount
      collectibleCollectedCount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateUserStatistics = /* GraphQL */ `
  subscription OnUpdateUserStatistics {
    onUpdateUserStatistics {
      id
      followerCount
      followingCount
      collectibleCreatedCount
      collectibleCollectedCount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteUserStatistics = /* GraphQL */ `
  subscription OnDeleteUserStatistics {
    onDeleteUserStatistics {
      id
      followerCount
      followingCount
      collectibleCreatedCount
      collectibleCollectedCount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      owner
      accountID
      editors
      email
      username
      firstName
      lastName
      displayName
      bio
      links {
        facebook
        youtube
        instagram
        twitter
        spotify
        soundcloud
        email
        website
        tiktok
        snapchat
        appleMusic
        clubhouse
      }
      notifications {
        outbid {
          inApp
          email
        }
        bidResponses {
          inApp
          email
        }
        offerResponses {
          inApp
          email
        }
        successfulPurchase {
          inApp
          email
        }
        collectibleSold {
          inApp
          email
        }
        collectibleMinted {
          inApp
          email
        }
        collectiblePrinted {
          inApp
          email
        }
        offerReceived {
          inApp
          email
        }
        bidReceived {
          inApp
          email
        }
        newCollectibles {
          inApp
          email
        }
        collectibleRelease {
          inApp
          email
        }
        fansincNewsletter {
          inApp
          email
        }
      }
      tags
      verified
      fanProfile
      creatorCategory
      emailVerified
      joinDate
      isHidden
      profileTemplate
      address {
        recipientName
        line1
        country
        stateName
        city
        postCode
        phone
      }
      shippingAddress {
        recipientName
        line1
        country
        stateName
        city
        postCode
        phone
      }
      walletAddresses
      fee {
        salesFee
        royaltyFee
      }
      discourseData {
        userID
        username
        category
        channels
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      media {
        id
        avatarUrl
        avatarVideoUrl
        coverUrl
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      statistics {
        id
        followerCount
        followingCount
        collectibleCreatedCount
        collectibleCollectedCount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      managers {
        items {
          id
          profileID
          managerID
          accountID
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          editors
        }
        nextToken
        startedAt
      }
      profileManaged {
        items {
          id
          profileID
          managerID
          accountID
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          editors
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      owner
      accountID
      editors
      email
      username
      firstName
      lastName
      displayName
      bio
      links {
        facebook
        youtube
        instagram
        twitter
        spotify
        soundcloud
        email
        website
        tiktok
        snapchat
        appleMusic
        clubhouse
      }
      notifications {
        outbid {
          inApp
          email
        }
        bidResponses {
          inApp
          email
        }
        offerResponses {
          inApp
          email
        }
        successfulPurchase {
          inApp
          email
        }
        collectibleSold {
          inApp
          email
        }
        collectibleMinted {
          inApp
          email
        }
        collectiblePrinted {
          inApp
          email
        }
        offerReceived {
          inApp
          email
        }
        bidReceived {
          inApp
          email
        }
        newCollectibles {
          inApp
          email
        }
        collectibleRelease {
          inApp
          email
        }
        fansincNewsletter {
          inApp
          email
        }
      }
      tags
      verified
      fanProfile
      creatorCategory
      emailVerified
      joinDate
      isHidden
      profileTemplate
      address {
        recipientName
        line1
        country
        stateName
        city
        postCode
        phone
      }
      shippingAddress {
        recipientName
        line1
        country
        stateName
        city
        postCode
        phone
      }
      walletAddresses
      fee {
        salesFee
        royaltyFee
      }
      discourseData {
        userID
        username
        category
        channels
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      media {
        id
        avatarUrl
        avatarVideoUrl
        coverUrl
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      statistics {
        id
        followerCount
        followingCount
        collectibleCreatedCount
        collectibleCollectedCount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      managers {
        items {
          id
          profileID
          managerID
          accountID
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          editors
        }
        nextToken
        startedAt
      }
      profileManaged {
        items {
          id
          profileID
          managerID
          accountID
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          editors
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      owner
      accountID
      editors
      email
      username
      firstName
      lastName
      displayName
      bio
      links {
        facebook
        youtube
        instagram
        twitter
        spotify
        soundcloud
        email
        website
        tiktok
        snapchat
        appleMusic
        clubhouse
      }
      notifications {
        outbid {
          inApp
          email
        }
        bidResponses {
          inApp
          email
        }
        offerResponses {
          inApp
          email
        }
        successfulPurchase {
          inApp
          email
        }
        collectibleSold {
          inApp
          email
        }
        collectibleMinted {
          inApp
          email
        }
        collectiblePrinted {
          inApp
          email
        }
        offerReceived {
          inApp
          email
        }
        bidReceived {
          inApp
          email
        }
        newCollectibles {
          inApp
          email
        }
        collectibleRelease {
          inApp
          email
        }
        fansincNewsletter {
          inApp
          email
        }
      }
      tags
      verified
      fanProfile
      creatorCategory
      emailVerified
      joinDate
      isHidden
      profileTemplate
      address {
        recipientName
        line1
        country
        stateName
        city
        postCode
        phone
      }
      shippingAddress {
        recipientName
        line1
        country
        stateName
        city
        postCode
        phone
      }
      walletAddresses
      fee {
        salesFee
        royaltyFee
      }
      discourseData {
        userID
        username
        category
        channels
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      media {
        id
        avatarUrl
        avatarVideoUrl
        coverUrl
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      statistics {
        id
        followerCount
        followingCount
        collectibleCreatedCount
        collectibleCollectedCount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      managers {
        items {
          id
          profileID
          managerID
          accountID
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          editors
        }
        nextToken
        startedAt
      }
      profileManaged {
        items {
          id
          profileID
          managerID
          accountID
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          editors
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onCreateProfileManager = /* GraphQL */ `
  subscription OnCreateProfileManager {
    onCreateProfileManager {
      id
      profileID
      managerID
      accountID
      firstName
      lastName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      manager {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      profile {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      owner
      editors
    }
  }
`;
export const onUpdateProfileManager = /* GraphQL */ `
  subscription OnUpdateProfileManager {
    onUpdateProfileManager {
      id
      profileID
      managerID
      accountID
      firstName
      lastName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      manager {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      profile {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      owner
      editors
    }
  }
`;
export const onDeleteProfileManager = /* GraphQL */ `
  subscription OnDeleteProfileManager {
    onDeleteProfileManager {
      id
      profileID
      managerID
      accountID
      firstName
      lastName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      manager {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      profile {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      owner
      editors
    }
  }
`;
export const onCreateWallet = /* GraphQL */ `
  subscription OnCreateWallet {
    onCreateWallet {
      id
      amount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      walletOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      owner
    }
  }
`;
export const onUpdateWallet = /* GraphQL */ `
  subscription OnUpdateWallet {
    onUpdateWallet {
      id
      amount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      walletOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      owner
    }
  }
`;
export const onDeleteWallet = /* GraphQL */ `
  subscription OnDeleteWallet {
    onDeleteWallet {
      id
      amount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      walletOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      owner
    }
  }
`;
export const onCreateWalletHistory = /* GraphQL */ `
  subscription OnCreateWalletHistory {
    onCreateWalletHistory {
      id
      senderID
      receiverID
      paymentProvider
      debit
      credit
      invoiceID
      referenceID
      exchangeID
      balance
      transactionType
      status
      description
      currency
      withdrawal {
        provider
        destination
        OTP
        OTPVerified
      }
      owner
      createdAt
      updatedAt
      searchableTransactionType
      searchableStatus
      _version
      _deleted
      _lastChangedAt
      sender {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      receiver {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onUpdateWalletHistory = /* GraphQL */ `
  subscription OnUpdateWalletHistory {
    onUpdateWalletHistory {
      id
      senderID
      receiverID
      paymentProvider
      debit
      credit
      invoiceID
      referenceID
      exchangeID
      balance
      transactionType
      status
      description
      currency
      withdrawal {
        provider
        destination
        OTP
        OTPVerified
      }
      owner
      createdAt
      updatedAt
      searchableTransactionType
      searchableStatus
      _version
      _deleted
      _lastChangedAt
      sender {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      receiver {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onDeleteWalletHistory = /* GraphQL */ `
  subscription OnDeleteWalletHistory {
    onDeleteWalletHistory {
      id
      senderID
      receiverID
      paymentProvider
      debit
      credit
      invoiceID
      referenceID
      exchangeID
      balance
      transactionType
      status
      description
      currency
      withdrawal {
        provider
        destination
        OTP
        OTPVerified
      }
      owner
      createdAt
      updatedAt
      searchableTransactionType
      searchableStatus
      _version
      _deleted
      _lastChangedAt
      sender {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      receiver {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
