import { createContext, useState, useContext, useCallback } from 'react';

const SiderContext = createContext({
  isCollapse: false,
  toggleSider: () => {}
});

export const SidebarProvider = ({ children }) => {
  const [isCollapse, setCollapse] = useState(false);

  const toggleSider = useCallback((value) => {
    setCollapse(value);
  }, []);

  return (
    <SiderContext.Provider
      value={{
        isCollapse,
        toggleSider
      }}
    >
      {children}
    </SiderContext.Provider>
  );
};

export const useSider = () => useContext(SiderContext);
