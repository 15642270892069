import { useState, useEffect } from 'react';
import { DashboardContent, Post, UnauthorizedAccess } from '@components';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAuth } from '@libs/contexts/auth';
import { Row, Col, Select, Spin } from 'antd';
import { useParams } from 'react-router';
import { useCollectibleByCreator } from '@libs/hooks/collectible';

function FanClub() {
  const history = useHistory();
  const { t } = useTranslation();
  const { creatorID, channelID } = useParams();
  const { creators: profile, user } = useAuth();
  const { Option } = Select;
  const [creator, setCreator] = useState();
  const [unauthorize, setUnauthorize] = useState(false);
  const [channel, setChannel] = useState();
  const { data: collectibles, loading } = useCollectibleByCreator(creatorID);

  const profileIds = profile?.map((x) => x.id);

  useEffect(() => {
    if (profile?.length > 0) {
      if (creatorID) {
        const creator = profile?.filter((x) => x.id === creatorID);
        setCreator(creator[0]);
      } else {
        setCreator(profile[0]);
      }
    }
  }, [profile]); // eslint-disable-line

  const profileOption =
    profile?.map((x) => ({
      label: x.firstName + ' ' + x.lastName,
      value: x.id
    })) || [];

  const handleChange = (id) => {
    const selectedProfile = profile?.filter((x) => x.id === id);
    history.push(`/commentary/${id}/channels/${channelID}`);
    setCreator(selectedProfile[0]);
  };
  useEffect(() => {
    if (collectibles.length > 0 && creatorID && profileIds) {
      if (profileIds?.includes(creatorID)) {
        const channelIDs = collectibles.map((x) => x?.id);
        if (!channelIDs.includes(channelID)) {
          const defaultChannels = collectibles[0];
          setChannel(defaultChannels);
          history.push(`/commentary/${creatorID}/channels/${defaultChannels?.id}`);
        }
      } else {
        setUnauthorize(true);
      }
    }
  }, [collectibles, creatorID, profileIds]); // eslint-disable-line

  useEffect(() => {
    if (collectibles) {
      const channelIDs = collectibles.map((x) => x?.id);
      if (channelIDs.includes(channelID)) {
        const selectedChannel = collectibles.filter((x) => x?.id === channelID);
        setChannel(selectedChannel[0]);
      }
    }
  }, [collectibles, channelID]); // eslint-disable-line

  if (unauthorize) {
    return <UnauthorizedAccess />;
  }

  return (
    <>
      <DashboardContent className="fanclub-page" title="Commentary">
        {user?.type !== 'Artist' && (
          <Row className="toolbar">
            <Col sm={8} xs={24}>
              {profile?.length > 0 && (
                <div className="toolbar-search">
                  <Select
                    placeholder={t('fanClub.input.creatorPlaceholder')}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) => {
                      handleChange(value);
                    }}
                    value={creatorID}
                    defaultValue={profile?.[0].id}
                  >
                    {profileOption &&
                      profileOption.map((creator) => (
                        <Option key={`creator-${creator.value}`} value={creator.value}>
                          {creator.label}
                        </Option>
                      ))}
                  </Select>
                </div>
              )}
            </Col>
          </Row>
        )}
        <>
          <Row className="fanclub-page-body">
            <Col span={24} lg={18}>
              {!loading ? (
                <>{channel?.userID === creatorID && <Post user={creator} channel={channel} />}</>
              ) : (
                <center>
                  <Spin />
                </center>
              )}
            </Col>

            <Col className="fanclub-page-body-channel" span={24} lg={6}>
              <div className="fanclub-page-body-channel-content">
                <div className="fanclub-page-body-channel-content-header">
                  {t('fanClub.collectible')}
                </div>
                <div className="fanclub-page-body-channel-content-list"></div>
                {collectibles &&
                  collectibles.map((x) => (
                    <div
                      onClick={() => {
                        setChannel(x);
                        history.push(`/commentary/${creatorID}/channels/${x?.id}`);
                      }}
                      className={`fanclub-page-body-channel-content-list-item${
                        x?.id === channel?.id ? '-active' : ''
                      }`}
                    >
                      <Row>
                        <Col className="fanclub-page-body-channel-content-list-item-left" span={16}>
                          <div className="fanclub-page-body-channel-content-list-item-left-name">
                            {x.title}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ))}
              </div>
            </Col>
          </Row>
        </>
      </DashboardContent>
      <Helmet>
        <title>{t('fanClub.title')}</title>
      </Helmet>
    </>
  );
}

export default FanClub;
