import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Table, Select } from 'antd';
import { Helmet } from 'react-helmet';
import { DashboardContent, DisplayDate, Pagination, WithdrawalModal, Price } from '@components';
import { useWalletHistory, useWalletByOwner } from '@libs/hooks/wallet';
import { useProfileOrganisation } from '@libs/hooks/organisation';
import { useState, useEffect } from 'react';
import { useAuth } from 'libs/contexts/auth';

const { Option } = Select;

function Balance() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { data: balance, getData: fetchBalance, loading: loadingBalance } = useWalletByOwner();
  const { data, loading, getData: fetchHistory, nextToken: nextHistoryToken } = useWalletHistory();
  const { data: profile, loading: loadingProfile } = useProfileOrganisation(true);
  const [creatorID, setCreatorID] = useState();
  const [withdrawalModal, setWithdrawalModal] = useState(false);

  useEffect(() => {
    if (profile.length > 0) {
      setCreatorID(profile[0]?.value);
    }
  }, [profile]);

  const refetch = () => {
    fetchBalance(creatorID);
    fetchHistory(creatorID);
  };

  const handleChange = (id) => {
    fetchBalance(id);
    fetchHistory(id);
    setCreatorID(id);
  };

  const nextPage = (nextToken) => {
    fetchHistory(creatorID, nextToken);
  };

  const columns = [
    {
      title: '#',
      responsive: ['xs'],
      render: (_, row) => <span style={{ textDecoration: 'underline' }}>{row.invoiceID}</span>
    },
    {
      title: '#',
      responsive: ['sm'],
      render: (_, row) => <span style={{ textDecoration: 'underline' }}>{row.invoiceID}</span>
    },
    {
      title: t('balance.table.date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      responsive: ['md'],
      render: (value) => <DisplayDate date={value} />
    },
    {
      title: t('balance.table.description'),
      dataIndex: 'description',
      key: 'description',
      responsive: ['xs', 'sm'],
      render: (value) => <p>{value}</p>
    },
    {
      title: t('balance.table.amount'),
      responsive: ['xs', 'sm'],
      render: (_, row) => (
        <Price
          inline
          amount={row?.debit === '0' ? `${row?.credit}` : row?.debit}
          minus={row?.credit !== '0'}
        />
      )
    },
    {
      title: t('balance.table.balance'),
      dataIndex: 'balance',
      key: 'balance',
      responsive: ['sm'],
      render: (value) => (
        <span>
          <Price amount={value} />
        </span>
      )
    },
    {
      title: t('balance.table.status'),
      dataIndex: 'status',
      key: 'status',
      responsive: ['xs', 'sm'],
      render: (value) => <span>{value}</span>
    }
  ];
  return (
    <>
      <DashboardContent title={t('balance.title')}>
        {user?.type !== 'Artist' && (
          <Row className="toolbar">
            <Col sm={8} xs={24}>
              {profile.length > 0 && (
                <div className="toolbar-search">
                  <Select
                    loading={loadingProfile}
                    placeholder={t('components.notificationMenu.creator.placeholder')}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) => {
                      handleChange(value);
                    }}
                    defaultValue={profile[0].value}
                  >
                    {profile &&
                      profile.map((creator) => (
                        <Option key={`creator-${creator.value}`} value={creator.value}>
                          {creator.label}
                        </Option>
                      ))}
                  </Select>
                </div>
              )}
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24} lg={24} className="balance__amount">
            <p className="balance__amount-desc">
              {process.env.REACT_APP_NAME === 'patrons'
                ? t('balance.patrons.text')
                : t('balance.text')}
            </p>
            <div className="balance__amount-current">
              <span className="balance__amount-text">{t('balance.current')}</span>
              {/* { && ( */}
              <span className="balance__amount-value">
                <Price amount={loadingBalance ? 0 : balance?.amount} />
              </span>
              {/* )} */}
              <Button type="primary" onClick={() => setWithdrawalModal(true)}>
                {t('balance.withdraw')}
              </Button>
            </div>
          </Col>
        </Row>
        <div className="manage-content">
          <div className="fans-table">
            <Table
              bordered
              dataSource={data}
              columns={columns}
              loading={loading}
              pagination={false}
            />
            <Pagination nextToken={nextHistoryToken} loading={loading} fetchData={nextPage} />
          </div>
        </div>
        <WithdrawalModal
          visible={withdrawalModal}
          onCancel={() => {
            setWithdrawalModal(false);
          }}
          refetch={refetch}
          balance={balance}
          creatorID={creatorID}
        />
      </DashboardContent>
      <Helmet>
        <title>{t('balance.title')}</title>
      </Helmet>
    </>
  );
}

export default Balance;
