import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Form, Input } from 'antd';

import { Link } from '@components';

import { useUser } from '@libs/hooks/user';

function AuthResetPassword() {
  const { t } = useTranslation();
  const { success, error, resetPass, resendForgotPass, loading: formLoading } = useUser();

  const onFinish = (values) => {
    resetPass({
      code: values.code,
      newPassword: values.password
    });
  };

  const onResend = () => resendForgotPass();
  return (
    <>
      <div className="auth-reset">
        <div className="auth-reset__wrapper">
          <div className="auth-reset__title">{t('auth.reset.title')}</div>
          <div className="auth-reset__description">{t('auth.reset.instruction')}</div>
          <Form name="forgot" className="auth-form fans-form--reset" onFinish={onFinish}>
            {(error || success) && (
              <Form.Item>
                {error && <Alert message={error} type="error" showIcon />}
                {success && <Alert message={success} type="success" showIcon />}
              </Form.Item>
            )}
            <Form.Item
              name="code"
              rules={[
                { required: true, message: t('auth.fields.code.required') },
                { pattern: /^(?:\d*)$/, message: t('auth.fields.code.numberValidation') }
              ]}
            >
              <Input placeholder={t('auth.fields.code.placeholder')} />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: t('auth.fields.password.required') },
                {
                  pattern: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                  message: t('auth.fields.password.strength')
                },
                { min: 8, message: t('auth.fields.password.minLength') },
                { max: 64, message: t('auth.fields.password.maxLength') }
              ]}
            >
              <Input type="password" placeholder={t('auth.fields.password.placeholder')} />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              dependencies={['password']}
              rules={[
                { required: true, message: t('auth.fields.confirmPassword.required') },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(t('auth.fields.confirmPassword.notMatch'));
                  }
                })
              ]}
            >
              <Input type="password" placeholder={t('auth.fields.confirmPassword.placeholder')} />
            </Form.Item>
            <div className="auth-reset__button">
              <Button type="primary" block loading={formLoading} htmlType="submit">
                {t('auth.buttons.recover')}
              </Button>
            </div>
          </Form>
          <div className="auth-box__link">
            <Link onClick={onResend}>{t('auth.reset.resend')}</Link>
          </div>
        </div>
      </div>
      <Helmet>
        <title>{t('auth.forgot.title')}</title>
      </Helmet>
    </>
  );
}

export default AuthResetPassword;
