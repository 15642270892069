import { useEffect, useState } from 'react';
import { useProfileOrganisation } from '@libs/hooks/organisation';
import {
  collectibleByTypeAndCreatedAt,
  collectibleByTypeAndLowestPrice,
  collectibleByCategoryAndCreatedAt,
  collectibleByCategoryAndLowestPrice
} from '@libs/custom-queries/collectible';

export const createLatestCollectibleQuery = (category, limit, sort) => {
  let queryName;
  let query;
  const params = {
    limit
  };

  switch (sort) {
    case 'PRICE_LOWEST':
      query = category ? collectibleByCategoryAndLowestPrice : collectibleByTypeAndLowestPrice;
      queryName = category
        ? 'collectibleByCategoryAndLowestPrice'
        : 'collectibleByTypeAndLowestPrice';
      params.sortDirection = 'ASC';

      if (category) {
        params.category = category;
      } else {
        params.type = 'COLLECTIBLE';
      }
      break;
    case 'PRICE_HIGHEST':
      query = category ? collectibleByCategoryAndLowestPrice : collectibleByTypeAndLowestPrice;
      queryName = category
        ? 'collectibleByCategoryAndLowestPrice'
        : 'collectibleByTypeAndLowestPrice';
      params.sortDirection = 'DESC';

      if (category) {
        params.category = category;
      } else {
        params.type = 'COLLECTIBLE';
      }
      break;
    case 'CREATED_AT_DESC':
    default:
      query = category ? collectibleByCategoryAndCreatedAt : collectibleByTypeAndCreatedAt;
      queryName = category ? 'collectibleByCategoryAndCreatedAt' : 'collectibleByTypeAndCreatedAt';
      params.sortDirection = 'DESC';

      if (category) {
        params.category = category;
      } else {
        params.type = 'COLLECTIBLE';
      }
      break;
  }

  return { query, params, queryName };
};

export const useCollectibleParams = (accountID) => {
  const { data: profiles } = useProfileOrganisation(true);
  const [query, setQuery] = useState({
    sort: { direction: 'desc', field: 'createdAt' },
    limit: 10,
    filter: {
      accountID: {
        eq: accountID
      },
      or: profiles.map((c) => ({ userID: { eq: c.value } }))
    }
  });

  const onSearch = (title) => {
    if (title) {
      const filter = {
        ...query.filter,
        title: { matchPhrasePrefix: title }
      };
      setQuery({ ...query, filter, nextToken: undefined });
    } else {
      const filter = {
        ...query.filter,
        title: undefined
      };
      setQuery({ ...query, filter, nextToken: undefined });
    }
  };

  const onPaginate = (nextToken) => {
    setQuery({ ...query, nextToken });
  };

  useEffect(() => {
    if (profiles) {
      setQuery({
        sort: { direction: 'desc', field: 'createdAt' },
        limit: 10,
        filter: {
          accountID: {
            eq: accountID
          },
          or: profiles.map((c) => ({ userID: { eq: c.value } }))
        }
      });
    }
  }, [profiles]); // eslint-disable-line

  return {
    query,
    onSearch,
    onPaginate
  };
};

export const useCollectibleParamsAdmin = (limit = 10) => {
  const [query, setQuery] = useState({
    sort: { direction: 'desc', field: 'createdAt' },
    limit
  });

  const onSearch = (title) => {
    if (title) {
      const filter = {
        ...query.filter,
        title: { matchPhrasePrefix: title }
      };
      setQuery({ ...query, filter, nextToken: undefined });
    } else {
      delete query.filter.title;
      if (Object.keys(query.filter).length < 1) {
        delete query.filter;
      }
      setQuery({ ...query, nextToken: undefined });
    }
  };

  const onPaginate = (nextToken) => {
    setQuery({ ...query, nextToken });
  };

  const onStatusChanges = (status) => {
    if (status) {
      const filter = {
        ...query.filter,
        searchableStatus: { eq: status }
      };
      setQuery({ ...query, filter, nextToken: undefined });
    } else {
      delete query.filter.searchableStatus;
      if (Object.keys(query.filter).length < 1) {
        delete query.filter;
      }
      setQuery({ ...query, nextToken: undefined });
    }
  };

  return {
    query,
    onSearch,
    onPaginate,
    onStatusChanges
  };
};
