import { useCallback, useState, useEffect } from 'react';
import { Button, Modal, Input } from 'antd';
import { Avatar, RichTextEditor, Image } from '@components';
import { useTranslation } from 'react-i18next';
import { HeartOutlined, DeleteOutlined } from '@ant-design/icons';
import Comment from './comment';
import { useParams } from 'react-router';
import useToast from '@libs/utils/toast';
import { useCollectible, useCollectibleLikesCountByCollectibleID } from '@libs/hooks/collectible';
import { usePost } from '@libs/hooks/discourse';
import { useCommentCollectible } from '@libs/hooks/discourse';
import { ReactComponent as EyeIcon } from '@assets/svg/eye.svg';

function Post({ user, postId, setDetailPost, post, fetchPosts, setLoadingPosts }) {
  const { t } = useTranslation();
  const { TextArea } = Input;
  const toast = useToast();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditPostModal, setShowEditPostModal] = useState(false);
  const [showCreateComment, setShowCreateComment] = useState(false);
  const [inputComment, setInputComment] = useState('');
  const [updatePostValue, setUpdatePostValue] = useState();
  const handleUpdatePostChange = (editor) => {
    setUpdatePostValue(editor.getHTML());
  };
  const { channelID } = useParams();
  const {
    data: updatePostData,
    getData: fetchPost,
    deletePost,
    deleteLoading,
    updateLoading,
    updatePost
  } = usePost(postId, user?.id);
  const [contentPost, setContentPost] = useState(post);
  const { data: collectible } = useCollectible(channelID);
  const { data: likeCollectible } = useCollectibleLikesCountByCollectibleID(channelID);
  const { createComment, data, getData, loading } = useCommentCollectible({
    collectibleID: collectible?.id
  });
  const [listComment, setListComment] = useState([]);
  const handleSubmitComment = useCallback(async () => {
    if (inputComment.length < 1) {
      toast(t('components.post.emptyComment'), 'error');
      return;
    }
    const payload = {
      raw: inputComment,
      userID: user?.id
    };
    const newComment = {
      channelID: collectible?.id,
      user: user,
      content: inputComment,
      createdAt: new Date()
    };
    setInputComment('');
    setShowCreateComment(false);
    setListComment(listComment.concat(newComment));
    setTotalComment(totalComment + 1);
    await createComment(payload);
    setTimeout(function () {
      getData();
    }, 2000);
  }, [inputComment]); // eslint-disable-line
  const [totalComment, setTotalComment] = useState(0);
  const postDetail = contentPost;

  const handleDeletePost = async () => {
    await deletePost();
    setLoadingPosts(true);
    setDetailPost(false);
    setTimeout(function () {
      fetchPosts();
    }, 2000);
    setShowDeleteModal(false);
  };

  const countComment = async () => {
    let count = 0;
    await data?.map((x) => {
      return (count += x?.replies?.items?.length);
    });
    setTotalComment(count + data?.length);
  };
  useEffect(() => {
    countComment();
  }, [data]); // eslint-disable-line

  const removeComment = (id) => {
    setListComment(listComment?.filter((comment) => comment?.id !== id));
  };

  const handleUpdatePost = useCallback(async () => {
    const payload = {
      raw: updatePostValue,
      id: post?.id,
      userID: user.id,
      channelID: post?.channelID
    };
    await updatePost(payload);
    setTimeout(function () {
      fetchPost();
    }, 2000);
    setShowEditPostModal(false);
  }, [updatePostValue]); // eslint-disable-line

  useEffect(() => {
    setListComment(data || []);
  }, [data]);

  useEffect(() => {
    if (updatePostData) {
      setContentPost(updatePostData);
    }
  }, [updatePostData]); // eslint-disable-line
  return (
    <>
      <div className="post">
        <div className="post-header">
          <div className="post-header-profile">
            <div className="post-header-profile-detail">
              <div className="post-header-profile-detail-name">
                <div className="post-header-profile-detail-name-left">{collectible?.title}</div>
                <div className="post-header-profile-detail-name-right">
                  <EyeIcon />
                  <div className="post-header-profile-detail-name-right-count">
                    {collectible?.viewCount}
                  </div>
                </div>
              </div>
              <Image size={600} src={collectible?.mediaThumbnail} />
            </div>
          </div>
        </div>
        <div className="post-separator-count-top"></div>
        <div className="post-response">
          <div className="post-response-likes">
            <div
              className={`post-response-likes-icon${
                user?.id === postDetail?.userID ? '-owner' : ''
              }`}
            >
              <HeartOutlined style={{ fontSize: '20px' }} />
            </div>
            <div className="post-response-likes-count">
              {likeCollectible > 0 && likeCollectible}
            </div>
          </div>
          <div className="post-response-comments">
            {totalComment > 0 && totalComment}
            <span
              className="post-response-comments-event"
              onClick={() => setShowCreateComment(!showCreateComment)}
            >
              {t('components.post.comments')}
            </span>
          </div>
        </div>
        <div className="post-separator-count-bottom"></div>
        {listComment?.map((comment) => (
          <Comment
            user={user}
            removeComment={removeComment}
            totalComment={totalComment}
            setTotalComment={setTotalComment}
            comment={comment}
            count
          />
        ))}
        {showCreateComment && (
          <>
            <div className="post-separator"></div>
            <div className="post-create-comment">
              <Avatar size={36} user={user} />
              <div className="post-create-comment-input">
                <div className="post-create-comment-input-editor">
                  <TextArea
                    rows={4}
                    value={inputComment}
                    onChange={(e) => setInputComment(e.target.value)}
                    placeholder="Type here..."
                    onKeyDown={(e) => e.key === 'Enter' && handleSubmitComment()}
                  />
                </div>
                <Button onClick={() => handleSubmitComment()} loading={loading} type="primary">
                  {t('components.post.button.send')}
                </Button>
              </div>
            </div>
          </>
        )}
        <Modal
          className="post-delete-modal"
          visible={showDeleteModal}
          footer={null}
          onCancel={() => setShowDeleteModal(false)}
          width={650}
        >
          <div className="post-delete-modal-container">
            <div className="post-delete-modal-container-header">
              <DeleteOutlined style={{ fontSize: '5rem', color: '#D6222A' }} />
              <div className="post-delete-modal-container-header-title">
                {t('components.post.modal.delete.title')}
              </div>
            </div>
            <div className="post-delete-modal-container-header-text">
              {t('components.post.modal.delete.description')}
            </div>
          </div>
          <div className="post-delete-modal-container-footer">
            <Button
              style={{ backgroundColor: '#D6222A', border: 'none' }}
              className="post-delete-modal-container-footer-delete"
              type="primary"
              loading={deleteLoading}
              onClick={() => handleDeletePost()}
            >
              {t('components.post.modal.delete.delete')}
            </Button>
            <Button
              onClick={() => setShowDeleteModal(false)}
              className="post-delete-modal-container-footer-cancel"
              type="secondary"
            >
              {t('components.post.modal.delete.cancel')}
            </Button>
          </div>
        </Modal>
        <Modal
          className="post-update-modal"
          visible={showEditPostModal}
          footer={null}
          title={t('components.post.modal.edit.title')}
          onCancel={() => setShowEditPostModal(false)}
          width={650}
        >
          <div className="post-update-modal-input">
            <div className="post-update-modal-input-editor">
              <RichTextEditor
                defaultValue={post?.content}
                placeholder={t('fanClub.input.placeholder')}
                onChange={handleUpdatePostChange}
                onBlur={handleUpdatePostChange}
              />
            </div>
          </div>
          <Button
            onClick={() => handleUpdatePost()}
            loading={updateLoading}
            className="post-update-modal-button"
            type="primary"
          >
            {t('fanClub.channel.save')}
          </Button>
        </Modal>
      </div>
    </>
  );
}

export default Post;
