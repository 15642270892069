import React, { useState } from 'react';
import { Button } from 'antd';

import { useProfileOrganisation } from '@libs/hooks/organisation';
import { generateCollectibleData } from '@libs/utils/dummy';
import { useCollectibles } from '@libs/hooks/collectible';
import { useHistory } from 'react-router-dom';

const generateCollectible = async ({ creators, onCreate, redirectToApprove, setLoading }) => {
  try {
    setLoading(true);
    const data = await generateCollectibleData({ creators });
    await onCreate(data, data.submitForReview, true, true);
    const { slug } = data;

    setTimeout(() => {
      setLoading(false);
      redirectToApprove(slug);
    }, 2000);
  } catch (error) {
    console.error(error);
    setLoading(false);
  }
};

const GenerateCollectible = () => {
  const history = useHistory();
  const { data: creators, loading: creatorsLoading } = useProfileOrganisation(true);
  const { onCreate } = useCollectibles();
  const [loading, setLoading] = useState(false);

  const redirectToApprove = (slug) => {
    const urlRedirect = `/dummy/approve-collectible?slug=${slug}`;
    history.push(urlRedirect);
  };

  return (
    <Button
      type="primary"
      onClick={() => generateCollectible({ creators, onCreate, redirectToApprove, setLoading })}
      loading={loading || creatorsLoading}
      disabled={loading || creatorsLoading}
    >
      Generate Collectible
    </Button>
  );
};

export default GenerateCollectible;
