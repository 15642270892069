import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { useCookies } from 'react-cookie';

import config from '@/config';
import gql from '@libs/utils/gql';
import useToast from '@libs/utils/toast';
import { useTokenPrefix } from '@libs/utils/token';
import { getUser, accountUserByUser } from '@graphql/queries';
import { profileManagerByAccountAndManager } from '@libs/custom-queries/organisation';

const AuthContext = createContext({
  loading: false,
  isAuthenticated: false,
  user: null,
  creators: [],
  authenticate: () => {},
  logout: () => {},
  error: null,
  success: null,
  resetMessage: () => {},
  setMessageStatus: () => {}
});

export const AuthProvider = ({ children }) => {
  const history = useHistory();
  const toast = useToast();
  const [cookies] = useCookies([config.cookie.INVITATION]);
  const { tokenPrefix, setTokenPrefix, removeTokenPrefix } = useTokenPrefix();

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [creators, setCreators] = useState();
  const [permission, setPermission] = useState();
  const [message, setMessage] = useState({ success: null, error: null });

  const [isAuthenticated, setAuth] = useState(!!localStorage.getItem(tokenPrefix));

  const getCurrentUser = async () => {
    try {
      const { attributes, signInUserSession } = await Auth.currentAuthenticatedUser();
      const { data: res } = await gql(getUser, { id: attributes.sub });
      if (res?.getUser) {
        setUser({
          ...res.getUser,
          isAdmin: signInUserSession.accessToken.payload['cognito:groups'].includes('Admin')
        });
        if (res.getUser.accountID && res?.getUser.id) {
          const params = {
            accountID: res.getUser.accountID,
            managerID: { eq: res?.getUser.id }
          };
          const { data: listCreators } = await gql(profileManagerByAccountAndManager, params);
          if (listCreators?.profileManagerByAccountAndManager?.items) {
            const profiles = listCreators?.profileManagerByAccountAndManager?.items?.map(
              (x) => x.profile
            );
            setCreators(profiles);
          }
        }
      }
    } catch (error) {
      console.error(error);
      await Auth.signOut();
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function getPermission() {
      try {
        if (user?.accountID) {
          const { data: permissionRes } = await gql(accountUserByUser, {
            userID: user.id,
            accountID: { eq: user.accountID }
          });
          if (permissionRes?.accountUserByUser?.items?.length) {
            setPermission(permissionRes.accountUserByUser.items[0]);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }

    getPermission();
  }, [user]);

  const authenticate = useCallback(
    async ({ email, password }) => {
      setLoading(true);
      resetMessage();

      try {
        const { keyPrefix, username, attributes } = await Auth.signIn(email, password);
        const { data: res } = await gql(getUser, { id: attributes.sub });
        const { signInUserSession } = await Auth.currentAuthenticatedUser();

        if (res?.getUser && res?.getUser?.status === 'NOT_ACTIVE') {
          toast('Account non active!', 'error');
          setMessageStatus('error', 'Account non active!');
          await Auth.signOut();
          return;
        }

        if (res?.getUser) {
          // fansTokenPrefix is used for accessing the token
          if (signInUserSession.accessToken.payload['cognito:groups'].includes('Creator')) {
            setTokenPrefix(keyPrefix, username);
            setUser({
              ...res.getUser,
              isAdmin: signInUserSession.accessToken.payload['cognito:groups'].includes('Admin')
            });
            setAuth(true);
            if (res.getUser.accountID && res?.getUser.id) {
              const params = {
                accountID: res.getUser.accountID,
                managerID: { eq: res?.getUser.id }
              };
              const { data: listCreators } = await gql(profileManagerByAccountAndManager, params);
              if (listCreators?.profileManagerByAccountAndManager?.items) {
                const profiles = listCreators?.profileManagerByAccountAndManager?.items?.map(
                  (x) => x.profile
                );
                setCreators(profiles);
              }
            }

            const invitationID = window.localStorage.getItem(config.storage.INVITATION_ID);
            const nextPath = invitationID ? `/accept-invitation/${invitationID}` : '/collectibles';

            history.push(nextPath);
          } else {
            toast('Not authorized', 'error');
            await Auth.signOut();
          }
        } else {
          setMessageStatus('error', 'Something went wrong');
        }
      } catch (error) {
        console.error(error);
        let errorMessage = error.errors ? error.errors[0]?.message : error.message;
        toast(errorMessage, 'error');
      } finally {
        setLoading(false);
      }
    },
    [history, setTokenPrefix, cookies] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const resetMessage = () => {
    setMessage({ success: null, error: null });
  };

  const setMessageStatus = (status, message) => {
    if (!['success', 'error'].includes(status)) {
      throw Error('Invalid message type');
    }

    setMessage({ [status]: message });
  };

  const logout = useCallback(
    async (global = false) => {
      try {
        await Auth.signOut({ global });
      } catch (err) {
        console.error(err);
      } finally {
        setAuth(false);
        setUser(null);
        removeTokenPrefix();
        history.replace('/');
      }
    },
    [history, removeTokenPrefix]
  );

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        permission,
        creators,
        authenticate,
        loading,
        logout,
        error: message.error,
        success: message.success,
        resetMessage,
        setCreators,
        setMessageStatus,
        getCurrentUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
