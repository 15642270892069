/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDataForTierTiles = /* GraphQL */ `
  query GetDataForTierTiles($input: getDataForTierTilesInput!) {
    getDataForTierTiles(input: $input)
  }
`;
export const discourseGetReplies = /* GraphQL */ `
  query DiscourseGetReplies($input: discourseGetRepliesInput!) {
    discourseGetReplies(input: $input)
  }
`;
export const discourseGetPosts = /* GraphQL */ `
  query DiscourseGetPosts($input: discourseInput!) {
    discourseGetPosts(input: $input)
  }
`;
export const discourseGetPost = /* GraphQL */ `
  query DiscourseGetPost($input: discoursePostInput!) {
    discourseGetPost(input: $input)
  }
`;
export const getHighestBid = /* GraphQL */ `
  query GetHighestBid($input: getHighestBidInput!) {
    getHighestBid(input: $input)
  }
`;
export const getFeaturedArtistByCreator = /* GraphQL */ `
  query GetFeaturedArtistByCreator($input: getFeaturedArtistByCreatorInput!) {
    getFeaturedArtistByCreator(input: $input)
  }
`;
export const getCollectibleDetail = /* GraphQL */ `
  query GetCollectibleDetail($input: getCollectibleDetailInput!) {
    getCollectibleDetail(input: $input)
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      name
      legalName
      address
      country
      state
      city
      postcode
      phone
      website
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      members {
        items {
          id
          accountID
          userID
          firstName
          lastName
          email
          roleType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts($filter: ModelAccountFilterInput, $limit: Int, $nextToken: String) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        legalName
        address
        country
        state
        city
        postcode
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        members {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const searchAccounts = /* GraphQL */ `
  query SearchAccounts(
    $filter: SearchableAccountFilterInput
    $sort: SearchableAccountSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchAccounts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        legalName
        address
        country
        state
        city
        postcode
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        members {
          nextToken
          startedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const syncAccounts = /* GraphQL */ `
  query SyncAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAccounts(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        name
        legalName
        address
        country
        state
        city
        postcode
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        members {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getAccountStatistic = /* GraphQL */ `
  query GetAccountStatistic($id: ID!) {
    getAccountStatistic(id: $id) {
      id
      collectibleCount
      collectionCount
      verifiedProfileCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listAccountStatistics = /* GraphQL */ `
  query ListAccountStatistics(
    $filter: ModelAccountStatisticFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountStatistics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        collectibleCount
        collectionCount
        verifiedProfileCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAccountStatistics = /* GraphQL */ `
  query SyncAccountStatistics(
    $filter: ModelAccountStatisticFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAccountStatistics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        collectibleCount
        collectionCount
        verifiedProfileCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getAccountUser = /* GraphQL */ `
  query GetAccountUser($id: ID!) {
    getAccountUser(id: $id) {
      id
      accountID
      userID
      firstName
      lastName
      email
      roleType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      account {
        id
        name
        legalName
        address
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        members {
          nextToken
          startedAt
        }
      }
      owner
      user {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listAccountUsers = /* GraphQL */ `
  query ListAccountUsers($filter: ModelAccountUserFilterInput, $limit: Int, $nextToken: String) {
    listAccountUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        userID
        firstName
        lastName
        email
        roleType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        account {
          id
          name
          legalName
          address
          phone
          website
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        user {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const accountUserByAccountRole = /* GraphQL */ `
  query AccountUserByAccountRole(
    $accountID: ID
    $roleType: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccountUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountUserByAccountRole(
      accountID: $accountID
      roleType: $roleType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        userID
        firstName
        lastName
        email
        roleType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        account {
          id
          name
          legalName
          address
          phone
          website
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        user {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const accountUserByAccount = /* GraphQL */ `
  query AccountUserByAccount(
    $accountID: ID
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccountUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountUserByAccount(
      accountID: $accountID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        userID
        firstName
        lastName
        email
        roleType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        account {
          id
          name
          legalName
          address
          phone
          website
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        user {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const accountUserByUser = /* GraphQL */ `
  query AccountUserByUser(
    $userID: ID
    $accountID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccountUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountUserByUser(
      userID: $userID
      accountID: $accountID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        userID
        firstName
        lastName
        email
        roleType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        account {
          id
          name
          legalName
          address
          phone
          website
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        user {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const accountUserByEmailAccount = /* GraphQL */ `
  query AccountUserByEmailAccount(
    $email: String
    $accountID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccountUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountUserByEmailAccount(
      email: $email
      accountID: $accountID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        userID
        firstName
        lastName
        email
        roleType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        account {
          id
          name
          legalName
          address
          phone
          website
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        user {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAccountUsers = /* GraphQL */ `
  query SyncAccountUsers(
    $filter: ModelAccountUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAccountUsers(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        accountID
        userID
        firstName
        lastName
        email
        roleType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        account {
          id
          name
          legalName
          address
          phone
          website
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        user {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getAccountCreator = /* GraphQL */ `
  query GetAccountCreator($id: ID!) {
    getAccountCreator(id: $id) {
      id
      accountID
      userID
      firstName
      lastName
      email
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      account {
        id
        name
        legalName
        address
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        members {
          nextToken
          startedAt
        }
      }
      owner
      user {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listAccountCreators = /* GraphQL */ `
  query ListAccountCreators(
    $filter: ModelAccountCreatorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountCreators(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        userID
        firstName
        lastName
        email
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        account {
          id
          name
          legalName
          address
          phone
          website
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        user {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const accountCreatorByUser = /* GraphQL */ `
  query AccountCreatorByUser(
    $userID: ID
    $accountID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccountCreatorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountCreatorByUser(
      userID: $userID
      accountID: $accountID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        userID
        firstName
        lastName
        email
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        account {
          id
          name
          legalName
          address
          phone
          website
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        user {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAccountCreators = /* GraphQL */ `
  query SyncAccountCreators(
    $filter: ModelAccountCreatorFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAccountCreators(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        userID
        firstName
        lastName
        email
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        account {
          id
          name
          legalName
          address
          phone
          website
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        user {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getActivity = /* GraphQL */ `
  query GetActivity($id: ID!) {
    getActivity(id: $id) {
      id
      userID
      type
      event
      lambdaEventID
      lambdaEventName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listActivities = /* GraphQL */ `
  query ListActivities($filter: ModelActivityFilterInput, $limit: Int, $nextToken: String) {
    listActivities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        type
        event
        lambdaEventID
        lambdaEventName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const activityByUser = /* GraphQL */ `
  query ActivityByUser(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activityByUser(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        type
        event
        lambdaEventID
        lambdaEventName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncActivities = /* GraphQL */ `
  query SyncActivities(
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncActivities(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        userID
        type
        event
        lambdaEventID
        lambdaEventName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCollectible = /* GraphQL */ `
  query GetCollectible($id: ID!) {
    getCollectible(id: $id) {
      id
      accountID
      userID
      type
      category
      genre
      title
      slug
      description
      insideStory
      assetUrl
      mediaThumbnail
      marketingText
      whatsIncludedOriginal
      whatsIncludedNumbered
      featured
      featuredInProfile
      status
      yearOfCreation
      releasedAt
      createdAt
      updatedAt
      releases {
        tierType
        releaseDate
        releaseCount
        soldCount
        listingType
        startingPrice
        finalPrice
        saleEndDate
        preReleaseAvailability
        availableForPreRelease
        setStartingPrice
        setFinalPrice
        isReleased
      }
      maxNumberedEditions
      metaDescription
      searchableType
      searchableFeatured
      searchablefeaturedInProfile
      searchableCategory
      searchableStatus
      searchableLowestPrice
      searchableHighestPrice
      searchableGenre
      _version
      _deleted
      _lastChangedAt
      owner
      collections {
        items {
          id
          collectionID
          collectibleID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      creator {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listCollectibles = /* GraphQL */ `
  query ListCollectibles($filter: ModelCollectibleFilterInput, $limit: Int, $nextToken: String) {
    listCollectibles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        userID
        type
        category
        genre
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaDescription
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableGenre
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const collectibleByTypeAndCreatedAt = /* GraphQL */ `
  query CollectibleByTypeAndCreatedAt(
    $type: CollectibleType
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCollectibleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    collectibleByTypeAndCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        userID
        type
        category
        genre
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaDescription
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableGenre
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const collectibleByCategoryAndCreatedAt = /* GraphQL */ `
  query CollectibleByCategoryAndCreatedAt(
    $category: CollectibleCategory
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCollectibleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    collectibleByCategoryAndCreatedAt(
      category: $category
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        userID
        type
        category
        genre
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaDescription
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableGenre
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const collectibleByUser = /* GraphQL */ `
  query CollectibleByUser(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCollectibleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    collectibleByUser(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        userID
        type
        category
        genre
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaDescription
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableGenre
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const collectibleByStatusUser = /* GraphQL */ `
  query CollectibleByStatusUser(
    $status: CollectibleStatus
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCollectibleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    collectibleByStatusUser(
      status: $status
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        userID
        type
        category
        genre
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaDescription
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableGenre
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const collectibleBySlug = /* GraphQL */ `
  query CollectibleBySlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelCollectibleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    collectibleBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        userID
        type
        category
        genre
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaDescription
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableGenre
        _version
        _deleted
        _lastChangedAt
        owner
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const searchCollectibles = /* GraphQL */ `
  query SearchCollectibles(
    $filter: SearchableCollectibleFilterInput
    $sort: SearchableCollectibleSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCollectibles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        accountID
        userID
        type
        category
        genre
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaDescription
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableGenre
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const syncCollectibles = /* GraphQL */ `
  query SyncCollectibles(
    $filter: ModelCollectibleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCollectibles(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        accountID
        userID
        type
        category
        genre
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaDescription
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableGenre
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const searchCollections = /* GraphQL */ `
  query SearchCollections(
    $filter: SearchableCollectionsFilterInput
    $sort: SearchableCollectionsSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCollections(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        collectionType
        avatarUrl
        coverUrl
        logoUrl
        createdAt
        updatedAt
        searchableCollectionType
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      total
    }
  }
`;
export const getFeaturedArtist = /* GraphQL */ `
  query GetFeaturedArtist($id: ID!) {
    getFeaturedArtist(id: $id) {
      id
      artistName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listFeaturedArtists = /* GraphQL */ `
  query ListFeaturedArtists(
    $filter: ModelFeaturedArtistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeaturedArtists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        artistName
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFeaturedArtists = /* GraphQL */ `
  query SyncFeaturedArtists(
    $filter: ModelFeaturedArtistFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFeaturedArtists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        artistName
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getFeaturedArtistCollectible = /* GraphQL */ `
  query GetFeaturedArtistCollectible($id: ID!) {
    getFeaturedArtistCollectible(id: $id) {
      id
      collectibleID
      featuredArtistID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaDescription
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableGenre
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      featuredArtist {
        id
        artistName
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const listFeaturedArtistCollectibles = /* GraphQL */ `
  query ListFeaturedArtistCollectibles(
    $filter: ModelFeaturedArtistCollectibleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeaturedArtistCollectibles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        collectibleID
        featuredArtistID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        featuredArtist {
          id
          artistName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const featuredArtistsCollectibleByCollectibleAndArtists = /* GraphQL */ `
  query FeaturedArtistsCollectibleByCollectibleAndArtists(
    $collectibleID: ID
    $featuredArtistID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeaturedArtistCollectibleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    featuredArtistsCollectibleByCollectibleAndArtists(
      collectibleID: $collectibleID
      featuredArtistID: $featuredArtistID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        collectibleID
        featuredArtistID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        featuredArtist {
          id
          artistName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFeaturedArtistCollectibles = /* GraphQL */ `
  query SyncFeaturedArtistCollectibles(
    $filter: ModelFeaturedArtistCollectibleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFeaturedArtistCollectibles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        collectibleID
        featuredArtistID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        featuredArtist {
          id
          artistName
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getCollection = /* GraphQL */ `
  query GetCollection($id: ID!) {
    getCollection(id: $id) {
      id
      userID
      owner
      accountID
      name
      slug
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      collectibles {
        items {
          id
          collectionID
          collectibleID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      currentUser {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listCollections = /* GraphQL */ `
  query ListCollections($filter: ModelCollectionFilterInput, $limit: Int, $nextToken: String) {
    listCollections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        owner
        accountID
        name
        slug
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        collectibles {
          nextToken
          startedAt
        }
        currentUser {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const collectionByUserAndName = /* GraphQL */ `
  query CollectionByUserAndName(
    $userID: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    collectionByUserAndName(
      userID: $userID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        owner
        accountID
        name
        slug
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        collectibles {
          nextToken
          startedAt
        }
        currentUser {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const collectionByAccount = /* GraphQL */ `
  query CollectionByAccount(
    $accountID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    collectionByAccount(
      accountID: $accountID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        owner
        accountID
        name
        slug
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        collectibles {
          nextToken
          startedAt
        }
        currentUser {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCollections = /* GraphQL */ `
  query SyncCollections(
    $filter: ModelCollectionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCollections(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        userID
        owner
        accountID
        name
        slug
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        collectibles {
          nextToken
          startedAt
        }
        currentUser {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getCollectionCollectible = /* GraphQL */ `
  query GetCollectionCollectible($id: ID!) {
    getCollectionCollectible(id: $id) {
      id
      collectionID
      collectibleID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaDescription
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableGenre
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      collection {
        id
        userID
        owner
        accountID
        name
        slug
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        collectibles {
          nextToken
          startedAt
        }
        currentUser {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      owner
    }
  }
`;
export const listCollectionCollectibles = /* GraphQL */ `
  query ListCollectionCollectibles(
    $filter: ModelCollectionCollectibleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCollectionCollectibles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        collectionID
        collectibleID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        collection {
          id
          userID
          owner
          accountID
          name
          slug
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCollectionCollectibles = /* GraphQL */ `
  query SyncCollectionCollectibles(
    $filter: ModelCollectionCollectibleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCollectionCollectibles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        collectionID
        collectibleID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        collection {
          id
          userID
          owner
          accountID
          name
          slug
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getExchange = /* GraphQL */ `
  query GetExchange($id: ID!) {
    getExchange(id: $id) {
      id
      exchangeType
      creatorID
      currentOwnerID
      collectibleID
      collectibleReleaseIndex
      releaseID
      serialIndex
      listingType
      releaseDate
      isReleased
      sellingPrice
      offerPrice
      startingPrice
      lastPrice
      auctionMinimumAllowedBidPrice
      isMinimumOfferVisible
      isAutoAcceptActive
      bidPrice
      maxBidPrice
      expiryDate
      isSold
      patronID
      paymentProvider
      bitpayPaymentRequested
      paymentRequestExpiredAt
      paymentReferenceID
      checkoutReceiptID
      createdAt
      updatedAt
      userID
      currentListingType
      lastPurchasedAt
      slug
      tierType
      releaseCount
      previousOwnerID
      previousPrice
      finalPrice
      currentPrice
      previousExchangeID
      parentExchangeID
      bitpayReceiptID
      _version
      _deleted
      _lastChangedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaDescription
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableGenre
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      owner
      previousExchange {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        patronID
        paymentProvider
        bitpayPaymentRequested
        paymentRequestExpiredAt
        paymentReferenceID
        checkoutReceiptID
        createdAt
        updatedAt
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      parentExchange {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        patronID
        paymentProvider
        bitpayPaymentRequested
        paymentRequestExpiredAt
        paymentReferenceID
        checkoutReceiptID
        createdAt
        updatedAt
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      release {
        id
        collectibleReleaseIndex
        slug
        tierType
        serialIndex
        releaseCount
        releaseDate
        collectibleID
        userID
        previousOwnerID
        previousPrice
        currentPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        finalPrice
        currentListingType
        lastPurchasedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      creator {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      patron {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      previousOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const searchExchanges = /* GraphQL */ `
  query SearchExchanges(
    $filter: SearchableExchangeFilterInput
    $sort: SearchableExchangeSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchExchanges(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        patronID
        paymentProvider
        bitpayPaymentRequested
        paymentRequestExpiredAt
        paymentReferenceID
        checkoutReceiptID
        createdAt
        updatedAt
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const listExchanges = /* GraphQL */ `
  query ListExchanges($filter: ModelExchangeFilterInput, $limit: Int, $nextToken: String) {
    listExchanges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        patronID
        paymentProvider
        bitpayPaymentRequested
        paymentRequestExpiredAt
        paymentReferenceID
        checkoutReceiptID
        createdAt
        updatedAt
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const exchangeByPatronAndExpiryDate = /* GraphQL */ `
  query ExchangeByPatronAndExpiryDate(
    $patronID: ID
    $expiryDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    exchangeByPatronAndExpiryDate(
      patronID: $patronID
      expiryDate: $expiryDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        patronID
        paymentProvider
        bitpayPaymentRequested
        paymentRequestExpiredAt
        paymentReferenceID
        checkoutReceiptID
        createdAt
        updatedAt
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const exchangeByOwnerAndExpiryDate = /* GraphQL */ `
  query ExchangeByOwnerAndExpiryDate(
    $currentOwnerID: ID
    $expiryDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    exchangeByOwnerAndExpiryDate(
      currentOwnerID: $currentOwnerID
      expiryDate: $expiryDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        patronID
        paymentProvider
        bitpayPaymentRequested
        paymentRequestExpiredAt
        paymentReferenceID
        checkoutReceiptID
        createdAt
        updatedAt
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const exchangeByReleaseAndExpiryDate = /* GraphQL */ `
  query ExchangeByReleaseAndExpiryDate(
    $releaseID: ID
    $expiryDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    exchangeByReleaseAndExpiryDate(
      releaseID: $releaseID
      expiryDate: $expiryDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        patronID
        paymentProvider
        bitpayPaymentRequested
        paymentRequestExpiredAt
        paymentReferenceID
        checkoutReceiptID
        createdAt
        updatedAt
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const exchangeByReleaseAndExchangeType = /* GraphQL */ `
  query ExchangeByReleaseAndExchangeType(
    $releaseID: ID
    $exchangeType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    exchangeByReleaseAndExchangeType(
      releaseID: $releaseID
      exchangeType: $exchangeType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        patronID
        paymentProvider
        bitpayPaymentRequested
        paymentRequestExpiredAt
        paymentReferenceID
        checkoutReceiptID
        createdAt
        updatedAt
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const listingTypeByIsSold = /* GraphQL */ `
  query ListingTypeByIsSold(
    $listingType: ListingType
    $isSold: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listingTypeByIsSold(
      listingType: $listingType
      isSold: $isSold
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        patronID
        paymentProvider
        bitpayPaymentRequested
        paymentRequestExpiredAt
        paymentReferenceID
        checkoutReceiptID
        createdAt
        updatedAt
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const exchangeByReleaseAndIsSold = /* GraphQL */ `
  query ExchangeByReleaseAndIsSold(
    $releaseID: ID
    $isSold: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    exchangeByReleaseAndIsSold(
      releaseID: $releaseID
      isSold: $isSold
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        patronID
        paymentProvider
        bitpayPaymentRequested
        paymentRequestExpiredAt
        paymentReferenceID
        checkoutReceiptID
        createdAt
        updatedAt
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const exchangeByCollectibleAndStartingPrice = /* GraphQL */ `
  query ExchangeByCollectibleAndStartingPrice(
    $collectibleID: ID
    $startingPrice: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    exchangeByCollectibleAndStartingPrice(
      collectibleID: $collectibleID
      startingPrice: $startingPrice
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        patronID
        paymentProvider
        bitpayPaymentRequested
        paymentRequestExpiredAt
        paymentReferenceID
        checkoutReceiptID
        createdAt
        updatedAt
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const exchangeByCollectibleAndPreviousOwner = /* GraphQL */ `
  query ExchangeByCollectibleAndPreviousOwner(
    $collectibleID: ID
    $previousOwnerID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    exchangeByCollectibleAndPreviousOwner(
      collectibleID: $collectibleID
      previousOwnerID: $previousOwnerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        patronID
        paymentProvider
        bitpayPaymentRequested
        paymentRequestExpiredAt
        paymentReferenceID
        checkoutReceiptID
        createdAt
        updatedAt
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const exchangeByCollectibleAndCurrentOwner = /* GraphQL */ `
  query ExchangeByCollectibleAndCurrentOwner(
    $collectibleID: ID
    $currentOwnerID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    exchangeByCollectibleAndCurrentOwner(
      collectibleID: $collectibleID
      currentOwnerID: $currentOwnerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        patronID
        paymentProvider
        bitpayPaymentRequested
        paymentRequestExpiredAt
        paymentReferenceID
        checkoutReceiptID
        createdAt
        updatedAt
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const exchangeByExchangeTypeAndIsReleasedAndReleaseDate = /* GraphQL */ `
  query ExchangeByExchangeTypeAndIsReleasedAndReleaseDate(
    $exchangeType: ExchangeType
    $isReleasedReleaseDate: ModelExchangeByExchangeTypeAndIsReleasedAndReleaseDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    exchangeByExchangeTypeAndIsReleasedAndReleaseDate(
      exchangeType: $exchangeType
      isReleasedReleaseDate: $isReleasedReleaseDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        patronID
        paymentProvider
        bitpayPaymentRequested
        paymentRequestExpiredAt
        paymentReferenceID
        checkoutReceiptID
        createdAt
        updatedAt
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const exchangeByOwnerAndExchangeType = /* GraphQL */ `
  query ExchangeByOwnerAndExchangeType(
    $currentOwnerID: ID
    $exchangeType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    exchangeByOwnerAndExchangeType(
      currentOwnerID: $currentOwnerID
      exchangeType: $exchangeType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        patronID
        paymentProvider
        bitpayPaymentRequested
        paymentRequestExpiredAt
        paymentReferenceID
        checkoutReceiptID
        createdAt
        updatedAt
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const exchangeByPreviousOwnerAndExchangeType = /* GraphQL */ `
  query ExchangeByPreviousOwnerAndExchangeType(
    $previousOwnerID: ID
    $exchangeType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    exchangeByPreviousOwnerAndExchangeType(
      previousOwnerID: $previousOwnerID
      exchangeType: $exchangeType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        patronID
        paymentProvider
        bitpayPaymentRequested
        paymentRequestExpiredAt
        paymentReferenceID
        checkoutReceiptID
        createdAt
        updatedAt
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          lowestPrice
          highestPrice
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const exchangeByPatronAndExchangeType = /* GraphQL */ `
  query ExchangeByPatronAndExchangeType(
    $patronID: ID
    $exchangeType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    exchangeByPatronAndExchangeType(
      patronID: $patronID
      exchangeType: $exchangeType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        patronID
        paymentProvider
        bitpayPaymentRequested
        paymentRequestExpiredAt
        paymentReferenceID
        checkoutReceiptID
        createdAt
        updatedAt
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          lowestPrice
          highestPrice
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncExchanges = /* GraphQL */ `
  query SyncExchanges(
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncExchanges(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        releaseID
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        patronID
        paymentProvider
        bitpayPaymentRequested
        paymentRequestExpiredAt
        paymentReferenceID
        checkoutReceiptID
        createdAt
        updatedAt
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          releaseID
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        release {
          id
          collectibleReleaseIndex
          slug
          tierType
          serialIndex
          releaseCount
          releaseDate
          collectibleID
          userID
          previousOwnerID
          previousPrice
          currentPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          finalPrice
          currentListingType
          lastPurchasedAt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getFollower = /* GraphQL */ `
  query GetFollower($followeeID: ID!, $followerID: ID!) {
    getFollower(followeeID: $followeeID, followerID: $followerID) {
      followeeID
      followerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFollowers = /* GraphQL */ `
  query ListFollowers(
    $followeeID: ID
    $followerID: ModelIDKeyConditionInput
    $filter: ModelFollowerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFollowers(
      followeeID: $followeeID
      followerID: $followerID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        followeeID
        followerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFollowers = /* GraphQL */ `
  query SyncFollowers(
    $filter: ModelFollowerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFollowers(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        followeeID
        followerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      userID
      type
      event
      read
      activityID
      lambdaEventID
      lambdaEventName
      createdAt
      updatedAt
      searchableRead
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications($filter: ModelNotificationFilterInput, $limit: Int, $nextToken: String) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        type
        event
        read
        activityID
        lambdaEventID
        lambdaEventName
        createdAt
        updatedAt
        searchableRead
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const notificationByRead = /* GraphQL */ `
  query NotificationByRead(
    $read: CustomBooleanType
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationByRead(
      read: $read
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        type
        event
        read
        activityID
        lambdaEventID
        lambdaEventName
        createdAt
        updatedAt
        searchableRead
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const notificationByLambdaEventName = /* GraphQL */ `
  query NotificationByLambdaEventName(
    $lambdaEventName: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationByLambdaEventName(
      lambdaEventName: $lambdaEventName
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        type
        event
        read
        activityID
        lambdaEventID
        lambdaEventName
        createdAt
        updatedAt
        searchableRead
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const notificationByUserAndRead = /* GraphQL */ `
  query NotificationByUserAndRead(
    $userID: ID
    $read: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationByUserAndRead(
      userID: $userID
      read: $read
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        type
        event
        read
        activityID
        lambdaEventID
        lambdaEventName
        createdAt
        updatedAt
        searchableRead
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const notificationByUserAndTypeAndRead = /* GraphQL */ `
  query NotificationByUserAndTypeAndRead(
    $userID: ID
    $typeRead: ModelNotificationByUserAndTypeAndReadCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationByUserAndTypeAndRead(
      userID: $userID
      typeRead: $typeRead
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        type
        event
        read
        activityID
        lambdaEventID
        lambdaEventName
        createdAt
        updatedAt
        searchableRead
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const searchNotifications = /* GraphQL */ `
  query SearchNotifications(
    $filter: SearchableNotificationFilterInput
    $sort: SearchableNotificationSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchNotifications(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        userID
        type
        event
        read
        activityID
        lambdaEventID
        lambdaEventName
        createdAt
        updatedAt
        searchableRead
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
    }
  }
`;
export const syncNotifications = /* GraphQL */ `
  query SyncNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotifications(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        userID
        type
        event
        read
        activityID
        lambdaEventID
        lambdaEventName
        createdAt
        updatedAt
        searchableRead
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getNotificationSubscription = /* GraphQL */ `
  query GetNotificationSubscription($userID: ID!, $type: NotificationType!) {
    getNotificationSubscription(userID: $userID, type: $type) {
      userID
      type
      inApp
      sms
      email
      push
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listNotificationSubscriptions = /* GraphQL */ `
  query ListNotificationSubscriptions(
    $userID: ID
    $type: ModelStringKeyConditionInput
    $filter: ModelNotificationSubscriptionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNotificationSubscriptions(
      userID: $userID
      type: $type
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userID
        type
        inApp
        sms
        email
        push
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncNotificationSubscriptions = /* GraphQL */ `
  query SyncNotificationSubscriptions(
    $filter: ModelNotificationSubscriptionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotificationSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        userID
        type
        inApp
        sms
        email
        push
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRelease = /* GraphQL */ `
  query GetRelease($id: ID!) {
    getRelease(id: $id) {
      id
      collectibleReleaseIndex
      slug
      tierType
      serialIndex
      releaseCount
      releaseDate
      collectibleID
      userID
      previousOwnerID
      previousPrice
      currentPrice
      isMinimumOfferVisible
      isAutoAcceptActive
      finalPrice
      currentListingType
      lastPurchasedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        metaDescription
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableGenre
        _version
        _deleted
        _lastChangedAt
        owner
        collections {
          nextToken
          startedAt
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      previousOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listReleases = /* GraphQL */ `
  query ListReleases($filter: ModelReleaseFilterInput, $limit: Int, $nextToken: String) {
    listReleases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        collectibleReleaseIndex
        slug
        tierType
        serialIndex
        releaseCount
        releaseDate
        collectibleID
        userID
        previousOwnerID
        previousPrice
        currentPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        finalPrice
        currentListingType
        lastPurchasedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const releaseBySlug = /* GraphQL */ `
  query ReleaseBySlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelReleaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    releaseBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        collectibleReleaseIndex
        slug
        tierType
        serialIndex
        releaseCount
        releaseDate
        collectibleID
        userID
        previousOwnerID
        previousPrice
        currentPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        finalPrice
        currentListingType
        lastPurchasedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const releaseByCollectibleID = /* GraphQL */ `
  query ReleaseByCollectibleID(
    $collectibleID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReleaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    releaseByCollectibleID(
      collectibleID: $collectibleID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        collectibleReleaseIndex
        slug
        tierType
        serialIndex
        releaseCount
        releaseDate
        collectibleID
        userID
        previousOwnerID
        previousPrice
        currentPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        finalPrice
        currentListingType
        lastPurchasedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const releaseByCollectibleAndUser = /* GraphQL */ `
  query ReleaseByCollectibleAndUser(
    $collectibleID: ID
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReleaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    releaseByCollectibleAndUser(
      collectibleID: $collectibleID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        collectibleReleaseIndex
        slug
        tierType
        serialIndex
        releaseCount
        releaseDate
        collectibleID
        userID
        previousOwnerID
        previousPrice
        currentPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        finalPrice
        currentListingType
        lastPurchasedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const releaseByUser = /* GraphQL */ `
  query ReleaseByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReleaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    releaseByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        collectibleReleaseIndex
        slug
        tierType
        serialIndex
        releaseCount
        releaseDate
        collectibleID
        userID
        previousOwnerID
        previousPrice
        currentPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        finalPrice
        currentListingType
        lastPurchasedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const releaseByCollectibleIDAndSerialIndex = /* GraphQL */ `
  query ReleaseByCollectibleIDAndSerialIndex(
    $collectibleID: ID
    $serialIndex: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReleaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    releaseByCollectibleIDAndSerialIndex(
      collectibleID: $collectibleID
      serialIndex: $serialIndex
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        collectibleReleaseIndex
        slug
        tierType
        serialIndex
        releaseCount
        releaseDate
        collectibleID
        userID
        previousOwnerID
        previousPrice
        currentPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        finalPrice
        currentListingType
        lastPurchasedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const releaseByReleaseDate = /* GraphQL */ `
  query ReleaseByReleaseDate(
    $releaseDate: AWSDateTime
    $sortDirection: ModelSortDirection
    $filter: ModelReleaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    releaseByReleaseDate(
      releaseDate: $releaseDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        collectibleReleaseIndex
        slug
        tierType
        serialIndex
        releaseCount
        releaseDate
        collectibleID
        userID
        previousOwnerID
        previousPrice
        currentPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        finalPrice
        currentListingType
        lastPurchasedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncReleases = /* GraphQL */ `
  query SyncReleases(
    $filter: ModelReleaseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReleases(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        collectibleReleaseIndex
        slug
        tierType
        serialIndex
        releaseCount
        releaseDate
        collectibleID
        userID
        previousOwnerID
        previousPrice
        currentPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        finalPrice
        currentListingType
        lastPurchasedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserMedia = /* GraphQL */ `
  query GetUserMedia($id: ID!) {
    getUserMedia(id: $id) {
      id
      avatarUrl
      avatarVideoUrl
      coverUrl
      fanclubLogoUrl
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUserMedias = /* GraphQL */ `
  query ListUserMedias($filter: ModelUserMediaFilterInput, $limit: Int, $nextToken: String) {
    listUserMedias(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        avatarUrl
        avatarVideoUrl
        coverUrl
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserMedias = /* GraphQL */ `
  query SyncUserMedias(
    $filter: ModelUserMediaFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserMedias(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        avatarUrl
        avatarVideoUrl
        coverUrl
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserStatistics = /* GraphQL */ `
  query GetUserStatistics($id: ID!) {
    getUserStatistics(id: $id) {
      id
      followerCount
      followingCount
      collectibleCreatedCount
      collectibleCollectedCount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUserStatistics = /* GraphQL */ `
  query ListUserStatistics(
    $filter: ModelUserStatisticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserStatistics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        followerCount
        followingCount
        collectibleCreatedCount
        collectibleCollectedCount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserStatistics = /* GraphQL */ `
  query SyncUserStatistics(
    $filter: ModelUserStatisticsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserStatistics(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        followerCount
        followingCount
        collectibleCreatedCount
        collectibleCollectedCount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      owner
      accountID
      editors
      email
      username
      firstName
      lastName
      displayName
      type
      bio
      status
      links {
        facebook
        youtube
        instagram
        twitter
        spotify
        soundcloud
        email
        website
        tiktok
        snapchat
        appleMusic
        clubhouse
      }
      notifications {
        outbid {
          inApp
          email
        }
        bidResponses {
          inApp
          email
        }
        offerResponses {
          inApp
          email
        }
        successfulPurchase {
          inApp
          email
        }
        collectibleSold {
          inApp
          email
        }
        collectibleMinted {
          inApp
          email
        }
        collectiblePrinted {
          inApp
          email
        }
        offerReceived {
          inApp
          email
        }
        bidReceived {
          inApp
          email
        }
        newCollectibles {
          inApp
          email
        }
        collectibleRelease {
          inApp
          email
        }
        fansincNewsletter {
          inApp
          email
        }
      }
      tags
      verified
      fanProfile
      creatorCategory
      emailVerified
      joinDate
      isHidden
      profileTemplate
      address {
        recipientName
        line1
        country
        stateName
        city
        postCode
        phone
      }
      shippingAddress {
        recipientName
        line1
        country
        stateName
        city
        postCode
        phone
      }
      walletAddresses
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      media {
        id
        avatarUrl
        avatarVideoUrl
        coverUrl
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      statistics {
        id
        followerCount
        followingCount
        collectibleCreatedCount
        collectibleCollectedCount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      managers {
        items {
          id
          profileID
          managerID
          accountID
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          editors
        }
        nextToken
        startedAt
      }
      profileManaged {
        items {
          id
          profileID
          managerID
          accountID
          firstName
          lastName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          editors
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const userByUsername = /* GraphQL */ `
  query UserByUsername(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const userByAccountAndVerified = /* GraphQL */ `
  query UserByAccountAndVerified(
    $accountID: ID
    $verified: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByAccountAndVerified(
      accountID: $accountID
      verified: $verified
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const userByOwner = /* GraphQL */ `
  query UserByOwner(
    $owner: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const userByVerifiedJoinDate = /* GraphQL */ `
  query UserByVerifiedJoinDate(
    $verified: CustomBooleanType
    $joinDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByVerifiedJoinDate(
      verified: $verified
      joinDate: $joinDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const userByFanprofileUsername = /* GraphQL */ `
  query UserByFanprofileUsername(
    $fanProfile: CustomBooleanType
    $username: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByFanprofileUsername(
      fanProfile: $fanProfile
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: SearchableUserSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUsers(filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken, from: $from) {
      items {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        profileTemplate
        address {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        shippingAddress {
          recipientName
          line1
          country
          stateName
          city
          postCode
          phone
        }
        walletAddresses
        fee {
          salesFee
          royaltyFee
        }
        discourseData {
          userID
          username
          category
          channels
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getProfileManager = /* GraphQL */ `
  query GetProfileManager($id: ID!) {
    getProfileManager(id: $id) {
      id
      profileID
      managerID
      accountID
      firstName
      lastName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      manager {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      profile {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        bio
        links {
          facebook
          youtube
          instagram
          twitter
          spotify
          soundcloud
          email
          website
          tiktok
          snapchat
          appleMusic
          clubhouse
        }
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        isHidden
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        media {
          id
          avatarUrl
          avatarVideoUrl
          coverUrl
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        statistics {
          id
          followerCount
          followingCount
          collectibleCreatedCount
          collectibleCollectedCount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        managers {
          nextToken
          startedAt
        }
        profileManaged {
          nextToken
          startedAt
        }
      }
      owner
      editors
    }
  }
`;
export const listProfileManagers = /* GraphQL */ `
  query ListProfileManagers(
    $filter: ModelProfileManagerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfileManagers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        profileID
        managerID
        accountID
        firstName
        lastName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        manager {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        profile {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        owner
        editors
      }
      nextToken
      startedAt
    }
  }
`;
export const profileManagerByAccountAndManager = /* GraphQL */ `
  query ProfileManagerByAccountAndManager(
    $accountID: ID
    $managerID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProfileManagerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    profileManagerByAccountAndManager(
      accountID: $accountID
      managerID: $managerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        profileID
        managerID
        accountID
        firstName
        lastName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        manager {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        profile {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        owner
        editors
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProfileManagers = /* GraphQL */ `
  query SyncProfileManagers(
    $filter: ModelProfileManagerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProfileManagers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        profileID
        managerID
        accountID
        firstName
        lastName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        manager {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        profile {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        owner
        editors
      }
      nextToken
      startedAt
    }
  }
`;

export const listChannels = /* GraphQL */ `
  query ListChannels($filter: ModelChannelFilterInput, $limit: Int, $nextToken: String) {
    listChannels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        owner
        removable
        members
        collectibles
        lastActivityAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const channelByOwnerAndCreatedAt = /* GraphQL */ `
  query ChannelByOwnerAndCreatedAt(
    $owner: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChannelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    channelByOwnerAndCreatedAt(
      owner: $owner
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        owner
        removable
        members
        collectibles
        lastActivityAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const listGalleryExperiences = /* GraphQL */ `
  query ListGalleryExperiences(
    $filter: ModelGalleryExperienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGalleryExperiences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        orientation
        heading
        description
        qrText
        logoPosition
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
