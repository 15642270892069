import { Select, Form, Button } from 'antd';
import { Helmet } from 'react-helmet';
import { DashboardContent } from '@components';
import { useAllCollectibles } from '@libs/hooks/collectible';

const { Option } = Select;

function DummyDeleteCollectible() {
  const { data: collectibles, onDelete, deleteLoading } = useAllCollectibles();

  const onSubmit = async (values) => {
    const payload = {
      ...values,
      item: 'collectible'
    };
    await onDelete(payload);
  };

  return (
    <>
      <DashboardContent title="[Temporary] Delete Collectible">
        <Form onFinish={onSubmit} layout="vertical" className="admin-form">
          <Form.Item
            className="delete-collectible-input"
            label="Select Collectibles"
            name="id"
            rules={[{ required: true, message: 'Collectible is required.' }]}
          >
            <Select
              placeholder="Select collectible to approve"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {collectibles?.map((c, i) => {
                const option = `${c.title} by ${c.creator.firstName} ${c.creator.lastName} (${c.id} - ${c.status})`;
                return (
                  <Option key={`collectible-${i}`} value={c.id}>
                    {option}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={deleteLoading}>
              Delete
            </Button>
          </Form.Item>
        </Form>
      </DashboardContent>
      <Helmet>
        <title>Delete Collectible</title>
      </Helmet>
    </>
  );
}

export default DummyDeleteCollectible;
