import { useState } from 'react';
import { Avatar } from '@components';
import { Dropdown, Menu, Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { formatDistanceToNowStrict } from 'date-fns';
import { MoreOutlined } from '@ant-design/icons';
import { useReplyCommentCollectible } from '@libs/hooks/discourse';
import { DeleteOutlined } from '@ant-design/icons';

function ChildComment({ user, setShowCreateComment, comment, removeComment, deleteCountComment }) {
  const { t } = useTranslation();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { deleteComment, deleteLoading } = useReplyCommentCollectible({
    commentID: comment.id,
    collectibleID: comment.channelID
  });
  const commentCreator = comment?.user;

  const handleDeleteComment = async () => {
    setShowDeleteModal(false);
    deleteCountComment();
    removeComment(comment?.id);
    await deleteComment(user?.id);
  };

  return (
    <>
      <div className="comment-child">
        <Avatar size={36} user={commentCreator} />
        <div className="comment-padding">
          <div className="comment-child-wrapper">
            <div className="comment-child-wrapper-header">
              <div className="comment-child-wrapper-header-left">
                {commentCreator?.displayName ||
                  commentCreator?.firstName + ' ' + commentCreator?.lastName}
                <span className="comment-child-wrapper-header-left-time">
                  {' '}
                  {formatDistanceToNowStrict(new Date(comment?.createdAt), {
                    addSuffix: true
                  })}
                </span>
              </div>
              {comment?.id && (
                <div className="comment-child-wrapper-header-right">
                  <Dropdown
                    placement="bottomRight"
                    overlay={
                      <Menu className="comment-child-wrapper-header-right-menu">
                        <Menu.Item
                          onClick={() => setShowCreateComment(true)}
                          className="comment-wrapper-header-right-menu-reply"
                          key="1"
                        >
                          <div>{t('components.post.reply')}</div>
                        </Menu.Item>
                        <Menu.Item
                          onClick={() => setShowDeleteModal(true)}
                          className="comment-wrapper-header-right-menu-delete"
                          key="2"
                        >
                          <div>{t('components.post.delete')}</div>
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <MoreOutlined style={{ fontSize: '13px' }} />
                  </Dropdown>
                </div>
              )}
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: comment?.content
              }}
              className="comment-child-wrapper-body"
            ></div>
          </div>
        </div>
      </div>
      <Modal
        className="comment-delete-modal"
        visible={showDeleteModal}
        footer={null}
        onCancel={() => setShowDeleteModal(false)}
        width={650}
      >
        <div className="comment-delete-modal-container">
          <div className="comment-delete-modal-container-header">
            <DeleteOutlined style={{ fontSize: '5rem', color: '#D6222A' }} />
            <div className="comment-delete-modal-container-header-title">
              {t('components.post.comment.modal.delete.title')}
            </div>
          </div>
          <div className="comment-delete-modal-container-header-text">
            {t('components.post.comment.modal.delete.description')}
          </div>
        </div>
        <div className="comment-delete-modal-container-footer">
          <Button
            style={{ backgroundColor: '#D6222A', border: 'none' }}
            className="comment-delete-modal-container-footer-delete"
            type="primary"
            onClick={() => handleDeleteComment()}
            loading={deleteLoading}
          >
            {t('components.post.comment.modal.delete.delete')}
          </Button>
          <Button
            onClick={() => setShowDeleteModal(false)}
            className="comment-delete-modal-container-footer-cancel"
            type="secondary"
          >
            {t('components.post.comment.modal.delete.cancel')}
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default ChildComment;
