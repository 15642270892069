import { notification } from 'antd';

const useToast = () => {
  return (message, severity = 'info') => {
    const availableSeverity = ['success', 'error', 'warn', 'info'];
    if (!availableSeverity.includes(severity)) {
      throw Error('Toast severity is not supported.');
    }

    const notify = notification[severity];
    const title = {
      success: 'Success',
      warn: 'Warning',
      error: 'Error',
      info: 'Info'
    };

    notify({ message: title[severity], description: message });
  };
};

export default useToast;
