export const faqs = [
  {
    title: 'When Will Fans.Inc Launch?',
    alias: 'faq-1',
    description:
      '<p>Fans.Inc will launch to the public in 2022. We’ll send you an email to let you know once you’ve signed up to the Fans.Inc waitlist.</p>'
  },
  {
    title: 'Why Should I Sign Up Early to Fans.Inc?',
    alias: 'faq-2',
    description:
      '<p>By joining Fans.Inc now, you’ll be among the first invited to access the platform, receive updates about Fans.Inc including the official launch date, and be the first to know about upcoming collectible releases.</p>'
  },
  {
    title: 'Is It Free to Join Fans.Inc?',
    alias: 'faq-3',
    description: "<p>Yes, it's free to be a member of Fans.Inc</p>"
  },
  {
    title: 'What is a digital collectible?',
    alias: 'faq-4',
    description:
      '<p>A digital collectible is basically a unique digital asset whose ownership is authenticated and tracked.  Digital collectibles can range from albums to artworks to footage of live events and more. And while there’s no stopping anyone from downloading or replicating the content, there can only be one true owner.</p><p>All digital collectibles released on Fans.Inc will be official digital collectibles. The intellectual property owner and/or rights holder to the content have authorised for that content to be used as a digital collectible exclusively on Fans.Inc, and have warrantied to Fans that they will not release such content elsewhere as a digital collectible. </p>'
  },
  {
    title: 'What type of creator do I need to be to be approved for the platform? ',
    alias: 'faq-5',
    description:
      '<p>Only the world’s most iconic creators and brands are invited to release digital collectibles on Fans.Inc.</p><p>To enquire about becoming a creator on Fans.Inc on behalf of yourself or a client, contact the Fans.Inc Creator Management team on creator@fans.inc for assistance.</p>'
  }
];
