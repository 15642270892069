import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Storage } from 'aws-amplify';
import { nanoid } from 'nanoid';

import { resizeImage } from '@graphql/mutations';
import useToast from '@libs/utils/toast';
import gql from '@libs/utils/gql';

export const useUploader = (callback) => {
  const { t } = useTranslation();
  const inputRef = useRef();
  const toast = useToast();
  const [s3, setS3] = useState();
  const [fileUrl, setFileUrl] = useState();
  const [preview, setPreview] = useState();
  const [loading, setLoading] = useState();
  const [validationError, setValidationError] = useState();

  const resValidation = (file, minWidth, minHeight) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = () => {
        reader.abort();
        reject(new Error('Problem parsing input file.'));
      };

      reader.readAsDataURL(file);
      reader.addEventListener('load', (event) => {
        const _loadedImageUrl = event.target.result;
        const image = document.createElement('img');
        image.src = _loadedImageUrl;
        image.addEventListener('load', () => {
          const { width, height } = image;
          resolve(width >= minWidth && height >= minHeight);
        });
      });
    });
  };

  const beforeUpload = async (file, type, onValidationError) => {
    if (!file) {
      return false;
    }

    const fileFormat = file.name.split('.').pop();
    const isJFIF = fileFormat === 'jfif';

    let allowed = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml', 'image/png'];
    let isAllowed = allowed.includes(file.type) && !isJFIF;

    if (type === 'video') {
      isAllowed = file.type.includes('video');
    }

    if (!isAllowed) {
      if (onValidationError) {
        onValidationError('Invalid file format.');
      }
      setValidationError('Invalid file format.');
      return isAllowed;
    }

    if (type !== 'video') {
      let minWidth = 400,
        minHeight = 40,
        resValidationError = t('userSettings.fields.cover.size');
      if (type === 'avatar') {
        minWidth = 128;
        minHeight = 128;
        resValidationError = t('userSettings.fields.avatar.size');
      }
      const isResValid = await resValidation(file, minWidth, minHeight);
      if (!isResValid) {
        if (onValidationError) {
          onValidationError(resValidationError);
        }
        setValidationError(resValidationError);
        return isResValid;
      }
    }

    const isLt5M = file.size / 1024 / 1024 < 100;
    if (!isLt5M) {
      if (onValidationError) {
        onValidationError('The file exceeds the maximum file size of 100 MB.');
      }
      setValidationError('The file exceeds the maximum file size of 100 MB.');
      return isLt5M;
    }

    return isAllowed && isLt5M;
  };

  const onUpload = async (e, type, onValidationError, creatorID) => {
    setLoading(true);
    setValidationError('');
    const file = e.target.files[0];

    try {
      const validation = await beforeUpload(file, type, onValidationError);
      if (!validation) {
        return;
      }

      // Set preview image in Base64
      const reader = new FileReader();
      reader.onloadend = (e) => {};
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);

      // Upload to S3
      let filename = file.name;
      if (type === 'video') {
        const ext = file.name.split('.').pop();
        filename = `videos/${creatorID}_${nanoid()}.${ext}`;
      }
      const res = await Storage.put(`temps/avatars/${filename}`, file);
      setS3(res);

      const signedURL = await Storage.get(res.key);
      setFileUrl(signedURL);

      if (callback) {
        callback({
          s3: res,
          fileUrl: signedURL
        });
      }
    } catch (error) {
      console.error('Error uploading file: ', error);
      const errorMessage = error.errors[0]?.message;
      toast(errorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  const onDelete = async (fileUrl) => {
    setLoading(true);
    try {
      const remove = await Storage.remove(fileUrl || s3.key);
      setS3({});
      setFileUrl('');
      toast('File Removed', 'success');
      return remove;
    } catch (error) {
      const errorMessage = error.errors[0]?.message;
      toast(errorMessage, 'error');
      console.error('Error removing file: ', error);
    } finally {
      setLoading(false);
    }
  };

  const onClick = () => {
    inputRef.current.click();
  };

  return {
    inputRef,
    preview,
    result: {
      s3,
      fileUrl
    },
    loading,
    validationError,
    onUpload,
    onDelete,
    onClick
  };
};

export const usePreviewImage = (callback) => {
  const inputRef = useRef();
  const toast = useToast();
  const [result, setResult] = useState();
  const [loading, setLoading] = useState();

  const onUpload = async (e) => {
    setLoading(true);
    const file = e.target.files[0];
    try {
      // Set preview image in Base64
      const reader = new FileReader();
      reader.onloadend = (e) => {};
      reader.onloadend = () => {
        setResult(reader.result);
      };
      reader.readAsDataURL(file);

      if (callback) {
        callback(result);
      }
    } catch (error) {
      const errorMessage = error.errors[0]?.message;
      toast(errorMessage, 'error');
      console.error('Error uploading file: ', error);
    } finally {
      setLoading(false);
    }
  };

  const onClick = () => {
    inputRef.current.click();
  };

  return {
    inputRef,
    result,
    loading,
    onUpload,
    onClick
  };
};

export const useImageResizer = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const onResize = async ({ key, width, height, x, y, folder }) => {
    setLoading(true);
    try {
      const payload = {
        input: {
          key,
          width,
          height,
          x,
          y,
          folder
        }
      };

      const resize = await gql(resizeImage, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });

      return resize.data?.resizeImage;
    } catch (error) {
      const errorMessage = error.errors[0]?.message;
      toast(errorMessage, 'error');
      console.error('Error resizing file: ', error);
    } finally {
      setLoading(false);
    }
  };

  return {
    onResize,
    loading
  };
};
