import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import { Banner, Blur, Container, Footer, Navigation, SinglePageContent } from '@components';
import { faqs } from './utils';
import ScrollTo from '@libs/utils/scrollTo';

function Faq() {
  const { t } = useTranslation();
  const onClickMenu = (alias) => {
    ScrollTo(document.getElementById(alias).offsetTop - 20);
  };
  return (
    <div className="single-page">
      <div className="single-page-wrapper">
        <Banner title={t('faq.title')}>
          <Navigation />
        </Banner>
        <div className="single-page-container">
          <Blur className="single-page-first-blur" />
          <Blur className="single-page-second-blur" />
          <Container>
            <Row>
              <Col span={8} className="faq-left">
                <div className="faq-menu">
                  {faqs.map((faq) => (
                    <div onClick={() => onClickMenu(faq.alias)} className="faq-menu__item">
                      {faq.title}
                    </div>
                  ))}
                </div>
              </Col>
              <Col span={24} md={16} className="faq-right">
                {faqs.map((faq) => (
                  <div id={faq.alias}>
                    <SinglePageContent>
                      <h2 className="faq-title">{faq.title}</h2>
                      <div dangerouslySetInnerHTML={{ __html: faq.description }}></div>
                    </SinglePageContent>
                  </div>
                ))}
              </Col>
            </Row>
          </Container>
        </div>
        <Helmet>
          <title>{t('faq.title')}</title>
        </Helmet>
        <Footer />
        <Blur className="single-page-footer-blur" />
      </div>
    </div>
  );
}

export default Faq;
