import { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import gql from '@libs/utils/gql';
import useToast from '@libs/utils/toast';
import { useProfileOrganisation } from '@libs/hooks/organisation';
import { acceptOffer } from '@graphql/mutations'; // eslint-disable-line
import { searchExchanges, exchangeByID } from '@libs/custom-queries/exchange';
import { onProcessPaymentV1 } from '@graphql/subscriptions';
import { useExchangeParams } from '@libs/hooks/exchange/utils';

export const exchangeIntenType = {
  FIXED_PRICE_PURCHASE: 'FIXED_PRICE_PURCHASE',
  MAKE_OFFER: 'MAKE_OFFER',
  PLACE_BID: 'PLACE_BID',
  ACCEPT_OFFER: 'ACCEPT_OFFER',
  CANCEL_OFFER: 'CANCEL_OFFER',
  ACCEPT_BID: 'ACCEPT_BID'
};

export const useExchangeByAccountAndProfiles = (exchangeType = 'OFFER', limit = 10) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { data: profiles } = useProfileOrganisation(true);
  const { query: params, onChangeCollectible, onPaginate } = useExchangeParams(limit, exchangeType);

  const [loading, setLoading] = useState(false);
  const [collectibleID, setCollectibleID] = useState();
  const [mutationLoading, setMutationLoading] = useState(false);
  const [error, setError] = useState();
  const [nextPageToken, setNextPageToken] = useState();
  const [data, setData] = useState([]);

  const getData = async () => {
    setLoading(true);

    try {
      if (params) {
        const { data: result } = await gql(searchExchanges, params);
        if (result?.searchExchanges?.items) {
          setData(result?.searchExchanges?.items);
        }
        if (result?.searchExchanges?.items.length < limit) {
          setNextPageToken();
        } else {
          setNextPageToken(result?.searchExchanges?.nextToken);
        }
      }
    } catch (error) {
      console.error(error);
      setData([]);
    } finally {
      setLoading(false);
    }
  };
  const handleChangeCollectible = (collectibleID) => {
    setData([]);
    onChangeCollectible(collectibleID);
  };

  useEffect(() => {
    getData();
  }, [params]); // eslint-disable-line

  useEffect(() => {
    const payload = {};
    const subscription = gql(onProcessPaymentV1, payload, {
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    }).subscribe({
      next: (_) => {
        if (profiles.length > 0) getData();
      },
      error: (error) => {
        console.warn(error);
        setData([]);
      }
    });
    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (profiles.length > 0) getData();
  }, [profiles.length, collectibleID]); // eslint-disable-line react-hooks/exhaustive-deps

  const onAcceptOffer = useCallback(
    async (exchangeID) => {
      setMutationLoading(true);
      try {
        const { data: res } = await gql(
          acceptOffer,
          {
            input: { exchangeID: exchangeID }
          },
          {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }
        );

        const jsonResp = JSON.parse(res?.acceptOffer);
        console.log(jsonResp);
        const severity = jsonResp?.statusCode < 300 ? 'success' : 'error';
        const toastMsg =
          jsonResp?.statusCode < 300 ? t('offers.accepted.message') : t('offers.accepted.failed');
        toast(toastMsg, severity);
      } catch (error) {
        console.error(error);
        setError(error.message);
        const errorMessage = error.errors[0]?.message;
        toast(errorMessage, 'error');
      } finally {
        setMutationLoading(false);
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return {
    data,
    loading,
    mutationLoading,
    error,
    nextPageToken,
    onAcceptOffer,
    handleChangeCollectible,
    onPaginate,
    onSearchCollectible: (value) => setCollectibleID(value),
    fetch: getData
  };
};

export const useExchangeByID = (id) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [isDataCleared, setIsDataCleared] = useState(false);

  const getData = async (id) => {
    setLoading(true);
    try {
      const { data: res } = await gql(exchangeByID, { id });
      if (res?.getExchange) {
        setData(res.getExchange);
      } else {
        setData(null);
      }
    } catch (error) {
      const errorMessage = error?.message;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      getData(id);
    } else {
      setLoading(false);
    }
  }, [id]); // eslint-disable-line

  const clearData = () => {
    setData(null);
    setIsDataCleared(true);
  };

  if (isDataCleared && data == null && id) {
    getData(id);
    setIsDataCleared(false);
  }

  const refreshData = () => {
    if (id) {
      setLoading(true);
      getData(id);
    } else {
      setLoading(false);
    }
  };

  return {
    loading,
    data,
    clearData,
    refreshData
  };
};
