import { useTranslation } from 'react-i18next';
import { Col, Row, Table, Modal } from 'antd';
import { Helmet } from 'react-helmet';
import { DashboardContent, DisplayDate, Pagination, Price, PrintInvoice } from '@components';
import { useWalletHistory, useWalletFansInc } from '@libs/hooks/wallet';
import { useState, useEffect } from 'react';
import config from '@/config';

const { fansIncWallet } = config;
function Balance() {
  const { t } = useTranslation();
  const {
    data: balance,
    getData: fetchBalance,
    loading: loadingBalance
  } = useWalletFansInc(fansIncWallet);
  const {
    data,
    loading,
    getData: fetchHistory,
    nextToken: nextHistoryToken
  } = useWalletHistory(fansIncWallet);
  const [creatorID, setCreatorID] = useState();
  const [isDetailModalOpen, setIsDetailModalOpen] = useState();
  const [selectedBalance, setSelectedBalance] = useState();

  useEffect(() => {
    setCreatorID(fansIncWallet);
    fetchBalance(fansIncWallet);
    fetchHistory(fansIncWallet);
  }, []); // eslint-disable-line

  const nextPage = (nextToken) => {
    fetchHistory(creatorID, nextToken);
  };

  const columns = [
    {
      title: '#',
      responsive: ['xs'],
      render: (_, row) => (
        <span
          onClick={() => {
            setSelectedBalance(row);
            setIsDetailModalOpen(true);
          }}
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
        >
          {row.invoiceID}
        </span>
      )
    },
    {
      title: '#',
      responsive: ['sm'],
      render: (_, row) => (
        <span
          onClick={() => {
            setSelectedBalance(row);
            setIsDetailModalOpen(true);
          }}
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
        >
          {row.invoiceID}
        </span>
      )
    },
    {
      title: t('balance.table.date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      responsive: ['md'],
      render: (value) => <DisplayDate date={value} />
    },
    {
      title: t('balance.table.description'),
      dataIndex: 'description',
      key: 'description',
      responsive: ['xs', 'sm'],
      render: (value) => <p>{value}</p>
    },
    {
      title: t('balance.table.amount'),
      responsive: ['xs', 'sm'],
      render: (_, row) => (
        <span>
          {row.debit !== '0' ? <Price amount={row.debit} /> : <Price amount={row.credit} />}
        </span>
      )
    },
    {
      title: t('balance.table.balance'),
      dataIndex: 'balance',
      key: 'balance',
      responsive: ['sm'],
      render: (value) => (
        <span>
          <Price amount={value} />
        </span>
      )
    },
    {
      title: t('balance.table.status'),
      dataIndex: 'status',
      key: 'status',
      responsive: ['xs', 'sm'],
      render: (value) => <span>{value}</span>
    }
  ];

  return (
    <>
      <DashboardContent title={t('transactions.title')}>
        <Row>
          <Col span={24} lg={24} className="balance-admin__amount">
            <div className="balance-admin__amount-current">
              <span className="balance-admin__amount-text">{t('balance.current')}</span>
              <span className="balance-admin__amount-value">
                <Price amount={loadingBalance ? 0 : balance?.amount} />
              </span>
            </div>
          </Col>
        </Row>
        <div className="manage-content">
          <div className="fans-table">
            <Table
              bordered
              dataSource={data}
              columns={columns}
              loading={loading}
              pagination={false}
            />
            <Pagination nextToken={nextHistoryToken} loading={loading} fetchData={nextPage} />
          </div>
        </div>
        <Modal
          className="transactions-modal"
          visible={isDetailModalOpen}
          title={t('transactions.modal.title')}
          onCancel={() => setIsDetailModalOpen(false)}
          footer={null}
          width={600}
        >
          <div className="transactions-modal-container-detail">
            <table className="transactions-modal-container-detail-table">
              <tr className="transactions-modal-container-detail-table-row">
                <td className="transactions-modal-container-detail-table-row-left">
                  {t('transactions.modal.transactionID')}
                </td>
                <td>{selectedBalance?.invoiceID}</td>
              </tr>
              <tr className="transactions-modal-container-detail-table-row">
                <td className="transactions-modal-container-detail-table-row-left">
                  {t('transactions.modal.date')}
                </td>
                <td>{<DisplayDate date={selectedBalance?.createdAt} />}</td>
              </tr>
              <tr className="transactions-modal-container-detail-table-row">
                <td className="transactions-modal-container-detail-table-row-left">
                  {t('transactions.modal.description')}
                </td>
                <td className="transactions-modal-container-detail-table-row-description">
                  {selectedBalance?.description}
                </td>
              </tr>
              <tr className="transactions-modal-container-detail-table-row">
                <td className="transactions-modal-container-detail-table-row-left">
                  {t('transactions.modal.amount')}
                </td>
                <td>
                  {selectedBalance?.debit !== '0' ? (
                    <Price amount={selectedBalance?.debit} />
                  ) : (
                    <Price amount={selectedBalance?.credit} />
                  )}
                </td>
              </tr>
            </table>
            <div className="transactions-modal-container-footer">
              <PrintInvoice history={selectedBalance}></PrintInvoice>
            </div>
          </div>
        </Modal>
      </DashboardContent>
      <Helmet>
        <title>{t('creators.title')}</title>
      </Helmet>
    </>
  );
}

export default Balance;
