import { Select, Form, Button } from 'antd';
import { Helmet } from 'react-helmet';
import { DashboardContent } from '@components';
import { useAllUsers } from '@libs/hooks/user';

const { Option } = Select;

function DummyDeleteCollectible() {
    const { data: users, onDelete, deleteLoading } = useAllUsers();

    const onSubmit = async (values) => {
        const payload = {
            ...values,
            item: 'user'
        };
        await onDelete(payload);
    };

    return (
        <>
            <DashboardContent title="[Temporary] Delete User">
                <Form onFinish={onSubmit} layout="vertical" className="admin-form">
                    <Form.Item
                        label="Select User"
                        name="id"
                        rules={[{ required: true, message: 'User is required.' }]}
                    >
                        <Select placeholder="Select user to delete">
                            {users?.map((u, i) => {
                                return (
                                    <Option key={`user-${i}`} value={u.id}>
                                        {`${u.firstName} ${u.lastName}`}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={deleteLoading}>
                            Delete
                        </Button>
                    </Form.Item>
                </Form>
            </DashboardContent>
            <Helmet>
                <title>Delete Collectible</title>
            </Helmet>
        </>
    );
}

export default DummyDeleteCollectible;
