import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

const Verify = ({ showModal, onCancel, refetch }) => {
  const { t } = useTranslation();

  return (
    <Modal
      className="withdrawal-modal"
      title={t('balance.verify.label')}
      visible={showModal}
      footer={null}
      width={508}
      onCancel={() => {
        onCancel();
        refetch();
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <CheckCircleOutlined style={{ fontSize: '80px' }} />
        <h1>{t('balance.modal.success.label')}</h1>
        <p>{t('balance.modal.success.description')}</p>
      </div>
    </Modal>
  );
};
export default Verify;
