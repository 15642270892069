import { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '@libs/contexts/auth';
import gql from '@libs/utils/gql';
import useToast from '@libs/utils/toast';
import { userByAccountAndVerified } from '@graphql/queries';
import { customCreateAccount, customCreateUser, customUpdateAccount } from '@graphql/mutations';

export const useAccount = () => {
  const history = useHistory();
  const toast = useToast();
  const [createLoading, setCreateLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const onCreate = useCallback(
    async (payload) => {
      setCreateLoading(true);
      try {
        await gql(
          customCreateAccount,
          {
            input: {
              name: payload.name,
              userID: payload.userID,
              legalName: payload.legalName,
              address: payload.address
            }
          },
          {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }
        );

        toast('Account created', 'success');
      } catch (error) {
        const errorMessage = error.errors[0]?.message;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setCreateLoading(false);

        setTimeout(() => history.push('/admin/organization'), 500);
      }
    },
    [toast] // eslint-disable-line
  );

  const onUpdate = useCallback(
    async (payload) => {
      setUpdateLoading(true);
      try {
        await gql(
          customUpdateAccount,
          {
            input: {
              id: payload.id,
              ownerID: payload.owner,
              name: payload.name,
              legalName: payload.legalName,
              address: payload.address,
              phone: payload.phone,
              website: payload.website,
              country: payload.country,
              city: payload.city,
              state: payload.state,
              postcode: payload.postcode
            }
          },
          {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }
        );

        toast('Account updated', 'success');
      } catch (error) {
        const errorMessage = error.errors[0]?.message;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setUpdateLoading(false);

        setTimeout(() => history.push(`/admin/organization/${payload.id}`), 500);
      }
    },
    [toast] // eslint-disable-line
  );

  return {
    createLoading,
    updateLoading,
    onCreate,
    onUpdate
  };
};

export const useProfileAccount = (type = 'CREATOR_PROFILE') => {
  const toast = useToast();
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        setLoading(true);

        if (user?.accountID) {
          const params = {
            accountID: user.accountID,
            verified: {
              beginsWith: type === 'CREATOR_PROFILE' ? 'TRUE' : 'FALSE'
            }
          };
          const { data: res } = await gql(userByAccountAndVerified, params);

          const result =
            res?.userByAccountAndVerified?.items.map((x) => ({
              label: x.firstName + ' ' + x.lastName,
              value: x.id
            })) || [];

          setData(result);
        }
      } catch (error) {
        const errorMessage = error.errors[0]?.message;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    getData();
  }, [user]); // eslint-disable-line

  const onCreate = useCallback(
    async (payload) => {
      setLoading(true);

      try {
        await gql(
          customCreateUser,
          {
            input: {
              accountID: user.accountID,
              editors: payload.editors || [],
              username: payload.username,
              firstName: payload.firstName,
              lastName: payload.lastName,
              bio: payload.bio,
              avatarUrl: payload.avatarUrl,
              coverUrl: payload.coverUrl,
              facebook: payload.facebook,
              youtube: payload.youtube,
              instagram: payload.instagram,
              twitter: payload.twitter,
              soundcloud: payload.soundcloud,
              spotify: payload.spotify,
              email: payload.email
            }
          },
          {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }
        );

        toast('Profile created', 'success');
      } catch (error) {
        const errorMessage = error.errors[0]?.message;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [toast, user]
  );

  return {
    loading,
    data,
    onCreate
  };
};
