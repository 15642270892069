export const searchHistoryOrderPrints = /* GraphQL */ `
  query SearchOrderPrints(
    $filter: SearchableOrderPrintFilterInput
    $sort: SearchableOrderPrintSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchOrderPrints(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        shippingProduct {
          sku
          copies
          size
          frame
          # attributes -> This field currently not used in order print
          assets
          certificate
        }
        shippingDetail {
          address
          city
          country
          email
          fullName
          postalCode
          phone
        }
        exchangeID
        userID
        prodigiOrderID
        prodigiResponse
        totalPrice
        createdAt
        paymentStatus
        orderStatus
        exchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectible {
            id
            accountID
            userID
            type
            category
            genre
            title
            slug
            description
            insideStory
            assetUrl
            mediaThumbnail
            marketingText
            whatsIncludedOriginal
            whatsIncludedNumbered
            featured
            featuredInProfile
            status
            yearOfCreation
            releasedAt
            createdAt
            updatedAt
            maxNumberedEditions
            metaDescription
            searchableType
            searchableFeatured
            searchablefeaturedInProfile
            searchableCategory
            searchableStatus
            searchableLowestPrice
            searchableHighestPrice
            searchableGenre
            _version
            _deleted
            _lastChangedAt
            owner
            creator {
              id
              owner
              accountID
              editors
              email
              username
              firstName
              lastName
              displayName
              bio
              tags
              verified
              fanProfile
              creatorCategory
              emailVerified
              joinDate
              isHidden
              profileTemplate
              address {
                recipientName
                line1
                country
                stateName
                city
                postCode
                phone
              }
              walletAddresses
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          collectibleReleaseIndex
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        user {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          address {
            recipientName
            line1
            country
            stateName
            city
            postCode
            phone
          }
          media {
            id
            avatarUrl
            avatarVideoUrl
            coverUrl
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            owner
          }
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
