import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// If you're curious about the i18next setup
// please read it here https://react.i18next.com/latest/using-with-hooks

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    keySeparator: '.',
    nsSeparator: ':',
    react: {
      // Fix issue: suspended while rendering
      // See: https://stackoverflow.com/questions/54432861/a-react-component-suspended-while-rendering-but-no-fallback-ui-was-specified
      useSuspense: true,
    }
  });

export default i18n;
