import cx from 'classnames';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { AuthFooter, AuthHeader, Container } from '@components';
import Landing from './landing';
import AuthLogin from './login';
import AuthForgot from './forgot';
import AuthResetPassword from './reset';
import AuthAcceptInvitation from './accept-invitation';
import AuthJoinMember from './join-member';

function Auth() {
  const { path } = useRouteMatch();

  const authClass = (path) => {
    switch (path) {
      case '/login':
        return 'auth--login';
      default:
        return null;
    }
  };

  return (
    <>
      <div className={cx('auth', authClass(path))}>
        <AuthHeader />
        <div className="auth-wrapper">
          <Switch>
            <Route exact path="/" component={Landing} />
            <Container>
              <div className="auth-container">
                <Route exact path="/login" component={AuthLogin} />
                <Route exact path="/forgot-password" component={AuthForgot} />
                <Route exact path="/reset-password" component={AuthResetPassword} />
                <Route exact path="/accept-invitation/:id" component={AuthAcceptInvitation} />
                <Route exact path="/join-team/:id" component={AuthJoinMember} />
              </div>
            </Container>
          </Switch>
        </div>
        <AuthFooter />
      </div>
    </>
  );
}

export default Auth;
