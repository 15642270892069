import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';
import { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import gql from '@libs/utils/gql';
import { collectionByUserAndName } from '@graphql/queries';
import { collectionByAccount, searchCollections } from '@libs/custom-queries/collection';
import {
  customCreateCollection,
  createCollections,
  updateCollections,
  deleteCollections
} from '@graphql/mutations'; // eslint-disable-line
import useToast from '@libs/utils/toast';
import { useAuth } from '@libs/contexts/auth';

export const useCollectionsByUser = (userID, form = false) => {
  const [loading, setLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [error, setError] = useState();
  const [value, setValue] = useState([]);
  const [options, setOptions] = useState([]);
  const { user } = useAuth();
  const history = useHistory();

  useEffect(() => {
    async function getData() {
      setLoading(true);

      if (!userID) return;

      try {
        const { data: res } = await gql(collectionByUserAndName, {
          userID: userID
        });

        const options =
          res?.collectionByUserAndName?.items.map((x) => ({ label: x.name, value: x.id })) || [];

        setOptions(options);
      } catch (error) {
        console.error(error);
        setOptions([]);
      } finally {
        setLoading(false);
      }
    }

    getData();
  }, [query, userID]);

  const onCreate = useCallback(
    async (payload, collectibleForm = false) => {
      setCreateLoading(true);
      try {
        await gql(
          customCreateCollection,
          {
            input: {
              name: payload.name,
              userID: payload.userID,
              accountID: user?.accountID
            }
          },
          {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }
        );
        history.push('/collections');
      } catch (error) {
        setError(error.message);
        console.error(error);
      } finally {
        setCreateLoading(false);
      }
    },
    [value, history, user?.accountID] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return {
    options,
    value,
    loading,
    createLoading,
    error,
    onCreate,
    onChange: (v) => setValue(v),
    onSearch: debounce((value) => setQuery(value), 500)
  };
};

export const useCollectionsByAccount = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [nextPageToken, setNextPageToken] = useState();

  const getData = async (Token, Name) => {
    setLoading(true);
    try {
      const params = { accountID: user?.accountID, limit: 10 };
      if (Token) {
        params.nextToken = Token;
      }
      if (Name) {
        params.filter = { name: { contains: Name } };
        params.limit = 100;
      }
      const { data: res } = await gql(collectionByAccount, params);
      setData(res.collectionByAccount.items);
      setNextPageToken(res.collectionByAccount.nextToken);
    } catch (error) {
      console.error(error);
      setData([]);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, [user?.accountID]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    data,
    loading,
    nextPageToken,
    getData
  };
};

export const useCollection = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [createLoading, setCreateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const toast = useToast();
  const history = useHistory();
  const [nextPageToken, setNextPageToken] = useState();

  const getData = async (Token, name) => {
    setLoading(true);
    const params = { limit: 10 };
    if (Token) {
      params.nextToken = Token;
    }
    if (name) {
      params.filter = { name: { matchPhrasePrefix: name } };
    }
    try {
      const { data: result } = await gql(searchCollections, params);
      if (result?.searchCollections?.items) {
        setData(result?.searchCollections?.items);
        setNextPageToken(result?.searchCollections?.nextToken);
      }
    } catch (error) {
      const errorMessage = error.errors[0]?.errorType;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line

  const onCreate = useCallback(
    async (payload) => {
      setCreateLoading(true);

      try {
        await gql(
          createCollections,
          {
            input: {
              name: payload.name,
              collectionType: payload.collectionType,
              avatarUrl: payload.avatarUrl,
              coverUrl: payload.coverUrl,
              logoUrl: payload.logoUrl,
              collectionUrl: payload.collectionUrl,
              slug: payload.slug,
              shortName: payload.shortName,
              isShowLogo: payload.isShowLogo
            }
          },
          {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }
        );

        toast(t('collections.add.success'), 'success');
        setTimeout(function () {
          history.push('/admin/collections');
        }, 1000);
      } catch (error) {
        const errorMessage = error.errors[0]?.message;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setCreateLoading(false);
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const isNameAvailable = async (slug) => {
    if (!slug) {
      return;
    }

    try {
      const { data: res } = await gql(searchCollections, { filter: { slug: { eq: slug } } });
      const isExists = !!res?.searchCollections?.items?.length;
      return !isExists;
    } catch (err) {
      return false;
    }
  };

  const onDelete = async (payload) => {
    setDeleteLoading(true);
    try {
      await gql(
        deleteCollections,
        {
          input: {
            id: payload
          }
        },
        {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }
      );

      toast(t('collections.delete.title'), 'success');
    } catch (error) {
      const errorMessage = error.errors[0]?.message;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setDeleteLoading(false);
    }
  };

  return {
    data,
    loading,
    createLoading,
    onCreate,
    nextPageToken,
    getData,
    onDelete,
    isNameAvailable,
    deleteLoading
  };
};

export const useCollectionById = (id) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [updateLoading, setUpdateLoading] = useState(false);
  const toast = useToast();

  const getData = async () => {
    setLoading(true);
    const params = { limit: 10, filter: { id: { eq: id } } };
    try {
      const { data: result } = await gql(searchCollections, params);
      if (result?.searchCollections?.items) {
        setData(result?.searchCollections?.items[0]);
      }
    } catch (error) {
      const errorMessage = error.errors[0]?.errorType;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line

  const onUpdate = useCallback(
    async (payload) => {
      setUpdateLoading(true);

      try {
        await gql(
          updateCollections,
          {
            input: {
              id: payload.id,
              name: payload.name,
              collectionType: payload.collectionType,
              avatarUrl: payload.avatarUrl,
              coverUrl: payload.coverUrl,
              logoUrl: payload.logoUrl,
              slug: payload.slug,
              collectionUrl: payload.collectionUrl,
              shortName: payload.shortName,
              isShowLogo: payload.isShowLogo
            }
          },
          {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }
        );

        toast(t('collections.edit.success'), 'success');
      } catch (error) {
        const errorMessage = error.errors[0]?.message;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setUpdateLoading(false);
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return {
    data,
    loading,
    updateLoading,
    onUpdate,
    getData
  };
};

export const useCollectionByAccountId = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const accountID = user?.accountID;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [updateLoading, setUpdateLoading] = useState(false);
  const toast = useToast();

  const getData = async () => {
    setLoading(true);
    const params = { filter: { accountID: { eq: accountID } } };
    try {
      const { data: result } = await gql(searchCollections, params);
      if (result?.searchCollections?.items) {
        setData(result?.searchCollections?.items[0]);
      }
    } catch (error) {
      const errorMessage = error.errors[0]?.errorType;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (accountID) {
      getData();
    }
  }, [accountID]); // eslint-disable-line

  const onUpdate = useCallback(
    async (payload) => {
      setUpdateLoading(true);

      try {
        await gql(
          updateCollections,
          {
            input: {
              id: payload.id,
              name: payload.name,
              collectionType: payload.collectionType,
              avatarUrl: payload.avatarUrl,
              coverUrl: payload.coverUrl,
              logoUrl: payload.logoUrl,
              slug: payload.slug,
              collectionUrl: payload.collectionUrl,
              shortName: payload.shortName,
              isShowLogo: payload.isShowLogo
            }
          },
          {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }
        );

        toast(t('collections.edit.success'), 'success');
      } catch (error) {
        const errorMessage = error.errors[0]?.message;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setUpdateLoading(false);
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return {
    data,
    loading,
    updateLoading,
    onUpdate,
    getData
  };
};
